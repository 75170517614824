import TemplateRecharge from '../app/templates/TemplateRecharge'
import FirstAccessRecharge from '../app/pages/FirstAccessRecharge'
import EditPaymentCreditCard from '../app/pages/EditPaymentCreditCard'
import FirstAccessRechargeSuccess from '../app/pages/FirstAccessRechargeSuccess'

export default {
  path: '/minha-veloe/:id/primeira-recarga',
  component: TemplateRecharge,
  routes: [
    {
      path: '/minha-veloe/:id/primeira-recarga',
      exact: true,
      component: FirstAccessRecharge
    },

    {
      path: '/minha-veloe/:id/primeira-recarga/cartao',
      exact: true,
      component: EditPaymentCreditCard
    },

    {
      path: '/minha-veloe/:id/primeira-recarga/sucesso',
      exact: true,
      component: FirstAccessRechargeSuccess
    }
  ]
}
