import gql from 'graphql-tag'

export const gqlSexos = gql`
  {
    sexos {
      codigo
      nome
    }
  }
`
export const transformSexos = ({ data }) =>
  data.sexos.map(ec => ({ value: ec.codigo.toString(), label: ec.nome }))

export const gqlEstadosCivis = gql`
  query {
    estadosCivis {
      codigo
      nome
      nemotecnico
    }
  }
`
export const transformEstadosCivis = ({ data }) =>
  data.estadosCivis.map(ec => ({ value: ec.codigo.toString(), label: ec.nome }))

export const gqlParametrizacao = gql`
  query parametrizacao($parametro: String!) {
    parametrizacao(parametro: $parametro) {
      cobrar
      chave
      descricao
      valor
      convenio
    }
  }
`
export const transformBancos = ({ data }) =>
  data.parametrizacao.map(it => ({
    value: it.chave,
    label: it.descricao,
    convenio: it.convenio
  }))
