import React from 'react'
import { Field, reduxForm } from 'redux-form'
import { Box } from 'grid-styled'
import { withRouter } from 'react-router-dom'
import { utils } from 'alelo-logic'

import Radio from '../../elements/Radio'
import TextInput from '../../elements/TextInput'

import { ParagraphGrayLabel } from '../../pages/BlockIdentifierNew/styles'

const renderRadio = ({ input, label }) => <Radio {...input} label={label} />

const renderOtherMotive = ({ placeholder, input, onChange, value, meta: { touched, error } }) => (
  <div>
    <ParagraphGrayLabel>QUAL O NÚMERO DO NOVO ACESSÓRIO?</ParagraphGrayLabel>
    <TextInput
      {...input}
      placeholder={placeholder}
      maxlength="10"
      errorMessage={touched && error}
    />
  </div>
)

const BlockIdentifiersListOptions = props => {
  const { list, handleChange, optionSelected, handleChangeNewVeloeTag } = props

  return (
    <Box mt={15}>
      <form onSubmit={() => {}}>
        {list &&
          list.map(
            item =>
              item && (
                <Box mb={10} key={item?.chave}>
                  <Field
                    component={renderRadio}
                    label={item.descricao}
                    name="option"
                    value={item.chave}
                    type="radio"
                    onChange={e => {
                      handleChange(e)
                      window.dataLayer.push({
                        event: 'event',
                        dimension25: item.descricao
                      })
                    }}
                  />
                </Box>
              )
          )}
        {optionSelected === 'POSSUO' && (
          <Box
            mb={20}
            onFocus={() => {
              window.dataLayer.push({
                event: 'event',
                eventAction: 'preencher:novo-acessorio',
                eventCategory: 'veloe:area-logado:meus-produtos:bloquear-substituir',
                eventLabel: 'novo-acessorio'
              })
            }}
          >
            <Field
              component={renderOtherMotive}
              placeholder="Digite aqui"
              name="novoCodigoIdentificador"
              onChange={e => handleChangeNewVeloeTag(e)}
              validate={utils.identifierValidate}
            />
          </Box>
        )}
      </form>
    </Box>
  )
}

export default reduxForm({
  form: 'formBlockIdentifierListOptions',
  destroyOnUnmount: true
})(withRouter(BlockIdentifiersListOptions))
