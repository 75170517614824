import styled, { css } from 'styled-components'

import rem from '../../../styles/tools/rem'
import em from '../../../styles/tools/em'
import media from '../../../styles/tools/media'

import {
  txt14OpenSans,
  txt12OpenSans,
  title36GalanoBold,
  title40GalanoBold
} from '../../../styles/settings/types'

import {
  darkSlateBlue,
  darkBlue,
  blueGray,
  paleGray,
  lightBlue,
  grey,
  white
} from '../../../styles/settings/colors'

export const Title = styled.h1`
  ${title40GalanoBold} letter-spacing: ${em('0.4px')};
  color: ${darkSlateBlue};
  margin-top: ${rem('40px')};
`

export const SubTitle = styled.div`
  ${title36GalanoBold};
  font-size: 1.4rem;
  font-weight: 500;
  line-height: 1.44;
  margin-bottom: 20px;
  color: ${darkSlateBlue};
`

export const Paragraph = styled.p`
  ${txt14OpenSans} color: ${darkBlue};
  width: ${rem('320px')};

  ${media.large`
    width: ${rem('660px')};
  `};

  ${({ noMargin }) =>
    noMargin &&
    css`
      margin-bottom: 0;
    `};
`

export const BoxGrey = styled.div`
  background: -moz-linear-gradient(
    top,
    rgba(241, 241, 247, 1) 0%,
    rgba(241, 241, 247, 1) 45%,
    rgba(241, 241, 247, 1) 40%,
    rgba(241, 241, 247, 0) 41%
  );
  background: -webkit-linear-gradient(
    top,
    rgba(241, 241, 247, 1) 0%,
    rgba(241, 241, 247, 1) 45%,
    rgba(241, 241, 247, 1) 40%,
    rgba(241, 241, 247, 0) 41%
  );
  background: linear-gradient(
    to bottom,
    rgba(241, 241, 247, 1) 0%,
    rgba(241, 241, 247, 1) 45%,
    rgba(241, 241, 247, 1) 40%,
    rgba(241, 241, 247, 0) 41%
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='${paleGray}', endColorstr='${paleGray}',GradientType=0 );
  margin: 50px 0 0;
  padding: 40px 0;
`

export const TextInfo = styled.p`
  ${txt14OpenSans};
  color: ${darkSlateBlue};
  margin: 0 0 30px;
`

export const List = styled.ul`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0;
  padding: 0;

  ${media.small`
    flex-direction: column;
  `};

  li {
    max-width: 205px;
    min-width: 205px;
    /* max-width: 168px; */
    /* min-width: 168px; */
    min-height: 80px;

    ${media.small`
      max-width: 100%;
      min-width: 100%;
      min-height: auto;
      margin-bottom: 10px;
      padding-top: 0;
      padding-bottom: 0;
    `};
  }
`

export const ListForm = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 40px 0 30px;

  ${media.small`
    flex-direction: column;
    margin-top: 20px;
  `};

  > div {
    max-width: 216px;
    margin-right: 25px;

    ${media.small`
      margin-bottom: 15px;
      margin-right: 0;
      max-width: 100%;
    `};
  }

  label {
    ${txt12OpenSans};
    font-weight: bold;
    letter-spacing: ${em('1.6px')};
    color: ${blueGray};
    text-transform: uppercase;
  }
`

export const TitleSecond = styled.h2`
  ${title36GalanoBold} letter-spacing: ${em('0.1px')};
  color: ${darkSlateBlue};
  margin-top: ${rem('50px')};
`

export const WrapperPill = styled.ul`
  display: flex;
  justify-content: flex-start;
  margin: 0;
  padding: 0;
  padding-top: 25px;
  min-height: ${rem('100px')};
`

export const Pill = styled.li`
  background: ${lightBlue};
  border-radius: 12px;
  ${txt14OpenSans} color: ${white};
  font-size: 10px;
  font-weight: bold;
  height: 24px;
  letter-spacing: 0.5px;
  list-style: none;
  margin-right: 20px;
  padding: 5px 30px 5px 15px;
  position: relative;

  ${({ noSelected }) =>
    noSelected &&
    `
    background: ${grey};
    padding-right: 15px;

    span {
      display: none;
    }
  `};

  span {
    cursor: pointer;
    display: block;
    position: absolute;
    right: 12px;
    top: 5px;
  }
`

export const Container = styled.div`
  /* background: #efeff6; */
  padding: 0;
`

export const FormGroup = styled.div`
  margin-bottom: 35px;
`

export const Vehicles = styled.div`
  margin-bottom: 60px;
  ${SubTitle} {
    margin-bottom: 30px;
  }
`

export const ExtraInfoContainer = styled.div`
  padding-bottom: 150px;
`
