import styled from 'styled-components'
import rem from '../../../styles/tools/rem'
import { Flex } from 'grid-styled'

import media from '../../../styles/tools/media'
import { txt14OpenSans } from '../../../styles/settings/types'

import {
  lightSilver,
  white,
  tealish,
  darkSlateBlue,
  blueGray
} from '../../../styles/settings/colors'

export const Wrapper = styled(Flex)`
  justify-content: space-between;
  margin-bottom: 25px;

  ${media.small`
    flex-direction: column;
    margin-bottom: 0;
  `};
`

export const Item = styled.div`
  border: ${rem('1px')} solid ${lightSilver};
  border-radius: ${rem('40px')};
  height: ${rem('64px')};
  width: ${rem('64px')};
  color: ${lightSilver};
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
`

export const ItemContainer = styled.div`
  background: ${white};
  align-items: center;
  position: relative;
  min-width: 235px;

  ${media.small`
    display: flex;
    height: 95px;
    align-items: flex-start;
  `};

  ${media.large`
    min-width: 235px;
  `};

  ${props =>
    props.borderBlue &&
    ` & {
      div {
        background: ${tealish} !important;;
        border: ${rem('1px')} solid ${tealish};
        color: ${darkSlateBlue} !important;
      }

      p {
        color: ${darkSlateBlue} !important;
      }
    }`};

  :last-child {
    min-width: auto;
    padding-top: 4px;
    ${media.small`
      padding-top: 0;
      max-width: 205px;
    `};
  }

  :not(:last-child):after {
    background: ${lightSilver};
    content: '';
    height: 2px;
    position: absolute;
    width: 100%;
    left: 63px;
    top: calc(64px - 30%);

    ${media.small`
      height: 31px;
      left: 32px;
      bottom: 0;
      top: auto;
      width: 2px;
    `};
  }

  &:after {
    background: ${props => (props.borderBlue ? `${tealish} !important;` : `${lightSilver};`)};
  }

  svg {
    max-width: 32px;
    max-height: 28px;
  }
`

export const Infos = styled.span`
  display: flex;
  flex-direction: column;

  ${media.small`
    margin-left: 16px;
  `};
`

export const Description = styled.p`
  color: ${blueGray};
  ${txt14OpenSans};
  font-weight: 600;
  line-height: 1.57;
  margin: 15px 0 0;
`

export const DateTicket = styled.span`
  color: ${blueGray};
  ${txt14OpenSans};
  font-weight: 600;
  line-height: 1.57;
  margin: 0;
`
