const AUTH_KEY = 'veloe-auth'

const parseJwt = token => {
  var base64Url = token.split('.')[1]
  var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
  return JSON.parse(window.atob(base64))
}

const objectSetter = key => obj => {
  sessionStorage.setItem(key, JSON.stringify(obj))
}

const objectRemover = key => () => {
  sessionStorage.removeItem(key)
}

const objectGetter = key => () => {
  const json = sessionStorage.getItem(key)
  try {
    return JSON.parse(json)
  } catch (e) {
    console.warn(`Erro parsing storage key "${key}" object. value="${json}"`)
    return null
  }
}

const getAuthObj = objectGetter(AUTH_KEY)
export const setAuthObj = objectSetter(AUTH_KEY)
export const removeAuthObj = objectRemover(AUTH_KEY)

export const getAccessToken = () => {
  const authObj = getAuthObj()
  if (!authObj || !authObj.access_token) return null
  return authObj.access_token
}

export const getAccessTokenObject = () => {
  const acessToken = getAccessToken()
  return (acessToken && parseJwt(acessToken)) || null
}

export const getRefreshToken = () => {
  const authObj = getAuthObj()
  if (!authObj || !authObj.refresh_token) return null
  return authObj.refresh_token
}
