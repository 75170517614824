import React from 'react'
import _ from 'lodash'
import { connect } from 'react-redux'

import Option from '../../elements/Option'

import { WrapperInstall, List, TextInstall, SubTitle } from './styles'

import IcCarBig from '../../elements/Icon/ic-car-big'
import IcBus from '../../elements/Icon/ic-bus-big'
import IcTruck from '../../elements/Icon/ic-truck-big'
import TruckPDF from '../../../static/truck.pdf'
import CarPDF from '../../../static/car.pdf'
import BusPDF from '../../../static/bus.pdf'
const { installText, carType } = require('./data.json')

const BandPDF = 'https://veloe.com.br/mobile-static/nfcpayment/ManualUsuarioPulseira.pdf'
const SmartBandPDF = 'https://veloe.com.br/mobile-static/nfcpayment/ManualUsuarioRelogio.pdf'

const handleClickDownload = function(path) {
  window.open(path, '_blank')
}

const HowToInstall = props => {
  const { accountPlans } = props
  const { plans, activePlanId } = accountPlans

  const currentPlan = plans.find(plan => String(plan.id) === String(activePlanId)) || { plan: {} }

  // SEMIAUTOMATICO
  const { identifiers = [] } = currentPlan
  const identifiersTypes = _.map(identifiers, 'identifierType')
  const semiautomaticoTypes = ['BAND', 'SMART_BAND']
  const isSemiautomatico = _.some(semiautomaticoTypes, t => _.includes(identifiersTypes, t))
  const hasBand = _.includes(identifiersTypes, 'BAND')
  const hasSmartBand = _.includes(identifiersTypes, 'SMART_BAND')
  const hasTag = _.includes(identifiersTypes, 'TAG')

  return (
    <WrapperInstall>
      {(!isSemiautomatico || hasTag) && (
        <div>
          <TextInstall textWhite={props.textWhite}>{installText}</TextInstall>
          <SubTitle textWhite={props.textWhite}>Adesivo</SubTitle>
          <List>
            <Option
              description={carType.car}
              icon={<IcCarBig />}
              setOption={() => {
                handleClickDownload(CarPDF)
                window.dataLayer.push({
                  event: 'event',
                  eventAction: 'clique:como-instalar',
                  eventCategory: 'veloe:area-logado:identificadores',
                  eventLabel: 'carro-passeio'
                })
              }}
              value="carro-passeio"
              notGray
            />
            <Option
              description={carType.truck}
              icon={<IcTruck />}
              setOption={() => {
                handleClickDownload(TruckPDF)
                window.dataLayer.push({
                  event: 'event',
                  eventAction: 'clique:como-instalar',
                  eventCategory: 'veloe:area-logado:identificadores',
                  eventLabel: 'caminhao'
                })
              }}
              value="caminhao"
              notGray
            />
            <Option
              description={carType.bus}
              icon={<IcBus />}
              setOption={() => {
                handleClickDownload(BusPDF)
                window.dataLayer.push({
                  event: 'event',
                  eventAction: 'clique:como-instalar',
                  eventCategory: 'veloe:area-logado:identificadores',
                  eventLabel: 'onibus'
                })
              }}
              value="onibus"
              notGray
            />
          </List>
        </div>
      )}
      {isSemiautomatico && <SubTitle textWhite={props.textWhite}>Dispositivo</SubTitle>}
      <List>
        {isSemiautomatico && hasBand && (
          <Option
            description={carType.pulseira}
            icon={<div />}
            setOption={() => handleClickDownload(BandPDF)}
            value="pulseira"
            notGray
            className="gtm-element-event"
            data-gtm-event-category="veloe:area-logado:identificadores"
            data-gtm-event-action="clique:como-instalar"
            data-gtm-event-label="pulseira"
          />
        )}
        {isSemiautomatico && hasSmartBand && (
          <Option
            description={carType.relogio}
            icon={<div />}
            setOption={() => handleClickDownload(SmartBandPDF)}
            value="relogio"
            notGray
            className="gtm-element-event"
            data-gtm-event-category="veloe:area-logado:identificadores"
            data-gtm-event-action="clique:como-instalar"
            data-gtm-event-label="relogio"
          />
        )}
      </List>
    </WrapperInstall>
  )
}

const mapStateToProps = ({ form, reducer }) => {
  return {
    accountPlans: reducer.accountPlans
  }
}

const mapDispatchToProps = () => ({})

export default connect(mapStateToProps, mapDispatchToProps)(HowToInstall)
