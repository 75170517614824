import React from 'react'
import { Field, reduxForm } from 'redux-form'
import { connect } from 'react-redux'
import { Flex, Box } from 'grid-styled'
import { utils } from 'alelo-logic'
import Button from '../../elements/Button'
import { renderTextInput, renderCnpjInput } from '../../../utils/renderInputs'

import { Container } from '../EditUserDataForm/styles'

const { labels } = require('./data.json')

const { currencyParser } = utils

const EditCompanyDataForm = props => {
  const { handleSubmit, saving } = props

  return (
    <form onSubmit={handleSubmit}>
      <Container>
        <Flex wrap width={[1, 7 / 12]}>
          <Box width={1} mb={3}>
            <Field
              component={renderTextInput}
              label={labels.name}
              name="name"
              type="text"
              validate={utils.emptyFieldValidate}
              isRequired
            />
          </Box>
          <Box width={1} mb={3}>
            <Field
              component={renderCnpjInput}
              label={labels.cnpj}
              name="cnpj"
              type="text"
              normalize={utils.cnpjMask}
              validate={utils.cnpjValidate}
              disabled
              isRequired
            />
          </Box>
          <Box width={1} mb={3}>
            <Field
              component={renderTextInput}
              label={labels.companyName}
              name="companyName"
              type="text"
              validate={utils.emptyFieldValidate}
              disabled
              isRequired
            />
          </Box>
          <Box width={1} mb={3}>
            <Field
              component={renderTextInput}
              label={labels.foc}
              name="foc"
              type="text"
              validate={utils.emptyFieldValidate}
              disabled
              isRequired
            />
          </Box>
          <Box width={[1, 1 / 2]} pr={[0, 2]} mb={3}>
            <Field
              component={renderTextInput}
              label={labels.doc}
              name="doc"
              type="text"
              validate={utils.dateBeforeTodayValidate}
              normalize={utils.dateMask}
              disabled
              isRequired
            />
          </Box>

          <Box width={[1, 1 / 2]} pl={[0, 2]} mb={3}>
            <Field
              component={renderTextInput}
              label={labels.cnae}
              name="cnae"
              type="text"
              normalize={utils.numberMask}
              validate={utils.emptyFieldValidate}
              isRequired
            />
          </Box>
          <Box width={[1, 1 / 2]} pr={[0, 2]} mb={3}>
            <Field
              component={renderTextInput}
              label={labels.registration}
              name="registration"
              type="text"
              validate={utils.emptyFieldValidate}
              disabled
              isRequired
            />
          </Box>
          <Box width={[1, 1 / 2]} pl={[0, 2]} mb={3}>
            <Field
              component={renderTextInput}
              label={labels.income}
              name="income"
              type="text"
              isRequired
              format={value => currencyParser(value, true, '')}
              lockCursor
            />
          </Box>
        </Flex>
      </Container>
      <Container>
        <Button type="submit" text="Salvar" disabled={saving} />
      </Container>
    </form>
  )
}

const mapStateToProps = state => {
  return {
    saving: state.reducer.formState.saving
  }
}

const form = reduxForm({
  form: 'editCompanyData',
  enableReinitialize: true,
  destroyOnUnmount: false
})(EditCompanyDataForm)

export default connect(mapStateToProps, undefined)(form)
