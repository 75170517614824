import styled from 'styled-components'

export const Overlay = styled.div`
  position: fixed;
  display: ${({ isOpen }) => (isOpen ? 'initial' : 'none')};
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: ${({ isOpen }) => (isOpen ? 0 : -1)};
`
