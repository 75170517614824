import React from 'react'
import { Field, reduxForm } from 'redux-form'
import { Flex, Box } from 'grid-styled'
import { isEmpty } from 'lodash'
import Button from '../../elements/Button'
import rem from '../../../styles/tools/rem'
import { renderTextInput } from '../../../utils/renderInputs'

import { Buttons } from './style'

const { errorLogin, labelLogin, send } = require('./data.json')

function validate(values) {
  const errors = {}

  if (isEmpty(values.login)) {
    errors.login = errorLogin
  }

  return errors
}

const ForgoPasswordForm = ({ handleSubmit }) => (
  <form onSubmit={handleSubmit}>
    <Flex direction="column">
      <Box mb={[24, 28]}>
        <Field component={renderTextInput} label={labelLogin} name="login" />
      </Box>
    </Flex>
    <Buttons>
      <Box mt={15}>
        <Button type="submit" text={send} width={rem('210px')} />
      </Box>
    </Buttons>
  </form>
)

export default reduxForm({
  form: 'login',
  validate
})(ForgoPasswordForm)
