import React from 'react'
import Helmet from 'react-helmet'
import { SubHeader, Title, Subtitle, Container } from './styles'

const Header = () => (
  <Container>
    <Helmet title="Consultar Placa" />
    <SubHeader>
      <Title>Consultar placa</Title>
      <Subtitle>
        Digite as letras e números da placa e período que deseja fazer a pesquisa.
      </Subtitle>
    </SubHeader>
  </Container>
)

export default Header
