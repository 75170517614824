import styled, { css } from 'styled-components'
import rem from 'styles/tools/rem'
import Content from 'styles/objects/Content'
import {
  darkBlue,
  lightGray,
  blueGray,
  darkGrey,
  ghostWhite,
  lightBlue,
  lightOlive,
  red
} from 'styles/settings/colors'
import {
  txt12OpenSans,
  title20GalanoBold,
  title32GalanoBold,
  txt16OpenSans
} from 'styles/settings/types'

export const CustomContent = styled(Content)`
  margin-bottom: ${rem('40px')};
`

export const Title = styled.h1`
  ${title32GalanoBold};
  margin-top: ${rem('30px')};
  margin-bottom: ${rem('10px')};
  color: ${darkBlue};
`
export const Paragraph = styled.p`
  ${txt16OpenSans};
  margin-bottom: ${rem('10px')};
  color: ${darkBlue};
`
export const Wrapper = styled.div`
  border-bottom: ${rem('1px')} solid ${lightGray};
  width: 100%;
`

export const EditHeader = styled.div`
  padding: ${rem('12px')} 0;
  text-transform: uppercase;
  ${txt12OpenSans};
  line-height: 1.67;
  color: ${blueGray};
  letter-spacing: 1px;
`

export const NoContentText = styled.span`
  ${title20GalanoBold};
  letter-spacing: 0;
  color: ${darkGrey};
`

export const ListItem = styled.div`
  padding: ${rem('15px')} 0;
  position: relative;
  overflow: hidden;
  &:nth-child(2n + 1) {
    background-color: ${ghostWhite};
  }
  ${({ isActived }) =>
    isActived &&
    css`
      outline: 1px solid ${lightBlue};
    `};
`
export const ListItemText = styled.span`
  ${txt12OpenSans};
  color: ${({ color }) => color || darkGrey};
  font-weight: ${({ bold }) => (bold ? 'bold' : 'regular')};
`

export const StatusWrapper = styled.span`
  color: ${({ active }) => (active ? darkBlue : red)};
  &:before {
    background: ${({ active }) => (active ? lightOlive : red)};
    border-radius: 50%;
    content: '';
    position: absolute;
    top: 9px;
    left: 0px;
    height: 6px;
    width: 6px;
  }
  display: flex;
  flex-direction: column;
  padding-left: 10px;
  position: relative;
`
