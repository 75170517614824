import React from 'react'
import { utils } from 'alelo-logic'
import { connect } from 'react-redux'
import { downloadStatementFile, downloadStatementFileCSV } from 'alelo-logic/actions'
import { showModal } from '../../modules/ModalRoot/actions'
import { INPUT_MODAL, CONFIRM_UPDATE } from '../../../constants/ModalTypes'

import { Icon } from '../../elements/Icon'
import { setLoading } from '../../modules/PageLoader/actions'
import image from '../../../static/mail-to.png'

import { Content, HeaderText, IconColor, NavHeader, NavIcon, StatementHeader, Text } from './styles'

const {
  downloadStatement,
  downloadStatementCSV,
  // printStatement,
  // shareStatement,
  modal
} = require('./data.json')

const StatementsHeader = ({ currency, match, statements, ...props }) => (
  <StatementHeader>
    <Content>
      <HeaderText>
        <Text>Atualizado às {utils.ISODateFormat(new Date(), 'HH:mm')},</Text>
        <Text>
          dia {utils.ISODateFormat(new Date())} - Valores em {currency}
        </Text>
      </HeaderText>
      {statements.data.length > 0 && (
        <NavHeader>
          <NavIcon alt="Download" title="Download extrato em CSV">
            <Icon
              icon="download"
              svgStyle={IconColor}
              aria-label={downloadStatementCSV}
              width="31"
              height="31"
              viewBox="0 0 70 70"
              onClick={() => {
                props.setLoading(true)
                props.downloadCSVStatement(match.params.id, statements.filter)
                window.dataLayer = window.dataLayer || []
                window.dataLayer.push({
                  event: 'event',
                  eventCategory: 'veloe:area-logado:extrato',
                  eventAction: 'clique:botao:download',
                  eventLabel: 'download:csv'
                })
              }}
            />
          </NavIcon>
          <NavIcon className="print" alt="Download" title="Download extrato em PDF">
            <Icon
              icon="print"
              svgStyle={IconColor}
              aria-label={downloadStatement}
              width="31"
              height="31"
              viewBox="0 0 85 75"
              onClick={() => {
                props.setLoading(true)
                props.downloadPdfStatement(match.params.id, statements.filter)
                window.dataLayer = window.dataLayer || []
                window.dataLayer.push({
                  event: 'event',
                  eventCategory: 'veloe:area-logado:extrato',
                  eventAction: 'clique:botao:download',
                  eventLabel: 'download:pdf'
                })
              }}
            />
          </NavIcon>
          {/* <NavIcon>
          <Icon
            icon="share"
            svgStyle={IconColor}
            aria-label={shareStatement}
            width="31"
            height="31"
            viewBox="0 0 85 70"
            onClick={() => props.shareStatement()}
          />
        </NavIcon> */}
          {/* <NavIcon className="print">
          <Icon
            icon="print"
            svgStyle={IconColor}
            aria-label={printStatement}
            width="31"
            height="31"
            viewBox="0 0 85 75"
            onClick={() => window.print()}
          />
        </NavIcon> */}
        </NavHeader>
      )}
    </Content>
  </StatementHeader>
)

const mapDispatchToProps = dispatch => ({
  downloadPdfStatement: (planId, filter) =>
    dispatch(downloadStatementFile(planId, filter)).finally(() => dispatch(setLoading(false))),
  setLoading: isLoading => {
    dispatch(setLoading(isLoading))
  },
  shareStatement: (planId, year, month) => {
    dispatch(
      showModal(INPUT_MODAL, {
        ...modal,
        fetchSendProvider: () =>
          fetch(`/api/accountplans/${planId}/invoices/${year}/${month}/mail`),
        onConfirm: () =>
          dispatch(
            showModal(CONFIRM_UPDATE, {
              modalTitle: 'Seu extrato foi enviado.',
              image
            })
          ),
        image
      })
    )
  },
  downloadCSVStatement: (planId, filter) =>
    dispatch(downloadStatementFileCSV(planId, filter)).finally(() => dispatch(setLoading(false)))
})

export default connect(null, mapDispatchToProps)(StatementsHeader)
