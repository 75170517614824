export default {
  __html: `<g fill="none" fill-rule="evenodd">
                <path d="M51.09,92A34.72,34.72,0,0,1,16.34,57.2a3.06,3.06,0,0,1,6.13,0,28.62,28.62,0,1,0,57.24,0,3.06,3.06,0,1,1,6.13,0A34.72,34.72,0,0,1,51.09,92Z" />
                <path d="M51.09,79.7A34.65,34.65,0,1,1,64.62,77,34.53,34.53,0,0,1,51.09,79.7Zm0-63.37a28.62,28.62,0,1,0,20.24,8.38A28.43,28.43,0,0,0,51.09,16.33Z" />
                <path d="M52.37,61.25H46.24a3.06,3.06,0,0,1,0-6.13h6.13a3.06,3.06,0,0,0,0-6.13h-2a9.19,9.19,0,1,1,0-18.38h6.13a3.06,3.06,0,1,1,0,6.13H50.32a3.06,3.06,0,1,0,0,6.13h2a9.19,9.19,0,0,1,0,18.38Z" />
                <path d="M51.35,36.74a3.06,3.06,0,0,1-3.06-3.06V26.54a3.06,3.06,0,1,1,6.13,0v7.13A3.06,3.06,0,0,1,51.35,36.74Z" />
                <path d="M51.15,67.36a3.06,3.06,0,0,1-3.06-3.06V58.2a3.06,3.06,0,0,1,6.13,0v6.1A3.06,3.06,0,0,1,51.15,67.36Z" />
                <rect width="102.13" height="102.13" style="fill:none"/>
            </g>`
}
