import React from 'react'
import { FieldArray, reduxForm } from 'redux-form'
import AddressFormOtherSection from '../AddressFormOtherSection'

const OtherAddressForm = props => {
  const {
    initialValues,
    handleSubmit,
    onDelete,
    onChange,
    disableButton,
    saving,
    history,
    loadingButton
  } = props
  return (
    <form onSubmit={handleSubmit}>
      <FieldArray
        name="address"
        component={AddressFormOtherSection}
        initialValues={initialValues.address}
        onDelete={onDelete}
        saving={saving}
        history={history}
        disableButton={disableButton}
        onChange={onChange}
        loadingButton={loadingButton}
      />
    </form>
  )
}

export default reduxForm({
  form: 'othertAddress',
  destroyOnUnmount: false,
  enableReinitialize: true
})(OtherAddressForm)
