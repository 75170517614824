import styled from 'styled-components'

import rem from '../../../styles/tools/rem'
import media from '../../../styles/tools/media'

import { txt14OpenSans, title40GalanoBold } from '../../../styles/settings/types'
import { hexToRgba } from '../../../utils/hexToRgba'

import { darkBlue, white, tealish, darkSlateBlue } from '../../../styles/settings/colors'

export const WrapperInstall = styled.div`
  margin: 50px 0 0;
`

export const TextInstall = styled.div`
  ${title40GalanoBold}
  color: ${({ textWhite }) => (textWhite ? `${white}` : `${darkSlateBlue}`)};
  letter-spacing: ${rem('0.4px')};
  font-size: ${rem('32px')};
  margin: ${rem('15px')} 0 ${rem('40px')} !important;

`

export const SubTitle = styled.div`
  ${title40GalanoBold}
  color: ${({ textWhite }) => (textWhite ? `${white}` : `${darkSlateBlue}`)};
  letter-spacing: ${rem('0.4px')};
  font-size: ${rem('28px')};
  margin: ${rem('15px')} 0 !important;
`

export const TextInfo = styled.p`
  ${txt14OpenSans};
  font-weight: bold;
  letter-spacing: normal;
  font-weight: normal;
  line-height: 1.57;
  color: ${darkSlateBlue};
  margin-top: ${rem('40px')};
  padding-bottom: ${rem('40px')};
`

export const List = styled.ul`
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0;
  margin-bottom: 60px;

  ${media.small`
    flex-direction: column;
  `};

  li {
    min-height: 160px;
    min-width: 296px;
    max-width: 296px;
    align-items: flex-start;
    flex-direction: column;
    justify-content: flex-end;
    padding: 15px;
    margin-right: 30px;
    &:last-child {
      margin-right: 0;
    }

    ${media.small`
      max-width: 100%;
      min-width: 100%;
      margin-bottom: 10px;
      padding-top: 0;
      padding-bottom: 0;
      margin-right: 0;
    `};

    &:hover {
      border: solid 2px ${tealish};
      box-shadow: 0 4px 12px 0 ${hexToRgba(tealish, 0.24)};

      svg {
        opacity: 1;
        filter: grayscale(0);
      }

      p {
        color: ${darkBlue};
      }
    }
  }

  p {
    font-size: 18px;
    margin: 0;
    line-height: 1.22;
    letter-spacing: normal;
    text-align: left;
    margin-top: 20px;
  }

  svg {
    opacity: 0.64;
    filter: grayscale(100%);
  }
`
