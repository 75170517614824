import styled from 'styled-components'
import { Link } from 'react-router-dom'

import { darkGrey, linkNavyBlue } from '../../../styles/settings/colors'

import { txt12OpenSansLight, txt14OpenSans } from '../../../styles/settings/types'

import rem from '../../../styles/tools/rem'
import media from '../../../styles/tools/media'

export const Header = styled.header`
  color: ${darkGrey};
  display: flex;
  justify-content: space-between;
  width: 100%;
`

export const Title = styled.h2`
  ${txt12OpenSansLight};
  color: ${darkGrey};
  letter-spacing: ${rem('1px')};
  margin: 13px 0 ${rem('22px')};
  text-transform: uppercase;
`

export const Text = styled(Link)`
  ${txt14OpenSans} color: ${linkNavyBlue};
  cursor: pointer;
  font-weight: 700;
  letter-spacing: ${rem('-0.4px')};
  margin: ${rem('10px')};
  text-decoration: none;
`

export const Span = styled.span`
  color: ${linkNavyBlue};
  padding-left: ${rem('9px')};
`

export const IdentifierList = styled.div`
  ${media.large`
        display: flex;
        margin-top: ${rem('5px')};
    `};
`

export const IdentifierItem = styled.div`
  flex: 1;

  ${media.large`
        :not(:last-child) {
            margin-right: ${rem('20px')};
        }
    `};
`

export const MobileText = styled.span`
  ${media.large`
        display: none;
    `};
`

export const DesktopText = styled.span`
  ${media.small`
        display: none;
    `};
`
