import React from 'react'
import { Box } from 'grid-styled'

import ImersiveHeader from '../../elements/ImersiveHeader'
import Content from '../../../styles/objects/Content'

const FirstAccessIdentifierNewAddress = () => (
  <Box>
    <div>
      <ImersiveHeader text="Novo endereço" url="/" />
    </div>

    <Content>
      <p>Pronto! Identificador novo a caminho. :)</p>
    </Content>
  </Box>
)

export default FirstAccessIdentifierNewAddress
