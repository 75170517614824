/* eslint-disable */
import React, { useState } from 'react'
import ClickOut from 'react-click-out'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'

import Dropdown from '../../elements/Dropdown'
import DropdownList from '../../elements/DropdownList'

import { Wrapper } from './styles'

function ChoosePlanDropdown(props) {
  const { accountPlans, history } = props

  const [isOpen, setIsOpen] = useState(false)

  const toggleDropdownList = () => {
    setIsOpen(!isOpen)
  }

  const closeDropdownList = () => {
    setIsOpen(false)
  }

  const goToPlanResume = () => {
    const planId = accountPlans.plans[0].id
    history.push(`/minha-veloe/${planId}/resumo`)
  }

  const activePlanId = accountPlans && accountPlans.activePlanId
  const planList = accountPlans && accountPlans.plans

  let activePlan = {}

  if (activePlanId) {
    activePlan = planList.find(plan => activePlanId == plan.id) || {}
  } else if (planList.length > 0) {
    activePlan = planList[0]
  }

  const activePlanData = activePlan.plan || {}
  const activePlanFullName = activePlanData.category

  const isSinglePlan = planList.length <= 1

  return (
    <Wrapper isSinglePlan={isSinglePlan}>
      <ClickOut callback={closeDropdownList}>
        <Dropdown
          isOpen={isOpen}
          categoryLabel={isOpen ? 'Planos' : 'Plano'}
          dropdownLabel={isOpen ? 'Todos os planos' : activePlanFullName}
          headerLabelCategory={'plan'}
          onClick={isSinglePlan ? goToPlanResume : toggleDropdownList}
        />

        {!isSinglePlan && (
          <DropdownList
            options={[
              ...planList.map(accountPlan => ({
                link: `/minha-veloe/${accountPlan.plan.id}/resumo`,
                title: accountPlan.plan.type,
                description: accountPlan.plan.category
              }))
            ]}
            isOpen={isOpen}
            onClose={toggleDropdownList}
          />
        )}
      </ClickOut>
    </Wrapper>
  )
}

ChoosePlanDropdown.defaultProps = {
  accountPlans: {}
}

const mapStateToProps = state => ({
  accountPlans: state.reducer.accountPlans
})

export default connect(mapStateToProps)(withRouter(ChoosePlanDropdown))
