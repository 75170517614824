import React from 'react'
import { Field, reduxForm } from 'redux-form'
import { renderRoundedInput } from '../../../../utils/renderInputs'
import IcSearch from '../../../elements/Icon/icSearch'
import { Form } from './styles'

export const searchFilter = (searchValue, list) => {
  if (searchValue && searchValue.length > 2) {
    let result
    result = list.filter(listItem => {
      if (
        listItem.date.includes(searchValue.toUpperCase()) ||
        listItem.number.includes(searchValue.toUpperCase()) ||
        listItem.status.toUpperCase().includes(searchValue.toUpperCase())
      ) {
        return listItem
      }
    })
    return result
  }
  return list
}

export const SearchForm = props => {
  return (
    <Form
      onFocus={() => {
        window.dataLayer.push({
          event: 'event',
          eventAction: 'busca:filtrar-pedido',
          eventCategory: 'veloe:area-logado:meus-pedidos',
          eventLabel: 'filtrar-pedidos'
        })
      }}
    >
      <Field
        name="search"
        id="search"
        component={renderRoundedInput}
        type="text"
        placeholder={props.placeholder}
        label={props.label}
        icon={IcSearch}
      />
    </Form>
  )
}

export default reduxForm({
  form: 'searchOrders'
})(SearchForm)
