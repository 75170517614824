// Bin List Updated 15/04/2020
export const binElo = [
  '650406',
  '651662',
  '650726',
  '650596',
  '655080',
  '650590',
  '650710',
  '655040',
  '650580',
  '650510',
  '650511',
  '650938',
  '650721',
  '650577',
  '509407',
  '506717',
  '650038',
  '650498',
  '506707',
  '506708',
  '506719',
  '509018',
  '509019',
  '509060',
  '509061',
  '509070',
  '509071',
  '509087',
  '509088',
  '509089',
  '655016',
  '655017',
  '509119',
  '505726',
  '651709',
  '651747',
  '655014',
  '655015',
  '650921',
  '650051',
  '650039',
  '650040',
  '650042',
  '650922',
  '509147',
  '509148',
  '509149',
  '506721',
  '650503',
  '650504',
  '650513',
  '650518',
  '650519',
  '650520',
  '650521',
  '650522',
  '650523',
  '650524',
  '650516',
  '655012',
  '650517',
  '506718',
  '506733',
  '509108',
  '506722',
  '506724',
  '506725',
  '506726',
  '506727',
  '506728',
  '506730',
  '506731',
  '506732',
  '506734',
  '506735',
  '506741',
  '506742',
  '506745',
  '506746',
  '506747',
  '506775',
  '506776',
  '506777',
  '506778',
  '509000',
  '509001',
  '509002',
  '509003',
  '509004',
  '509030',
  '509035',
  '509040',
  '509041',
  '509042',
  '509044',
  '509045',
  '509046',
  '509047',
  '509051',
  '509053',
  '509054',
  '509055',
  '509056',
  '509057',
  '509058',
  '509062',
  '509065',
  '509066',
  '509067',
  '509068',
  '509069',
  '509072',
  '509073',
  '636368',
  '627780',
  '509076',
  '509081',
  '509082',
  '509083',
  '509084',
  '509085',
  '509086',
  '509093',
  '509094',
  '509095',
  '509096',
  '509097',
  '509098',
  '509099',
  '655000',
  '655001',
  '651653',
  '650485',
  '650486',
  '650487',
  '650488',
  '651654',
  '650489',
  '650491',
  '655002',
  '650492',
  '650493',
  '655003',
  '650494',
  '650901',
  '650902',
  '650903',
  '650904',
  '650905',
  '650495',
  '651655',
  '655004',
  '650032',
  '650502',
  '650906',
  '650907',
  '650909',
  '650911',
  '650916',
  '650912',
  '650917',
  '650913',
  '650918',
  '650914',
  '650915',
  '650507',
  '655006',
  '506788',
  '651659',
  '650908',
  '506723',
  '650527',
  '650528',
  '650529',
  '650044',
  '650045',
  '650046',
  '509023',
  '755914',
  '755915',
  '755916',
  '755917',
  '755918',
  '755919',
  '650505',
  '509123',
  '509110',
  '655013',
  '655056',
  '655057',
  '650584',
  '650497',
  '655005',
  '651663',
  '509107',
  '650405',
  '651656',
  '509100',
  '650000',
  '650001',
  '655052',
  '650724',
  '650589',
  '655018',
  '650506',
  '650579',
  '650593',
  '651669',
  '650062',
  '650514',
  '650078',
  '650965',
  '650966',
  '650968',
  '650587',
  '650409',
  '650588',
  '650410',
  '650435',
  '650591',
  '506748',
  '650033',
  '650035',
  '650036',
  '650037',
  '650047',
  '650411',
  '650434',
  '650436',
  '650437',
  '650508',
  '650525',
  '650526',
  '650530',
  '650531',
  '650582',
  '650583',
  '650920',
  '650928',
  '650939',
  '650948',
  '650950',
  '650954',
  '650963',
  '650967',
  '650971',
  '651658',
  '651666',
  '651667',
  '651668',
  '651670',
  '651677',
  '651678',
  '651679',
  '655008',
  '650592',
  '655007',
  '650412',
  '655051',
  '651675',
  '509104',
  '655009',
  '651660',
  '650586',
  '651665',
  '650578',
  '650910',
  '506502',
  '509063',
  '655011',
  '650725',
  '645000',
  '645001',
  '645002',
  '654004',
  '654012',
  '654015',
  '654043',
  '654042',
  '654041',
  '654054',
  '654119',
  '654075',
  '654071',
  '654072',
  '651676',
  '650946',
  '650949',
  '509146',
  '509027',
  '808186',
  '509257',
  '650727',
  '650439',
  '506720',
  '615664',
  '506793',
  '509258',
  '650424',
  '650438',
  '650425',
  '436368',
  '650426',
  '650043',
  '200041',
  '509122',
  '506715',
  '655058',
  '650041',
  '650515',
  '509109',
  '506729',
  '506753',
  '509048',
  '509059',
  '504175',
  '509091',
  '651652',
  '650490',
  '650031',
  '654112',
  '665520',
  '675520',
  '685520',
  '695520',
  '645520',
  '635520',
  '625520',
  '615520',
  '635252',
  '650413',
  '509259',
  '509260',
  '657855',
  '655520',
  '850503',
  '850501',
  '850502',
  '850507',
  '850506',
  '850505',
  '850511',
  '850509',
  '850510',
  '850514',
  '850513',
  '850515',
  '650581'
]

export const binAlelo = [
  '509880',
  '509881',
  '509882',
  '509883',
  '509884',
  '509885',
  '509886',
  '509987',
  '509988',
  '509989',
  '509990',
  '509992',
  '506749',
  '506750',
  '506751',
  '506752'
]
