import React from 'react'

import { Container, ContainerContent, Title, Body } from './styles'

const WarningBase = ({ content, image }) => (
  <Container>
    <ContainerContent>
      <Title>Dicas para seguir tranquilo nas praças de pedágio ;)</Title>
      <Body>{content}</Body>
    </ContainerContent>
    {image}
  </Container>
)

export default WarningBase
