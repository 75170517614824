import styled from 'styled-components'
import content from '../../../styles/_objects/content'
import rem from '../../../styles/tools/rem'
import media from '../../../styles/tools/media'

import { darkGrey } from 'styles/settings/colors'

export const ListContainer = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;

  ${media.small`
        li:last-of-type {
            border-bottom: 0 !important;
        }
    `};
`

export const ListFooter = styled.div`
  ${content} display: flex;
  justify-content: flex-start;
  padding: ${rem('40px')} ${rem('30px')};

  ${media.small`
        justify-content: center;
        width: ${rem('180px')};
    `};
`

export const Label = styled.label`
  color: ${darkGrey};
  font-family: OpenSans;
  font-size: ${rem('12px')};
  font-weight: 200;
  letter-spacing: ${rem('0.4px')};
  line-height: 1;
  pointer-events: none;
  text-transform: uppercase;
  white-space: nowrap;
  display: block;
  margin-bottom: ${rem('15px')};

  ${props =>
    props.right &&
    `
      text-align: right;
      padding-right: 50px;
    `};

  ${props =>
    props.marginStatus &&
    `
        margin-left: 70px;
      `};
`
