import styled from 'styled-components'

import rem from 'styles/tools/rem'
import media from 'styles/tools/media'

import { linkNavyBlue, darkGrey, white } from 'styles/settings/colors'

import {
  txt14OpenSansBold,
  txt12OpenSansLight,
  txt16OpenSans,
  txt24OpenSansBold
} from 'styles/settings/types'

export const Wrapper = styled.section`
  width: 100%;
  border-radius: ${rem('6px')};
  background-color: ${white};
  padding: ${rem('22px')};
  box-shadow: 0 ${rem('8px')} ${rem('8px')} 0 rgba(30, 27, 91, 0.11);

  ${media.large`
        padding: ${rem('40px')} ${rem('40px')} ${rem('30px')};
        width: ${rem('335px')};
    `};
`

export const Text = styled.p`
  ${txt12OpenSansLight} letter-spacing: ${rem('1px')};
  color: ${darkGrey};
  margin: 0;
  margin-bottom: ${rem('5px')};
  text-transform: uppercase;
`

export const TextBold = styled.p`
  ${txt16OpenSans} font-weight: bold;
  color: ${linkNavyBlue};
  margin: 0;
`

export const UserImage = styled.img`
  width: ${rem('50px')};
  height: ${rem('50px')};
  border-radius: 100%;
  margin-right: ${rem('25px')};
`

export const Username = styled.p`
  ${txt16OpenSans} color: ${linkNavyBlue};
  font-weight: bold;
  margin: 0;

  ${media.large`
        ${txt24OpenSansBold}
    `};
`

export const EditPaymentMethod = styled.p`
  ${txt14OpenSansBold};

  line-height: 1;
  text-align: center;
  margin-top: ${rem('20px')};
  margin-bottom: 0;
  color: ${linkNavyBlue};
`
