import styled from 'styled-components'
import { Box, Flex } from 'grid-styled'

import { darkGrey, linkNavyBlue, offWhite, skyBlue, white } from '../../../styles/settings/colors'
import { txt12OpenSansBold, txt12OpenSansLight } from '../../../styles/settings/types'
import rem from '../../../styles/tools/rem'
import content from '../../../styles/_objects/content'

export const Content = styled.section`
  ${content};
`

export const GroupItems = styled.div`
  margin-bottom: ${rem('25px')};
`

export const Item = styled.div`
  position: relative;

  &:before {
    background-color: ${white};
    content: '';
    display: inline-block;
    height: 100%;
    position: absolute;
    width: calc(((100% - ${rem('1000px')}) / 2) + ((2 / 12) * ${rem('1000px')}));
  }

  &:nth-child(2n + 1) {
    background-color: ${offWhite};
  }
`

export const ItemRow = styled(Flex)`
  padding: 0;
`

export const ItemCell = styled(Box)`
  padding-bottom: ${rem('4px')};
  padding-top: ${rem('4px')};
`

export const ItemSpan = styled.div`
  ${txt12OpenSansLight};

  color: ${darkGrey};

  span {
    text-transform: uppercase;
  }
`

export const ItemSpanCenter = styled.div`
  ${txt12OpenSansLight};

  color: ${linkNavyBlue};
  text-align: center;
`

export const ItemStrong = styled.div`
  ${txt12OpenSansBold};

  color: ${linkNavyBlue};
`

export const ItemStrongCenter = styled.div`
  ${txt12OpenSansBold};

  color: ${linkNavyBlue};
  text-align: center;
`

export const ItemStrongBlue = styled.div`
  ${txt12OpenSansBold};

  color: ${skyBlue};
`

export const ItemStrongGrey = styled.div`
  ${txt12OpenSansBold};

  color: ${darkGrey};
`

export const Value = styled.div`
  ${txt12OpenSansBold};

  color: ${linkNavyBlue};
  text-align: right;
`

export const ValueBlue = styled.div`
  ${txt12OpenSansBold};

  color: ${skyBlue};
  text-align: right;
`
