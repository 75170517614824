import React from 'react'
import { string } from 'prop-types'

import { Field } from 'redux-form'

import Icon from '../Icon/Icon'

import { TypeOption, Input, Label } from './styles'

const renderFilterTypeOption = ({ input = {}, name, disabled }) => (
  <Input
    {...input}
    name={name}
    onChange={() => input.onChange(input.value)}
    value={input.value}
    type="radio"
    disabled={disabled}
  />
)

function ChooseFilterTypeOption(props) {
  const { filterType, filterIcon, name, value, disableOnSemiautomatico, isSemiautomatico } = props

  const disabled = isSemiautomatico && disableOnSemiautomatico

  return (
    <Label>
      <Field
        component={renderFilterTypeOption}
        name={name}
        type="radio"
        value={value}
        disabled={disabled}
      />
      <TypeOption>
        <Icon icon={filterIcon} width="25" height="25" viewBox="0 0 100 100" />
        <span>{filterType}</span>
      </TypeOption>
    </Label>
  )
}

ChooseFilterTypeOption.defaultProps = {
  filterType: null
}

ChooseFilterTypeOption.propTypes = {
  filterType: string.isRequired
}

export default ChooseFilterTypeOption
