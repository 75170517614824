import styled, { css } from 'styled-components'

import { darkGrey, linkNavyBlue } from 'styles/settings/colors'

import {
  title24GalanoBold,
  title18Galano,
  txt12OpenSans,
  txt14OpenSans
} from 'styles/settings/types'

import rem from 'styles/tools/rem'
import media from 'styles/tools/media'

export const Label = styled.p`
  ${txt12OpenSans} color: ${darkGrey};
  margin: 0 0 ${rem('8px')};
  text-transform: uppercase;
`

export const Text = styled.p`
  ${txt14OpenSans} color: ${darkGrey};
  margin: 0;

  ${({ highlighted }) =>
    highlighted &&
    css`
      ${title18Galano} color: ${linkNavyBlue};
      text-transform: uppercase;

      ${media.large`
            ${title24GalanoBold}
        `};
    `};
`
