/* eslint-disable */
export const VALIDATION_PHONE = /^1\d\d(\d\d)?$|^0800 ?\d{3} ?\d{4}$|^(\(0?([1-9a-zA-Z][0-9a-zA-Z])?[1-9]\d\) ?|0?([1-9a-zA-Z][0-9a-zA-Z])?[1-9]\d[ .-]?)?(9|9[ .-])?[2-9]\d{3}[ .-]?\d{4}$/
export const VALIDATION_DDD = /\(?(\d{2})\)?/
export const VALIDATION_EMAIL = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
export const VALIDATION_CEP = /^[0-9]{5}-?[0-9]{3}$/
export const VALIDATION_OLD_PLATE = /[a-z]{3}[0-9]{4}$/
export const VALIDATION_NEW_PLATE = /[a-z]{3}[0-9]{1}[a-z]{1}[0-9]{2}$/
export const VALIDATION_IDENTIFIER = /(^\d{10})$/

export const VALIDATION_CEP_MSG_EMPTY = 'O CEP é obrigatório.'
export const VALIDATION_CEP_MSG_INVALID = 'O CEP digitado é inválido.'
export const VALIDATION_CPF_MSG_EMPTY = 'O CPF é obrigatório.'
export const VALIDATION_CPF_MSG_INVALID = 'O CPF digitado é inválido.'
export const VALIDATION_CNPJ_MSG_EMPTY = 'O CNPJ é obrigatório.'
export const VALIDATION_CNPJ_MSG_INVALID = 'O CNPJ digitado é inválido.'
export const VALIDATION_EMAIL_MSG_EMPTY = 'O e-mail é obrigatório.'
export const VALIDATION_EMAIL_MSG_INVALID = 'O e-mail digitado é inválido.'
export const VALIDATION_PHONE_MSG_EMPTY = 'O telefone é obrigatório.'
export const VALIDATION_PHONE_MSG_INVALID = 'O telefone digitado é inválido.'
export const VALIDATION_DOB_MSG_EMPTY = 'A data de nascimento é obrigatória.'
export const VALIDATION_DOB_MSG_INVALID = 'A data de nascimento digitada é inválida.'
export const FORMAT_DATE = 'DD/MM/YYYY'
export const INVOICE_MONTH_FORMAT_DATE = 'MMMM/YYYY'
export const ISO_FORMAT_DATE = 'YYYY-MM-DDTHH:mm:ss.SSSZ'
export const FORMAT_TIME = 'HH:mm'
export const FORMAT_TIME_INVALID =
  'To format a time, a proper object should be present as a parameter.'
export const FORMAT_DATE_INVALID = 'Data inválida'
export const FORMAT_HOUR_MINUTE_INVALID = 'Horário inválido'
export const VALIDATION_DBT_MSG_INVALID = 'Data precisa ser menor que a data atual.'
export const FORMAT_DATE_MINIMUM_YEAR = '1900-01-01'
export const EMPTY_FIELD = 'Campo obrigatório'
export const VALIDATION_DDD_MSG_EMPTY = 'O DDD é obrigatório.'
export const VALIDATION_DDD_MSG_INVALID = 'O DDD digitado é inválido.'
export const VALIDATION_PLATE_MSG_EMPTY = 'A placa é obrigatório.'
export const VALIDATION_PLATE_MSG_INVALID = 'A placa informada é inválida.'
export const VALIDATION_IDENTIFIER_MSG_EMPTY = 'O produto é obrigatório'
export const VALIDATION_IDENTIFIER_BAND_MSG_EMPTY = 'O produto é obrigatório'
export const VALIDATION_IDENTIFIER_MSG_INVALID = 'O produto é inválido.'
export const VALIDATION_IDENTIFIER_BAND_MSG_INVALID = 'O produto é inválido.'
export const VALIDATION_IDENTIFIER_BAND_INVALID_NUMBER = 'Não é possível ativar esse acessório para motocicletas.'
export const VALIDATION_NICKNAME_MSG_EMPTY = 'Campo obrigatório.'
export const VALIDATION_NICKNAME_MSG_INVALID = 'O apelido deve ter ao menos 3 caracteres.'
