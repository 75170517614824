import { updateShippingAddress, getShippingAmount } from '../services/shipping'

import types from './types'

const { FETCH_SHIPPING_UPDATE_ADDRESS, FETCH_SHIPPING_AMOUNT } = types

export function fetchShippingUpdateAddress(data) {
  return dispatch =>
    updateShippingAddress(data).then(response => {
      dispatch({
        type: FETCH_SHIPPING_UPDATE_ADDRESS,
        state: false
      })

      return response
    })
}

export function fetchGetShippingAmount(data) {
  return dispatch =>
    getShippingAmount(data)
      .then(shippingAmount => {
        dispatch({
          type: FETCH_SHIPPING_AMOUNT,
          state: shippingAmount.amount
        })

        return shippingAmount
      })
      .catch(error => {
        console.log('Erro em #fetchGetShippingAmount()')
        console.log(error)
      })
}
