import { connect } from 'react-redux'
import Modal, { SimpleModal } from './Modal'

function mapStateToProps({ modal, reducer }) {
  const { user, planData, banks } = reducer
  const bank = banks.find(b => b.value === '001')
  return {
    isOpen: modal,
    userInfo: user,
    nextPaymentDate: planData.nextPaymentDate,
    convenio: bank && bank.convenio
  }
}

export { SimpleModal, mapStateToProps }
export default connect(mapStateToProps)(Modal)
