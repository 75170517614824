import types from '../actions/types'

const { SET_NEW_DATA_VALUE } = types

const initialState = {
  currentEditDataValue: {
    accountPlanId: '',
    recharge: '',
    payment: {
      paymentMethod: '',
      type: '',
      cardNumber: '',
      yourName: '',
      validad: '',
      securityCode: '',
      save: ''
    }
  }
}

function editPaymentReducer(state = initialState, action = {}) {
  switch (action.type) {
    case SET_NEW_DATA_VALUE: {
      return {
        ...state,
        paymentFormChange: action.data
      }
    }

    default:
      return state
  }
}

export default editPaymentReducer
