import React from 'react'
import { utils } from 'alelo-logic'
import InvoiceDetailsVehicle from '../../elements/InvoiceDetailsVehicle'
import InvoiceDetailsListHeaders from '../../elements/InvoiceDetailsListHeaders'

import { InvoiceTable } from './styles'

const { currency } = require('./data.json')

// function calcAmount(data) {
//   let amount = 0
//   data.forEach(el => {
//     if (el.type.caption !== benefits) amount += el.value
//   })
//   return amount
// }

// function groupData(data) {
//   const groupedData = utils.groupArray(data || [], item => item.vehicle.licensePlate)
//   const keys = Object.keys(groupedData)
//   return { keys, groupedData }
// }

// function renderInvoiceComplete(data) {
//   const group = groupData(data)
//   return group.keys.map(key => {
//     const amountVehicle = calcAmount(group.groupedData[key])
//     const conductor = group.groupedData[key][0].vehicle.conductor
//     return (
//       <InvoiceTable key={key}>
//         <InvoiceDetailsVehicle
//           amount={amountVehicle}
//           conductorName={conductor}
//           licensePlateNumber={key}
//         />
//         <InvoiceDetailsListHeaders data={group.groupedData[key]} />
//       </InvoiceTable>
//     )
//   })
// }

function renderInvoiceComplete(data) {
  return data.transacoes.map((item, i) => (
    <InvoiceTable key={i}>
      <InvoiceDetailsVehicle
        amount={utils.currencyParser(item.total, false, currency)}
        licensePlateNumber={item.placa}
        conductorName={item.condutor}
        transacao={item}
      />
      <InvoiceDetailsListHeaders data={item.transacoesAgrupadas} />
    </InvoiceTable>
  ))
}

const InvoiceComplete = ({ data }) => <div>{renderInvoiceComplete(data)}</div>

export default InvoiceComplete
