export default function creditCardValidadValidate(value) {
  if (!value) {
    return value
  }

  const onlyNums = value.replace(/[^\d]/g, '')
  const actualDate = new Date()
  const actualMonth = actualDate.getMonth() + 1
  const actualYear = actualDate.getFullYear() + ''
  const aux = actualYear.replace(/[^\d]/g, '')

  if (onlyNums.length <= 3) {
    return onlyNums.slice(0, 2) < 1 || onlyNums.slice(0, 2) > 12 ? 'Mês inválido.' : undefined
  } else {
    return onlyNums.slice(2, 4) < aux.slice(2, 4) ||
      (onlyNums.slice(2, 4) <= aux.slice(2, 4) && onlyNums.slice(0, 2) < actualMonth)
      ? 'Ano inválido.'
      : onlyNums.slice(0, 2) < 1 ||
        onlyNums.slice(0, 2) > 12 ||
        (onlyNums.slice(2, 4) < aux.slice(2, 4) && onlyNums.slice(0, 2) < actualMonth)
      ? 'Mês inválido.'
      : undefined
  }
}
