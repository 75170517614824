import React from 'react'
import { Route } from 'react-router-dom'

import TemplateLayout from '../app/templates/TemplateLayout'

import Home from '../app/pages/Home'

import EditUserData from '../app/pages/EditUserData'
import EditCompanyData from '../app/pages/EditCompanyData'
import EditContactData from '../app/pages/EditContactData'
import EditAddress from '../app/pages/EditAddress'
import EditCompanyAddress from '../app/pages/EditCompanyAddress'
import EditRepresentatives from '../app/pages/EditRepresentatives'
import EditAdminContact from '../app/pages/EditAdminContact'
import ChangePassword from '../app/pages/ChangePassword'
import CancelAccount from '../app/pages/CancelAccount'
import CanceledAccount from '../app/pages/CanceledAccount'

import firstAccess from './firstAccess'
import blockIdentifiers from './blockIdentifiers'
import editIdentifier from './editIdentifier'
import contestation from './contestation'
import recharge from './recharge'
import dashboard from './dashboard'
import authentication from './authentication'
import editPaymentMethod from './editPaymentMethod'
import editDueDate from './editDueDate'
import cancelPlan from './cancelPlan'
import followRequest from './followRequest'
import activation from './activation'
import editPaymentData from './editPaymentData'
import firstRecharge from './firstRecharge'

import ecRoute from './ec'

const inactiveRoutes = [
  {
    exact: true,
    path: '/',
    component: Home
  },
  {
    path: '/perfil',
    component: TemplateLayout,
    routes: [
      {
        path: '/perfil/conta-cancelada',
        component: CanceledAccount
      }
    ]
  },
  authentication,
  { component: Home }
]

export const routes = {
  pf: {
    active: [
      {
        exact: true,
        path: '/',
        component: Home
      },
      {
        path: '/perfil',
        component: TemplateLayout,
        routes: [
          {
            path: '/perfil/editar-dados',
            component: EditUserData
          },
          {
            path: '/perfil/editar-contatos',
            component: EditContactData
          },
          {
            path: '/perfil/editar-endereco',
            component: EditAddress
          },
          {
            path: '/perfil/alterar-senha',
            component: ChangePassword
          },
          {
            path: '/perfil/conta-cancelada',
            component: CanceledAccount
          }
        ]
      },
      {
        path: '/cancelar-conta',
        component: CancelAccount
      },
      firstAccess,
      followRequest,
      blockIdentifiers,
      editIdentifier,
      cancelPlan,
      editPaymentMethod,
      editDueDate,
      contestation,
      recharge,
      activation,
      editPaymentData,
      firstRecharge,
      dashboard,
      authentication
    ],
    inactive: inactiveRoutes
  },
  pj: {
    active: [
      {
        exact: true,
        path: '/',
        component: Home
      },
      {
        path: '/perfil',
        component: TemplateLayout,
        routes: [
          {
            path: '/perfil/editar-dados',
            component: EditCompanyData
          },
          {
            path: '/perfil/endereco-comercial',
            component: EditCompanyAddress
          },
          {
            path: '/perfil/representantes',
            component: EditRepresentatives
          },
          {
            path: '/perfil/contato-do-administrador',
            component: EditAdminContact
          },
          {
            path: '/perfil/alterar-senha',
            component: ChangePassword
          },
          {
            path: '/perfil/conta-cancelada',
            component: CanceledAccount
          }
        ]
      },
      {
        path: '/cancelar-conta',
        component: CancelAccount
      },
      firstAccess,
      followRequest,
      blockIdentifiers,
      editIdentifier,
      cancelPlan,
      editPaymentMethod,
      contestation,
      recharge,
      activation,
      editPaymentData,
      dashboard,
      authentication
    ],
    inactive: inactiveRoutes
  },
  ec: {
    active: ecRoute,
    inactive: inactiveRoutes
  }
}

export const RouteWithSubRoutes = route => (
  <Route
    exact={route.exact}
    path={route.path}
    render={props => <route.component {...props} routes={route.routes} profile={route.profile} />}
  />
)
