import { merge } from 'lodash'
import types from '../actions/types'
import { transformExtrato } from '../../alelo-logic/services/queries/transactions'

const { SET_STATEMENTS, UPDATE_STATEMENTS, RESET_STATEMENTS } = types

const initialState = {
  data: [],
  total: null,
  monthlyTotal: null,
  next: null,
  filter: {},
  updatedOn: null,
  hasNext: null
}

function dashboardStatementsReducer(state = initialState, action = {}) {
  switch (action.type) {
    case SET_STATEMENTS: {
      return {
        ...state,
        data: transformExtrato(action.data),
        total: action.total,
        monthlyTotal: action.monthlyTotal,
        filter: action.filter,
        next: action.next,
        updatedOn: action.updatedOn,
        hasNext: action.hasNext
      }
    }
    case UPDATE_STATEMENTS: {
      return {
        ...state,
        data: merge(transformExtrato([...state.data, ...action.data])),
        total: action.total,
        monthlyTotal: action.monthlyTotal,
        filter: action.filter,
        next: action.next,
        updatedOn: action.updatedOn,
        hasNext: action.hasNext
      }
    }
    case RESET_STATEMENTS: {
      return {
        ...state,
        data: [],
        filter: {},
        total: null,
        monthlyTotal: null,
        next: null,
        updatedOn: null,
        hasNext: null
      }
    }
    default:
      return state
  }
}

export default dashboardStatementsReducer
