import React, { useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter, Link } from 'react-router-dom'
import { fetchLogout } from 'alelo-logic/actions'
import Hide from '../../../styles/utilities/Hide'
import Icon from '../../elements/Icon/Icon'
import MobileMenu from '../../modules/MobileMenu'
import Dropdown from '../../elements/Dropdown'
import DropdownList from '../../elements/DropdownList'
import ClickOut from '../../elements/ClickOut'
import Modal from '../../elements/ModalBannerJaBaixouOApp'

import ChoosePlanDropdown from '../../modules/ChoosePlanDropdown'

import {
  Avatar,
  HeaderWrapper,
  HeaderContainer,
  FlexContainer,
  Logo,
  VerticalSpacer,
  UserInfoContainer,
  UserDropdownInfo
} from './styles'

import avatarPj from '../../../static/avatars/avatar-pj.png'
import bannerJaBaixou from '../../../static/banner-ja-baixou-o-app.png'
import { useToggle } from 'hooks/useToggle'
const { headerMenuLinks } = require('./data.json')

function Header({ userCancelled, avatar, account, doLogout, history, accountPlans }) {
  const [isUserDropdownOpen, toggleUserDropdown, resetUserDropdownState] = useToggle(false)
  const [isMobileMenuOpen, toggleMobileMenu, resetMobileMenuState] = useToggle(false)

  const avatarImage = useMemo(() => {
    const avatarList = [
      require('../../../static/avatars/avatar-1.png').default,
      require('../../../static/avatars/avatar-2.png').default,
      require('../../../static/avatars/avatar-3.png').default,
      require('../../../static/avatars/avatar-4.png').default,
      require('../../../static/avatars/avatar-5.png').default,
      require('../../../static/avatars/avatar-6.png').default,
      require('../../../static/avatars/avatar-7.png').default,
      require('../../../static/avatars/avatar-8.png').default
    ]
    console.log(avatarList[avatar])
    return avatarList[avatar]
  }, [avatar])

  const logout = useCallback(() => {
    history.push('/conta/login')
    doLogout()
  }, [history, doLogout])

  const headerMenuOptions = useMemo(() => {
    const menu = headerMenuLinks[account.accountType] || []
    const filterLabel = account.active ? 'active' : 'canceled'

    return menu
      .map(item => (item.isLogout ? { ...item, onClick: logout } : item))
      .filter(
        item =>
          !item.exclude || (Array.isArray(item.exclude) && !item.exclude.includes(filterLabel))
      )
  }, [headerMenuLinks, account, logout])

  const accountTypeLabel = account.accountType === 'pf' ? 'Pessoal' : 'Empresarial'
  const resumeLink = `/minha-veloe${
    accountPlans.activePlanId ? `/${accountPlans.activePlanId}` : ''
  }/resumo`

  return (
    <HeaderWrapper>
      <HeaderContainer>
        <FlexContainer>
          <Link to={resumeLink}>
            <Icon icon="veloe-logo" svgStyle={Logo} width="74" height="31" viewBox="0 0 74 31" />
          </Link>

          <Hide at="large">
            {isMobileMenuOpen ? (
              <a href="#a" onClick={toggleMobileMenu}>
                <Icon icon="close" svgStyle="height: 15px; width: 22px" viewBox="0 0 14 14" />
              </a>
            ) : (
              <a href="#a" onClick={toggleMobileMenu}>
                <Icon icon="hamburger" width="22" height="15" viewBox="0 0 22 15" />
              </a>
            )}
          </Hide>

          <Hide at="small">
            <ChoosePlanDropdown />
          </Hide>
        </FlexContainer>
        <Hide at="large">
          <MobileMenu
            avatar={avatarImage}
            isOpen={isMobileMenuOpen}
            menuOptions={headerMenuOptions}
            categoryLabel={accountTypeLabel}
            username={account.name}
            headerLabelCategory="profile"
            onClose={resetMobileMenuState}
            userCancelled={userCancelled}
          />
        </Hide>
        <Hide at="small">
          <FlexContainer>
            {/* <HelpLink href="#">Ajuda</HelpLink> */}
            <VerticalSpacer />
            <UserInfoContainer>
              <ClickOut callback={resetUserDropdownState}>
                <UserDropdownInfo isOpen={isUserDropdownOpen}>
                  <Avatar src={account.accountType === 'pj' ? avatarPj : avatarImage} />
                  <Dropdown
                    categoryLabel={accountTypeLabel}
                    dropdownLabel={account.name}
                    headerLabelCategory="profile"
                    onClick={toggleUserDropdown}
                  />
                </UserDropdownInfo>
                <DropdownList
                  options={headerMenuOptions}
                  isOpen={isUserDropdownOpen}
                  onClose={resetUserDropdownState}
                  userCancelled={userCancelled}
                />
              </ClickOut>
            </UserInfoContainer>
          </FlexContainer>
        </Hide>
      </HeaderContainer>
      <Modal image={bannerJaBaixou} />
    </HeaderWrapper>
  )
}

Header.defaultProps = {
  account: {},
  userCancelled: false
}

Header.propTypes = {
  account: PropTypes.shape({
    name: PropTypes.string,
    accountType: PropTypes.string
  }),
  userCancelled: PropTypes.bool
}

const mapStateToProps = state => ({
  user: state.reducer.user.profile,
  avatar: state.header.avatar,
  account: state.reducer.account,
  accountPlans: state.reducer.accountPlans
})

const mapDispatchToProps = dispatch => ({
  doLogout: () => {
    // eslint-disable-next-line handle-callback-err
    dispatch(fetchLogout()).catch(error => {
      console.log('erro no logout')
    })
  }
})

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Header))
