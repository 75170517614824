import React from 'react'
import PropTypes from 'prop-types'

import { Wrapper, ButtonField, FormButtonField, BtnIcon } from './styles'

function Button({ disabled, text, href, onClick, buttonType, type, width, id, loading, ...props }) {
  const handleDisabled = e => {
    e.preventDefault()
  }

  const handleClick = e => {
    e.preventDefault()

    if (onClick) {
      onClick()
    }
  }

  return (
    <Wrapper width={width} disabled={disabled} buttonType={buttonType}>
      {type && type === 'submit' ? (
        <FormButtonField disabled={disabled} type={type} loading={loading} {...props}>
          {text}
        </FormButtonField>
      ) : (
        <ButtonField
          id={id}
          disabled={disabled}
          href={href}
          onClick={disabled ? handleDisabled : handleClick}
          loading={loading}
          {...props}
        >
          {props.icon && <BtnIcon alt="" src={props.icon} />}
          {text}
        </ButtonField>
      )}
    </Wrapper>
  )
}

Button.defaultProps = {
  disabled: null,
  buttonType: '',
  text: null,
  href: null,
  onClick: null,
  loading: false
}

Button.propTypes = {
  disabled: PropTypes.bool,
  buttonType: PropTypes.string,
  text: PropTypes.string.isRequired,
  href: PropTypes.string,
  onClick: PropTypes.func,
  loading: PropTypes.bool
}

export default Button
