import { injectGlobal } from 'styled-components'

import OpenSansSemibolditalicWoff from '../../static/fonts/opensans-semibolditalic-webfont.woff2'
import OpenSansSemibolditalicWoff2 from '../../static/fonts/opensans-semibolditalic-webfont.woff'
import OpenSansSemiboldWoff from '../../static/fonts/opensans-semibold-webfont.woff2'
import OpenSansSemiboldWoff2 from '../../static/fonts/opensans-semibold-webfont.woff'
import OpenSansRegularWoff from '../../static/fonts/opensans-regular-webfont.woff2'
import OpenSansRegularWoff2 from '../../static/fonts/opensans-regular-webfont.woff'
import OpenSansLightitalicWoff from '../../static/fonts/opensans-lightitalic-webfont.woff2'
import OpenSansLightitalicWoff2 from '../../static/fonts/opensans-lightitalic-webfont.woff'
import OpenSansLightWoff from '../../static/fonts/opensans-light-webfont.woff2'
import OpenSansLightWoff2 from '../../static/fonts/opensans-light-webfont.woff'
import OpenSansItalicWoff from '../../static/fonts/opensans-italic-webfont.woff2'
import OpenSansItalicWoff2 from '../../static/fonts/opensans-italic-webfont.woff'
import OpenSansExtrabolditalicWoff from '../../static/fonts/opensans-extrabolditalic-webfont.woff2'
import OpenSansExtrabolditalicWoff2 from '../../static/fonts/opensans-extrabolditalic-webfont.woff'
import OpenSansExtraboldWoff from '../../static/fonts/opensans-extrabold-webfont.woff2'
import OpenSansExtraboldWoff2 from '../../static/fonts/opensans-extrabold-webfont.woff'
import OpenSansBolditalicWoff from '../../static/fonts/opensans-bolditalic-webfont.woff2'
import OpenSansBolditalicWoff2 from '../../static/fonts/opensans-bolditalic-webfont.woff'
import OpenSansBoldWoff from '../../static/fonts/opensans-bold-webfont.woff2'
import OpenSansBoldWoff2 from '../../static/fonts/opensans-bold-webfont.woff'

import GalanoGrotesqueBoldWoff from '../../static/fonts/galano-grotesque-alt-bold.woff'

injectGlobal`
    @font-face {
        font-family: Galano;
        font-style: bold;
        font-weight: 600;

        src: url(${GalanoGrotesqueBoldWoff}) format('woff');
    }
    @font-face {
        font-family: OpenSans;
        font-style: italic;
        font-weight: 600;

        src: url(${OpenSansSemibolditalicWoff}) format('woff2'),
        url(${OpenSansSemibolditalicWoff2}) format('woff');
    }

    @font-face {
        font-family: OpenSans;
        font-style: normal;
        font-weight: 600;

        src: url(${OpenSansSemiboldWoff}) format('woff2'),
        url(${OpenSansSemiboldWoff2}) format('woff');
    }

    @font-face {
        font-family: OpenSans;
        font-style: normal;
        font-weight: 400;

        src: url(${OpenSansRegularWoff}) format('woff2'),
        url(${OpenSansRegularWoff2}) format('woff');
    }

    @font-face {
        font-family: OpenSans;
        font-style: italic;
        font-weight: 300;

        src: url(${OpenSansLightitalicWoff}) format('woff2'),
        url(${OpenSansLightitalicWoff2}) format('woff');
    }

    @font-face {
        font-family: OpenSans;
        font-style: normal;
        font-weight: 300;

        src: url(${OpenSansLightWoff}) format('woff2'),
        url(${OpenSansLightWoff2}) format('woff');
    }

    @font-face {
        font-family: OpenSans;
        font-style: italic;
        font-weight: 400;

        src: url(${OpenSansItalicWoff}) format('woff2'),
        url(${OpenSansItalicWoff2}) format('woff');
    }

    @font-face {
        font-family: OpenSans;
        font-style: italic;
        font-weight: 800;

        src: url(${OpenSansExtrabolditalicWoff}) format('woff2'),
        url(${OpenSansExtrabolditalicWoff2}) format('woff');
    }

    @font-face {
        font-family: OpenSans;
        font-style: normal;
        font-weight: 800;

        src: url(${OpenSansExtraboldWoff}) format('woff2'),
        url(${OpenSansExtraboldWoff2}) format('woff');
    }

    @font-face {
        font-family: OpenSans;
        font-style: italic;
        font-weight: 700;

        src: url(${OpenSansBolditalicWoff}) format('woff2'),
        url(${OpenSansBolditalicWoff2}) format('woff');
    }

    @font-face {
        font-family: OpenSans;
        font-style: normal;
        font-weight: 700;

        src: url(${OpenSansBoldWoff}) format('woff2'),
        url(${OpenSansBoldWoff2}) format('woff');
    }

`
