import { updateUserProfile } from '../services/userService'

import types from './types'

const { FETCH_PROFILE_UPDATE } = types

function fetchUpdateProfile(data) {
  return dispatch =>
    updateUserProfile(data).then(response => {
      dispatch({
        type: FETCH_PROFILE_UPDATE,
        state: data.user
      })
    })
}

export default fetchUpdateProfile
