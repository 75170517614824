import types from '../actions/types'

const { FETCH_SUBSIDY_MESSAGE } = types

function subsidyMessageReducer(state = [], action = {}) {
  switch (action.type) {
    case FETCH_SUBSIDY_MESSAGE: {
      return action.subsidyMessage
    }
    default:
      return state
  }
}

export default subsidyMessageReducer
