import styled from 'styled-components'

export const Form = styled.form`
  width: 335px;
  position: relative;
  img {
    position: absolute;
    right: 13px;
    top: 35px;
    width: 25px;
    height: 25px;
  }
  input {
    padding-left: 45px;
    box-sizing: border-box;
  }
`
