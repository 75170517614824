/**
 * SERVIÇOS LEGADO. Certificar que não são mais utilizados
 */

import {
  dynamicAPI,
  // USER_PROFILE,
  // USER_ADDRESS,
  BUSINESS_USER_REPRESENTATIVE,
  // BUSINESS_USER,
  // BUSINESS_USER_PROFILE,
  // BUSINESS_USER_CONTACTS,
  // BUSINESS_USER_ADDRESS,
  VERIFY_USER_PASSWORD
} from './common/constants'

import fetchHandler from './common/fetchHandler'
// export * from '../services/userService'

// export function getUser(userToken) {
//   return fetchHandler(dynamicAPI + INDIVIDUAL_USER, {
//     method: 'GET'
//   })
// }

/*
export function updateUserProfile(data) {
  return fetchHandler(dynamicAPI + USER_PROFILE, {
    method: 'POST',
    body: JSON.stringify(data)
  })
}
*/

// export function updateUserContact(data) {
//   return fetchHandler(dynamicAPI + USER_CONTACTS, {
//     method: 'POST',
//     body: JSON.stringify(data)
//   })
// }

/*
export function updateUserAddress(data) {
  const uri = dynamicAPI + USER_ADDRESS

  return fetchHandler(uri, {
    method: 'POST',
    body: JSON.stringify(data)
  })
}
*/

/*
export function deleteUserAddress(id) {
  return fetchHandler(`${dynamicAPI + USER_ADDRESS}/${id}`, {
    method: 'DELETE'
  })
}
*/

/* export function updateUserPassword(data) {
  return fetchHandler(dynamicAPI + USER_CREDENTIALS, {
    method: 'POST',
    body: JSON.stringify(data)
  })
} */

export function createRepresentative(data) {
  return fetchHandler(dynamicAPI + BUSINESS_USER_REPRESENTATIVE, {
    method: 'PUT',
    body: JSON.stringify(data)
  })
}

export function updateBusinessUserRepresentative(data) {
  return fetchHandler(`${dynamicAPI + BUSINESS_USER_REPRESENTATIVE}/${data.document}`, {
    method: 'POST',
    body: JSON.stringify(data)
  })
}

export function deleteRepresentative(id) {
  return fetchHandler(`${dynamicAPI + BUSINESS_USER_REPRESENTATIVE}/delete/${id}`, {
    method: 'POST'
  })
}

// export function getBusinessUser(userToken) {
//   return fetchHandler(dynamicAPI + BUSINESS_USER, {
//     method: 'GET'
//   })
// }

// export function updateBusinessUserProfile(data) {
//   return fetchHandler(dynamicAPI + BUSINESS_USER_PROFILE, {
//     method: 'POST',
//     body: JSON.stringify(data)
//   })
// }

// export function updateBusinessUserContact(data) {
//   return fetchHandler(dynamicAPI + BUSINESS_USER_CONTACTS, {
//     method: 'POST',
//     body: JSON.stringify(data)
//   })
// }

/*
export function updateBusinessUserAddress(data) {
  return fetchHandler(dynamicAPI + BUSINESS_USER_ADDRESS, {
    method: 'POST',
    body: JSON.stringify(data)
  })
}
*/

/*
export function deleteBusinessUserAddress(id) {
  return fetchHandler(`${dynamicAPI + BUSINESS_USER_ADDRESS}/${id}`, {
    method: 'DELETE'
  })
}
*/

export function verifyUserPassword(data) {
  return fetchHandler(dynamicAPI + VERIFY_USER_PASSWORD, {
    method: 'POST',
    body: JSON.stringify(data)
  })
}
