import styled from 'styled-components'
import media from '../../../styles/tools/media'
import rem from 'styles/tools/rem'
import { Box } from 'grid-styled'

import { paleGrayTwo, blueGray } from 'styles/settings/colors'

import { txt12OpenSansBold } from 'styles/settings/types'

export const ListContainer = styled.ul`
  list-style: none;
  margin: 15px 0 0;
  padding: 0;

  ${media.small`
        li:last-of-type {
            border-bottom: 0 !important;
        }
    `};
`

export const ListItem = styled.div`
  display: block;
  padding: ${rem('18px')} 0 ${rem('18px')};
  border-bottom: ${rem('1px')} solid ${paleGrayTwo};
  ${txt12OpenSansBold};
  line-height: 1.67;
  letter-spacing: 1.6px;
  text-align: left;
  color: ${blueGray};
  text-transform: uppercase;

  ${media.small`
    width: 100%;
    padding: ${rem('13px')} 0;
    margin: 0;
  `};
`

export const DropdownBox = styled(Box)`
  position: relative;
`
