import { combineReducers } from 'redux'
import { reducers } from 'alelo-logic'
import formStateReducer from './reducers/formStateReducer'

const parsedReducers = {}
const reducersKeys = Object.keys(reducers)

reducersKeys.forEach(reducerKey => {
  const parsedKey = reducerKey.replace('Reducer', '')
  parsedReducers[parsedKey] = reducers[reducerKey]
})

// Reducers específicos do WEBSITE
parsedReducers.formState = formStateReducer

const reducer = combineReducers(parsedReducers)

export default reducer
