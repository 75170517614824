import { getRecharges } from '../services/dashboardService'

import types from './types'

const { UPDATE_RECHARGES, RESET_RECHARGES } = types

export function fetchDashboardRecharges(planId, next) {
  return dispatch =>
    getRecharges(planId, next).then(recharges => {
      dispatch({
        type: UPDATE_RECHARGES,
        data: recharges.data,
        next: recharges.next
      })
    })
}

export function resetDashboardRecharges() {
  return dispatch =>
    dispatch({
      type: RESET_RECHARGES
    })
}
