import React, { Component } from 'react'
import { connect } from 'react-redux'
import { reduxForm, getFormValues, Field } from 'redux-form'
import moment from 'moment'
import { Flex, Box } from 'grid-styled'
import { Icon } from '../../elements/Icon'
import Button from '../../elements/Button'
import RoundedCheck from '../../elements/RoundedCheck'
import rem from '../../../styles/tools/rem'
import { required } from '../../../utils/validators'

import { Buttons, Title, FormField, Legend, DateLabel } from './styles'
const { labels } = require('./data.json')

class DueDateForm extends Component {
  componentDidMount() {
    this.props.initialize({ dataFatura: '15' })
  }

  render() {
    const {
      handleSubmit,
      onSubmit,
      paymentInfo,
      onSucessUpdate,
      reset,
      dueDates,
      formValues,
      ableToChangeDueDate,
      ...props
    } = this.props

    const nextDueDates = formValues
      ? dueDates.find(dueDate => dueDate.dia === parseInt(formValues.dataFatura))
      : []
    return (
      <div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormField disabled={!ableToChangeDueDate}>
            <Title>Selecione uma nova data para o vencimento de sua fatura</Title>
            <Box width={[1]}>
              <Flex direction={['column', 'row']}>
                {dueDates.map(date => (
                  <Box key={date.dia} mb={[12, 28]} ml={8} mr={8} width={[1, 56]}>
                    <Field
                      component={RoundedCheck}
                      label={date.dia.toString()}
                      name="dataFatura"
                      type="radio"
                      value={date.dia.toString()}
                      validate={required}
                    />
                  </Box>
                ))}
              </Flex>
            </Box>
            <Box width={[1, 382]}>
              <Legend>Veja como fica o vencimento das suas próximas faturas:</Legend>
              <Flex direction={['column']}>
                {nextDueDates?.proximasFaturas &&
                  nextDueDates?.proximasFaturas.map(date => (
                    <Box key={date} mb={[12, 28]} width={[1, 290]}>
                      <Flex direction={['row']} align="center">
                        <Icon icon="calendar" width="24" height="24" viewBox="0 0 100 100" />
                        <DateLabel>{moment(date, 'DD/MM/YYYY').format('DD [de] MMMM')}</DateLabel>
                      </Flex>
                    </Box>
                  ))}
              </Flex>
            </Box>
          </FormField>
          <Buttons>
            <Box>
              <Button
                onClick={props.handleBackClick}
                text={labels.buttons.back}
                buttonType="white"
                width={rem('220px')}
              />
            </Box>
            <Box mt={[15, 0]} ml={[12, 60]}>
              <Button
                text={props.submitting ? labels.buttons.saving : labels.buttons.save}
                type={formValues && 'submit'}
                onClick={() => {
                  window.dataLayer = window.dataLayer || []
                  window.dataLayer.push({
                    event: 'event',
                    eventCategory: 'veloe:area-logado:dados-plano',
                    eventLabel: 'alteracao:[[sucesso]]',
                    dimension29: `[[${formValues.dataFatura}]]`
                  })
                }}
                width={rem('220px')}
                disabled={props.invalid || props.submitting || !ableToChangeDueDate}
              />
            </Box>
          </Buttons>
        </form>
      </div>
    )
  }
}

const Container = connect(state => ({
  formValues: getFormValues('DueDateForm')(state)
}))

const Form = reduxForm({
  form: 'DueDateForm'
})(Container(DueDateForm))

export default Form
