import styled from 'styled-components'

import rem from '../../../styles/tools/rem'

export const BarContainer = styled.div`
  height: ${({ height }) => rem(height || '16px')};
  border-radius: 78px;
  overflow: hidden;
  display: flex;
  background-color: #f1f1f7;
  width: 100%;
`

export const BarSlice = styled.div`
  height: 100%;
  width: ${props => props.width};
  ${({ color }) => `background-color: ${color};`};
`
