import styled from 'styled-components'

import rem from '../../../styles/tools/rem'
import { BottomMessage } from '../TextInput/styles'

export const Content = styled.div`
  position: relative;
`

export const Wrapper = styled.div`
  position: relative;

  ${props =>
    props.isSecurity &&
    `
        input {
            line-height: ${rem('0.83px')};
            letter-spacing: ${rem('5.5px')};
        }
        input::placeholder {
            letter-spacing: ${rem('-0.6px')};
        }
    `};
`

export const ShowPassword = styled.div`
  cursor: pointer;
  position: absolute;
  right: ${rem('17px')};
  bottom: ${rem('16px')};
  z-index: 1;
`

export const SecurityLevel = BottomMessage
