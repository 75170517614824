import React, { Component } from 'react'
import _ from 'lodash'
import { connect } from 'react-redux'
import Helmet from 'react-helmet'
import {
  fetchLogout,
  fetchGetUser,
  fetchGetBusinessUser,
  fetchNewIdentifier
} from 'alelo-logic/actions'
import { Box, Flex } from 'grid-styled'
import Button from 'app/elements/Button'

import {
  Heading,
  Paragraph,
  Content,
  WrapperButtons,
  BoxHeader,
  // SearchBox,
  Row,
  Subtitle,
  BoxCep,
  CepText,
  CheckBoxCep,
  NewAddress,
  // WrapperAddress,
  WrapperSelectNewAddress,
  NewAddressText,
  WrapperFormNewAddress,
  WrapperRadios
} from './styles'
import { setLoading } from '../../modules/PageLoader/actions'

import IdentifierQuantityForm from '../../modules/IdentifierQuantityForm'
import OtherAddressForm from '../../modules/OtherAddressForm'
import RequestIdentifierModal from '../../modules/RequestIdentifierModal'
import { cepMask } from '../../../utils/masks'
import ExisteTagsModal from 'app/modules/ExisteItemModal'

import { showModal } from '../../modules/ModalRoot/actions'
import image from '../../../static/contestacao.png'
import { CONFIRM_UPDATE } from '../../../constants/ModalTypes'

const {
  helmetTitle,
  identifiersTitle,
  identifiersDescription,
  quantityDescription,
  adesaoDescriptionOne,
  adesaoDescriptionTwo,

  // identifiersQuantity,
  addressDescription,
  // btnFollow,
  // btnActivation,
  // btnCancel
  titleModalError,
  orderLimitMessageError,
  orderStatusMessageError,
  orderLimitTitleModalError,
  orderStatusTitleModalError,
  orderLimitDescription,
  orderStatusDescription
} = require('./data.json')

class DashboardIdentifiersRequests extends Component {
  // constructor(props) {
  //   super(props)
  // }

  state = {
    addressSelected: 'actual',
    address: {
      bairro: '',
      cep: '',
      cidade: '',
      codigo: '',
      complemento: '',
      correspondencia: '',
      logradouro: '',
      numero: '',
      uf: ''
    },
    disableButton: false,
    accountId: '',
    quantity: '1',
    loading: false
  }

  async componentDidMount() {
    this.props.getUser(this.props.account.accountType, this.props.history)
  }

  changeCheckBox = evt => {
    this.setState({ addressSelected: evt.target.value })
  }

  handleSaveQuantity = evt => {
    if (evt > 0) {
      this.setState({ quantity: evt, disableButton: false })
    } else {
      this.setState({ quantity: evt, disableButton: true })
    }
  }

  handleSaveAddress = evt => {
    this.setState({ saveAddress: evt })
  }

  handleSave(isSemiautomatico) {
    this.setState({ loading: true, disableButton: true })
    const { history, match, accountPlans } = this.props
    const { plans, activePlanId } = accountPlans
    const { saveAddress, address, addressSelected, quantity } = this.state
    const currentPlan = plans.find(plan => String(plan.id) === String(activePlanId)) || { plan: {} }

    let tipo
    let endereco

    if (currentPlan.plan.type) {
      tipo = currentPlan.plan.type.toUpperCase()
    }

    if (addressSelected === 'actual' && address) {
      endereco = address
    } else if (addressSelected === 'new' && saveAddress && saveAddress[0]) {
      endereco = {
        bairro: saveAddress[0].neighborhood,
        cep: saveAddress[0].zipCode.replace(/-/g, ''),
        cidade: saveAddress[0].city,
        complemento: saveAddress[0].additionalInfo,
        correspondencia: saveAddress[0].mail,
        logradouro: saveAddress[0].address,
        numero: saveAddress[0].number,
        uf: saveAddress[0].state
      }
    }
    let data = {
      codigoConta: match.params.id,
      quantidadeIdentificadores: quantity,
      tipoIdentificador: isSemiautomatico ? 'BAND' : 'TAG',
      endereco,
      valorAdesao: currentPlan.valorAdesao,
      cobrar: currentPlan.cobrar,
      entregarIdentificador: currentPlan.entregarIdentificador,
      tipoPlano: tipo
    }

    this.props
      .newIdentifier(data, history)
      .then(response => {
        if (
          response.data !== undefined &&
          response.data.identificadorAdquirir !== undefined &&
          response.data.identificadorAdquirir.result !== undefined &&
          response.data.identificadorAdquirir.result === '409'
        ) {
          this.setState({ loading: false, idOrder: null, modal: false, modalExiste: true })
        } else if (
          response.data &&
          response.data.identificadorAdquirir &&
          response.data.identificadorAdquirir.error &&
          response.data.identificadorAdquirir.error[0] &&
          response.data.identificadorAdquirir.error[0].message.includes(orderLimitMessageError)
        ) {
          this.props.dispatch(
            showModal(CONFIRM_UPDATE, {
              modalTitle: orderLimitTitleModalError,
              modalDescription: orderLimitDescription,
              image
            })
          )
          this.setState({ loading: false, disableButton: false })
        } else if (
          response.data &&
          response.data.identificadorAdquirir &&
          response.data.identificadorAdquirir.error &&
          response.data.identificadorAdquirir.error[0] &&
          response.data.identificadorAdquirir.error[0].message.includes(orderStatusMessageError)
        ) {
          this.props.dispatch(
            showModal(CONFIRM_UPDATE, {
              modalTitle: orderStatusTitleModalError,
              modalDescription: orderStatusDescription,
              image
            })
          )
          this.setState({ loading: false, disableButton: false })
        } else {
          this.setState({
            loading: false,
            idOrder: response.result,
            modal: true,
            modalExiste: false
          })
        }
      })
      .catch(error => {
        if (error) {
          this.props.dispatch(showModal(CONFIRM_UPDATE, { modalTitle: titleModalError, image }))
          this.setState({ loading: false, disableButton: false })
        }
      })
  }

  disableButton() {
    const { disableButton } = this.state
    const {
      form: { othertAddress }
    } = this.props

    if (othertAddress && !othertAddress.syncErrors) {
      if (!disableButton) {
        return false
      } else {
        return true
      }
    } else {
      return true
    }
  }

  handleClick = async () => {
    this.setState({ modal: false })
    this.props.history.push(`/minha-veloe/${this.props.match.params.id}/identificadores`)
  }

  handleExisteClick = async () => {
    this.setState({ modalExiste: false })
    this.props.history.push(
      `/minha-veloe/${this.props.match.params.id}/identificadores/acompanhar-pedido`
    )
  }

  mensagemAdesao = () => {
    const { accountPlans } = this.props
    const { plans, activePlanId } = accountPlans
    const currentPlan = plans.find(plan => String(plan.id) === String(activePlanId)) || { plan: {} }

    let valorAdesaoNumber = Number(currentPlan.valorAdesao)

    if (currentPlan.cobrar) {
      return (
        <Paragraph>
          {adesaoDescriptionOne}{' '}
          {valorAdesaoNumber.toLocaleString('pt-br', { minimumFractionDigits: 2 })}{' '}
          {adesaoDescriptionTwo}
        </Paragraph>
      )
    } else {
      return <Paragraph>{identifiersDescription}</Paragraph>
    }
  }

  render() {
    const {
      history,
      match,
      handleDelete,
      user: { enderecos },
      accountPlans
    } = this.props
    const {
      loading,
      addressSelected,
      disableButton,
      idOrder,
      modal,
      quantity,
      modalExiste
    } = this.state
    this.state.address = enderecos && enderecos[0]
    // this.setTipoPlano()

    const { plans, activePlanId } = accountPlans

    const currentPlan = plans.find(plan => String(plan.id) === String(activePlanId)) || { plan: {} }

    // SEMIAUTOMATICO
    const { identifiers = [] } = currentPlan
    const identifiersTypes = _.map(identifiers, 'identifierType')
    const semiautomaticoTypes = ['BAND', 'SMART_BAND']
    const isSemiautomatico = _.some(semiautomaticoTypes, t => _.includes(identifiersTypes, t))

    // if (isSemiautomatico) {
    //   this.props.history.push(`/minha-veloe/${this.props.match.params.id}/identificadores`)
    // }

    return (
      <section>
        <Helmet title={helmetTitle} />
        {this.state.address ? (
          <Content>
            <BoxHeader>
              <Heading>{identifiersTitle}</Heading>
            </BoxHeader>

            <Subtitle>{quantityDescription}</Subtitle>
            {this.mensagemAdesao()}

            <IdentifierQuantityForm
              identifier={parseInt(quantity)}
              maxIdentifier={2}
              isSemiautomatico={isSemiautomatico}
              onChange={newQuantity => this.handleSaveQuantity(newQuantity)}
            />

            <WrapperRadios>
              <Box width={[1]} style={{ alignSelf: 'center' }}>
                <Flex align="center" px={[0, 0, 0, 0]}>
                  <Box width={[1]}>
                    <Flex direction="column">
                      <Box width={[1]}>
                        <Row>
                          <Subtitle>{addressDescription}</Subtitle>
                        </Row>
                      </Box>
                      <Box width={[7 / 12]}>
                        <Row>
                          <BoxCep>
                            <CheckBoxCep
                              type="radio"
                              value="actual"
                              checked={addressSelected === 'actual'}
                              onChange={e => {
                                this.changeCheckBox(e)
                                window.dataLayer.push({
                                  event: 'event',
                                  eventAction: 'clique:endereco-atual',
                                  eventCategory: 'veloe:area-logado:solicitar-produto'
                                })
                              }}
                            />

                            <CepText>
                              {this.state.address.logradouro}, {this.state.address.numero} -{' '}
                              {this.state.address.complemento
                                ? `${this.state.address.complemento} - `
                                : ''}{' '}
                              {this.state.address.bairro} - {this.state.address.cidade} -{' '}
                              {this.state.address.uf.toUpperCase()} -{' '}
                              {cepMask(this.state.address.cep)}{' '}
                            </CepText>
                          </BoxCep>
                        </Row>
                      </Box>
                      <Box width={[10 / 12]}>
                        <Row>
                          <NewAddress focus={addressSelected === 'new'}>
                            <WrapperSelectNewAddress>
                              <NewAddressText>
                                <CheckBoxCep
                                  type="radio"
                                  value="new"
                                  checked={addressSelected === 'new'}
                                  onChange={e => {
                                    this.changeCheckBox(e)
                                    window.dataLayer.push({
                                      event: 'event',
                                      eventAction: 'clique:endereco-novo',
                                      eventCategory: 'veloe:area-logado:solicitar-produto'
                                    })
                                  }}
                                />
                                <CepText transform="initial">Outro endereço de entrega</CepText>
                              </NewAddressText>
                            </WrapperSelectNewAddress>
                            <WrapperFormNewAddress>
                              {addressSelected === 'new' ? (
                                <OtherAddressForm
                                  initialValues={{ address: [] }}
                                  disableButton={this.disableButton()}
                                  onDelete={handleDelete}
                                  length={0}
                                  match={match}
                                  history={history}
                                  onSubmit={() => this.handleSave(isSemiautomatico)}
                                  onChange={values => this.handleSaveAddress(values.address)}
                                  loadingButton={loading}
                                />
                              ) : null}
                            </WrapperFormNewAddress>
                          </NewAddress>
                        </Row>
                      </Box>
                    </Flex>
                    {addressSelected === 'actual' && (
                      <WrapperButtons>
                        <Flex wrap>
                          <Box width={[1, 'auto']} mr={[0, 30]} mb={[20, 0]}>
                            <Button
                              disabled={disableButton}
                              loading={loading}
                              width="185px"
                              buttonType="skyBlue"
                              text="Enviar Pedido"
                              onClick={() => {
                                this.handleSave(isSemiautomatico)
                                window.dataLayer = window.dataLayer || []
                                window.dataLayer.push({
                                  event: 'event',
                                  eventAction: 'clique:enviar-pedido',
                                  eventCategory: 'veloe:area-logado:solicitar-produto'
                                })
                              }}
                            />
                          </Box>
                          <Box width={[1, 'auto']}>
                            <Button
                              buttonType="white"
                              onClick={() => {
                                history.goBack()
                                window.dataLayer = window.dataLayer || []
                                window.dataLayer.push({
                                  event: 'event',
                                  eventAction: 'clique:cancelar',
                                  eventCategory: 'veloe:area-logado:solicitar-produto'
                                })
                              }}
                              text="Cancelar"
                            />
                          </Box>
                        </Flex>
                      </WrapperButtons>
                    )}
                  </Box>
                </Flex>
              </Box>
            </WrapperRadios>
          </Content>
        ) : (
          <Content />
        )}
        {modal && <RequestIdentifierModal handleClick={this.handleClick} idPedido={idOrder} />}
        {modalExiste && <ExisteTagsModal handleExisteClick={this.handleExisteClick} />}
      </section>
    )
  }
}

const mapStateToProps = ({ form, reducer }) => {
  return {
    form,
    account: reducer.account,
    user: reducer.user,
    accountPlans: reducer.accountPlans
  }
}

const mapDispatchToProps = dispatch => ({
  dispatch,
  getUser: (userType, history) => {
    dispatch(setLoading(true))
    if (userType === 'pf') {
      dispatch(fetchGetUser())
        .then(() => dispatch(setLoading(false)))
        .catch(resp => {
          if (resp && (resp.status === 401 || resp.status === 403)) {
            dispatch(fetchLogout())
            history.push('/conta/login')
          }
          dispatch(setLoading(false))
        })
    } else {
      dispatch(fetchGetBusinessUser())
        .then(() => dispatch(setLoading(false)))
        .catch(resp => {
          if (resp && (resp.status === 401 || resp.status === 403)) {
            dispatch(fetchLogout())
            history.push('/conta/login')
          }
          dispatch(setLoading(false))
        })
    }
  },
  setLoading: isLoading => {
    dispatch(setLoading(isLoading))
  },
  newIdentifier: (data, history) => dispatch(fetchNewIdentifier(data))
  // .then()
  // .catch(resp => {
  //   if (resp && (resp.status === 401 || resp.status === 403)) {
  //     dispatch(fetchLogout())
  //     history.push('/conta/login')
  //   }
  //   dispatch(setLoading(false))
  // })
})

export default connect(mapStateToProps, mapDispatchToProps)(DashboardIdentifiersRequests)
