import React from 'react'

const Plus = ({ color = '#292663' }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <defs>
      <path
        id="plus-a"
        d="M13 11h6a1 1 0 1 1 0 2h-6v6a1 1 0 1 1-2 0v-6H5a1 1 0 0 1 0-2h6V5a1 1 0 0 1 2 0v6z"
      />
    </defs>
    <g fill="none" fillRule="evenodd">
      <mask id="plus-b" fill="#fff">
        <use xlinkHref="#plus-a" />
      </mask>
      <g fill={color} mask="url(#plus-b)">
        <path d="M0 0h24v24H0z" />
      </g>
    </g>
  </svg>
)

export default Plus
