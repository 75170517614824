import React, { Component } from 'react'
import { connect } from 'react-redux'
import { reduxForm, getFormValues, getFormMeta, change, untouch } from 'redux-form'
import { Banner } from './styles'
import BannerInfoPaymentApp from '../../modules/BannerInfoPayment'

const DIGITAL_BANK = {
  agency: '0001',
  bankList: ['212']
}

class PaymentForm extends Component {
  constructor(props) {
    super(props)

    this.state = {
      showWarningMessage: true
    }

    this.agencyInputRef = null
    this.accountInputRef = null
    this.onFocus = this.onFocus.bind(this)
  }

  setAgencyInputRef = ref => {
    this.agencyInputRef = ref
  }

  setAccountInputRef = ref => {
    this.accountInputRef = ref
  }

  onFocus = ref => {
    this.onFocus = ref
  }

  showDebitAuthorizationWarningMessage = formValues => {
    const { showWarningMessage } = this.state
    if (
      formValues &&
      formValues.paymentMethod === 'CUENTA' &&
      formValues.bankCode &&
      formValues.agency &&
      formValues.account &&
      formValues.accountDigit &&
      showWarningMessage
    ) {
      this.setState(
        {
          showWarningMessage: false
        },
        () => this.props.showDebitWarningModal()
      )
    }
  }

  componentDidMount() {
    const { paymentType } = this.props
    if (paymentType === 'DEBITO_EM_CONTA') {
      this.props.initialize({ paymentMethod: 'CUENTA' })
    } else if (paymentType === 'CARTAO_DE_CREDITO') {
      this.props.initialize({ paymentMethod: 'CAJA' })
    } else if (paymentType === 'CARTAO_DEBITO') {
      this.props.initialize({ paymentMethod: 'CARTAO_DEBITO' })
    } else {
      this.props.initialize({ paymentMethod: 'DISPOSICION' })
    }
  }

  componentDidUpdate() {
    const { formValues, fields } = this.props

    if (formValues.bankCode) {
      const isFormPaymentCuenta = formValues && formValues.paymentMethod === 'CUENTA'
      const isDigitalBank =
        isFormPaymentCuenta && DIGITAL_BANK.bankList.includes(formValues.bankCode.toString())

      if (!isDigitalBank && this.agencyInputRef && !fields) {
        this.agencyInputRef.focus()
      }
    }
  }

  onChangeBank = bankCode => {
    const { formValues, updateFieldValue, untouchFields } = this.props
    const isFormPaymentCuenta = formValues && formValues.paymentMethod === 'CUENTA'
    const isDigitalBank = isFormPaymentCuenta && DIGITAL_BANK.bankList.includes(bankCode.toString())

    if (isDigitalBank) {
      updateFieldValue('agency', DIGITAL_BANK.agency)
      this.accountInputRef.focus()
    } else {
      updateFieldValue('agency', '')
      untouchFields('account', 'agency')
    }
  }

  onSuccess = () => {
    this.props.reset()
    this.props.onFinish()
  }

  isParceiroCartaoDebito = nome => {
    if (nome) {
      return nome === 'Next' || nome.toUpperCase().includes('BTG')
    }
    return false
  }

  removerDebitoLista = paymentTypesOptions => {
    for (let i = 0; paymentTypesOptions.formas.length > i; i++) {
      if (paymentTypesOptions.formas[i].forma === 'CARTAO_DEBITO') {
        paymentTypesOptions.formas.splice(i, 1)
        break
      }
    }
  }

  render() {
    // console.log(paymentTypesOptions)

    /* return (
      <div>
        <form ref="paymentForm" onSubmit={handleSubmit(onSubmit)}>
          <FormField
          //  disabled={!ableToChangePaymentForm}
          >
            <Title>Escolha a forma de pagamento</Title>
            <div>
              <Flex direction={['column', 'row']}>
                {paymentTypesOptions &&
                  paymentTypesOptions.formas.map(paymentType => (
                    <Box key={paymentType.forma} mr={20} mb={[39, 28]}>
                      <Field
                        component={Radio}
                        label={paymentType.descricao}
                        name="paymentMethod"
                        type="radio"
                        value={paymentType.forma}
                      />
                    </Box>
                  ))}
              </Flex>
            </div>
            {isFormPaymentCartaoDebito && (
              <Flex direction="column" width="56%">
                <DebitCardNotice>
                  <img src={require(`../../../static/atencao.png`)} alt="" />
                  Insira apenas cartões {nomeParceiroComercial || 'Bradesco'}
                </DebitCardNotice>
              </Flex>
            )}
            <Flex direction="column">
              {isFormPaymentCuenta && (
                <div>
                  <DebitPaymentForm
                    onChangeBank={this.onChangeBank}
                    disableAgencyInput={isDigitalBank}
                    agencyRefFn={this.setAgencyInputRef}
                    accountRefFn={this.setAccountInputRef}
                  />
                </div>
              )}
              {(isFormPaymentCaja || isFormPaymentCartaoDebito) && (
                <div>
                  <CreditPaymentForm type={formValues.paymentMethod} formValues={formValues} />
                </div>
              )}
            </Flex>
          </FormField>
          {showNotices && (
            <Box width={[1, 420]} mb={20}>
              {isFormPaymentCuenta && (
                <Notice>
                  <Icon height={28} icon="info-purple" viewBox="0 0 24 22" />
                  <NoticeText>
                    Os dados bancários devem ser de mesma titularidade e não pode ser uma conta
                    poupança :)
                  </NoticeText>
                </Notice>
              )}
              <Notice>
                <Icon height={28} icon="info-purple" viewBox="0 0 24 22" />
                <NoticeText>
                  As alterações estão previstas para a fatura com vencimento no mês de{' '}
                  {nextPaymentDate.mesAlteracao}.
                </NoticeText>
              </Notice>
            </Box>
          )}
          <Buttons>
            <Box>
              <Button
                onClick={props.handleBackClick}
                text={!accountHasPayment ? labels.buttons.leave : labels.buttons.back}
                buttonType="white"
                width={rem('220px')}
              />
            </Box>
            <Box mt={[15, 0]} ml={[12, 60]}>
              <Button
                text="Continuar"
                type="button"
                width={rem('220px')}
                disabled={
                  !props.dirty || props.invalid || props.submitting
                  // || !ableToChangePaymentForm
                }
                onClick={() =>
                  showPaymentConfirmationModal(
                    this.props.handleSubmit(this.props.onSubmit),
                    this.onSuccess,
                    formValues,
                    paymentType,
                    window.dataLayer.push({
                      event: 'event',
                      eventCategory: 'veloe:area-logado:dados-plano',
                      eventAction: 'clique:botao:continuar',
                      eventLabel: 'continuar:[[sucesso]]'
                    })
                  )
                }
              />
            </Box>
          </Buttons>
        </form>
      </div>
    ) */

    return (
      <Banner>
        <BannerInfoPaymentApp />
      </Banner>
    )
  }
}

const Container = connect(
  state => ({
    formValues: getFormValues('PaymentForm')(state),
    fields: getFormMeta('PaymentForm')(state)
  }),
  dispatch => ({
    updateFieldValue: (fieldName, value) => dispatch(change('PaymentForm', fieldName, value)),
    untouchFields: (...fields) => dispatch(untouch('PaymentForm', ...fields))
  })
)

const Form = reduxForm({
  form: 'PaymentForm'
})(Container(PaymentForm))

export default Form
