/* eslint-disable */
import React from 'react'
import { connect } from 'react-redux'
import { formValueSelector } from 'redux-form'

import IndentifiersRequestStatus from '../../modules/IdentifiersRequestStatus'
import OrdersRequestList from '../../modules/OrdersRequestList'
import Content from '../../../styles/objects/Content'
import ArrowRight from '../../elements/Icon/arrow-right'
import ImersiveHeader from '../../elements/ImersiveHeader'
import { setLoading } from '../../modules/PageLoader/actions'
import fetchOrders from 'alelo-logic/actions/fetchOrders'
import PageLoader from '../../modules/PageLoader'
import RoundedSearchForm, { searchFilter } from './RoundedSearchForm'

import { Title, Paragraph, Wrapper, TitleContainer, TitleBox } from './styles'

const { title, paragraph, searchPlaceholder } = require('./data.json')

class FirstAccessFollowRequest extends React.Component {
  constructor(props) {
    super(props)
    this.state = { orders: [], type: 'pre' }
  }

  componentWillMount() {
    const accPlans = this.props.accountPlans
    const acc =
      accPlans && accPlans.plans && accPlans.plans.length > 0
        ? accPlans.plans.find(plan => plan.accountId == accPlans.activePlanId)
        : null

    const type = acc && acc.plan && acc.plan.type ? acc.plan.type : null

    this.setState({ type })
  }

  async componentDidMount() {
    this.props.setLoading(true)
    const accountNumber = this.props.match.params.id

    try {
      const response = await fetchOrders(accountNumber, this.state.type)
      this.setState({ orders: response })
      this.props.setLoading(false)
    } catch (error) {
      console.log(error)
    }
  }

  render() {
    const { orders } = this.state
    const {
      accountPlans: { activePlanId },
      match: { url },
      history,
      loading
    } = this.props

    const urlBack = `/minha-veloe/${activePlanId}/identificadores`
    const urlIdentifierFollow =
      url === `/minha-veloe/${activePlanId}/identificadores/acompanhar-pedido`

    return (
      <Wrapper>
        {urlIdentifierFollow && (
          <div>
            <ImersiveHeader text="Voltar" url={urlBack} />
          </div>
        )}
        <PageLoader>
          <Content>
            <TitleContainer>
              <TitleBox>
                <Title>{title}</Title>
                <Paragraph>{paragraph}</Paragraph>
              </TitleBox>
              <TitleBox alignSelf="flex-end" margin="0 0 20px 0">
                <RoundedSearchForm placeholder={searchPlaceholder} />
              </TitleBox>
            </TitleContainer>

            <OrdersRequestList
              orders={searchFilter(this.props.searchValue, orders)}
              history={history}
              accountId={this.props.match.params.id}
              type={this.state.type}
            />
          </Content>
        </PageLoader>
      </Wrapper>
    )
  }
}

const selector = formValueSelector('searchOrders')

const mapStateToProps = state => {
  return {
    accountPlans: state.reducer.accountPlans,
    loading: state.pageLoader.isLoading,
    searchValue: selector(state, 'search')
  }
}

const mapDispatchToProps = dispatch => ({
  setLoading: isLoading => {
    dispatch(setLoading(isLoading))
  }
})

export default connect(mapStateToProps, mapDispatchToProps)(FirstAccessFollowRequest)
