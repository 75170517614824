import React from 'react'
import bannerImage from '../../../static/banner-informativo-info-pagamento.png'
import { BannerInfoPagApp, ButtomsApp, Content } from './styles'
import ButtomGoogleApp from '../ButtomGoogleApp'
import ButtomIosApp from '../ButtomIosApp'

const BannerInfoPaymentApp = () => {
  return (
    <Content>
      <BannerInfoPagApp src={bannerImage} alt="Banner Informativo" />
      <ButtomsApp>
        <ButtomGoogleApp />
        <ButtomIosApp />
      </ButtomsApp>
    </Content>
  )
}
export default BannerInfoPaymentApp
