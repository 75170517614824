import React from 'react'
import Helmet from 'react-helmet'
import { connect } from 'react-redux'
import { Box, Flex } from 'grid-styled'

import {
  getBrasPagAccessToken,
  getVindiAccessToken,
  postBraspagCard
  // postBraspagVerifyCard
} from 'alelo-logic/services/rechargeService'

import { setNewDataValueWithoutRecharge, lockAuthorizationPayment } from 'alelo-logic/actions'

import { setLoading } from 'app/modules/PageLoader/actions'
import PaymentForm from 'app/modules/PaymentForm'
import { showModal } from 'app/modules/ModalRoot/actions'

import { CONFIRM_UPDATE, PAYMENT_CONFIRMATION_MODAL } from 'constants/ModalTypes'

import pfConfirmationImage from 'static/confirmation.png'
import pjImage from 'static/icon-veloe-background.svg'
import pfErrorImage from 'static/traffic-signal-error.svg'
import Content from 'styles/objects/Content'
import { normalizeFormValuesForModal, formatAccount } from './helpers'
import { BANKS, checkCreditCardFlag, validateDVAccount, validateItauDVAccount } from './validators'

const {
  titleModalError,
  titleModalSuccess,
  bodyModalSuccess,
  titleModalWarning
} = require('./data.json')

const DEBIT_PAYMENT_ERROR = (
  <span>
    Não foi possível alterar sua forma de pagamento. <br /> Verifique os dados fornecidos e tente
    novamente.
  </span>
)

const BANK_CODE = {
  BB: '001',
  BRADESCO: '237',
  ITAU: '341',
  ORIGINAL: '212'
}

const EditPaymentMethod = props => {
  // const getVerifyCardResponse = async values => {
  //   try {
  //     const cardFlag = checkCreditCardFlag(values.cardNumber.replace(/\s/g, ''))
  //     const res = await postBraspagVerifyCard({
  //       CardNumber: values.cardNumber.replace(/\s/g, ''),
  //       Holder: formatCardHolderName(values.fullName),
  //       ExpirationDate: values.validThru,
  //       SecurityCode: values.cvv,
  //       Brand: cardFlag,
  //       Type: cardFlag === 'elo' ? 'DebitCard' : 'CreditCard'
  //     })

  //     return res.data
  //   } catch (error) {
  //     console.log(error)
  //   }
  // }
  const validateAgencyDV = async formValues => {
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({
      event: 'event',
      eventCategory: 'veloe:area-logado:dados-plano',
      eventAction: 'clique:botao:confirmar:debito',
      eventLabel: 'confirmar:[[erro]]'
    })
    const AccountNotFoundResponse = {
      valid: false,
      message: 'Conta informada não confere, por favor verifique e tente novamente!'
    }
    if (
      formValues.bankCode.toString() === BANK_CODE.BB &&
      validateDVAccount(formValues.account, BANKS.BB) !== parseInt(formValues.accountDigit)
    ) {
      return AccountNotFoundResponse
    } else if (
      formValues.bankCode.toString() === BANK_CODE.BRADESCO &&
      validateDVAccount(formValues.account, BANKS.BRADESCO) !== parseInt(formValues.accountDigit)
    ) {
      return AccountNotFoundResponse
    } else if (
      formValues.bankCode.toString() === BANK_CODE.ITAU &&
      validateItauDVAccount(formValues.agency + formValues.account) !==
        parseInt(formValues.accountDigit)
    ) {
      return AccountNotFoundResponse
    } else if (
      formValues.bankCode.toString() === BANK_CODE.ORIGINAL &&
      validateDVAccount(formValues.account, BANKS.ORIGINAL) !== parseInt(formValues.accountDigit)
    ) {
      return AccountNotFoundResponse
    }
    return { valid: true, message: '' }
  }

  const getPaymentCompany = flag =>
    flag === 'master' ? 'masterCard' : flag === 'amex' ? 'american_express' : flag

  const getVindiToken = async (values, userData) => {
    const flag = checkCreditCardFlag(values.cardNumber)
    return await getVindiAccessToken({
      holder: formatCardHolderName(values.fullName),
      registry: userData.profile.cpf,
      cardCvv: values.cvv,
      cardExpiration: values.validThru,
      cardNumber: values.cardNumber,
      paymentCompany: getPaymentCompany(flag)
    })
  }

  const getPaymentToken = async values => {
    let paymentToken

    await getBrasPagAccessToken()
      .then(async response => {
        const resp = await postBraspagCard(
          formatCardHolderName(values.fullName),
          values.cardNumber.replace(/\s/g, ''),
          values.validThru,
          values.cvv,
          response.data
        )
        paymentToken = resp.data
      })
      .catch(error => console.error(error))
    return paymentToken
  }

  const formatCardHolderName = name => {
    return name
      .toUpperCase()
      .replace(new RegExp('[ÁÀÂÃ]', 'gi'), 'A')
      .replace(new RegExp('[ÉÈÊ]', 'gi'), 'E')
      .replace(new RegExp('[ÍÌÎ]', 'gi'), 'I')
      .replace(new RegExp('[ÓÒÔÕ]', 'gi'), 'O')
      .replace(new RegExp('[ÚÙÛ]', 'gi'), 'U')
      .replace(new RegExp('[Ç]', 'gi'), 'C')
  }

  const onSubmit = values => {
    const {
      updatePaymentMethod,
      showErrorModal,
      showCardErrorModal,
      userData,
      currentPlan,
      setBlockChangePaymentMethod,
      fetchPaymentInfo,
      fetchDebitPaymentInfo
    } = props

    return new Promise(async (resolve, reject) => {
      // 1. Se for diferente da selecionada, aplicar alteração para a selecionada
      if (values.paymentMethod === 'CUENTA' && values.agency && values.account) {
        // 2. Aplicar debito em conta
        const validAgencyDV = await validateAgencyDV(values)
        if (!validAgencyDV.valid) {
          showErrorModal('pf', validAgencyDV.message)
          return resolve()
        }
        const data = {
          dados: {
            conta: currentPlan.accountId,
            alias: 'EDICAO FORMA PAGAMENTO',
            tipoPagamento: values.paymentMethod,
            cpf: userData.profile.cpf,
            nome: userData.profile.nome,
            agencia: values.agency,
            numeroContaCorrente: `${formatAccount(values.account)}-${values.accountDigit}`,
            idBanco: values.bankCode.toString()
          }
        }

        updatePaymentMethod(data)
          .then(async () => {
            await fetchDebitPaymentInfo()
            await setBlockChangePaymentMethod()
            resolve()
          })
          .catch(e => {
            console.error(e)
            showErrorModal('pf', DEBIT_PAYMENT_ERROR)
            reject(e)
          })
      } else if (values.paymentMethod === 'DISPOSICION') {
        const data = {
          dados: {
            conta: currentPlan.accountId,
            alias: 'EDICAO FORMA PAGAMENTO',
            tipoPagamento: values.paymentMethod,
            cpf: userData.profile.cpf,
            nome: userData.profile.nome
          }
        }

        updatePaymentMethod(data)
          .then(async () => {
            await fetchPaymentInfo()
            await setBlockChangePaymentMethod()
            resolve()
          })
          .catch(e => {
            console.error(e)
            showErrorModal()
            reject(e)
          })
      } else if (
        (values.paymentMethod === 'CAJA' || values.paymentMethod === 'CARTAO_DEBITO') &&
        values.cardNumber
      ) {
        // VerifyCard
        // if (checkCreditCardFlag(values.cardNumber.replace(/\s/g, '')) !== 'alelo') {
        //   const verifyCardResponse = await getVerifyCardResponse(values)
        //   if (!verifyCardResponse || (verifyCardResponse && verifyCardResponse.Status !== 1)) {
        //     showErrorModal('pf', DEBIT_PAYMENT_ERROR)
        //     return reject(new Error('VerifyCard not authorized'))
        //   }
        // }

        const vindiToken = await getVindiToken(values, userData)
        const paymentToken = await getPaymentToken(values)
        if (!paymentToken) {
          showCardErrorModal()
          return reject(new Error('Payment Token error'))
        }
        const data = {
          dados: {
            conta: currentPlan.accountId,
            numeroMascarado: (
              values.cardNumber
                .replace(/[^\dA-Z]/g, '')
                .replace(/(.{4})/g, '$1 ')
                .trim()
                .substring(0, 7) +
              'XX-XXXX-' +
              values.cardNumber
                .replace(/[^\dA-Z]/g, '')
                .replace(/(.{4})/g, '$1 ')
                .trim()
                .substring(15)
            ).replace(/\s/g, '-'),
            validade: values.validThru.replace('/20', '-'),
            bandeira: checkCreditCardFlag(values.cardNumber.replace(/\s/g, '')),
            tipoPagamento: values.paymentMethod,
            alias: 'EDICAO FORMA PAGAMENTO',
            nome: formatCardHolderName(values.fullName),
            cpf: userData.profile.cpf,
            paymentToken: paymentToken.PaymentToken,
            cvv:
              checkCreditCardFlag(values.cardNumber.replace(/\s/g, '')) === 'amex'
                ? values.cvv
                : values.cvv.slice(0, 3),
            gateways: [{ id: 'VINDI', token: vindiToken }]
          }
        }

        updatePaymentMethod(data)
          .then(async () => {
            await fetchPaymentInfo()
            await setBlockChangePaymentMethod()
            resolve()
            window.dataLayer = window.dataLayer || []
            window.dataLayer.push({
              event: 'event',
              eventCategory: 'veloe:area-logado:dados-plano',
              eventAction: 'clique:botao:confirmar:credito',
              eventLabel: 'confirmar:[[sucesso]]'
            })
          })
          .catch(e => {
            console.error(e)
            const graphErr = e.graphQLErrors && e.graphQLErrors.length > 0 && e.graphQLErrors[0]
            if (graphErr && graphErr.message) {
              showErrorModal('pf', graphErr.message)
              window.dataLayer = window.dataLayer || []
              window.dataLayer.push({
                event: 'event',
                eventCategory: 'veloe:area-logado:dados-plano',
                eventAction: 'clique:botao:confirmar:credito',
                eventLabel: 'confirmar:[[erro]]'
              })
            } else {
              showErrorModal('pf')
            }
            reject(e)
          })
      } else {
        resolve()
      }
    })
  }

  // Removido por ora até ser confirmado que não haverá mais restrição de alteração
  // const ableToChangePaymentForm =
  //   props.authorizations.FORMA &&
  //   !props.authorizations.FORMA.dataProximaAlteracao &&
  //   !props.authorizations.FORMA.error
  // const daysToChangePaymentForm = !ableToChangePaymentForm
  //   ? moment(props.authorizations.FORMA.dataProximaAlteracao, 'DD/MM/YYYY').diff(moment(), 'days') +
  //     1
  //   : 0

  return (
    <Flex direction="column">
      <Helmet title="Editar dados de pagamento" />
      <Content>
        <Box width={[1, 680]}>
          <PaymentForm
            // ableToChangePaymentForm={ableToChangePaymentForm}
            accountHasPayment={props.accountHasPayment}
            nextPaymentDate={props.nextPaymentDate}
            paymentType={props.currentPlan.paymentType}
            parceiroComercial={props.parceiroComercial}
            onSubmit={onSubmit}
            paymentTypesOptions={props.paymentTypes}
            onFinish={props.onFinish}
            handleBackClick={props.onBackButtonClick}
            showPaymentConfirmationModal={props.showPaymentConfirmationModal}
            showDebitWarningModal={props.showDebitWarningModal}
          />
        </Box>
      </Content>
    </Flex>
  )
}

const mapStateToProps = ({ reducer }) => ({
  userData: reducer.user,
  accountPlans: reducer.accountPlans,
  accountType: reducer.account.accountType,
  authorizations: reducer.planData.authorizations,
  creditPaymentInfo: reducer.recharge.paymentInfo
})

const mapDispatchToProps = (dispatch, componentProps) => ({
  showPaymentConfirmationModal: (submitAction, successAction, formValues, paymentType) =>
    dispatch(
      showModal(PAYMENT_CONFIRMATION_MODAL, {
        onConfirm: () => submitAction(),
        onSuccess: () => successAction(),
        changePaymentTo: normalizeFormValuesForModal(formValues, componentProps.nextPaymentDate),
        currentPaymentType: paymentType
      })
    ),
  showSuccessModal: accountType =>
    dispatch(
      showModal(CONFIRM_UPDATE, {
        modalTitle: titleModalSuccess,
        modalDescription: window.dataLayer.push({
          event: 'event',
          eventCategory: 'veloe:area-logado:dados-plano',
          eventAction: 'clique:botao:confirmar:credito',
          eventLabel: 'pre:alteracao:[[erro:erro-ao-dados]]'
        }),
        bodyModalSuccess,
        accountType,
        image: accountType === 'pf' ? pfConfirmationImage : pjImage
      })
    ),
  showErrorModal: (accountType, bodyText) =>
    dispatch(
      showModal(CONFIRM_UPDATE, {
        modalTitle: titleModalError,
        modalDescription: bodyText || (
          <span>
            Não foi possível alterar os dados do cartão. <br /> Verifique os dados fornecidos e
            tente novamente.
          </span>
        ),
        accountType,
        image: accountType === 'pf' ? pfErrorImage : pjImage
      })
    ),
  showDebitWarningModal: () =>
    dispatch(
      showModal(CONFIRM_UPDATE, {
        modalTitle: titleModalWarning,
        modalDescription: (
          <span>
            Lembre-se de autorizar o débito em conta no seu banco,
            <br /> caso contrário seu adesivo (tag) poderá ser bloqueada.
            <br />
            <br />
            Caso já tenha realizado a autorização,
            <br /> por favor desconsidere esta mensagem.
          </span>
        ),
        accountType: 'pf',
        image: pfErrorImage
      })
    ),
  showCardErrorModal: accountType =>
    dispatch(
      showModal(CONFIRM_UPDATE, {
        modalTitle: titleModalError,
        modalDescription:
          (window.dataLayer.push({
            event: 'event',
            eventCategory: 'veloe:area-logado:dados-plano',
            eventAction: 'clique:botao:confirmar:credito',
            eventLabel: 'confirmar:[[erro]]'
          }),
          (
            <span>
              <b>Cartão inválido</b>
              <br />
              Por favor, confira o número digitado ou escolha outro cartão
            </span>
          )),
        accountType,
        image: accountType === 'pf' ? pfErrorImage : pjImage
      })
    ),
  setBlockChangePaymentMethod: () => dispatch(lockAuthorizationPayment('FORMA')),

  updatePaymentMethod: data => dispatch(setNewDataValueWithoutRecharge(data)),
  setLoading: isLoading => {
    dispatch(setLoading(isLoading))
  }
})

export default connect(mapStateToProps, mapDispatchToProps)(EditPaymentMethod)
