import React from 'react'
import { string } from 'prop-types'

import waiting from './waiting'
import appStore from './app-store'
import purpleAlert from './alert-purple'
import attachment from './attachment'
import overdue from './overdue'
import backArrow from './back-arrow'
import calendar from './calendar'
import car from './car'
import card from './card'
import check from './check'
import checkStatus from './checkStatus'
import checkGreen from './checkGreen'
import close from './close'
import diners from './diners'
import dropdownArrow from './dropdown-arrow'
import edit from './edit'
import eye from './eye'
import exclude from './exclude'
import closed from './closed'
import fuel from './fuel'
import gift from './gift'
import greenCheck from './green-check'
import hand from './hand'
import hamburger from './hamburger'
import install from './install'
import info from './info'
import infoPurple from './info-purple'
import loading from './loading'
import money from './money'
import maestro from './maestro'
import master from './master'
import modalClose from './modal-close'
import lock from './lock'
import percentual from './percentual'
import park from './park'
import packageDelivery from './packageDelivery'
import payment from './payment'
import playStore from './play-store'
import recharge from './face-icon'
import cashback from './cashback'
import refills from './refills'
import resend from './resend'
import rightArrow from './right-arrow'
import rightArrowLarge from './right-arrow-large'
import leftArrowLarge from './left-arrow-large'
import socialIcons from './social-icons'
import supply from './supply'
import veloe from './veloe-logo'
import visa from './visa'
import tagVeloe from './tagVeloe'
import tramaVeloe from './trama-veloe'
import tramaVeloeFooter from './trama-veloe-footer'
import trophy from './trophy'
import truck from './truck'
import toll from './toll'
import update from './update'
import newClose from './newClose'
import dropdown from './dropdown'
import download from './download'
import share from './share'
import print from './print'
import dollarSign from './dollar-sign'
import doc from './doc'
import barcode from './barcode'
import newCloseBlue from './newCloseBlue'
import dryWash from './dry-wash'

import { Svg } from './styles'

const files = {
  waiting,
  'app-store': appStore,
  alert: purpleAlert,
  attachment,
  overdue,
  'back-arrow': backArrow,
  barcode,
  calendar,
  car,
  card,
  check,
  checkStatus,
  checkGreen,
  close,
  diners,
  'dropdown-arrow': dropdownArrow,
  dropdown,
  download,
  edit,
  eye,
  exclude,
  closed,
  fuel,
  gift,
  'green-check': greenCheck,
  hand,
  hamburger,
  install,
  info,
  'info-purple': infoPurple,
  loading,
  money,
  maestro,
  master,
  'modal-close': modalClose,
  lock,
  packageDelivery,
  percentual,
  park,
  payment,
  print,
  'play-store': playStore,
  recharge,
  cashback,
  refills,
  resend,
  'right-arrow': rightArrow,
  'right-arrow-large': rightArrowLarge,
  'left-arrow-large': leftArrowLarge,
  'social-icons': socialIcons,
  share,
  supply,
  'veloe-logo': veloe,
  visa,
  tagVeloe,
  'trama-veloe': tramaVeloe,
  'trama-veloe-footer': tramaVeloeFooter,
  toll,
  trophy,
  truck,
  update,
  'new-close': newClose,
  'new-close-blue': newCloseBlue,
  'dollar-sign': dollarSign,
  doc,
  'dry-wash': dryWash
}

const renderIcon = icon => {
  const selectedIcon = Object.keys(files).filter(iconFile => icon === iconFile)

  return files[selectedIcon]
}

function Icon(props) {
  const selectedIcon = renderIcon(props.icon)

  return (
    <Svg {...props} xmlns="http://www.w3.org/2000/svg" dangerouslySetInnerHTML={selectedIcon} />
  )
}

Icon.defaultProps = {
  icon: '',
  svgStyle: ''
}

Icon.propTypes = {
  icon: string.isRequired,
  svgStyle: string
}

export default Icon
