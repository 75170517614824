import styled, { css } from 'styled-components'

import { linkNavyBlue, darkBlue, blueGray, darkGrey } from 'styles/settings/colors'
import { txt12OpenSansBold } from 'styles/settings/types'
import rem from 'styles/tools/rem'

export const DropdownLabel = styled.p`
  color: ${darkBlue};
  font-family: 'OpenSans';
  font-size: ${rem('12px')};
  font-weight: lighter;
  margin: 0;
  min-width: ${rem('60px')};
  user-select: none;
  text-transform: capitalize;
`

export const DropdownLabelContainer = styled.div`
  align-items: center;
  display: flex;
  position: relative;
`

export const DropdownArrow = `height: 5px; width: 10px; stroke: ${linkNavyBlue}`

export const HeaderLabel = styled.p`
  ${txt12OpenSansBold};
  margin: 0;
  margin-right: ${rem('3px')};
  line-height: 1.67;
  letter-spacing: 1.6px;
  text-align: left;
  color: ${({ isOpen }) => (isOpen ? linkNavyBlue : blueGray)};
  text-transform: uppercase;
`

export const DropdownContainer = styled.div`
  cursor: pointer;
  margin: 0;
  transition: all 0.5s;
  ${({ isOpen }) =>
    isOpen &&
    css`
      svg {
        color: ${darkGrey};
        transform: rotate(180deg);
      }
    `};
`
