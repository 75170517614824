import styled from 'styled-components'
import rem from 'styles/tools/rem'
import { txt12OpenSansBold } from 'styles/settings/types'
import { white, tealish, lightRed } from 'styles/settings/colors'

export const ChipsContainer = styled.div`
  flex-wrap: wrap;
  display: flex;
`

export const Chip = styled.span`
  ${txt12OpenSansBold};
  color: ${white};
  background-color: ${tealish};
  padding: ${rem('3px')} ${rem('10px')};
  border-radius: ${rem('15px')};
  margin-top: ${rem('5px')};
  margin-right: ${rem('4px')};
  margin-left: ${rem('4px')};
  cursor: pointer;
`

export const MsgError = styled.div`
  ${txt12OpenSansBold};
  color: ${lightRed};
  padding: ${rem('5px')};
  margin-top: ${rem('20px')};
`
