import types from '../actions/types'

const {
  ADD_REPRESENTATIVE,
  UPDATE_REPRESENTATIVE,
  DELETE_REPRESENTATIVE,
  FETCH_USER,
  FETCH_PROFILE_UPDATE,
  FETCH_ADDRESS_UPDATE
} = types

function userReducer(state = {}, action = {}) {
  switch (action.type) {
    case UPDATE_REPRESENTATIVE: {
      const representatives = state.representatives || []

      if (action.data && action.data.document) {
        const representativeIndex = representatives.findIndex(
          representive => representive.document === action.data.document
        )

        representatives[representativeIndex] = action.data
      }

      return {
        ...state,
        representatives
      }
    }
    case ADD_REPRESENTATIVE: {
      const representatives = state.representatives || []

      if (action.data && action.data.document) {
        representatives.push(action.data)
      }

      return {
        ...state,
        representatives
      }
    }
    case DELETE_REPRESENTATIVE: {
      const representatives = state.representatives || []

      representatives.splice(
        representatives.findIndex(representive => representive.document === action.document),
        1
      )

      return {
        ...state,
        representatives
      }
    }
    case FETCH_USER: {
      return action.state
    }
    case FETCH_PROFILE_UPDATE: {
      state.profile.sexo.codigo = action.state.sexo.codigo
      state.profile['estado-civil'].codigo = action.state['estado-civil'].codigo
      return state
    }
    case FETCH_ADDRESS_UPDATE: {
      state.enderecos = action.state
      return state
    }

    default:
      return state
  }
}

export default userReducer
