import React from 'react'
import { Container, BoxIdentifier, ButtonIdentifier, IdentifiersText, Paragraph } from './styles'
import LessIcon from '../../elements/Icon/less-sign'
import PlusIcon from '../../elements/Icon/plus-sign'
import { Flex, Box } from 'grid-styled'

const { labels } = require('./data.json')

const IdentifierQuantityForm = props => {
  const { onChange, identifier, maxIdentifier, isSemiautomatico } = props

  const decrementIdentifier = () => {
    const newValue = identifier === 1 ? 1 : identifier - 1
    onChange(newValue)
    window.dataLayer.push({
      event: 'event',
      eventAction: 'clique:menos',
      eventCategory: 'veloe:area-logado:solicitar-produto',
      eventLabel: identifier - 1,
      quantidade_tags: identifier - 1
    })
  }

  const incrementIdentifier = () => {
    const newValue = identifier === maxIdentifier ? maxIdentifier : identifier + 1
    onChange(newValue)
    window.dataLayer.push({
      event: 'event',
      eventAction: 'clique:mais',
      eventCategory: 'veloe:area-logado:solicitar-produto',
      eventLabel: identifier + 1,
      quantidade_tags: identifier + 1
    })
  }

  const deviceName = isSemiautomatico ? 'Pulseira' : 'Tag'

  return (
    <Container>
      <Flex wrap width={[1, 7 / 12]}>
        <Box width={1}>
          <BoxIdentifier>
            <ButtonIdentifier
              marginLeft="0"
              disabled={identifier === 1}
              onClick={() => decrementIdentifier()}
            >
              <LessIcon color="white" />
            </ButtonIdentifier>
            <IdentifiersText>{`${identifier} ${deviceName}${
              identifier > 1 ? 's' : ''
            }`}</IdentifiersText>
            <ButtonIdentifier
              disabled={identifier === maxIdentifier}
              onClick={() => incrementIdentifier()}
            >
              <PlusIcon color="white" />
            </ButtonIdentifier>
          </BoxIdentifier>
        </Box>
        {identifier === maxIdentifier && (
          <Paragraph>{`${labels.limitOfIdentifiers} ${maxIdentifier} ${deviceName}s.`}</Paragraph>
        )}
      </Flex>
    </Container>
  )
}

export default IdentifierQuantityForm
