import styled from 'styled-components'
import { Flex } from 'grid-styled'
import media from 'styles/tools/media'
import rem from 'styles/tools/rem'
import { txt12OpenSans, txt12OpenSansBold } from 'styles/settings/types'
import { offWhite, bbYellow, darkBlue, butterflyBush, quartz } from 'styles/settings/colors'

export const Separator = styled.hr`
  background-color: ${offWhite};
  border: 0;
  height: ${rem('1px')};
  margin: ${rem('35px')} 0 ${rem('25px')} 0;
`

export const Container = styled(Flex)`
  flex-direction: column;
  ${props =>
    props.justifyContent &&
    `
    justify-content: ${props.justifyContent};
  `};
  ${media.large`
    margin: ${rem('40px')} 0 ${rem('60px')} 0;
    align-self: stretch;
    width: 100%;
    flex-direction: row;

    ${props =>
      props.editMode &&
      `
      margin-top: 0;
    `};
  `};
`

export const QrCodeBBBar = styled(Flex)`
  margin-top: ${rem('20px')};
  padding: ${rem('15px')};
  background-color: ${bbYellow};
  border-radius: ${rem('10px')};
  max-width: ${rem('500px')};
  width: 100%;
`

export const QrCodeImage = styled.img`
  width: ${rem('66px')};
  height: 100%;
`

export const QrCodeInnerWrapper = styled(Flex)`
  flex-direction: column;
  justify-content: space-between;
`

export const QrCodeBBText = styled.div`
  ${txt12OpenSans};
  color: ${darkBlue};
`

export const QrCodeLink = styled.a`
  ${txt12OpenSansBold};
  color: ${darkBlue};
  cursor: pointer;
  text-decoration: underline;
`

/* export const BannerInfoPagApp = styled(Flex)`
  width: 100%;
  flex-direction: column;

  ${media.large`
    margin-left: ${rem('41px')};
  `};
  ${media.small`
    margin: ${rem('25px')} 0;
  `}
` */

export const Notice = styled.div`
  ${txt12OpenSans};
  display: flex;
  background-color: ${quartz};
  color: ${butterflyBush};
  padding: 15px 25px 15px 0;
  border-radius: 12px;

  & > svg {
    margin: 12px 12px 0;
  }
`

export const NoticeText = styled.p`
  width: 100%;
  font-weight: 600;
  line-height: 2;
`
