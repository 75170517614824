const contestationFactory = {
  toAPI: (data, transactionId) =>
    data &&
    transactionId && {
      transactionId,
      reason: 1,
      details: data.name
    }
}

export default contestationFactory
