import { gqlMutate, gqlQuery } from './common/apolloClient'
import axios from 'axios/index'
import qs from 'qs'
import {
  API_BRASPAG_CARD_URL,
  BRASPAG_VERIFYCARD_PROVIDER,
  BRASPAG_VERIFYCARD_URL,
  MERCHANT_ID,
  MERCHANT_KEY,
  VINDI_TOKEN_URL,
  VINDI_AUTHORIZATION
} from './common/constants'
import {
  gqlInserirRecarga,
  gqlValoresRecarga,
  gqlDadosPagamento,
  gqlEditarValorRecarga,
  gqlAccessTokenBrasPag,
  transformDadosPagamento,
  transformValoresRecarga
} from './queries/recharges'

const formaPagamentoMap = {
  credit: 'CARTAO_DE_CREDITO',
  digitalAccount: 'CONTA_DIGITAL',
  debit: 'DEBITO_EM_CONTA',
  bankSlip: 'BOLETO',
  debitCard: 'CARTAO_DEBITO'
}
const atividadeMap = {
  manual: 'RECARGA_MANUAL',
  auto: 'RECARGA_AUTOMATICA'
}

export async function createRecharge(accountPlanId, value, paymentMethod, type) {
  await gqlMutate(gqlInserirRecarga, {
    conta: accountPlanId,
    valor: value,
    atividade: atividadeMap[type],
    formaPagamento: formaPagamentoMap[paymentMethod],
    canal: 'site'
  })
}

export async function getRechargeValues(accountPlanId) {
  const gqlResp = await gqlQuery(gqlValoresRecarga, { idConta: accountPlanId })
  return transformValoresRecarga(gqlResp)
}

export async function getPaymentInfo(accountPlanId) {
  const gqlResp = await gqlQuery(gqlDadosPagamento, { conta: accountPlanId })
  return transformDadosPagamento(gqlResp)
}

export async function changeRecharge(accountPlanId, valorRecarga) {
  await gqlMutate(gqlEditarValorRecarga, { conta: accountPlanId, valor: valorRecarga })
}

export async function getBrasPagAccessToken() {
  const {
    data: { accessTokenBrasPag }
  } = await gqlQuery(gqlAccessTokenBrasPag)
  return { data: accessTokenBrasPag }
}

export async function getVindiAccessToken({
  holder,
  registry,
  cardExpiration,
  cardNumber,
  cardCvv,
  paymentCompany
}) {
  try {
    const payload = {
      holder_name: holder,
      registry_code: registry,
      card_expiration: cardExpiration,
      card_number: cardNumber,
      card_cvv: cardCvv,
      payment_method_code: 'credit_card_bradesco',
      payment_company_code: paymentCompany
    }
    const response = await axios.post(VINDI_TOKEN_URL, payload, {
      headers: {
        Authorization: VINDI_AUTHORIZATION
      }
    })

    if (response && response.data.payment_profile)
      return response.data.payment_profile.gateway_token
    else throw new Error('Vindi token invalid response')
  } catch (error) {
    console.error(error)
    return ''
  }
}

const normalizeValidade = src => {
  let [mes, ano] = src.split('/')
  if (!mes || !ano || ![2, 4].includes(ano.length)) throw new Error('Data de validade inválida')
  if (ano.length === 2) {
    ano = `20${ano}`
  }
  return `${mes}/${ano}`
}

export async function postBraspagCard(
  HolderName,
  RawNumber,
  Expiration,
  SecurityCode,
  AccessToken
) {
  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/x-www-form-urlencoded'
  }

  return axios.post(
    API_BRASPAG_CARD_URL,
    qs.stringify({
      HolderName,
      RawNumber,
      Expiration: normalizeValidade(Expiration),
      SecurityCode,
      AccessToken
    }),
    {
      headers
    }
  )
}

export async function postBraspagVerifyCard(cardData) {
  try {
    const headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      MerchantId: MERCHANT_ID,
      MerchantKey: MERCHANT_KEY
    }
    const res = await axios.post(
      BRASPAG_VERIFYCARD_URL,
      {
        Provider: BRASPAG_VERIFYCARD_PROVIDER,
        Card: { ...cardData }
      },
      {
        headers
      }
    )

    return res
  } catch (error) {
    console.log(error)
  }
}

export function bacenAPI(numeroAgencia) {
  return axios.get(
    encodeURI(
      `https://olinda.bcb.gov.br/olinda/servico/Informes_Agencias/versao/v1/odata/Agencias?$top=100&$skip=0&$filter=NomeIf eq 'BANCO DO BRASIL S.A.' and CodigoCompe eq '${numeroAgencia}'&$format=json&$select=NomeIf,CodigoCompe,NomeAgencia,Endereco,Municipio,UF`
    )
  )
}
