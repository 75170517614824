import qs from 'qs'
import { post } from './common/axiosClient'
import { setAuthObj, removeAuthObj, getAccessTokenObject } from './common/storage'
import { AUTH_URL, AUTH_SCOPE } from './common/constants'
import { gqlQuery } from './common/apolloClient'
import { gqlContas, transformConta, gqlInfoExtra, transformInfosAdicionais } from './queries/plans'
import { gqlPedidosEntregasRecargas, transformRastreio } from './queries/tracking'
import { transformOrders } from './queries/orders'

import { gqlTermo } from './queries/term'

export * from '../services-middleware/accountService'

export async function authenticate(login, password, responseCaptcha) {
  return authenticateGQL(login, password)
}

export async function logout() {
  removeAuthObj()
}

export async function authenticateGQL(login, password, responseCaptcha) {
  try {
    removeAuthObj()
    const cred = await post(
      AUTH_URL,
      qs.stringify({ grant_type: 'password', scope: AUTH_SCOPE, username: login, password }),
      { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } }
    )
    setAuthObj(cred)
    const accessToken = getAccessTokenObject()
    // TODO Trata erros
    if (!accessToken || !accessToken.title) {
      throw new Error('accessToken Object is invalid:' + JSON.stringify({ accessToken, cred }))
    }
    return { accountType: accessToken.title.toLowerCase(), success: true, active: true }
  } catch (e) {
    console.error('authenticate failed:', e)
    throw e
  }
}

// FIXME accountId não é passado para a função
export async function getAccountPlans(accountId) {
  // recuperar conta
  let contas = transformConta(await gqlQuery(gqlContas))
  const infoAdicionaisPromises = contas.map(c => gqlQuery(gqlInfoExtra, { idConta: c.id }))
  const infosAdicionais = await Promise.all(infoAdicionaisPromises)
  return contas.map((conta, ind) => ({
    ...conta,
    ...transformInfosAdicionais(infosAdicionais[ind])
  }))
}

export async function getTermsAndConditions() {
  const { data: { buscarArquivos = [] } = {} } = await gqlQuery(gqlTermo)
  const arquivos = buscarArquivos
  return { arquivos }
}

export const getTrackIdentifiersDetails = async (accountNumber, orderNumber) => {
  const queryresult = await gqlQuery(gqlPedidosEntregasRecargas, { conta: accountNumber })
  return transformRastreio(queryresult, orderNumber)
}

export async function getOrders(conta, type) {
  const result = await gqlQuery(gqlPedidosEntregasRecargas, { conta })
  return transformOrders(result, type)
}
