import React from 'react'
import Helmet from 'react-helmet'
// Styles
import { Content } from './style'
// Component
import HowtoInstall from 'app/modules/HowToInstall'

const DashboardHowToInstall = () => {
  return (
    <section>
      <Helmet title={'Como instalar'} />
      <Content>
        <HowtoInstall />
      </Content>
    </section>
  )
}

export default DashboardHowToInstall
