import { requestMoneyTransfer } from '../services/ecService'

import types from './types'

const { FETCH_EC_MONEY_TRANSER } = types

function fetchECMoneyTransfer() {
  return dispatch =>
    requestMoneyTransfer()
      .then(moneyTransfer => {
        dispatch({
          type: FETCH_EC_MONEY_TRANSER,
          state: moneyTransfer
        })
        return moneyTransfer
      })
      .catch(error => {
        console.log('Erro em #fetchECMoneyTransfer()')
        console.log(error)
      })
}

export default fetchECMoneyTransfer
