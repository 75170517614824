import styled from 'styled-components'
import { Box } from 'grid-styled'

import rem from 'styles/tools/rem'
import em from 'styles/tools/em'

import { txt14OpenSans, title40GalanoBold, title28GalanoBold } from '../../../styles/settings/types'

import media from '../../../styles/tools/media'
import { darkSlateBlue, lightGray } from '../../../styles/settings/colors'

export const Title = styled.h1`
  ${title40GalanoBold} letter-spacing: ${em('0.4px')};
  color: ${darkSlateBlue};
  margin-top: ${rem('40px')};

  ${media.small`
    ${title28GalanoBold}
    letter-spacing: normal;
    line-height: 1;
  `};
`

export const Paragraph = styled.p`
  ${txt14OpenSans} color: ${darkSlateBlue};
  max-width: ${rem('400px')};
`
export const Wrapper = styled(Box)`
  border-top: ${rem('1px')} solid ${lightGray};
`

export const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
`
export const TitleBox = styled.div`
  ${({ alignSelf }) => `align-self: ${alignSelf}`};
  ${({ margin }) => `margin: ${margin}`};
`
