'use strict'

import { getBankList } from '../services'

import types from './types'

const { FETCH_BANK_LIST } = types

function fetchBankList() {
  return dispatch =>
    getBankList().then(banks => {
      dispatch({
        type: FETCH_BANK_LIST,
        state: banks
      })

      return banks
    })
}

export default fetchBankList
