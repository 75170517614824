import { API, dynamicAPI, PLAN_RESUME } from './common/constants'
import fetchHandler from './common/fetchHandler'

// export * from '../services/dashboardService'

export function getPlans() {
  return fetchHandler(`${API + PLAN_RESUME}`, {
    method: 'GET'
  })
}

// export function getPlanResume(planId) {
//   fetchHandler(`${dynamicAPI}/minha-veloe/${planId}/resumo`, {
//     method: 'GET'
//   }).then(console.log)
//   return dashboardService.getPlanResume(planId)
// }

// export function getInvoices(planId, next) {
//   const url = next ? `${dynamicAPI}${next}` : `${dynamicAPI}/minha-veloe/fatura/${planId}/historico`
//   return fetchHandler(url, {
//     method: 'GET'
//   })
// }

// export function getStatements(planId, next, filter = {}) {
//   const query = `&${queryStringParser(filter)}`
//
//   const url = next
//     ? `${API_URL}${next}${query}`
//     : `${API_URL}/api/accountplans/${planId}/transactions?limit=7${query}`
//   return fetchHandler(url, {
//     method: 'GET'
//   })
// }

export function getStatement(planId, statementId) {
  const url = `${dynamicAPI}/accountplans/${planId}/transactions/${statementId}`
  return fetchHandler(url, {
    method: 'GET'
  })
}

// export function downloadStatement(planId, filter = {}) {
//   const query = queryStringParser(filter)
//   const url = `${dynamicAPI}/accountplans/${planId}/statement/download?${query}`
//   return fetchHandler(url, {
//     method: 'GET'
//   })
// }

// export function downloadStatementCSV(planId, filter = {}) {
//   const query = queryStringParser(filter)
//   const url = `${dynamicAPI}/accountplans/${planId}/statement/csv?${query}`
//   return fetchHandler(url, {
//     method: 'GET'
//   })
// }

// export function downloadCSVInvoice(planId, invoiceId) {
//   const url = `${dynamicAPI}/minha-veloe/fatura/${planId}/detalhe/${invoiceId}/download`
//   return fetchHandler(url, {
//     method: 'GET'
//   })
// }

// export function downloadTicket(planId, invoiceId) {
//   const url = `${dynamicAPI}/minha-veloe/boleto/${planId}/${invoiceId}/download`
//   return fetchHandler(url, {
//     method: 'GET'
//   })
// }

// export function downloadInvoice(planId, year, month) {
//   const url = `${dynamicAPI}/accountplans/${planId}/invoices/${year}/${month}/pdf`
//   return fetchHandler(url, {
//     method: 'GET'
//   })
// }

export function getReasons() {
  const url = `${dynamicAPI}/contestationreasons`
  return fetchHandler(url, {
    method: 'GET'
  })
}

export function getContestationProtocol(data) {
  const url = `${dynamicAPI}/transactions/${data.transactionId}/contestation`
  return fetchHandler(url, {
    method: 'POST',
    body: JSON.stringify(data)
  })
}

// export function getRecharges(planId, next) {
//   const url = next
//     ? `${dynamicAPI}${next}`
//     : `${dynamicAPI}/accountplans/${planId}/recharges?limit=6`
//   return fetchHandler(url, {
//     method: 'GET'
//   })
// }

// export function getVehicles(accountId) {
//   const url = `${dynamicAPI}/accountplans/${accountId}/vehicles`
//   return fetchHandler(url, {
//     method: 'GET'
//   })
// }

// export function downloadFatura(planId, invoiceId) {
//   const url = `${dynamicAPI}/minha-veloe/fatura/${planId}/detalhe/${invoiceId}/pdf`
//   return fetchHandler(url, {
//     method: 'GET'
//   })
// }
