import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { func, bool, oneOf, object } from 'prop-types'
import { SimpleModal } from 'app/elements/Modal'
import moment from 'moment'
// Assets
import IcClose from '../../elements/Icon/ic-close'
import IcCheckImg from '../../../static/ic-check.png'
import { blueGray, white } from 'styles/settings/colors'
// UIComponents
import Button from 'app/elements/Button'
// Actions
import {
  fetchGetVehicles,
  fetchGetManageInfsTop5,
  fetchLogout,
  saveManageInf,
  updateManageInf,
  deleteManageInf
} from 'alelo-logic/actions'
// Styles
import {
  Header,
  Back,
  SuccessContent,
  SuccessMessage,
  SuccessContainer,
  WrapperContentModal,
  WrapperButton
} from './styles'
// Components
import ManageForm from 'app/elements/FormEditManageInfo'
import ChooseGeneralInformation from '../../elements/ChooseGeneralInformation'

class GeneralInformationModal extends Component {
  static defaultProps = {
    controlled: false,
    isOpen: false,
    hideSuccessButton: false,
    toggleModal: () => {},
    modalStart: '',
    vehicle: {},
    isUpdating: false
  }
  static propTypes = {
    controlled: bool,
    isOpen: bool.isRequired,
    toggleModal: func,
    hideSuccessButton: bool,
    vehicle: object,
    isUpdating: bool,
    modalStart: oneOf(['edit', 'remove', 'include'])
  }

  state = {
    onSucessUpdate: false,
    informationSelected: null,
    // 'chooseRemove', 'chooseEdit', 'form', 'success'
    modalType: 'chooseEdit',
    successMessage: 'Sucesso',
    successBtnTitle: 'OK',
    loading: false,
    msgError: '',
    dayMonth: moment().format('DD/MM/YYYY'),
    hourMinute: moment().format('HH:mm')
  }

  componentDidMount() {
    const {
      fetchGetInfsTop5 = fetchGetManageInfsTop5,
      match,
      fetchLogoutMount = fetchLogout,
      history
    } = this.props
    this.prepareModal()
    if (match.params.id) {
      fetchGetInfsTop5(match.params.id).then(resp => {
        if (resp && (resp.status === 401 || resp.status === 403)) {
          fetchLogoutMount()
          history.push('/conta/login')
        }
      })
    }

    const dayMonth = moment().format('DD/MM/YYYY')
    const hourMinute = moment().format('HH:mm')
    this.setState({ dayMonth, hourMinute })
  }

  prepareModal = () => {
    const { modalStart } = this.props
    if (modalStart === 'edit') {
      this.setState({ modalType: 'form' })
    } else if (modalStart === 'include') {
      this.setState({ modalType: 'chooseEdit' })
    } else if (modalStart === 'remove') {
      this.setState({ modalType: 'chooseRemove' })
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.modalStart !== this.props.modalStart) {
      this.prepareModal()
    }
  }

  onSubmit = async values => {
    this.setState({ loading: true })
    const {
      saveManageInfSubmit = saveManageInf,
      updateManageInfSubmit = updateManageInf,
      vehicle,
      controlled,
      handleSubmitExternal,
      modalStart,
      match
    } = this.props
    const { informationSelected } = this.state
    const year = moment().year()
    const dateTime = `${values.data}/${year} ${values.hora}`
    const isoDate = moment(dateTime, 'DD/MM/YYYY HH:mm').toISOString()
    const data = {
      ...(vehicle.id && { id: vehicle.id }),
      dataInicioVigencia: isoDate,
      descricao: values.descricao,
      tipoInfo: modalStart === 'edit' ? vehicle.tipoInfo : informationSelected,
      placa: vehicle.codIdentificador ? vehicle.veiculo.placa : '',
      conta: match.params.id,
      codIdentificador: vehicle ? vehicle.codIdentificador : ''
    }
    if (controlled) {
      this.setState({ loading: false })
      return handleSubmitExternal(data)
    }
    if (modalStart === 'edit') {
      updateManageInfSubmit({ data })
        .then(resp => {
          this.setState({
            modalType: 'success',
            successMessage: 'Informação alterada com sucesso',
            loading: false
          })
        })
        .catch(error => {
          error.json().then(reponse => this.setState({ loading: false, msgError: reponse.message }))
        })
    } else {
      saveManageInfSubmit({ data })
        .then(resp => {
          this.setState({
            modalType: 'success',
            successMessage: 'Informação adicionada com sucesso',
            loading: false
          })
        })
        .catch(error => {
          error.json().then(reponse => this.setState({ loading: false, msgError: reponse.message }))
        })
    }
  }

  onRemoveInfo = () => {
    this.props
      .deleteManageInf()
      .then(() => {
        this.setState({
          modalType: 'success',
          successMessage: 'Informação removida com sucesso'
        })
      })
      .catch(error => this.setState({ msgError: error }))
  }

  handleChangeInformation = informationSelected => {
    this.setState({ informationSelected })
  }

  chooseOnSubmit = () => {
    this.setState({ modalType: 'form' })
    window.dataLayer.push({
      event: 'event',
      eventAction: 'clique:sucesso',
      eventCategory: 'veloe:area-logado:meus-produtos:adicionar-informacao',
      eventLabel: 'ok'
    })
  }

  handleCloseModal = () => {
    this.setState({ modalType: 'chooseEdit', informationSelected: null, msgError: '' })
    this.props.toggleModal()
  }

  handleCloseModalSuccess = () => {
    this.handleCloseModal()
    window.dataLayer.push({
      event: 'event',
      eventAction: 'clique:ok',
      eventCategory: 'veloe:area-logado:meus-produtos:adicionar-informacao',
      eventLabel: 'sucesso'
    })
  }

  renderContentModal = () => {
    const { hideSuccessButton, manageInfsReducer, modalStart, vehicle } = this.props
    const { updateLoading, manageInfsTop5 } = manageInfsReducer
    const {
      informationSelected,
      modalType,
      successMessage,
      successBtnTitle,
      loading,
      msgError,
      dayMonth,
      hourMinute
    } = this.state
    switch (modalType) {
      case 'chooseEdit':
        return (
          <WrapperContentModal>
            <div>
              <Header>Selecione a informação que deseja Incluir.</Header>
              <ChooseGeneralInformation handleChangeInformation={this.handleChangeInformation} />
            </div>
            <WrapperButton>
              <Button
                text="OK"
                buttonType="darkBlue"
                onClick={this.chooseOnSubmit}
                loading={false}
                disabled={!informationSelected}
              />
            </WrapperButton>
          </WrapperContentModal>
        )
      case 'chooseRemove':
        return (
          <WrapperContentModal>
            <div>
              <Header>Selecione a informação que deseja remover.</Header>
              <ChooseGeneralInformation handleChangeInformation={this.handleChangeInformation} />
            </div>
            <WrapperButton>
              <Button
                text="OK"
                buttonType="darkBlue"
                onClick={this.onRemoveInfo}
                loading={updateLoading}
                disabled={!informationSelected || updateLoading}
              />
            </WrapperButton>
          </WrapperContentModal>
        )
      case 'success':
        return (
          <SuccessContainer>
            <SuccessContent>
              <img src={IcCheckImg} alt="Success Check" />
              <SuccessMessage>{successMessage}</SuccessMessage>
            </SuccessContent>
            {!hideSuccessButton && (
              <WrapperButton justify="center">
                <Button
                  text={successBtnTitle}
                  onClick={this.handleCloseModalSuccess}
                  buttonType="transparent"
                />
              </WrapperButton>
            )}
          </SuccessContainer>
        )
      // Default is ModalType=form
      default:
        const formTitle =
          informationSelected === 'INFORMACAO_1'
            ? 'Informação 1'
            : informationSelected === 'INFORMACAO_2'
            ? 'Informação 2'
            : 'Informação 3'

        const parseDate = isoDate => moment(isoDate).format('DD/MM/YYYY')
        const parseHour = isoDate => moment(isoDate).format('HH:mm')
        return (
          <ManageForm
            isLoading={updateLoading}
            initialValues={
              modalStart === 'edit'
                ? {
                    data: parseDate(vehicle.dataInicioVigencia),
                    hora: parseHour(vehicle.dataInicioVigencia),
                    descricao: vehicle.descricaoAtual
                  }
                : { data: dayMonth, hora: hourMinute }
            }
            onSubmit={this.onSubmit}
            formTitle={modalStart === 'edit' ? 'Alterar' : formTitle}
            dateTimeDisabled={modalStart === 'edit'}
            top5List={manageInfsTop5}
            loading={loading}
            msgError={msgError}
          />
        )
    }
  }

  render() {
    const { isOpen, toggleModal, modalType } = this.props
    const iconColor = modalType === 'success' ? white : blueGray
    if (isOpen) {
      return (
        <SimpleModal hideModal={toggleModal}>
          {this.renderContentModal()}
          <Back onClick={this.handleCloseModal}>
            <IcClose width={24} height={24} fill={iconColor} />
          </Back>
        </SimpleModal>
      )
    }
    return null
  }
}

const mapStateToProps = ({ reducer }) => ({
  manageInfsReducer: reducer.manageInfs
})

// eslint-disable-next-line no-class-assign
GeneralInformationModal = withRouter(GeneralInformationModal)
export default connect(mapStateToProps, {
  fetchGetManageInfsTop5,
  fetchGetVehicles,
  fetchLogout,
  saveManageInf,
  updateManageInf,
  deleteManageInf
})(GeneralInformationModal)
