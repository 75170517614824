import { Box } from 'grid-styled'

import styled from 'styled-components'
import media from '../tools/media'
import rem from '../tools/rem'

export default styled(Box)`
  margin: 0 auto;
  max-width: ${rem('1000px')};
  padding: 0 ${rem('20px')};
  width: 100%;

  ${media.large`
        padding: 0 ${rem('30px')};
    `};
`
