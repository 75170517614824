import styled from 'styled-components'
import media from 'styles/tools/media'
import rem from 'styles/tools/rem'
import {
  title32GalanoBold,
  title26GalanoBold,
  title16Galano,
  txt12OpenSans,
  txt14OpenSans,
  txt14OpenSansBold
} from 'styles/settings/types'
import { paleGrayTwo, linkNavyBlue, darkBlue, darkGrey } from 'styles/settings/colors'

export const Title = styled.h1`
  ${title26GalanoBold} color: ${linkNavyBlue};
  margin: ${rem('40px')} 0 ${rem('30px')} 0;

  ${media.large`
        ${title32GalanoBold}

    `};
`

export const Subtitle = styled.div`
  color: ${darkBlue};
  ${title26GalanoBold} font-weight: bold;
  margin: 12px 0 36px 0;
`

export const Legend = styled.div`
  color: ${darkGrey};
  ${txt14OpenSans};
  letter-spacing: 1px;
  line-height: 1.5;
  margin-top: 30px;
  margin-bottom: 16px;
  text-transform: uppercase;
`

export const DateLabel = styled.div`
  ${txt14OpenSansBold};
  color: ${darkBlue};
  margin: 0 8px;
`

export const Buttons = styled.div`
  display: flex;
  flex-direction: column;

  ${media.large`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
  `};
`

export const Separator = styled.hr`
  border: 1px solid ${paleGrayTwo};
  margin-left: -20px;
  width: calc(100% + 36px);
`

export const Item = styled.div`
  display: flex;
  flex-direction: row;
  width: 223px;
  cursor: pointer;
  align-items: center;
  box-shadow: 0 12px 27px 0 rgba(221, 231, 243, 0.66);
`

export const ItemDescription = styled.div`
  flex-direction: column;
  flex: 0.85;
  padding: 22px 14px;
`

export const CardTitle = styled.div`
  ${title16Galano};
  font-weight: 500;
  color: #14110c;
`

export const CardSubtitle = styled.div`
  ${txt12OpenSans};
  color: #656769;
`

export const SelectCreditCardWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`

export const CardFlagList = styled.div`
  padding: 8px 0 0 12px;
`

export const CardFlagItem = styled.span`
  ${props => (props.hideCard ? `display: none` : 'display: inline')};
  margin-right: 8px;
`

export const FormField = styled.fieldset`
  border: 0;
  padding: 0;
  margin: 0;
  min-width: 0;

  ${({ disabled }) =>
    disabled &&
    `
  opacity: 0.4;
  cursor: not-allowed
  `};
`
