import React from 'react'
import { connect } from 'react-redux'
import { hideModal } from '../ModalRoot/actions'
import Modal from '../../elements/QrCodeModal'

function QrCodeModal(props) {
  return (
    <Modal
      convenio={props.convenio}
      onClose={props.hideModal}
      userInfo={props.user}
      nextPaymentInfo={props.nextPaymentDate}
    />
  )
}

const mapStateToProps = ({ reducer }) => {
  const { user, planData, banks } = reducer
  const bank = banks.find(b => b.value === 1)
  return {
    user,
    nextPaymentDate: planData.nextPaymentDate,
    convenio: bank && bank.convenio
  }
}

export default connect(mapStateToProps, { hideModal })(QrCodeModal)
