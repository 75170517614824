const initialState = {
  avatar: 0
}

export default function headerReducer(state = initialState, action = {}) {
  switch (action.type) {
    case 'CHANGE_AVATAR':
      return {
        ...state,
        avatar: action.avatar
      }
    default:
      return state
  }
}
