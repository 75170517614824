import React from 'react'

import WarningBase from './base'
import illustrationManMegaphone from 'static/illustration-man-megaphone.svg'

const Toll = () => (
  <WarningBase
    content={
      <div>
        Ande sempre a <b>40 km/h ou menos</b>, conforme a legislação local.*
        <br />
        Mantenha ao menos 30 (trinta) metros do veículo à sua frente.
        <br />
        <br />
        <b>*Veículos com mais de 6 eixos não poderão exceder 20km/h ou inferior se previsto.</b>
      </div>
    }
    image={
      <img src={illustrationManMegaphone} className="WarningImage-megaphone" alt="man-megaphone" />
    }
  />
)

export default Toll
