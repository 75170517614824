import { updateUserAddress, deleteUserAddress } from '../services/userService'

import types from './types'

const { FETCH_ADDRESS_UPDATE, FETCH_ADDRESS_DELETE } = types

export function fetchUpdateAddress(data) {
  return dispatch =>
    updateUserAddress(data).then(response => {
      dispatch({
        type: FETCH_ADDRESS_UPDATE,
        state: data
      })
    })
}

export function fetchDeleteAddress(data) {
  return dispatch =>
    deleteUserAddress(data).then(address => {
      dispatch({
        type: FETCH_ADDRESS_DELETE,
        state: address
      })
    })
}
