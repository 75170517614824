import styled from 'styled-components'
import { paleGrayTwo } from 'styles/settings/colors'

export const BaseTable = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

export const BaseTableHeader = styled.div`
  border-top: 1px solid ${paleGrayTwo};
  border-bottom: 1px solid ${paleGrayTwo};
  display: flex;
  flex-direction: row;
  width: 100%;
`

export const BaseTableHeaderCell = styled.p`
  display: inline-block;
  margin: 10px;
  overflow: hidden;
  ${({ width }) => `width: ${width};`};
`

export const BaseTableRow = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`

export const BaseTableRowCell = styled.p`
  display: inline-block;
  margin: 10px;
  overflow: hidden;
  white-space: nowrap;
  ${({ width }) => `width: ${width};`};
`
