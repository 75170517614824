import React from 'react'
import Icon from '../Icon/Icon'

import {
  FooterNavigation,
  LogoSocial,
  NavigationColumn,
  NavigationContent,
  NavigationLabel,
  DescriptionItem,
  VeloeLogo,
  TelephoneNumber,
  Row
} from './styles'

export const Logo = 'height: 30px; width: 74px;'

function FooterNavigationLarge() {
  return (
    <FooterNavigation>
      <NavigationContent>
        <NavigationColumn>
          <LogoSocial>
            <Icon
              icon="veloe-logo"
              svgStyle={VeloeLogo}
              width="74"
              height="31"
              viewBox="0 0 74 31"
            />
          </LogoSocial>
          <Row>
            <NavigationLabel>Central de relacionamento para clientes</NavigationLabel>
            <TelephoneNumber>3003 3510</TelephoneNumber>
            <DescriptionItem>Capitais e regiões metropolitanas</DescriptionItem>
          </Row>
          <Row>
            <TelephoneNumber>0800 208 3510</TelephoneNumber>
            <DescriptionItem>Demais cidades</DescriptionItem>
            <DescriptionItem>24 horas 7 dias por semana</DescriptionItem>
          </Row>
        </NavigationColumn>
        <NavigationColumn>
          <Row>
            <NavigationLabel bold>SAC</NavigationLabel>
          </Row>
          <Row>
            <TelephoneNumber>0800 208 2010</TelephoneNumber>
            <DescriptionItem>24 horas 7 dias por semana</DescriptionItem>
          </Row>
        </NavigationColumn>
        <NavigationColumn>
          <Row>
            <NavigationLabel bold>Ouvidoria</NavigationLabel>
          </Row>
          <Row>
            <TelephoneNumber>0800 202 3278</TelephoneNumber>
            <DescriptionItem>De segunda a sexta,</DescriptionItem>
            <DescriptionItem>das 10h às 16h</DescriptionItem>
          </Row>
        </NavigationColumn>
      </NavigationContent>
    </FooterNavigation>
  )
}

export default FooterNavigationLarge
