import types from '../actions/types'

const { TOGGLE_FOOTER } = types
const initialState = {
  toggleFooter: false
}

function uiReducer(state = initialState, action = {}) {
  switch (action.type) {
    case TOGGLE_FOOTER: {
      return {
        ...state,
        toggleFooter: !state.toggleFooter
      }
    }
    default:
      return state
  }
}

export default uiReducer
