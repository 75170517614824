import styled from 'styled-components'

import rem from 'styles/tools/rem'
import media from 'styles/tools/media'
import { white } from 'styles/settings/colors'

import {
  txt12OpenSans,
  title32GalanoBold,
  title144GalanoBold,
  title18Galano,
  title44GalanoBold
} from 'styles/settings/types'

import { PageTitle } from '../../templates/TemplateRecharge/styles'

export const Text = styled(PageTitle)`
  ${txt12OpenSans};
  letter-spacing: ${rem('1px')};
`

export const Cash = styled.p`
  color: ${white};
  margin: 0;
  align-items: center;
  display: inline-flex;
  justify-content: space-between;

  ${media.large`
        display: flex;
    `};
`

export const CashPrefix = styled.span`
  ${title18Galano} line-height: 1;
  margin-right: ${rem('12px')};

  ${media.large`
        ${title32GalanoBold}
    `};
`

export const CashValue = styled.span`
  ${title44GalanoBold} line-height: 1;

  ${media.large`
        ${title144GalanoBold}
        letter-spacing: .072em;
    `};
`

export const RechargeValue = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  margin: ${rem('28px')} 0 ${rem('5px')};

  ${media.large`
        margin: 0;
        display: block;
    `} ${Text} {
    ${media.large`
            margin: ${rem('50px')} 0 ${rem('25px')};
            text-transform: uppercase;
        `};
  }
`
