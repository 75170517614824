import React from 'react'
import { utils } from 'alelo-logic'

import { violet, orange, yellow } from 'styles/settings/colors'
import ConsumptionChartBar from '../../elements/ConsumptionChartBar'
import ConsumptionChartIndicator from '../../elements/ConsumptionChartIndicator'

import { Title, Text, IndicatorList, Usage, IndicatorItem } from './styles'

const { consumptionLabel, indicatorLabel } = require('./data.json')

const { currencyParser } = utils
const defaultColors = [violet, orange, yellow]
const semiautomaticoColors = ['#CCC', '#CCC', yellow]

const toPercentage = (value, total) => {
  const decimal = value / total
  return decimal * 100
}

// TODO: move to logic
const calculateDataPercentage = (maxValue, dataArray = []) => {
  if (maxValue) {
    return dataArray.map(data => toPercentage(data, maxValue))
  }

  const total = dataArray.reduce((data, nextData) => data + nextData, 0)

  return dataArray.map(data => toPercentage(data, total))
}

const ConsumptionChart = ({ consumption, balance, isSemiautomatico }) => {
  const colors = isSemiautomatico ? semiautomaticoColors : defaultColors
  const data = (consumption && consumption.spending) || (balance && balance.spending)
  const consumptionTypes = Object.keys(indicatorLabel)
  const consumptionValues = consumptionTypes.map(consumptionType => data[consumptionType])
  const dataPercentage =
    calculateDataPercentage(consumption && consumption.limit, consumptionValues) || []
  const totalPercentage = dataPercentage.reduce((percentage, nextData) => percentage + nextData, 0)

  return (
    <section>
      {balance && <Title>últimos 30 dias</Title>}

      <ConsumptionChartBar colors={colors} values={dataPercentage} />

      {consumption && (
        <Usage>
          <span>{totalPercentage}%</span>

          <span>De {currencyParser(consumption.limit, false, 'R$ ')}</span>
        </Usage>
      )}
      <IndicatorList>
        <IndicatorItem>
          <Text>{consumptionLabel}</Text>
        </IndicatorItem>
        {consumptionTypes.map((indicator, index) => (
          <IndicatorItem key={indicator}>
            <ConsumptionChartIndicator
              name={indicatorLabel[indicator]}
              color={colors[index]}
              value={currencyParser(data[indicator])}
            />
          </IndicatorItem>
        ))}
      </IndicatorList>
    </section>
  )
}

export default ConsumptionChart
