import createAutoCorrectedDatePipe from 'text-mask-addons/dist/createAutoCorrectedDatePipe'

export const dddMask = (value, previousValue) => {
  const onlyNums = value && value.replace(/[^\d]/g, '')

  if (!value || (previousValue && value.length < previousValue.length)) {
    return onlyNums.slice(0, 1)
  }

  if (onlyNums.length < 2) {
    return onlyNums
  }

  return `(${onlyNums.slice(0, 2)})`
}

const amexMaskFormat = value => {
  if (value.length <= 4) {
    return value
  }
  if (value.length <= 10) {
    return `${value.slice(0, 4)} ${value.slice(4)}`
  }
  if (value.length <= 15) {
    return `${value.slice(0, 4)} ${value.slice(4, 10)} ${value.slice(10)}`
  }
  return `${value.slice(0, 4)} ${value.slice(4, 10)} ${value.slice(10, 15)}`
}

export const creditCardMask = value => {
  if (!value) {
    return value
  }

  const onlyNums = value.replace(/[^\d]/g, '')

  if (onlyNums[0] === '3' && (onlyNums[1] === '4' || onlyNums[1] === '7')) {
    return amexMaskFormat(onlyNums)
  } else {
    if (onlyNums.length <= 4) {
      return onlyNums
    }
    if (onlyNums.length <= 8) {
      return `${onlyNums.slice(0, 4)} ${onlyNums.slice(4)}`
    }

    if (onlyNums.length <= 12) {
      return `${onlyNums.slice(0, 4)} ${onlyNums.slice(4, 8)} ${onlyNums.slice(8, 12)}`
    }

    return `${onlyNums.slice(0, 4)} ${onlyNums.slice(4, 8)} ${onlyNums.slice(
      8,
      12
    )} ${onlyNums.slice(12, 16)}`
  }
}

export const cvcMask = (value, maxLength = 3) => {
  if (!value) {
    return value
  }

  const onlyNums = value.replace(/[^\d]/g, '')

  return `${onlyNums.slice(0, maxLength)}`
}

export const numberMask = (value, maxLength) => {
  if (!value) {
    return value
  }

  const onlyNums = value.replace(/[^\d]/g, '')

  if (onlyNums.length <= maxLength) {
    return onlyNums
  }
  return `${onlyNums.slice(0, maxLength)}`
}

export const accountDigitMask = (value, maxLength) => {
  if (!value) {
    return value
  }

  const onlyNumsAndSpecialCharacters = value.replace(/[^\d|x|X]/g, '').toUpperCase()

  if (onlyNumsAndSpecialCharacters.length <= maxLength) {
    return onlyNumsAndSpecialCharacters
  }
  return `${onlyNumsAndSpecialCharacters.slice(0, maxLength)}`
}

export const autoCorrectedExpireDatePipe = createAutoCorrectedDatePipe('mm/yy')

export const validThruMask = value => {
  if (!value) {
    return value
  }

  const onlyNums = value.replace(/[^\d]/g, '')

  if (onlyNums.length <= 2) {
    return onlyNums
  }
  return `${onlyNums.slice(0, 2)}/${onlyNums.slice(2, 6)}`
}

export const cepMask = number => {
  if (!number) return ''
  let newNumber = number.replace(/\D/g, '')
  newNumber = newNumber.substring(0, 8)
  newNumber = newNumber.replace(/^(\d{5})(\d)/, '$1-$2')
  return newNumber
}
