import styled from 'styled-components'
import media from 'styles/tools/media'
import rem from 'styles/tools/rem'

export const Content = styled.div`
  position: relative;

  ${media.small`
    padding-bottom: 213px;
  `}
`

export const BannerInfoPagApp = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;

  width: 644px;
  height: 490px;

  ${media.large`
    width: ${rem('644px')};
  `};
  ${media.small`
  width: ${rem('300px')};
  height: auto;
  `}
`

export const ButtomsApp = styled.div`
  position: relative;
  top: 240px;
  left: 199px;
  z-index: 2;

  ${media.small`
  width: 58%;
  top: 110px;
  left: 89px;
`}
`
