import React, { Component } from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'
import {
  fetchInvoiceDetails,
  resetDashboardInvoices,
  fetchDashboardResume,
  fetchLogout
} from 'alelo-logic/actions'
import { utils } from 'alelo-logic'
import { ISODateFormat, capitalize } from 'alelo-logic/utils'
import { Box, Flex } from 'grid-styled'
import { paymentMethodDescription } from '../../../utils/paymentMethods'
import Content from '../../../styles/objects/Content'
import InvoiceComplete from '../../modules/InvoiceComplete'
// import InvoiceFooter from '../../modules/InvoiceFooter'
import InvoiceHeader from '../../modules/InvoiceHeader'
import InvoiceResume from '../../modules/InvoiceResume'
import InvoiceValue from '../../modules/InvoiceValue'
import PlanResume from '../../modules/PlanResume'
import InvoiceDetailsHeaderAmount from '../../elements/InvoiceDetailsHeaderAmount'
import rem from '../../../styles/tools/rem'
import PageLoader from '../../modules/PageLoader'
import { setLoading } from '../../modules/PageLoader/actions'

import { BoxPlanResume, Resume, Structure } from './styles'

class InvoiceDetails extends Component {
  async componentWillMount() {
    this.props.setLoading(true)
    await this.fetchDetails()
  }
  shouldComponentUpdate(nextProps) {
    const hasPlanChange =
      this.props.accountPlans.activePlanId !== nextProps.accountPlans.activePlanId
    const params = this.props.match.params || {}
    const currentInvoice = this.props.invoices.data.find(
      invoice => invoice.numero === parseInt(params.fatura)
    )
    if (hasPlanChange) {
      this.props.setLoading(true)
      this.props.invoiceDetails(
        this.props.match.params.id,
        this.props.match.params.fatura,
        currentInvoice ? currentInvoice.qtdTransacoes : 1
      )
    }
    return true
  }

  async fetchDetails() {
    const params = this.props.match.params || {}
    const currentInvoice = this.props.invoices.data.find(
      invoice => invoice.numero === parseInt(params.fatura)
    )

    try {
      this.props.setLoading(true)
      await this.props.invoiceDetails(
        params.id,
        params.fatura,
        currentInvoice ? currentInvoice.qtdTransacoes : 1
      )
      await this.props.fetchResume(params.id)
      window.dataLayer = window.dataLayer || []
      window.dataLayer.push({
        event: 'event',
        eventCategory: 'veloe:area-logado:faturas',
        eventAction: 'clique:fatura:sucesso',
        eventLabel: 'fatura:sucesso'
      })
    } catch (err) {
      window.dataLayer = window.dataLayer || []
      window.dataLayer.push({
        event: 'event',
        eventCategory: 'veloe:area-logado:faturas',
        eventAction: 'clique:fatura:erro',
        eventLabel: `fatura:${err}`
      })
    }
  }

  render() {
    const { details, resume, user, bankData, match } = this.props
    const email = user.email || ''
    const vehicles = resume.vehicles || []
    const title = 'Atual'
    const vencimento = 'Em Aberto'
    const faturaId = match.params.fatura

    const currentInvoice = this.props.invoices.data.find(
      invoice => invoice.numero === parseInt(faturaId)
    )

    if (!details) {
      return null
    }
    return (
      <PageLoader>
        {details && resume && (
          <Structure ref={node => (this.printRef = node)}>
            <InvoiceHeader
              title={
                faturaId === '0' || !details.dataVencimento
                  ? title
                  : !_.isEmpty(details)
                  ? `${capitalize(ISODateFormat(details.dataVencimento, 'MMMM'))}/${ISODateFormat(
                      details.dataVencimento,
                      'YY'
                    )}`
                  : ''
              }
              status={currentInvoice ? currentInvoice.status : ''}
              email={email}
              nfe={details.linkNFE || ''}
              linkFaturaPDF={details.linkFaturaPDF || ''}
              linkBoleto={details.linkBoleto || ''}
              printRef={this.printRef}
              transacoes={details && details.transacoes && details.transacoes.length}
              nsu={currentInvoice ? currentInvoice.nsu : ''}
            />
            <Resume>
              <Content>
                <Flex align="stretch" justify="space-between">
                  <Box mb={[rem('20px'), 0]} pr={[0, 20]} width={[1, 3 / 12]} />
                  <Box pl={[0, 20]} pb={rem('20px')} pt={rem('20px')} width={[1, 8 / 12]}>
                    <InvoiceValue
                      currentInvoice={!details.dataVencimento && !details.formaPagamento}
                      date={
                        faturaId === '0' || !details.dataVencimento
                          ? vencimento
                          : ISODateFormat(details.dataVencimento)
                      }
                      paymentMethod={
                        !_.isEmpty(details) ? paymentMethodDescription[details.formaPagamento] : ''
                      }
                      bankName={bankData && bankData.bankName ? bankData.bankName : ''}
                      total={!_.isEmpty(details) ? utils.currencyParser(details.total * -1) : ''}
                    />
                  </Box>
                </Flex>
              </Content>
            </Resume>
            <Content>
              <Flex mt={[0, rem('35px')]} align="stretch" justify="space-between">
                <Box mb={[rem('20px'), 0]} pr={[0, 20]} width={[1, 3 / 12]}>
                  <BoxPlanResume>
                    <PlanResume
                      active={resume.active}
                      category={resume.category}
                      name={resume.name}
                      vehicles={vehicles.length}
                    />
                  </BoxPlanResume>
                </Box>
                <Box mb={[rem('20px'), 0]} pl={[0, 20]} width={[1, 8 / 12]}>
                  <InvoiceResume
                    benefitsValue={details.totalBeneficios}
                    dataServices={details.servicosVeloe}
                    dataConsumption={details.consumos}
                    subtotalValue={details.subTotal}
                    totalValue={details.total}
                  />
                </Box>
              </Flex>
            </Content>
            {details && details.transacoes && <InvoiceComplete data={details} />}
            <InvoiceDetailsHeaderAmount amount={details.total * -1 || 0} />
            {/* <InvoiceFooter /> */}
          </Structure>
        )}
      </PageLoader>
    )
  }
}

const mapStateToProps = ({ reducer }) => ({
  accountPlans: reducer.accountPlans,
  details: reducer.invoiceDetails.details,
  resume: reducer.dashboardResume,
  user: reducer.account,
  invoices: reducer.dashboardInvoices,
  bankData: reducer.accountPlans.plans.find(
    plan => plan.accountId === reducer.accountPlans.activePlanId
  )
    ? reducer.accountPlans.plans.find(plan => plan.accountId === reducer.accountPlans.activePlanId)
        .debitPaymentData
    : null
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  resetInvoices: () => dispatch(resetDashboardInvoices()),
  invoiceDetails: (planId, faturaId, qtdTransacoes) =>
    dispatch(fetchInvoiceDetails(planId, faturaId, qtdTransacoes))
      .then(() => dispatch(setLoading(false)))
      .catch(resp => {
        if (resp && (resp.status === 401 || resp.status === 403)) {
          dispatch(fetchLogout())
          ownProps.history.push('/conta/login')
        }
      }),

  fetchResume: planId =>
    dispatch(fetchDashboardResume(planId))
      .then(() => dispatch(setLoading(false)))
      .catch(resp => {
        if (resp && (resp.status === 401 || resp.status === 403)) {
          dispatch(fetchLogout())
          ownProps.history.push('/conta/login')
        }
      }),

  setLoading: isLoading => {
    dispatch(setLoading(isLoading))
  }
})

export default connect(mapStateToProps, mapDispatchToProps)(InvoiceDetails)
