import types from '../actions/types'

const { SET_AUTHENTICATION, FETCH_CANCEL_ACCOUNT } = types

const initialValues = {}

function accountReducer(state = initialValues, action = {}) {
  switch (action.type) {
    case SET_AUTHENTICATION: {
      return {
        ...state,
        ...action.credentials
      }
    }
    case FETCH_CANCEL_ACCOUNT: {
      return {
        ...state,
        active: action.state
      }
    }
    default:
      return state
  }
}

export default accountReducer
