import styled from 'styled-components'

import content from 'styles/_objects/content'
import rem from 'styles/tools/rem'
import media from 'styles/tools/media'

import { txt14OpenSans, title40GalanoBold } from 'styles/settings/types'
import { linkNavyBlue, darkGrey, skyBlue, lighterGrey } from 'styles/settings/colors'

export const Aside = styled.div`
  display: none;

  ${media.large`
        background-color: ${skyBlue};
        display: block;
        height: 100vh;
        margin-right: ${rem('120px')};
        position: relative;
        width: 32.4%;
        img {
            bottom: 0;
            position: absolute;
            width: 100%;
        }
    `};
`

export const Header = styled.div`
  border-bottom: ${rem('2px')} solid ${lighterGrey};
  border-top: ${rem('4px')} solid ${skyBlue};
  display: block;
  width: 100%;

  ${media.large`
        border: 0;
        margin-top: ${rem('85px')};

    `};
`

export const Text = styled.a`
  ${txt14OpenSans} font-weight: bold;
  line-height: 1;
  color: ${linkNavyBlue};
  display: flex;
  width: ${rem('100px')};
  margin: ${rem('17px')} 0 ${rem('21px')} ${rem('29px')};

  ${media.large`
        cursor: pointer;
        margin-left: 0;
    `};
`

export const Veloe = styled.span`
  display: flex;
  justify-content: center;
  margin: ${rem('24px')} 0 ${rem('33px')} 0;
  img {
    height: ${rem('32px')};
    width: ${rem('74px')};
  }
`

export const Content = styled.div`
  width: 100%;

  ${media.large`
        display: flex;
        flex-direction: column;
        width: ${rem('456px')};
    `};
`

export const Title = styled.h1`
  ${title40GalanoBold} display: none;
  width: 100%;

  ${media.large`
        color:${linkNavyBlue};
        display: block;
        font-weight: bold;
        letter-spacing: ${rem('0.4px')};
        margin-bottom: ${rem('20px')};
        margin-top: ${rem('68px')};
        max-width: ${rem('450px')};
    `};
`

export const Description = styled.p`
  ${txt14OpenSans} color: ${darkGrey};
  font-weight: 200;
  letter-spacing: ${rem('0.2px')};
  line-height: 1.71;
  margin-bottom: ${rem('25px')};
  white-space: normal;
  width: 100%;
  box-sizing: border-box;
  padding: 0 ${rem('40px')} ${rem('20px')};

  ${media.large`
        width: ${rem('340px')};
        padding: 0;
    `};
`

export const Container = styled.div`
  ${content} width: 100%;

  ${media.large`
        min-width: ${rem('456px')};
        padding: ${rem('20px')} ${rem('20px')} 0 0;
    `};
`
