import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import Helmet from 'react-helmet'
import {
  fetchGetUser,
  fetchDashboardInvoices,
  resetDashboardInvoices,
  requestNewBoleto,
  downloadTicketFile,
  fetchLogout
} from 'alelo-logic/actions'
import AddItem from '../../modules/AddItem'
import { showModal } from '../../modules/ModalRoot/actions'
import { BANK_SLIP_MODAL, CONFIRM_UPDATE } from '../../../constants/ModalTypes'
import InvoicesList from '../../modules/InvoicesList'
import { setLoading } from '../../modules/PageLoader/actions'
import pfErrorImage from 'static/traffic-signal-error2.svg'

import { Heading, Paragraph, BoxHeader, Content } from './styles'

const {
  helmetTitle,
  invoiceTitle,
  invoiceDescription,
  noInvoiceTitle,
  noInvoiceDescription
} = require('./data.json')

let bankSlipData = {}

const DashboardInvoice = ({
  setLoading,
  resetInvoices,
  getUserData,
  match,
  fetchInvoices: fetchInvoicesProp,
  showRequestNewBoletoResponseModal,
  requestBoleto,
  showErrorModal,
  invoices = [],
  downloadCSVInvoice
}) => {
  const [loadingMore, setLoadingMore] = useState(false)
  const { params } = match || {}
  useEffect(() => {
    prepareInvoices()
  }, [])

  useEffect(() => {
    if (invoices.data.length > 0) {
      invoices.data.sort((a, b) => {
        if (!a.dataVencimento || !b.dataVencimento) {
          return 1
        }
        return new Date(b.dataVencimento) - new Date(a.dataVencimento)
      })
    }
  }, [invoices])

  const fetchInvoices = nextUrl => {
    setLoadingMore(true)
    fetchInvoicesProp(params.id, nextUrl)
      .then()
      .catch()
      .finally(() => {
        setLoadingMore(false)
      })
  }

  const prepareInvoices = async () => {
    setLoading(true)
    await getUserData()
    await resetInvoices()
    fetchInvoices()
  }

  const handleRequestNewBoleto = async (idCliente, nsu) => {
    try {
      const data = await requestBoleto(idCliente, nsu)
      if (data) {
        bankSlipData = data.solicitarBoletoFatura
        return showRequestNewBoletoResponseModal()
      }
    } catch (error) {
      console.log(error)
      showErrorModal()
    }

    return showErrorModal()
  }

  return (
    <section>
      <Helmet title={helmetTitle} />
      {!invoices || invoices.data.length === 0 ? (
        <Content>
          <BoxHeader>
            <Heading>{noInvoiceTitle}</Heading>
            <Paragraph>{noInvoiceDescription}</Paragraph>
          </BoxHeader>
        </Content>
      ) : (
        <Content>
          <BoxHeader>
            <Heading>{invoiceTitle}</Heading>
            <Paragraph>{invoiceDescription}</Paragraph>
          </BoxHeader>
          <AddItem
            data={invoices.data}
            next={invoices.next.proxima}
            planId={params.id}
            fetchHandler={fetchInvoices}
          >
            <InvoicesList
              requestNewBoleto={handleRequestNewBoleto}
              downloadTicket={downloadCSVInvoice}
              loadingMore={loadingMore}
            />
          </AddItem>
        </Content>
      )}
    </section>
  )
}

const mapStateToProps = ({ reducer }) => ({
  invoices: reducer.dashboardInvoices
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  fetchInvoices: (idCliente, nextURL) =>
    dispatch(fetchDashboardInvoices(idCliente, nextURL))
      .then(() => {
        dispatch(setLoading(false))
      })
      .catch(resp => {
        if (resp && (resp.status === 401 || resp.status === 403)) {
          dispatch(fetchLogout())
          ownProps.history.push('/conta/login')
        }
      }),
  showRequestNewBoletoResponseModal: () => {
    dispatch(
      showModal(BANK_SLIP_MODAL, {
        bankSlipData: bankSlipData
      })
    )
  },
  requestBoleto: (idCliente, nsu) => dispatch(requestNewBoleto(idCliente, nsu)),
  showErrorModal: (accountType = 'pf') =>
    dispatch(
      showModal(CONFIRM_UPDATE, {
        modalTitle: 'Tente de novo mais tarde',
        modalDescription: (
          <span style={{ color: '#FFFFFF' }}>Ops! Não conseguimos emitir seu boleto agora</span>
        ),
        accountType,
        image: pfErrorImage
      })
    ),
  resetInvoices: () => dispatch(resetDashboardInvoices()),
  downloadCSVInvoice: (idCliente, nsu) => {
    dispatch(setLoading(true))
    dispatch(downloadTicketFile(idCliente, nsu))
      .then(response => {
        response.arrayBuffer().then(buffer => {
          if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(new Blob([buffer]), 'Boleto.pdf')
          } else {
            const url = window.URL.createObjectURL(new Blob([buffer]))
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', 'Boleto.pdf')
            document.body.appendChild(link)
            link.click()
          }
        })
      })
      .catch(err => {
        return err.json()
      })
      .then(message => {
        if (message.statusCode === '20' || message.statusName === 'NAO_DISPONIVEL_SOLICITANDO') {
          dispatch(
            showModal(BANK_SLIP_MODAL, {
              bankSlipData: bankSlipData
            })
          )
        } else if (
          message.statusCode === '21' ||
          message.statusName === 'NAO_DISPONIVEL_SOLICITADO_PREVIAMENTE'
        ) {
          dispatch(
            showModal(BANK_SLIP_MODAL, {
              bankSlipData: bankSlipData
            })
          )
        }
      })
      .finally(() => dispatch(setLoading(false)))
  },
  getUserData: () => dispatch(fetchGetUser()),
  setLoading: isLoading => {
    dispatch(setLoading(isLoading))
  }
})

export default connect(mapStateToProps, mapDispatchToProps)(DashboardInvoice)
