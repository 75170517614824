import styled, { keyframes, css } from 'styled-components'

import { hexToRgba } from '../../../utils/hexToRgba'
import { white, darkBlue, lightBlue, informationalBlue } from '../../../styles/settings/colors'

import media from '../../../styles/tools/media'

const showModalContent = keyframes`
    0% {
        opacity: 0;
        transform: translateY(1.875rem) scale(.95);
    }

    100% {
        opacity: 1;
        transform: translateY(0) scale(1);
    }
`

const hideModalContent = keyframes`
0% {
    opacity: 1;
    transform: translateY(0) scale(1);
}

100% {
    opacity: 0;
    transform: translateY(1.875rem) scale(.95);
}
`

const showModal = keyframes`
0% {
    opacity: 0;
}

100% {
    opacity: 1;
}
`

const hideModal = keyframes`
0% {
    opacity: 1;
}

100% {
    opacity: 0;
}
`

export const Overlay = styled.div`
  ${({ modalBlue }) => css`
    align-items: center;
    background: ${modalBlue ? hexToRgba(darkBlue, 0.64) : 'rgba(0, 0, 0, 0.15)'};
    bottom: 0;
    display: flex;
    justify-content: center;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
  `}
`

export const Content = styled.div`
  opacity: 0;
  position: relative;
  transform-origin: bottom;
  transition: 0.4s;
  z-index: 1;
  width: 100%;

  ${({ state }) => {
    switch (state) {
      case 'entered':
        return css`
          animation: 0.4s ${showModalContent} forwards;
        `
      case 'exiting':
        return css`
          animation: 0.4s ${hideModalContent} forwards;
        `
      case 'exited':
        return css`
          display: none;
        `
      default:
        return css`
          display: block;
        `
    }
  }};
  ${media.large`
      max-width: ${({ maxWidth }) => (maxWidth ? `${maxWidth}px` : '41.25rem')};
  `};
`

export const Wrapper = styled.div`
  align-items: center;
  background: rgba(0, 0, 0, 0.2);
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  opacity: 0;
  position: fixed;
  right: 0;
  top: 0;
  transition: 0.4s;
  z-index: 101;

  ${({ state }) => {
    switch (state) {
      case 'entered':
        return css`
          animation: 0.4s ${showModal} forwards;
        `
      case 'exiting':
        return css`
          animation: 0.4s ${hideModal} forwards;
        `
      case 'exited':
        return css`
          display: none;
        `
      default:
        return css`
          display: block;
        `
    }
  }};
`

export const ModalContainer = styled.div`
  background-color: ${white};
  height: 100vh;
  overflow: auto;
  position: relative;
  ${media.large`
    border-radius: ${'0.5rem'};
    box-shadow: 0 ${'0.5rem'} ${'0.5rem'} 0 rgba(0, 0, 0, 0.12);
    height: auto;
  `};
`

export const ModalContent = styled.div`
  ${({ noPadding, center }) => css`
  padding: ${noPadding ? '0 !important' : `${'3.75rem'} ${'1.875rem'}`};
  text-align: ${center ? 'center' : 'default'}
  display: flex;
  justify-content: center;
  flex-direction: column;
`}
  ${media.large`
      ${({ compact }) =>
        compact
          ? css`
              padding: ${'2.5rem'} ${'4rem'};
            `
          : css`
              padding: ${'74rem'} ${'3.75rem'} ${'3.438rem'};
            `}
  `};
`

export const Banner = styled.div`
  background: ${lightBlue};
  display: flex;
  align-items: center;
  justify-content: center;
  height: 20.188rem;
  width: 100%;
  padding: 1.75rem 0 0.938rem 0;

  & img:last-child {
    height: 100%;
  }
`

export const CloseIcon = styled.img`
  position: fixed;
  top: 0.938rem;
  right: 0.938rem;
  width: 1.125rem;
  height: 1.125rem;
  cursor: pointer;
`

export const CopySlipBankCode = styled.span`
  cursor: pointer;
  background: none;
  transition: background 0.8s ease-in;
  img {
    width: 1.125rem;
    height: 1.125rem;
  }

  :hover {
    background: ${lightBlue};
    transition: background 0.8s ease-in;
  }
`

export const TextContent = styled.div`
  padding: 0 1.875rem;
  h1,
  b,
  span,
  p {
    color: ${informationalBlue};
    font-family: 'OpenSans';
    font-size: 1rem;
    line-height: 1.5rem;
  }

  h1 {
    font-family: 'Galano';
    font-size: 2rem;
    line-height: 2.625rem;
  }

  img {
    margin: 0 0.313rem;
  }
`

export const ValuesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 1.25rem 0;

  p {
    margin: 0;
  }
  span {
    font-weight: bold;
  }
`

export const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;
  width: 100%;
  margin-bottom: 2.188rem;
  > button + button {
    margin-left: 1.25rem;
  }
`

export const Button = styled.button`
  ${({ secondary, borderRadius }) => css`
    cursor: pointer;
    padding: 0.75rem 1.5rem;
    margin: 0.625rem 0;
    background: ${secondary ? darkBlue : 'transparent'};
    border: 0.125 solid ${darkBlue};
    border-radius: ${'1.75rem' || borderRadius};
    transition: opacity 0.6s ease-in;
    font-family: 'Galano';
    font-size: 0.875rem;
    font-weight: bold;
    color: ${secondary ? white : darkBlue};

    &:hover {
      opacity: 0.8;
      transition: opacity 0.6 ease-in;
    }
  `}
`
