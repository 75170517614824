import moment from 'moment'

const representativesFactory = {
  toAPI: data => ({
    name: data.name,
    document: data.document,
    relationship: data.relationship,
    relationship_start: data.relationshipStart,
    relationship_ending: data.relationshipEnding,
    nationality: data.nationality,
    email: data.email,
    mobile_phone: {
      number: data.telephone,
      area_code: data.telephoneddd
    }
  }),

  toForm: representative =>
    representative &&
    representative.map(data => ({
      name: data.nome,
      document: data.cpf,
      relationship: data.tipoRelacao.nome,
      relationshipStart: moment(data.dataInicio).format('DD/MM/YYYY'),
      relationshipEnding: moment(data.dataFim).format('DD/MM/YYYY'),
      nationality: data.nacionalidade,
      email: data.email,
      telephone: data.celular && data.celular.toString().substring(2),
      telephoneddd: data.celular && data.celular.toString().substring(0, 2)
    }))
}

export default representativesFactory
