/* eslint-disable */
import {
  VALIDATION_EMAIL,
  VALIDATION_EMAIL_MSG_EMPTY,
  VALIDATION_EMAIL_MSG_INVALID
} from './constants'

export default function validateEmail(value) {
  return !value
    ? VALIDATION_EMAIL_MSG_EMPTY
    : !VALIDATION_EMAIL.test(value)
    ? VALIDATION_EMAIL_MSG_INVALID
    : undefined
}
