import styled from 'styled-components'
import { darkBlue, darkGrey, blueGray } from '../../../styles/settings/colors'
import { title24Galano, txt16OpenSans, txt12OpenSans } from '../../../styles/settings/types'

import rem from '../../../styles/tools/rem'

export const Heading = styled.h1`
  ${title24Galano} color: ${darkBlue};
`

export const ExcludeLink = styled.a`
  color: ${darkGrey};
  cursor: pointer;
  font-family: OpenSans;
  font-size: ${rem('12px')};
  margin-left: ${rem('9px')};
`

export const SecondaryHeading = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding-top: ${rem('32px')};
  border-top: 1px solid rgba(197, 195, 216, 0.5);
`

export const ExcludeLinkContainer = styled.div`
  align-items: center;
  display: flex;
`

export const ShippingText = styled.span`
  ${txt12OpenSans};
  font-weight: bold;
  line-height: 1.67;
  letter-spacing: 1.6px;
  color: ${blueGray};
  text-transform: uppercase;
`

export const ShippingAmount = styled.p`
  ${txt16OpenSans};
  font-weight: bold;
  line-height: 1.5;
  letter-spacing: normal;
  color: ${darkBlue};
  margin: 5px 0;
`

export const ButtonContainer = styled.div`
  margin: -20px 0 20px;
`
