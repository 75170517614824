import React from 'react'

import Icon from '../Icon/Icon'

import { Wrapper, Content, Logo } from './styles'

const HeaderWithBrand = () => {
  return (
    <Wrapper>
      <Content>
        <Icon icon="veloe-logo" svgStyle={Logo} width="74" height="31" viewBox="0 0 74 31" />
      </Content>
    </Wrapper>
  )
}

export default HeaderWithBrand
