import styled from 'styled-components'
import { skyBlue, white } from '../../../styles/settings/colors'

import {
  title36GalanoBold,
  title30GalanoBold,
  title24GalanoBold,
  title20GalanoBold,
  txt18OpenSansLight,
  txt14OpenSans,
  txt12OpenSansLight
} from '../../../styles/settings/types'

import content from '../../../styles/_objects/content'
import media from '../../../styles/tools/media'
import em from '../../../styles/tools/em'
import rem from '../../../styles/tools/rem'

export const Wrapper = styled.div`
  background: ${skyBlue};
`

export const Content = styled.section`
  ${content};

  padding-bottom: ${rem('60px')};
  padding-top: ${rem('60px')};
`

export const Title = styled.h1`
  ${title24GalanoBold};

  color: ${white};

  ${media.large`
        ${title36GalanoBold};
    `};
`

export const Text = styled.p`
  ${txt14OpenSans};

  color: ${white};
  font-weight: 200;

  ${media.large`
        ${txt18OpenSansLight};
    `};
`

export const SmallText = styled.span`
  ${txt12OpenSansLight};

  color: ${white};
  display: block;
  text-transform: uppercase;
`

export const ProtocolTitle = Text.extend`
  border-bottom: solid ${rem('1px')} rgba(255, 255, 255, 0.5);
  display: inline-block;
  margin-bottom: ${rem('24px')};
  padding-bottom: ${rem('24px')};

  ${media.large`
        margin-bottom: ${rem('32px')};
        padding-bottom: ${rem('32px')};
    `};
`

export const Protocol = styled.span`
  ${title20GalanoBold};

  color: ${white};
  display: block;
  letter-spacing: ${em('2.7px')};
  margin-bottom: ${rem('24px')};
  word-break: break-all;

  ${media.large`
        ${title30GalanoBold};
    `};
`

export const SavedDate = SmallText.extend`
  ${media.small`
        text-align: center;
    `};
`
