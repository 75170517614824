import types from '../actions/types'

const { FETCH_SHIPPING_AMOUNT } = types

function shippingAmountReducer(state = null, action = {}) {
  switch (action.type) {
    case FETCH_SHIPPING_AMOUNT:
      return action.state
    default:
      return state
  }
}

export default shippingAmountReducer
