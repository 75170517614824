import * as R from 'ramda'
import _get from 'lodash/get'
import _set from 'lodash/set'
import _invert from 'lodash/invert'

export const removeTypename = obj =>
  JSON.parse(JSON.stringify(obj, (k, v) => (k === '__typename' ? undefined : v)))

export const transformObject = (obj, attrMap) => {
  return Object.entries(attrMap).reduce((acc, [key, value]) => {
    if (!obj) return acc
    if (R.is(Function, value)) {
      _set(acc, key, value(obj))
      return acc
    }
    // if (R.is(Object, value)) {
    //   const newObj = transformObject(obj, value)
    //   return _set(acc, key, newObj)
    // }
    _set(acc, key, _get(obj, value))
    return acc
  }, {})
}

export const copyNotTransformed = (obj, attrMap) => {
  const attrsToExclude = _invert(attrMap)
  return Object.entries(obj).reduce((acc, [key, value]) => {
    if (!attrsToExclude[key]) {
      _set(acc, key, _get(obj, value))
    }
    return acc
  }, {})
}

export const getTipoConta = tipo => (tipo === 'CLIENTES_PRE' ? 'pre' : 'pos')

export const getTipoTransacao = tipo =>
  ({
    fuel: 'ABASTECIMENTO',
    toll: 'PEDAGIO',
    park: 'ESTACIONAMENTO'
  }[tipo])

export const splitNumeroConta = (numero = '') => [
  numero.substring(0, numero.length - 1),
  numero.substring(numero.length - 1)
]

export async function base64toResponse(b64String, mimeType) {
  const url = `data:${mimeType};base64,${b64String}`

  const res = await fetch(url)
  const blob = await res.blob()
  return new Response(blob)
}

export async function downloadBase64(b64String, mimeType, filename) {
  const url = `data:${mimeType};base64,${b64String}`
  const res = await fetch(url)
  const blob = await res.blob()
  downloadFile(blob, filename)
}

export const downloadFile = (blob, fileName, useDownloadAttr = true) => {
  const link = document.createElement('a')
  // create a blobURI pointing to our Blob
  link.href = URL.createObjectURL(blob)
  link.download = fileName
  // some browser needs the anchor to be in the doc
  document.body.append(link)
  if ('download' in link) {
    link.download = fileName
  }
  link.click()
  link.remove()
  // in case the Blob uses a lot of memory
  window.addEventListener('focus', e => URL.revokeObjectURL(link.href), { once: true })
}
