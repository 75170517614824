import styled from 'styled-components'
import { Flex } from 'grid-styled'
import { Link } from 'react-router-dom'
import rem from 'styles/tools/rem'
import media from 'styles/tools/media'
import { Icon } from '../../elements/Icon'

import { linkNavyBlue, ghostWhite, veloeBlue } from 'styles/settings/colors'

import { txt14OpenSansBold } from 'styles/settings/types'

export const IconColorCian = `fill: ${veloeBlue}`
export const IconColorDarkBlue = `fill: ${linkNavyBlue};`

export const CallContainer = styled.div``

export const Container = styled(Flex)`
  background-color: ${ghostWhite};
  border-radius: ${rem('4.4px')};
  box-sizing: border-box;
  flex-direction: row;
  height: ${rem('76px')};
  align-items: center;
  padding-left: ${rem('20px')};

  ${media.large`
    flex-direction: column;
    justify-content: flex-end;
    margin: ${rem('25px')} auto ${rem('10px')};
    min-height: ${rem('190px')};
    padding: ${rem('15px')} ${rem('10px')} ${rem('10px')};
    align-items: flex-start;
  `};
  ${media.small`
    height: auto;
    padding: ${rem('20px')};
  `}
`
export const DownloadItem = styled.div`
  margin: 5px 0;
  ${media.small`
    margin: 0 ${rem('10px')};
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    flex: 1;
  `}
`

export const IconDownload = styled(Icon)`
  cursor: pointer;
  display: inline-block;
  height: 20px;
  width: 25px;
  ${media.small`
    margin-bottom: ${rem('10px')};
  `}
`

export const Title = styled.h2`
  color: ${linkNavyBlue};
  cursor: pointer;
  font-family: ${txt14OpenSansBold};
  font-size: ${rem('14px')};
  height: ${rem('30px')};
  display: inline-block;
  margin-top: 0;
  margin-left: 5px;
`

export const TitleWithLink = styled.a`
  color: ${linkNavyBlue};
  cursor: pointer;
  font-family: ${txt14OpenSansBold};
  font-size: ${rem('14px')};
  height: ${rem('30px')};
  display: inline-block;
  margin-top: 0;
  margin-left: 5px;
  text-decoration: none;
`

export const LinkCancelPlan = styled(Flex)`
  cursor: pointer;
  margin: ${rem('15px')} 0;
`

export const Text = styled(Link)`
  color: ${linkNavyBlue};
  font-family: ${txt14OpenSansBold};
  text-decoration: none;
`

export const Arrow = styled(Icon)`
  margin-left: ${rem('10px')};
  text-decoration: none;
`
