import { emailValidate, cpfValidate, dddValidate, phoneValidate } from 'alelo-logic/utils'

import { errorMessage } from '../../../utils/dictionary'

const { emailFormat, required } = errorMessage

export const validate = values => {
  const errors = {}

  if (!values.email) {
    errors.email = required
  } else if (values.email && emailValidate(values.email)) {
    errors.email = emailFormat
  }

  if (values.document && cpfValidate(values.document)) {
    errors.document = cpfValidate(values.document)
  }

  if (!values.name) {
    errors.name = required
  }

  if (!values.relationship) {
    errors.relationship = required
  }

  if (!values.document) {
    errors.document = required
  }

  if (!values.relationship || values.relationship.length === 0) {
    errors.relationship = required
  }

  if (!values.nationality) {
    errors.nationality = required
  }

  if (!values.telephoneddd || !values.telephone) {
    errors.telephone = required
  } else {
    if (values.telephoneddd && dddValidate(values.telephoneddd)) {
      errors.telephone = dddValidate(values.telephoneddd)
    }

    if (values.telephone && phoneValidate(values.telephone)) {
      errors.telephone = phoneValidate(values.telephone)
    }
  }

  return errors
}
