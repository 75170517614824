import { gqlQuery } from './common/apolloClient'
import { gqlTiposTelefone, transformTelefones } from './queries/telephone'
import { gqlReasons } from './queries/vehicles'
import { gqlGetCitiesFromState, gqlSearchAddress, gqlGetStates } from './queries/address'
import { gqlSubsidyMessage } from './queries/plans'
import {
  gqlEstadosCivis,
  transformEstadosCivis,
  gqlSexos,
  transformSexos,
  gqlParametrizacao,
  transformBancos
} from './queries/domain'

// TODO Migrar para serviço graphQL
export async function getBankList() {
  const resp = await gqlQuery(gqlParametrizacao, { parametro: 'bancos' })
  return transformBancos(resp)
  // return [
  //   { value: 1, label: '001 - Banco do Brasil S.A.' },
  //   { value: 33, label: '033 - Banco Santander (Brasil) S.A.' },
  //   { value: 104, label: '104 - Caixa Econômica Federal' },
  //   { value: 237, label: '237 - Banco Bradesco S.A.' },
  //   { value: 341, label: '341 - Itaú Unibanco S.A.' }
  // ]
}

export async function getDeadline() {
  const resp = await gqlQuery(gqlParametrizacao, { parametro: 'prazoEntrega' })

  if (resp && resp.data && resp.data.parametrizacao && resp.data.parametrizacao.length > 0) {
    return {
      key: resp.data.parametrizacao[0].chave,
      value: resp.data.parametrizacao[0].valor,
      description: resp.data.parametrizacao[0].descricao
    }
  } else {
    return {
      key: '',
      value: '',
      description: '5 dias úteis'
    }
  }
}

export async function getTelephoneTypesList() {
  const telefones = await gqlQuery(gqlTiposTelefone)
  return transformTelefones(telefones)
}

export async function getCitiesFromState(data) {
  const payload = { estado: data.state }
  const response = await gqlQuery(gqlGetCitiesFromState, payload)
  return response.data.cidades
}

export async function zipCodeGet(data) {
  const payload = { cep: data.zip_code }
  const response = await gqlQuery(gqlSearchAddress, payload)
  return response.data.cep
}

const reasonsListFallback = [
  {
    chave: 'PARABRISA_QUEBRADO',
    cobrar: false,
    descricao: 'Parabrisa quebrado'
  },
  {
    chave: 'ERRO_LEITURA_TAG',
    cobrar: false,
    descricao: 'Erro de leitura do produto'
  },
  {
    chave: 'EXTRAVIO_TAG',
    cobrar: true,
    descricao: 'Perdi o produto',
    valor: '34.9'
  },
  {
    chave: 'ROUBO_VEICULO',
    cobrar: false,
    descricao: 'Fui roubado'
  },
  {
    chave: 'VENDA_VEICULO',
    cobrar: true,
    descricao: 'Venda do veículo',
    valor: '34.9'
  },
  {
    chave: 'OUTRO',
    cobrar: true,
    descricao: 'Outro motivo',
    valor: '34.9'
  }
]

export async function getReasonList(codIdentificador) {
  const payload = { codIdentificador }
  const { data: { identificadorMotivoBloqueio = null } = {} } = await gqlQuery(gqlReasons, payload)
  const list = identificadorMotivoBloqueio || reasonsListFallback
  return list
}

export async function getStates() {
  const response = await gqlQuery(gqlGetStates)
  return response.data.estados
}

export async function getGenderList() {
  const resp = await gqlQuery(gqlSexos)
  return transformSexos(resp)
}

export async function getMaritialStatusList() {
  const resp = await gqlQuery(gqlEstadosCivis)
  return transformEstadosCivis(resp)
}

export async function getSubsidyMessage(idConta) {
  const idCliente = sessionStorage.getItem('customerId')
  const payload = { idConta: idConta, idCliente: idCliente }
  const response = await gqlQuery(gqlSubsidyMessage, payload)
  return response.data.mensagemSubsidio
}
