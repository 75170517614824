import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Box } from 'grid-styled'

import { hideModal } from '../ModalRoot/actions'

import { Icon } from '../../elements/Icon'
import Modal from '../../elements/Modal'
import TextInput from '../../elements/TextInput'
import Button from '../../elements/Button'
import rem from '../../../styles/tools/rem'

import { Description, InputContainer, Resend, Title, InputModalContainer } from './styles'

const data = require('./data.json')

class InputModal extends Component {
  state = {
    isLoading: false,
    isRefreshed: false
  }

  onInputChange = value => {
    this.setState({ inputValue: value })
  }

  handleRefresh = e => {
    this.setState({
      errorMessage: false,
      isLoading: true,
      inputValue: ''
    })

    this.props
      .fetchRefreshProvider()
      .then(() => {
        this.setState({
          isLoading: false,
          isRefreshed: true
        })
      })
      .catch(() => {
        this.setState({
          errorMessage: data.defaultError,
          isLoading: false
        })
      })
  }

  handleConfirm = e => {
    e.preventDefault()

    this.setState({ isLoading: true })

    this.props
      .fetchSendProvider(this.state.inputValue)
      .then(() => {
        this.setState({ isLoading: false })
        this.props.hideModal()

        if (this.props.onConfirm) {
          this.props.onConfirm()
        }
      })
      .catch(() => {
        this.setState({
          errorMessage: this.props.fetchErrorMessage || data.defaultError,
          isLoading: false
        })
      })
  }

  render() {
    const {
      fetchRefreshProvider,
      image,
      title,
      description,
      buttonText,
      label,
      placeholder,
      refreshMessage
    } = this.props

    const { isLoading, isRefreshed } = this.state

    const inputValue = this.state.inputValue

    const modalTitle = title || data.title
    const modalDesc = description || data.description
    const modalButtonText = buttonText || data.buttonText

    return (
      <InputModalContainer>
        <Modal image={image} hideModal={this.props.hideModal}>
          <Title>{isRefreshed ? refreshMessage : modalTitle}</Title>
          <Description>{modalDesc}</Description>

          <InputContainer mb={[60, 48]} width={[1, 262]}>
            <form onSubmit={this.handleConfirm}>
              <TextInput
                errorMessage={this.state.errorMessage}
                label={label}
                loading={isLoading}
                negative
                onChange={e => this.onInputChange(e)}
                placeholder={placeholder}
                value={inputValue}
              />

              {!isLoading && fetchRefreshProvider && (
                <Resend onClick={this.handleRefresh}>
                  <Icon height="18px" icon="resend" width="18px" viewBox="0 0 18 18" />
                </Resend>
              )}

              <Box mt={[rem('30px'), rem('48px')]}>
                <Button
                  buttonType="transparent"
                  type="submit"
                  text={modalButtonText}
                  onClick={this.props.onConfirm}
                />
              </Box>
            </form>
          </InputContainer>
        </Modal>
      </InputModalContainer>
    )
  }
}

export default connect(null, {
  hideModal
})(InputModal)
