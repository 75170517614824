import gql from 'graphql-tag'

export const gqlInvoices = gql`
  query historicoFaturas($idConta: String, $pagina: Int, $aplicarFiltroAbastecimento: Boolean) {
    historicoFaturas(
      idConta: $idConta
      pagina: $pagina
      aplicarFiltroAbastecimento: $aplicarFiltroAbastecimento
    ) {
      faturas {
        numero
        valor
        dataVencimento
        status
        formaPagamento
        mes
        nsu
        qtdTransacoes
        exibirTransacoes
        mensagemStatusPrincipal
        mensagemStatusAdjacente
        situacao
        linkBoleto
      }
      paginacao {
        anterior
        atual
        proxima
        total
      }
      error
    }
  }
`

export const gqlNFSe = gql`
  query notaFiscalDados($customerId: String, $idSap: String) {
    notaFiscalDados(customerId: $customerId, idSap: $idSap) {
      dadosNF {
        idCliente
        slip
        nsu
        notaFiscal
        dataCriacao
        linkNFSe
        dataAutorizacao
        totalNF
        codigoISS
        valorISS
        aliquotaISS
        codigoPIS
        valorPIS
        aliquotaPIS
        codigoCofins
        valorCofins
        aliquotaCofins
      }
      messageService {
        code
        message
      }
    }
  }
`

export const gqlInvoice = gql`
  query detalheFatura($idConta: String!, $faturaId: Int!, $qtdTransacoes: Int!) {
    detalheFaturaPj(idConta: $idConta, numeroFatura: $faturaId, qtdTransacoes: $qtdTransacoes) {
      servicosVeloe {
        tipoTransacao
        quantidade
        total
      }
      consumos {
        tipoTransacao
        quantidade
        total
      }
      transacoes {
        placa
        condutor
        total
        transacoesAgrupadas {
          tipoTransacao
          total
          transacaoes {
            data
            hora
            categoria
            horaEntrada
            horaSaida
            estabelecimento
            valor
            endereco
            permanencia
          }
        }
      }
      formaPagamento
      total
      linkNFE
      numeroNF
      dataEmissaoNF
      error
      totalBeneficios
      subTotal
      dataVencimento
      linkFaturaPDF
      linkBoleto
    }
  }
`

export const gqlDownloadFaturaPdf = gql`
  query downloadFaturaPdf($idConta: String!, $numeroFatura: Int!) {
    downloadFaturaPdf(idConta: $idConta, numeroFatura: $numeroFatura) {
      result
    }
  }
`

export const gqlDownloadFaturaCsv = gql`
  query downloadFaturaCsv($idConta: String!, $numeroFatura: Int!) {
    downloadFatura(idConta: $idConta, numeroFatura: $numeroFatura) {
      result
    }
  }
`

export const gqlDownloadBoleto = gql`
  query downloadBoleto($idConta: String!, $nsu: Int!) {
    downloadBoleto(idConta: $idConta, nsu: $nsu) {
      result
    }
  }
`

export const gqlSolicitarBoleto = gql`
  mutation SolicitarBoletoFatura($parametros: SolicitarBoletoRequisicao) {
    solicitarBoletoFatura(parametros: $parametros) {
      idCliente
      nsu
      dataVencimento
      status
      mensagem
      valores {
        original
        cobranca
        juros
        multa
        desconto
      }
      boleto {
        codigo
        link
      }
    }
  }
`
