import TemplateLayout from '../../app/templates/TemplateLayout'
import CarPlateQuery from '../../app/pages/ec/CarPlateQuery'

export default {
  path: '/minha-veloe/consultar-placa',
  component: TemplateLayout,
  routes: [
    {
      path: '/minha-veloe/consultar-placa',
      exact: true,
      component: CarPlateQuery
    }
  ]
}
