import {
  VALIDATION_OLD_PLATE,
  VALIDATION_NEW_PLATE,
  VALIDATION_PLATE_MSG_EMPTY,
  VALIDATION_PLATE_MSG_INVALID
} from './constants'

export default function validatePlate(value) {
  const lowPlate = String(value).toLowerCase()

  if (!value) return VALIDATION_PLATE_MSG_EMPTY

  if (value.length === 7) {
    const letters = value.match(/[a-zA-Z]/g) ? value.match(/[a-zA-Z]/g).length : 0
    const numbers = value.match(/[0-9]/g) ? value.match(/[0-9]/g).length : 0
    if (letters === 4 && numbers === 3) {
      if (!VALIDATION_NEW_PLATE.test(lowPlate)) {
        return VALIDATION_PLATE_MSG_INVALID
      }
    } else if (letters === 3 && numbers === 4) {
      if (!VALIDATION_OLD_PLATE.test(lowPlate)) {
        return VALIDATION_PLATE_MSG_INVALID
      }
    } else {
      return VALIDATION_PLATE_MSG_INVALID
    }
  } else {
    return VALIDATION_PLATE_MSG_INVALID
  }
}
