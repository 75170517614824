const locations = [
  {
    value: '1',
    label: 'Posto Rebouças - Av. Rebouças, 2012, Campinas, São Paulo/SP'
  },
  {
    value: '2',
    label: 'Posto Rebouças 2 - Av. Paulista, 1550, São Paulo/SP'
  },
  {
    value: '3',
    label: 'Posto Rebouças 3 - Rua do Posto, 5, Guarulhos/SP'
  },
  {
    value: '4',
    label: 'Posto Rebouças 4 - Av. Principal, 2015, Congonhas, São Paulo/SP'
  }
]

const unblockedCar = {
  blocked: false,
  plate: 'FRM-0009',
  queries: [
    { date: '04/11', hour: '12:20', blocked: false },
    { date: '05/11', hour: '07:20', blocked: false },
    { date: '06/11', hour: '05:20', blocked: false },
    { date: '07/11', hour: '13:20', blocked: false },
    { date: '08/11', hour: '14:20', blocked: true },
    { date: '09/11', hour: '16:20', blocked: true }
  ]
}

const blockedCar = {
  blocked: true,
  plate: 'FRM-0099',
  queries: [
    { date: '04/11', hour: '12:20', blocked: false },
    { date: '05/11', hour: '07:20', blocked: false },
    { date: '06/11', hour: '05:20', blocked: false },
    { date: '07/11', hour: '13:20', blocked: true },
    { date: '08/11', hour: '14:20', blocked: true },
    { date: '09/11', hour: '16:20', blocked: true }
  ]
}

const requestFileData = [
  {
    date: '11/03/2018',
    hour: '10:15pm',
    fileType: 'TRT',
    sequential: '12345678',
    status: 'Processando'
  },
  {
    date: '01/03/2018',
    hour: '06:47pm',
    fileType: 'TRF',
    sequential: '12345678',
    status: 'Finalizado'
  },
  {
    date: '11/11/2018',
    hour: '02:47am',
    fileType: 'NEL',
    sequential: '12345678',
    status: 'Finalizado'
  },
  {
    date: '12/28/2018',
    hour: '00:09am',
    fileType: 'TAG',
    sequential: '12345678',
    status: 'Finalizado'
  },
  {
    date: '10/30/2018',
    hour: '09:50am',
    fileType: 'TGT',
    sequential: '12345678',
    status: 'Finalizado'
  },
  {
    date: '12/02/2018',
    hour: '08:44am',
    fileType: 'LNT',
    sequential: '12345678',
    status: 'Finalizado'
  }
]

const moneyTransfers = [
  {
    date: '30/09/2017',
    value: 1700,
    status: 'Realizado'
  },
  {
    date: '01/10/2017',
    value: 1750,
    status: 'Realizado'
  },
  {
    date: '05/10/2017',
    value: 1250,
    status: 'Realizado'
  }
]

const contestations = [
  { date: '04/11', value: 30 },
  { date: '01/11', value: 230 },
  { date: '29/10', value: 50 },
  { date: '22/10', value: 230 },
  { date: '14/10', value: 50 },
  { date: '09/10', value: 230 },
  { date: '05/10', value: 30 }
]

const createPromise = body =>
  new Promise(resolve => {
    window.setTimeout(() => {
      resolve(body)
    }, 1000)
  })

export function getLocations() {
  return createPromise(locations)
}

export function queryPlate(formData) {
  return new Promise(resolve => {
    window.setTimeout(() => {
      if (formData.plate === 'FRM-0099') resolve(blockedCar)
      else resolve(unblockedCar)
    }, 1000)
  })
}

export function requestFileHistory() {
  return createPromise(requestFileData)
}

export function requestMoneyTransfer() {
  return createPromise(moneyTransfers)
}

export function requestContestations() {
  return createPromise(contestations)
}
