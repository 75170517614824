import {
  getRechargeValues,
  getPaymentInfo,
  createRecharge,
  changeRecharge
} from '../services/rechargeService'

import types from './types'

const {
  SET_RECHARGE_VALUES,
  SET_CURRENT_RECHARGE_VALUE,
  SET_PAYMENT_INFO,
  SET_CHANGE_RECHARGE
} = types

export function setCurrentRechargeValue(currentRechargeValue) {
  return {
    type: SET_CURRENT_RECHARGE_VALUE,
    currentRechargeValue
  }
}

export function fetchRechargeValues(accountPlanId) {
  return dispatch =>
    getRechargeValues(accountPlanId).then((resp = {}) => {
      const rechargeValues = resp.rechargeValues || []
      dispatch({
        type: SET_RECHARGE_VALUES,
        rechargeValues
      })

      return rechargeValues
    })
}

export function fetchPaymentInfo(accountPlanId) {
  return async dispatch => {
    const paymentInfo = await getPaymentInfo(accountPlanId)
    if (paymentInfo) {
      dispatch({
        type: SET_PAYMENT_INFO,
        paymentInfo
      })
      return paymentInfo
    }
    return {}
  }
}

export function fetchCreateRecharge({ accountPlanId, value, paymentMethod, type }) {
  return () => createRecharge(accountPlanId, value, paymentMethod, type).then(resp => resp)
}

export function fetchChangeRecharge(accountId, valorRecarga) {
  return dispatch =>
    changeRecharge(accountId, valorRecarga).then((info = {}) => {
      const action = {
        type: SET_CHANGE_RECHARGE,
        valorRecarga: info
      }
      dispatch(action)
      return info
    })
}
