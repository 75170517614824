import React from 'react'

const IcLoading = props => (
  <svg width={24} height={24} {...props}>
    <g fill="none" fillRule="evenodd">
      <path
        fill="#2A2264"
        d="M20.167 13.082a.604.604 0 0 1-.55-.588v-1.16c0-.301.248-.566.55-.588l3.282-.241a.504.504 0 0 1 .551.507v1.804c0 .3-.247.53-.55.507l-3.283-.241z"
      />
      <path
        fill="#C5C3D8"
        d="M10.824 20.022a.603.603 0 0 1 .592-.546h1.168c.303 0 .57.246.592.546l.243 3.259a.504.504 0 0 1-.51.547h-1.817a.504.504 0 0 1-.511-.547l.243-3.259zm-5.43-3.2a.608.608 0 0 1 .808.03l.823.819c.216.213.23.574.032.802l-2.15 2.475a.509.509 0 0 1-.751.027L2.871 19.7a.5.5 0 0 1 .03-.745l2.493-2.133zm-1.561-6.076c.302.022.55.287.55.588v1.16a.603.603 0 0 1-.55.587l-3.282.242A.504.504 0 0 1 0 12.816v-1.804c0-.301.247-.53.55-.508l3.283.242zm3.224-5.392a.598.598 0 0 1-.03.803l-.827.819a.608.608 0 0 1-.808.03L2.901 4.872a.5.5 0 0 1-.028-.745l1.285-1.275c.214-.213.55-.2.75.028l2.149 2.474z"
      />
      <path
        fill="#2A2264"
        d="M13.176 3.805a.603.603 0 0 1-.592.547h-1.168a.603.603 0 0 1-.592-.547L10.581.547A.504.504 0 0 1 11.09 0h1.817c.304 0 .534.246.511.547l-.243 3.258zm5.598 3.601a.609.609 0 0 1-.809-.03l-.825-.82a.6.6 0 0 1-.031-.802l2.15-2.475a.509.509 0 0 1 .75-.027l1.285 1.275a.5.5 0 0 1-.027.745l-2.493 2.134z"
      />
      <path
        fill="#C5C3D8"
        d="M17.11 18.074a.598.598 0 0 1 .03-.803l.825-.819a.608.608 0 0 1 .809-.03l2.493 2.134a.5.5 0 0 1 .027.745l-1.284 1.275a.51.51 0 0 1-.751-.027l-2.148-2.475z"
      />
    </g>
  </svg>
)

export default IcLoading
