import React from 'react'

import { OverlayClick } from 'app/elements/ClickOut'
import { DropdownListContainer, DropdownListItem, Title } from './styles'

export const ListItem = ({ children, onClick, isActived, secondary, withIcon }) => {
  return (
    <DropdownListItem onClick={onClick} secondary={secondary}>
      <Title withIcon={withIcon} isActived={isActived}>
        {children}
      </Title>
    </DropdownListItem>
  )
}

function DropdownList({
  options,
  isOpen,
  onClose,
  onSelect,
  optionSelected,
  secondary,
  withIcon,
  children
}) {
  const closeDropdown = onClick => {
    if (onClose) {
      onClose()
    }

    if (onClick) {
      onClick()
    }
  }

  return (
    <div>
      <OverlayClick onClick={onClose} isOpen={isOpen} />
      <DropdownListContainer isOpen={isOpen} withIcon={withIcon} secondary={secondary}>
        {options &&
          options.map((option, index) => {
            const isActived = optionSelected === option
            return (
              <ListItem
                key={index}
                secondary={secondary}
                isActived={isActived}
                withIcon={withIcon}
                onClick={() => {
                  closeDropdown(option.onClick)
                  onSelect(option)
                }}
              >
                {option}
              </ListItem>
            )
          })}
        {children}
      </DropdownListContainer>
    </div>
  )
}

export default DropdownList
