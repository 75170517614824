import React from 'react'
import { bool, shape, string } from 'prop-types'

import {
  CarPlateLabel,
  Input,
  Label,
  VehicleOption,
  VehicleOptionCarNickname,
  VehicleOptionCarPlate,
  VehicleOptionContent,
  VehicleOptionDriverName,
  VehicleOptionNames
} from './styles'

function ChooseFilterVehiclesOptionInput(props) {
  if (props.isChecked) {
    return (
      <Input
        onChange={props.onChange}
        type="checkbox"
        id={props.vehicleId}
        name={props.name}
        className="vehicleCheckbox"
        checked
      />
    )
  }
  return (
    <Input
      onChange={props.onChange}
      type="checkbox"
      id={props.vehicleId}
      name={props.name}
      className="vehicleCheckbox"
    />
  )
}

function ChooseFilterVehiclesOption(props) {
  const { filterVehicle = {}, checked, onChange, name } = props
  const { carPlate = '' } = filterVehicle

  return (
    <Label>
      <ChooseFilterVehiclesOptionInput
        onChange={onChange}
        vehicleId={carPlate}
        isChecked={checked}
        name={name}
      />
      <VehicleOption>
        <VehicleOptionContent>
          <VehicleOptionCarPlate>
            <CarPlateLabel>Placa</CarPlateLabel>
            {`${carPlate.substring(0, 3)}`}
            <span>{carPlate.substring(3, carPlate.length)}</span>
          </VehicleOptionCarPlate>
          <VehicleOptionNames>
            <VehicleOptionDriverName>{filterVehicle.driverName}</VehicleOptionDriverName>
            <VehicleOptionCarNickname>{filterVehicle.carNick}</VehicleOptionCarNickname>
          </VehicleOptionNames>
        </VehicleOptionContent>
      </VehicleOption>
    </Label>
  )
}

ChooseFilterVehiclesOption.defaultProps = {
  filterVehicle: {},
  checked: null
}

ChooseFilterVehiclesOption.propTypes = {
  filterVehicle: shape({
    carPlate: string,
    driverName: string,
    carNickname: string
  }).isRequired,
  checked: bool
}

export default ChooseFilterVehiclesOption
