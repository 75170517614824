import styled, { css } from 'styled-components'
import media from 'styles/tools/media'
import rem from 'styles/tools/rem'

import { skyBlue, white } from 'styles/settings/colors'
import { txt14OpenSansBold } from 'styles/settings/types'

export const Steps = styled.ol`
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin: 0;
  padding: 0;

  ${media.large`
        display: inline-flex;
    `};
`

export const CircleCheck = styled.span`
  position: absolute;
  right: ${rem('-8px')};
  top: ${rem('-8px')};
`

export const StepCircle = styled.span`
  ${txt14OpenSansBold};

  align-items: center;
  border-radius: 100%;
  border: solid ${rem('2px')} ${white};
  color: ${white};
  display: inline-flex;
  height: ${rem('44px')};
  justify-content: center;
  line-height: 1;
  position: relative;
  transition: 0.2s background, 0.2s color, 0.2s border;
  width: ${rem('44px')};
`

export const Step = styled.li`
  align-items: center;
  display: inline-flex;
  margin: 0;
  padding: 0;

  :not(:first-child) {
    flex: 1;

    ${media.large`
            flex: none;
        `}
      :before {
      background-color: ${white};
      border-radius: ${rem('100px')};
      content: '';
      display: block;
      height: ${rem('2px')};
      margin: 0 ${rem('20px')};
      width: ${rem('36px')};
      flex: 1;

      ${media.large`
                flex: none;
                margin: 0 ${rem('10px')};
            `};
    }
  }

  ${({ visited, active }) =>
    !visited &&
    !active &&
    css`
      opacity: 0.5;
    `} ${StepCircle} {
    ${({ visited }) =>
      visited &&
      css`
        background: ${white};
        color: ${skyBlue};
      `} ${({ active }) =>
      active &&
      css`
        border: solid ${rem('3px')} ${white};
      `};
  }
`
