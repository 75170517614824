export default {
  __html: `<g fill="none" fill-rule="nonzero">
                <path fill="#00C7D6" d="M47.979 8.724c-.101-2.383-.06-3.771-.101-5.927-.02-.787-.222-1.595-.787-2.113C46.344 0 45.355 0 44.628 0 30.6.228 18.59.104 4.562.332 3.31.352.122 0 .06 2.01L0 3.73c1.433.829 3.007 2.797 3.916 3.626 1.877 1.782 3.936 3.565 5.106 5.844 12.232-.207 23.495.083 36.03-.56.727-.04 3.007.29 2.947-1.201-.08-.746 0-1.99-.02-2.715z"/>
                <g fill="#292763">
                    <path d="M33.097 8.4c-.78 0-1.355-.523-1.355-1.204v-.76c0-.745.542-1.236 1.355-1.236s1.355.491 1.355 1.236v.237c0 .38-.237.539-.44.539h-1.474c.017.317.339.412.542.412.101 0 .203-.016.305-.048a.421.421 0 0 1 .135-.031c.119 0 .17.11.17.126l.186.365s.068.142-.068.206c-.22.11-.457.158-.711.158zm.542-1.917c0-.3-.187-.507-.542-.507-.356 0-.542.206-.542.507h1.084zM42.387 8.4c-.779 0-1.355-.523-1.355-1.204v-.76c0-.745.542-1.236 1.355-1.236s1.355.491 1.355 1.236v.237c0 .38-.237.539-.44.539h-1.474c.017.317.339.412.542.412.102 0 .203-.016.305-.048a.421.421 0 0 1 .135-.031c.119 0 .17.11.17.126l.186.365s.068.142-.068.206c-.22.11-.457.158-.71.158zm.542-1.917c0-.3-.186-.507-.542-.507-.356 0-.542.206-.542.507h1.084zM38.903 8.4c-.77 0-1.355-.523-1.355-1.22v-.729c0-.744.552-1.251 1.355-1.251.82 0 1.355.507 1.355 1.251v.73c0 .696-.585 1.219-1.355 1.219m0-2.44c-.334 0-.518.159-.518.444v.824c0 .332.318.412.518.412.184 0 .519-.095.519-.412v-.824c0-.285-.184-.444-.519-.444M36 10.4c-.585 0-1.118-.214-1.48-.608a.197.197 0 0 1-.068-.148c0-.066.051-.099.068-.115l.379-.28a.215.215 0 0 1 .292.016c.207.198.5.297.809.297.31 0 .602-.099.809-.296a.215.215 0 0 1 .292-.017l.379.28c.017 0 .068.05.068.115 0 .05-.017.098-.068.148-.379.394-.895.608-1.48.608M36.4 8.4c-.698 0-1.174-.451-1.174-1.128V3.745c0-.08.068-.145.153-.145h.578c.086 0 .154.064.154.145v3.48c0 .209.102.321.306.321h.204c.085 0 .153.065.153.145v.564c0 .08-.068.145-.153.145H36.4zM29.26 8.4h-.034-.034c-.069 0-.274-.016-.48-.114-.257-.13-.445-.357-.565-.666l-.034-.065-.616-1.965a.132.132 0 0 1 0-.114.201.201 0 0 1 .085-.081l.548-.179c.017 0 .034-.016.052-.016.068 0 .12.032.154.097l.582 1.901c.017.049.034.097.051.13a.3.3 0 0 0 .257.162.3.3 0 0 0 .257-.162c.017-.049.034-.081.051-.13l.582-1.884c.017-.065.086-.098.154-.098.017 0 .034 0 .051.016l.548.18c.035.015.069.032.086.08a.132.132 0 0 1 0 .114l-.616 1.966-.018.065v.016a1.133 1.133 0 0 1-.564.666c-.223.081-.411.081-.497.081"/>
                </g>
                <path fill="#2A2467" d="M15.468 14.93c1.926.057 1.818-2.217.054-2.015-.434.057-.868-.029-1.302-.087-.922-.115-1.248-.518-2.062-.057-.298.173-1.437 3.425-.949 4.951.488 1.555 1.22 2.994 1.682 4.548.163.547.271 1.18-.027 1.67-.353.518-1.085.576-1.628.288-.542-.288-.922-.806-1.275-1.324-.678-.98-1.356-1.987-2.062-2.965-.217-.317-.434-.634-.515-1.037-.108-.518.109-1.036.271-1.525.679-2.015.977-4.146.814-6.276-.081-1.381-.19-3.166 1.004-3.742.542-.259 1.166-.144 1.736-.057 3.418.662 6.836 1.295 10.254 1.957.678.115 1.384.26 1.926.72 1.085.863 1.41 2.303 1.384 3.742v5.67c0 1.037-.353 1.641-1.221 2.13-2.496 1.498-10.363-6.678-8.084-6.591z"/>
                <path fill="#FFD100" d="M8.374 12.694c.423.335.966.67 1.45.418.26-.146.422-.418.583-.69.422-.668 1.006-1.232 1.428-1.922.423-.69.705-1.526.463-2.3-.402-1.274-1.932-1.65-3.22-1.922a27.803 27.803 0 0 1-6.016-1.944c-.543-.25-2.898-1.358-3.059-.25-.1.69 2.033 2.612 2.496 3.114 1.79 2.027 3.762 3.845 5.875 5.496z"/>
                <path fill="#2A2467" d="M35.613 34.433s-3.545-1.112-6.107-3.99c-2.563-2.88-4.445-6.614-4.39-10.547 0-1.055.136-2.223-.464-3.078-.327-.456-.845-.77-1.39-.94-2.645-.97-5.78.256-8.261-1.084-1.582-.855-2.509-2.565-3.49-4.104-.982-1.54-2.236-3.136-3.981-3.45-.627-.113-1.336 0-1.772.485-.437.513-.382 1.311-.218 1.967.708 2.508 3.08 4.019 4.416 6.214 1.909 3.05 1.636 7.011 2.618 10.489.9 3.25 2.89 6.042 5.262 8.351 2.372 2.309 5.099 4.162 7.798 5.986 3.026 2.052 6.734 5.558 9.952 7.268V34.433h.027z"/>
            </g>`
}
