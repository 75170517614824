import React from 'react'

const IcCar = props => (
  <svg width="48" height="48" viewBox="0 0 48 48">
    <defs>
      <path
        id="a"
        d="M20.631 9.25h7.037a5.71 5.71 0 0 0-1.678-4.007 5.71 5.71 0 0 0-4.054-1.678H12.01c-.793 0-1.445.652-1.445 1.445v5.732c0 .792-.652 1.444-1.445 1.444H3.016v6.105c0 .792.652 1.445 1.444 1.445h.654c.587-1.7 2.211-2.936 4.1-2.936 1.872 0 3.484 1.215 4.084 2.893l11.763.039c.589-1.698 2.211-2.932 4.098-2.932 1.889 0 3.512 1.236 4.1 2.936h2.145v-6.152c0-.792-.653-1.444-1.445-1.444H19.55a1.426 1.426 0 0 1-.725 0h-3.972a1.452 1.452 0 0 1-1.444-1.445c0-.792.652-1.445 1.444-1.445h2.89V6.967c0-.792.652-1.445 1.444-1.445.793 0 1.445.653 1.445 1.445V9.25zM5.131 22.58h-.624A4.276 4.276 0 0 1 .219 18.29v-7.55c0-.791.653-1.444 1.445-1.444H7.77V5.01A4.276 4.276 0 0 1 12.056.722h9.927c4.737 0 8.549 3.843 8.574 8.528h3.402a4.276 4.276 0 0 1 4.288 4.288v7.596c0 .373-.14.746-.42 1.025-.28.326-.606.466-1.025.466l-3.563-.036c-.604 1.67-2.212 2.879-4.08 2.879-1.855 0-3.455-1.193-4.067-2.847l-11.797-.039c-.601 1.674-2.212 2.886-4.081 2.886-1.872 0-3.483-1.213-4.084-2.89zm24.028-2.936c-.792 0-1.491.652-1.491 1.491 0 .839.652 1.491 1.491 1.491.792 0 1.491-.652 1.491-1.491 0-.839-.699-1.491-1.49-1.491zm-19.945 0c-.793 0-1.492.652-1.492 1.491 0 .839.653 1.491 1.492 1.491.792 0 1.49-.652 1.49-1.491 0-.839-.698-1.491-1.49-1.491z"
      />
    </defs>
    <g fill="none" fillRule="evenodd" transform="translate(5 12)">
      <mask id="b" fill="#fff">
        <use xlinkHref="#a" />
      </mask>
      <use fill="#2A2264" fillRule="nonzero" xlinkHref="#a" />
      <g mask="url(#b)">
        <path fill="currentColor" d="M-5-12h48v48H-5z" />
      </g>
      <path d="M-4.627-11.581h47.534v47.534H-4.627z" mask="url(#b)" />
    </g>
  </svg>
)

export default IcCar
