import styled from 'styled-components'

import media from 'styles/tools/media'
import rem from 'styles/tools/rem'
import em from 'styles/tools/em'

import { linkNavyBlue, darkGrey, darkViolet } from 'styles/settings/colors'
import { txt12OpenSans, txt14OpenSans, title32GalanoBold } from 'styles/settings/types'

export const Title = styled.h1`
  ${title32GalanoBold} color: ${linkNavyBlue};
  margin: ${rem('40px')} 0 ${rem('30px')} 0;

  ${media.large`
        ${title32GalanoBold}

    `};
`

export const SubTitle = styled.p`
  ${txt12OpenSans};

  color: ${darkGrey};
  letter-spacing: ${em('1px')};
  margin: 0 0 ${rem('18')} 0;
  text-transform: uppercase;
`

export const Notice = styled.div`
  display: flex;
  ${txt14OpenSans};
  color: ${darkViolet};
  line-height: 1.29;

  svg {
    overflow: inherit;
    padding-top: 2px;
  }
  span {
    margin-left: 16px;
  }
`
