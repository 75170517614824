/**
 * ARQUIVO LEGADO.
 * TODO: Conseguir excluir arquivo de serviço. Primeiro acesso é realizado pelo site público
 */

const activationResponse = {
  status: true,
  protocol: '201801228659'
}

const unactivationResponse = {
  status: false
}

const freeResponseShipping = {
  status: true,
  amount: 'Grátis'
}

const valueResponseShipping = {
  status: false,
  amount: '29,00'
}

export function updateShippingAddress(address) {
  return new Promise((resolve, reject) => {
    window.setTimeout(() => {
      if (address) resolve(activationResponse)
      else reject(unactivationResponse)
    }, 1000)
  })
}

export function getShippingAmount(zipcode) {
  return new Promise((resolve, reject) => {
    window.setTimeout(() => {
      if (zipcode) resolve(freeResponseShipping)
      else resolve(valueResponseShipping)
    }, 1000)
  })
}
