import React, { Component } from 'react'
import { connect } from 'react-redux'
import { fetchDashboardRecharges, resetDashboardRecharges } from 'alelo-logic/actions'
import AddItem from '../../modules/AddItem'
import RechargeList from '../../modules/RechargeList'
import { setLoading } from '../../modules/PageLoader/actions'

class DashboardRechargeHistory extends Component {
  componentWillMount() {
    this.props.setLoading(true)
    this.props.fetchRecharges(this.props.match.params.id)
  }

  componentWillUnmount() {
    this.props.resetRecharges()
  }

  fetchRecharge = nextPage => {
    const params = this.props.match.params || {}
    this.props.fetchRecharges(params.id, nextPage)
  }

  render() {
    const { recharges } = this.props

    return (
      <section>
        <AddItem data={recharges.data} next={recharges.next} fetchHandler={this.fetchRecharge}>
          <RechargeList />
        </AddItem>
      </section>
    )
  }
}

const mapStateToProps = ({ reducer }) => ({
  recharges: reducer.dashboardRecharges
})

const mapDispatchToProps = dispatch => ({
  fetchRecharges: (planId, nextPage) =>
    dispatch(fetchDashboardRecharges(planId, nextPage)).then(() => dispatch(setLoading(false))),
  resetRecharges: () => dispatch(resetDashboardRecharges()),
  setLoading: isLoading => {
    dispatch(setLoading(isLoading))
  }
})

export default connect(mapStateToProps, mapDispatchToProps)(DashboardRechargeHistory)
