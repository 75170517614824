import {
  hasNumbers,
  validatePasswordLength,
  validateLetterLow,
  validateLetterUpper,
  validateLetterEspecial
} from 'alelo-logic/utils'
const { errors } = require('./data.json')

const {
  lowerLetterNeeded,
  upperLetterNeeded,
  especialNeeded,
  newPasswordLength,
  numberNeeded
} = errors

export default function validatePasswordRules(value) {
  let showErrors
  let error = {}

  if (value && value.length > 0) {
    showErrors = true

    if (!validatePasswordLength(value)) {
      error.newPasswordLength = newPasswordLength
    }

    if (validateLetterLow(value)) {
      error.lowerLetterNeeded = lowerLetterNeeded
    }

    if (validateLetterUpper(value)) {
      error.upperLetterNeeded = upperLetterNeeded
    }

    if (!hasNumbers(value)) {
      error.numberNeeded = numberNeeded
    }

    if (validateLetterEspecial(value)) {
      error.especialNeeded = especialNeeded
    }
  }
  return { showErrors, error }
}
