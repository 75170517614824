import styled from 'styled-components'
import { Link } from 'react-router-dom'

import { linkNavyBlue, menuOptionGrey } from '../../../styles/settings/colors'

import { title16Galano, txt12OpenSansLight } from '../../../styles/settings/types'

import rem from '../../../styles/tools/rem'

export const Description = styled.span`
  display: block;
`

export const Title = styled.span`
  ${title16Galano};

  color: ${linkNavyBlue};
  display: block;
  line-height: 1;
  text-transform: capitalize;

  + ${Description} {
    ${txt12OpenSansLight};
  }
`

export const DropdownListContainer = styled.ul`
  background-color: white;
  border-radius: ${rem('2px')};
  box-shadow: 0 ${rem('8px')} ${rem('8px')} 0 rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  left: 0;
  padding: 0 0 ${rem('32px')};
  pointer-events: none;
  position: absolute;
  top: ${rem('90px')};
  visibility: hidden;
  width: 100%;
  z-index: 20;
  margin: 0;

  ${props =>
    props.isOpen &&
    `
        pointer-events: all;
        visibility: visible;
    `};
`

export const DropdownListItem = styled.li`
  color: ${menuOptionGrey};
  font-family: 'OpenSans';
  font-size: ${rem('12px')};
  font-weight: bold;
  margin: 0 ${rem('22px')};
  text-decoration: none;
  padding: 0;
  list-style: none;

  :not(:last-child) {
    margin-bottom: ${rem('24px')};
  }

  ${props =>
    props.userCancelled &&
    `
        color: ${linkNavyBlue};
        cursor: default;
        opacity: 0.2;
        pointer-events: none;
    `} ${props =>
    props.cancelledMenuItem &&
    `
        color: ${menuOptionGrey};
        cursor: pointer;
        opacity: 1;
        pointer-events: all;
    `};
`

export const DropdowLink = styled(Link)`
  text-decoration: none;
  color: inherit;
`
