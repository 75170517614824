import types from './types'

const { STATE_MODAL, LOGIN_MSG_ERROR, SHOW_CAPTCHA } = types

export function setStateModal(data) {
  return {
    type: STATE_MODAL,
    stateModal: data
  }
}

export function setLoginMsgError(data) {
  return {
    type: LOGIN_MSG_ERROR,
    loginMsgError: data
  }
}

export function setShowCaptch(data) {
  return {
    type: SHOW_CAPTCHA,
    showCaptcha: data
  }
}
