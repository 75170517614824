import styled from 'styled-components'

import { white } from 'styles/settings/colors'

import rem from 'styles/tools/rem'
import content from 'styles/objects/Content'
import { hexToRgba } from '../../../utils/hexToRgba'

export const Content = styled(content)`
  height: ${rem('70px')};
  line-height: ${rem('70px')};
`

export const Logo = `
  height: ${rem('30px')};
  fill: ${white};
  width: ${rem('74px')};
`

export const Wrapper = styled.nav`
  box-shadow: 0 1px 0 0 ${hexToRgba(white, 0.64)};
  width: 100%;
`
