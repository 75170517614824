import styled from 'styled-components'
import { Link } from 'react-router-dom'

import rem from 'styles/tools/rem'
import em from 'styles/tools/em'
import media from 'styles/tools/media'

import { blizzardBlue, skyBlue, white } from 'styles/settings/colors'

import {
  title18Galano,
  title24Galano,
  title32GalanoBold,
  title40GalanoBold,
  txt12OpenSans,
  txt14OpenSans
} from 'styles/settings/types'

export const Container = styled.section`
  background-color: ${skyBlue};
  height: 100vh;
  padding: ${rem('62px')} 0;
  width: 100%;
  display: flex;
  overflow: auto;

  ${media.large`
        padding: ${rem('124px')} 0 ${rem('69px')};
    `};
`

export const Main = styled.main`
  width: 100%;
`

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`

export const Title = styled.h1`
  ${title24Galano};

  color: ${white};
  letter-spacing: ${em('0.4')};
  line-height: 1;
  margin-bottom: ${rem('-3px')};

  ${media.large`
        ${title40GalanoBold};

        margin: ${rem('-3px')};
        max-width: ${rem('390px')};
    `};
`

export const Close = styled(Link)`
  bottom: ${'27px'};
  position: relative;
  right: ${'2px'};
  text-decoration: none;

  ${media.large`
        bottom: ${rem('83px')};
        height: ${rem('20px')};
        left: ${rem('160px')};
        width: ${rem('20px')};
    `};
`

export const Text = styled.p`
  ${txt14OpenSans} font-weight: 200;
  color: ${white};
  margin-bottom: 0;
  max-width: ${rem('215px')};

  ${media.large`
        max-width: ${rem('333px')};
    `};
`

export const Date = styled.time`
  ${txt12OpenSans} color: ${white};
  text-transform: uppercase;

  ${media.large`

        position: relative;
        letter-spacing: ${em('1px')};

    `};
`

export const Protocol = styled.p`
  ${title18Galano} color: ${white};
  margin: 0;

  ${media.large`
    ${title32GalanoBold}

    letter-spacing: ${em('0.3px')};
    `};
`

export const Illustration = styled.div`
  display: none;

  ${media.large`
        display: block;
    `};
`

export const Separator = styled.hr`
  border: ${rem('1px')} solid ${blizzardBlue};
  margin: ${rem('29px')} 0 ${rem('34px')} 0;

  ${media.large`
        width: ${rem('228px')};
        border: ${rem('1px')} solid ${blizzardBlue};
        margin: ${rem('29px')} 0 ${rem('32px')} 0;
        display: block;
        margin-right: ${rem('600px')};
        width: 30%;
    `};
`
