import {
  VALIDATION_PHONE,
  VALIDATION_PHONE_MSG_EMPTY,
  VALIDATION_PHONE_MSG_INVALID
} from './constants'

export default function validatePhone(value) {
  const validate = !VALIDATION_PHONE.test(value) ? VALIDATION_PHONE_MSG_INVALID : undefined
  return !value ? VALIDATION_PHONE_MSG_EMPTY : validate
}
