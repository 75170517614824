import styled from 'styled-components'
import { Link } from 'react-router-dom'
import media from 'styles/tools/media'
import content from 'styles/_objects/content'
import rem from 'styles/tools/rem'

import { title24Galano, txt14OpenSansBold, title30GalanoBold } from 'styles/settings/types'
import { linkNavyBlue, lightestBlue, darkGrey, nearWhite, orange } from 'styles/settings/colors'

export const Content = styled.div`
  ${content};
  display: flex;
`

export const Item = styled.div`
  ${media.large`
        width: 25%;
    `};
`

export const Label = styled.label`
  color: ${darkGrey};
  font-family: OpenSans;
  font-size: ${rem('12px')};
  font-weight: 200;
  letter-spacing: ${rem('0.4px')};
  line-height: 1;
  pointer-events: none;
  text-transform: uppercase;
  white-space: nowrap;
  display: block;
  margin-bottom: ${rem('15px')};
`

export const Title = styled.p`
  letter-spacing: ${rem('0.3px')};
  margin: 0;
  line-height: 1;
  font-size: ${rem('14px')};
  font-weight: normal;

  ${props =>
    props.right &&
    `
      text-align: right;
      padding-right: 50px;
    `};

  ${props =>
    props.left &&
    `
      text-align: left;
      padding-left: 32px;
    `};
`

export const Text = styled.p`
  ${txt14OpenSansBold};
  color: ${darkGrey};
  line-height: ${rem('1.2px')};

  ${props =>
    props.status === 'atraso' &&
    `
        color: ${orange}
    `};
`

export const Download = styled.button`
  ${txt14OpenSansBold};
  color: ${linkNavyBlue};
  text-decoration: none;
  cursor: pointer;
`

export const LinkToInvoice = styled(Link)`
  display: block;
  width: 100%;
  text-decoration: none;
  color: inherit;
`

export const StatusContainer = styled.div`
  position: relative;
  display: flex;
  align-items: flex-start;
  height: 100%;

  ${media.small`
        margin-top: ${rem('20px')};
    `};
`

export const IconContainer = styled.div`
  display: inline-block;
  width: ${rem('40px')};
  margin-right: ${rem('15px')};

  img {
    max-width: 100%;
  }

  ${media.large`
        margin-right: ${rem('30px')};
    `};
`

export const StatusContent = styled.div`
  p {
    font-size: ${rem('14px')};
  }
`

export const ListItem = styled.li`
  position: relative;
  padding: ${rem('5px')} 0;
  border-bottom: ${rem('1px')} solid ${lightestBlue};
  color: ${darkGrey};

  :hover {
    background-color: ${nearWhite};

    p {
      color: ${linkNavyBlue};
    }
  }

  .download-button {
    position: absolute;
    top: ${rem('30px')};
    right: ${rem('30px')};
    display: none;
  }

  ${props =>
    props.first
      ? `
            ${Title} {
                ${title30GalanoBold};
                color: ${linkNavyBlue};
                letter-spacing: 0.001em;
            }
        `
      : `
            ${Title} {
                ${title24Galano};
            }
    `}

  ${media.small`
        ${props =>
          props.first &&
          `
            ${Title} {
                font-size: ${rem('36px')};
            }

            .download-button {
                top: ${rem('40px')};
            }
          `};

        .month {
            display: none;
        }

        .download-button {
            display: block;
        }
    `};
`

export const ButtonContainer = styled.div`
  position: absolute;
  left: 256px;
  top: 4px;
`
