/* eslint-disable */

import React from 'react'
import { string } from 'prop-types'

import ChooseFilterTypeOption from '../../elements/ChooseFilterTypeOption'

import { FilterTypeContainer, FilterTypes, Container, Content, ListItem, Title } from './styles'

const { title, types } = require('./data.json')

function ChooseFilterType(props) {
  const { isSemiautomatico } = props

  return (
    <Container>
      <Content>
        <Title>{title}</Title>
        <FilterTypeContainer>
          <FilterTypes>
            {types.map(type => (
              <ListItem key={type.typeName}>
                <ChooseFilterTypeOption
                  key={type.typeName}
                  value={type.typeValue}
                  onChange={props.onChange}
                  name="type"
                  filterType={type.typeName}
                  filterIcon={type.typeIcon}
                  disableOnSemiautomatico={type.disableOnSemiautomatico}
                  isSemiautomatico={isSemiautomatico}
                />
              </ListItem>
            ))}
          </FilterTypes>
        </FilterTypeContainer>
      </Content>
    </Container>
  )
}

ChooseFilterType.defaultProps = {
  label: null
}

ChooseFilterType.propTypes = {
  label: string
}

export default ChooseFilterType
