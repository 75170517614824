import { getPaymentType, getDueDate } from '../services/editPaymentDataService'

import types from './types'

const { FETCH_PAYMENT_TYPE, FETCH_DUE_DATES } = types

export function fetchPaymentType(accountPlanId) {
  return dispatch =>
    getPaymentType(accountPlanId).then((types = {}) => {
      const action = {
        type: FETCH_PAYMENT_TYPE,
        paymentTypes: types
      }
      dispatch(action)
    })
}

export function fetchDueDates() {
  return dispatch =>
    getDueDate().then((dates = {}) => {
      const action = {
        type: FETCH_DUE_DATES,
        dueDates: dates
      }
      dispatch(action)
    })
}
