import TemplateEmpty from '../app/templates/TemplateEmpty'
import BlockIdentifiers from '../app/pages/BlockIdentifiers'
import BlockIdentifierSuccess from '../app/pages/BlockIdentifierSuccess'
import BlockIdentifierNew from '../app/pages/BlockIdentifierNew'

export default {
  path: '/minha-veloe/:id/identificador/:identifierId/inativar',
  component: TemplateEmpty,
  routes: [
    {
      path: '/minha-veloe/:id/identificador/:identifierId/inativar',
      exact: true,
      component: BlockIdentifiers
    },
    {
      path: '/minha-veloe/:id/identificador/:identifierId/inativar/sucesso',
      exact: true,
      component: BlockIdentifierSuccess
    },
    {
      path: '/minha-veloe/:id/identificador/:identifierId/inativar/novo-identificador',
      exact: true,
      component: BlockIdentifierNew
    }
  ]
}
