import React from 'react'

const IcClose = props => (
  <svg width={props.width || '11'} height={props.height || '11'} viewBox="0 0 48 48">
    <defs>
      <path
        id="abbbb"
        d="M35.122 37.262c-.59 0-1.18-.221-1.623-.664L.664 3.837a2.227 2.227 0 0 1 0-3.173 2.227 2.227 0 0 1 3.173 0l32.835 32.761a2.227 2.227 0 0 1 0 3.173c-.37.443-.96.664-1.55.664zm-32.835.517c-.59 0-1.18-.222-1.623-.664a2.227 2.227 0 0 1 0-3.173l10.7-10.552a2.227 2.227 0 0 1 3.172 0 2.227 2.227 0 0 1 0 3.173l-10.7 10.552c-.442.442-.958.664-1.549.664zM24.94 15.052c-.59 0-1.18-.22-1.623-.664a2.227 2.227 0 0 1 0-3.172L33.942.738a2.227 2.227 0 0 1 3.173 0 2.227 2.227 0 0 1 0 3.173L26.489 14.388a2.25 2.25 0 0 1-1.55.664z"
      />
    </defs>
    <g fill="none" fillRule="evenodd" transform="translate(5 5)">
      <mask id="bbbb" fill={props.fill || '#fff'}>
        <use xlinkHref="#abbbb" />
      </mask>
      <use fill={props.fill || '#fff'} fillRule="nonzero" xlinkHref="#abbbb" />
      <g mask="url(#bbbb)">
        <path fill={props.fill || '#fff'} d="M-5-5h48v48H-5z" />
      </g>
    </g>
  </svg>
)

export default IcClose
