import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { Avatar } from '../Header/styles'

import { HeaderLabel, DropdownLabel } from '../../elements/Dropdown/styles'

import {
  Anchor,
  AnchorFull,
  MobileMenuContainer,
  UserInfoContainer,
  MobileMenuUpperInfo,
  PlanTitle,
  PlanDescription,
  List,
  PlanItem,
  NavItem,
  Hr
} from './styles'

const { allPlans } = require('./data.json')

function MobileMenu({
  avatar,
  menuOptions,
  categoryLabel,
  username,
  isOpen,
  headerLabelCategory,
  accountPlans,
  onClose,
  userCancelled
}) {
  const planList = accountPlans.map(accountPlan => ({
    link: `/minha-veloe/${accountPlan.plan.id}/resumo`,
    title: accountPlan.plan.type,
    description: accountPlan.plan.category
  }))

  function checkUserStatus(option) {
    return option.cancelledMenu ? option.link : '#'
  }

  return (
    <MobileMenuContainer isOpen={isOpen}>
      <MobileMenuUpperInfo>
        <Avatar src={avatar} />
        <UserInfoContainer>
          <HeaderLabel component={headerLabelCategory}>{categoryLabel}</HeaderLabel>
          <DropdownLabel>{username}</DropdownLabel>
        </UserInfoContainer>
      </MobileMenuUpperInfo>
      <Hr />
      <List>
        {planList.map((plan, index) => (
          <PlanItem key={index}>
            <Anchor to={plan.link} onClick={onClose}>
              <PlanTitle>{plan.title}</PlanTitle>
              <PlanDescription>{plan.description}</PlanDescription>
            </Anchor>
          </PlanItem>
        ))}
      </List>
      <Hr />
      <AnchorFull to="#" onClick={onClose}>
        {allPlans}
      </AnchorFull>
      <Hr />
      <List>
        {menuOptions.map(option => (
          <NavItem key={option.link}>
            <Anchor to={userCancelled ? checkUserStatus(option) : option.link} onClick={onClose}>
              {option.description}
            </Anchor>
          </NavItem>
        ))}
      </List>
    </MobileMenuContainer>
  )
}

MobileMenu.defaultProps = {
  avatar: '',
  menuOptions: [],
  categoryLabel: 'Pessoal',
  username: '',
  isOpen: false,
  headerLabelCategory: '',
  userCancelled: false
}

MobileMenu.propTypes = {
  avatar: PropTypes.string,
  menuOptions: PropTypes.arrayOf(Object),
  categoryLabel: PropTypes.string,
  username: PropTypes.string,
  isOpen: PropTypes.bool,
  headerLabelCategory: PropTypes.string,
  userCancelled: PropTypes.bool
}

const mapStateToProps = ({ reducer }) => ({
  accountPlans: reducer.accountPlans.plans
})

export default connect(mapStateToProps)(MobileMenu)
