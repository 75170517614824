import React, { Component } from 'react'
import { bool } from 'prop-types'
import { throttle } from 'lodash'
import Button from '../../elements/Button/Button'
import { Icon } from '../../elements/Icon'
import StatementsFilterForm from '../StatementsFilterForm'
import {
  ButtonContainerDesktop,
  ButtonContainerMobile,
  Filter,
  FilterBarLabel,
  FilterBarValue,
  FilterContainer,
  FilterClose,
  FilterClosed,
  FilterDriver,
  FilterLicense,
  FilterOpened,
  FilterWrapper
} from './styles'

class StatementsFilter extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isOpen: this.props.visible,
      isFixed: true,
      currentValues: {},
      currentVehicle: ''
    }
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll)
    this.handleScroll()
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll)
  }

  handleScroll = throttle(
    () => {
      const footer = document.getElementById('footer')
      const currentScroll = footer.getBoundingClientRect().top - window.innerHeight > 0
      if (currentScroll > 0) {
        this.setState({
          isFixed: true
        })
      } else {
        this.setState({
          isFixed: false
        })
      }
    },
    50,
    {
      maxWait: 1000
    }
  )

  applyFilter = checkPlan =>
    checkPlan.id.toString() === this.props.accountPlans.activePlanId.toString()

  render() {
    const openFilter = () => {
      this.setState({
        isOpen: true
      })
    }

    const closeFilter = () => {
      this.setState({
        isOpen: false
      })
    }

    const currentPlan = this.props.accountPlans.plans[0]
      ? this.props.accountPlans.plans.find(this.applyFilter)
      : ''

    const findCurrentVehicle = currentIdentifierId => {
      const currentIdentifier = currentPlan.identifiers.find(
        value => currentIdentifierId.toString() === value.carPlate.toString()
      )
      this.setState({
        currentVehicle: currentIdentifier
      })
    }

    const onSubmitFilter = values => {
      const changedValues = {
        plate: values.plate,
        month: values.month,
        type: values.type
      }

      if (this.props.onFilter) {
        this.props.onFilter(changedValues)
      }

      this.setState({
        isOpen: false,
        currentValues: changedValues
      })

      if (changedValues.plate) {
        findCurrentVehicle(changedValues.plate)
      }

      if (changedValues.type === undefined) {
        window.dataLayer = window.dataLayer || []
        window.dataLayer.push({
          event: 'event',
          eventCategory: 'veloe:area-logado:extrato:filtro',
          eventAction: 'clique:filtrar-extrato',
          eventLabel: `filtro:total-expenses`
        })
      } else {
        window.dataLayer = window.dataLayer || []
        window.dataLayer.push({
          event: 'event',
          eventCategory: 'veloe:area-logado:extrato:filtro',
          eventAction: 'clique:filtrar-extrato',
          eventLabel: `filtro:${changedValues.type}`
        })
      }
    }

    if (
      currentPlan.identifiers &&
      currentPlan.identifiers.length === 1 &&
      this.state.currentVehicle === ''
    ) {
      this.setState({
        currentVehicle: currentPlan.identifiers[0]
      })
    }

    return (
      <FilterWrapper>
        <Filter isOpen={this.state.isOpen} isFixed={this.state.isFixed}>
          <FilterContainer isOpen={this.state.isOpen}>
            <FilterClosed isOpen={this.state.isOpen}>
              {this.state.currentVehicle !== '' ? (
                <FilterLicense>
                  <FilterBarLabel>Placa</FilterBarLabel>
                  <FilterBarValue>
                    {this.state.currentVehicle.carPlate.substring(0, 3)}
                    <span>{this.state.currentVehicle.carPlate.substring(3, 7)}</span>
                  </FilterBarValue>
                </FilterLicense>
              ) : (
                <FilterLicense>
                  <FilterBarValue>Todos os veículos</FilterBarValue>
                </FilterLicense>
              )}
              {this.state.currentVehicle !== '' ? (
                <FilterDriver>
                  <FilterBarLabel>Condutor</FilterBarLabel>
                  <FilterBarValue>{this.state.currentVehicle.driverName}</FilterBarValue>
                </FilterDriver>
              ) : (
                <FilterDriver />
              )}
              <ButtonContainerMobile>
                <Button
                  buttonType="skyBlue"
                  text="Filtrar"
                  onClick={() => {
                    openFilter()
                    window.dataLayer = window.dataLayer || []
                    window.dataLayer.push({
                      event: 'event',
                      eventCategory: 'veloe:area-logado:extrato',
                      eventAction: 'clique:botao:filtrar',
                      eventLabel: 'filtro'
                    })
                  }}
                />
              </ButtonContainerMobile>
              <ButtonContainerDesktop>
                <Button
                  buttonType="transparent"
                  text="Filtrar"
                  onClick={() => {
                    openFilter()
                    window.dataLayer = window.dataLayer || []
                    window.dataLayer.push({
                      event: 'event',
                      eventCategory: 'veloe:area-logado:extrato',
                      eventAction: 'clique:botao:filtrar',
                      eventLabel: 'filtro'
                    })
                  }}
                />
              </ButtonContainerDesktop>
            </FilterClosed>
            <FilterOpened isOpen={this.state.isOpen}>
              <FilterClose onClick={closeFilter}>
                <Icon icon="modal-close" height="31.2px" width="31.2px" viewBox="0 0 24 24" />
              </FilterClose>
              <StatementsFilterForm onSubmit={onSubmitFilter} />
            </FilterOpened>
          </FilterContainer>
        </Filter>
      </FilterWrapper>
    )
  }
}

StatementsFilter.defaultProps = {
  visible: false
}

StatementsFilter.propTypes = {
  visible: bool
}

export default StatementsFilter
