import React from 'react'
import { string } from 'prop-types'

import { BackIcon, Wrapper, BackLink, Content, ButtonLink } from './styles'

function ImersiveHeader({ text, url, isColored, back }) {
  return (
    <Wrapper isColored={isColored}>
      <Content>
        {url !== '/' ? (
          <BackLink to={url}>
            <BackIcon icon="back-arrow" width="8px" height="12px" viewBox="0 0 8 12" />
            {text}
          </BackLink>
        ) : (
          <ButtonLink onClick={back}>
            <BackIcon icon="back-arrow" width="8px" height="12px" viewBox="0 0 8 12" />
            {text}
          </ButtonLink>
        )}
      </Content>
    </Wrapper>
  )
}

ImersiveHeader.defaultProps = {
  text: null,
  url: '/'
}

ImersiveHeader.propTypes = {
  text: string.isRequired,
  url: string
}

export default ImersiveHeader
