import React from 'react'
import { connect } from 'react-redux'
import Helmet from 'react-helmet'
import { actions } from 'alelo-logic'
import EditUserDataForm from '../../modules/EditUserDataForm'
import { showModal } from '../../modules/ModalRoot/actions'
import { CONFIRM_UPDATE } from '../../../constants/ModalTypes'
import { startSaving, stopSaving } from '../../modules/form-state-actions'
import { changeUserAvatar } from '../../modules/Header/actions'
import userFactory from './factory'

import { Content, Heading, Paragraph, Wrapper } from './styles'

import image from '../../../static/confirmation.png'
import imageError from '../../../static/contestacao.png'

const { helmetTitle, title, subtitle, modalTitle, titleModalError } = require('./data.json')

const { fetchUpdateProfile, fetchUpdateContact } = actions

const EditUserData = props => {
  const initialValues = userFactory.toForm(props.profile, props.contato)

  return (
    <section>
      <Helmet title={helmetTitle} />
      <Content>
        <Heading>{title}</Heading>
        <Paragraph>{subtitle}</Paragraph>
      </Content>
      <Wrapper>
        <EditUserDataForm
          initialValues={initialValues}
          onSubmit={values => props.handleSave(userFactory.toAPI(values), props.contato)}
        />
      </Wrapper>
    </section>
  )
}

const mapStateToProps = state => ({
  profile: state.reducer.user.profile,
  contato: state.reducer.user.contato
})

const mapDispatchToProps = dispatch => ({
  handleSave: (profile, contato) => {
    dispatch(startSaving())
    const dataProfile = {
      user: {
        'estado-civil': profile['estado-civil'],
        sexo: profile.sexo
      },
      pap: {
        adesaoPap: !(profile.pap === '' || profile.pap === undefined || profile.pap === false),
        documento: profile.documento
      }
    }
    contato.nome.nomeCompleto = profile.name
    contato.nome.emiteNotaFiscal = !(
      profile.emiteNotaFiscal === undefined ||
      profile.emiteNotaFiscal === '' ||
      profile.emiteNotaFiscal === false
    )
    Promise.all([dispatch(fetchUpdateContact(contato)), dispatch(fetchUpdateProfile(dataProfile))])
      .then(() => {
        dispatch(showModal(CONFIRM_UPDATE, { modalTitle, image }))
        dispatch(stopSaving())
        profile.avatar && dispatch(changeUserAvatar(profile.avatar))
      })
      .catch(e => {
        dispatch(showModal(CONFIRM_UPDATE, { modalTitle: titleModalError, image: imageError }))
        dispatch(stopSaving())
      })
  }
})

export default connect(mapStateToProps, mapDispatchToProps)(EditUserData)
