import React from 'react'
import { RouteWithSubRoutes } from '../../../routes'
import PageLoader from 'app/modules/PageLoader'

function TemplateEmpty({ routes }) {
  return (
    <PageLoader>
      <div>
        {routes.map(route => (
          <RouteWithSubRoutes key={route.path} {...route} />
        ))}
      </div>
    </PageLoader>
  )
}

export default TemplateEmpty
