import React from 'react'
import { string } from 'prop-types'

import { Field } from 'redux-form'

import { MonthOption, Input, Label } from './styles'

const renderFilterMonthOption = props => (
  <Input
    {...props.input}
    name={props.name}
    onChange={() => props.input.onChange(props.input.value)}
    value={props.input.value}
    type="radio"
  />
)

function ChooseFilterMonthOption(props) {
  const { filterMonth, name, value } = props

  return (
    <Label>
      <Field component={renderFilterMonthOption} name={name} type="radio" value={value} />
      <MonthOption>
        <span>{filterMonth}</span>
      </MonthOption>
    </Label>
  )
}

ChooseFilterMonthOption.defaultProps = {
  filterMonth: null
}

ChooseFilterMonthOption.propTypes = {
  filterMonth: string.isRequired
}

export default ChooseFilterMonthOption
