import React, { Component } from 'react'
import { connect } from 'react-redux'
import Helmet from 'react-helmet'
import {
  setDashboardStatements,
  fetchDashboardStatements,
  resetDashboardStatements
} from 'alelo-logic/actions'
import StatementsHeader from '../../elements/StatementsHeader'
import AddItem from '../../modules/AddItem'
import StatementsList from '../../modules/StatementsList'
import StatementsFilter from '../../modules/StatementsFilter'
import { setLoading } from '../../modules/PageLoader/actions'

import Content from '../../../styles/objects/Content'
import { Title, Paragraph } from './styles'

import statementFactory from './factory'
const { helmetTitle, topbar } = require('./data.json')

class DashboardStatement extends Component {
  state = {
    showFilter: false,
    loading: false
  }

  componentWillMount() {
    this.props.resetStatements()
    this.props.setLoading(true)
    this.props.setStatements(this.props.match.params.id)
  }

  componentWillUnmount() {
    this.props.resetStatements()
  }

  selectedFilters = {}

  resetList() {
    this.props.resetStatements()
  }

  loadStatements = (nextPage = 0) => {
    const params = this.props.match.params || {}
    this.props.fetchStatements(params.id, nextPage, this.selectedFilters)
  }

  doStatementFetch = (nextPage, filter) => {
    const params = this.props.match.params || {}
    this.props.fetchStatements(params.id, nextPage, filter)
    this.setState({ loading: true })
  }

  openFilter = () => {
    this.setState({ showFilter: true })
  }

  applyFilter = filter => {
    this.selectedFilters = filter
    this.props.resetStatements()
    this.doStatementFetch(null, this.selectedFilters)
    this.setState({ showFilter: false })
  }

  closeFilter = () => {
    this.setState({ showFilter: false })
  }

  render() {
    const { statements, accountPlans } = this.props
    return (
      <div>
        <Helmet title={helmetTitle} />
        <StatementsHeader
          statements={statements}
          currency={topbar.currency}
          match={this.props.match}
        />
        {statements.data.length === 0 ? (
          <Content>
            <Title>Ops!</Title>
            <Paragraph>Nenhuma transação encontrada para o filtro selecionado.</Paragraph>
          </Content>
        ) : (
          <div>
            <AddItem
              data={statementFactory.toForm(statements.data)}
              next={statements.next}
              fetchHandler={this.loadStatements}
              hasNext={statements.hasNext}
            >
              <StatementsList />
            </AddItem>
          </div>
        )}
        <StatementsFilter
          onFilter={values => this.applyFilter(values)}
          statements={statements}
          accountPlans={accountPlans}
        />
      </div>
    )
  }
}

const mapStateToProps = ({ reducer }) => ({
  statements: reducer.dashboardStatements,
  accountPlans: reducer.accountPlans
})

const mapDispatchToProps = dispatch => ({
  setStatements: (planId, nextPage, filter) => {
    dispatch(setDashboardStatements(planId, nextPage, filter)).then(resp => {
      if (resp && resp.status === 204) {
        dispatch(setLoading(false))
        dispatch(resetDashboardStatements())
      }
      dispatch(setLoading(false))
    })
  },

  fetchStatements: (planId, nextPage, filter) => {
    dispatch(fetchDashboardStatements(planId, nextPage, filter)).then(resp => {
      if (resp && resp.status === 204) {
        dispatch(setLoading(false))
        dispatch(resetDashboardStatements())
      }
      dispatch(setLoading(false))
    })
  },

  resetStatements: () => {
    dispatch(setLoading(true))
    dispatch(resetDashboardStatements())
  },

  setLoading: isLoading => {
    dispatch(setLoading(isLoading))
  }
})

export default connect(mapStateToProps, mapDispatchToProps)(DashboardStatement)
