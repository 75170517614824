import React, { Component } from 'react'
import { connect } from 'react-redux'
import Helmet from 'react-helmet'
// Services and Actions
import {
  fetchGetManageInfsHistory,
  fetchGetManageInfsActive,
  fetchGetVehicles,
  fetchLogout,
  deleteManageInf
} from 'alelo-logic/actions'
import { setLoading } from 'app/modules/PageLoader/actions'

import { showModal } from '../../modules/ModalRoot/actions'
import image from '../../../static/contestacao.png'
import { CONFIRM_UPDATE } from '../../../constants/ModalTypes'
// UIComponents
import ImersiveHeader from 'app/elements/ImersiveHeader'
// Components
import ManageInfoModal from 'app/modules/ManageInfoModal'
import ManageInfoHeader from 'app/modules/EditIdentifierHeader'
import ManageInfoList from 'app/modules/ManageInfoList'
import ManageInfoHistoricList from 'app/modules/ManageInfoHistoricList'
const { helmetTitle, titleModalError } = require('./data.json')

class EditIdentifiers extends Component {
  state = {
    vehicle: {},
    rowActive: null,
    identifier: {},
    selectValue: 'INFORMACAO_1',
    idIdentifierDelete: null,
    isModalOpen: false,
    modalType: 'edit',
    loadingDelete: false
  }

  componentDidMount() {
    const { vehicles, fetchGetVehicles, fetchLogout, setLoading, history, match } = this.props
    const { id } = match.params
    if (vehicles.length === 0) {
      setLoading(true)
      return fetchGetVehicles(id).then(resp => {
        if (resp && (resp.status === 401 || resp.status === 403)) {
          fetchLogout()
          history.push('/conta/login')
        }
        setLoading(false)
      })
    }
    this.setVehicle()
  }

  componentDidUpdate(prevProps) {
    const { vehicles } = this.props
    if (vehicles !== prevProps.vehicles && vehicles.length > 0) {
      this.setVehicle()
    }
  }

  setVehicle = () => {
    const { vehicles, match, fetchGetManageInfsHistory, fetchGetManageInfsActive } = this.props
    const { identifierId, id } = match.params
    const vehicle = vehicles.find(obj => obj.codIdentificador === identifierId)
    if (vehicle) {
      this.setState({ vehicle }, () => {
        fetchGetManageInfsActive(id, vehicle.veiculo.placa)
        fetchGetManageInfsHistory(id, vehicle.veiculo.placa)
      })
    }
  }

  onChangeSelect = selectValue => {
    this.setState({ selectValue })
    window.dataLayer.push({
      event: 'event',
      eventAction: `selecionar:${selectValue}`,
      eventCategory: 'veloe:area-logado:meus-produtos:informacoes-gerenciais',
      eventLabel: `${selectValue}`
    })
  }

  onRowClick = id => {
    const { rowActive } = this.state
    this.setState({ rowActive: rowActive !== null ? null : id })
  }

  onRowEdit = identifier => {
    const vehicle = {
      veiculo: {
        placa: identifier.placa
      },
      ...identifier
    }
    this.setState({ identifier: vehicle, isModalOpen: true, modalType: 'edit' })
  }

  onRowDelete = item => {
    this.setState({ loadingDelete: true })
    const data = {
      id: item.id,
      tipoInfo: item.tipoInfo,
      descricao: item.descricaoAtual,
      placa: item.placa,
      conta: item.conta,
      dataInicioVigencia: item.dataInicioVigencia,
      codIdentificador: item.codIdentificador
    }
    this.props
      .deleteManageInf(data)
      .then(() => {
        this.setVehicle()
        this.setState({ loadingDelete: false })
      })
      .catch(() => {
        this.props.dispatch(showModal(CONFIRM_UPDATE, { modalTitle: titleModalError, image }))
        this.setState({ loadingDelete: false })
      })
  }

  toggleModal = () => {
    this.setState(prevState => {
      if (prevState.isModalOpen) {
        this.setVehicle()
      }
      return { isModalOpen: !prevState.isModalOpen }
    })
  }

  onAddInf = () => {
    this.setState({ isModalOpen: true, modalType: 'include', identifier: this.state.vehicle })
  }

  render() {
    const { vehicle, rowActive, selectValue, isModalOpen, identifier, loadingDelete } = this.state
    const { manageInfsReducer, match } = this.props
    const { manageInfs, manageInfsHistory } = manageInfsReducer
    const { id } = match.params
    return (
      <section>
        <Helmet title={helmetTitle} />
        <ImersiveHeader text="Meus Produtos" url={`/minha-veloe/${id}/identificadores`} />
        {/* VehicleInfo */}
        <ManageInfoHeader vehicle={vehicle} />
        {/* Manage Info */}
        <ManageInfoList
          data={manageInfs}
          rowActived={rowActive}
          onRowClick={this.onRowClick}
          onDelete={this.onRowDelete}
          onEdit={this.onRowEdit}
          onAddInf={this.onAddInf}
          valueSelect={selectValue}
          onChangeSelect={this.onChangeSelect}
          toggleModal={this.toggleModal}
          loadingDelete={loadingDelete}
        />
        {/* Historic */}
        <ManageInfoHistoricList data={manageInfsHistory} />
        {/* Modal */}
        {isModalOpen && (
          <ManageInfoModal
            isOpen={isModalOpen}
            toggleModal={this.toggleModal}
            modalStart={this.state.modalType}
            vehicle={identifier}
          />
        )}
      </section>
    )
  }
}

const mapStateToProps = ({ reducer }) => ({
  vehicles: reducer.dashboardVehicles,
  manageInfsReducer: reducer.manageInfs
})

const dispatch = dispatch => dispatch

export default connect(mapStateToProps, {
  setLoading,
  fetchLogout,
  fetchGetVehicles,
  fetchGetManageInfsActive,
  deleteManageInf,
  fetchGetManageInfsHistory,
  dispatch
})(EditIdentifiers)
