import React from 'react'
import { string, number, oneOfType } from 'prop-types'

import { Container, Name, Value } from './styles'

const ConsumptionChartIndicator = ({ name, value, color }) => (
  <Container>
    <Name color={color}>{name}</Name>
    <Value>{value}</Value>
  </Container>
)

ConsumptionChartIndicator.defaultProps = {
  name: '',
  value: null,
  color: ''
}

ConsumptionChartIndicator.propTypes = {
  name: string,
  value: oneOfType([number, string]),
  color: string
}

export default ConsumptionChartIndicator
