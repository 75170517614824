import { utils } from 'alelo-logic'
import Button from 'app/elements/Button'
import { Box } from 'grid-styled'
import T from 'prop-types'
import React from 'react'
import { Field } from 'redux-form'
import { renderTextInput } from 'utils/renderInputs'
import { ListForm } from './styles'
const { labels } = require('../../data.json')

const defaultProps = {
  handleSubmit: () => {},
  onSubmit: () => {},
  identifiers: {},
  fetchIdentifierType: () => {},
  identifier: {}
}

const propTypes = {
  handleSubmit: T.func,
  onSubmit: T.func,
  identifier: T.any,
  identifiers: T.any,
  identifierType: T.string,
  fetchIdentifierType: T.func,
  fieldIdentifier: T.any
}

export const IdentifierForm = props => {
  const { handleSubmit, onSubmit, fetchIdentifierType, fieldIdentifier } = props

  const submit = v => {
    fetchIdentifierType(v, onSubmit)
    window.dataLayer.push({
      event: 'event',
      eventAction: 'clique:validar-tag',
      eventCategory: 'veloe:area-logado:ativar-identificador',
      eventLabel: 'validar-tag'
    })
  }

  const reset = () => {
    onSubmit(null)
  }

  return (
    <div>
      <form onSubmit={handleSubmit(submit)}>
        <ListForm>
          <Box width={1} mb={1}>
            <div>
              <Field
                component={renderTextInput}
                label={labels.identifier.default}
                name="identifier"
                type="text"
                validate={utils.identifierValidate}
                onChange={reset}
                isRequired
                onFocus={() =>
                  window.dataLayer.push({
                    event: 'event',
                    eventAction: 'preencher:numero-tag',
                    eventCategory: 'veloe:area-logado:ativar-identificador',
                    eventLabel: 'numero-tag'
                  })
                }
              />
            </div>
          </Box>
          <Box width={1} mb={1} style={{ marginBottom: '-20px' }}>
            <div>
              <Button
                text={labels.verifyButton}
                type="submit"
                width="200px"
                disabled={!fieldIdentifier}
              />
            </div>
          </Box>
        </ListForm>
      </form>
    </div>
  )
}

IdentifierForm.defaultProps = defaultProps
IdentifierForm.propTypes = propTypes

export default IdentifierForm
