import styled, { css } from 'styled-components'
import rem from '../../../styles/tools/rem'

export const Container = styled.div`
  max-width: ${rem('1280px')};
  margin: 0 auto;
`

export const MainWrapper = styled.main`
  max-width: ${rem('940px')};
  margin: inherit;
`

export const Main = styled.main`
  display: block;
  ${({ modalOpen }) =>
    modalOpen &&
    css`
      height: 100vh;
    `};
`
