import { SAVING_FORM } from '../constants/ActionTypes'

function formStateReducer(state = {}, action = {}) {
  switch (action.type) {
    case SAVING_FORM: {
      return action.state
    }

    default:
      return state
  }
}

export default formStateReducer
