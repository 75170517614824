import React from 'react'

const Less = ({ color = '#292663' }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <defs>
      <path id="less-a" d="M5 13a1 1 0 0 1 0-2h14a1 1 0 1 1 0 2H5z" />
    </defs>
    <g fill="none" fillRule="evenodd">
      <mask id="less-b" fill="#fff">
        <use xlinkHref="#less-a" />
      </mask>
      <g fill={color} mask="url(#less-b)">
        <path d="M0 0h24v24H0z" />
      </g>
    </g>
  </svg>
)

export default Less
