import styled from 'styled-components'
import { greyBlue, linkNavyBlue, paleBlue, white } from '../../../styles/settings/colors'
import rem from '../../../styles/tools/rem'
import media from '../../../styles/tools/media'
import { txt16OpenSansBold, txt16OpenSans } from '../../../styles/settings/types'

export const FooterContainer = styled.div`
  background-color: ${linkNavyBlue};
  font-family: 'OpenSans';
  overflow: hidden;
  width: 100%;
`
export const CardsFlags = styled.div`
  display: flex;
`
export const CardsContainer = styled.div`
  align-items: center;
  color: ${greyBlue};
  display: flex;
  font-size: ${rem('12px')};
  justify-content: center;
  letter-spacing: ${rem('0.8px')};
  padding: 30px 0;
  ${media.small`
      flex-direction: column-reverse;
      padding: 0px 0px 30px;
  `};
`
export const CardFlag = 'height: 30px; width: 50px; margin-right: 10px'

export const Row = styled.div`
  margin-bottom: 20px;
  display: block;
`

export const AppIcon = 'height: 38px; width: 110px; margin: 17px 20px 0 0;'

export const AppsLinkContainer = styled.div`
  display: flex;
`

export const FooterNavigation = styled.div`
  align-items: center;
  background-color: ${linkNavyBlue};
  color: ${paleBlue};
  display: flex;
  justify-content: center;
  padding: 50px 0 20px;
`

export const LogoSocial = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-right: 140px;
`

export const DescriptionItem = styled.p`
  font-size: ${rem('12px')};
  font-weight: 600;
  margin: 0;

  &:last-child {
    margin-bottom: 0;
  }
`

export const NavigationBottomItem = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 48px;

  ${DescriptionItem} {
    margin-bottom: 0;
  }
`

export const NavigationColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 240px;
  justify-content: center;

  ${media.small`
    margin-bottom: 0;
  `};
`

export const NavigationContent = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;

  ${media.small`
    flex-flow: wrap;
  `};
`

export const NavigationLabel = styled.div`
  ${({ bold }) => (bold ? txt16OpenSansBold : txt16OpenSans)};
`

export const NavigationText = styled.span`
  font-size: ${rem('12px')};
  line-height: 1.5;
  text-align: center;
  /* conditionally adds margin on small using a prop */
  ${({ marginOnSmall }) =>
    marginOnSmall &&
    media.small`
        margin-top: 30px;
    `};
  ${props =>
    props.short &&
    `
      max-width: ${rem('200px')};
  `};
`

export const SocialIcons = 'height: 24px; width: 58px;'

export const TelephoneNumber = styled.span`
  color: ${white};
  font-family: 'Galano';
  font-size: ${rem('24px')};
  font-weight: 600;
  margin-top: 5px;
`

export const VeloeLogo = `fill: ${white}; height: 41px; width: 99px; margin-bottom: 15px;`
