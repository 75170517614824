import { doCancelAccount } from '../services/accountService'

import types from './types'

const { FETCH_CANCEL_ACCOUNT } = types

function fetchCancelAccount(data) {
  return dispatch =>
    doCancelAccount(data).then(() => {
      dispatch({
        type: FETCH_CANCEL_ACCOUNT,
        state: false
      })
    })
}

export default fetchCancelAccount
