import React from 'react'
import Icon from '../Icon/Icon'

import {
  AppIcon,
  AppsLinkContainer,
  FooterNavigation,
  LogoSocial,
  NavigationBottomItem,
  NavigationColumn,
  NavigationContent,
  NavigationLabel,
  NavigationLink,
  NavigationText,
  SocialIcons,
  VeloeLogo,
  TelephoneNumber
} from './styles'

function FooterNavigationLarge() {
  return (
    <FooterNavigation>
      <LogoSocial>
        <Icon icon="veloe-logo" svgStyle={VeloeLogo} width="74" height="31" viewBox="0 0 74 31" />
        <Icon
          icon="social-icons"
          svgStyle={SocialIcons}
          width="58"
          height="24"
          viewBox="0 0 58 24"
        />
      </LogoSocial>
      <NavigationContent>
        <NavigationColumn>
          <NavigationLink>Sobre a Veloe</NavigationLink>
          <NavigationLink>Planos</NavigationLink>
          <NavigationLink>Onde encontrar</NavigationLink>
          <NavigationBottomItem>
            <NavigationLabel>Baixe nossos apps</NavigationLabel>
            <AppsLinkContainer>
              <Icon
                icon="play-store"
                svgStyle={AppIcon}
                width="110"
                height="38"
                viewBox="0 0 110 38"
              />
              <Icon
                icon="app-store"
                svgStyle={AppIcon}
                width="110"
                height="38"
                viewBox="0 0 110 38"
              />
            </AppsLinkContainer>
          </NavigationBottomItem>
        </NavigationColumn>
        <NavigationColumn>
          <NavigationLink>Ajuda</NavigationLink>
          <NavigationLink>Imprensa</NavigationLink>
          <NavigationLink>Carreiras</NavigationLink>
        </NavigationColumn>
        <NavigationColumn>
          <NavigationLink>Mapa do site</NavigationLink>
          <NavigationLink>Termos de uso</NavigationLink>
          <NavigationLink>Mais carreiras</NavigationLink>
          <NavigationBottomItem>
            <NavigationLink>Tem alguma dúvida?</NavigationLink>
            <TelephoneNumber>0800 000 800</TelephoneNumber>
            <NavigationText short>
              Estamos prontos para te atender 24h por dia, 7 dias por semana.
            </NavigationText>
          </NavigationBottomItem>
        </NavigationColumn>
      </NavigationContent>
    </FooterNavigation>
  )
}

export default FooterNavigationLarge
