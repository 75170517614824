import styled from 'styled-components'
import rem from 'styles/tools/rem'
import media from 'styles/tools/media'

import { txt16OpenSans } from 'styles/settings/types'

import { paleGrayTwo, darkSlateBlue, deliveredGreen, carnation } from 'styles/settings/colors'

export const LineTextContainer = styled.div`
  display: flex;
  align-items: center;
  min-height: 100%;
  justify-content: ${({ justifyContent }) => justifyContent || `flex-start`};
`

export const LineText = styled.div`
  ${txt16OpenSans};
  font-weight: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: ${darkSlateBlue};
  display: block;

  ${({ capitalize }) =>
    capitalize &&
    `
    text-transform: capitalize;
  `};

  ${({ bold }) =>
    bold &&
    `
    font-weight: bold;
  `};
  ${({ green }) =>
    green &&
    `
    color: ${deliveredGreen}
  `};
  ${({ red }) =>
    red &&
    `
    color: ${carnation}
  `};
`

export const ListItem = styled.div`
  display: block;
  padding: ${rem('18px')} 0 ${rem('18px')};
  border-bottom: ${rem('1px')} solid ${paleGrayTwo};

  ${media.small`
    width: 100%;
    padding: ${rem('13px')} 0;
    margin: 0;
  `};
`
