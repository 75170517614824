import React from 'react'

import WarningBase from './base'
import illustrationMobile from 'static/illustration-mobile.svg'

const CheckApp = () => (
  <WarningBase
    content={
      <div>
        <b>Vai pegar a estrada?</b> Consulte o app, veja se seu produto está ativo e seu saldo.
        <br />
        <br />
        Conte com a gente para descomplicar o seu caminho!
      </div>
    }
    image={<img src={illustrationMobile} className="WarningImage-mobile" alt="mobile" />}
  />
)

export default CheckApp
