import { getReasonList } from '../services/commonService'

import types from './types'

const { FETCH_REASON_LIST, RESET_REASON_LIST } = types

function fetchReasonList(identifierId) {
  return dispatch =>
    getReasonList(identifierId).then(response => {
      dispatch({
        type: FETCH_REASON_LIST,
        state: response
      })
      return response
    })
}

export function resetReasonList() {
  return dispatch => dispatch({ type: RESET_REASON_LIST })
}

export default fetchReasonList
