import React from 'react'
import { currencyParser } from 'alelo-logic/utils'
import { isMobile } from 'utils/mediaQuery'

import {
  Table,
  TableHeader,
  TableHeaderCell,
  TableHeaderActionCell,
  TableRow,
  TableRowCell,
  TableRowBoldCell,
  TableRowActionCell
} from './styles'
const { tableConfig } = require('./data.json')

const Index = ({ data }) => {
  const { columns } = tableConfig

  if (isMobile()) {
    return <MobileTable data={data} />
  } else {
    return (
      <Table>
        <TableHeader>
          {columns.map((column, index) =>
            column.type === 'action' ? (
              <TableHeaderActionCell
                index={index}
                key={`money-transfer-header-${index}`}
                width={column.width}
              >
                {column.header}
              </TableHeaderActionCell>
            ) : (
              <TableHeaderCell
                index={index}
                key={`money-transfer-header-${index}`}
                width={column.width}
              >
                {column.header}
              </TableHeaderCell>
            )
          )}
        </TableHeader>
        {data.map((row, index) => (
          <TableRow key={`money-transfer-body-${index}`} index={index}>
            <TableRowBoldCell width={columns[0].width}>{row.date}</TableRowBoldCell>
            <TableRowBoldCell width={columns[1].width}>
              {currencyParser(row.value)}
            </TableRowBoldCell>
            <TableRowCell width={columns[2].width}>{row.status}</TableRowCell>
            <TableRowActionCell width={columns[3].width}>ver transações</TableRowActionCell>
          </TableRow>
        ))}
      </Table>
    )
  }
}

const MobileTable = ({ data }) => {
  return (
    <Table>
      <TableHeader>
        <TableHeaderCell key="money-transfer-header-2" width={50}>
          REPASSES
        </TableHeaderCell>
        <TableHeaderActionCell key="money-transfer-header-1" width={50}>
          Ver mais >
        </TableHeaderActionCell>
      </TableHeader>
      {data.map((row, index) => (
        <TableRow
          key={`money-transfer-body-${index}`}
          index={index}
          style={{ justifyContent: 'space-between' }}
        >
          <TableRowBoldCell width={33}>{row.date}</TableRowBoldCell>
          <TableRowBoldCell width={33}>{currencyParser(row.value)}</TableRowBoldCell>
          <TableRowCell width={33}>{row.status}</TableRowCell>
        </TableRow>
      ))}
    </Table>
  )
}

export default Index
