import { css } from 'styled-components'

const media = {
  small: (...args) => css`
    @media (max-width: 767px) {
      ${css(...args)};
    }
  `,
  large: (...args) => css`
    @media (min-width: 768px) {
      ${css(...args)};
    }
  `
}

export default media
