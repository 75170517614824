import styled from 'styled-components'

import { borderGrey, darkGrey, white } from '../../../styles/settings/colors'
import { txt12OpenSansLight } from '../../../styles/settings/types'
import rem from '../../../styles/tools/rem'
import content from '../../../styles/_objects/content'

export const Content = styled.section`
  ${content};
`

export const GroupHeader = styled.div`
  background-color: ${white};
  border-bottom: ${rem('2px')} ${borderGrey} solid;
  border-top: ${rem('2px')} ${borderGrey} solid;
  padding: ${rem('10px')} 0;
`

export const GroupSpan = styled.div`
  ${txt12OpenSansLight};

  color: ${darkGrey};
  text-transform: uppercase;
`

export const GroupSpanCenter = styled.div`
  ${txt12OpenSansLight};

  color: ${darkGrey};
  text-align: center;
  text-transform: uppercase;
`

export const GroupAmountSpan = styled.div`
  ${txt12OpenSansLight};

  color: ${darkGrey};
  text-transform: uppercase;
  text-align: right;
`
