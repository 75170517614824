import styled from 'styled-components'
import { darkBlue, darkGrey } from '../../../styles/settings/colors'
import { title24Galano, txt16OpenSans, txt14OpenSans } from '../../../styles/settings/types'

import content from '../../../styles/_objects/content'
import rem from '../../../styles/tools/rem'

export const Heading = styled.h1`
  ${title24Galano};

  color: ${darkBlue};
  line-height: 1;
  margin: 0 0 ${rem('24px')};
  max-width: ${rem('300px')};
`
export const Subtitle = styled.p`
  ${txt16OpenSans};

  color: ${darkBlue};
  font-weight: bold;
  line-height: 1;
  margin: 0 0 ${rem('10px')};
`

export const Paragraph = styled.p`
  ${txt14OpenSans};

  color: ${darkGrey};
  margin: 0 0 ${rem('40px')};
`

export const Wrapper = styled.div`
  margin-bottom: ${rem('32px')};
`

export const Content = styled.section`
  ${content};

  margin: ${rem('42px')} auto;
`

export const ParagraphGrayLabel = styled.p`
  ${txt14OpenSans};
  text-transform: uppercase;
  color: ${darkGrey};
  margin: 0 0;
  margin: 10px 0 0 15px;
  position: absolute;
`
