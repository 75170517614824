import React from 'react'
import styled from 'styled-components'
import { Box } from 'grid-styled'
import media from '../tools/media'

export default styled(({ at, ...props }) => <Box {...props} />)`
  ${props => media[props.at]`
        display: none;
    `};
`
