import { getPlanResume } from '../services/dashboardService'

import types from './types'

const { UPDATE_PLAN_RESUME } = types

export default function fetchDashboardResume(planId) {
  return dispatch => {
    dispatch({
      type: UPDATE_PLAN_RESUME,
      planResume: {}
    })

    return getPlanResume(planId).then(planResume => {
      dispatch({
        type: UPDATE_PLAN_RESUME,
        planResume
      })
    })
  }
}
