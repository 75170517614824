import types from '../actions/types'

const {
  FETCH_MANAGE_INF,
  FETCH_MANAGE_INF_FINISHED,
  FETCH_HIST_MANAGE_INF,
  FETCH_HIST_MANAGE_INF_FINISHED,
  FETCH_TOP5_MANAGE_INF,
  FETCH_TOP5_MANAGE_INF_FINISHED,
  UPDATE_MANAGE_INF,
  UPDATE_MANAGE_INF_FINISHED
} = types

const initialState = {
  manageInfs: [],
  loadingInfs: false,
  manageInfsHistory: [],
  loadingHistory: false,
  manageInfsTop5: [],
  loadingTop5: false,
  updateLoading: false
}

function dashboardVehiclesReducer(state = initialState, action = {}) {
  switch (action.type) {
    case FETCH_MANAGE_INF: {
      return { ...state, loadingInfs: true }
    }
    case FETCH_MANAGE_INF_FINISHED: {
      return { ...state, manageInfs: action.informations, loadingInfs: false }
    }
    case FETCH_TOP5_MANAGE_INF: {
      return { ...state, loadingTop5: true }
    }
    case FETCH_TOP5_MANAGE_INF_FINISHED: {
      return { ...state, manageInfsTop5: action.informations, loadingTop5: false }
    }
    case FETCH_HIST_MANAGE_INF: {
      return { ...state, loadingHistory: true }
    }
    case FETCH_HIST_MANAGE_INF_FINISHED: {
      return { ...state, manageInfsHistory: action.informations, loadingHistory: false }
    }
    case UPDATE_MANAGE_INF:
      return { ...state, updateLoading: true }
    case UPDATE_MANAGE_INF_FINISHED:
      return { ...state, updateLoading: false }
    default:
      return state
  }
}

export default dashboardVehiclesReducer
