import moment from 'moment'

const userFactory = {
  toAPI: data =>
    data && {
      name: data.name,
      // cpf: data.cpf,
      'estado-civil': { codigo: data.maritalStatus },
      // date_of_birth: data.dob,
      // mother_name: data.mothersName,
      sexo: { codigo: data.gender },
      // "lastTimeSaved": new Date().toLocaleString(),
      // "avatar": data.avatar //TODO quando existir....
      emiteNotaFiscal: data.emiteNotaFiscal,
      pap: data.pap,
      documento: data.cpf
    },
  toForm: (data, contact) =>
    data && {
      name: data.nome,
      cpf: data.cpf,
      dob: moment(data.nascimento).format('DD/MM/YYYY'),
      mothersName: data['nome-mae'],
      gender: data.sexo.codigo,
      maritalStatus: data['estado-civil'].codigo,
      lastTimeSaved: new Date().toLocaleString(),
      avatar: data.avatar,
      emiteNotaFiscal: contact.nome.emiteNotaFiscal,
      pap: data.pap
    }
}

export default userFactory
