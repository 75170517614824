import React, { Component } from 'react'
import Helmet from 'react-helmet'
import { connect } from 'react-redux'

import { utils } from 'alelo-logic'

import { Box, Flex } from 'grid-styled'

import Content from '../../../styles/objects/Content'

import {
  Close,
  Container,
  Date,
  Header,
  Illustration,
  Main,
  Protocol,
  Separator,
  Text,
  Title
} from './style'

import Button from '../../elements/Button'
import { Icon } from '../../elements/Icon'
import contestacao from '../../../static/contestacao.png'

const { text, title, confirmButtonLabel } = require('./data.json')

class ContestationConfirm extends Component {
  getUrlStatement = urlContestation => {
    const split = urlContestation.split('/')
    return `/${split[1]}/${split[2]}/${split[3]}`
  }

  render() {
    const { contestationProtocol } = this.props

    const urlStatement = this.getUrlStatement(this.props.location.pathname)

    return (
      <Container>
        <Content>
          <Flex>
            <Main>
              <Flex>
                <Helmet title="Contestation" />
                <Header>
                  <Title>{title}</Title>
                  <Close to={urlStatement}>
                    <Icon height={23} icon="new-close" viewBox="0 0 52 53" />
                  </Close>
                </Header>
              </Flex>
              <Flex>
                <Text>{text}</Text>
              </Flex>
              <Separator />
              <Box mb={[90, 75]}>
                <Flex>
                  <Protocol>{contestationProtocol.protocol}</Protocol>
                </Flex>
                <Date>
                  Salvo em {utils.ISODateFormat(contestationProtocol.date)}
                  às {utils.ISODateFormat(contestationProtocol.date, 'HH:mm')}
                </Date>
              </Box>
              <Box>
                <Button
                  buttonType="transparent"
                  text={confirmButtonLabel}
                  width="204px"
                  onClick={() => this.props.history.push(urlStatement)}
                />
              </Box>
            </Main>
            <Illustration>
              <img src={contestacao} alt="Ilustraçāo veloe" />
            </Illustration>
          </Flex>
        </Content>
      </Container>
    )
  }
}

const mapStateToProps = ({ reducer }) => ({
  contestationProtocol: reducer.contestationProtocol
})

export default connect(mapStateToProps)(ContestationConfirm)
