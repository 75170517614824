import styled from 'styled-components'

import rem from 'styles/tools/rem'
import media from 'styles/tools/media'
import { white } from 'styles/settings/colors'

import {
  title144GalanoBold,
  title24Galano,
  title32GalanoBold,
  title44GalanoBold
} from 'styles/settings/types'

import { PageTitle } from '../../templates/TemplateRecharge/styles'

export const Title = styled(PageTitle)`
  line-height: 1.3;
  margin-bottom: ${rem('30px')};

  ${media.large`
        max-width: ${rem('350px')};
    `};
`

export const Text = styled(PageTitle)`
  ${title24Galano};
  letter-spacing: ${rem('1px')};
`

export const TextSmall = styled(PageTitle)`
  ${title24Galano};
  font-size: ${rem('15px')};
  letter-spacing: ${rem('1px')};
  margin-top: ${rem('15px')};
`

export const Cash = styled.p`
  align-items: center;
  color: ${white};
  display: inline-flex;
  justify-content: space-between;
  margin: 0;
`

export const CashPrefix = styled.span`
  ${title24Galano} line-height: 1;
  margin-right: ${rem('22px')};

  ${media.large`
        margin-right: ${rem('12px')};
        ${title32GalanoBold}
    `};
`

export const CashValue = styled.span`
  ${title44GalanoBold} font-size: ${rem('100px')};
  line-height: 1;

  ${media.large`
        ${title144GalanoBold}
        letter-spacing: .072em;
    `};
`
