import styled from 'styled-components'

import { white } from '../../../styles/settings/colors'

import { txt12OpenSans, txt14OpenSans } from '../../../styles/settings/types'

import rem from '../../../styles/tools/rem'
import em from '../../../styles/tools/em'

import media from '../../../styles/tools/media'

export const Container = styled.section`
  max-width: ${rem('940px')};
  margin: 0 auto;
  padding: ${rem('9px')} 0 ${rem('17px')};

  ${media.small`
        padding: ${rem('7px')} ${rem('24px')} 0;
    `};
`

export const Content = styled.div`
  display: block;
`

export const FilterTypeContainer = styled.div`
  ${media.large`
        padding: 0;
    `};
  ${media.small`
        margin: 0 ${rem('-24px')};
        overflow: auto;
        padding: 0 ${rem('20px')};
    `};
`

export const FilterTypes = styled.ul`
  display: flex;
  justify-content: flex-start;
  list-style: none;
  margin: 0;
  min-height: ${rem('76px')};
  padding: 0;
  width: 100%;

  ${media.small`
        display: block;
        padding-right: 10%;
        width 212%;

        :after {
            clear: both;
            content: '';
            display: table;
        }
    `};
`

export const ListItem = styled.li`
  padding: 0 ${rem('3px')};
  width: ${rem('224px')};

  :first-child {
    padding-left: 0;
  }

  :last-child {
    padding-right: 0;
  }

  ${media.small`
        float: left;
        margin-bottom: ${rem('24px')};
        text-align: left;
        width: 25%;
    `};
`

export const Title = styled.h2`
  ${txt12OpenSans} letter-spacing: ${em('1px')};
  line-height: 1.33;
  color: ${white};
  margin: 0 0 ${rem('15px')};
  text-transform: uppercase;

  ${media.small`
        ${txt14OpenSans}
    `};
`
