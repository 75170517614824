import styled from 'styled-components'

import { darkGrey, linkNavyBlue } from '../../../../../styles/settings/colors'
import {
  title40GalanoBold,
  title24GalanoBold,
  txt12OpenSansLight
} from '../../../../../styles/settings/types'
import media from '../../../../../styles/tools/media'
import rem from '../../../../../styles/tools/rem'

export const CashDisplay = styled.section`
  margin-bottom: 17px;
  ${media.small`
        display: flex;
        justify-content: space-between;
    `};
`

export const Text = styled.p`
  ${txt12OpenSansLight};

  color: ${darkGrey};
  margin: 0;
  text-transform: uppercase;

  span {
    display: block;

    ${media.large`
            display: inline;

            :after {
                content: " • "
            }
        `};
  }
`

export const Currency = styled.p`
  ${title24GalanoBold};

  color: ${linkNavyBlue};
  margin: 0;
  text-align: right;

  ${media.large`
        ${title40GalanoBold};
        text-align: left;
        margin-top: ${rem('10')};
    `};
`
