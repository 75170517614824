import React from 'react'

import BannerZapay from 'static/banner-zapay.png'

function Zapay() {
  return (
    <div>
      <a href="https://veloewl.usezapay.com.br" target="_blank">
        <img src={BannerZapay} className="WarningImage-megaphone" alt="banner-zapay" />
      </a>
    </div>
  )
}

export default Zapay
