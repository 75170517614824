import React, { useState } from 'react'

import CollapsibleItem from '../../elements/CollapsibleItem'

function CollapsibleFormList(props) {
  const [itemStatus, setItemStatus] = useState([])

  const toggleItem = index => {
    const newItemsStatus = itemStatus

    newItemsStatus[index] = !newItemsStatus[index]

    setItemStatus(newItemsStatus)
  }

  const { onDelete, onUpdate, collapsibleItems, children } = props

  return (
    <section>
      {collapsibleItems.map(
        (collapsibleItem, index) =>
          collapsibleItem.document && (
            <CollapsibleItem
              key={collapsibleItem.document}
              label={collapsibleItem.relationship}
              title={collapsibleItem.name}
              active={itemStatus[index]}
              onClick={() => toggleItem(index)}
            >
              {React.cloneElement(children, {
                form: `representative${collapsibleItem.document}`,
                initialValues: collapsibleItem,
                disableKeyFields: true,
                onDelete:
                  collapsibleItems.length > 1 &&
                  (() => onDelete(collapsibleItem.document, () => toggleItem(index))),
                onSubmit: (...args) => onUpdate(...args, () => toggleItem(index)),
                onCancel: () => toggleItem(index)
              })}
            </CollapsibleItem>
          )
      )}
    </section>
  )
}

CollapsibleFormList.defaultProps = {
  collapsibleItems: []
}

export default CollapsibleFormList
