import { updateBusinessUserContact } from '../services/userService'

import types from './types'

const { FETCH_CONTACT_UPDATE } = types

function fetchUpdateBusinessContact(data) {
  return dispatch =>
    updateBusinessUserContact(data).then(contact => {
      dispatch({
        type: FETCH_CONTACT_UPDATE,
        state: contact
      })
    })
}

export default fetchUpdateBusinessContact
