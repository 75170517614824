import React from 'react'
import FooterNavigationLarge from './FooterNavigationLarge'

import Content from 'styles/objects/Content'
import { FooterContainer, CardsContainer, NavigationText } from './styles'

const Footer = () => {
  return (
    <FooterContainer id="footer">
      <Content>
        <FooterNavigationLarge />
        <CardsContainer>
          <NavigationText marginOnSmall>© Veloe 2020 todos direitos reservados</NavigationText>
        </CardsContainer>
      </Content>
    </FooterContainer>
  )
}

export default Footer
