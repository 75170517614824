import types from '../actions/types'

const { UPDATE_REASONS } = types

function reasonsReducer(state = {}, action = {}) {
  switch (action.type) {
    case UPDATE_REASONS: {
      return {
        ...state,
        reasons: action.reasons
      }
    }
    default:
      return state
  }
}

export default reasonsReducer
