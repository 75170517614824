import styled, { css } from 'styled-components'

import { darkGrey, linkNavyBlue } from '../../../styles/settings/colors'
import {
  title40GalanoBold,
  title24GalanoBold,
  txt12OpenSansLight,
  txt40OpenSans
} from '../../../styles/settings/types'
import media from '../../../styles/tools/media'
import rem from '../../../styles/tools/rem'

export const CashDisplay = styled.section`
  ${media.small`
        display: flex;
        justify-content: space-between;
    `};

  ${({ recharge }) =>
    !recharge &&
    css`
      margin-bottom: ${rem('15px')};
    `};
`

export const Text = styled.p`
  ${txt12OpenSansLight};

  color: ${darkGrey};
  margin: 0;
  text-transform: uppercase;

  span {
    display: block;

    ${media.large`
            display: inline;

            :after {
                content: " • "
            }
        `};
  }
`

export const DueDateContainer = styled.div`
  text-align: right;
  margin-top: ${rem('10px')};

  ${Text} {
    margin: ${rem('2px')};
  }
  ${media.large`
        margin-top: 0;
    `};
`

export const DueDate = styled.span`
  font-family: Galano;
  font-size: ${rem('18px')};
  font-weight: bold;
  color: ${linkNavyBlue};
`

export const Currency = styled.p`
  ${title24GalanoBold};

  color: ${linkNavyBlue};
  margin: 0;
  text-align: right;
  span {
    ${txt40OpenSans};
  }
  ${media.large`
        ${title40GalanoBold};
        text-align: left;
        margin-top: ${rem('10')};
    `};
`
