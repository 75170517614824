import React from 'react'
import { connect } from 'react-redux'
import { Flex, Box } from 'grid-styled'
import { reduxForm } from 'redux-form'

import Button from 'app/elements/Button'
import { FileTypeField, SequentialField } from './form-fields'
import {
  FormSimpleContent,
  SimpleFormQueryButtonContainer,
  Container,
  RequestFileFormTitle,
  RequestFileFormSubtitle
} from './styles'

const { labels, fileTypes, simpleForm } = require('./data.json')

class RequestFileForm extends React.Component {
  componentWillMount() {
    this.props.initialize()
  }

  render() {
    const { handleSubmit, saving } = this.props

    return (
      <FormSimpleContent>
        <Container>
          <RequestFileFormTitle>{simpleForm.title}</RequestFileFormTitle>
          <RequestFileFormSubtitle>{simpleForm.subtitle}</RequestFileFormSubtitle>
          <form onSubmit={handleSubmit}>
            <Flex wrap>
              <Box width={1} mb={3}>
                <FileTypeField fileTypes={fileTypes} saving={saving} />
              </Box>
              <Box width={1} mb={3}>
                <SequentialField saving={saving} />
              </Box>
              <Box width={1} mb={3}>
                <SimpleFormQueryButtonContainer>
                  <Button text={labels.submitButton} type="submit" disabled={saving} />
                </SimpleFormQueryButtonContainer>
              </Box>
            </Flex>
          </form>
        </Container>
      </FormSimpleContent>
    )
  }
}

const form = reduxForm({
  form: 'fileRequestForm',
  destroyOnUnmount: false
})(RequestFileForm)

const mapStateToProps = ({ reducer }) => ({
  saving: reducer.formState.saving
})

export default connect(mapStateToProps)(form)
