import styled, { keyframes, css } from 'styled-components'

import { hexToRgba } from '../../../utils/hexToRgba'
import { white, darkBlue, butterflyBush, quartz } from '../../../styles/settings/colors'

import { txt24OpenSansBold, txt12OpenSans } from '../../../styles/settings/types'

import media from '../../../styles/tools/media'
import rem from '../../../styles/tools/rem'

const showModalContent = keyframes`
    0% {
        opacity: 0;
        transform: translateY(${rem('30px')}) scale(.95);
    }

    100% {
        opacity: 1;
        transform: translateY(0) scale(1);
    }
`

const hideModalContent = keyframes`
0% {
    opacity: 1;
    transform: translateY(0) scale(1);
}

100% {
    opacity: 0;
    transform: translateY(${rem('30px')}) scale(.95);
}
`

const showModal = keyframes`
0% {
    opacity: 0;
}

100% {
    opacity: 1;
}
`

const hideModal = keyframes`
0% {
    opacity: 1;
}

100% {
    opacity: 0;
}
`

export const Overlay = styled.div`
  align-items: center;
  background: ${({ modalBlue }) => (modalBlue ? hexToRgba(darkBlue, 0.64) : 'rgba(0, 0, 0, 0.15)')};
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
`

export const Content = styled.div`
  opacity: 0;
  position: relative;
  transform-origin: bottom;
  transition: 0.4s;
  z-index: 1;
  width: 100%;

  ${({ state }) => {
    switch (state) {
      case 'entered':
        return css`
          animation: 0.4s ${showModalContent} forwards;
        `
      case 'exiting':
        return css`
          animation: 0.4s ${hideModalContent} forwards;
        `
      case 'exited':
        return css`
          display: none;
        `
      default:
        return css`
          display: block;
        `
    }
  }};
  ${media.large`
      max-width: ${({ maxWidth }) => (maxWidth ? `${maxWidth}px` : rem('660px'))};
  `};
`

export const Wrapper = styled.div`
  align-items: center;
  background: rgba(0, 0, 0, 0.2);
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  opacity: 0;
  position: fixed;
  right: 0;
  top: 0;
  transition: 0.4s;
  z-index: 101;

  ${({ state }) => {
    switch (state) {
      case 'entered':
        return css`
          animation: 0.4s ${showModal} forwards;
        `
      case 'exiting':
        return css`
          animation: 0.4s ${hideModal} forwards;
        `
      case 'exited':
        return css`
          display: none;
        `
      default:
        return css`
          display: block;
        `
    }
  }};
`

export const ModalContainer = styled.div`
  background-color: ${white};
  height: 100vh;
  overflow: auto;
  position: relative;
  padding: ${rem('44px')} ${rem('76px')};
  width: 100%;
  ${media.large`
    border-radius: ${rem('8px')};
    box-shadow: 0 ${rem('8px')} ${rem('8px')} 0 rgba(0, 0, 0, 0.12);
    height: auto;
  `};
`

export const ModalContent = styled.div`
  padding: ${({ noPadding }) => (noPadding ? '0 !important' : `${rem('60px')} ${rem('30px')}`)};
  ${({ center }) => center && 'text-align: center'};
  display: flex;
  justify-content: center;
  flex-direction: column;
  ${media.large`
      ${({ compact }) =>
        compact
          ? css`
              padding: ${rem('40px')} ${rem('64px')};
            `
          : css`
              padding: ${rem('74px')} ${rem('60px')} ${rem('55px')};
            `}
  `};
`

export const QrCodeDescription = styled.div`
  ${txt24OpenSansBold};
  color: ${darkBlue};
  margin-bottom: ${rem('10px')};
`

export const Notice = styled.div`
  ${txt12OpenSans};
  display: flex;
  background-color: ${quartz};
  color: ${butterflyBush};
  padding: 15px 25px 15px 0;
  border-radius: 12px;

  & > svg {
    margin: 12px 12px 0;
  }
`

export const NoticeContent = styled.div`
  padding: 0 ${rem('10px')};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: left;
  & > p:first-of-type {
    margin-bottom: ${rem('20px')};
  }
`

export const NoticeText = styled.p`
  font-weight: 600;
  line-height: 1.57;
  margin: 0;
`
