import styled, { keyframes } from 'styled-components'
import { darkBlue, darkSlateBlue, darkGrey } from '../../../styles/settings/colors'
import { title18Galano, title24Galano, txt14OpenSans } from '../../../styles/settings/types'

import content from '../../../styles/_objects/content'
import rem from '../../../styles/tools/rem'
import media from '../../../styles/tools/media'

export const Content = styled.div`
  ${content};
  margin: ${rem('42px')} auto;
  ${media.small`
      margin: ${rem('29px')} auto;
  `};
`

export const Paragraph = styled.p`
  ${txt14OpenSans} color: ${darkBlue};
  width: ${rem('270px')};
  margin-top: ${rem('7px')};
  ${media.large`
    width: ${rem('313px')};
    margin: 0;
  `};
`

export const Heading = styled.h1`
  ${title24Galano}
  color: ${darkSlateBlue};
  font-size: 48px;
  font-weight: bold;
  line-height: 1.08;
  letter-spacing: normal;
  margin: 0 0 ${rem('10px')};
  ${media.small`
    ${title18Galano}
    margin: ${rem('8px')} 0;
  `};
`

export const WrapperButtons = styled.div`
  display: flex;
  justify-content: space-between;
  min-width: 400px;
  & > div {
    margin: 0 15px;
  }
`

export const BoxHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 40px;
`

export const SearchBox = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: ${rem('20px')};
`

const spin = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`

export const LoadingContainer = styled.div`
  margin-top: ${rem('20px')};
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    animation: ${spin} 1s linear infinite;
  }
`

export const LoadingText = styled.span`
  margin-left: ${rem('10px')};
  ${txt14OpenSans};
  color: ${darkGrey};
`
