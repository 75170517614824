import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import { clearECQueryPlate, fetchECQueryPlate } from 'alelo-logic/actions'

import arrowLeft from '../../../../static/ic-arrow-blue-left.png'

import Header from './header'
import CarPlateForm from './form'
import Car from './car'
import { Main, HideOnSmall, HideOnLarge, BackToFormContainer, BackToFormText } from './styles'

class CarPlateQuery extends React.Component {
  state = {
    formValues: {}
  }

  componentWillMount() {
    const { state } = this.props.history.location
    if (state) this.onForm(state)
  }

  onForm = values => {
    this.setState({ formValues: values })
    this.props.fetchQueryPlate(values)
  }

  render() {
    const { clearQueryPlate, carData } = this.props
    const { formValues } = this.state

    return carData == null ? (
      <Main>
        <Header />
        <CarPlateForm formValues={formValues} onSubmit={this.onForm} />
      </Main>
    ) : (
      <Main>
        <HideOnSmall>
          <CarPlateForm formValues={formValues} onSubmit={this.onForm} />
        </HideOnSmall>
        <HideOnLarge>
          <BackToFormContainer>
            <img src={arrowLeft} alt={'voltar'} />
            <BackToFormText onClick={clearQueryPlate}>Fazer nova consulta</BackToFormText>
          </BackToFormContainer>
        </HideOnLarge>
        <Car data={carData} />
      </Main>
    )
  }
}

CarPlateQuery.displayName = 'Consultar Placa'

const mapStateToProps = ({ reducer }) => ({
  carData: reducer.ecQueryPlate
})

const mapDispatchToProps = dispatch => ({
  clearQueryPlate: () => dispatch(clearECQueryPlate()),
  fetchQueryPlate: values => dispatch(fetchECQueryPlate(values))
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CarPlateQuery))
