import React from 'react'

import Button from 'app/elements/Button'
import { Container, Title, Subtitle, ButtonContainer } from './styles'

const { title, subtitle, button } = require('./data.json')

const Advertise = () => (
  <Container>
    <header>
      <Title>{title}</Title>
      <Subtitle>{subtitle}</Subtitle>
    </header>
    <ButtonContainer>
      <Button text={button} />
    </ButtonContainer>
  </Container>
)

export default Advertise
