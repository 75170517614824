import styled, { css } from 'styled-components'

import rem from 'styles/tools/rem'
import media from 'styles/tools/media'

import { grey, linkNavyBlue } from 'styles/settings/colors'

export const Item = styled.li`
  margin: 0;

  ${props =>
    props.flexibleWidth &&
    css`
      max-width: ${rem('190px')};
    `} &:last-child {
    max-width: 100%;
  }
`

export const IconColor = `fill: ${linkNavyBlue}`

export const List = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
`

export const Navigator = styled.nav`
  padding: 1em;
  text-align: center;

  ${media.large`
        ${({ hasArrow }) =>
          hasArrow &&
          css`
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            padding-top: 0;
            padding-bottom: 0;
          `}
    `};
`

export const NavigatorList = styled.ul`
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const NavItem = styled.li`
    -webkit-transition: all .5s;
    background-clip: content-box;
    background-color: ${grey};
    border-radius: 50%;
    border: ${rem('1px')}; solid transparent;
    cursor: pointer;
    display: inline-block;
    height: ${rem('12px')};
    height: ${rem('8px')};
    list-style: none;
    margin: 0 ${rem('3px')};
    transition: all .5s;
    width: ${rem('12px')};
    width: ${rem('8px')};

    ${props =>
      props.active &&
      `
        background-color: ${linkNavyBlue};
    `}

`

export const CarouselWrapper = styled.div`
  overflow: hidden;
  width: 100%;

  ${({ mobileOnly }) =>
    mobileOnly &&
    css`
      > ${List} {
        > ${Item} {
          cursor: default;

          ${({ startedCarousel, fullWidth }) =>
            !startedCarousel &&
            css`
              ${media.large`
                        flex: ${fullWidth ? `1` : '.5'};
                        :not(:last-child) {
                            margin-right: ${rem('20px')};
                        }
                    `};
            `} ${({ startedCarousel }) =>
            startedCarousel &&
            `
                    flex: none;
                    cursor: move;
                `};
        }
      }
    `};
`
