import { getUser } from '../services/userService'

import types from './types'

const { FETCH_USER } = types

function fetchGetUser() {
  return async dispatch => {
    try {
      const user = await getUser()
      dispatch({ type: FETCH_USER, state: user })
      return user
    } catch (error) {
      console.log('Erro em #fetchGetUser()')
    }
  }
}

export default fetchGetUser
