import types from '../actions/types'

const {
  UPDATE_PLAN_DATA,
  FETCH_PAYMENT_TYPE,
  FETCH_DUE_DATES,
  FETCH_NEXT_PAYMENT_TYPE_DATE,
  GET_EDIT_AUTH_PAYMENT,
  GET_LAST_PAYMENT_SCHEDULE,
  SET_EDIT_AUTH_PAYMENT,
  LOCK_EDIT_AUTH_PAYMENT
} = types

function planDataReducer(state = { authorizations: {} }, action = {}) {
  switch (action.type) {
    case UPDATE_PLAN_DATA: {
      return {
        ...state,
        ...action.plandata
      }
    }
    case FETCH_PAYMENT_TYPE: {
      return {
        ...state,
        paymentTypes: action.paymentTypes
      }
    }
    case FETCH_DUE_DATES: {
      return {
        ...state,
        dueDates: action.dueDates
      }
    }
    case FETCH_NEXT_PAYMENT_TYPE_DATE: {
      return {
        ...state,
        nextPaymentDate: {
          ...action.nextDate
        }
      }
    }
    case GET_EDIT_AUTH_PAYMENT:
    case LOCK_EDIT_AUTH_PAYMENT: {
      return {
        ...state,
        authorizations: {
          ...state.authorizations,
          [action.changeType]: action.authorizations
        }
      }
    }
    case SET_EDIT_AUTH_PAYMENT: {
      return {
        ...state,
        authorizations: {
          ...state.authorizations,
          [action.changeType]: {
            ...action.authorizations.message
          }
        }
      }
    }
    case GET_LAST_PAYMENT_SCHEDULE: {
      return {
        ...state,
        paymentSchedule: action.payload
      }
    }
    default:
      return state
  }
}

export default planDataReducer
