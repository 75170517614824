import React from 'react'
import { Field, reduxForm } from 'redux-form'
import { Flex, Box } from 'grid-styled'
import { utils } from 'alelo-logic'

import { Container } from './styles'

import Button from '../../elements/Button'

import { renderTextInput, renderSelectField } from '../../../utils/renderInputs'

const { labels } = require('./data.json')

function convertData(data) {
  if (data) {
    const reasonsOptions = []
    let newItem
    reasonsOptions.push({
      value: '',
      label: 'Selecione',
      selected: true,
      disabled: true,
      hidden: true
    })
    data.forEach(oldItem => {
      newItem = {}
      newItem.value = oldItem.id
      newItem.label = oldItem.description
      reasonsOptions.push(newItem)
    })
    return reasonsOptions
  }
}

const ContestationForm = ({ data, handleSubmit }) => {
  const reasonsData = convertData(data) || []

  return (
    <form onSubmit={handleSubmit}>
      <Container>
        <Flex wrap width={[1, 420]}>
          <Box width={1} mb={2}>
            <Field
              component={renderSelectField}
              label={labels.motivation}
              name="options"
              options={reasonsData}
              validate={utils.emptyFieldValidate}
              negative
            />
          </Box>
          <Box width={1} mb={3}>
            <Field
              component={renderTextInput}
              label={labels.details}
              name="name"
              type="text"
              maxlength="150"
              lengthCounter
              placeholder={labels.insert}
              negative
            />
          </Box>
          <Box mt={39} width={[1, 180]}>
            <Button type="submit" buttonType="transparent" text="Enviar" />
          </Box>
        </Flex>
      </Container>
    </form>
  )
}

export default reduxForm({
  form: 'contestationForm',
  destroyOnUnmount: false
})(ContestationForm)
