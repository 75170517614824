import React, { useState } from 'react'
import { string, bool, oneOfType } from 'prop-types'
import { skyBlue, grey } from '../../../styles/settings/colors'

import { Icon } from '../Icon'
import TextInput from '../TextInput'
import StepCounter from '../StepCounter'

import { Content, SecurityLevel, ShowPassword, Wrapper } from './styles'

function PasswordInput(props) {
  const [showPassword, setShowPassword] = useState(false)

  const preventDefault = e => {
    e.preventDefault()
  }

  const togglePassword = () => {
    setShowPassword(!showPassword)
    window.dataLayer.push({
      event: 'event',
      eventAction: 'login:visualizar-senha',
      eventCategory: 'veloe:login',
      eventLabel: 'visualizar:login'
    })
  }

  const { passwordStrength, passwordMessage, errorMessage, loading, maxlength } = props

  const isSecurity = !showPassword
  const inputType = isSecurity ? 'password' : 'text'

  return (
    <Wrapper isSecurity={isSecurity}>
      <Content>
        <TextInput
          {...props}
          onCopy={preventDefault}
          onCut={preventDefault}
          onPaste={preventDefault}
          type={inputType}
          maxlength={maxlength}
          errorMessage={errorMessage || (!!passwordStrength && passwordStrength < 8)}
        />
        {!loading && (
          <ShowPassword onClick={togglePassword}>
            <Icon
              width="24"
              height="24"
              icon="eye"
              viewBox="0 -20 100 100"
              color={showPassword ? skyBlue : grey}
            />
          </ShowPassword>
        )}
      </Content>
      {(passwordMessage || passwordStrength) && (
        <SecurityLevel>
          <StepCounter counter={passwordStrength} message={passwordMessage} />
        </SecurityLevel>
      )}
    </Wrapper>
  )
}

TextInput.defaultProps = {
  passwordStrength: null,
  passwordMessage: null
}

TextInput.propTypes = {
  passwordStrength: oneOfType([string, bool]),
  passwordMessage: oneOfType([string, bool])
}

export default PasswordInput
