import types from '../actions/types'

const { FETCH_REASON_LIST } = types

function reasonsListBlockReducer(state = {}, { type, state: reasons = [] }) {
  switch (type) {
    case FETCH_REASON_LIST: {
      return {
        reasons
      }
    }
    default:
      return state
  }
}

export default reasonsListBlockReducer
