import {
  getGenderList,
  getMaritialStatusList,
  getTelephoneTypesList
} from '../services/commonService'

import { setCookie, getCookie } from '../utils/cookie'

let genderList = null
let maritialStatusList = null
export function fetchGenderList() {
  if (genderList) return Promise.resolve(genderList)

  return getGenderList().then(resp => {
    genderList = resp
    return resp
  })
}

export function fetchMaritialStatusList() {
  if (maritialStatusList) return Promise.resolve(maritialStatusList)
  return getMaritialStatusList().then(resp => {
    maritialStatusList = resp
    return resp
  })
}

export function fetchTelephoneTypeList() {
  // const storedTelephoneType = getCookie('telephone-type');

  // if (storedTelephoneType) {
  //    return new Promise(resolve => resolve(JSON.parse(storedTelephoneType)));
  // }

  return getTelephoneTypesList().then(resp => {
    setCookie('telephone-type', JSON.stringify(resp), 30)
    return resp
  })
}

export function fetchStatesList() {
  const storedStates = getCookie('states')

  if (storedStates) {
    return new Promise(resolve => resolve(JSON.parse(storedStates)))
  }

  return fetchStatesList().then(resp => {
    setCookie('states', JSON.stringify(resp), 30)
    return resp
  })
}
