import { getTermsAndConditions } from '../services/accountService'

import types from './types'

const { FETCH_TERMS_CONDITIONS } = types

function fetchTermsAndConditions() {
  return dispatch =>
    getTermsAndConditions().then(response => {
      dispatch({
        type: FETCH_TERMS_CONDITIONS,
        state: response
      })
    })
}

export default fetchTermsAndConditions
