import TemplateEmpty from '../app/templates/TemplateEmpty'

import FirstAccessIdentifierNotArrived from '../app/pages/FirstAccessIdentifierNotArrived'
import FirstAccessCreatePassword from '../app/pages/FirstAccessCreatePassword'
import FirstAccessIdentifierNewAddress from '../app/pages/FirstAccessIdentifierNewAddress'
import NewIdentifierSuccess from '../app/pages/NewIdentifierSuccess'

export default {
  path: '/primeiro-acesso',
  component: TemplateEmpty,
  routes: [
    {
      path: '/primeiro-acesso/criar-senha',
      exact: true,
      component: FirstAccessCreatePassword
    },
    {
      path: '/primeiro-acesso/extravio',
      exact: true,
      component: FirstAccessIdentifierNotArrived
    },
    {
      path: '/primeiro-acesso/novo-endereco',
      exact: true,
      component: FirstAccessIdentifierNewAddress
    },
    {
      path: '/primeiro-acesso/novo-identificador-sucesso',
      exact: true,
      component: NewIdentifierSuccess
    }
  ]
}
