import { checkCreditCardFlag } from './validators'

const getFinalNumberCard = number => {
  const cardArray = number.split(' ')
  return cardArray[cardArray.length - 1]
}

export const formatAccount = account => {
  if (account) {
    return account.replace('-', '')
  }
  return ''
}

export const normalizeFormValuesForModal = (formValues, nextPaymentDate) => {
  if (formValues.paymentMethod === 'CAJA') {
    return {
      paymentMethod: formValues.paymentMethod,
      finalNumber: getFinalNumberCard(formValues.cardNumber),
      cardFlag: checkCreditCardFlag(formValues.cardNumber),
      validThru: formValues.validThru,
      ...nextPaymentDate
    }
  } else if (formValues.paymentMethod === 'CUENTA') {
    return {
      paymentMethod: formValues.paymentMethod,
      agency: formValues.agency,
      bankCode: formValues.bankCode,
      account: formatAccount(formValues.account) + '-' + formValues.accountDigit,
      ...nextPaymentDate
    }
  } else if (formValues.paymentMethod === 'CARTAO_DEBITO') {
    return {
      paymentMethod: formValues.paymentMethod,
      finalNumber: getFinalNumberCard(formValues.cardNumber),
      cardFlag: checkCreditCardFlag(formValues.cardNumber),
      validThru: formValues.validThru,
      ...nextPaymentDate
    }
  }
}
