import React from 'react'

import WarningBase from './base'
import illustrationSignal from 'static/illustration-signal.svg'

const Signalization = () => (
  <WarningBase
    content={
      <div>
        Ao ver um <b>sinal vermelho</b> na pista, <b>pare e siga as orientações do agente.</b> Assim
        você evita multa por evasão de pedágio.
      </div>
    }
    image={<img src={illustrationSignal} className="WarningImage-signal" alt="sinal" />}
  />
)

export default Signalization
