export default {
  __html: `
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
    <g fill="#B1B0C5" fill-rule="nonzero">
        <path d="M15.868 11.464a.701.701 0 0 1-.7-.699V8.621c0-.862-.349-1.7-.954-2.306a3.225 3.225 0 0 0-2.307-.956c-.862 0-1.701.35-2.307.956a3.225 3.225 0 0 0-.955 2.306v2.144a.701.701 0 0 1-.7.7.717.717 0 0 1-.698-.7V8.621c0-1.258.489-2.423 1.374-3.308a4.674 4.674 0 0 1 3.31-1.375c1.234 0 2.422.49 3.308 1.375a4.674 4.674 0 0 1 1.375 3.308v2.144c-.047.396-.35.7-.746.7zM11.86 16.683a.701.701 0 0 1-.699-.699v-1.91c0-.396.326-.7.7-.7.372 0 .698.327.698.7v1.91a.687.687 0 0 1-.699.7z"/>
        <path d="M16.148 19.969H7.573a2.337 2.337 0 0 1-2.33-2.33v-5.266a2.337 2.337 0 0 1 2.33-2.33h8.575a2.337 2.337 0 0 1 2.33 2.33v5.266c-.024 1.281-1.072 2.33-2.33 2.33zm-8.575-8.505a.91.91 0 0 0-.909.909v5.266c0 .49.396.909.909.909h8.575a.91.91 0 0 0 .908-.91v-5.265a.91.91 0 0 0-.908-.909H7.573z"/>
    </g>
</svg>

    `
}
