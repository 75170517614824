import gql from 'graphql-tag'

export const gqlGetVehiclesCategory = gql`
  query consultaCategorias($categoria: String!) {
    consultaCategorias(categoria: $categoria) {
      descricao
      id
    }
  }
`

export const gqlVerifyIdentifier = gql`
  query identificadorSerial($serialNumber: String!) {
    identificadorSerial(serialNumber: $serialNumber) {
      tipoIdentificador
    }
  }
`

export const gqlReasons = gql`
  query identificadorMotivoBloqueio($codIdentificador: String!) {
    identificadorMotivoBloqueio(codIdentificador: $codIdentificador) {
      cobrar
      chave
      valor
      descricao
    }
  }
`

export const gqlActivateIdentification = gql`
  mutation identificadorAtivar(
    $placa: String!
    $categoria: String!
    $id: String!
    $tipoProduto: String!
    $codIdentificador: String!
    $tipoIdentificador: String!
  ) {
    identificadorAtivar(
      identificador: {
        veiculo: { placa: $placa, categoria: $categoria }
        clienteConta: { conta: { id: $id, tipoProduto: $tipoProduto } }
        codIdentificador: $codIdentificador
        tipoIdentificador: $tipoIdentificador
      }
    ) {
      result {
        idConta
        nomeUsuario
      }
      error {
        codigo
        message
      }
    }
  }
`

export const gqlActivateEMVACGIdentification = gql`
  mutation ativarIdentificadorNFCEMV(
    $cvv: String!
    $codIdentificador: String!
    $tipoIdentificador: String!
    $idConta: String!
    $idCliente: String!
    $nome: String!
    $cpf: String!
  ) {
    ativarIdentificadorNFCEMV(
      identificador: {
        cvv: $cvv
        codIdentificador: $codIdentificador
        tipoIdentificador: $tipoIdentificador
        clienteConta: {
          conta: { id: $idConta }
          cliente: { id: $idCliente, nome: $nome, cpf: $cpf }
        }
      }
    ) {
      result {
        idConta
        nomeUsuario
      }
      error {
        codigo
        message
      }
    }
  }
`

export const gqlSetAlias = gql`
  mutation identificadorPFapelidoPost($codIdentificador: String!, $alias: String!) {
    identificadorPFapelidoPost(
      idCodIdentificadorPost: { codIdentificador: $codIdentificador, alias: $alias }
    ) {
      result {
        alias
      }
      error {
        message
      }
    }
  }
`

export const gqlNewIdentifier = gql`
  mutation identificadorAdquirir(
    $quantidadeIdentificadores: Int!
    $endereco: EnderecoIdentificadorInput
    $id: String!
    $tipoIdentificador: String!
    $fluxoCanal: String
    $valorAdesao: String
    $cobrar: Boolean
    $entregarIdentificador: Boolean
    $tipoPlano: String
  ) {
    identificadorAdquirir(
      adquirirIdentificador: {
        fluxoCanal: $fluxoCanal
        quantidadeIdentificadores: $quantidadeIdentificadores
        endereco: $endereco
        codigoCliente: { conta: { id: $id } }
        tipoIdentificador: $tipoIdentificador
        valorAdesao: $valorAdesao
        cobrar: $cobrar
        entregarIdentificador: $entregarIdentificador
        tipoPlano: $tipoPlano
      }
    ) {
      result
      error {
        message
      }
    }
  }
`

export const gqlIdentificadores = gql`
  query identificadoresPJ($idConta: String!) {
    identificadoresPJ(idConta: $idConta) {
      idIdentificador
      codIdentificador
      tipoIdentificador
      informacoesGerenciais {
        tipoInfo
        descricaoAtual
      }
      veiculo {
        marca
        modelo
        placa
        chassi
        renavam
        categoria
        tipo
      }
      situacao
      dataAtualizacao
    }
  }
`

export const gqlDesabilitarIdentificador = gql`
  mutation identificadorInativar(
    $quantidadeIdentificadores: Int!
    $codigoIdentificador: String!
    $motivoInativacaoIdentificador: String!
    $motivoInativacaoIdentificadorDescricao: String
    $id: String!
  ) {
    identificadorInativar(
      inativarIdentificador: {
        quantidadeIdentificadores: $quantidadeIdentificadores
        codigoIdentificador: $codigoIdentificador
        motivoInativacaoIdentificador: $motivoInativacaoIdentificador
        motivoInativacaoIdentificadorDescricao: $motivoInativacaoIdentificadorDescricao
        codigoCliente: { conta: { id: $id } }
      }
    ) {
      result
      error {
        message
      }
    }
  }
`

export const gqlSubstituirIdentificador = gql`
  mutation identificadorSubstituir(
    $codigoConta: String!
    $codigoIdentificador: String
    $motivoInativacao: String
    $motivoDescricao: String
    $novoCodigo: String
    $cep: String
    $logradouro: String
    $numero: String
    $complemento: String
    $bairro: String
    $uf: String
    $cidade: String
  ) {
    identificadorSubstituir(
      substituirIdentificador: {
        codigoCliente: { conta: { id: $codigoConta, tipoProduto: "POS" } }
        codigoIdentificador: $codigoIdentificador
        motivoInativacaoIdentificador: $motivoInativacao
        motivoInativacaoIdentificadorDescricao: $motivoDescricao
        novoCodigoIdentificador: $novoCodigo
        endereco: {
          cep: $cep
          logradouro: $logradouro
          numero: $numero
          complemento: $complemento
          bairro: $bairro
          uf: $uf
          cidade: $cidade
        }
      }
    ) {
      result
      error {
        codigo
        message
      }
    }
  }
`

export const transformIndentificadores = src => {
  const { identificadoresPJ } = src.data
  return (
    identificadoresPJ &&
    identificadoresPJ.map(({ 'id-conta': idConta, 'id-cliente': idCliente, ...rest }) => ({
      ...rest,
      idConta,
      idCliente
    }))
  )
}
