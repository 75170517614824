import React from 'react'
import { omit } from 'lodash'
import { string } from 'prop-types'

import { Container, Circle, Input, Text, Label, HiddenContent } from './styles'

function Radio(props) {
  const { label, children, negative, name } = props

  const inputProps = props.input ? props.input : omit(props, ['label', 'children'])
  return (
    <Label>
      <Input {...inputProps} type="radio" name={name} />
      <Container>
        <Circle negative={negative} />
        {label && <Text>{label}</Text>}
      </Container>

      {children && <HiddenContent>{children}</HiddenContent>}
    </Label>
  )
}

Radio.defaultProps = {
  label: null
}

Radio.propTypes = {
  label: string
}

export default Radio
