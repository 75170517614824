import styled from 'styled-components'
import { Link } from 'react-router-dom'

import { linkNavyBlue, darkGrey, white } from '../../../styles/settings/colors'

import { txt12OpenSans, txt12OpenSansBold, title16Galano } from '../../../styles/settings/types'

import { Avatar } from '../Header/styles'

import rem from '../../../styles/tools/rem'

export const MobileMenuContainer = styled.div`
  background-color: ${white};
  height: calc(100vh - ${rem('71px')});
  left: 0;
  opacity: 0;
  overflow: auto;
  padding-top: ${rem('10px')};
  position: absolute;
  top: ${rem('70px')};
  visibility: hidden;
  width: 100%;
  z-index: 2;

  ${props =>
    props.isOpen &&
    `
        opacity: 1;
        visibility: visible;
    `} ${Avatar} {
    margin-left: 0;
    margin-right: ${rem('24px')};
  }
`

export const UserInfoContainer = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`

export const MobileMenuUpperInfo = styled.div`
  align-items: center;
  display: flex;
  justify-content: flex-start;
  padding: ${rem('22px')} ${rem('28px')};
  width: 100%;
`

export const Hr = styled.hr`
  background: rgba(41, 38, 99, 0.1);
  border: 0;
  height: ${rem('1px')};
  margin: 0;
  padding: 0;
`

export const AnchorFull = styled(Link)`
  ${txt12OpenSansBold};

  color: ${darkGrey};
  display: block;
  padding: ${rem('22px')} ${rem('28px')};
  text-decoration: none;
`

export const Anchor = styled(Link)`
  ${txt12OpenSansBold};

  color: ${darkGrey};
  text-decoration: none;
`

export const PlanTitle = styled.p`
  ${title16Galano};
  color: ${linkNavyBlue};
  line-height: 1;
  margin: 0;
  text-transform: capitalize;
`

export const PlanDescription = styled.p`
  ${txt12OpenSans} color: ${darkGrey};
  margin: 0;
  text-transform: capitalize;
`

export const NavItem = styled.li`
  padding: 0;

  :not(:first-child) {
    margin-top: ${rem('16px')};
  }

  :last-child {
    margin-top: ${rem('40px')};
  }
`

export const PlanItem = styled.li`
  padding: 0;

  :not(:first-child) {
    margin-top: ${rem('24px')};
  }
`

export const List = styled.ul`
  list-style: none;
  margin: 0;
  padding: ${rem('24px')} ${rem('28px')};
`
