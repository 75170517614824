import gql from 'graphql-tag'

export const gqlConsultarFormasPagamento = gql`
  query ConsultarFormasPagamento {
    ConsultarFormasPagamento {
      formas {
        forma
        descricao
      }
    }
  }
`

export const gqlAutorizaEditarFormaPagamentoDataVencimento = gql`
  query AutorizaEditarFormaPagamentoDataVencimento($tipo: String, $idConta: String) {
    AutorizaEditarFormaPagamentoDataVencimento(tipo: $tipo, idConta: $idConta) {
      mensagem
      dataUltimaAlteracao
      dataProximaAlteracao
    }
  }
`

export const gqlConsultarUltimoAgendamentoPendente = gql`
  query ConsultarUltimoAgendamentoPendente($tipo: String, $idConta: String) {
    ConsultarUltimoAgendamentoPendente(tipo: $tipo, idConta: $idConta) {
      id
      tipoPagamento
      formaAlteracao
      tipoAlteracao
      conta
      ativo
      cpf
      dataAtualizacao
      dataCriacao
      estornado
      excluido
      nomePortador
      vencimentoFatura
      idBanco
      agencia
      digitoAgencia
      numeroContaCorrente
      bandeira
      dataValidade
      numeroCartao
    }
  }
`

export const gqlConsultarDataVencimento = gql`
  {
    ConsultarDataVencimento {
      dia
      proximasFaturas
    }
  }
`

export const gqlSimulaEdicaoFormaPagamento = gql`
  query SimulaEdicaoFormaPagamento($conta: String) {
    SimulaEdicaoFormaPagamento(conta: $conta) {
      mesAlteracao
      faturaAberta
    }
  }
`

export const gqlEditarDataVencimento = gql`
  mutation EditarDataVencimento($idConta: String!, $diaVenctoSolicitado: String!) {
    EditarDataVencimento(
      dataVencimento: { idConta: $idConta, diaVenctoSolicitado: $diaVenctoSolicitado }
    ) {
      statusCode
      message
    }
  }
`
export const gqlEditarFormaPagamento = gql`
  mutation EditarFormaPagamento(
    $tipoPagamento: String
    $conta: String
    $numeroMascarado: String
    $validade: String
    $bandeira: String
    $alias: String
    $cpf: String
    $nome: String
    $paymentToken: String
    $cvv: String
    $agencia: String
    $ativo: Int
    $numeroContaCorrente: String
    $idBanco: String
  ) {
    EditarFormaPagamento(
      formaPagamento: {
        tipoPagamento: $tipoPagamento
        conta: $conta
        numeroMascarado: $numeroMascarado
        validade: $validade
        bandeira: $bandeira
        alias: $alias
        cpf: $cpf
        nome: $nome
        paymentToken: $paymentToken
        cvv: $cvv
        agencia: $agencia
        ativo: $ativo
        numeroContaCorrente: $numeroContaCorrente
        idBanco: $idBanco
      }
    ) {
      tipoPagamento
      conta
      numeroMascarado
      validade
      bandeira
      alias
      cpf
      nome
      paymentToken
      cvv
      agencia
      ativo
      numeroContaCorrente
      idBanco
    }
  }
`

export const gqlEditarFormaPagamentoV2 = gql`
  mutation EditarFormaPagamentoV2(
    $tipoPagamento: String
    $conta: String
    $numeroMascarado: String
    $validade: String
    $bandeira: String
    $alias: String
    $cpf: String
    $nome: String
    $paymentToken: String
    $cvv: String
    $agencia: String
    $ativo: Int
    $numeroContaCorrente: String
    $idBanco: String
    $gateways: [Gateway]
  ) {
    EditarFormaPagamentoV2(
      formaPagamento: {
        tipoPagamento: $tipoPagamento
        conta: $conta
        numeroMascarado: $numeroMascarado
        validade: $validade
        bandeira: $bandeira
        alias: $alias
        cpf: $cpf
        nome: $nome
        paymentToken: $paymentToken
        cvv: $cvv
        agencia: $agencia
        ativo: $ativo
        numeroContaCorrente: $numeroContaCorrente
        idBanco: $idBanco
        gateways: $gateways
      }
    ) {
      dataAgendamento
      agendado
    }
  }
`
