import gql from 'graphql-tag'
import { transformObject } from '../common/helpers'

export const gqlUpdateBusiness = gql`
  mutation editarUsuarioJuridico(
    $nomeFantasia: String!
    $faturamentoMedioMensal: Float!
    $cnaePrincipal: String!
    $tipoUsuario: String!
  ) {
    editarUsuarioJuridico(
      usuarioJuridico: {
        nomeFantasia: $nomeFantasia
        faturamentoMedioMensal: $faturamentoMedioMensal
        cnaePrincipal: $cnaePrincipal
        tipo: $tipoUsuario
      }
    ) {
      nomeFantasia
      faturamentoMedioMensal
      cnaePrincipal
    }
  }
`

export const gqlBusiness = gql`
  {
    usuario {
      cnpj
      nomeFantasia
      razaoSocial
      dataConstituicao
      formaConstituicao {
        longDesc
      }
      cnaePrincipal
      inscricaoMunicipal
      faturamentoMedioMensal
      enderecos {
        bairro
        cep
        cidade
        codigo
        complemento
        correspondencia
        logradouro
        numero
        uf
      }
    }
    contatos {
      celular {
        ddd
        id
        numero
        tipoTelefone
      }
      email {
        id
        enderecoEletronico
        # additionDate - "20180521000000000" chega nesse formato pelo middlaew
        newsletter
      }
      # identificationNumber: null
      nome {
        nomeCompleto
        id
        # emiteNotaFiscal - false chega neste formato pelo middleware
      }
      # site: null
      # telefone: null
      telefone {
        id
        ddd
        numero
        tipoTelefone
        # ramal
      }
    }
    representantes {
      nome
      cpf
      nacionalidade
      tipoRelacao {
        id
        longDesc
        nemotecnico
        shortDesc
      }
      email
      celular
    }
  }
`

export const gqlEditarContatoAdministrador = gql`
  mutation editarContatoAdministrador(
    $nome: String!
    $email: String!
    $site: String
    $newsletter: Boolean!
    $telefoneDDD: Int
    $telefoneNumero: Int
    $telefoneTipo: Int
    $celularDDD: Int
    $celularNumero: Int
    $celularTipo: Int
  ) {
    editarContatoAdministrador(
      contatoAdministrador: {
        nome: { nomeCompleto: $nome }
        email: { enderecoEletronico: $email, newsletter: $newsletter }
        site: { enderecoEletronico: $site, newsletter: $newsletter }
        telefone: { ddd: $telefoneDDD, numero: $telefoneNumero, tipoTelefone: $telefoneTipo }
        celular: { ddd: $celularDDD, numero: $celularNumero, tipoTelefone: $celularTipo }
      }
    ) {
      email {
        enderecoEletronico
        newsletter
      }
      telefone {
        ddd
        numero
        tipoTelefone
      }
      celular {
        ddd
        numero
        tipoTelefone
      }
      site {
        enderecoEletronico
      }
    }
  }
`

// TODO No objeto de contato algumas informações não foram encontradas no graphql
// identificationNumber
// nome.emiteNotaFiscal
// site

const businessMap = {
  nomeFantasia: 'nomeFantasia',
  cnpj: 'cnpj',
  razaoSocial: 'razaoSocial',
  dataConstituicao: 'dataConstituicao',
  formaConstituicao: 'formaConstituicao.longDesc',
  cnaePrincipal: 'cnaePrincipal',
  inscricaoMunicipal: 'inscricaoMunicipal',
  faturamentoMedioMensal: 'faturamentoMedioMensal'
  // pap: 'pap',
}

export const transformBusiness = ({ data }) => {
  const { contatos: contato, usuario, representantes } = data
  const { enderecos } = usuario
  const profile = transformObject(usuario, businessMap)

  return {
    contato,
    enderecos,
    profile,
    representantes
  }
}
