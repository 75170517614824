import styled from 'styled-components'
import { darkBlue } from '../../../styles/settings/colors'
import { title24Galano, txt14OpenSans } from '../../../styles/settings/types'

import content from '../../../styles/_objects/content'
import rem from '../../../styles/tools/rem'

export const Heading = styled.h1`
  ${title24Galano} color: ${darkBlue};
`

export const Paragraph = styled.p`
  ${txt14OpenSans} color: ${darkBlue};
  width: ${rem('270px')};
`

export const Wrapper = styled.div`
  margin-top: ${rem('32px')};
`

export const Content = styled.section`
  ${content};
  margin: ${rem('42px')} auto;
`
