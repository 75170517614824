import React from 'react'
import { Field, reduxForm } from 'redux-form'
import { Flex, Box } from 'grid-styled'
import { utils } from 'alelo-logic'
import { validate } from './validateRepresentatives'
import { renderTextInput, renderTelephoneField, renderCpfInput } from '../../../utils/renderInputs'

const { labels, placeholders } = require('./data.json')

const EditRepresentativesForm = ({
  onCancel,
  onDelete,
  reset,
  handleSubmit,
  pristine,
  disableKeyFields
}) => (
  <form onSubmit={handleSubmit}>
    <Flex wrap width={[1, 7 / 12]}>
      <Box width={1} mb={3}>
        <Field
          component={renderTextInput}
          label={labels.name}
          name="name"
          placeholder={placeholders.name}
          type="text"
          isRequired
          disabled={disableKeyFields}
        />
      </Box>
      <Box width={[1, 1 / 2]} pr={[0, 2]} mb={3}>
        <Field
          component={renderCpfInput}
          label={labels.cpf}
          normalize={utils.cpfMask}
          placeholder={placeholders.cpf}
          name="document"
          type="text"
          disabled={disableKeyFields}
        />
      </Box>
      <Box width={[1, 1 / 2]} pl={[0, 2]} mb={3}>
        <Field
          component={renderTextInput}
          label={labels.nationality}
          name="nationality"
          placeholder={placeholders.nationality}
          type="text"
          isRequired
          disabled={disableKeyFields}
        />
      </Box>
      <Box width={1} mb={3}>
        <Field
          component={renderTextInput}
          label={labels.relationship}
          name="relationship"
          validate={utils.emptyFieldValidate}
          isRequired
          disabled={disableKeyFields}
        />
      </Box>
      <Box width={[1, 1 / 2]} pr={[0, 2]} mb={3}>
        <Field
          component={renderTextInput}
          label={labels.relationshipStart}
          placeholder={placeholders.date}
          name="relationshipStart"
          validate={utils.emptyFieldValidate}
          normalize={utils.dateMask}
          disabled={disableKeyFields}
        />
      </Box>
      <Box width={[1, 1 / 2]} pl={[0, 2]} mb={3}>
        <Field
          component={renderTextInput}
          label={labels.relationshipEnding}
          placeholder={placeholders.date}
          normalize={utils.dateMask}
          name="relationshipEnding"
          type="text"
          disabled={disableKeyFields}
        />
      </Box>
      <Box width={1} mb={3}>
        <Field
          component={renderTextInput}
          label={labels.email}
          name="email"
          placeholder={placeholders.email}
          type="email"
          disabled={disableKeyFields}
        />
      </Box>
      <Box width={[1, 1 / 2]} pr={[0, 2]} mb={3}>
        <Field
          component={renderTelephoneField}
          label={labels.telephone}
          name="telephone"
          type="text"
          disabled={disableKeyFields}
        />
      </Box>
    </Flex>
  </form>
)

export default reduxForm({
  form: 'editUserData',
  destroyOnUnmount: false,
  validate,
  forceUnregisterOnUnmount: true
})(EditRepresentativesForm)
