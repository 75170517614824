export const composeValidators = (...validators) => (value, values) =>
  validators.reduce((error, validator) => error || validator(value, values), undefined)

const VALIDTHRU_REGEX = /^(((0)[0-9])|((1)[0-2]))(\/)\d{4}$/ // MM/yyyy

export const validateExpireDate = dateToVerify => {
  if (VALIDTHRU_REGEX.test(dateToVerify)) {
    const currentDate = new Date()
    const dateArray = dateToVerify.replace(/_|__/g, '').split('/')

    const monthToVerify = dateArray[0] * 1
    const yearToVerify = dateArray[1] * 1

    const currentMonth = currentDate.getMonth() + 1
    const currentYear = currentDate.getFullYear()

    if (
      (monthToVerify >= currentMonth && yearToVerify >= currentYear) ||
      (monthToVerify < currentMonth && yearToVerify > currentYear)
    ) {
      return undefined
    } else {
      return 'Digite uma data válida'
    }
  }
  return 'Padrão obrigatório MM/YYYY'
}

export const validateAmexCVV = value => (value.length < 4 ? `No mínimo 4 dígitos` : undefined)

export const validateCVV = value => (value.length < 3 ? `No mínimo 3 dígitos` : undefined)

export const required = value => (value ? undefined : 'Campo Obrigatório')
