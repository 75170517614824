import React, { Component } from 'react'
import { connect } from 'react-redux'
import Helmet from 'react-helmet'
// Services and Actions
import { fetchGetVehicles, fetchLogout } from 'alelo-logic/actions'
import { setLoading } from '../../modules/PageLoader/actions'
// Components
import IdentifiersList from '../../modules/IdentifiersList'
import { Heading, Paragraph, Content, SearchBox, LoadingContainer, LoadingText } from './styles'
import SearchForm, { searchFilter } from 'app/modules/SearchForm'
import ManagementModal from 'app/modules/ManageInfoModal'
// Assets
import IconLoading from 'app/elements/Icon/ic-loading'

const {
  helmetTitle,
  identifiersTitle,
  identifiersDescription,
  noIdentifierTitle,
  noIdentifierDescription
} = require('./data.json')

class DashboardIdentifiers extends Component {
  constructor(props) {
    super(props)
    this.state = {
      itemsSelected: [],
      searchText: '',
      identifiersList: [],
      listGrowNumber: 20,
      isLoadingMoreItems: false,
      modalOpen: false,
      vehicle: {}
    }
  }

  async componentDidMount() {
    const { setLoading, match, history, fetchVehicles } = this.props
    setLoading(true)
    const { id } = match.params
    // const { vehicles } = this.props
    // if (vehicles.length === 0) {
    await fetchVehicles(history, id)
    // } else {
    // this._prepareVehiclesList()
    // }
  }

  componentDidUpdate = (prevProps, prevState) => {
    const { vehicles } = this.props
    if (prevProps.vehicles !== vehicles && vehicles.length > 0) {
      this._prepareVehiclesList()
    }
  }

  _prepareVehiclesList = () => {
    const { vehicles, setLoading } = this.props
    const { listGrowNumber } = this.state
    if (vehicles.length > listGrowNumber) {
      this.setState({ identifiersList: vehicles.slice(0, listGrowNumber) })
    } else {
      this.setState({ identifiersList: vehicles })
    }
    setLoading(false)
  }

  handleSearchChange = ({ target }) => {
    this.setState({ searchText: target.value })
    // window.dataLayer.push({
    //   event: 'event',
    //   eventAction: 'busca:filtrar-produtos',
    //   eventCategory: 'veloe:area-logado:meus-produtos',
    //   eventLabel: target.value
    // })
  }

  // Checkbox
  handleSelectItem = item => () => {
    const { itemsSelected } = this.state
    let newArray
    const hasItem =
      itemsSelected.findIndex(({ idIdentificador }) => item.idIdentificador === idIdentificador) !==
      -1
    if (hasItem) {
      newArray = itemsSelected.filter(
        ({ idIdentificador }) => item.idIdentificador !== idIdentificador
      )
      return this.setState({ itemsSelected: newArray })
    }
    newArray = [...itemsSelected, item]
    this.setState({ itemsSelected: newArray })
  }

  handleSelectAll = () => {
    const { itemsSelected, identifiersList } = this.state
    if (itemsSelected.length === identifiersList.length) {
      return this.setState({ itemsSelected: [] })
    }
    this.setState({ itemsSelected: identifiersList })
  }

  // Infinity List
  loadMoreItems = () => {
    const { identifiersList, listGrowNumber } = this.state
    const { vehicles } = this.props
    if (vehicles.length !== identifiersList.length) {
      this.setState({ isLoadingMoreItems: true })
      setTimeout(
        () =>
          this.setState({
            isLoadingMoreItems: false,
            identifiersList: vehicles.slice(0, identifiersList.length + listGrowNumber)
          }),
        400
      )
    }
  }

  // Modal
  handleModalCreateInfo = vehicle => {
    this.setState({ modalOpen: true, vehicle })
  }

  handleCloseModal = async () => {
    this.setState({ modalOpen: false, vehicle: {} })
    const { match, history, fetchVehicles } = this.props
    const { id } = match.params
    await fetchVehicles(history, id)
  }

  render() {
    const {
      searchText,
      itemsSelected,
      identifiersList,
      isLoadingMoreItems,
      modalOpen,
      vehicle
    } = this.state
    return (
      <section>
        <Helmet title={helmetTitle} />
        {!identifiersList || identifiersList.length === 0 ? (
          <Content>
            <Heading>{noIdentifierTitle}</Heading>
            <Paragraph>{noIdentifierDescription}</Paragraph>
          </Content>
        ) : (
          <Content>
            <Heading>{identifiersTitle}</Heading>
            <Paragraph>{identifiersDescription}</Paragraph>
            <SearchBox>
              <SearchForm
                value={searchText}
                onChange={this.handleSearchChange}
                placeholder="Busque pela placa, produto ou Informação gerencial"
              />
            </SearchBox>
            <IdentifiersList
              handleCreateInf={this.handleModalCreateInfo}
              checkboxValues={itemsSelected}
              handleLoadMoreItems={this.loadMoreItems}
              handleSelectItem={this.handleSelectItem}
              handleSelectAll={this.handleSelectAll}
              identifiers={searchFilter(searchText, identifiersList)}
            />
            {isLoadingMoreItems && (
              <LoadingContainer>
                <IconLoading />
                <LoadingText>Carregando</LoadingText>
              </LoadingContainer>
            )}
            {modalOpen && (
              <ManagementModal
                vehicle={vehicle}
                isOpen={modalOpen}
                modalStart={'include'}
                toggleModal={this.handleCloseModal}
              />
            )}
          </Content>
        )}
      </section>
    )
  }
}

const mapStateToProps = ({ reducer }) => ({
  vehicles: reducer.dashboardVehicles,
  accountPlans: reducer.accountPlans
})

const mapDispatchToProps = dispatch => ({
  fetchVehicles: (history, accountId) =>
    dispatch(fetchGetVehicles(accountId)).then(resp => {
      if (resp && (resp.status === 401 || resp.status === 403)) {
        dispatch(fetchLogout())
        history.push('/conta/login')
      }
      dispatch(setLoading(false))
    }),
  setLoading: isLoading => {
    dispatch(setLoading(isLoading))
  }
})

export default connect(mapStateToProps, mapDispatchToProps)(DashboardIdentifiers)
