import React from 'react'
import { utils } from 'alelo-logic'

import { yellowGreen, eletricViolet } from '../../../../../../styles/settings/colors'
import ConsumptionChartBar from '../../../../../elements/ConsumptionChartBar'
import ConsumptionChartIndicator from '../../../../../elements/ConsumptionChartIndicator'
import { TextWithoutMargins, IndicatorList, Usage, IndicatorItem } from './styles'

const { totalLabel, indicatorLabel, chartData } = require('./data.json')

const { currencyParser } = utils
const colors = [yellowGreen, eletricViolet]

const toPercentage = (value, total) => {
  const decimal = value / total
  return decimal * 100
}

const calculateDataPercentage = (maxValue, dataArray = []) => {
  if (maxValue) {
    return dataArray.map(data => toPercentage(data, maxValue))
  }

  const total = dataArray.reduce((data, nextData) => data + nextData, 0)

  return dataArray.map(data => toPercentage(data, total))
}

const ConsumptionChart = ({ consumption }) => {
  const consumptionTypes = Object.keys(chartData)
  const consumptionValues = consumptionTypes.map(consumptionType => chartData[consumptionType])
  const dataPercentage =
    calculateDataPercentage(consumption && consumption.limit, consumptionValues) || []
  const totalPercentage = dataPercentage.reduce((percentage, nextData) => percentage + nextData, 0)

  return (
    <section>
      <ConsumptionChartBar colors={colors} values={dataPercentage} />

      {consumption && (
        <Usage>
          <span>{totalPercentage}%</span>

          <span>De {currencyParser(consumption.limit, false, 'R$ ')}</span>
        </Usage>
      )}
      <IndicatorList>
        {consumptionTypes.map((indicator, index) => (
          <IndicatorItem key={indicator}>
            <ConsumptionChartIndicator
              name={indicatorLabel[indicator]}
              color={colors[index]}
              value={currencyParser(chartData[indicator])}
            />
          </IndicatorItem>
        ))}
        <IndicatorItem>
          <TextWithoutMargins>{totalLabel}</TextWithoutMargins>
        </IndicatorItem>
      </IndicatorList>
    </section>
  )
}

export default ConsumptionChart
