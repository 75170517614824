import gql from 'graphql-tag'

export const gqlTermo = gql`
  {
    buscarArquivos {
      urlContratoVeloeCliente
      urlManualUsuario
      urlPoliticaDePrivacidade
    }
  }
`
