import React from 'react'
import { string, number, oneOfType } from 'prop-types'

import { Container, Name, Value, BarChartContainer } from './styles'
import ConsumptionChartBarPos from '../../elements/ConsumptionChartBarPos'
import { posYellow, posGrey } from 'styles/settings/colors'

const colors = [posYellow, posGrey]

const Icon = ({ icon, name, ...otherProps }) => (
  <img src={require(`../../../static/${icon}.png`)} alt={name} {...otherProps} />
)

const ConsumptionChartIndicatorPos = ({
  indicator = '',
  name = '',
  value = 0,
  color = '',
  dataPercentage
}) => (
  <Container>
    <Icon width="24px" height="24px" icon={indicator} alt={name} viewBox="0 0 11 8" />
    <Name color={color}>{name}</Name>
    <BarChartContainer>
      <ConsumptionChartBarPos height="8px" colors={colors} values={dataPercentage} />
    </BarChartContainer>

    <Value>
      <span>R$</span>
      {value}
    </Value>
  </Container>
)

ConsumptionChartIndicatorPos.propTypes = {
  name: string,
  value: oneOfType([number, string]),
  color: string,
  indicator: string
}

export default ConsumptionChartIndicatorPos
