/* eslint-disable */
import moment from 'moment/moment'
import { EMPTY_FIELD, VALIDATION_DBT_MSG_INVALID, FORMAT_DATE_INVALID } from './constants'

export default function dateBeforeTodayValidate(date) {
  const format = 'DD/MM/YYYY'
  const valid = moment(date, format, true).isValid()
  const isBeforeToday = moment(date, format, true).isBefore(moment().startOf('day'))

  return !date
    ? EMPTY_FIELD
    : !valid
    ? FORMAT_DATE_INVALID
    : !isBeforeToday
    ? VALIDATION_DBT_MSG_INVALID
    : undefined
}
