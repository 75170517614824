import React from 'react'
import { Box, Flex } from 'grid-styled'
import { utils } from 'alelo-logic'

import { Content, Span, Title, VehicleBar } from './styles'

const { currency, total } = require('./data.json')

const InvoiceDetailHeaderAmount = ({ amount }) => {
  return (
    <VehicleBar>
      <Content>
        <Flex align="center" justify="space-between">
          <Box width={[1, 2 / 12]} />
          <Box pl={[0, 20]} width={[1, 4 / 12]} />
          <Box pl={[0, 20]} width={[1, 4 / 12]} />
          <Box pl={[0, 20]} width={[1, 2 / 12]}>
            <Span>
              {total} ({currency})
            </Span>
            <Title>{utils.currencyParser(amount, false, currency)}</Title>
          </Box>
        </Flex>
      </Content>
    </VehicleBar>
  )
}

export default InvoiceDetailHeaderAmount
