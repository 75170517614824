import styled from 'styled-components'
import { title36GalanoBold } from '../../../styles/settings/types'

export const Main = styled.main`
  display: block;
  text-align: center;

  h1 {
    ${title36GalanoBold} margin-bottom: 30px;
  }

  ul,
  li {
    margin: 0 0 15px;
    padding: 0;
    list-style: none;
  }
`
