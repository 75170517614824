import styled, { css } from 'styled-components'
import imgLoading from '../../../static/loading.gif'

import {
  grey,
  darkBlue,
  navyBlue,
  lightBlue,
  mediumGrey,
  offWhite,
  skyBlue,
  white,
  linkNavyBlue
} from 'styles/settings/colors'

import rem from 'styles/tools/rem'
import media from 'styles/tools/media'

const transitionTime = 0.4

const floatingSlices = {
  white: `background-color: ${darkBlue};`,
  transparent: `background-color: ${white};`,
  darkBlue: `background-color: ${white};`,
  whiteNavyBlue: `background-color: ${darkBlue};`
}

const buttonStyles = {
  white: css`
    background-color: ${white};
    box-shadow: 0 0 0 ${rem('2px')} ${mediumGrey} inset;
    color: ${mediumGrey};

    ${media.large`
            :hover {
                border-color: ${darkBlue};
                color: ${white};
            }
        `};
  `,
  transparent: css`
    background-color: transparent;
    box-shadow: 0 0 0 ${rem('2px')} ${white} inset;
    color: ${white};

    ${media.large`
            :hover {
                color: ${darkBlue};
            }
        `};
  `,
  darkBlue: css`
    background-color: ${darkBlue};
    box-shadow: 0 ${rem('2px')} ${rem('8px')} 0 rgba(0, 0, 0, 0.29);
    color: ${white};

    ${media.large`
            :hover {
                color: ${darkBlue};
            }
        `};
  `,
  skyBlue: css`
    background-color: ${skyBlue};
    box-shadow: 0 ${rem('2px')} ${rem('8px')} 0 rgba(0, 0, 0, 0.29);
    color: ${white};

    ${media.large`
            :hover {
                color: ${skyBlue};
            }
        `};
  `,
  whiteNavyBlue: css`
    background-color: ${white};
    box-shadow: 0 0 0 ${rem('2px')} ${linkNavyBlue} inset;
    color: ${linkNavyBlue};

    ${media.large`
            :hover {
                border-color: ${linkNavyBlue};
                color: ${white};
            }
        `};
  `
}

const disabled = props =>
  props.disabled &&
  `
        background-color: ${offWhite};
        box-shadow: none;
        color: ${grey};
        cursor: default;

        :hover,
        :visited,
        :active,
        :focus {
            transform: none;
            background-color: ${offWhite};
            box-shadow: none;
            color: ${grey};
            cursor: default;
        }

        :before {
            display: none;
        }
    `

const dispatchColor = props => floatingSlices[props.buttonType]
const buttonModifier = props => buttonStyles[props.buttonType]

export const Wrapper = styled.div`
  background: transparent;
  background-color: ${props => (props.secondary ? linkNavyBlue : lightBlue)};
  border-radius: ${rem('6px')};
  color: ${white};
  display: inline-block;
  min-width: auto;
  overflow: hidden;
  position: relative;
  text-align: center;
  transform: translateY(0);
  user-select: none;
  width: 100%;

  ${media.large`
      transition:
        ${transitionTime / 4}s transform linear,
        ${transitionTime}s color;
      min-width: ${props => props.width || rem('160px')};
      width: auto;

      :active {
        transition: none;
        transform: translateY(${rem('2px')});
      }

      :before {
        background-color: ${navyBlue};
        ${dispatchColor};
        content: '';
        display: block;
        height: 100%;
        position: absolute;
        right: 0px;
        top: 0px;
        transition-delay: 0;
        transition: all 0.4s cubic-bezier(0.42, 0, 0.58, 1);
        width: 0px;
        z-index: 0;
      }

      :hover {
        transition:
          ${transitionTime / 4}s transform linear,
          ${transitionTime}s .2s color;

        :before {
          left: 0%;
          right: auto;
          transition-delay: .2s;
          width: 100%;
        }
      }
    `};

  ${media.small`
    min-width: ${props => props.width || rem('100px')};
  `}

  ${buttonModifier};
  ${disabled};
`

export const ButtonField = styled.a`
  cursor: pointer;
  display: block;
  font-family: OpenSans;
  font-size: ${props => (props.fontSize ? rem(props.fontSize) : rem('16px'))};
  font-weight: 600;
  height: 100%;
  line-height: ${props => (props.lineHeight ? rem(props.lineHeight) : rem('60px'))};
  letter-spacing: ${rem('-0.5px')};
  position: relative;
  text-align: center;
  width: 100%;
  z-index: 1;

  ${media.large`
    line-height: ${props => (props.lineHeight ? rem(props.lineHeight) : rem('48px'))};
    padding: ${props => (props.padding ? props.padding : `0 ${rem('30px')}`)};
    min-width: ${props => props.width || rem('160px')};
  `};

  ${({ disabled, loading }) =>
    disabled &&
    css`
      cursor: not-allowed;
      ${loading && `background-image: url(${imgLoading})`};
      background-repeat: no-repeat;
      background-position: 20px center;
      background-size: 20px;
    `};
`

export const FormButtonField = ButtonField.withComponent('button').extend`
  border: none;
`
export const BtnIcon = styled.img`
  padding-right: 10px;
`
