import React from 'react'
import Helmet from 'react-helmet'
import { actions } from 'alelo-logic'
import { connect } from 'react-redux'
import EditAdminContactForm from '../../modules/EditAdminContactForm'
import { showModal } from '../../modules/ModalRoot/actions'
import { CONFIRM_UPDATE } from '../../../constants/ModalTypes'
import { startSaving, stopSaving } from '../../modules/form-state-actions'
import contactFactory from './factory'

import { Content, Heading, Paragraph, Wrapper } from '../EditUserData/styles'

import image from '../../../static/confirmation.png'

const {
  helmetTitle,
  title,
  subtitle,
  modalTitle,
  modaTitleError,
  modaTextError
} = require('./data.json')

const { fetchUpdateBusinessContact } = actions

const EditAdminContact = props => {
  const { contacts, handleSave } = props

  const initialValues = contactFactory.toForm(contacts)

  return (
    <section>
      <Helmet title={helmetTitle} />
      <Content>
        <Heading>{title}</Heading>
        <Paragraph>{subtitle}</Paragraph>
      </Content>
      <Wrapper>
        <EditAdminContactForm
          initialValues={initialValues}
          onSubmit={values => handleSave(contactFactory.toAPI(values))}
        />
      </Wrapper>
    </section>
  )
}

const mapStateToProps = state => ({
  contacts: state.reducer.user.contato,
  saving: state.reducer.formState.saving
})

const mapDispatchToProps = dispatch => ({
  initFormState: () => dispatch(stopSaving()),
  handleSave: contacts => {
    dispatch(startSaving())
    dispatch(fetchUpdateBusinessContact(contacts))
      .then(() => dispatch(showModal(CONFIRM_UPDATE, { modalTitle, image })))
      .then(() => dispatch(stopSaving()))
      .catch(e => {
        console.log('Erro na promise erro ao salvar', e)
        dispatch(
          showModal(CONFIRM_UPDATE, {
            modalTitle: modaTitleError,
            modalDescription: modaTextError,
            image
          })
        )
        dispatch(stopSaving())
      })
  }
})

export default connect(mapStateToProps, mapDispatchToProps)(EditAdminContact)
