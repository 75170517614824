import styled from 'styled-components'

import media from 'styles/tools/media'
import rem from 'styles/tools/rem'

import { blizzardBlue, skyBlue, white } from 'styles/settings/colors'
import {
  title24GalanoBold,
  title32GalanoBold,
  title40GalanoBold,
  txt12OpenSans,
  txt14OpenSans
} from 'styles/settings/types'

export const Container = styled.main`
  background: ${skyBlue};
  height: 100vh;
  margin: 0;
  overflow: auto;
`

export const Title = styled.h1`
  ${title24GalanoBold} color: ${white};
  letter-spacing: ${rem('0.4px')};
  font-weight: bold;
  margin: ${rem('46px')} 0 ${rem('16px')} 0;
  max-width: ${rem('231px')};

  ${media.large`
        ${title40GalanoBold}

        letter-spacing: ${rem('0.4px')};
        margin: ${rem('78px')} 0 ${rem('16px')} 0;
        max-width: ${rem('364px')};
    `};
`

export const Paragraph = styled.p`
  ${txt14OpenSans} color: ${white};
  margin: 0;
  max-width: ${rem('431px')};
`

export const Protocol = styled.p`
  ${title32GalanoBold} color: ${white};
  letter-spacing: 0.3px;
  line-height: 1;
  margin: 0;
`

export const Date = styled.time`
  ${txt12OpenSans} color: ${white};
  letter-spacing: 1px;
`

export const Illustration = styled.div`
  position: absolute;
  right: 0;
  top: ${rem('220px')};
`

export const Separator = styled.hr`
  border: ${rem('1px')} solid ${blizzardBlue};
  margin: ${rem('29px')} 0 ${rem('34px')} 0;
  width: 100%;

  ${media.large`
        border: ${rem('1px')} solid ${blizzardBlue};
        display: block;
        margin-right: ${rem('600px')};
        margin: ${rem('29px')} 0 ${rem('32px')} 0;
        width: 30%;
    `};
`
