/* eslint-disable */

import React from 'react'
import { string } from 'prop-types'

import ChooseFilterMonthOption from '../../elements/ChooseFilterMonthOption'

import { FilterTypeContainer, FilterTypes, Container, Content, ListItem, Title } from './styles'

const { title, months } = require('./data.json')

function ChooseFilterMonth(props) {

  return (
    <Container>
      <Content>
        <Title>{title}</Title>
        <FilterTypeContainer>
          <FilterTypes>
            {months.map((month, i) => (
              <ListItem key={month}>
                <ChooseFilterMonthOption
                  key={month}
                  value={i + 1}
                  onChange={props.onChange}
                  name="month"
                  filterMonth={month}
                />
              </ListItem>
            ))}
          </FilterTypes>
        </FilterTypeContainer>
      </Content>
    </Container>
  )
}

ChooseFilterMonth.defaultProps = {
  label: null
}

ChooseFilterMonth.propTypes = {
  label: string
}

export default ChooseFilterMonth
