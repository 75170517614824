/* eslint-disable */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { fetchCreateRecharge } from 'alelo-logic/actions'
import { converText } from '../../../utils/textSlug'
import { Flex, Box } from 'grid-styled'
import PaymentInfoCard from '../../modules/PaymentInfoCard'
import RechargeBreadcrumbs from '../../modules/RechargeBreadcrumbs'
import rem from '../../../styles/tools/rem'

import { showModal } from '../../modules/ModalRoot/actions'
import image from '../../../static/contestacao.png'
import { CONFIRM_UPDATE } from '../../../constants/ModalTypes'

import { Text, RechargeValue, Cash, CashPrefix, CashValue } from './styles'

const {
  rechargeValueLabel,
  currency,
  disclaimer,
  titleModalError,
  descriptionModalError
} = require('./data.json')

class RechargePayment extends Component {
  state = {
    loading: false
  }

  onConfirm = () => {
    this.setState({ loading: true })
    const {
      recharge: { currentRechargeValue, paymentInfo },
      history,
      match,
      dispatch,
      dashboardResume,
      actualPlan = {}
    } = this.props

    const paymentType = actualPlan.isHubClient ? 'digitalAccount' :
    actualPlan.paymentType === 'CARTAO_DEBITO' ? 'debitCard':
    'credit'
    
    dispatch(
      fetchCreateRecharge({
        accountPlanId: match.params.id,
        value: currentRechargeValue,
        paymentMethod: paymentType,
        type: 'manual'
      })
    )
      .then(() => {
        window.dataLayer.push({
          event: 'purchase',
          eventCategory: 'veloe:area-logado:recarga-manual',
          eventAction: 'sucesso:recarga',
          eventLabel: 'purchase',
          dimension9: 'recarga',
          ecommerce: {
            purchase: {
              actionField: {
                id: paymentInfo.id,
                revenue: currentRechargeValue.toFixed(2)
              },
              products: [
                {
                  name: converText(`plano-${actualPlan.plan.category}`),
                  id: converText(`plano-${actualPlan.plan.category}-${currentRechargeValue}`),
                  price: currentRechargeValue.toFixed(2),
                  category: dashboardResume.type,
                  quantity: '1'
                }
              ]
            }
          }
        })
        this.setState({ loading: false })
        history.push(`/minha-veloe/${match.params.id}/recarga/confirmacao`)
      })
      .catch(e => {
        this.setState({ loading: false })
        console.error(e) // eslint-disable-line
        dispatch(
          showModal(CONFIRM_UPDATE, {
            modalTitle: titleModalError,
            modalDescription: descriptionModalError,
            image
          })
        )
      })
  }

  onEdit = () => {
    const { history } = this.props
    history.push(`/minha-veloe/${this.props.match.params.id}/editar-dados/cartao?path=recharge`)
  }

  render() {
    const {
      recharge: { currentRechargeValue },
      account, 
      actualPlan
    } = this.props
    const { loading } = this.state
    
    return (
      <section>
        <RechargeBreadcrumbs active={2} />
        <Flex
          direction={['column', 'row']}
          justify={account && account.accountType === 'pj' ? 'space-between' : ''}
        >
          <Box mr={[0, `${(1 / 12) * 100}%`]} mb={rem('20px')} width={[1, 4 / 12]}>
            <RechargeValue>
              <Text>{rechargeValueLabel}</Text>
              <Cash>
                <CashPrefix>{currency}</CashPrefix>
                <CashValue id="lblCurrentRechargeValue">{currentRechargeValue}</CashValue>
              </Cash>
            </RechargeValue>
            <Text id="lblDisclaimer">{disclaimer}</Text>
          </Box>
          <Box>
            <PaymentInfoCard
              accountPlanId={this.props.match.params.id}
              onConfirm={this.onConfirm}
              onEdit={this.onEdit}
              loading={loading}
              paymentType={actualPlan.paymentType}
            />
          </Box>
        </Flex>
      </section>
    )
  }
}

const mapStateToProps = ({ reducer }) => ({
  recharge: reducer.recharge,
  dashboardResume: reducer.dashboardResume,
  account: reducer.account,
  actualPlan:
    reducer.accountPlans.plans.find(plan => plan.accountId === reducer.accountPlans.activePlanId) ||
    {}
})

export default connect(mapStateToProps)(RechargePayment)
