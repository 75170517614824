import em from '../tools/em'
import rem from '../tools/rem'

export const title144GalanoBold = `
    font-family: Galano;
    font-size: ${rem('144px')};
    font-weight: 600;
    line-height: 1;
`

export const title60GalanoBold = `
    font-family: Galano;
    font-size: ${rem('60px')};
    font-weight: 600;
`

export const title44GalanoBold = `
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: Galano;
    font-size: ${rem('44px')};
    font-weight: 600;
    line-height: 1.39;
`

export const title40GalanoBold = `
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-family: Galano;
    font-size: ${rem('40px')};
    font-weight: 600;
    letter-spacing: ${em('0.4px')};
    line-height: 1;
`

export const title36GalanoBold = `
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: Galano;
    font-size: ${rem('36px')};
    font-weight: 600;
    line-height: 1.39;
`

export const title32GalanoBold = `
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: Galano;
    font-size: ${rem('36px')};
    font-weight: bold;
    letter-spacing: ${em('0.3px')};
`

export const title30GalanoBold = `
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: Galano;
    font-size: ${rem('30px')};
    font-weight: bold;
    letter-spacing: ${em('4px')};
`

export const title28GalanoBold = `
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: Galano;
    font-size: ${rem('28px')};
    font-weight: bold;
    letter-spacing: 0.3px;
`

export const title30Galano = `
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: Galano;
    font-size: ${rem('30px')};
`

export const title26GalanoBold = `
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
	line-height: 1.17;
    font-family: Galano;
    font-weight: bold;
    font-size: ${rem('26px')};
`

export const title24Galano = `
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: Galano;
    font-size: ${rem('24px')};
`

export const title24GalanoBold = `
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
	line-height: 1.17;
    font-family: Galano;
    font-weight: bold;
    font-size: ${rem('24px')};
`

export const title20GalanoBold = `
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: Galano;
    font-size: ${rem('20px')};
    letter-spacing: ${em('2.7px')};
    font-weight: bold;
    line-height: 1.17;
`

export const title20Galano = `
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: Galano;
    font-size: ${rem('20px')};
    line-height: 1.17;
`

export const title18Galano = `
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: Galano;
    font-size: ${rem('18px')};
    font-weight: bold;
    line-height: 1;
`

export const title16Galano = `
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: Galano;
    font-size: ${rem('16px')};
    font-weight: bold;
`

export const title14Galano = `
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: Galano;
    font-size: ${rem('14px')};
    font-weight: bold;
`

export const txt24OpenSansBold = `
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: OpenSans;
    font-size: ${rem('24px')};
    font-weight: bold;
`

export const txt24OpenSansNormal = `
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: OpenSans;
    font-size: ${rem('24px')};
    font-weight: 400;
`

export const txt20OpenSans = `
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: OpenSans;
    font-size: ${rem('20px')};
    font-weight: normal;
    line-height: 1;
`

export const txt40OpenSans = `
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: OpenSans;
    font-size: ${rem('40px')};
    font-weight: normal;
    line-height: 1;
`

export const txt20OpenSansLight = `
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: OpenSans;
    font-size: ${rem('20px')};
    font-weight: 200;
`

export const txt20OpenSansBold = `
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: OpenSans;
    font-size: ${rem('20px')};
    font-weight: 600;
    line-height: 1;
`

export const txt18OpenSansLight = `
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
	font-size: 18px;
	line-height: 1.33;
    font-family: OpenSans;
    font-weight: 200;
    letter-spacing: ${em('-0.5px')};
`

export const txt18OpenSans = `
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: OpenSans;
    font-size: ${rem('18px')};
    font-weight: normal;
`

export const txt16OpenSans = `
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: OpenSans;
    font-size: ${rem('16px')};
    font-weight: normal;
`

export const txt16OpenSansLight = `
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: OpenSans;
    font-size: ${rem('16px')};
    font-weight: 200;
    letter-spacing: -0.5px;
`
export const txt16OpenSansBold = `
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: OpenSans;
    font-size: ${rem('16px')};
    font-weight: bold;
`

export const txt14OpenSans = `
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: OpenSans;
    font-size: ${rem('14px')};
    font-weight: normal;
`

export const txt14OpenSansBold = `
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: OpenSans;
    font-size: ${rem('14px')};
    font-weight: bold;
`

export const txt12OpenSans = `
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: OpenSans;
    font-size: ${rem('12px')};
    font-weight: normal;
    line-height: 1.83;
`

export const txt12OpenSansBold = `
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: OpenSans;
    font-size: ${rem('12px')};
    font-weight: bold;
    line-height: 1.83;
`

export const txt12OpenSansLight = `
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: OpenSans;
    font-size: ${rem('12px')};
    font-weight: 200;
    letter-spacing: ${em('1px')};
`

export const txt10OpenSans = `
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: OpenSans;
    font-size: 10px;
    letter-spacing: 0.8px;
    line-height: 1;
`
