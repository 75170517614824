import React from 'react'
import { connect } from 'react-redux'
import { fetchRecover } from 'alelo-logic/actions'
import { Flex } from 'grid-styled'
import Hide from '../../../styles/utilities/Hide'
import { Icon } from '../../elements/Icon'
import { showModal } from '../../modules/ModalRoot/actions'
import { CONFIRM_UPDATE } from '../../../constants/ModalTypes'

import ForgotPasswordForm from '../../modules/ForgotPasswordForm'
import login from '../../../static/login.jpg'
import veloeLogo from '../../../static/logoVeloe.png'

import { Aside, Container, Content, Description, Header, Text, Title, Veloe } from './style'

import image from '../../../static/alterarSenha.png'

const {
  back,
  description,
  veloeLogoAlt,
  modalDescription,
  modalTitle,
  title
} = require('./data.json')

const ForgotPassword = ({ sendEmail }) => (
  <Flex>
    <Aside>
      <img src={login} alt="Avatar" />
    </Aside>
    <Content>
      <Header>
        <Text>
          <Icon height={16} icon="back-arrow" viewBox="0 0 16 13" display="flex" />
          {back}
        </Text>
      </Header>
      <Hide at="large">
        <Veloe>
          <img src={veloeLogo} alt={veloeLogoAlt} />
        </Veloe>
      </Hide>
      <Title>{title}</Title>
      <Description>{description}</Description>
      <Container>
        <ForgotPasswordForm onSubmit={sendEmail} />
      </Container>
    </Content>
  </Flex>
)

const mapStateToProps = ({ reducer }) => ({
  destinationEmail: reducer.recover
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  sendEmail: values => {
    dispatch(fetchRecover(values.login)).then(
      data => {
        if (data.success) {
          dispatch(
            showModal(CONFIRM_UPDATE, {
              modalTitle: modalTitle,
              modalDescription: `${modalDescription} ${data.email}`,
              onConfirm: () => ownProps.history.push('/conta/login'),
              image
            })
          )
        } else {
          dispatch(
            showModal(CONFIRM_UPDATE, {
              modalTitle: data.message,
              image
            })
          )
        }
      },
      data => {
        data.json().then(err => {
          dispatch(
            showModal(CONFIRM_UPDATE, {
              modalTitle: err.message,
              image
            })
          )
        })
      }
    )
  }
})

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword)
