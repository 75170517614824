/* eslint-disable */
import React, { useEffect, useRef } from 'react'
import { Overlay } from './styles'

function ClickOut({ callback, children }) {
  const elementRef = useRef()

  const onSelfClick = e => {
    e.clickOutTarget = elementRef
  }

  const onAnyClick = e => {
    if (e.clickOutTarget !== elementRef) {
      callback(e)
    }
  }

  useEffect(() => {
    elementRef.current.addEventListener('click', onSelfClick)
    document.addEventListener('click', onAnyClick)
    return () => {
      if (elementRef.current === null) return
      elementRef.current.removeEventListener('click', onSelfClick)
      document.removeEventListener('click', onAnyClick)
    }
  }, [])

  return (
    <div
      ref={elementRef}
    >
      {children}
    </div>
  )
}

export default ClickOut

export const OverlayClick = ({ isOpen, onClick }) => <Overlay isOpen={isOpen} onClick={onClick} />
