import styled from 'styled-components'
import { offWhite, linkNavyBlue, grey } from 'styles/settings/colors'

export const Wrapper = styled.section`
  margin-top: 30px;
  display: flex;
  overflow: hidden;

  .wrap {
    white-space: normal;
    background-color: ${offWhite};
  }

  .active {
    cursor: unset !important;
  }
`

export const Navigator = styled.div`
  position: relative;
  bottom: 56px;
  left: 24px;
`

export const NavigatorButton = styled.button`
  background-color: ${grey};
  height: 6px;
  width: 18px;
  border: none;
  border-radius: 6px;
  margin: 16px 8px;
  cursor: pointer;
  outline: none;

  ${props =>
    props.active &&
    `
      background-color: ${linkNavyBlue};
      width: 28px
  `};
`
