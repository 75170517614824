import styled from 'styled-components'
import media from 'styles/tools/media'
import rem from 'styles/tools/rem'

export const ButtomIosAppStyle = styled.img`
  width: 150px;
  height: 45.02px;

  ${media.large`
    width: ${rem('150px')};
 `};
  ${media.small`
  width: ${rem('55px')};
  height: auto;
 `}
`
