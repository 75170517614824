import styled from 'styled-components'
import { darkBlue, darkGrey } from '../../../styles/settings/colors'
import {
  title40GalanoBold,
  title24GalanoBold,
  txt16OpenSans,
  txt12OpenSans
} from '../../../styles/settings/types'

import content from '../../../styles/_objects/content'
import rem from '../../../styles/tools/rem'
import media from '../../../styles/tools/media'

export const Label = styled.p`
  ${txt12OpenSans};
  letter-spacing: 0.1em;
  color: ${darkGrey};
  line-height: 1;
  margin: 0 0 ${rem('16px')};
  text-transform: uppercase;
`

export const Heading = styled.h1`
  ${title24GalanoBold} color: ${darkBlue};
  line-height: 1;
  margin: 0 0 ${rem('24px')};

  ${media.large`
        ${title40GalanoBold};

        margin: 0 0 ${rem('42px')};
    `};
`
export const Subtitle = styled.p`
  ${txt16OpenSans};

  color: ${darkBlue};
  font-weight: bold;
  line-height: 1.5;
  margin: 0 0 ${rem('24px')};
  max-width: ${rem('210px')};

  ${media.large`
        max-width: none;
    `};
`

export const Wrapper = styled.div`
  margin-bottom: ${rem('32px')};
`

export const Content = styled.section`
  ${content};

  margin: ${rem('42px')} auto;
`
