import React from 'react'
import { utils } from 'alelo-logic'
import { renderTextInput, renderSelectField } from 'utils/renderInputs'
import { Field } from 'redux-form'

const { labels } = require('./data.json')

const LocationField = ({ locations }) => (
  <Field
    component={renderSelectField}
    label={labels.location}
    name="location"
    options={locations}
    isRequired
  />
)

const PlateField = () => (
  <Field
    component={renderTextInput}
    label={labels.plate}
    name="plate"
    type="text"
    validate={utils.plateValidate}
    normalize={utils.plateMask}
    isRequired
  />
)

const PeriodInitDateField = ({ dateFormat }) => (
  <Field
    component={renderTextInput}
    label={labels.period}
    name="periodInitDate"
    type="text"
    validate={utils.dateValidateWithFormat(dateFormat)}
    normalize={utils.dateMask}
    isRequired
  />
)

const PeriodEndDateField = ({ dateFormat }) => (
  <Field
    component={renderTextInput}
    label={labels.periodEnd}
    name="periodEndDate"
    type="text"
    validate={utils.dateValidateWithFormat(dateFormat)}
    normalize={utils.dateMask}
    isRequired
  />
)

export { LocationField, PlateField, PeriodInitDateField, PeriodEndDateField }
