import styled, { css } from 'styled-components'
import rem from 'styles/tools/rem'
import media from 'styles/tools/media'

import { txt12OpenSansBold, txt16OpenSans } from 'styles/settings/types'

import {
  white,
  paleGrayTwo,
  tealish,
  darkSlateBlue,
  red,
  darkGrey,
  paleGray
} from 'styles/settings/colors'

export const LineTextContainer = styled.div`
  display: flex;
  align-items: center;
  min-height: 100%;
  position: relative;
`

export const LineText = styled.div`
  ${txt16OpenSans};
  font-weight: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: ${darkSlateBlue};
  display: block;
  ${({ capitalize }) =>
    capitalize &&
    css`
      text-transform: capitalize;
    `};
  ${({ uppercase }) =>
    uppercase &&
    css`
      text-transform: uppercase;
      font-size: 12px;
      font-weight: bold;
      letter-spacing: 1.6px;
    `};
  ${({ bold }) =>
    bold &&
    css`
      font-weight: bold;
    `};
`

export const ListItem = styled.div`
  display: block;
  padding: ${rem('18px')} 0 ${rem('18px')};
  border-bottom: ${rem('1px')} solid ${paleGrayTwo};

  ${media.small`
    width: 100%;
    padding: ${rem('13px')} 0;
    margin: 0;
  `};

  ${props =>
    !props.blocked &&
    css`
      border-bottom: ${rem('4px')} solid ${red};
      ${LineText} {
        color: ${red};
      }
    `};
`

export const ChipContainer = styled(LineTextContainer)`
  flex-wrap: wrap;
  & span:nth-child(1),
  & span:nth-child(2) {
    margin-right: ${rem('5px')};
  }
`

export const Chip = styled.span`
  ${txt12OpenSansBold};
  color: ${white};
  background-color: ${tealish};
  padding: ${rem('3px')} ${rem('10px')};
  border-radius: ${rem('15px')};
  margin-top: ${rem('5px')};
`

export const ChipEmpty = styled.a`
  ${txt12OpenSansBold};
  background-color: ${paleGray};
  padding: ${rem('3px')} ${rem('10px')};
  cursor: pointer;
  text-transform: uppercase;
  border-radius: ${rem('15px')};
  border: solid 1px ${darkGrey};
  color: ${darkGrey};
  transition: all 0.3s;
  svg {
    font-size: ${rem('18px')};
    margin-right: ${rem('4px')};
    path {
      fill: ${darkGrey};
    }
  }
  &:hover {
    background-color: ${darkGrey};
    color: ${paleGray};
    svg {
      path {
        fill: ${paleGray};
      }
    }
  }
`
