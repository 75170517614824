import styled, { keyframes } from 'styled-components'

import { red, grey } from '../../../styles/settings/colors'

import rem from '../../../styles/tools/rem'

export const Row = styled.div`
  display: flex;
`

export const Column = styled.div`
  flex: 1 1 auto;
  width: 100%;

  :not(:last-child) {
    flex: 0 0 53px;
    margin-right: 27px;
    width: 53px;
  }
`

const bottomAnimate = keyframes`
    0% {
        max-height: 0;
    }
    100% {
        max-height: ${rem('50px')};
    }
`

export const Error = styled.span`
  color: ${red};
  display: inline-block;
  font-family: OpenSans;
  font-size: ${rem('12px')};
  letter-spacing: ${rem('-0.3px')};
`

export const BottomMessage = styled.div`
  animation: 0.4s ${bottomAnimate} forwards;
  display: flex;
  align-items: center;
  margin-top: ${rem('8px')};
  max-height: 0;
  overflow: hidden;
  padding-left: ${rem('16px')};

  .animated-loading {
    margin-right: ${rem('8px')};
    fill: ${grey};
  }
`
