import styled from 'styled-components'
import { darkBlue, darkGrey, grey } from '../../../styles/settings/colors'
import { title24Galano, txt14OpenSans } from '../../../styles/settings/types'

import content from '../../../styles/_objects/content'
import rem from '../../../styles/tools/rem'
import media from '../../../styles/tools/media'

export const Content = styled.section`
  ${content};
  margin: ${rem('42px')} auto;
`

export const Wrapper = styled.div`
  margin-top: ${rem('32px')};
`

export const Heading = styled.h1`
  ${title24Galano} color: ${darkBlue};
`

export const Paragraph = styled.p`
  ${txt14OpenSans} color: ${darkBlue};
  width: ${rem('270px')};

  ${media.large`
        width: ${rem('435px')};
    `};
`

export const ExcludeLink = styled.a`
  color: ${darkGrey};
  cursor: pointer;
  font-family: OpenSans;
  font-size: ${rem('12px')};
  margin-left: ${rem('9px')};
`

export const SecondaryHeading = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
`

export const ExcludeLinkContainer = styled.div`
  align-items: center;
  display: flex;
`

export const AddressFormsContainer = styled.div`
  ${props =>
    props.index === 1 &&
    `
        border-top: 1px solid ${grey};
        margin: ${rem('16px')} 0;
        padding-top: ${rem('32px')};
    `};
`

export const LastSavedLabel = styled.label`
  color: ${grey};
  display: block;
  font-family: OpenSans;
  font-size: ${rem('12px')};
  letter-spacing: ${rem('1px')};
  margin-top: ${rem('8px')};
  text-transform: uppercase;
`

export const ButtonContainer = styled.div`
  ${props =>
    props.amount === 'singular' &&
    `
        float: left;
        margin-right: ${rem('20px')};
    `} ${media.small`
        width: 100%
    `};
`
