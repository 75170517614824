import React from 'react'
import Icon from '../Icon/Icon'
import Hide from '../../../styles/utilities/Hide'
import FooterNavigationSmall from './FooterNavigationSmall'
import FooterNavigationLarge from './FooterNavigationLarge'

import { CardsContainer, CardFlag, CardsFlags, FooterContainer, NavigationText } from './styles'

function Footer() {
  return (
    <FooterContainer id="footer">
      <Hide at="small">
        <FooterNavigationLarge />
      </Hide>
      <Hide at="large">
        <FooterNavigationSmall />
      </Hide>
      <CardsContainer>
        <NavigationText marginOnSmall>
          © consectetur adipiscing elit. Hoc dixerit potius Ennius: Veloe 2017
        </NavigationText>
        <CardsFlags>
          <Icon icon="visa" svgStyle={CardFlag} width="50" height="30" viewBox="0 0 50 30" />
          <Icon icon="master" svgStyle={CardFlag} width="50" height="30" viewBox="0 0 50 30" />
          <Icon icon="maestro" svgStyle={CardFlag} width="50" height="30" viewBox="0 0 50 30" />
          <Icon icon="diners" svgStyle={CardFlag} width="50" height="30" viewBox="0 0 50 30" />
        </CardsFlags>
      </CardsContainer>
    </FooterContainer>
  )
}

export default Footer
