import React, { useEffect, useMemo } from 'react'

import { connect } from 'react-redux'
import { Box, Flex } from 'grid-styled'

import { fetchPaymentInfo } from 'alelo-logic/actions'

import Button from '../../elements/Button'
import rem from '../../../styles/tools/rem'

import { Wrapper, Text, TextBold, UserImage, EditPaymentMethod, Username } from './styles'

const { paymentTypeLabel, cardLabel, editDataBtn, confirmBtn } = require('./data.json')

const PaymentInfoCard = ({
  onEdit,
  onConfirm,
  getPaymentInfo,
  accountPlanId,
  avatar,
  paymentInfo,
  loading,
  paymentType
}) => {
  useEffect(() => {
    getPaymentInfo(accountPlanId)
  }, [])

  const splitedUsername = paymentInfo.nome ? paymentInfo.nome.split(' ') : ['']
  const paymentLabel = paymentType === 'CARTAO_DEBITO' ? 'Débito' : 'Crédito'
  const avatarImage = useMemo(() => {
    return require(`../../../static/avatars/avatar-${avatar + 1}.png`).default
  }, [avatar])

  return (
    <Wrapper>
      <Box mb={[rem('20px'), rem('50px')]}>
        <Text>{paymentTypeLabel}</Text>
        <TextBold>{paymentLabel}</TextBold>
      </Box>
      <Flex align="center" mb={[rem('30px'), rem('40px')]}>
        <UserImage src={avatarImage} alt="userImg" />
        <Username>
          <span id="lblFirstName">{splitedUsername.shift()}</span>
          <br />
          <span id="lblLastName">{splitedUsername.join(' ')}</span>
        </Username>
      </Flex>
      <Box mb="30px">
        <Text>{cardLabel}</Text>
        <TextBold id="lblMaskedCard">{paymentInfo.numeroMascarado}</TextBold>
      </Box>
      <Box>
        <Button
          id="btnContinue"
          onClick={onConfirm}
          width="100%"
          text={confirmBtn}
          disabled={loading}
          loading={loading}
          className="gtm-link-event"
          data-gtm-event-category="veloe:area-logado:recarga"
          data-gtm-event-action="clique:confirmar-recarga"
          data-gtm-event-label="confirmacao"
        />
        <EditPaymentMethod>
          <Button id="btnEdit" onClick={onEdit} width="100%" text={editDataBtn} />
        </EditPaymentMethod>
      </Box>
    </Wrapper>
  )
}

const mapStateToProps = ({ reducer, header }) => {
  return {
    account: reducer.account,
    avatar: header.avatar,
    paymentInfo: reducer.recharge.paymentInfo
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getPaymentInfo: accountPlanId => fetchPaymentInfo(accountPlanId)(dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PaymentInfoCard)
