import React from 'react'
import Helmet from 'react-helmet'
import { SubHeader, Title, Subtitle, Container } from './styles'

const Header = () => (
  <Container>
    <Helmet title="Solicitar Lista" />
    <SubHeader>
      <Title>Solicitar lista</Title>
      <Subtitle>
        Escolha o tipo de arquivo para exportar os dados e preencha o número sequencial que deseja.
      </Subtitle>
    </SubHeader>
  </Container>
)

export default Header
