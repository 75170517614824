import styled from 'styled-components'

import { darkBlue, white } from '../../../styles/settings/colors'

import { txt12OpenSans, txt12OpenSansBold } from '../../../styles/settings/types'

import rem from '../../../styles/tools/rem'

import media from '../../../styles/tools/media'

export const VehicleOption = styled.div`
  ${txt12OpenSans} background: transparent;
  border-top: 1px solid rgba(255, 255, 255, 0.4);
  color: ${white};
  display: block;
  padding: ${rem('13px')} 0;
  text-align: center;
  text-transform: uppercase;
  transition: all 0.3s ease;
  width: 100%;

  span {
    ${txt12OpenSansBold} color: ${white};
    display: inline-block;
    text-transform: uppercase;
    transition: color 0.3s ease;
  }
`

export const VehicleOptionContent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0 auto;
  max-width: ${rem('940px')};

  ${media.small`
        justify-content: flex-start;
        margin: 0 ${rem('24px')};
        width: 100%;
    `};
`

export const Label = styled.label`
  cursor: pointer;
  user-select: none;
`

export const Input = styled.input`
  display: none;

  :checked + ${VehicleOption} {
    background: ${white};
    color: ${darkBlue};

    span {
      color: ${darkBlue};
    }
  }
`

export const VehicleOptionCarPlate = styled.div`
  ${media.small`
        width: 60px;
        text-align: left;
    `};
`

export const CarPlateLabel = styled.span`
  ${txt12OpenSans} ${media.large`
        margin-right: ${rem('24px')};
    `};
`

export const VehicleOptionNames = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  text-transform: none;
  width: 66%;

  ${media.small`
        display:block;
        width: auto;
        margin-left: ${rem('53px')};
    `};
`

export const VehicleOptionDriverName = styled.div`
  text-align: left;
  width: 50%;

  ${media.small`
        width: auto;
    `};
`

export const VehicleOptionCarNickname = styled.div`
  text-align: left;
  width: 50%;

  ${media.small`
        width: auto;
    `};
`
