import React from 'react'
import { connect } from 'react-redux'
import {
  fetchAuthentication,
  setStateModal,
  setLoginMsgError,
  setShowCaptch
} from 'alelo-logic/actions'
import { Flex } from 'grid-styled'
import Hide from '../../../styles/utilities/Hide'

import LoginForm from '../../modules/LoginForm'
import login from '../../../static/login.jpg'
import veloeLogo from '../../../static/logoVeloe.png'

import { Aside, Content, Title, Veloe, Container } from './style'
import { firstAccess } from 'alelo-logic/services/userService'

const { title, authCode, redirect } = require('./data.json')

const nomeCompTipoLogin = 'radioTipoLogin'
const tipoPF = 'loginPessoaFisica'
const tipoPJ = 'loginPessoaJuridica'

const Login = ({ doLogin, closeModal, stateModal, loginMsgError, showCaptcha }) => (
  <Flex>
    <Aside>
      <img src={login} alt="Avatar" />
    </Aside>
    <Content>
      <Hide at="large">
        <Veloe>
          <img src={veloeLogo} alt="Logo Veloe" />
        </Veloe>
      </Hide>
      <Title>{title}</Title>
      <Container>
        <LoginForm
          onSubmit={doLogin}
          nomeCompTipoLogin={nomeCompTipoLogin}
          tipoPF={tipoPF}
          tipoPJ={tipoPJ}
          isOpen={stateModal}
          closeModal={closeModal}
          loginMsgError={loginMsgError}
          showCaptcha={showCaptcha}
        />
      </Container>
    </Content>
  </Flex>
)

const mapStateToProps = ({ reducer }) => {
  document.title = 'Minha Veloe | Login';
  return {
    account: reducer.account,
    stateModal: reducer.loginModal.stateModal,
    loginMsgError: reducer.loginModal.loginMsgError,
    showCaptcha: reducer.loginModal.showCaptcha
  }
}

const mapDispatchToProps = (dispatch, ownProps) => ({
  doLogin: values => {
    const login = values[nomeCompTipoLogin] === tipoPF ? values.cpfLogin : values.emailLogin

    dispatch(fetchAuthentication(login, values.password, values.captcha))
      .then(credentials => {
        firstAccess(login).then(resp => {})
        if (credentials) {
          if (!credentials.active) {
            ownProps.history.push(redirect.canceledAccount)
          } else {
            ownProps.history.push(redirect.dashboard)
          }
        }

        window.dataLayer.push({
          event: 'event',
          eventCategory: 'veloe:login',
          eventAction: 'login:tentativa',
          eventLabel: 'continuar:sucesso'
        })
      })
      .catch(e => {
        let json = JSON.parse(e.response.data)
        var msg = json.messageError
        dispatch(setLoginMsgError(msg))
        dispatch(setShowCaptch(json.captcha))

        if (e.status === authCode.unauthorized || e.status === authCode.locked) {
          dispatch(setStateModal(true))
        } else {
          dispatch(setStateModal(true))
          console.log(e) // eslint-disable-line
        }
        window.dataLayer = window.dataLayer || []
        window.dataLayer.push({
          event: 'login',
          eventCategory: 'veloe:login',
          eventAction: 'login:tentativa',
          eventLabel: 'continuar:erro'
        })
      })
  },
  closeModal: () => dispatch(setStateModal(false))
})

export default connect(mapStateToProps, mapDispatchToProps)(Login)
