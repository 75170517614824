import styled, { css } from 'styled-components'
import ReactSelect from 'react-select'
import { bottomLine, Label } from '../TextInput/styles'

import {
  darkBlue,
  darkGrey,
  mediumGrey,
  grey,
  lighterGrey,
  red,
  white
} from '../../../styles/settings/colors'
import rem from '../../../styles/tools/rem'
import media from '../../../styles/tools/media'

const placeholderStyle = `
    font-family: OpenSans;
    font-size: 20px;
    line-height: ${rem('45px')};
    display: flex;
`

const greyBottomLine = `
    ${bottomLine}
    background-color: ${grey};
    left: 0;
    right: 0;
    width: 100%;
`

export const dropdownArrow = `
    stroke: ${grey}; height: 10px; width: 15px;
`

export const MobileSelect = styled.div`
  display: none;
  position: relative;
  width: 100%;
  > select {
    background: transparent;
    color: ${white};
    font-size: ${rem('20px')};
    font-family: OpenSans;
    height: ${rem('45px')};
    padding-top: ${rem('5px')};
    text-indent: ${rem('16px')};
    -webkit-appearance: none;
    -moz-appearance: none;
  }
  :after {
    ${greyBottomLine};
  }
  > select,
  > select:hover {
    border: none;
  }
  > svg {
    position: absolute;
    right: 15px;
    top: 20px;
    pointer-events: none;
    ${props => props.disabled && 'opacity: .3'};
  }

  ${media.small`
        display: block;
    `};
`

export const WrapperSelect = styled.div`
  /* display: none; */
  ${media.large`
    display: block;
  `};
`

export const SelectConstructor = isAsync => styled(isAsync ? ReactSelect.Async : ReactSelect)`
    + svg {
        position: absolute;
        right: 15px;
        top: 38px;
        pointer-events: none;
        ${props => props.disabled && 'opacity: .3'}
    }
    &.has-value.Select--single > .Select-control .Select-value .Select-value-label,
    &.has-value.is-focused.Select--single > .Select-control .Select-value .Select-value-label {
        color: ${darkGrey}
    }
    .Select-placeholder {
        padding-left: ${rem('16px')};
    }
    .Select-value-label {
        padding-left: ${rem('5px')};
    }
    &.is-focused {
        .Select-control {
            background-color:transparent
        }
        :not(.is-open) {
            > .Select-control {
                box-shadow: none;
            }
        }
    }
    .Select-placeholder {
        ${placeholderStyle}
    }
    & .Select-control {
        border: none;
        border-radius: 0;
        background-color: transparent;
        height: ${rem('45px')};
        :after {
            ${greyBottomLine}
            ${props => props.disabled && 'opacity: .3'}
        }
        :before {
            ${bottomLine}
            background-color: transparent;
            left: 50%;
            right: 0;
            transform: translateX(-50%);
            transition: 0.6s ease width, 0.6s ease background-color;
            width: 0;
            z-index: 1;

            ${props =>
              props.hasError &&
              `
                background-color: ${red};
                width: 100%;
            `}
        }
        .Select-value {
            .Select-value-label {
                font-family: OpenSans;
                font-size: ${rem('20px')};
                line-height: ${rem('45px')};
            }
        }
    }
    &.is-open {
        + svg {
            transform: rotate(180deg);
        }
        .Select-control {
            box-shadow: 0 8px 8px 0 rgba(30, 27, 91, 0.2);
            :hover {
                box-shadow: 0 8px 8px 0 rgba(30, 27, 91, 0.2);
            }
            :after {
                background-color: ${lighterGrey};
                width: 100%;
                transition: 0.6s ease width, 0.6s ease background-color;
            }
            :before {
                background-color: ${lighterGrey};
                width: 100%;
            }
        }
    }
    & .Select-menu-outer {
        box-shadow: 0 8px 8px 0 rgba(30, 27, 91, 0.2);
        border: none;
        margin-top: 0;
        z-index: 10;
    }
    & .Select-option {
        font-family: OpenSans;
        font-size: ${rem('16px')};
        padding: ${rem('15px')} ${rem('16px')};
        color: ${mediumGrey};
        &.is-selected {
            background-color: transparent;
            color: ${mediumGrey};
        }
        &.is-focused {
            background-color: transparent;
            color ${darkBlue};
        }
    }
`

export const Wrapper = styled.div`
  padding-top: 0.3125rem;
  position: relative;

  .Select.is-disabled .Select-control {
    background: transparent;
  }

  ${props =>
    props.negative &&
    css`
      color: ${white};

      ${Label} {
        color: ${white};
        opacity: 1;
      }
      ${SelectConstructor} {
        & .Select-control {
          &:after {
            background-color: ${white};
          }
        }
        &.has-value.Select--single,
        &.has-value.is-focused.Select--single {
          > .Select-control .Select-value .Select-value-label {
            color: ${white};
          }
        }
      }
    `} ${MobileSelect} {
    :after {
      /* background-color: white; */
      font-size: 10px;
    }
  }
`
