import styled, { keyframes, css } from 'styled-components'
import { Flex } from 'grid-styled'
import { hexToRgba } from '../../../utils/hexToRgba'
import { butterflyBush, white, darkBlue } from '../../../styles/settings/colors'

import { txt14OpenSans, txt18OpenSans, txt24OpenSansBold } from '../../../styles/settings/types'

import media from '../../../styles/tools/media'
import rem from '../../../styles/tools/rem'

const showModalContent = keyframes`
    0% {
        opacity: 0;
        transform: translateY(${rem('30px')}) scale(.95);
    }

    100% {
        opacity: 1;
        transform: translateY(0) scale(1);
    }
`

const hideModalContent = keyframes`
0% {
    opacity: 1;
    transform: translateY(0) scale(1);
}

100% {
    opacity: 0;
    transform: translateY(${rem('30px')}) scale(.95);
}
`

const showModal = keyframes`
0% {
    opacity: 0;
}

100% {
    opacity: 1;
}
`

const hideModal = keyframes`
0% {
    opacity: 1;
}

100% {
    opacity: 0;
}
`

export const Overlay = styled.div`
  align-items: center;
  background: ${({ modalBlue }) => (modalBlue ? hexToRgba(darkBlue, 0.64) : 'rgba(0, 0, 0, 0.15)')};
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
`

export const Content = styled.div`
  opacity: 0;
  position: relative;
  transform-origin: bottom;
  transition: 0.4s;
  z-index: 1;
  width: 100%;

  ${({ state }) => {
    switch (state) {
      case 'entered':
        return css`
          animation: 0.4s ${showModalContent} forwards;
        `
      case 'exiting':
        return css`
          animation: 0.4s ${hideModalContent} forwards;
        `
      case 'exited':
        return css`
          display: none;
        `
      default:
        return css`
          display: block;
        `
    }
  }};
  ${media.large`
      max-width: ${({ maxWidth }) => (maxWidth ? `${maxWidth}px` : rem('660px'))};
  `};
`

export const Wrapper = styled.div`
  align-items: center;
  background: rgba(0, 0, 0, 0.2);
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  opacity: 0;
  position: fixed;
  right: 0;
  top: 0;
  transition: 0.4s;
  z-index: 101;

  ${({ state }) => {
    switch (state) {
      case 'entered':
        return css`
          animation: 0.4s ${showModal} forwards;
        `
      case 'exiting':
        return css`
          animation: 0.4s ${hideModal} forwards;
        `
      case 'exited':
        return css`
          display: none;
        `
      default:
        return css`
          display: block;
        `
    }
  }};
`

export const ModalContainer = styled.div`
  background-color: ${white};
  height: 100vh;
  overflow: auto;
  position: relative;
  padding: ${rem('44px')} ${rem('76px')};
  width: 100%;
  ${media.large`
    border-radius: ${rem('8px')};
    box-shadow: 0 ${rem('8px')} ${rem('8px')} 0 rgba(0, 0, 0, 0.12);
    height: auto;
  `};
`

export const ModalSimpleContainer = styled.div`
  background-color: ${white};
  height: 100vh;
  overflow: auto;
  position: relative;
  width: 100%;
  display: flex;
  ${media.large`
    border-radius: ${rem('20px')};
    box-shadow: 0 ${rem('8px')} ${rem('8px')} 0 rgba(0, 0, 0, 0.12);
    height: auto;
    min-height: ${rem('440px')};
  `};
`

export const ModalContent = styled.div`
  padding: ${({ noPadding }) => (noPadding ? '0 !important' : `${rem('60px')} ${rem('30px')}`)};
  ${({ center }) => center && 'text-align: center'};
  ${media.large`
      ${({ compact }) =>
        compact
          ? css`
              padding: ${rem('40px')} ${rem('64px')};
            `
          : css`
              padding: ${rem('74px')} ${rem('60px')} ${rem('55px')};
            `}
  `};
`

export const ModalIllustration = styled.img`
  display: block;
  margin: 0 auto;
  width: 40%;
  ${({ accountType }) =>
    accountType === 'pj'
      ? media.large`
  display: block;
    max-height: 88%;
    position: absolute;
    right: ${rem('50px')};
    top: 56%;
    transform: translateY(-50%) translateX(19%);
  `
      : media.large`
    display: block;
    max-height: 75%;
    position: absolute;
    right: ${rem('50px')};
    top: 50%;
    transform: translateY(-50%);
    width: auto;
  `};
`

export const ModalTitle = styled.h1`
  color: ${darkBlue};
  font-family: 'Galano';
  font-size: ${rem('36px')};
  font-weight: 600;
  line-height: 1.17;
  margin: 0;
  text-align: center;

  ${media.large`
    font-size: ${props => (props.compact ? rem('32px') : rem('34px'))};
    line-height: 1.04;
    text-align: center;
  `};
`

export const ModalDescription = styled.h3`
  ${txt18OpenSans} color: ${darkBlue};
font-weight: ${props => (props.bold ? '800' : '600')};
  letter-spacing: $normal;
  line-height: 1.44;
  margin-bottom: ${rem('20px')};
  white-space: normal;
  ${({ top }) =>
    top &&
    `
    margin-top: ${top}px;
  `};

  ${media.large`
    text-align: center;
    max-width: 386px;
    margin: 0 auto;
    ${({ top }) =>
      top &&
      `
      margin-top: ${top}px;
    `};
  `};
`

export const SummaryContainer = styled.div`
  max-width: 386px;
  margin: 0 auto;
  background: #f7f7fc;
  padding: 14px 24px;
  display: flex;
  flex-wrap: wrap;
`

export const SummaryItem = styled.div`
  width: 70%;
  margin-bottom: 16px;
  ${props =>
    props.small &&
    `
      width: 30%;
    `};
`

export const ItemTitle = styled.div`
  ${txt14OpenSans};
  font-weight: 800;
  line-height: 1.22;
  color: ${butterflyBush};
`

export const ItemValue = styled.div`
  ${txt14OpenSans};
  font-weight: normal;
  color: ${butterflyBush};
`

export const ButtonsContainer = styled.div`
  max-width: 386px;
  margin: 35px auto 0;
  display: flex;
  justify-content: space-between;
  padding: 0 20px;
`

export const Close = styled.div`
  color: ${white};
  cursor: pointer;
  height: ${rem('18px')};
  position: absolute;
  right: ${rem('30px')};
  top: ${rem('30px')};
  width: ${rem('18px')};

  ${media.large`
    right: ${rem('35px')};
    top: ${rem('38px')};
  `};
`

export const QrCodeWrapper = styled(Flex)`
  flex-direction: column;
  justify-content: center;
`

export const QrCodeDescription = styled.div`
  ${txt24OpenSansBold};
  color: ${darkBlue};
  margin-bottom: ${rem('10px')};
`
