import TemplateDashboard from '../app/templates/TemplateDashboard'
import DashboardResume from '../app/pages/DashboardResume'
import DashboardStatement from '../app/pages/DashboardStatement'
import DashboardInvoice from '../app/pages/DashboardInvoice'
import DashboardIdentifiers from '../app/pages/DashboardIdentifiers'
import DashboardIdentifiersRequests from '../app/pages/DashboardIdentifiersRequests'
import DashboardRechargeHistory from '../app/pages/DashboardRechargeHistory'
import DashboardHowToInstall from 'app/pages/DashboardHowToInstall'
import PlanData from '../app/pages/PlanData'
import InvoiceDetails from '../app/pages/InvoiceDetails'

export default {
  path: '/minha-veloe/:id',
  component: TemplateDashboard,
  routes: [
    {
      exact: true,
      path: '/minha-veloe/:id/resumo/',
      component: DashboardResume
    },
    {
      exact: true,
      path: '/minha-veloe/:id/extrato',
      component: DashboardStatement
    },
    {
      exact: true,
      path: '/minha-veloe/:id/fatura',
      component: DashboardInvoice
    },
    {
      exact: true,
      path: '/minha-veloe/:id/fatura/:fatura',
      component: InvoiceDetails
    },
    {
      exact: true,
      path: '/minha-veloe/:id/historico-de-recarga',
      component: DashboardRechargeHistory
    },
    {
      exact: true,
      path: '/minha-veloe/:id/identificadores',
      component: DashboardIdentifiers
    },
    {
      exact: true,
      path: '/minha-veloe/:id/instalacao',
      component: DashboardHowToInstall
    },
    {
      exact: true,
      path: '/minha-veloe/:id/dados-do-plano',
      component: PlanData
    },
    {
      exact: true,
      path: '/minha-veloe/:id/identificadores/novo-produto',
      component: DashboardIdentifiersRequests
    }
  ]
}
