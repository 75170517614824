import React from 'react'

import {
  Table,
  TableHeader,
  TableHeaderCell,
  TableRow,
  TableRowCell,
  TableRowCellStatus
} from './styles'
const { tableConfig } = require('./data.json')

const Index = ({ data }) => {
  const { columns } = tableConfig

  return (
    <Table>
      <TableHeader>
        {columns.map((column, index) => (
          <TableHeaderCell key={`car-table-header-${index}`} width={column.width}>
            {column.header}
          </TableHeaderCell>
        ))}
      </TableHeader>
      {data.map((row, index) => (
        <TableRow key={`car-table-body-${index}`} index={index}>
          <TableRowCell width={columns[0].width}>{row.date}</TableRowCell>
          <TableRowCell width={columns[1].width}>{row.hour}</TableRowCell>
          <TableRowCellStatus width={columns[2].width} blocked={row.blocked}>
            {row.blocked ? 'Bloqueado' : 'Liberado'}
          </TableRowCellStatus>
        </TableRow>
      ))}
    </Table>
  )
}

export default Index
