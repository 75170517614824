export default {
  __html: `
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
    <g fill="#B1B0C5" fill-rule="nonzero">
        <path d="M4.894 10.928c-.07 0-.118 0-.188-.023a.732.732 0 0 1-.518-.886c.447-1.677 1.483-3.192 2.87-4.264a8.155 8.155 0 0 1 4.99-1.677 8.155 8.155 0 0 1 4.988 1.678c.682.535 1.293 1.164 1.764 1.887.494.722.87 1.538 1.106 2.376a.735.735 0 0 1-.518.886.744.744 0 0 1-.894-.513c-.376-1.375-1.223-2.61-2.376-3.495A6.686 6.686 0 0 0 12.024 5.5c-1.506 0-2.918.49-4.118 1.398a6.593 6.593 0 0 0-2.353 3.519c-.047.302-.33.512-.659.512zM12.07 20.202c-3.694 0-6.94-2.47-7.882-6.012a.7.7 0 0 1 .518-.862.708.708 0 0 1 .87.513c.777 2.912 3.436 4.94 6.471 4.94s5.718-2.028 6.47-4.94a.727.727 0 0 1 .871-.513c.377.093.612.49.518.862-.894 3.542-4.141 6.012-7.835 6.012z"/>
        <path d="M12.024 9.996c.4 0 .729.326.729.722v5.43a.73.73 0 0 1-1.459 0v-5.43c0-.419.33-.722.73-.722z"/>
        <ellipse cx="12.047" cy="8.109" rx="1" ry="1" transform="rotate(-180 12.047 8.109)"/>
    </g>
</svg>
  `
}
