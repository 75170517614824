import styled from 'styled-components'
import { darkBlue } from '../../../styles/settings/colors'
import { title24Galano, title18Galano, txt14OpenSans } from '../../../styles/settings/types'

import content from '../../../styles/_objects/content'
import rem from '../../../styles/tools/rem'
import media from '../../../styles/tools/media'

export const Content = styled.section`
  ${content};
  margin: ${rem('42px')} auto;

  ${media.small`
        margin: ${rem('29px')} auto;
    `};
`

export const Wrapper = styled.div`
  margin: ${rem('32px')} 0;
`

export const Heading = styled.h1`
  ${title18Galano} color: ${darkBlue};
  font-weight: bold;
  margin-bottom: ${rem('20px')};

  ${media.large`
        ${title24Galano}
    `};
`

export const Paragraph = styled.p`
  ${txt14OpenSans} color: ${darkBlue};
  width: ${rem('270px')};
  margin-top: ${rem('7px')};

  ${media.large`
        width: ${rem('313px')};
        margin: 0;
    `};
`

export const EditUserDataWrapper = styled.div`
  margin-left: ${rem('30px')};
  margin-right: ${rem('30px')};
`
