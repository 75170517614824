import { utils } from 'alelo-logic'

import { errorMessage } from '../../../utils/dictionary'

const { emailValidate, dddValidate, phoneValidate } = utils

const { required, emailFormat } = errorMessage

export const validate = values => {
  const errors = {}

  if (!values.email) {
    errors.email = required
  } else if (values.email && emailValidate(values.email)) {
    errors.email = emailFormat
  }

  if (values.phoneddd && !values.phone) {
    errors.phone = 'Campo obrigarório (preencher número)'
  } else if (!values.phoneddd && values.phone) {
    errors.phone = 'Campo obrigarório (preencher DDD)'
  }

  if (values.phoneddd && dddValidate(values.phoneddd)) {
    errors.phone = dddValidate(values.phoneddd)
  }

  if (values.phone && phoneValidate(values.phone)) {
    errors.phone = phoneValidate(values.phone)
  }

  if (!values.mobileddd || !values.mobile) {
    errors.mobile = required
  } else {
    if (values.mobileddd && dddValidate(values.mobileddd)) {
      errors.mobile = dddValidate(values.mobileddd)
    }

    if (values.mobile && phoneValidate(values.mobile)) {
      errors.mobile = phoneValidate(values.mobile)
    }
  }

  return errors
}
