import styled from 'styled-components'
import { darkBlue, linkNavyBlue } from '../../../styles/settings/colors'
import { title24Galano, txt14OpenSans } from '../../../styles/settings/types'

import content from '../../../styles/_objects/content'
import rem from '../../../styles/tools/rem'
import media from '../../../styles/tools/media'
import container from '../../../styles/_objects/container'

export const Header = styled.div`
  ${container};

  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: ${rem('40px')} ${rem('65px')} ${rem('5px')};

  ${media.small`
        padding: ${rem('20px')} ${rem('30px')};
        position: relative;
        text-align: center;
        & > a {
            margin: 0 auto;
        }
    `};
`

export const Heading = styled.h1`
  ${title24Galano} color: ${darkBlue};
  font-size: ${rem('40px')};
  margin-bottom: 0;

  ${media.small`
        font-size: ${rem('24px')};
        margin: 0;
    `};
`

export const Paragraph = styled.p`
  ${txt14OpenSans} color: ${darkBlue};
  max-width: ${rem('430px')};
  margin-top: 0;
`

export const Wrapper = styled.div`
  margin-top: ${rem('32px')};
`

export const Content = styled.section`
  ${content};
  margin: ${rem('42px')} auto;

  ${media.small`
        margin: ${rem('10px')} auto ${rem('42px')};
    `};
`

export const Logo = `
    height: ${rem('30px')};
    fill: ${linkNavyBlue};
    width: ${rem('74px')};
`
