import styled from 'styled-components'
import rem from '../../../styles/tools/rem'

import { title20GalanoBold } from '../../../styles/settings/types'

import media from '../../../styles/tools/media'

export const FormTitle = styled.h2`
  ${title20GalanoBold};
  color: #fff;
  letter-spacing: ${rem('2.1px')};
  max-width: ${rem('940px')};
  margin: ${rem('70px')} auto ${rem('23px')} auto;

  ${media.small`
        font-size: ${rem('14px')};
        letter-spacing: ${rem('1px')};
        margin-bottom: ${rem('16px')};
        margin-left: ${rem('25px')};
        margin-top: ${rem('25px')};
    `};
`

export const ButtonContainer = styled.div`
  bottom: ${rem('22px')};
  left: 50%;
  position: fixed;
  transform: translateX(-50%);

  ${media.small`
        bottom: ${rem('49px')};
        width: ${rem('220px')};
    `};
`
