import gql from 'graphql-tag'

export const gqlCriarInfoGerencial = gql`
  mutation criarInfoGerencial(
    $identificador: String!
    $conta: String
    $data: String
    $descricao: String
    $placa: String
    $tipo: String
  ) {
    criarInfoGerencial(
      info: {
        codIdentificador: $identificador
        conta: $conta
        dataInicioVigencia: $data
        descricao: $descricao
        placa: $placa
        tipoInfo: $tipo
      }
    ) {
      descricaoAtual
      placa
      conta
      codIdentificador
      dataInicioVigencia
      tipoInfo
    }
  }
`
export const gqlAlterarInfoGerencial = gql`
  mutation alterarInfoGerencial(
    $identificador: String!
    $conta: String
    $data: String
    $descricao: String
    $id: String
    $placa: String
    $tipo: String
  ) {
    alterarInfoGerencial(
      info: {
        codIdentificador: $identificador
        conta: $conta
        dataInicioVigencia: $data
        descricao: $descricao
        id: $id
        placa: $placa
        tipoInfo: $tipo
      }
    ) {
      descricaoAtual
      placa
      conta
      codIdentificador
      dataInicioVigencia
      tipoInfo
    }
  }
`
export const gqlExcluirInfoGerencial = gql`
  mutation excluirInfoGerencial(
    $identificador: String!
    $conta: String
    $data: String
    $descricao: String
    $id: String
    $placa: String
    $tipo: String
  ) {
    excluirInfoGerencial(
      info: {
        codIdentificador: $identificador
        conta: $conta
        dataInicioVigencia: $data
        descricao: $descricao
        id: $id
        placa: $placa
        tipoInfo: $tipo
      }
    ) {
      descricaoAtual
      placa
      conta
      codIdentificador
      dataInicioVigencia
      tipoInfo
    }
  }
`

export const gqlBuscarInfoGerencialHistorico = gql`
  query buscarInfoGerencialHistorico($idConta: String!, $placa: String!) {
    buscarInfoGerencialHistorico(idConta: $idConta, placa: $placa) {
      id
      descricaoAtual
      descricaoAnterior
      placa
      conta
      codIdentificador
      dataInicioVigencia
      dataAtualizacao
      acao
    }
  }
`

export const gqlBuscarInfoGerencialVigente = gql`
  query buscarInfoGerencialVigente($idConta: String!, $placa: String!) {
    buscarInfoGerencialVigente(idConta: $idConta, placa: $placa) {
      id
      tipoInfo
      descricaoAtual
      placa
      conta
      codIdentificador
      dataInicioVigencia
      dataAtualizacao
      acao
      situacao
    }
  }
`

export const gqlBuscarInfoGerencialAtiva = gql`
  query buscarInfoGerencialAtiva($idConta: String!, $placa: String!) {
    buscarInfoGerencialAtiva(idConta: $idConta, placa: $placa) {
      id
      tipoInfo
      descricaoAtual
      placa
      conta
      codIdentificador
      dataInicioVigencia
      dataAtualizacao
      acao
      situacao
    }
  }
`

export const gqlBuscarInfoGerencialTop5 = gql`
  query buscarInfoGerencialTop5($idConta: String!) {
    buscarInfoGerencialTop5(idConta: $idConta) {
      id
      tipoInfo
      descricaoAtual
      placa
      conta
      codIdentificador
      dataInicioVigencia
      dataAtualizacao
      acao
      situacao
    }
  }
`
