import React from 'react'
import Modal from '../../elements/ModalBlue'
import Button from '../../elements/Button'

import { Wrapper, Title, Text, WrapperButton } from './styles'

import image from '../../../static/icon-veloe-background.svg'

class ExisteTagsModal extends React.Component {
  render() {
    const { handleExisteClick } = this.props
    return (
      <Modal backgroundImage={image} position="right bottom">
        <Wrapper>
          <Title>Você já tem pedidos em andamento!</Title>
          <Text>
            Seus pedidos estão em andamento. Você pode acompanhar a entrega em "<b>Meus pedidos</b>"
          </Text>
          <Text>
            <b>Obrigado por escolher Veloe! ;)</b>
          </Text>

          <WrapperButton>
            <Button buttonType="darkBlue" text="Ok, entendi" onClick={() => handleExisteClick()} />
          </WrapperButton>
        </Wrapper>
      </Modal>
    )
  }
}

export default ExisteTagsModal
