import React from 'react'
import { FieldArray, reduxForm } from 'redux-form'
import AddressFormSection from '../AddressFormSection'
import AddressFormShippingSection from '../AddressFormShippingSection'

const EditAddressForm = props => {
  const { initialValues, handleSubmit, onDelete, saving } = props
  const componectSection =
    window.location.href.includes('/perfil/editar-endereco') ||
    window.location.href.includes('/perfil/endereco-comercial')
      ? AddressFormSection
      : AddressFormShippingSection
  return (
    <form onSubmit={handleSubmit}>
      <FieldArray
        name="address"
        component={componectSection}
        initialValues={initialValues.address}
        onDelete={onDelete}
        saving={saving}
      />
    </form>
  )
}

export default reduxForm({
  form: 'editAddress',
  destroyOnUnmount: false,
  enableReinitialize: true
})(EditAddressForm)
