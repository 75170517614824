import styled from 'styled-components'

import rem from 'styles/tools/rem'
import media from 'styles/tools/media'

import { blizzardBlue, white } from 'styles/settings/colors'
import { title60GalanoBold } from 'styles/settings/types'

export const Text = styled.p`
  ${title60GalanoBold} color: ${blizzardBlue};
  letter-spacing: ${rem('4.5px')};
  line-height: 1;
  margin: 0;
  text-align: right;
  transition: 0.3s font-size, 0.3s color;

  ${media.large`
        font-size: ${rem('90px')};
        text-align: center;
    `};
`

export const Item = styled.li`
  cursor: pointer;
  list-style: none;
  left: 0;
  margin: 0;
  position: absolute;
  top: -150%;
  transform: translateY(0);
  transition: 0.3s all;

  right: ${rem('30px')};

  ${media.large`
        right: auto;
        left: ${rem('30px')};
    `} &.carousel-item-prev {
    top: 0;
  }

  &.carousel-item-next {
    top: 100%;
    transform: translateY(-100%);
  }

  &.carousel-item-active {
    left: 5%;
    top: 50%;
    transform: translateY(-50%);

    ${Text} {
      color: ${white};
      font-size: ${rem('60px')};

      ${media.large`
                font-size: ${rem('100px')};
            `};
    }

    ~ li:not(.carousel-item-next) {
      top: 150%;
    }
  }
`

export const List = styled.ul`
  display: block;
  height: ${rem('250px')};
  margin: 0;
  overflow: hidden;
  padding: 0;
  position: relative;
  user-select: none;
  width: ${rem('300px')};

  ${media.large`
        height: ${rem('400px')};
        width: ${rem('380px')};
    `};
`
