import React from 'react'
import iosAppImage from '../../../static/botao-ios-app.png'
import { ButtomIosAppStyle } from './styles'

const ButtomIosApp = () => {
  return (
    <a href="https://apps.apple.com/br/app/veloe/id1278343398">
      {' '}
      <ButtomIosAppStyle src={iosAppImage} alt="Botao App na App Store"></ButtomIosAppStyle>{' '}
    </a>
  )
}

export default ButtomIosApp
