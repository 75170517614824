import React, { Component } from 'react'
import { func, array } from 'prop-types'
import { Box, Flex } from 'grid-styled'
import Hide from '../../../styles/utilities/Hide'
// Components
import DropdownList from './DropdownList'
import Dropdown from './Dropdown'
import DotButton from 'app/elements/DotButton'
// import Checkbox from 'app/elements/Checkbox'
import IdentifiersListItem from '../../elements/IdentifiersListItem'
// Styles
import { ListContainer, ListItem, DropdownBox } from './styles'
import { blueGray } from 'styles/settings/colors'

export default class IdentifiersList extends Component {
  static propTypes = {
    identifiers: array.isRequired,
    checkboxValues: array.isRequired,
    handleSelectItem: func.isRequired,
    handleSelectAll: func.isRequired,
    handleLoadMoreItems: func.isRequired
  }
  static defaultProps = {
    identifiers: [],
    checkboxValues: [],
    handleSelectItem: () => {},
    handleSelectAll: () => {},
    handleIncreaseList: () => {}
  }

  constructor(props) {
    super(props)
    this.state = {
      isItemDropdownOpen: null,
      itemDropdownId: null,
      isDropdownOpen: false,
      veloeTagsOptions: ['Ativa', 'Bloqueada'],
      selectedOption: 'Ativa'
    }
    window.onscroll = () => {
      if (
        window.innerHeight + document.documentElement.scrollTop ===
        document.documentElement.offsetHeight
      ) {
        props.handleLoadMoreItems()
      }
    }
  }

  handleDropdown = () => {
    this.setState({ isDropdownOpen: !this.state.isDropdownOpen })
  }

  handleItemDropdown = itemDropdownId => () => {
    if (itemDropdownId) {
      return this.setState({ isItemDropdownOpen: true, itemDropdownId })
    }
    this.setState({ isItemDropdownOpen: false, itemDropdownId: null })
  }

  handleSelectOption = selectedOption => {
    this.setState({ selectedOption, isDropdownOpen: false })
    window.dataLayer.push({
      event: 'event',
      eventAction: 'filtrar:produto',
      eventCategory: 'veloe:area-logado:meus-produtos',
      eventLabel: selectedOption
    })
  }

  render() {
    const {
      identifiers,
      checkboxValues,
      handleSelectItem,
      // handleSelectAll,
      handleCreateInf
    } = this.props
    const { isDropdownOpen, veloeTagsOptions, selectedOption } = this.state
    const itemStatus = selectedOption === 'Bloqueada' ? 'INATIVO' : 'ATIVO'
    // const isAllChecked = checkboxValues.length === identifiers.length
    const identifiersFiltered = identifiers.filter(({ situacao }) => situacao === itemStatus)
    return (
      <ListContainer>
        <ListItem>
          <Flex width={1} direction={['column', 'row']}>
            {/* <Box width={[1 / 12, 0.5 / 12]}>
              <Checkbox onChange={handleSelectAll} checked={isAllChecked} />
            </Box> */}
            <Box width={[3 / 12, 2 / 12]}>veículo</Box>
            <Box width={[3 / 12, 2 / 12]}>placa</Box>
            <Hide at="small" width={[1, 2 / 12]}>
              eixos
            </Hide>
            <DropdownBox width={[3 / 12, 2 / 12]}>
              <Dropdown
                isOpen={isDropdownOpen}
                categoryLabel="Produto"
                dropdownLabel={selectedOption}
                onClick={this.handleDropdown}
              />
              <DropdownList
                optionSelected={selectedOption}
                options={veloeTagsOptions}
                isOpen={isDropdownOpen}
                onClose={this.handleDropdown}
                onSelect={this.handleSelectOption}
              />
            </DropdownBox>
            <Box width={[3 / 12, 3 / 12]}>Informações gerenciais</Box>
            <Box width={[1, 0.5 / 12]}>
              <DotButton color={blueGray} removeCursor />
            </Box>
          </Flex>
        </ListItem>
        {identifiersFiltered.map((vehicle, index) => (
          <IdentifiersListItem
            checkboxValues={checkboxValues}
            vehicle={vehicle}
            handleSelectItem={handleSelectItem}
            key={index}
            handleCreateInf={handleCreateInf}
            itemDropdownId={this.state.itemDropdownId}
            isItemDropdownOpen={this.state.isItemDropdownOpen}
            handleItemDropdown={this.handleItemDropdown}
            itemStatus={itemStatus}
          />
        ))}
      </ListContainer>
    )
  }
}
