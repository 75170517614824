const plateMask = value => {
  if (!value) {
    return value
  }
  const onlyChars = value.replace(/[^\w\d]/g, '').toUpperCase()
  if (onlyChars.length <= 3) {
    return onlyChars
  }
  return `${onlyChars.slice(0, 3)}${onlyChars.slice(3, 7)}`
}

export default plateMask
