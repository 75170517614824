import React, { Component } from 'react'
import { connect } from 'react-redux'
import Helmet from 'react-helmet'
import { withRouter } from 'react-router-dom'
import { Flex } from 'grid-styled'

import { fetchAccountPlan } from 'alelo-logic/actions'

import { Heading, Title, Label, Text, Hr, Footer, Disclaimer } from './styles'

const data = require('./data.json')

import ImersiveHeader from '../../elements/ImersiveHeader'
import IdentifierDetails from '../../elements/IdentifierDetails'
import Button from '../../elements/Button'
import Content from '../../../styles/objects/Content'

class CancelPlan extends Component {
  async componentWillMount() {
    await this.props.getCurrentPlan(this.props.match.params.id)
  }

  render() {
    const { history, match, accountPlans } = this.props

    const currentPlan = accountPlans.plan
    const identifiers = currentPlan ? currentPlan.identifiers : []

    return (
      <main>
        <Helmet title="Cancelar plano" />

        <ImersiveHeader
          text="Dados do plano"
          url={`/minha-veloe/${match.params.id}/dados-do-plano`}
        />

        <Content>
          <Heading>
            <Label>{data.label}</Label>
            <Title>{data.title}</Title>
            <Text>{data.description.areYouSure}</Text>
            <Text>{data.description.advice}</Text>
          </Heading>

          {identifiers.map(
            identifier =>
              identifier.active && (
                <div key={identifier.identifierId}>
                  <Hr />
                  <IdentifierDetails {...identifier} />
                </div>
              )
          )}
        </Content>

        <Footer>
          <Content>
            <Flex direction={['column', 'row']} align="center" justify="space-between">
              <Disclaimer>{data.disclaimer}</Disclaimer>
              <Button
                onClick={() =>
                  history.push(`/minha-veloe/${match.params.id}/cancelar-plano/motivo`)
                }
                text={data.button}
              />
            </Flex>
          </Content>
        </Footer>
      </main>
    )
  }
}

CancelPlan.defaultProps = {
  accountPlans: {
    plan: {}
  }
}

const mapStateToProps = ({ reducer }) => ({
  accountPlans: reducer.accountPlans,
  account: reducer.account
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  getCurrentPlan(planId) {
    return dispatch(fetchAccountPlan(planId))
  }
})

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(CancelPlan))
