import types from '../actions/types'

const { UPDATE_CONTESTATION_PROTOCOL, CLEAR_CONTESTATION_PROTOCOL } = types

function contestationProtocolReducer(state = {}, action = {}) {
  switch (action.type) {
    case UPDATE_CONTESTATION_PROTOCOL: {
      return {
        ...state,
        ...action.protocol
      }
    }
    case CLEAR_CONTESTATION_PROTOCOL: {
      return {}
    }
    default:
      return state
  }
}

export default contestationProtocolReducer
