import gql from 'graphql-tag'

export const gqlPedidosEntregasRecargas = gql`
  query pedidosEntregasRecargas($conta: String!) {
    buscarPedido(conta: $conta) {
      pedido
      data
    }
    buscarEntrega(conta: $conta) {
      arsMestre {
        id
        codRastreio
        tipoRegistro
        cnpjCpf
        razaoSocial
        numeroRegistro
        codigoRetorno
        tipoProduto
        codigoPedido
        qtdeIdentificadores
        nome
        statusPedido
        dataEntrega
        peso
        valor
        tipoKit
        identificadores
        motivo
        conta
        codigoCliente
        dataPrevistaEntrega
        lote
      }
    }
    temRecarga(conta: $conta) {
      status
    }
  }
`

export const transformRastreio = (src, orderNumber) => {
  const { buscarPedido: orders, buscarEntrega: detailsQuery, temRecarga: recargaQuery } = src.data
  const { status: temRecarga } = recargaQuery
  const { arsMestre: detailsList } = detailsQuery

  const status = temRecarga ? 'Pagamento Aprovado' : 'Aguardando pagamento'
  const order = orders.find(o => parseInt(o.pedido, 10) === parseInt(orderNumber, 10))

  const fullOrder = {
    id: order.pedido,
    ticket: order.pedido,
    payment: {
      id: order.pedido,
      status,
      date: order.data
        .split('-')
        .reverse()
        .join('/')
    },
    tracker: []
  }

  // recuperar envio mais recente
  const detailsObjEnvio = detailsList.reduce((acc, details) => {
    return details.statusPedido === 'ENVIADO' && (!acc || details.dataEntrega > acc.dataEntrega)
      ? details
      : acc
  }, undefined)

  // se não houver ar com status enviado
  if (!detailsObjEnvio) {
    fullOrder.tracker.push({
      id: '',
      tipo: 'envio',
      status: 'Em Transporte',
      date: '',
      showNotArrived: false
    })
  } else {
    const dtEnvio = new Date(parseInt(detailsObjEnvio.dataEntrega, 10))
    fullOrder.tracker.push({
      id: detailsObjEnvio.id,
      tipo: 'envio',
      status: 'Em Transporte',
      date: dtEnvio
        .toISOString()
        .substr(0, 10)
        .split('-')
        .reverse()
        .join('/'),
      showNotArrived: new Date().getTime() > detailsObjEnvio.dataPrevistaEntrega
    })
  }

  // recuperar entregue/cancelado/extravio
  // recuperar da lista o item que tem status diferente de enviado
  // para apresentar se é entregue, cancelado ou extravio
  const detailsObjEntrega = detailsList.find(
    d => d.codigoPedido.toString() === orderNumber.toString() && d.statusPedido !== 'ENVIADO'
  )
  if (!detailsObjEntrega) {
    fullOrder.tracker.push({
      id: '',
      tipo: 'entrega',
      status: 'Enviado',
      date: ''
    })
  } else {
    const dtEntrega = new Date(parseInt(detailsObjEntrega.dataEntrega, 10))
    fullOrder.tracker.push({
      id: detailsObjEntrega.id,
      tipo: 'entrega',
      status: detailsObjEntrega.statusPedido,
      date: dtEntrega
        .toISOString()
        .substr(0, 10)
        .split('-')
        .reverse()
        .join('/')
    })
  }
  return fullOrder
}
