import {
  activateIdentifier,
  activateEMVACGIdentifier,
  inactiveIdentifier,
  replaceIdentifier,
  newIdentifier,
  setAlias
} from '../services/activationService'

import types from './types'

const {
  ACTIVATION_IDENTIFIER,
  ACTIVATION_EMV_ACG_IDENTIFIER,
  INACTIVE_IDENTIFIER,
  REPLACE_IDENTIFIER,
  NEW_IDENTIFIER,
  SET_ALIAS
} = types

export function fetchActivation(data) {
  return dispatch =>
    activateIdentifier(data).then(activation => {
      dispatch({
        type: ACTIVATION_IDENTIFIER,
        state: null
      })

      return activation
    })
}

export function fetchNFCEMVActivation(data) {
  return dispatch =>
    activateEMVACGIdentifier(data).then(activation => {
      dispatch({
        type: ACTIVATION_EMV_ACG_IDENTIFIER,
        state: null
      })

      return activation
    })
}

export function fetchInactiveIdentifier(data) {
  return dispatch =>
    inactiveIdentifier(data).then(activation => {
      dispatch({
        type: INACTIVE_IDENTIFIER,
        state: null
      })

      return activation
    })
}

export function fetchReplaceIdentifier(data) {
  return dispatch =>
    replaceIdentifier(data).then(activation => {
      dispatch({
        type: REPLACE_IDENTIFIER,
        state: null
      })

      return activation
    })
}

export function fetchNewIdentifier(data) {
  return async dispatch => {
    const identifier = await newIdentifier(data)
    dispatch({
      type: NEW_IDENTIFIER,
      state: null
    })
    return identifier
  }
}

export function fetchSetAlias(data) {
  return dispatch =>
    setAlias(data).then(result => {
      dispatch({
        type: SET_ALIAS,
        state: null
      })
      return result
    })
}
