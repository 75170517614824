import styled from 'styled-components'

import rem from '../../../styles/tools/rem'
import em from '../../../styles/tools/em'
import media from '../../../styles/tools/media'

import {
  txt16OpenSans,
  txt14OpenSans,
  txt12OpenSans,
  title40GalanoBold,
  title28GalanoBold
} from '../../../styles/settings/types'

import {
  darkSlateBlue,
  darkBlue,
  blueGray,
  skyBlue,
  grey,
  white
} from '../../../styles/settings/colors'

export const Title = styled.h1`
  ${title40GalanoBold} letter-spacing: ${em('0.4px')};
  color: ${darkSlateBlue};
  margin-top: ${rem('40px')};
  max-width: 520px;

  ${media.small`
    ${title28GalanoBold};
    letter-spacing: normal;
  `};
`

export const Paragraph = styled.p`
  ${txt14OpenSans} color: ${darkBlue};
  margin-bottom: 60px;
  width: ${rem('270px')};

  ${media.large`
        width: ${rem('435px')};
    `};
`
export const WrapperAddress = styled.div``

export const ActualAddress = styled.div`
  color: ${props => (props.focus ? `${darkSlateBlue}` : `${blueGray}`)};
  ${txt16OpenSans};
  letter-spacing: ${em('0.4px')};
  display: flex;
  margin-bottom: 20px;
  max-width: 520px;
  justify-content: space-between;

  ${media.small`
    flex-direction: column;
  `};
`

export const BoxCep = styled.label`
  flex-grow: 1;
`

export const Cep = styled.div`
  ${txt16OpenSans};
  font-weight: bold;
`

export const CepText = styled.div`
  max-width: 250px;
`

export const BoxShipping = styled.div`
  ${media.small`
    margin: 15px 0 15px 35px;
  `};
`

export const ShippingTitle = styled.div`
  color: ${blueGray};
  ${txt12OpenSans};
  font-weight: bold;
`

export const ShippingText = styled.div`
  color: ${darkSlateBlue};
  ${txt16OpenSans};
  font-weight: bold;
  display: flex;
  align-items: center;
`

export const ShippingIconText = styled.span`
  margin-left: 8px;
`

export const NewAddress = styled.div`
  color: ${props => (props.focus ? `${darkSlateBlue}` : `${blueGray}`)};
  ${txt16OpenSans};
  letter-spacing: ${em('0.4px')};
  display: flex;
  flex-direction: column;
`

export const WrapperSelectNewAddress = styled.div``

export const WrapperFormNewAddress = styled.div`
  margin-top: 30px;
`

export const NewAddressText = styled.label``

export const CheckBoxCep = styled.input`
  margin-right: 15px;
  &:checked,
  &:not(:checked) {
    position: absolute;
    z-index: 99999;
    margin-left: 2px;
    cursor: pointer;
  }
  &:checked + label,
  &:not(:checked) + label {
    position: relative;
    padding-left: 35px;
    line-height: 20px;
    display: inline-block;
  }
  &:checked,
  &:not(:checked) {
    &:before {
      content: '';
      position: absolute;
      left: -2px;
      top: 0;
      width: 20px;
      height: 20px;
      border: 2px solid ${grey};
      border-radius: 100%;
      background: ${white};
    }
  }
  &:checked {
    &:before {
      border: 2px solid ${skyBlue};
    }
  }
  &:checked,
  &:not(:checked) {
    &:after {
      content: '';
      width: 12px;
      height: 12px;
      background: ${skyBlue};
      position: absolute;
      top: 4px;
      left: 2px;
      border-radius: 100%;
      -webkit-transition: all 0.2s ease;
      transition: all 0.2s ease;
    }
  }
  &:not(:checked) {
    &:after {
      opacity: 0;
      -webkit-transform: scale(0);
      transform: scale(0);
    }
  }
  &:checked {
    &:after {
      opacity: 1;
      -webkit-transform: scale(1);
      transform: scale(1);
    }
  }
`

export const ButtonContainer = styled.div`
  margin-top: 40px;

  ${media.small`
    margin-top: 0;
  `};
`
