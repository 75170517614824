import styled, { css } from 'styled-components'

import { linkNavyBlue, lightGrey, darkGrey } from '../../../styles/settings/colors'

import rem from '../../../styles/tools/rem'

export const DropdownLabel = styled.p`
  color: ${linkNavyBlue};
  font-family: 'Galano';
  font-size: ${rem('14px')};
  margin: 0;
  margin-right: ${rem('10px')};
  min-width: ${rem('60px')};
  user-select: none;
`

export const DropdownLabelContainer = styled.div`
  align-items: center;
  display: flex;
  position: relative;
`

export const DropdownArrow = `height: 5px; width: 10px; stroke: ${linkNavyBlue}`

export const HeaderLabel = styled.p`
  color: ${lightGrey};
  font-family: OpenSans;
  font-size: ${rem('10px')};
  letter-spacing: ${rem('0.8px')};
  margin: 0;
  text-transform: uppercase;

  ${props =>
    props.component === 'profile' &&
    `
        font-size: ${rem('12px')};
        letter-spacing: ${rem('1px')};
    `};
`

export const DropdownContainer = styled.div`
  cursor: pointer;
  margin: 0 ${rem('15px')} 0 ${rem('20px')};

  ${({ isOpen }) =>
    isOpen &&
    css`
      ${DropdownLabel} {
        color: ${darkGrey};
      }

      svg {
        color: ${darkGrey};
        transform: rotate(180deg);
      }
    `};
`
