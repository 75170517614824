import React, { Component } from 'react'
import { Box, Flex } from 'grid-styled'
import { ISODateFormat, currencyParser } from 'alelo-logic/utils'
import Hide from '../../../styles/utilities/Hide'
import { nfse } from 'alelo-logic/services/dashboardService'
import Loader from '../Loader'

import {
  ListItem,
  Content,
  Label,
  Title,
  Button,
  LabelButton,
  Modal,
  ModalContainer,
  IconContainer,
  TitleModal,
  ButtonModal
} from './styles'

import { Icon } from '../../elements/Icon'

const labels = require('./data.json')

class RechargeListItem extends Component {
  constructor(props) {
    super(props)

    this.state = {
      openModal: false,
      openLoader: false
    }
  }

  preventDefault = e => {
    e.preventDefault()
  }

  render() {
    const { idSap, date, paymentMethod, type, value } = this.props
    const { openModal, openLoader } = this.state

    const showModal = open => {
      this.setState({ openModal: open })
    }

    const showLoader = show => {
      this.setState({ openLoader: show })
    }

    const openNFSe = idSapOpenNFSe => {
      showLoader(true)
      nfse(idSapOpenNFSe)
        .then(resp => {
          const link = resp.data.notaFiscalDados.dadosNF[0].linkNFSe

          if (link) {
            showLoader(false)
            window.open(link)
            window.dataLayer = window.dataLayer || []
            window.dataLayer.push({
              event: 'event',
              eventCategory: 'veloe:area-logado:historico-recarga',
              eventAction: 'clique:botao:nota-fiscal:callback',
              eventLabel: 'download:sucesso'
            })
          } else {
            showLoader(false)
            showModal(true)
            window.dataLayer = window.dataLayer || []
            window.dataLayer.push({
              event: 'event',
              eventCategory: 'veloe:area-logado:historico-recarga',
              eventAction: 'clique:botao:nota-fiscal:callback',
              eventLabel: 'download:erro:erro-ao-fazer-download'
            })
          }
        })
        .catch(e => {
          showLoader(false)
          showModal(true)
          window.dataLayer = window.dataLayer || []
          window.dataLayer.push({
            event: 'event',
            eventCategory: 'veloe:area-logado:historico-recarga',
            eventAction: 'clique:botao:nota-fiscal:callback',
            eventLabel: 'download:erro:erro-ao-fazer-download'
          })
        })
    }

    return (
      <div>
        {openLoader && <Loader />}
        <Modal isHidden={openModal}>
          <ModalContainer>
            <IconContainer onClick={() => showModal(false)}>
              <Icon width={18} height={18} icon="new-close-blue" viewBox="0 0 52 53" />
            </IconContainer>
            <TitleModal>Ops! Serviço indisponível</TitleModal>
            <div style={{ marginBottom: 50, marginTop: 25 }}>
              <span style={{ fontSize: 16, lineHeight: 1.4, fontFamily: 'OpenSans' }}>
                <p style={{ margin: 0 }}>Não foi possível atender a sua solicitação no momento.</p>
                Por favor, tente novamente mais tarde.
              </span>
            </div>
            <div style={{ float: 'right' }}>
              <ButtonModal onClick={() => showModal(false)}>Ok</ButtonModal>
            </div>
          </ModalContainer>
        </Modal>
        <ListItem id="litRechargeItem">
          <Content>
            <Flex width={1}>
              <Box width={[6 / 12, 1 / 12]} mr={70}>
                <Label name="recharge-item-date-label">{labels.title.date}</Label>
                <Title className="date" name="recharge-item-date">
                  {ISODateFormat(date, 'DD/MM/YYYY')}
                </Title>
              </Box>
              <Hide at="small" width={[1 / 12]} mr={25}>
                <Label name="recharge-item-hour-label">{labels.title.hour}</Label>
                <Title name="recharge-item-hour">{ISODateFormat(date, 'HH:mm')}</Title>
              </Hide>
              <Hide at="small" width={[4 / 12]} mr={12}>
                <Label name="recharge-item-payment-label">{labels.title.payment}</Label>
                <Title>{labels.paymentMethod[paymentMethod]}</Title>
              </Hide>
              <Hide at="small" width={[4 / 12]} mr={10}>
                <Label name="recharge-item-type-label">{labels.title.type}</Label>
                <Title>{labels.type[type]}</Title>
              </Hide>
              <Box width={[3 / 6, 1 / 6]}>
                <Label name="recharge-item-value-label">{labels.title.value}</Label>
                <Title>{currencyParser(value)}</Title>
              </Box>
              <Box width={[3 / 6, 1 / 6]}>
                <Button onClick={() => openNFSe(idSap)}>
                  <LabelButton>Nota fiscal</LabelButton>
                  <Icon
                    icon="download"
                    svgStyle="height: 16px; width: 16px; color: #24206b;"
                    viewBox="0 0 90 90"
                  />
                </Button>
              </Box>
            </Flex>
          </Content>
        </ListItem>
      </div>
    )
  }
}

export default RechargeListItem
