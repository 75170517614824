import React, { Component } from 'react'
import { connect } from 'react-redux'
import { fetchChangeRecharge, fetchPaymentInfo } from 'alelo-logic/actions'

import { Flex, Box } from 'grid-styled'

import EditPaymentInfoCard from '../../modules/EditPaymentInfoCard'
import RechargeBreadcrumbs from '../../modules/RechargeBreadcrumbs'
import rem from '../../../styles/tools/rem'

import { Text, RechargeValue, Cash, CashPrefix, CashValue } from './styles'

const { rechargeValueLabel, currency, disclaimer } = require('./data.json')

class RechargePayment extends Component {
  state = {
    loading: false
  }

  onConfirm = () => {
    this.setState({ loading: true })
    const {
      recharge: { currentRechargeValue },
      history,
      match,
      location: { search }
    } = this.props

    const accountId = this.props.match.params.id
    this.props
      .changeRecharge(accountId, currentRechargeValue)
      .then(() => {
        this.setState({ loading: false })
        history.push(`/minha-veloe/${match.params.id}/editar-dados/sucesso${search}`)
      })
      .catch(e => {
        this.setState({ loading: false })
        console.error(e) // eslint-disable-line
      })
  }

  render() {
    const {
      recharge: { currentRechargeValue, paymentInfo },
      history
    } = this.props
    const { loading } = this.state

    return (
      <section>
        <RechargeBreadcrumbs active={3} />
        <Flex direction={['column', 'row']}>
          <Box mr={[0, `${(1 / 12) * 100}%`]} mb={rem('20px')} width={[1, 4 / 12]}>
            <RechargeValue>
              <Text>{rechargeValueLabel}</Text>
              <Cash>
                <CashPrefix>{currency}</CashPrefix>
                <CashValue id="lblCurrentRechargeValue">{currentRechargeValue}</CashValue>
              </Cash>
            </RechargeValue>
            <Text id="lblDisclaimer">{disclaimer}</Text>
          </Box>
          <Box>
            <EditPaymentInfoCard
              accountPlanId={this.props.match.params.id}
              onConfirm={this.onConfirm}
              history={history}
              cardNumber={paymentInfo.numeroMascarado}
              loading={loading}
            />
          </Box>
        </Flex>
      </section>
    )
  }
}

const mapStateToProps = ({ reducer }) => ({
  accountPlanId: reducer.accountPlanId,
  recharge: reducer.recharge,
  editPaymentData: reducer.editPaymentData
})

const mapDispatchToProps = dispatch => ({
  getPaymentInfo: accountPlanId => fetchPaymentInfo(accountPlanId)(dispatch),
  changeRecharge: (accountId, valorRecarga) =>
    fetchChangeRecharge(accountId, valorRecarga)(dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(RechargePayment)
