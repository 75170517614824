import { queryPlate } from '../services/ecService'

import types from './types'

const { FETCH_EC_QUERY_PLATE } = types

function fetchECQueryPlate(formData) {
  return dispatch =>
    queryPlate(formData)
      .then(result => {
        dispatch({
          type: FETCH_EC_QUERY_PLATE,
          state: result
        })
        return result
      })
      .catch(error => {
        console.log('Erro em #fetchECQueryPlate()')
        console.log(error)
      })
}

export default fetchECQueryPlate
