import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Flex, Box } from 'grid-styled'

import Button from '../../elements/Button'
import RechargeBreadcrumbs from '../../modules/RechargeBreadcrumbs'
import Hide from '../../../styles/utilities/Hide'
import rem from '../../../styles/tools/rem'

import image from '../../../static/recarga.png'

import { Title } from './styles'

const { title } = require('./data.json')

class FirstAccessRechargeSuccess extends Component {
  state = {
    loading: false
  }

  onBack = () => {
    this.props.history.push(`/minha-veloe/${this.props.match.params.id}/resumo`)
  }

  render() {
    return (
      <section>
        <RechargeBreadcrumbs active={4} />
        <Flex direction={['column', 'row']}>
          <Box
            mr={[0, `${(1 / 12) * 100}%`]}
            mt={[rem('35px'), rem('60px')]}
            mb={rem('20px')}
            width={[1, 7 / 12]}
          >
            <Title>{title}</Title>

            <div>
              <Flex direction={['column', 'row']} mt={[rem('30px'), rem('30px')]}>
                <Box mb={[rem('16px'), 0]} mr={[0, rem('10px')]} width={[1, 1 / 2]}>
                  <Button
                    id="btnOk"
                    buttonType="white"
                    onClick={this.onBack}
                    width="100%"
                    text="Ok"
                  />
                </Box>
              </Flex>
            </div>
          </Box>
          <Hide at="small">
            <img alt="recarga" src={image} />
          </Hide>
        </Flex>
      </section>
    )
  }
}

const mapStateToProps = ({ reducer }) => ({
  recharge: reducer.recharge,
  dashboardResume: reducer.dashboardResume,
  actualPlan:
    reducer.accountPlans.plans.find(plan => plan.accountId === reducer.accountPlans.activePlanId) ||
    {}
})

export default connect(mapStateToProps)(FirstAccessRechargeSuccess)
