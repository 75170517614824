import styled from 'styled-components'

import { linkNavyBlue, separatorGrey, white } from '../../../styles/settings/colors'

import rem from '../../../styles/tools/rem'
import media from '../../../styles/tools/media'

import container from '../../../styles/_objects/container'

const blueLink = `
    color: ${linkNavyBlue};
    font-family: 'Galano';
    font-size: ${rem('14px')};
`

export const HeaderWrapper = styled.div`
  ${media.small`
        height: ${rem('70px')};
        background: ${white};
    `};
`

export const HeaderContainer = styled.div`
  ${container};

  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: ${rem('5px')} ${rem('65px')};
  background: ${white};

  ${media.small`
        padding: ${rem('20px')} ${rem('30px')};
        position: fixed;
        height: ${rem('70px')};
        top: 0;
        left: 0;
        right: 0;
        z-index: 50;
    `};
`

const flexStyle = `
    align-items: center;
    display: flex;
    justify-content: space-between;
    position: relative;
`

export const FlexContainer = styled.div`
  ${flexStyle} ${media.small`
        width: 100%;
    `};
`

export const UserInfoContainer = styled.div`
  ${flexStyle} margin-left: ${rem('10px')};
`

export const HelpLink = styled.a`
  cursor: pointer;
  margin: 0 ${rem('35px')};
  text-decoration: none;

  ${blueLink};
`

export const VerticalSpacer = styled.div`
  background-color: ${separatorGrey};
  height: ${rem('77px')};
  opacity: 0.1;
  width: ${rem('2px')};
`

export const Avatar = styled.img`
  height: ${rem('50px')};
  margin: 0 ${rem('5px')} 0 ${rem('15px')};
  width: ${rem('50px')};
`

export const Logo = `
    height: ${rem('30px')};
    fill: ${linkNavyBlue};
    width: ${rem('74px')};
`

export const UserDropdownInfo = styled.div`
  ${flexStyle} padding: ${rem('20px')} 0;
  z-index: 0;

  ${props =>
    props.isOpen &&
    `
        box-shadow: 0 ${rem('8px')} ${rem('8px')} 0 rgba(182, 193, 205, 0.66);
    `};
`
