import styled from 'styled-components'
import { Box } from 'grid-styled'

import rem from 'styles/tools/rem'
import em from 'styles/tools/em'

import {
  txt14OpenSans,
  title40GalanoBold,
  title32GalanoBold,
  title28GalanoBold,
  title24GalanoBold,
  title18Galano
} from '../../../styles/settings/types'

import media from '../../../styles/tools/media'
import { darkSlateBlue, lightGray, paleGray } from '../../../styles/settings/colors'

export const WrapperOrder = styled.div`
  padding: 20px 0 25px;

  :not(:last-child) {
    box-shadow: 0 1px 0 0 ${paleGray};
  }
`

export const Title = styled.h1`
  ${title40GalanoBold} letter-spacing: ${em('0.4px')};
  color: ${darkSlateBlue};
  margin-top: ${rem('40px')};

  ${media.small`
    ${title28GalanoBold}
    letter-spacing: normal;
    line-height: 1;
  `};
`

export const Paragraph = styled.p`
  ${txt14OpenSans} color: ${darkSlateBlue};
  max-width: ${rem('400px')};
`
export const Wrapper = styled(Box)`
  border-top: ${rem('1px')} solid ${lightGray};
`

export const Ticket = styled.h2`
  ${title32GalanoBold} letter-spacing: ${em('0.4px')};
  color: ${darkSlateBlue};

  ${media.small`
    ${title24GalanoBold}
    letter-spacing: normal;
    line-height: 1.17;
  `};
`

export const WrapperInfoLink = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-beetween;
`

export const TextInfoLink = styled.span`
  color: ${darkSlateBlue};
  ${title18Galano};
  line-height: 1.22;
  letter-spacing: normal;
  text-align: left;
`

export const ButtonAddress = styled.a`
  color: ${darkSlateBlue};
  cursor: pointer;
  ${txt14OpenSans};
  display: inline-table;
  line-height: 1.57;
  letter-spacing: normal;
  text-align: left;
  text-decoration: none;

  svg {
    margin-left: 5px;
  }
`
export const WrapperHelpers = styled.div`
  display: flex;
  justify-content: space-between;
`
