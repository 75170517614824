import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { fetchNewPassword, fetchRecoverTokenStatus } from 'alelo-logic/actions'
import ChangePasswordForm from '../../modules/ChangePasswordForm'
import { showModal } from '../../modules/ModalRoot/actions'
import { CONFIRM_UPDATE } from '../../../constants/ModalTypes'
import passwordFactory from './factory'
import Icon from '../../elements/Icon/Icon'

import { Content, Header, Heading, Logo, Paragraph, HeaderContent, Wrapper } from './styles'

import image from '../../../static/confirmation.png'

const { modalTitle, subtitle, title } = require('./data.json')

class FirstAccessCreatePassword extends Component {
  componentWillMount() {
    const params = this.props.match.params || {}
    this.props.fetchStatusToken(params.token)
  }

  render() {
    const token = this.props.match.params.token || {}

    return (
      <div>
        <Header>
          <HeaderContent>
            <Link to={'/conta/login'}>
              <Icon icon="veloe-logo" svgStyle={Logo} width="74" height="31" viewBox="0 0 74 31" />
            </Link>
          </HeaderContent>
        </Header>
        <Content>
          <div>
            <Heading>{title}</Heading>
            <Paragraph>{subtitle}</Paragraph>
            <Wrapper>
              <ChangePasswordForm
                form="createNewPasswordData"
                asyncValidate={null}
                asyncBlurFields={['newPassword']}
                onSubmit={values => this.props.handleSave(passwordFactory.toAPI(values, token))}
              />
            </Wrapper>
          </div>
        </Content>
      </div>
    )
  }
}

const mapStateToProps = ({ reducer }) => ({
  recoverTokenStatus: reducer.recoverTokenStatus
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  handleSave: newPass => {
    dispatch(fetchNewPassword(newPass)).then(
      data => {
        if (data.success) {
          dispatch(
            showModal(CONFIRM_UPDATE, {
              modalTitle: modalTitle,
              onConfirm: () => ownProps.history.push('/conta/login'),
              image
            })
          )
        } else {
          dispatch(
            showModal(CONFIRM_UPDATE, {
              modalTitle: data.message,
              image
            })
          )
        }
      },
      data => {
        dispatch(
          showModal(CONFIRM_UPDATE, {
            modalTitle: data.message,
            image
          })
        )
      }
    )
  },
  fetchStatusToken: token => dispatch(fetchRecoverTokenStatus(token))
})

export default connect(mapStateToProps, mapDispatchToProps)(FirstAccessCreatePassword)
