import moment from 'moment/moment'
import { EMPTY_FIELD, FORMAT_DATE_INVALID, FORMAT_HOUR_MINUTE_INVALID } from './constants'

export default function formatDate(value, format) {
  if (!value) return EMPTY_FIELD
  const betweenMoment = moment(value, format).isBetween('2000-12-31', '2050-12-31')

  return moment(value, format, true).isValid() === false || !betweenMoment
    ? format === 'HH:mm'
      ? FORMAT_HOUR_MINUTE_INVALID
      : FORMAT_DATE_INVALID
    : undefined
}
