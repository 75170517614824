import types from '../actions/types'

const { FETCH_GENERATE_CANCEL_ACCOUNT_TOKEN } = types

function cancelAccountReducer(state = {}, action = {}) {
  switch (action.type) {
    case FETCH_GENERATE_CANCEL_ACCOUNT_TOKEN: {
      return {
        ...state,
        ...action.state,
        generatedToken: true
      }
    }

    default:
      return state
  }
}

export default cancelAccountReducer
