import React from 'react'
import { Field } from 'redux-form'
import { utils } from 'alelo-logic'
import { renderPhoneInput } from '../../../utils/renderInputs'

import { Row, Column, Error, BottomMessage } from './styles'

let prefixInput
let phoneInput

const prefixOnChange = e => {
  if (e.length >= 4) {
    phoneInput.focus()
  }
}

const phoneOnChange = e => {
  if (e.length === 0) {
    prefixInput.focus()
  }
}

const PhoneInput = ({ errorMessage, label, name, isRequired, disabled }) => (
  <div>
    <Row>
      <Column>
        <Field
          component={renderPhoneInput}
          maxlength="2"
          type="tel"
          name={`${name}ddd`}
          ref={component => {
            prefixInput = component
          }}
          onChange={prefixOnChange}
          label={`${label}${isRequired !== undefined ? '*' : ''}`}
          errorMessage={!!errorMessage}
          placeholder="XX"
          disabled={disabled}
        />
      </Column>

      <Column>
        <Field
          component={renderPhoneInput}
          maxlength="10"
          type="tel"
          name={name}
          ref={component => {
            phoneInput = component
          }}
          onChange={phoneOnChange}
          errorMessage={!!errorMessage}
          placeholder="XXXXX-XXXX"
          normalize={utils.phoneMask}
          disabled={disabled}
        />
      </Column>
    </Row>
    {errorMessage && errorMessage.length > 1 && (
      <BottomMessage>
        <Error>{errorMessage}</Error>
      </BottomMessage>
    )}
  </div>
)

export default PhoneInput
