/* eslint-disable */
import { fetchActivation, fetchNFCEMVActivation, fetchSetAlias } from 'alelo-logic/actions'
import Button from 'app/elements/Button'
import ManageInfoModal from 'app/modules/ManageInfoModal'
import { Box, Flex } from 'grid-styled'
import _ from 'lodash'
import React from 'react'
import { connect } from 'react-redux'
import { Field, formValueSelector, reduxForm, reset } from 'redux-form'
import { renderTextInput } from 'utils/renderInputs'
import { getVehiclesCategory } from '../../../alelo-logic/services/activationService'
import { CONFIRM_UPDATE } from '../../../constants/ModalTypes'
import image from '../../../static/confirmation.png'
import imageError from '../../../static/contestacao.png'
import BandImg from '../../../static/semiautomatico/band.png'
import SmartBandImg from '../../../static/semiautomatico/smartband.png'
import TagImg from '../../../static/semiautomatico/tag.png'
import Content from '../../../styles/objects/Content'
import IcBus from '../../elements/Icon/ic-bus'
import IcCar from '../../elements/Icon/ic-car'
import IcTruck from '../../elements/Icon/ic-truck'
import IcUtility from '../../elements/Icon/ic-utility'
import ImersiveHeader from '../../elements/ImersiveHeader'
import Option from '../../elements/Option'
import { startSaving, stopSaving } from '../../modules/form-state-actions'
import { showModal } from '../../modules/ModalRoot/actions'
const {
  labels,
  modalDescriptionError,
  paragraph,
  paragraph2,
  title,
  titleModal,
  titleModalError
} = require('./data.json')
import { AxisField, NickNameField, PlateField } from './form-fields'
import { IdentifierForm } from './forms'
import {
  Container,
  ExtraInfoContainer,
  FormGroup,
  List,
  ListForm,
  Paragraph,
  SubTitle,
  TextInfo,
  Title,
  Vehicles
} from './styles'

const identifiers = {
  TAG: {
    image: TagImg,
    title: 'Adesivo',
    description: '',
    hasVehiclesOptions: true
  },
  BAND: {
    image: BandImg,
    title: 'Pulseira',
    description: 'Pulseira ajustável de silicone resistente a água e com design personalizado.',
    details: [
      'Após a ativação insira o chip no compartimento posicionado na parte traseira da pulseira.',
      'Posicione e afivele a pulseira sobre o pulso.',
      'Na cabine do pedágio, informe que deseja pagar com Veloe.',
      'Após o OK da operadora, encoste a pulseira no leitor para pagar.',
      'Confirmado o pagamento, o comprovante será emitido.'
    ],
    url: 'https://veloe.com.br/mobile-static/nfcpayment/ManualUsuarioPulseira.pdf'
  },
  SMART_BAND: {
    image: SmartBandImg,
    title: 'Relógio',
    description:
      'Além de passar por aproximação em pedágios, monitora frequência cardíaca, conta passos, calorias, distância e mostra notificações dos apps.',
    details: [
      'Após a ativação insira o chip no compartimento posicionado na parte traseira da pulseira.',
      'Posicione e afivele a pulseira sobre o pulso.',
      'Na cabine do pedágio, informe que deseja pagar com Veloe.',
      'Após o OK da operadora, encoste a pulseira no leitor para pagar.',
      'Confirmado o pagamento, o comprovante será emitido.'
    ],
    url: 'https://veloe.com.br/mobile-static/nfcpayment/ManualUsuarioRelogio.pdf'
  }
}

class Activation extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      selectedIdentifier: undefined,
      selected: '',
      axis: [],
      modalIsOpen: false,
      manageInfo: {
        informacao1: null,
        informacao2: null,
        informacao3: null
      }
    }
  }

  setIdentifier = props => {
    if (!props) {
      this.setState({ selectedIdentifier: undefined })
      return false
    }

    const { type = '', ...values } = props
    const identifier = identifiers[type] || {}
    const selectedIdentifier = { ...identifier, type, ...values }

    this.setState({ selectedIdentifier })
  }

  setOption = selected => {
    const { dispatchReset } = this.props

    this.setState({ selected }, () => {
      const { selected } = this.state
      dispatchReset()

      if (selected === 'caminhao' || selected === 'onibus') this.getCategory(selected)
    })

    window.dataLayer.push({
      event: 'event',
      eventAction: 'clique:selecionar-veiculo',
      eventCategory: 'veloe:area-logado:ativar-identificador',
      eventLabel: selected,
      dimension19: selected,
    })
  }

  componentDidMount() {
    this.props.dispatchReset()
  }

  getCategory = selected => {
    getVehiclesCategory(selected).then(response => {
      const optionCategory = response.map(item => {
        return Object.assign(
          {},
          {
            value: item.id,
            label: item.descricao
          }
        )
      })
      this.setState({ axis: optionCategory })
    })
  }

  handleSubmit = values => {
    const { selected, manageInfo, selectedIdentifier = {} } = this.state
    const { identifier = '', type: tipoIdentificador } = selectedIdentifier
    const { accountPlans } = this.props
    const { plans, activePlanId } = accountPlans
    const currentPlan = plans.find(plan => String(plan.id) === String(activePlanId))
    const { accountId, plan = {} } = currentPlan || {}

    const formatValues = Object.assign({ typeCar: selected }, values, {
      accountId: accountId,
      type: plan.type,
      tipoIdentificador,
      identifier
    })

    const data = Object.values(manageInfo).map(item => {
      if (item != null) {
        item.codIdentificador = formatValues.identifier
        item.conta = formatValues.accountId
        item.placa = formatValues.plate

        return item
      }
    })

    this.props.fetchActivation(formatValues, _.compact(data))
  }

  handleSubmitEMVACG = values => {
    const { selectedIdentifier = {} } = this.state
    const { identifier = '', type } = selectedIdentifier

    const { accountPlans } = this.props
    const { plans, activePlanId } = accountPlans
    const currentPlan = plans.find(plan => String(plan.id) === String(activePlanId))
    const { accountId } = currentPlan || {}

    const { cvv } = values

    const { profile } = this.props.user
    const { customerId, nome, cpf } = profile

    this.props.fetchNFCEMVActivation({ customerId, nome, cpf, accountId, cvv, type, identifier })
  }

  toggleModal = () => {
    this.setState({ modalIsOpen: !this.state.modalIsOpen })
  }

  addManageInf = values => {
    const { manageInfo } = this.state

    switch (values.tipoInfo) {
      case 'INFORMACAO_1':
        manageInfo.informacao1 = values
        break

      case 'INFORMACAO_2':
        manageInfo.informacao2 = values
        break

      case 'INFORMACAO_3':
        manageInfo.informacao3 = values
        break
    }

    this.setState({ manageInfo })
    this.toggleModal()
  }

  handleDeletePil = typeInfo => {
    const { manageInfo } = this.state

    switch (typeInfo) {
      case 'INFORMACAO_1':
        manageInfo.informacao1 = ''
        break

      case 'INFORMACAO_2':
        manageInfo.informacao2 = ''
        break

      case 'INFORMACAO_3':
        manageInfo.informacao3 = ''
        break
    }

    this.setState({ manageInfo })
  }

  render() {
    const { selected, axis, modalIsOpen, manageInfo, selectedIdentifier = {} } = this.state
    const {
      hasVehiclesOptions = false,
      type: identifierType = null,
      isEMV_ACG
    } = selectedIdentifier
    const {
      fieldPlate,
      fieldNickname,
      saving,
      accountPlans: { activePlanId },
      change
    } = this.props

    const showAxios = selected === 'caminhao' || selected === 'onibus'
    const urlBack = `/minha-veloe/${activePlanId}/identificadores`
    change('identifierType', identifierType)

    return (
      <Box>
        <div>
          <ImersiveHeader text="Voltar" url={urlBack} />
        </div>

        <Content>
          <Title>{title}</Title>
          <Paragraph>{paragraph}</Paragraph>
        </Content>

        <Content>
          <FormGroup>
            <IdentifierForm
              onSubmit={this.setIdentifier}
              identifier={this.state.selectedIdentifier}
              identifiers={identifiers}
            />
          </FormGroup>
        </Content>

        {identifierType && isEMV_ACG && (
          <div>
            <div>
              <form onSubmit={this.props.handleSubmit(this.handleSubmitEMVACG)}>
                <Container>
                  <Content>
                    <ListForm>
                      <Box width={1} mb={1}>
                        <div>
                          <Field
                            component={renderTextInput}
                            label={labels.identifier.cvv}
                            name="cvv"
                            type="text"
                            isRequired
                          />
                        </div>
                      </Box>
                      <Box width={1} mb={1} style={{ marginBottom: '-20px' }}>
                        <div>
                          <Button text={labels.submitButton} type="submit" width="200px" />
                        </div>
                      </Box>
                    </ListForm>
                  </Content>
                </Container>
              </form>
            </div>
          </div>
        )}

        {identifierType && !isEMV_ACG && (
          <div>
            <div>
              <form onSubmit={this.props.handleSubmit(this.handleSubmit)}>
                <Container>
                  <Content>
                    {hasVehiclesOptions && (
                      <Vehicles>
                        <SubTitle>Selecione o tipo do seu veículo</SubTitle>
                        <List>
                          <Option
                            active={selected === 'carro-passeio'}
                            description="Carro de passeio"
                            icon={<IcCar />}
                            setOption={this.setOption}
                            value="carro-passeio"
                          />
                          <Option
                            active={selected === 'carro-utilitario'}
                            description="Carro utilitário"
                            icon={<IcUtility />}
                            setOption={this.setOption}
                            value="carro-utilitario"
                          />
                          <Option
                            active={selected === 'caminhao'}
                            description="Caminhão"
                            icon={<IcTruck />}
                            setOption={this.setOption}
                            value="caminhao"
                          />
                          <Option
                            active={selected === 'onibus'}
                            description="Ônibus"
                            icon={<IcBus />}
                            setOption={this.setOption}
                            value="onibus"
                          />
                        </List>
                      </Vehicles>
                    )}

                    {selected && (
                      <FormGroup>
                        <SubTitle>{paragraph2}</SubTitle>
                        <TextInfo>
                          Lembre-se que todos os campos são obrigatórios para a ativação
                        </TextInfo>
                        <ListForm>
                          <Box width={1} mb={1}>
                            <PlateField />
                          </Box>
                          {showAxios && (
                            <Box width={1} mb={1}>
                              <AxisField axis={axis} />
                            </Box>
                          )}
                        </ListForm>
                      </FormGroup>
                    )}

                    {(!hasVehiclesOptions || selected) && (
                      <FormGroup>
                        <SubTitle>Como gostaria de chamar seu produto?</SubTitle>
                        <TextInfo>Coloque um apelido para facilitar a identificação</TextInfo>

                        <ListForm>
                          <Box width={1} mb={1}>
                            <NickNameField isRequired={!hasVehiclesOptions} />
                          </Box>
                        </ListForm>
                      </FormGroup>
                    )}
                  </Content>
                </Container>

                <ExtraInfoContainer>
                  <Content>
                    <Flex align="center" justify="flex-start" mt={[0, '10px']}>
                      <Button
                        text={labels.submitButton}
                        type="submit"
                        loading={saving}
                        width="200px"
                        disabled={
                          saving ||
                          (hasVehiclesOptions && !fieldPlate) ||
                          (!hasVehiclesOptions && !fieldNickname)
                        }
                      />
                    </Flex>
                  </Content>
                </ExtraInfoContainer>
              </form>
            </div>
          </div>
        )}
        {modalIsOpen ? (
          <ManageInfoModal
            isOpen={modalIsOpen}
            toggleModal={this.toggleModal}
            modalStart="include"
            match={this.props.match}
            controlled
            handleSubmitExternal={values => this.addManageInf(values)}
          />
        ) : null}
      </Box>
    )
  }
}

const mapStateToProps = state => {
  const selector = formValueSelector('activationForm')

  return {
    fieldPlate: selector(state, 'plate'),
    fieldIdentifier: selector(state, 'identifier'),
    fieldNickname: selector(state, 'nickname'),
    saving: state.reducer.formState.saving,
    accountPlans: state.reducer.accountPlans,
    user: state.reducer.user
  }
}

const mapDispatchToProps = (dispatch, ownProps) => ({
  initFormState: () => dispatch(stopSaving()),
  dispatchReset: () => {
    dispatch(reset('activationForm'))
  },
  fetchNFCEMVActivation: values => {
    dispatch(fetchNFCEMVActivation(values))
      .then(async resp => {})
      .catch(e => {
        dispatch(
          showModal(CONFIRM_UPDATE, {
            modalTitle: titleModalError,
            modalDescription: modalDescriptionError,
            image: imageError,
            hideModal: () => {
              window.dataLayer = window.dataLayer || []
              window.dataLayer.push({
                event: 'event',
                eventAction: 'tentativa:fechar',
                eventCategory: 'veloe:area-logado:ativar-identificador'
              })
            }
          })
        )
        window.dataLayer = window.dataLayer || []
        window.dataLayer.push({
          event: 'event',
          eventAction: 'ativar-tag',
          eventCategory: 'veloe:area-logado:ativar-identificador',
          eventLabel: 'tentativa:erro',
          dimension5: values.identifier
        })
      })
  },
  fetchActivation: (values, manageInfs) => {
    dispatch(startSaving())
    dispatch(fetchActivation(values))
      .then(async resp => {
        if (resp) {
          if (resp.status) {
            /**
              @TODO Tratamento de erros para proxima versao. [autorizado]
              Salva o apelido para o dispositivo
             */
            if ((values || {}).nickname) {
              dispatch(fetchSetAlias(values))
            }

            dispatch(stopSaving())
            ownProps.history.push('/identificadores/ativacao/sucesso')
          } else {
            dispatch(showModal(CONFIRM_UPDATE, { modalTitle: titleModal, image }))
          }
          window.dataLayer = window.dataLayer || []
          window.dataLayer.push({
            event: 'event',
            eventAction: 'ativar-tag',
            eventCategory: 'veloe:area-logado:ativar-identificador',
            eventLabel: 'tentativa:sucesso',
            dimension5: values.identifier
          })
        }
      })
      .catch(e => {
        dispatch(
          showModal(CONFIRM_UPDATE, {
            modalTitle: titleModalError,
            modalDescription: modalDescriptionError,
            image: imageError
          })
        )
        dispatch(stopSaving())
        window.dataLayer = window.dataLayer || []
        window.dataLayer.push({
          event: 'event',
          eventAction: 'ativar-tag',
          eventCategory: 'veloe:area-logado:ativar-identificador',
          eventLabel: 'tentativa:erro',
          dimension5: values.identifier
        })
      })
  }
})

const form = reduxForm({
  form: 'activationForm',
  destroyOnUnmount: false
})(Activation)

export default connect(mapStateToProps, mapDispatchToProps)(form)
