import styled, { css } from 'styled-components'

import { DropdownListContainer, DropdownListItem } from '../../elements/DropdownList/styles'
import { DropdownContainer, HeaderLabel } from '../../elements/Dropdown/styles'

import rem from '../../../styles/tools/rem'

export const Wrapper = styled.div`
  position: relative;
  min-width: ${rem('300px')};

  ${({ isSinglePlan }) =>
    isSinglePlan &&
    css`
      svg {
        display: none;
      }
    `} ${DropdownContainer} {
    position: relative;
    z-index: 21;
  }

  ${DropdownListContainer} {
    top: 0;
    padding-top: ${rem('65px')};
  }

  ${DropdownListItem} {
    margin-bottom: ${rem('32px')};

    :last-child {
      padding-top: ${rem('10px')};
      margin-bottom: 0;
    }
  }

  ${HeaderLabel} {
    font-size: ${rem('10px')};
  }
`
