import React from 'react'
import { Field, reduxForm } from 'redux-form'
import { Flex, Box } from 'grid-styled'
import { connect } from 'react-redux'
import { utils } from 'alelo-logic'
import Button from '../../elements/Button'

import {
  renderTextInput,
  renderTelephoneField,
  renderCheckboxField,
  renderTelephoneTypesField
} from '../../../utils/renderInputs'

import { errorMessage } from '../../../utils/dictionary'

import { Container } from '../EditUserDataForm/styles'

const { labels } = require('./data.json')

const validate = values => {
  const errors = {}

  if (!values.telephoneType) {
    errors.telephoneType = errorMessage.required
  }

  if (!values.telephone) {
    errors.telephone = errorMessage.required
  }

  return errors
}

const EditAdminContactForm = props => {
  const { handleSubmit, saving } = props

  return (
    <form onSubmit={handleSubmit}>
      <Container>
        <Flex wrap width={[1, 7 / 12]}>
          <Box width={1} mb={3}>
            <Field
              component={renderTextInput}
              label={labels.name}
              validate={utils.emptyFieldValidate}
              name="name"
              type="text"
              isRequired
            />
          </Box>
          <Box width={1} mb={3}>
            <Field
              component={renderTextInput}
              label={labels.email}
              validate={utils.emailValidate}
              name="email"
              type="text"
              isRequired
            />
          </Box>
          <Box width={1} mb={3}>
            <Field
              component={renderTextInput}
              label={labels.site}
              validate={utils.emptyFieldValidate}
              name="site"
              type="text"
              isRequired
            />
          </Box>
          <Box width={[1, 1 / 2]} pr={[0, 2]} mb={3}>
            <Field
              component={renderTelephoneField}
              label={labels.telephone}
              name="telephone"
              type="text"
              isRequired
            />
          </Box>
          <Box width={[1, 1 / 6]} pr={[0, 2]} mb={3}>
            <Field component={renderTextInput} label={labels.line} name="line" type="text" />
          </Box>
          <Box width={[1, 1 / 3]} mb={3}>
            <Field
              component={renderTelephoneTypesField}
              label={labels.telephoneType}
              name="telephoneType"
              isRequired
            />
          </Box>
          <Box width={[1, 1 / 2]} pr={[0, 2]} mb={3}>
            <Field
              component={renderTelephoneField}
              label={labels.cellphone}
              name="cellphone"
              type="text"
            />
          </Box>
          <Box width={1} mb={3}>
            <Field
              component={renderCheckboxField}
              label={labels.promotions}
              name="optInPromotions"
            />
          </Box>
        </Flex>
      </Container>
      <Container>
        <Button type="submit" text="Salvar" disabled={saving} />
      </Container>
    </form>
  )
}

const mapStateToProps = state => {
  return {
    saving: state.reducer.formState.saving
  }
}

const form = reduxForm({
  form: 'editAdminContactForm',
  destroyOnUnmount: false,
  validate
})(EditAdminContactForm)

export default connect(mapStateToProps, undefined)(form)
