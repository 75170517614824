import React from 'react'
import { currencyParser } from 'alelo-logic/utils'
import {
  Container,
  Header,
  TotalValue,
  TotalText,
  Table,
  TableHeader,
  TableHeaderCell,
  TableRow,
  TableRowCell,
  SeeAllContainer,
  SeeAll,
  SeeAllIcon
} from './styles'

const { tableConfig, headerTitle, seeLess, seeAll } = require('./data.json')

const CollapsedShowCount = 5

class Contestations extends React.Component {
  state = {
    showAll: false
  }

  render() {
    const { data } = this.props
    const { showAll } = this.state
    const { columns } = tableConfig

    const showData = showAll
      ? { text: seeLess, count: data.length }
      : { text: seeAll, count: CollapsedShowCount }

    return (
      <Container>
        <Header>
          <TotalValue>{data.length}</TotalValue>
          <TotalText>{headerTitle}</TotalText>
        </Header>
        {data.length > 0 && (
          <div>
            <Table>
              <TableHeader>
                {columns.map((column, index) => (
                  <TableHeaderCell key={`contestations-header-${index}`} width={column.width}>
                    {column.header}
                  </TableHeaderCell>
                ))}
              </TableHeader>
              {data.slice(0, showData.count).map((row, index) => (
                <TableRow key={`contestations-body-${index}`}>
                  <TableRowCell width={columns[0].width}>{row.date}</TableRowCell>
                  <TableRowCell width={columns[1].width}>{currencyParser(row.value)}</TableRowCell>
                </TableRow>
              ))}
            </Table>
            <SeeAllContainer onClick={() => this.setState({ showAll: !this.state.showAll })}>
              <SeeAll>{showData.text}</SeeAll>
              <SeeAllIcon icon="right-arrow" width="8" height="13" viewBox="0 0 8 13" />
            </SeeAllContainer>
          </div>
        )}
      </Container>
    )
  }
}

export default Contestations
