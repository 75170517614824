import styled from 'styled-components'
import media from 'styles/tools/media'
import rem from 'styles/tools/rem'
import {
  title32GalanoBold,
  title26GalanoBold,
  title16Galano,
  txt12OpenSans,
  txt14OpenSans
} from 'styles/settings/types'
import {
  paleGrayTwo,
  linkNavyBlue,
  darkBlue,
  quartz,
  butterflyBush,
  informationalLight,
  informationalBlue
} from 'styles/settings/colors'

export const Title = styled.h1`
  ${title32GalanoBold} color: ${linkNavyBlue};
  margin: ${rem('40px')} 0 ${rem('30px')} 0;

  ${media.large`
        ${title32GalanoBold}
    `};
`

export const Subtitle = styled.div`
  color: ${darkBlue};
  ${title26GalanoBold} font-weight: bold;
  margin: 12px 0 36px 0;
`

export const Legend = styled.div`
  color: ${darkBlue};
  ${txt14OpenSans};
  letter-spacing: normal;
  margin-top: 62px;
`

export const Buttons = styled.div`
  display: flex;
  flex-direction: column;

  ${media.large`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
  `};
`

export const Separator = styled.hr`
  border: 1px solid ${paleGrayTwo};
  margin-left: -20px;
  width: calc(100% + 36px);
`

export const Item = styled.div`
  display: flex;
  flex-direction: row;
  width: 223px;
  cursor: pointer;
  align-items: center;
  box-shadow: 0 12px 27px 0 rgba(221, 231, 243, 0.66);
`

export const ItemDescription = styled.div`
  flex-direction: column;
  flex: 0.85;
  padding: 22px 14px;
`

export const CardTitle = styled.div`
  ${title16Galano};
  font-weight: 500;
  color: #14110c;
`

export const CardSubtitle = styled.div`
  ${txt12OpenSans};
  color: #656769;
`

export const SelectCreditCardWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`

export const CardFlagList = styled.div`
  padding: 8px 0 0 12px;
`

export const CardFlagItem = styled.span`
  ${props => (props.hideCard ? `display: none` : 'display: inline')};
  margin-right: 8px;
`

export const Notice = styled.div`
  ${txt12OpenSans};
  display: flex;
  background-color: ${quartz};
  color: ${butterflyBush};
  padding: 15px 25px 15px 0;
  border-radius: 12px;
  align-items: center;
  margin: 10px 0;
  & > svg {
    margin: 0 12px;
  }
`

export const NoticeText = styled.p`
  width: 390px;
  font-weight: 600;
  line-height: 1.57;
`

export const FormField = styled.fieldset`
  border: 0;
  padding: 0;
  margin: 0;
  min-width: 0;

  ${({ disabled }) =>
    disabled &&
    `
  opacity: 0.4;
  cursor: not-allowed
  `};
`
export const DebitCardNotice = styled.div`
  ${txt12OpenSans};
  display: auto;
  background-color: ${informationalLight};
  color: ${informationalBlue};
  padding: 20px;
  border-radius: 12px;
  align-items: center;
  width: 100%;
  img {
    padding-right: 13px;
  }
`

export const Banner = styled.div`
  margin-bottom: 715px;
`
