import types from '../actions/types'

const { FETCH_BANK_LIST } = types

function banksReducer(state = [], action = {}) {
  switch (action.type) {
    case FETCH_BANK_LIST: {
      return action.state
    }

    default:
      return state
  }
}

export default banksReducer
