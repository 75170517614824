import styled from 'styled-components'

import { darkGrey, linkNavyBlue, offWhite } from '../../../styles/settings/colors'
import media from '../../../styles/tools/media'
import rem from '../../../styles/tools/rem'
import { title16Galano, title18Galano } from '../../../styles/settings/types'

export const Container = styled.p`
  margin: 0;
`

export const Name = styled.span`
  color: ${darkGrey};
  display: block;
  font-family: OpenSans;
  font-size: ${rem('12px')};
  font-weight: 200;
  line-height: 1;
  margin-bottom: ${rem('4px')};

  ${media.large`
        line-height: 1.9;
        margin-bottom: 0;
    `}
    :before {
    background-color: ${props => props.color || offWhite};
    border-radius: ${rem('64px')};
    content: '';
    display: block;
    height: ${rem('8px')};
    margin-bottom: ${rem('5px')};
    width: ${rem('24px')};

    ${media.large`
            margin-bottom: 0;
        `};
  }
`

export const Value = styled.span`
  ${title16Galano} color: ${linkNavyBlue};
  letter-spacing: ${rem('1.2px')};
  line-height: 1;

  ${media.large`
        ${title18Galano}
        letter-spacing: ${rem('1px')};
    `};
`
