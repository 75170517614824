import { reduxForm, formValueSelector } from 'redux-form'
import { connect } from 'react-redux'
import { fetchIdentifierType } from 'alelo-logic/actions'
import { showModal } from '../../../../modules/ModalRoot/actions'
import { CONFIRM_UPDATE } from '../../../../../constants/ModalTypes'
import imageError from '../../../../../static/contestacao.png'
import IdenfitierForm from '../components/IdentifierForm'

const Form = reduxForm({
  form: 'identifierForm',
  destroyOnUnmount: false
})(IdenfitierForm)

const mapStateToProps = state => {
  const selector = formValueSelector('identifierForm')

  return {
    fieldIdentifier: selector(state, 'identifier')
  }
}

const mapDispatchToProps = dispatch => ({
  fetchIdentifierType: (values = {}, onSubmit) => {
    const { identifier = '' } = values

    dispatch(fetchIdentifierType({ identifier }))
      .then(resp => {
        const { identificadorSerial: { tipoIdentificador: type } = {} } = resp || {}
        onSubmit({ type, isEMV_ACG: type === 'EMV_ACG', ...values })
      })
      .catch(e => {
        dispatch(
          showModal(CONFIRM_UPDATE, {
            modalTitle: 'Erro ao ativar seu produto',
            modalDescription: 'Por favor, tente novamente.',
            image: imageError
          })
        )
      })
  }
})

export default connect(mapStateToProps, mapDispatchToProps)(Form)
