export default {
  __html: `<g id="Styleguide" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                <g id="Iconografia" transform="translate(-1547.000000, -3151.000000)">
                    <g id="IC_close" transform="translate(1547.000000, 3151.000000)">
                        <path fill="#c5c3d8" d="M71.6504854,74.1747573 C70.8737864,74.1747573 70.0970874,73.8834951 69.5145631,73.3009709 L26.3106796,30.1941748 C25.1456311,29.0291262 25.1456311,27.184466 26.3106796,26.0194175 C27.4757282,24.8543689 29.3203883,24.8543689 30.4854369,26.0194175 L73.6893204,69.1262136 C74.8543689,70.2912621 74.8543689,72.1359223 73.6893204,73.3009709 C73.2038835,73.8834951 72.4271845,74.1747573 71.6504854,74.1747573 Z" id="Shape" fill="#201A5B" fill-rule="nonzero" transform="translate(50.000000, 49.660194) scale(-1, 1) translate(-50.000000, -49.660194) "></path>
                        <path fill="#c5c3d8" d="M34.0291262,43.0582524 C29.0776699,43.0582524 25,38.9805825 25,34.0291262 C25,29.0776699 29.0776699,25 34.0291262,25 C38.9805825,25 43.0582524,29.0776699 43.0582524,34.0291262 C42.961165,38.9805825 38.9805825,43.0582524 34.0291262,43.0582524 Z M34.0291262,30.9223301 C32.3786408,30.9223301 30.9223301,32.2815534 30.9223301,34.0291262 C30.9223301,35.776699 32.2815534,37.1359223 34.0291262,37.1359223 C35.776699,37.1359223 37.1359223,35.776699 37.1359223,34.0291262 C37.1359223,32.2815534 35.6796117,30.9223301 34.0291262,30.9223301 Z" id="Shape" fill="#201A5B" fill-rule="nonzero" transform="translate(34.029126, 34.029126) scale(-1, 1) translate(-34.029126, -34.029126) "></path>
                        <path fill="#c5c3d8" d="M66.0291262,74.0582524 C61.0776699,74.0582524 57,69.9805825 57,65.0291262 C57,60.0776699 61.0776699,56 66.0291262,56 C70.9805825,56 75.0582524,60.0776699 75.0582524,65.0291262 C74.961165,69.9805825 70.9805825,74.0582524 66.0291262,74.0582524 Z M66.0291262,61.9223301 C64.3786408,61.9223301 62.9223301,63.2815534 62.9223301,65.0291262 C62.9223301,66.776699 64.2815534,68.1359223 66.0291262,68.1359223 C67.776699,68.1359223 69.1359223,66.776699 69.1359223,65.0291262 C69.1359223,63.2815534 67.6796117,61.9223301 66.0291262,61.9223301 Z" id="Shape" fill="#201A5B" fill-rule="nonzero" transform="translate(66.029126, 65.029126) scale(-1, 1) translate(-66.029126, -65.029126) "></path>
                        <rect id="Rectangle-path" x="0.776699029" y="0.388349515" width="99.0291262" height="99.0291262"></rect>
                    </g>
                </g>
            </g>`
}
