import { getStatement } from '../services/dashboardService'

import types from './types'

const { UPDATE_STATEMENT } = types

export default function fetchStatement(planId, statementId) {
  return dispatch =>
    getStatement(planId, statementId).then(statement => {
      dispatch({
        type: UPDATE_STATEMENT,
        statement
      })
    })
}
