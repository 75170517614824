const addressFactory = {
  toAPI: address =>
    address &&
    address.map((data, index) => ({
      cep: data.zipCode,
      logradouro: data.address,
      numero: data.number,
      complemento: data.additionalInfo,
      bairro: data.neighborhood,
      uf: data.state,
      cidade: data.city,
      correspondencia: data.mail,
      principal: data.principal,
      codigo: data.key
    })),
  toForm: address =>
    address &&
    address.map(
      (data, index) =>
        address && {
          zipCode: data.cep,
          address: data.logradouro,
          number: data.numero,
          additionalInfo: data.complemento,
          neighborhood: data.bairro,
          state: data.uf,
          city: data.cidade,
          mail: data.correspondencia,
          principal: data.principal,
          key: data.codigo
        }
    )
}

export default addressFactory
