import styled from 'styled-components'

import content from 'styles/_objects/content'
import rem from 'styles/tools/rem'
import media from 'styles/tools/media'

export const Content = styled.div`
  ${content};
  margin: ${rem('42px')} auto;
  ${media.small`
      margin: ${rem('29px')} auto;
  `};
`
