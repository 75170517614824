import { merge } from 'lodash'
import types from '../actions/types'

const { UPDATE_INVOICE_DETAILS, RESET_INVOICE_DETAILS } = types

const initialState = {
  details: {}
}

function invoiceDetailsReducer(state = initialState, action = {}) {
  switch (action.type) {
    case UPDATE_INVOICE_DETAILS: {
      return {
        ...state,
        details: merge({ ...state.details, ...action.details })
      }
    }
    case RESET_INVOICE_DETAILS: {
      return {
        ...state,
        details: {}
      }
    }
    default:
      return state
  }
}

export default invoiceDetailsReducer
