import React, { Component, cloneElement } from 'react'
import { string, func } from 'prop-types'
import { Transition } from 'react-transition-group'
import { Icon } from '../../elements/Icon'

import BannerInfoPaymentApp from '../../modules/BannerInfoPayment'

import { Close, Content, ModalSimpleContainer, Wrapper } from './styles'

const Fade = ({ isOpen, children }) => (
  <Transition in={isOpen} timeout={400}>
    {state => cloneElement(children, { state })}
  </Transition>
)

class Modal extends Component {
  state = { isOpen: true }

  close = () => {
    this.setState({ isOpen: !this.state.isOpen })
  }

  render() {
    const { id, backButton, maxWidth } = this.props

    return (
      <Fade isOpen={this.state.isOpen}>
        <Wrapper>
          <Fade isOpen={this.state.isOpen}>
            <Content maxWidth={maxWidth}>
              <BannerInfoPaymentApp />
              <Close to={`/minha-veloe/${id}/${backButton}`}>
                <Icon icon="modal-close" height="1.125rem" width="1.125rem" viewBox="0 0 21 21" />
              </Close>
            </Content>
          </Fade>
        </Wrapper>
      </Fade>
    )
  }
}

export class SimpleModal extends Component {
  state = { isOpen: true }

  close = () => {
    this.setState({ isOpen: !this.state.isOpen })
    console.log(this.state.isOpen)
  }

  render() {
    const { isOpen } = this.state
    const { children } = this.props
    return (
      <Fade isOpen={isOpen}>
        <Wrapper>
          <Fade isOpen={isOpen}>
            <Content maxWidth={400}>
              <ModalSimpleContainer>{children}</ModalSimpleContainer>
            </Content>
          </Fade>
        </Wrapper>
      </Fade>
    )
  }
}

Modal.defaultProps = {
  title: null,
  onConfirm: null,
  hideModal: null
}

Modal.propTypes = {
  title: string,
  onConfirm: func,
  hideModal: func
}

export default Modal
