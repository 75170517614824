import React from 'react'
import { connect } from 'react-redux'
import { hideModal } from '../ModalRoot/actions'
import Modal from '../../elements/WhiteModal'

const PaymentConfirmation = props => {
  const handleConfirm = () => {
    if (props.onConfirm) {
      return props.onConfirm()
    }
  }

  return (
    <Modal
      onSuccess={props.onSuccess}
      description={props.modalDescription}
      image={props.image}
      hideModal={props.hideModal}
      nextPaymentData={props.changePaymentTo}
      currentPaymentType={props.currentPaymentType}
      onConfirm={() => handleConfirm()}
    />
  )
}

export default connect(null, { hideModal })(PaymentConfirmation)
