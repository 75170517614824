import React, { Component } from 'react'
import { connect } from 'react-redux'
import {
  setNewDataValueWithoutRecharge,
  fetchPaymentInfo,
  fetchPlanData,
  setActualPlan,
  fetchCreateRecharge
} from 'alelo-logic/actions'

import { Flex, Box } from 'grid-styled'

import EditPaymentForm from '../../modules/EditPaymentForm'
import RechargeBreadcrumbs from '../../modules/RechargeBreadcrumbs'
import rem from '../../../styles/tools/rem'

import { showModal } from '../../modules/ModalRoot/actions'
import image from '../../../static/confirmation.png'
import { CONFIRM_UPDATE } from '../../../constants/ModalTypes'
import Modal from '../../elements/ModalBannerInfoPaymentApp/Modal'

import {
  Text,
  RechargeValue,
  Cash,
  CashPrefix,
  CashValue,
  TitlePayment,
  BoxPayment
} from './styles'

import {
  getBrasPagAccessToken,
  getVindiAccessToken,
  postBraspagCard,
  postBraspagVerifyCard
} from '../../../alelo-logic/services/rechargeService'

import { validateCreditCardFlag } from 'app/modules/EditPaymentForm/validators'

const { rechargeValueLabel, currency, titleModalError, valueLabel } = require('./data.json')

const getPaymentCompany = flag =>
  flag === 'master' ? 'masterCard' : flag === 'amex' ? 'american_express' : flag

class RechargePayment extends Component {
  constructor(props) {
    super(props)
    this.state = {
      maskedNumber: '',
      loading: false,
      valorRecarga: ''
    }
  }

  async componentDidMount() {
    await this.props.getPaymentInfo(this.props.match.params.id)
    const plan = this.props.accountPlans.plans.find(this.applyFilter)

    if (this.props.location.search.includes('path=track')) {
      await this.props.fetchPlanData(plan).then(() => {
        this.setState({ valorRecarga: this.props.planData.recharge })
      })
    } else {
      this.setState({ valorRecarga: this.props.recharge.currentRechargeValue })
    }
  }

  applyFilter = checkPlan =>
    checkPlan.id.toString() === this.props.accountPlans.activePlanId.toString()

  resolverTipoCompra = () => {
    return window.location.href.includes('/empresa') ? 'compra-pj' : 'compra'
  }

  checkCreditCardFlag = creditCardNumber => {
    const creditCardFlag = validateCreditCardFlag(creditCardNumber)
    if (creditCardFlag) {
      return creditCardFlag
    }
  }

  getVerifyCardResponse = async values => {
    try {
      const cardFlag = this.checkCreditCardFlag(values.cardNumber)
      const res = await postBraspagVerifyCard({
        CardNumber: values.cardNumber.replace(/\s/g, ''),
        Holder: this.formatCardHolderName(values.yourName),
        ExpirationDate: values.validad.replace('/', '/20'),
        SecurityCode: values.securityCode,
        Brand: cardFlag,
        Type: cardFlag === 'elo' ? 'DebitCard' : 'CreditCard'
      })

      return res.data
    } catch (error) {
      console.log(error)
    }
  }

  getPaymentToken = async values => {
    var paymentToken
    await getBrasPagAccessToken()
      .then(async response => {
        const resp = await postBraspagCard(
          this.formatCardHolderName(values.yourName),
          values.cardNumber.replace(/\s/g, ''),
          values.validad,
          values.securityCode,
          response.data
        )
        paymentToken = resp.data
      })
      .catch(error => console.error(error))
    return paymentToken
  }

  getVindiToken = async (values, userData) => {
    const flag = this.checkCreditCardFlag(values.cardNumber)
    return await getVindiAccessToken({
      holder: this.formatCardHolderName(values.yourName),
      registry: userData.profile.cpf,
      cardCvv: values.securityCode,
      cardExpiration: values.validad,
      cardNumber: values.cardNumber,
      paymentCompany: getPaymentCompany(flag)
    })
  }

  formatCardHolderName = name => {
    return name
      .toUpperCase()
      .replace(new RegExp('[ÁÀÂÃ]', 'gi'), 'A')
      .replace(new RegExp('[ÉÈÊ]', 'gi'), 'E')
      .replace(new RegExp('[ÍÌÎ]', 'gi'), 'I')
      .replace(new RegExp('[ÓÒÔÕ]', 'gi'), 'O')
      .replace(new RegExp('[ÚÙÛ]', 'gi'), 'U')
      .replace(new RegExp('[Ç]', 'gi'), 'C')
  }

  cardErrorModalButtonClick = () => {
    this.setState({ showCardErrorModal: false })
  }

  onConfirm = async (evt, values) => {
    const {
      location: { search },
      userData
    } = this.props
    const customerId = this.props.match.params.id
    this.setState({ loading: true })

    if (
      (values && values.paymentMethod === 'Adicionar um novo cartão') ||
      location.search.includes('path=track') ||
      location.search.includes('path=recharge')
    ) {
      const vindiToken = await this.getVindiToken(values, userData)
      const { PaymentToken: paymentToken } = await this.getPaymentToken(values)

      if (!paymentToken) {
        this.setState({ loading: false })
        this.props.showErrorModal()
      } else {
        const data = {
          dados: {
            conta: customerId,
            numeroMascarado: (
              values.cardNumber.substring(0, 7) +
              'XX-XXXX-' +
              values.cardNumber.substring(15)
            ).replace(/\s/g, '-'),
            validade: values.validad.replace('/', '-'),
            bandeira: this.checkCreditCardFlag(values.cardNumber.replace(/\s/g, '')),
            alias: 'EDICAO FORMA PAGAMENTO',
            nome: this.formatCardHolderName(values.yourName),
            cpf: userData.profile.cpf,
            paymentToken: paymentToken,
            cvv: values.securityCode,
            // valorRecarga: this.state.valorRecarga,
            tipoPagamento: values.tipoPagamento,
            gateways: [{ id: 'VINDI', token: vindiToken }]
          }
        }

        try {
          await this.props.handleSave(data)
          window.dataLayer = window.dataLayer || []
          window.dataLayer.push({
            event: 'event',
            eventCategory: 'veloe:area-logado:dados-plano',
            eventAction: 'clique:botao:confirmar:credito',
            eventLabel: 'pre:alteracao:[[sucesso]]'
          })
          const newPaymentInfo = await this.props.getPaymentInfo(customerId)
          this.setState({ loading: false })
          if (newPaymentInfo && newPaymentInfo.length > 0) {
            const { numeroMascarado, nome } = newPaymentInfo
            if (numeroMascarado !== data.dados.numeroMascarado || nome !== data.dados.nome)
              throw new Error('Dados do cartão não alterados corretamente!')
          }

          const redirectUrl = `/minha-veloe/${customerId}/`
          if (search.includes('path=recharge') || search.includes('path=track')) {
            this.props.history.push(redirectUrl + `editar-dados/sucesso${search}`)
          } else if (location.pathname.includes('primeira-recarga')) {
            this.props.history.push(redirectUrl + 'primeira-recarga/sucesso')
          } else {
            this.props.history.push(redirectUrl + `editar-dados/sucesso-automatico?path=edit`)
          }
        } catch (e) {
          console.error(e)
          const graphErr = e.graphQLErrors && e.graphQLErrors.length > 0 && e.graphQLErrors[0]
          if (graphErr && graphErr.message) {
            this.props.showErrorModal(graphErr.message)
          } else {
            this.props.showErrorModal()
          }
          this.setState({ loading: false })
        }
      }
    } else {
      await this.props.getPaymentInfo(this.props.match.params.id)
      this.setState({ loading: false })
      if (search.includes('path=recharge')) {
        this.props.history.push(
          `/minha-veloe/${this.props.match.params.id}/editar-dados/sucesso${search}`
        )
      } else {
        this.props.history.push(
          `/minha-veloe/${this.props.match.params.id}/editar-dados/sucesso-automatico`
        )
      }
    }
  }

  formatCurrencyRecharge = value => {
    if (!isNaN(value)) {
      return parseFloat(value)
    }
  }

  doNotSubmit() {
    return false
  }

  render() {
    const {
      match: {
        params: { id }
      },
      location,
      location: { search }
    } = this.props

    const backButton = location.search.includes('path=recharge')
      ? 'recarga/pagamento'
      : location.pathname.includes('primeira-recarga')
      ? 'primeira-recarga'
      : 'editar-dados'

    return (
      <section>
        <RechargeBreadcrumbs active={2} />
        <Flex direction={['column', 'row']} justify="space-between">
          <Box mr={[0, `${(1 / 12) * 100}%`]} mb={rem('20px')} width={[1, 4 / 12]}>
            <RechargeValue>
              {search.includes('path=recharge') ? (
                <Text>{valueLabel}</Text>
              ) : (
                <Text>{rechargeValueLabel}</Text>
              )}

              <Cash>
                <CashPrefix>{currency}</CashPrefix>
                <CashValue id="lblCurrentRechargeValue" bigValue={this.state.valorRecarga > 1000}>
                  {this.state.valorRecarga}
                </CashValue>
              </Cash>
            </RechargeValue>
          </Box>
          <BoxPayment width={[1, 6 / 12]}>
            {search.includes('path=recharge') || search.includes('path=track') ? (
              <TitlePayment>Inserir um novo cartão para sua recarga?</TitlePayment>
            ) : location.pathname.includes('primeira-recarga') ? (
              <TitlePayment>Insira um cartão para sua recarga</TitlePayment>
            ) : (
              <TitlePayment>Inserir um novo cartão para sua recarga automática?</TitlePayment>
            )}
            <EditPaymentForm
              paymentInfo
              loading={this.state.loading}
              onSubmit={this.doNotSubmit}
              id={id}
              location={location}
              backButton={backButton}
            />
          </BoxPayment>
        </Flex>
        <Modal id={id} backButton={backButton} />
      </section>
    )
  }
}

const mapStateToProps = ({ reducer }) => {
  return {
    recharge: reducer.recharge,
    account: reducer.account,
    activePlanId: reducer.accountPlans.activePlanId,
    accountPlans: reducer.accountPlans,
    planData: reducer.planData,
    userData: reducer.user
  }
}

const mapDispatchToProps = dispatch => ({
  getPaymentInfo: accountPlanId => fetchPaymentInfo(accountPlanId)(dispatch),
  handleSave: data => dispatch(setNewDataValueWithoutRecharge(data)),
  setActualPlan: actualPlan => dispatch(setActualPlan(actualPlan)),
  showErrorModal: (bodyText = '') =>
    dispatch(
      showModal(CONFIRM_UPDATE, {
        modalTitle: titleModalError,
        modalDescription: window.dataLayer.push({
          event: 'event',
          eventCategory: 'veloe:area-logado:dados-plano',
          eventAction: 'clique:botao:confirmar:credito',
          eventLabel: 'pre:alteracao:[[erro:erro-ao-dados]]'
        }),
        bodyText,
        image
      })
    ),
  fetchPlanData: plano => dispatch(fetchPlanData(plano)),
  createRecharge: recharge => dispatch(fetchCreateRecharge(recharge))
})

export default connect(mapStateToProps, mapDispatchToProps)(RechargePayment)
