import styled from 'styled-components'
import { Box } from 'grid-styled'

import { white } from '../../../styles/settings/colors'

import media from '../../../styles/tools/media'
import rem from '../../../styles/tools/rem'

export const TokenModalContainer = styled.div`
  .feedback-image {
    display: none;
  }
`

export const Title = styled.h2`
  color: ${white};
  font-family: Galano;
  font-size: ${rem('24px')};
  font-weight: bold;
  line-height: 1.1;
  margin: 0 0 ${rem('20px')};

  ${media.large`
        font-size: ${rem('40px')};
        max-width: ${rem('345px')};
    `};
`

export const Description = styled.p`
  color: ${white};
  font-family: OpenSans;
  font-size: ${rem('14px')};
  font-weight: 200;
  margin-bottom: ${rem('40px')};

  ${media.large`
        margin-bottom: ${rem('36px')};
        max-width: ${rem('245px')};
    `};
`

export const InputContainer = styled(Box)`
  position: relative;
`

export const Resend = styled.div`
  cursor: pointer;
  height: ${rem('16px')};
  position: absolute;
  right: ${rem('14px')};
  top: ${rem('30px')};
  width: ${rem('16px')};
  z-index: 1;
`
