import TemplateECDashboard from '../../app/templates/ec/TemplateECDashboard'
import DashboardResume from '../../app/pages/ec/DashboardResume'

export default {
  path: '/minha-veloe/:id',
  component: TemplateECDashboard,
  routes: [
    {
      exact: true,
      path: '/minha-veloe/:id/resumo/',
      component: DashboardResume
    }
  ]
}
