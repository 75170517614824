import React from 'react'

import { Wrap, Icon, Description } from './styles'
// import Mark from 'app/components/Icons/Mark'

const Option = ({ active, value, setOption, icon, description, children, notGray, ...props }) => (
  <Wrap active={active} onClick={() => setOption(value)} {...props}>
    <Icon active={active} notGray={notGray}>
      {icon}
    </Icon>
    <Description active={active}>{description}</Description>
  </Wrap>
)

export default Option
