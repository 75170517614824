import styled from 'styled-components'
import { darkGrey, borderGrey, white } from '../../../styles/settings/colors'
import { txt14OpenSans } from '../../../styles/settings/types'
import rem from '../../../styles/tools/rem'

import imgLoading from '../../../static/loading.gif'

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;

  ${props =>
    props.width &&
    `
        width: ${rem(props.width)};
    `};
`

export const ItemIcon = styled.a`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${rem('40px')};
  height: ${rem('40px')};
  border: 1px solid ${borderGrey};
  border-radius: ${rem('80px')};
  margin-right: ${rem('15px')};
  margin-bottom: ${rem('10px')};
  background-color: ${white};
  box-shadow: 0 0 0 10px ${white};
  z-index: 11;
  cursor: pointer;

  img {
    width: ${rem('22px')};
    height: auto;
  }
`

export const Label = styled.span`
  ${txt14OpenSans};
  margin: 0;
  color: ${darkGrey};
  font-size: ${rem('12px')};
  font-weight: 100;
  text-transform: uppercase;
  letter-spacing: ${rem('1px')};
  margin-bottom: ${rem('10px')};
  margin-right: ${rem('15px')};
`

export const LabelLoading = styled.span`
  cursor: not-allowed;
  background-image: url(${imgLoading});
  background-repeat: no-repeat;
  background-position: left;
  background-size: 18px;
  ${txt14OpenSans};
  color: ${darkGrey};
  font-size: ${rem('12px')};
  font-weight: 100;
  text-transform: uppercase;
  padding-left: 24px;
`
