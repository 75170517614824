import styled from 'styled-components'
import media from 'styles/tools/media'
import content from 'styles/_objects/content'
import rem from 'styles/tools/rem'

import { title20GalanoBold, txt14OpenSans } from 'styles/settings/types'
import { lightestBlue, darkGrey, nearWhite } from 'styles/settings/colors'

export const Content = styled.div`
  ${content};
  display: flex;
`

export const Item = styled.div`
  ${media.large`
    width: 25%;
  `};
`

export const Label = styled.label`
  color: ${darkGrey};
  font-family: OpenSans;
  font-size: ${rem('12px')};
  font-weight: 200;
  letter-spacing: ${rem('0.4px')};
  line-height: 1;
  pointer-events: none;
  text-transform: uppercase;
  white-space: nowrap;
  display: block;
  margin-bottom: ${rem('15px')};
`

export const Title = styled.p`
  ${title20GalanoBold};

  letter-spacing: ${rem('0.3px')};
  margin: 0;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
`

export const Text = styled.p`
  ${txt14OpenSans};
  font-weight: 500;
  color: ${darkGrey};
  line-height: ${rem('1.2px')};
`

export const StatusContainer = styled.div`
  display: flex;
  align-items: flex-start;
  height: 100%;

  ${media.small`
        margin-top: ${rem('20px')};
    `};
`

export const Button = styled.button`
  padding: 16px 32px;
  width: 174px;
  height: 52px;
  border: 2px solid #24206b;
  border-radius: 28px;

  display: flex;
  align-items: center;
  justify-content: center;
`

export const LabelButton = styled.span`
  color: #24206b;
  font-size: 14px;
  margin-right: 9px;
  font-family: 'Galano';
`

export const ListItem = styled.li`
  position: relative;
  padding: ${rem('30px')} 0 ${rem('23px')} 0;
  border-bottom: ${rem('1px')} solid ${lightestBlue};
  color: ${darkGrey};
  justify-content: center;

  :hover {
    cursor: pointer;
    background-color: ${nearWhite};
  }

  .download-button {
    position: absolute;
    top: ${rem('30px')};
    right: ${rem('30px')};
    display: none;
  }

  ${media.small`
        ${props =>
          props.first &&
          `
            ${Title} {
                font-size: ${rem('36px')};
            }
        `}
    `};
`

export const Modal = styled.div`
  background: rgb(0, 0, 0, 0.4);
  position: fixed;
  width: 100%;
  top: 0;
  height: 100vh;
  z-index: 12;
  display: ${props => (props.isHidden ? 'flex' : 'none')};
  justify-content: center;
  align-items: center;
`

export const ModalContainer = styled.div`
  position: relative;
  background: white;
  padding: 48px;
  box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.12);
  border-radius: 8px;
  color: #24206b;
`

export const IconContainer = styled.div`
  position: absolute;
  right: 20px;
  top: 20px;
  width: 18px;
  height: 18px;
`

export const TitleModal = styled.span`
  font-size: 32px;
  font-weight: bold;
  font-family: Galano;
`

export const ButtonModal = styled.button`
  padding-top: 16px;
  padding-bottom: 16px;
  padding-right: 32px;
  padding-left: 32px;
  background: #24206b;
  border: none;
  color: white;
  font-size: 16px;
  border-radius: 28px;
`
