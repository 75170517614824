import types from '../actions/types'

const { RECOVER } = types

function recoverReducer(state = {}, action = {}) {
  switch (action.type) {
    case RECOVER: {
      return {
        ...state,
        ...action.recover
      }
    }
    default:
      return state
  }
}

export default recoverReducer
