import styled from 'styled-components'
import { lighterGrey, darkGrey, linkNavyBlue } from '../../../styles/settings/colors'
import { txt12OpenSans } from '../../../styles/settings/types'
import media from '../../../styles/tools/media'
import content from '../../../styles/_objects/content'
import rem from '../../../styles/tools/rem'

export const StatementHeader = styled.div`
  border-bottom: ${rem('1px')} solid ${lighterGrey};
`

export const IconColor = `fill: ${linkNavyBlue}`

export const Content = styled.section`
  ${content};
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  ${media.large`
        height: ${rem('49px')};
    `} ${media.small`
        min-height: ${rem('49px')};
    `};
`

export const HeaderText = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  flex-direction: column;

  .month {
    font-size: ${rem('14px')};
    font-weight: bold;
    margin-right: ${rem('60px')};
    margin-bottom: ${rem('5px')};

    span {
      display: none;
    }
  }

  ${media.large`
        align-items: center;
        flex-direction: row;

        .month {
            font-size: ${rem('12px')};
            margin-bottom: 0;

            span {
                display: inline-block;
            }
        }
    `} ${media.small`
        margin: ${rem('10px')} 0;
    `};
`

export const Text = styled.p`
  ${txt12OpenSans};
  margin: 0;
  color: ${darkGrey};

  ${media.large`
        margin: 0 ${rem('5px')} 0 0;
    `};
`

export const NavHeader = styled.div`
  display: flex;
  align-items: center;

  .print {
    display: none;
  }

  ${media.large`
        .print {
            display: block;
        }
    `};
`

export const NavIcon = styled.a`
  display: inline-block;
  width: ${rem('31px')};
  height: ${rem('31px')};
  cursor: pointer;

  img {
    width: 100%;
    height: auto;
  }

  :not(:first-child) {
    margin-left: 20px;
  }
`
