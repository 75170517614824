import React from 'react'
import { connect } from 'react-redux'
import {
  Label,
  Text,
  StatusContainer,
  StatusContent,
  IconContainer,
  ButtonContainer
} from '../InvoiceListItem/styles'
import Button from 'app/elements/Button'
import { formatMethod } from '../../../utils/paymentMethods'
import { Icon } from '../Icon'

/**
 * Return label based on status
 * @param {string} status
 */
const formatLabel = status => {
  switch (status) {
    case 'PENDENTE':
      return 'Fatura fechada'
    case 'AGENDADO':
      return 'Fatura fechada'
    case 'NAO_AUTORIZADO':
      return 'Fatura fechada'
    case 'NAO_COMPENSADO':
      return 'Fatura fechada'
    case 'CANCELADO':
      return 'Fatura fechada'
    case 'COMPENSADO':
      return 'Fatura paga'
    case 'AUTORIZADO':
      return 'Fatura paga'
    case 'ATRASO':
      return 'Fatura em atraso'
    default:
      return 'Fatura atual'
  }
}

const InvoiceStatus = props => {
  const { status, situacao, method, nsu, requestNewBoleto, userData } = props
  return (
    <StatusContainer>
      <IconContainer>
        <Icon width="62" height="50" icon={status} viewBox="0 0 102 102" />
      </IconContainer>
      <StatusContent>
        <Label>{formatLabel(status)}</Label>
        <Text status={status}>{formatMethod(status, method)}</Text>
      </StatusContent>

      {userData && userData.profile && situacao === 'ATRASADA' && (
        <ButtonContainer>
          <Button
            width="112px"
            lineHeight="32px"
            fontSize="14.5px"
            padding="2px"
            onClick={() => requestNewBoleto(`${userData.profile.customerId}`, nsu)}
            text="Solicitar Boleto"
          />
        </ButtonContainer>
      )}
    </StatusContainer>
  )
}

const mapStateToProps = ({ reducer }) => ({
  userData: reducer.user
})

export default connect(mapStateToProps, null)(InvoiceStatus)
