import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Flex, Box } from 'grid-styled'

import Button from '../../elements/Button'
import RechargeBreadcrumbs from '../../modules/RechargeBreadcrumbs'
import Hide from '../../../styles/utilities/Hide'
import rem from '../../../styles/tools/rem'

import image from '../../../static/recarga.png'

import { Title, Text, TextSmall } from './styles'

const { title, titleEdit, awaitUpdate } = require('./data.json')

class EditPaymentSuccess extends Component {
  state = {
    loading: false
  }

  minor = () => {
    const { actualPlan } = this.props
    return parseFloat(actualPlan.ammount) < parseFloat(actualPlan.plan.rechargeAmount)
  }

  onBack = () => {
    const {
      location: { search }
    } = this.props

    if (search.includes('path=recharge')) {
      if (this.minor()) {
        this.props.history.push(`/minha-veloe/${this.props.match.params.id}/resumo`)
      } else {
        this.props.history.push(
          `/minha-veloe/${this.props.match.params.id}/recarga/confirmacao?path=recharge`
        )
      }
    } else {
      this.props.history.push(`/minha-veloe/${this.props.match.params.id}/dados-do-plano`)
    }
  }

  onStatement = () => {
    this.props.history.push(`/minha-veloe/${this.props.match.params.id}/historico-de-recarga`)
  }

  isEditCreditCard = () => {
    const {
      location: { search }
    } = this.props

    return search.includes('?path=edit')
  }

  render() {
    const {
      location: { search }
    } = this.props
    return (
      <section>
        <RechargeBreadcrumbs active={4} />
        <Flex direction={['column', 'row']}>
          <Box
            mr={[0, `${(1 / 12) * 100}%`]}
            mt={[rem('35px'), rem('60px')]}
            mb={rem('20px')}
            width={[1, 7 / 12]}
          >
            {this.isEditCreditCard() ? <Title>{titleEdit}</Title> : <Title>{title}</Title>}

            {this.minor() ? (
              <div>
                <Text>{search.split('msgrecarga=')[1]}</Text>

                <TextSmall>{awaitUpdate}</TextSmall>

                <Flex direction={['column', 'row']} mt={[rem('30px'), rem('30px')]}>
                  <Box mb={[rem('16px'), 0]} mr={[0, rem('10px')]} width={[1, 1 / 2]}>
                    <Button
                      id="btnOk"
                      buttonType="white"
                      onClick={this.onBack}
                      width="50%"
                      text="Voltar"
                    />
                  </Box>
                  <Box ml={[0, rem('10px')]} width={[1, 1 / 2]}>
                    <Button
                      id="btnNewRecharge"
                      buttonType="transparent"
                      onClick={this.onStatement}
                      width="100%"
                      text="Consultar suas recargas"
                    />
                  </Box>
                </Flex>
              </div>
            ) : (
              <div>
                <Flex direction={['column', 'row']} mt={[rem('30px'), rem('30px')]}>
                  <Box mb={[rem('16px'), 0]} mr={[0, rem('10px')]} width={[1, 1 / 2]}>
                    <Button
                      id="btnOk"
                      buttonType="white"
                      onClick={this.onBack}
                      width="100%"
                      text="Ok"
                    />
                  </Box>
                </Flex>
              </div>
            )}
          </Box>
          <Hide at="small">
            <img alt="recarga" src={image} />
          </Hide>
        </Flex>
      </section>
    )
  }
}

const mapStateToProps = ({ reducer }) => ({
  recharge: reducer.recharge,
  dashboardResume: reducer.dashboardResume,
  actualPlan:
    reducer.accountPlans.plans.find(plan => plan.accountId === reducer.accountPlans.activePlanId) ||
    {}
})

export default connect(mapStateToProps)(EditPaymentSuccess)
