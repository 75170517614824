import gql from 'graphql-tag'

export const gqlGetAddress = gql`
  {
    enderecos {
      cep
      uf
      cidade
      bairro
      logradouro
      numero
      complemento
      correspondencia
      codigo
    }
  }
`

export const gqlDeleteAddress = gql`
  mutation excluirEndereco($id: Int) {
    excluirEndereco(id: $id)
  }
`

export const gqlAddAddress = gql`
  mutation inserirEndereco($enderecos: [EnderecoInput]) {
    inserirEndereco(enderecos: $enderecos) {
      cep
      uf
      cidade
      bairro
      logradouro
      numero
      complemento
      correspondencia
      codigo
    }
  }
`

export const gqlUpdateAddress = gql`
  mutation editarEndereco($enderecos: [EnderecoInput]) {
    editarEndereco(enderecos: $enderecos) {
      cep
      uf
      cidade
      bairro
      logradouro
      numero
      complemento
      correspondencia
      codigo
    }
  }
`

export const gqlSearchAddress = gql`
  query cep($cep: String!) {
    cep(cep: $cep) {
      logradouro
      bairro
      cidade
      uf
      cep
    }
  }
`

export const gqlGetStates = gql`
  query {
    estados {
      id
      descricao
      uf
    }
  }
`

export const gqlGetCitiesFromState = gql`
  query cidades($estado: String!) {
    cidades(estado: $estado) {
      id
      descricao
      abreviacao
    }
  }
`
