const placeDots = string => {
  let iteration = -2
  const arrayWithDots = []

  for (let i = string.length - 1; i > -1; i -= 1) {
    if (iteration % 3 === 0 && i !== 0) {
      arrayWithDots.push(`.${string[i]}`)
    } else {
      arrayWithDots.push(string[i])
    }

    iteration += 1
  }

  return arrayWithDots.reverse().join('')
}

const currencyParser = (value, isAbsolute, prefix = '') => {
  let stringValue = new String(value) // eslint-disable-line
  let integers = []
  let decimals = ''

  const sign = /-.+/.test(stringValue) ? '-' : ''

  stringValue = stringValue
    .replace(/-/g, '')
    .replace(/,/g, '.')
    .replace(prefix, '')

  if (isAbsolute) {
    const absoluteValues = stringValue.replace(/[^\d]/g, '')
    decimals = absoluteValues.substr(-2, 2)

    integers = placeDots(absoluteValues.slice(0, -2))

    return `${`${prefix + sign + integers},${decimals}`}`
  }

  const splitedValues = stringValue.split('.')

  decimals = splitedValues.length > 1 ? splitedValues.pop() : '00'
  const integerValues = splitedValues.join('')

  integers = placeDots(integerValues)

  if (decimals.length < 2) {
    decimals = `${decimals}0`
  } else if (decimals.length > 2) {
    decimals = decimals.substring(0, 2)
  }

  return `${prefix + sign + integers},${decimals}`
}

export default currencyParser
