import styled from 'styled-components'
import { txt16OpenSans } from 'styles/settings/types'
import rem from 'styles/tools/rem'
import { blueGray, grey, darkBlue } from 'styles/settings/colors'

export const SearchField = styled.input`
  ${txt16OpenSans};
  border: none;
  color: ${darkBlue};
  width: 100%;
  &::-webkit-input-placeholder {
    color: ${blueGray};
  }
  &:focus {
    outline: none;
  }
`

export const Form = styled.form`
  width: 53%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: ${rem('8px')} ${rem('15px')};
  border: 1px solid ${grey};
  border-radius: ${rem('30px')};
  transition: all 0.3s;
  &:focus-within {
    border-color: ${darkBlue};
  }
`

export const SearchButton = styled.button`
  border: none;
  &:focus {
    outline: none;
  }
  &:active {
    transform: translateX(10px);
  }
`

export const SearchImg = styled.img`
  width: auto;
  height: auto;
`
