import styled from 'styled-components'
import {
  darkBlue,
  darkSlateBlue,
  darkGrey,
  blueGray,
  grey,
  white,
  skyBlue,
  solitude,
  linkNavyBlue
} from '../../../styles/settings/colors'
import media from 'styles/tools/media'
import { txt14OpenSans, txt16OpenSans, txt18OpenSans } from '../../../styles/settings/types'

// import content from '../../../styles/_objects/content'
import rem from '../../../styles/tools/rem'

import container from '../../../styles/_objects/container'

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  ${container};
`

export const WrapperButtons = styled.div`
  display: flex;
  justify-content: start;
  min-width: 400px;
  & > div {
    margin: 0 0;
  }
`

export const WrapperRadios = styled.div`
  display: flex;
  justify-content: flex-start;
  min-width: 200px;
  & > div {
    margin: 0 0;
  }
`

export const WrapperAddress = styled.div``

export const CepText = styled.div`
  ${txt16OpenSans};
  color: ${darkGrey};
  width: 100%;
  padding-left: 30px;
  cursor: pointer;
`

export const NewAddress = styled.div`
  color: ${props => (props.focus ? `${darkSlateBlue}` : `${blueGray}`)};
  ${txt16OpenSans};
  letter-spacing: 0.4px;
  display: flex;
  flex-direction: column;
`

export const WrapperSelectNewAddress = styled.div``

export const CheckBoxCep = styled.input`
  margin-right: 15px;
  &:checked,
  &:not(:checked) {
    position: absolute;
    z-index: 99999;
    margin-left: 2px;
    cursor: pointer;
  }
  &:checked + label,
  &:not(:checked) + label {
    position: relative;
    padding-left: 35px;
    line-height: 20px;
    display: inline-block;
  }
  &:checked,
  &:not(:checked) {
    &:before {
      content: '';
      position: absolute;
      left: -2px;
      top: 0;
      width: 20px;
      height: 20px;
      border: 2px solid ${grey};
      border-radius: 100%;
      background: ${white};
    }
  }
  &:checked {
    &:before {
      border: 2px solid ${skyBlue};
    }
  }
  &:checked,
  &:not(:checked) {
    &:after {
      content: '';
      width: 12px;
      height: 12px;
      background: ${skyBlue};
      position: absolute;
      top: 4px;
      left: 2px;
      border-radius: 100%;
      -webkit-transition: all 0.2s ease;
      transition: all 0.2s ease;
    }
  }
  &:not(:checked) {
    &:after {
      opacity: 0;
      -webkit-transform: scale(0);
      transform: scale(0);
    }
  }
  &:checked {
    &:after {
      opacity: 1;
      -webkit-transform: scale(1);
      transform: scale(1);
    }
  }
`

export const WrapperFormNewAddress = styled.div`
  margin-top: 30px;

  form > div > div > div {
    width: 100%;
  }
`

export const NewAddressText = styled.label``

export const BoxCep = styled.label`
  flex-grow: 1;
`

export const Row = styled.div`
  margin: 5px 0;
  display: inline-block;
  width: 100%;
`
export const Subtitle = styled.p`
  ${txt16OpenSans};

  color: ${darkBlue};
  font-weight: bold;
  line-height: 1;
  margin: 0 0 ${rem('10px')};
`

export const BoxIdentifier = styled.div`
  align-items: center;
  display: flex;
`

export const ButtonIdentifier = styled.a`
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')}!important;
  background: ${({ disabled }) => (disabled ? solitude : skyBlue)};
  margin-left: ${({ marginLeft }) => (marginLeft ? `${marginLeft}px` : `31px`)};
  margin-right: ${({ marginRight }) => (marginRight ? `${marginRight}px` : `31px`)};
  max-height: 60px;
  width: ${({ width }) => (width ? `${width}px` : `36px`)};
  height: ${({ height }) => (height ? `${height}px` : `36px`)};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 24px;
  font-size: 32px;
  color: ${white};
  user-select: none;

  &:hover,
  &:active,
  &:link {
    text-decoration: none;
  }

  ${media.small`
    margin-left: 0;
    margin-right: 0;
  `};

  ${media.small`
    margin-left: 0;
    margin-right: 0;
  `};
`

export const IdentifiersText = styled.p`
  ${txt18OpenSans};
  color: ${linkNavyBlue};
  font-weight: 800;
  line-height: 1.44;
  ${media.small`
    margin: 0 ${rem('20px')} 0;
    text-align: center;
  `};
`

export const Paragraph = styled.p`
  ${txt14OpenSans} color: ${darkBlue};
  margin-top: ${rem('7px')};
`
