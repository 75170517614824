/* eslint-disable */

import validate from 'validate.js/validate.js'
import {
  VALIDATION_CNPJ,
  VALIDATION_CNPJ_MSG_EMPTY,
  VALIDATION_CNPJ_MSG_INVALID
} from './constants'

function checkCNPJ(cnpj = '') {
  cnpj = cnpj.replace(/[^\d]+/g, '')

  if (cnpj === '' || cnpj.length != 14) return false

  let size = cnpj.length - 2
  let numbers = cnpj.substring(0, size)
  const digits = cnpj.substring(size)
  let sum = 0
  let pos = size - 7

  for (let i = size; i >= 1; i--) {
    sum += numbers.charAt(size - i) * pos--
    if (pos < 2) pos = 9
  }

  let result = sum % 11 < 2 ? 0 : 11 - (sum % 11)

  if (result != digits.charAt(0)) return false

  size += 1
  numbers = cnpj.substring(0, size)
  sum = 0
  pos = size - 7

  for (let i = size; i >= 1; i--) {
    sum += numbers.charAt(size - i) * pos--
    if (pos < 2) pos = 9
  }

  result = sum % 11 < 2 ? 0 : 11 - (sum % 11)

  if (result != digits.charAt(1)) return false

  return true
}

export default function validateCNPJ(value) {
  if (checkCNPJ(value)) return undefined
  return VALIDATION_CNPJ_MSG_INVALID
}
