import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import { fetchECAccountPlans, setActivePlanId } from 'alelo-logic/actions'

import Header from '../../../modules/Header'
import Submenu from '../../../elements/Submenu'
import Footer from '../../../elements/Footer'
import PageLoader from '../../../modules/PageLoader'

import { RouteWithSubRoutes } from '../../../../routes'

const { subnavLinks } = require('./data.json')

class TemplateECDashboard extends Component {
  componentWillMount() {
    this.props
      .fetchAccountPlans()
      .then(() => this.props.setActivePlanId(this.props.match.params.id))
  }

  shouldComponentUpdate(nextProps) {
    const hasChange =
      // eslint-disable-next-line
      nextProps.accountPlans.activePlanId != nextProps.match.params.id ||
      nextProps.location.pathname !== this.props.location.pathname

    if (hasChange) {
      this.props.setActivePlanId(this.props.match.params.id)
    }
    return hasChange
  }

  componentDidUpdate() {
    this.props.setActivePlanId(this.props.match.params.id)
  }

  render() {
    const { routes, match } = this.props

    const subnavLinksWithPlanId = subnavLinks.map(subnavLink => ({
      ...subnavLink,
      link: subnavLink.link.replace(':id', match.params.id)
    }))

    return (
      <div>
        <div className="empty-template">
          <Header />
          <Submenu navItems={subnavLinksWithPlanId} />
        </div>
        <PageLoader>
          <div>
            {routes.map(route => (
              <RouteWithSubRoutes key={route.path} {...route} />
            ))}
          </div>
        </PageLoader>
        <div className="empty-template">
          <Footer />
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  account: state.reducer.account,
  accountPlans: state.reducer.accountPlans.ecPlans
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  fetchAccountPlans: () =>
    dispatch(fetchECAccountPlans()).then(accountPlans => {
      const { history, match } = ownProps

      if (accountPlans && (match.params.id === '' || match.params.id === 'resumo')) {
        const firstPlanId = accountPlans && accountPlans[0] && accountPlans[0].id
        history.push(`/minha-veloe/${firstPlanId}/resumo`)
      }
    }),
  setActivePlanId: planId => dispatch(setActivePlanId(planId))
})

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(TemplateECDashboard))
