'use strict'

import { verifyIdentifier } from '../services/activationService'

function fetchIdentifierType(data) {
  return dispatch =>
    verifyIdentifier(data).then(resp => {
      return resp
    })
}

export default fetchIdentifierType
