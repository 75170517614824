import moment from 'moment/moment'
import { FORMAT_TIME, FORMAT_TIME_INVALID } from './constants'

export default function timeFormat(momentObject) {
  if (!momentObject) {
    throw new Error(FORMAT_TIME_INVALID)
  }

  return moment(momentObject).format(FORMAT_TIME)
}
