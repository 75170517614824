export default {
  __html: `
    <g fill="none" fill-rule="evenodd" opacity="0.858150653">
        <g id="07.6.1_Fatura_Pos_Pago_Simples_Detalhe" transform="translate(-877.000000, -2309.000000)" stroke="#C5C3D8" stroke-width="1.8">
            <g id="Infos" transform="translate(0.000000, 2255.000000)">
                <g id="Trama-veloe-Copy" transform="translate(1066.000000, 172.500000) rotate(-270.000000) translate(-1066.000000, -172.500000) translate(949.000000, -16.000000)">
                    <g id="Group-8" transform="translate(-0.000000, 0.000000)">
                        <g id="Group-2" transform="translate(0.000000, 0.000000)">
                            <path d="M11,18.5566253 C11,11.6760789 13.7267556,5.21253521 18.5510156,0.834005634 C19.1802669,0.208501408 19.8095182,0 20.4387695,0 C21.2777712,0 21.6972721,0.625504225 21.9070225,0.834005634 L25.4727799,5.42103662 C25.6825303,5.62953803 26.1020312,6.25504225 26.1020312,7.08904788 C26.1020312,7.71455211 25.8922807,8.34005634 25.2630294,8.96556056 C22.7460242,11.4675775 21.4875216,14.8036 21.4875216,18.5566253 C21.4875216,22.3096507 22.7460242,25.8541746 25.2630294,28.1476901 C25.8922807,28.7731944 26.1020312,29.1901972 26.1020312,30.0242028 C26.1020312,30.8582084 25.6825303,31.4837127 25.4727799,31.6922141 L21.9070225,36.2792451 C21.9070225,36.4877465 21.2777712,37.1132507 20.4387695,37.1132507 C19.8095182,37.1132507 19.1802669,36.9047493 18.5510156,36.2792451 C13.7267556,31.6922141 11,25.4371718 11,18.5566253" id="Shape"></path>
                            <path d="M81.1737808,12.3095901 C86.2077912,7.30555631 92.5003042,4.80353941 99.212318,5.01204082 C100.05132,5.01204082 100.680571,5.22054223 101.100072,5.84604645 C101.519573,6.47155068 101.519573,7.30555631 101.519573,7.51405772 L100.680571,13.1435957 C100.680571,13.3520972 100.680571,14.1861028 100.05132,14.811607 C99.6318189,15.2286098 99.0025676,15.4371112 98.1635659,15.4371112 C94.807559,15.4371112 91.2418016,16.8966211 88.7247964,19.6071394 C85.9980408,22.3176577 84.5297878,25.6536802 84.5297878,28.9897028 C84.5297878,29.8237084 84.3200373,30.4492126 83.9005365,30.8662155 C83.2712852,31.4917197 82.4322834,31.4917197 82.222533,31.4917197 L76.5592713,32.1172239 C76.3495209,32.1172239 75.5105192,32.3257253 74.8812679,31.7002211 C74.461767,31.2832183 74.0422661,30.657714 74.0422661,29.8237084 C73.6227653,23.5686662 76.3495209,17.3136239 81.1737808,12.3095901" id="Shape"></path>
                            <path d="M23.1696016,80.925456 C28.203612,85.9294898 30.7206172,92.184532 30.5108667,98.8565771 C30.5108667,99.6905827 30.3011163,100.316087 29.671865,100.73309 C29.0426137,101.358594 28.203612,101.150093 27.9938616,101.150093 L22.3305999,100.524588 C22.1208494,100.524588 21.2818477,100.524588 20.6525964,99.8990841 C20.2330955,99.4820813 20.0233451,98.8565771 20.0233451,98.0225714 C20.0233451,94.6865489 18.3453417,91.142025 15.8283365,88.6400081 C13.1015808,85.9294898 9.74557391,84.4699799 6.38956699,84.4699799 C5.55056526,84.4699799 4.92131396,84.2614785 4.5018131,83.8444757 C3.8725618,83.2189715 3.8725618,82.3849658 3.66281137,82.1764644 L3.03356007,76.5469264 C3.03356007,76.338425 2.82380964,75.5044193 3.45306093,74.8789151 C3.8725618,74.4619123 4.5018131,74.0449095 5.34081483,74.0449095 C11.8430782,73.6279067 18.1355912,76.1299236 23.1696016,80.925456" id="Shape"></path>
                            <path d="M79,86.5566253 C79,79.6760789 81.7267556,73.2125352 86.5510156,68.8340056 C87.1802669,68.2085014 87.8095182,68 88.4387695,68 C89.2777712,68 89.6972721,68.6255042 89.9070225,68.8340056 L93.4727799,73.4210366 C93.6825303,73.629538 94.1020312,74.2550423 94.1020312,75.0890479 C94.1020312,75.7145521 93.8922807,76.3400563 93.2630294,76.9655606 C90.9557747,79.4675775 89.4875216,82.8036 89.4875216,86.5566253 C89.4875216,90.3096507 90.7460242,93.8541746 93.2630294,96.1476901 C93.8922807,96.7731944 94.1020312,97.1901972 94.1020312,98.0242028 C94.1020312,98.8582084 93.6825303,99.4837127 93.4727799,99.6922141 L89.9070225,104.279245 C89.6972721,104.487746 89.2777712,105.113251 88.4387695,105.113251 C87.8095182,105.113251 87.1802669,104.904749 86.5510156,104.279245 C81.7267556,99.6922141 79,93.4371718 79,86.5566253" id="Shape"></path>
                            <path d="M18.4580381,281 C25.3798024,281 31.8820658,283.710518 36.2868249,288.506051 C36.9160761,289.131555 37.1258266,289.757059 37.1258266,290.382563 C37.1258266,291.216569 36.4965753,291.633572 36.2868249,291.842073 L31.8820658,295.386597 C31.6723153,295.595099 31.043064,296.012101 30.2040623,296.012101 C29.574811,296.012101 28.9455597,295.8036 28.3163084,295.178096 C25.7993032,292.88458 22.4432963,291.42507 18.6677885,291.42507 C14.8922807,291.42507 11.3265234,292.676079 9.0192686,295.178096 C8.39001731,295.8036 7.97051644,296.012101 7.13151471,296.012101 C6.29251298,296.012101 5.66326168,295.595099 5.45351125,295.386597 L0.839001731,291.842073 C0.629251298,291.842073 7.10542736e-14,291.216569 7.10542736e-14,290.382563 C7.10542736e-14,289.757059 0.209750433,289.131555 0.839001731,288.506051 C5.03401038,283.710518 11.3265234,281 18.4580381,281" id="Shape"></path>
                            <path d="M86.6677885,212 C93.5895528,212 100.091816,214.710518 104.496575,219.506051 C105.125827,220.131555 105.335577,220.757059 105.335577,221.382563 C105.335577,222.216569 104.496575,222.633572 104.496575,222.842073 L99.8820658,226.386597 C99.6723153,226.595099 99.043064,227.012101 98.2040623,227.012101 C97.574811,227.012101 96.9455597,226.8036 96.3163084,226.178096 C93.7993032,223.88458 90.4432963,222.42507 86.6677885,222.42507 C82.8922807,222.42507 79.3265234,223.676079 77.0192686,226.178096 C76.3900173,226.8036 75.760766,227.012101 75.1315147,227.012101 C74.292513,227.012101 73.6632617,226.595099 73.4535112,226.386597 L68.8390017,222.842073 C68.6292513,222.842073 68,222.216569 68,221.382563 C68,220.757059 68.2097504,220.131555 68.8390017,219.506051 C73.4535112,214.710518 79.7460242,212 86.6677885,212" id="Shape"></path>
                            <path d="M94.1020312,288.556625 C94.1020312,295.437172 91.3752755,301.900715 86.5510156,306.279245 C85.9217643,306.904749 85.292513,307.113251 84.6632617,307.113251 C83.82426,307.113251 83.4047591,306.487746 83.1950087,306.279245 L79.6292513,301.692214 C79.4195009,301.483713 79,300.858208 79,300.024203 C79,299.398699 79.2097504,298.773194 79.8390017,298.14769 C82.1462565,295.645673 83.6145095,292.309651 83.6145095,288.556625 C83.6145095,284.8036 82.3560069,281.259076 79.8390017,278.965561 C79.2097504,278.340056 79,277.923054 79,277.089048 C79,276.255042 79.4195009,275.629538 79.6292513,275.421037 L83.1950087,270.834006 C83.4047591,270.625504 83.82426,270 84.6632617,270 C85.292513,270 85.9217643,270.208501 86.5510156,270.834006 C91.3752755,275.212535 94.1020312,281.467577 94.1020312,288.556625" id="Shape"></path>
                            <path d="M26.1020312,219.556625 C26.1020312,226.437172 23.3752755,232.900715 18.5510156,237.279245 C17.9217643,237.904749 17.292513,238.113251 16.6632617,238.113251 C15.82426,238.113251 15.4047591,237.487746 15.1950087,237.279245 L11.6292513,232.692214 C11.4195009,232.483713 11,231.858208 11,231.024203 C11,230.398699 11.2097504,229.773194 11.8390017,229.14769 C14.1462565,226.645673 15.6145095,223.309651 15.6145095,219.556625 C15.6145095,215.8036 14.3560069,212.259076 11.8390017,209.965561 C11.2097504,209.340056 11,208.714552 11,208.089048 C11,207.255042 11.4195009,206.629538 11.6292513,206.421037 L15.1950087,201.834006 C15.1950087,201.625504 15.82426,201 16.6632617,201 C17.292513,201 17.9217643,201.208501 18.5510156,201.834006 C23.585026,206.212535 26.1020312,212.467577 26.1020312,219.556625" id="Shape"></path>
                            <path d="M23.1696016,145.969567 C28.203612,150.973601 30.7206172,157.228643 30.5108667,163.900688 C30.5108667,164.734694 30.3011163,165.360198 29.671865,165.777201 C29.0426137,166.402705 28.203612,166.194204 27.9938616,166.194204 L22.3305999,165.568699 C22.1208494,165.568699 21.2818477,165.568699 20.6525964,164.943195 C20.2330955,164.526192 20.0233451,163.900688 20.0233451,163.066682 C20.0233451,159.73066 18.3453417,156.186136 15.8283365,153.684119 C13.1015808,150.973601 9.74557391,149.514091 6.38956699,149.514091 C5.55056526,149.514091 4.92131396,149.30559 4.5018131,148.888587 C3.8725618,148.263082 3.8725618,147.429077 3.66281137,147.220575 L3.03356007,141.591037 C3.03356007,141.382536 2.82380964,140.54853 3.45306093,139.923026 C3.8725618,139.506023 4.5018131,139.089021 5.34081483,139.089021 C11.8430782,138.463516 18.1355912,141.174035 23.1696016,145.969567" id="Shape"></path>
                            <path d="M79,151.556625 C79,144.676079 81.7267556,138.212535 86.5510156,133.834006 C87.1802669,133.208501 87.8095182,133 88.4387695,133 C89.2777712,133 89.6972721,133.625504 89.9070225,133.834006 L93.4727799,138.421037 C93.6825303,138.629538 94.1020312,139.255042 94.1020312,140.089048 C94.1020312,140.714552 93.8922807,141.340056 93.2630294,141.965561 C90.9557747,144.467577 89.4875216,147.8036 89.4875216,151.556625 C89.4875216,155.309651 90.7460242,158.854175 93.2630294,161.14769 C93.8922807,161.773194 94.1020312,162.190197 94.1020312,163.024203 C94.1020312,163.858208 93.6825303,164.483713 93.4727799,164.692214 L89.9070225,169.279245 C89.6972721,169.487746 89.2777712,170.113251 88.4387695,170.113251 C87.8095182,170.113251 87.1802669,169.904749 86.5510156,169.279245 C81.7267556,164.692214 79,158.437172 79,151.556625" id="Shape"></path>
                            <path d="M23.1696016,351.969567 C28.203612,356.973601 30.7206172,363.228643 30.5108667,369.900688 C30.5108667,370.734694 30.3011163,371.360198 29.671865,371.777201 C29.0426137,372.402705 28.203612,372.194204 27.9938616,372.194204 L22.3305999,371.568699 C22.1208494,371.568699 21.2818477,371.568699 20.6525964,370.943195 C20.2330955,370.526192 20.0233451,369.900688 20.0233451,369.066682 C20.0233451,365.73066 18.3453417,362.186136 15.8283365,359.684119 C13.1015808,356.973601 9.74557391,355.514091 6.38956699,355.514091 C5.55056526,355.514091 4.92131396,355.30559 4.5018131,354.888587 C3.8725618,354.263082 3.8725618,353.429077 3.66281137,353.220575 L3.03356007,347.591037 C3.03356007,347.382536 2.82380964,346.54853 3.45306093,345.923026 C3.8725618,345.506023 4.5018131,345.089021 5.34081483,345.089021 C11.8430782,344.463516 18.1355912,347.174035 23.1696016,351.969567" id="Shape"></path>
                            <path d="M79,357.556625 C79,350.676079 81.7267556,344.212535 86.5510156,339.834006 C87.1802669,339.208501 87.8095182,339 88.4387695,339 C89.2777712,339 89.6972721,339.625504 89.9070225,339.834006 L93.4727799,344.421037 C93.6825303,344.629538 94.1020312,345.255042 94.1020312,346.089048 C94.1020312,346.714552 93.8922807,347.340056 93.2630294,347.965561 C90.9557747,350.467577 89.4875216,353.8036 89.4875216,357.556625 C89.4875216,361.309651 90.7460242,364.854175 93.2630294,367.14769 C93.8922807,367.773194 94.1020312,368.190197 94.1020312,369.024203 C94.1020312,369.858208 93.6825303,370.483713 93.4727799,370.692214 L89.9070225,375.279245 C89.6972721,375.487746 89.2777712,376.113251 88.4387695,376.113251 C87.8095182,376.113251 87.1802669,375.904749 86.5510156,375.279245 C81.7267556,370.692214 79,364.437172 79,357.556625" id="Shape"></path>
                            <path d="M141.259542,18.5566253 C141.259542,11.6760789 143.986298,5.21253521 148.810558,0.834005634 C149.439809,0.208501408 150.06906,0 150.698311,0 C151.537313,0 151.956814,0.625504225 152.166564,0.834005634 L155.732322,5.42103662 C155.942072,5.62953803 156.361573,6.25504225 156.361573,7.08904788 C156.361573,7.71455211 156.151823,8.34005634 155.522571,8.96556056 C153.005566,11.4675775 151.747064,14.8036 151.747064,18.5566253 C151.747064,22.3096507 153.005566,25.8541746 155.522571,28.1476901 C156.151823,28.7731944 156.361573,29.1901972 156.361573,30.0242028 C156.361573,30.8582084 155.942072,31.4837127 155.732322,31.6922141 L152.166564,36.2792451 C152.166564,36.4877465 151.537313,37.1132507 150.698311,37.1132507 C150.06906,37.1132507 149.439809,36.9047493 148.810558,36.2792451 C143.986298,31.6922141 141.259542,25.4371718 141.259542,18.5566253" id="Shape"></path>
                            <path d="M209.433323,12.3095901 C214.467333,7.30555631 220.759846,4.80353941 227.47186,5.01204082 C228.310862,5.01204082 228.940113,5.22054223 229.359614,5.84604645 C229.779115,6.47155068 229.779115,7.30555631 229.779115,7.51405772 L228.940113,13.1435957 C228.940113,13.3520972 228.940113,14.1861028 228.310862,14.811607 C227.891361,15.2286098 227.26211,15.4371112 226.423108,15.4371112 C223.067101,15.4371112 219.501344,16.8966211 216.984338,19.6071394 C214.257583,22.3176577 212.78933,25.6536802 212.78933,28.9897028 C212.78933,29.8237084 212.579579,30.4492126 212.160078,30.8662155 C211.530827,31.4917197 210.691825,31.4917197 210.482075,31.4917197 L204.818813,32.1172239 C204.609063,32.1172239 203.770061,32.3257253 203.14081,31.7002211 C202.721309,31.2832183 202.301808,30.657714 202.301808,29.8237084 C201.882307,23.5686662 204.609063,17.3136239 209.433323,12.3095901" id="Shape"></path>
                            <path d="M153.429144,80.925456 C158.463154,85.9294898 160.980159,92.184532 160.770409,98.8565771 C160.770409,99.6905827 160.560658,100.316087 159.931407,100.73309 C159.302156,101.358594 158.463154,101.150093 158.253404,101.150093 L152.590142,100.524588 C152.380391,100.524588 151.54139,100.524588 150.912138,99.8990841 C150.492638,99.4820813 150.282887,98.8565771 150.282887,98.0225714 C150.282887,94.6865489 148.604884,91.142025 146.087878,88.6400081 C143.361123,85.9294898 140.005116,84.4699799 136.649109,84.4699799 C135.810107,84.4699799 135.180856,84.2614785 134.761355,83.8444757 C134.132104,83.2189715 134.132104,82.3849658 133.922353,82.1764644 L133.293102,76.5469264 C133.293102,76.338425 133.083352,75.5044193 133.712603,74.8789151 C134.132104,74.4619123 134.761355,74.0449095 135.600357,74.0449095 C142.10262,73.6279067 148.395133,76.1299236 153.429144,80.925456" id="Shape"></path>
                            <path d="M207.259542,86.5566253 C207.259542,79.6760789 209.986298,73.2125352 214.810558,68.8340056 C215.439809,68.2085014 216.06906,68 216.698311,68 C217.537313,68 217.956814,68.6255042 218.166564,68.8340056 L221.732322,73.4210366 C221.942072,73.629538 222.361573,74.2550423 222.361573,75.0890479 C222.361573,75.7145521 222.151823,76.3400563 221.522571,76.9655606 C219.215317,79.4675775 217.747064,82.8036 217.747064,86.5566253 C217.747064,90.3096507 219.005566,93.8541746 221.522571,96.1476901 C222.151823,96.7731944 222.361573,97.1901972 222.361573,98.0242028 C222.361573,98.8582084 221.942072,99.4837127 221.732322,99.6922141 L218.166564,104.279245 C217.956814,104.487746 217.537313,105.113251 216.698311,105.113251 C216.06906,105.113251 215.439809,104.904749 214.810558,104.279245 C209.986298,99.6922141 207.259542,93.4371718 207.259542,86.5566253" id="Shape"></path>
                            <path d="M148.71758,281 C155.639344,281 162.141608,283.710518 166.546367,288.506051 C167.175618,289.131555 167.385369,289.757059 167.385369,290.382563 C167.385369,291.216569 166.756117,291.633572 166.546367,291.842073 L162.141608,295.386597 C161.931857,295.595099 161.302606,296.012101 160.463604,296.012101 C159.834353,296.012101 159.205102,295.8036 158.57585,295.178096 C156.058845,292.88458 152.702838,291.42507 148.92733,291.42507 C145.151823,291.42507 141.586065,292.676079 139.278811,295.178096 C138.649559,295.8036 138.230058,296.012101 137.391057,296.012101 C136.552055,296.012101 135.922804,295.595099 135.713053,295.386597 L131.098544,291.842073 C130.888793,291.842073 130.259542,291.216569 130.259542,290.382563 C130.259542,289.757059 130.469292,289.131555 131.098544,288.506051 C135.293552,283.710518 141.586065,281 148.71758,281" id="Shape"></path>
                            <path d="M214.92733,212 C221.849095,212 228.351358,214.710518 232.756117,219.506051 C233.385369,220.131555 233.595119,220.757059 233.595119,221.382563 C233.595119,222.216569 232.756117,222.633572 232.756117,222.842073 L228.141608,226.386597 C227.931857,226.595099 227.302606,227.012101 226.463604,227.012101 C225.834353,227.012101 225.205102,226.8036 224.57585,226.178096 C222.058845,223.88458 218.702838,222.42507 214.92733,222.42507 C211.151823,222.42507 207.586065,223.676079 205.278811,226.178096 C204.649559,226.8036 204.020308,227.012101 203.391057,227.012101 C202.552055,227.012101 201.922804,226.595099 201.713053,226.386597 L197.098544,222.842073 C196.888793,222.842073 196.259542,222.216569 196.259542,221.382563 C196.259542,220.757059 196.469292,220.131555 197.098544,219.506051 C201.713053,214.710518 208.005566,212 214.92733,212" id="Shape"></path>
                            <path d="M222.361573,288.556625 C222.361573,295.437172 219.634818,301.900715 214.810558,306.279245 C214.181306,306.904749 213.552055,307.113251 212.922804,307.113251 C212.083802,307.113251 211.664301,306.487746 211.454551,306.279245 L207.888793,301.692214 C207.679043,301.483713 207.259542,300.858208 207.259542,300.024203 C207.259542,299.398699 207.469292,298.773194 208.098544,298.14769 C210.405798,295.645673 211.874052,292.309651 211.874052,288.556625 C211.874052,284.8036 210.615549,281.259076 208.098544,278.965561 C207.469292,278.340056 207.259542,277.923054 207.259542,277.089048 C207.259542,276.255042 207.679043,275.629538 207.888793,275.421037 L211.454551,270.834006 C211.664301,270.625504 212.083802,270 212.922804,270 C213.552055,270 214.181306,270.208501 214.810558,270.834006 C219.634818,275.212535 222.361573,281.467577 222.361573,288.556625" id="Shape"></path>
                            <path d="M156.361573,219.556625 C156.361573,226.437172 153.634818,232.900715 148.810558,237.279245 C148.181306,237.904749 147.552055,238.113251 146.922804,238.113251 C146.083802,238.113251 145.664301,237.487746 145.454551,237.279245 L141.888793,232.692214 C141.679043,232.483713 141.259542,231.858208 141.259542,231.024203 C141.259542,230.398699 141.469292,229.773194 142.098544,229.14769 C144.405798,226.645673 145.874052,223.309651 145.874052,219.556625 C145.874052,215.8036 144.615549,212.259076 142.098544,209.965561 C141.469292,209.340056 141.259542,208.714552 141.259542,208.089048 C141.259542,207.255042 141.679043,206.629538 141.888793,206.421037 L145.454551,201.834006 C145.454551,201.625504 146.083802,201 146.922804,201 C147.552055,201 148.181306,201.208501 148.810558,201.834006 C153.844568,206.212535 156.361573,212.467577 156.361573,219.556625" id="Shape"></path>
                            <path d="M153.429144,145.969567 C158.463154,150.973601 160.980159,157.228643 160.770409,163.900688 C160.770409,164.734694 160.560658,165.360198 159.931407,165.777201 C159.302156,166.402705 158.463154,166.194204 158.253404,166.194204 L152.590142,165.568699 C152.380391,165.568699 151.54139,165.568699 150.912138,164.943195 C150.492638,164.526192 150.282887,163.900688 150.282887,163.066682 C150.282887,159.73066 148.604884,156.186136 146.087878,153.684119 C143.361123,150.973601 140.005116,149.514091 136.649109,149.514091 C135.810107,149.514091 135.180856,149.30559 134.761355,148.888587 C134.132104,148.263082 134.132104,147.429077 133.922353,147.220575 L133.293102,141.591037 C133.293102,141.382536 133.083352,140.54853 133.712603,139.923026 C134.132104,139.506023 134.761355,139.089021 135.600357,139.089021 C142.10262,138.463516 148.395133,141.174035 153.429144,145.969567" id="Shape"></path>
                            <path d="M207.259542,151.556625 C207.259542,144.676079 209.986298,138.212535 214.810558,133.834006 C215.439809,133.208501 216.06906,133 216.698311,133 C217.537313,133 217.956814,133.625504 218.166564,133.834006 L221.732322,138.421037 C221.942072,138.629538 222.361573,139.255042 222.361573,140.089048 C222.361573,140.714552 222.151823,141.340056 221.522571,141.965561 C219.215317,144.467577 217.747064,147.8036 217.747064,151.556625 C217.747064,155.309651 219.005566,158.854175 221.522571,161.14769 C222.151823,161.773194 222.361573,162.190197 222.361573,163.024203 C222.361573,163.858208 221.942072,164.483713 221.732322,164.692214 L218.166564,169.279245 C217.956814,169.487746 217.537313,170.113251 216.698311,170.113251 C216.06906,170.113251 215.439809,169.904749 214.810558,169.279245 C209.986298,164.692214 207.259542,158.437172 207.259542,151.556625" id="Shape"></path>
                            <path d="M153.429144,351.969567 C158.463154,356.973601 160.980159,363.228643 160.770409,369.900688 C160.770409,370.734694 160.560658,371.360198 159.931407,371.777201 C159.302156,372.402705 158.463154,372.194204 158.253404,372.194204 L152.590142,371.568699 C152.380391,371.568699 151.54139,371.568699 150.912138,370.943195 C150.492638,370.526192 150.282887,369.900688 150.282887,369.066682 C150.282887,365.73066 148.604884,362.186136 146.087878,359.684119 C143.361123,356.973601 140.005116,355.514091 136.649109,355.514091 C135.810107,355.514091 135.180856,355.30559 134.761355,354.888587 C134.132104,354.263082 134.132104,353.429077 133.922353,353.220575 L133.293102,347.591037 C133.293102,347.382536 133.083352,346.54853 133.712603,345.923026 C134.132104,345.506023 134.761355,345.089021 135.600357,345.089021 C142.10262,344.463516 148.395133,347.174035 153.429144,351.969567" id="Shape"></path>
                            <path d="M207.259542,357.556625 C207.259542,350.676079 209.986298,344.212535 214.810558,339.834006 C215.439809,339.208501 216.06906,339 216.698311,339 C217.537313,339 217.956814,339.625504 218.166564,339.834006 L221.732322,344.421037 C221.942072,344.629538 222.361573,345.255042 222.361573,346.089048 C222.361573,346.714552 222.151823,347.340056 221.522571,347.965561 C219.215317,350.467577 217.747064,353.8036 217.747064,357.556625 C217.747064,361.309651 219.005566,364.854175 221.522571,367.14769 C222.151823,367.773194 222.361573,368.190197 222.361573,369.024203 C222.361573,369.858208 221.942072,370.483713 221.732322,370.692214 L218.166564,375.279245 C217.956814,375.487746 217.537313,376.113251 216.698311,376.113251 C216.06906,376.113251 215.439809,375.904749 214.810558,375.279245 C209.986298,370.692214 207.259542,364.437172 207.259542,357.556625" id="Shape"></path>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </g>`
}
