import React from 'react'
import { connect } from 'react-redux'
import { Field, reduxForm } from 'redux-form'
import { Flex, Box } from 'grid-styled'
import { utils } from 'alelo-logic'
import ChooseAvatar from '../../modules/ChooseAvatar'
import Button from '../../elements/Button'

import { Container } from './styles'

import {
  renderTextInput,
  renderCpfInput,
  renderMaritialStatusField,
  renderGenderField,
  renderCheckboxField
} from '../../../utils/renderInputs'

const { labels } = require('./data.json')

const EditUserDataForm = props => {
  const { handleSubmit, lastTimeSaved, saving } = props

  return (
    <form onSubmit={handleSubmit}>
      <Container>
        <Flex wrap width={[1, 7 / 12]}>
          <Box width={1} mb={3}>
            <Field
              component={renderTextInput}
              label={labels.name}
              name="name"
              type="text"
              disabled
              validate={utils.emptyFieldValidate}
              isRequired
            />
          </Box>
          <Box width={1} mb={3}>
            <Field
              component={renderCpfInput}
              label={labels.cpf}
              validate={utils.cpfValidate}
              normalize={utils.cpfMask}
              name="cpf"
              type="text"
              disabled
              isRequired
            />
          </Box>
          <Box width={[1, 1 / 2]} pr={[0, 2]} mb={3}>
            <Field
              component={renderTextInput}
              label={labels.dob}
              name="dob"
              type="text"
              normalize={utils.dateMask}
              disabled
              isRequired
            />
          </Box>
          <Box width={[1, 1 / 2]} pl={[0, 2]} mb={3}>
            <Field
              component={renderGenderField}
              label={labels.gender}
              name="gender"
              validate={utils.emptyFieldValidate}
              isRequired
              disabled={saving}
            />
          </Box>
          <Box width={1} mb={3}>
            <Field
              component={renderMaritialStatusField}
              label={labels.maritalStatus}
              name="maritalStatus"
              validate={utils.emptyFieldValidate}
              isRequired
              disabled={saving}
            />
          </Box>
          <Box width={1} mb={3}>
            <Field
              component={renderTextInput}
              label={labels.mothersName}
              name="mothersName"
              type="text"
              validate={utils.emptyFieldValidate}
              disabled
              isRequired
            />
          </Box>
          <Box width={1} mb={3}>
            <Field
              component={renderCheckboxField}
              label={labels.emiteNotaFiscal}
              name="emiteNotaFiscal"
              disabled={saving}
            />
          </Box>
          <Box width={1} mb={3}>
            <Field
              component={renderCheckboxField}
              label={labels.pap}
              name="pap"
              disabled={saving}
            />
          </Box>
        </Flex>
      </Container>
      <Box mb={50}>
        <ChooseAvatar />
      </Box>
      <Container>
        <Button type="submit" text="Salvar" disabled={saving} />
        {lastTimeSaved}
      </Container>
    </form>
  )
}

const mapStateToProps = state => ({
  saving: state.reducer.formState.saving
})

const form = reduxForm({
  form: 'editUserData',
  enableReinitialize: true,
  destroyOnUnmount: false
})(EditUserDataForm)

export default connect(mapStateToProps, undefined)(form)
