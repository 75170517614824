'use strict'

export function parseCities(list) {
  const result = []

  for (let i = 0, len = list.length; i < len; i += 1) {
    result.push({ label: list[i].descricao, value: list[i].descricao })
  }

  return result
}

export function parseStates(list) {
  const result = []

  for (let i = 0, len = list.length; i < len; i += 1) {
    result.push({ label: list[i].uf, value: list[i].uf })
  }

  return result
}
