import React from 'react'
import { Box, Flex } from 'grid-styled'

import { Currency, Payment, Text, Title, Value } from './styles'
import _ from 'lodash'

const { maturity, amount, partialAmount, currency, open } = require('./data.json')

const InvoiceValue = ({ currentInvoice, total, date, paymentMethod, bankName }) => (
  <div>
    <Flex align="stretch" justify="space-between">
      <Box mb={11} pr={[0, 20]} width={[1, 6 / 12]}>
        <Text>{maturity}</Text>
        <Title>{date || open}</Title>
      </Box>
      <Box pl={[0, 40]} width={[1, 6 / 12]}>
        <Text>{currentInvoice ? partialAmount : amount}</Text>
        <Value>
          <Currency>{!_.isEmpty(total) ? currency : ''}</Currency>
          <Title>{total}</Title>
        </Value>
      </Box>
    </Flex>
    {paymentMethod && <Payment>{`${paymentMethod} ${bankName}`}</Payment>}
  </div>
)

export default InvoiceValue
