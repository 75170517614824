import accountPlansReducer from './accountPlansReducer'
import accountReducer from './accountReducer'
import banksReducer from './banksReducer'
import cancelAccountReducer from './cancelAccountReducer'
import contestationProtocolReducer from './contestationProtocolReducer'
import dashboardInvoicesReducer from './dashboardInvoicesReducer'
import dashboardRechargesReducer from './dashboardRechargesReducer'
import dashboardResumeReducer from './dashboardResumeReducer'
import dashboardStatementsReducer from './dashboardStatementsReducer'
import dashboardVehiclesReducer from './dashboardVehiclesReducer'
import ecContestations from './ecContestations'
import ecLocationsReducer from './ecLocationsReducer'
import ecMoneyTransfer from './ecMoneyTransfer'
import ecQueryPlateReducer from './ecQueryPlateReducer'
import ecRequestFileHistory from './ecRequestFileHistory'
import invoiceDetailsReducer from './invoiceDetailsReducer'
import reasonsReducer from './reasonsReducer'
import rechargeReducer from './rechargeReducer'
import recoverReducer from './recoverReducer'
import recoverTokenStatusReducer from './recoverTokenStatusReducer'
import statementReducer from './statementReducer'
import userReducer from './userReducer'
import planDataReducer from './planDataReducer'
import termsConditionReducer from './termsAndConditionReducer'
import shippingAmountReducer from './shippingAmountReducer'
import editPaymentDataReducer from './editPaymentDataReducer'
import loginModalReducer from './loginModalReducer'
import manageInfsReducer from './manageInfsReducer'
import reasonsListBlockReducer from './reasonListBlockReducer'
import uiReducer from './uiReducer'
import subsidyMessageReducer from './subsidyMessageReducer'

export default {
  accountPlansReducer,
  accountReducer,
  banksReducer,
  cancelAccountReducer,
  contestationProtocolReducer,
  dashboardInvoicesReducer,
  dashboardRechargesReducer,
  dashboardResumeReducer,
  dashboardStatementsReducer,
  dashboardVehiclesReducer,
  ecContestations,
  ecLocationsReducer,
  ecMoneyTransfer,
  ecQueryPlateReducer,
  ecRequestFileHistory,
  invoiceDetailsReducer,
  reasonsReducer,
  rechargeReducer,
  recoverReducer,
  recoverTokenStatusReducer,
  statementReducer,
  userReducer,
  planDataReducer,
  termsConditionReducer,
  shippingAmountReducer,
  editPaymentDataReducer,
  loginModalReducer,
  manageInfsReducer,
  reasonsListBlockReducer,
  uiReducer,
  subsidyMessageReducer
}
