import styled from 'styled-components'
import { Link } from 'react-router-dom'

import media from 'styles/tools/media'
import rem from 'styles/tools/rem'

import {
  paleGrayTwo,
  white,
  red,
  informationalLight,
  informationalBlue
} from 'styles/settings/colors'
import { txt14OpenSansBold, txt12OpenSans } from 'styles/settings/types'

export const Buttons = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: ${rem('45px')};
  margin-top: ${rem('40px')};

  ${media.large`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  `};
`

export const Separator = styled.hr`
  border: 1px solid ${paleGrayTwo};
  margin-left: -20px;
  width: calc(100% + 36px);
`
export const WrapperPayment = styled.div`
  input {
    color: ${white};
  }

  span {
    color: ${white};
  }

  label {
    color: ${white};

    div > span {
      font-size: 12px;
    }
  }
`
export const FormField = styled.fieldset`
  border: 0;
  padding: 0;
  margin: 0;
  min-width: 0;

  ${({ disabled }) =>
    disabled &&
    `
  opacity: 0.4;
  cursor: not-allowed
  `};
`

export const BoxCreditCard = styled.div`
  div > span {
    color: ${red} !important;
  }
`

export const ButtonBack = styled(Link)`
  ${txt14OpenSansBold};
  font-size: 16px;
  align-items: center;
  color: ${white};
  display: inline-flex;
  line-height: 1;
  text-decoration: none;

  svg {
    margin-right: ${rem('15px')};
  }

  ${media.small`
      display: block;
      line-height: ${rem('60px')};
      position: relative;
      text-align: center;
      vertical-align: middle;
      width: 100%;

      svg {
          left: 0;
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
      }
  `};
`
export const DebitCardNotice = styled.div`
  ${txt12OpenSans};
  display: auto;
  background-color: ${informationalLight};
  color: ${informationalBlue};
  padding: 15px;
  border-radius: 12px;
  align-items: center;
  margin: 0 19% 0 0;
  & > svg {
    margin: 0 12px 0 0;
  }
  img {
    padding-right: 13px;
  }
`
