import React from 'react'
import { Box, Flex } from 'grid-styled'
import Hide from 'styles/utilities/Hide'
import { withRouter } from 'react-router-dom'

import DropdownList, { ListItem as DropListItem } from 'app/modules/IdentifiersList/DropdownList'
import DotButton from 'app/elements/DotButton'
// import Checkbox from 'app/elements/Checkbox'

import PlusIcon from 'app/elements/Icon/ic-plus'
import { ChipContainer, Chip, ChipEmpty, ListItem, LineTextContainer, LineText } from './styles'

function IdentifierListItem({
  vehicle = {},
  itemDropdownId,
  history,
  isItemDropdownOpen,
  handleItemDropdown,
  handleCreateInf,
  itemStatus
}) {
  const {
    idIdentificador,
    veiculo = {},
    situacao,
    codIdentificador,
    informacoesGerenciais
  } = vehicle
  const active = situacao === 'ATIVO'
  const identifierId = codIdentificador

  const { placa: carPlate = '', categoria: carCategory = '', tipo = '' } = veiculo || {}

  const carType = tipo === 'CAMINHAO' ? 'Caminhão' : tipo
  const sortedInfos = sortedInformations(informacoesGerenciais)
  // const isChecked = !!checkboxValues.find(item => item.idIdentificador === idIdentificador)
  const renderIndexInfoManage = tipoInfo =>
    tipoInfo === 'INFORMACAO_1' ? 1 : tipoInfo === 'INFORMACAO_2' ? 2 : 3

  function sortedInformations(informations) {
    return informations == null
      ? null
      : informations.sort((a, b) => {
          return a.tipoInfo.toLowerCase() < b.tipoInfo.toLowerCase() ? -1 : 1
        })
  }

  return (
    <ListItem blocked={!!active}>
      <Flex width={1} direction={['column', 'row']}>
        {/* <Box width={[1 / 12, 0.5 / 12]}>
          <LineTextContainer>
            <Checkbox checked={isChecked} onChange={handleSelectItem(vehicle)} />
          </LineTextContainer>
        </Box> */}
        <Box width={[3 / 12, 2 / 12]}>
          <LineTextContainer>
            <LineText capitalize bold>
              {carType.toLowerCase()}
            </LineText>
          </LineTextContainer>
        </Box>
        <Box width={[3 / 12, 2 / 12]}>
          <LineTextContainer>
            <LineText>{carPlate}</LineText>
          </LineTextContainer>
        </Box>
        <Hide at="small" width={[1, 2 / 12]}>
          <LineTextContainer>
            <LineText>{carCategory}</LineText>
          </LineTextContainer>
        </Hide>
        <Box width={[3 / 12, 2 / 12]}>
          <LineTextContainer>
            <LineText>{identifierId}</LineText>
          </LineTextContainer>
        </Box>
        <Box width={[3 / 12, 3 / 12]}>
          <ChipContainer>
            {!sortedInfos || sortedInfos.length === 0 ? (
              <ChipEmpty
                onClick={() => {
                  handleCreateInf(vehicle)
                  window.dataLayer.push({
                    event: 'event',
                    eventAction: 'clique:adicionar-informacao',
                    eventCategory: 'veloe:area-logado:meus-produtos',
                    dimension19: carType,
                    dimension20: carCategory
                  })
                }}
              >
                <PlusIcon /> Add Informação
              </ChipEmpty>
            ) : (
              sortedInfos.map((item, i) => (
                <Chip key={i}>
                  {renderIndexInfoManage(item.tipoInfo)}. {item.descricaoAtual}
                </Chip>
              ))
            )}
          </ChipContainer>
        </Box>
        <Box width={[1, 0.5 / 12]}>
          <LineTextContainer>
            <DotButton onClick={handleItemDropdown(idIdentificador)} />
            <DropdownList
              secondary
              isOpen={isItemDropdownOpen && itemDropdownId === idIdentificador}
              onClose={handleItemDropdown()}
            >
              {itemStatus !== 'INATIVO' && (
                <DropListItem
                  onClick={() => {
                    history.push(`identificador/${identifierId}/inativar`)
                    window.dataLayer.push({
                      event: 'event',
                      eventAction: 'clique:mais-opcoes',
                      eventCategory: 'veloe:area-logado:meus-produtos',
                      eventLabel: 'Inativar / Substituir',
                      dimension19: carType,
                      dimension20: carCategory,
                      dimension21: active ? 'ativo' : 'bloqueado'
                    })
                  }}
                >
                  Inativar / Substituir
                </DropListItem>
              )}
              <DropListItem
                onClick={() => {
                  history.push(`identificador/${identifierId}/alterar`)
                  window.dataLayer.push({
                    event: 'event',
                    eventAction: 'clique:mais-opcoes',
                    eventCategory: 'veloe:area-logado:meus-produtos',
                    eventLabel: 'Gerenciar informações',
                    dimension19: carType,
                    dimension20: carCategory,
                    dimension21: active ? 'ativo' : 'bloqueado'
                  })
                }}
              >
                Gerenciar informações
              </DropListItem>
            </DropdownList>
          </LineTextContainer>
        </Box>
      </Flex>
    </ListItem>
  )
}

IdentifierListItem.defaultProps = {
  checkboxValues: [],
  vehicle: {},
  handleSelectItem: () => {},
  itemDropdownId: 0,
  isItemDropdownOpen: false,
  handleItemDropdown: () => {},
  handleItemDropdownClose: () => {},
  handleCreateInf: () => {}
}

export default withRouter(IdentifierListItem)
