import styled from 'styled-components'

import media from '../../../styles/tools/media'

export const InvoiceTable = styled.div`
  ${media.small`
        display: none;
    `};

  @media {
    ${props =>
      props.noBreak
        ? ''
        : `display: block;
    page-break-before: initial;`};
  }
`
