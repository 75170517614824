const { apiURL, publicSite, brasPagURL } = require('../../config.json')

export const API_URL = `${apiURL}`
export const API = `${apiURL}/mocks`
export const dynamicAPI = `${apiURL}`
export const dynPublicAPI = `${apiURL}/publico`
export const PUBLIC_SITE_URL = `${publicSite}`
export const BRASPAG_URL = `${brasPagURL}`

export const SUPPORT = '/suporte'
export const ACCOUNT = '/conta'
export const INDIVIDUAL_USER = '/pessoal'
export const BUSINESS_USER = '/empresarial'
export const USER_CANCEL = `${ACCOUNT}/cancelamento`
export const USER_CREDENTIALS = `${ACCOUNT}/credenciais`
export const USER_ADDRESS = `${INDIVIDUAL_USER}/endereco`
export const USER_PROFILE = `${INDIVIDUAL_USER}/perfil`
export const USER_CONTACTS = `${INDIVIDUAL_USER}/contato`
export const USER_AVATAR = `${INDIVIDUAL_USER}/avatar`
export const BUSINESS_USER_PROFILE = `${BUSINESS_USER}/institucional`
export const BUSINESS_USER_ADDRESS = `${BUSINESS_USER}/endereco`
export const BUSINESS_USER_REPRESENTATIVE = `${BUSINESS_USER}/representante`
export const BUSINESS_USER_CONTACTS = `${BUSINESS_USER}/contato`
export const CEP = `${SUPPORT}/cep`
export const CANCEL_OPTIONS = '/cancelamento'
export const CREATE_CANCEL_TOKEN = `${ACCOUNT + CANCEL_OPTIONS}/token/create`
export const VALIDATE_CANCEL_TOKEN = `${ACCOUNT + CANCEL_OPTIONS}/token/validate`
export const VERIFY_USER_PASSWORD = '/verificar-senha'
export const PLAN_RESUME = '/minha-veloe/resumo'
export const PLAN_STATEMENTS = '/minha-veloe/extrato'
export const PLAN_INVOICES = '/minha-veloe/fatura'
export const PLAN_RECHARGES = '/minha-veloe/historico-de-recarga'
export const ACCESS_TOKEN = '/auth/login'
export const LOGOUT = '/auth/logout'
export const PLAN_INVOICE_DETAILS = '/minha-veloe'

// Informação Gerencial
export const MANAGE_INF = '/minha-veloe/informacao-gerencial'

// EC
export const EC_LOCATIONS = '/ec/locations'
export const EC_QUERY_PLATE = '/ec/query-plate'

export const FETCH_MAX_RETRY = 5
