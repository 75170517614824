import React from 'react'
import { arrayOf, number, string } from 'prop-types'

import { BarContainer, BarSlice } from './styles'

const ConsumptionChartBarPos = ({ colors = [], values = [10, 90], height = '16px' }) => (
  <BarContainer height={height}>
    {values.map((value, index) => (
      <BarSlice key={index} width={`${value}%`} color={colors[index]} />
    ))}
  </BarContainer>
)

ConsumptionChartBarPos.propTypes = {
  colors: arrayOf(string),
  values: arrayOf(number)
}

export default ConsumptionChartBarPos
