/* eslint-disable */
import React from 'react'
import { connect } from 'react-redux'

import Content from '../../../styles/objects/Content'
import HeaderWithBrand from '../../elements/HeaderWithBrand'
import Button from 'app/elements/Button'

import { Title, Paragraph, Wrapper } from './styles'

const { title, paragraph, submitButton } = require('./data.json')

const ActivationSuccess = props => {
  const {
    accountPlans: { activePlanId },
    history
  } = props
  return (
    <Wrapper>
      <HeaderWithBrand />
      <Content>
        <Title>{title}</Title>
        <Paragraph>{paragraph}</Paragraph>
        <Button
          text={submitButton}
          onClick={() => {
            history.push(`/minha-veloe/${activePlanId}/identificadores`)
          }}
          buttonType="transparent"
        />
      </Content>
    </Wrapper>
  )
}

const mapStateToProps = ({ reducer }) => {
  return {
    accountPlans: reducer.accountPlans
  }
}

export default connect(mapStateToProps, null)(ActivationSuccess)
