import React from 'react'
import { connect } from 'react-redux'
import { stopSubmit } from 'redux-form'
import Helmet from 'react-helmet'
import { actions } from 'alelo-logic'

import CollapsibleFormList from '../../modules/CollapsibleFormList'
import EditRepresentativesForm from '../../modules/EditRepresentativesForm'
import { showModal } from '../../modules/ModalRoot/actions'
import { CONFIRM_UPDATE } from '../../../constants/ModalTypes'
import representativesFactory from './factory'

import { Content, Heading, Paragraph } from '../EditUserData/styles'

import image from '../../../static/confirmation.png'

const { helmetTitle, title, subtitle, modalTitle, sameCpfError } = require('./data.json')

const { fetchCreateRepresentative, fetchUpdateRepresentative, fetchDeleteRepresentative } = actions

const EditRepresentatives = ({
  representatives,
  addRepresentative,
  removeRepresentative,
  updateRepresentative,
  triggerDocumentError
}) => {
  const initialValues = representativesFactory.toForm(representatives)

  const onUpdate = (values, dispatch, props, done) => {
    updateRepresentative(representativesFactory.toAPI(values)).then(() => {
      done()
    })
  }

  const onDelete = (document, done) => {
    if (representatives.length > 1) {
      removeRepresentative(document).then(() => {
        done()
      })
    }
  }

  const onCreate = (values, dispatch, props, done) => {
    if (
      representatives.findIndex(
        representative => representative.document === values.document.replace(/\.|-/g, '')
      ) === -1
    ) {
      addRepresentative(representativesFactory.toAPI(values)).then(() => {
        props.reset()
        done()
      })
    } else {
      triggerDocumentError()
    }
  }

  return (
    <section>
      <Helmet title={helmetTitle} />
      <Content>
        <Heading>{title}</Heading>
        <Paragraph>{subtitle}</Paragraph>
      </Content>
      <section>
        <CollapsibleFormList
          onDelete={onDelete}
          onCreate={onCreate}
          onUpdate={onUpdate}
          collapsibleItems={initialValues || []}
        >
          <EditRepresentativesForm />
        </CollapsibleFormList>
      </section>
    </section>
  )
}

const mapStateToProps = state => ({
  representatives: state.reducer.user.representantes
})

const mapDispatchToProps = dispatch => ({
  addRepresentative: data => dispatch(fetchCreateRepresentative(data)),
  removeRepresentative: document => dispatch(fetchDeleteRepresentative(document)),
  updateRepresentative: data =>
    dispatch(fetchUpdateRepresentative(data)).then(() =>
      dispatch(showModal(CONFIRM_UPDATE, { modalTitle, image }))
    ),
  triggerDocumentError: () =>
    dispatch(
      stopSubmit('addrepresentative', {
        document: sameCpfError
      })
    )
})

export default connect(mapStateToProps, mapDispatchToProps)(EditRepresentatives)
