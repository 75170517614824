import styled from 'styled-components'

import rem from 'styles/tools/rem'
import media from 'styles/tools/media'
import { white } from 'styles/settings/colors'
import { title24Galano } from 'styles/settings/types'

import { PageTitle } from '../../templates/TemplateRecharge/styles'

export const Title = styled(PageTitle)`
  ${media.large`
        max-width: ${rem('240px')};
    `};
`

export const Currency = styled.span`
  ${title24Galano};
  color: ${white};
  font-weight: bold;

  ${media.large`
        font-size: ${rem('50px')};
        letter-spacing: ${rem('-1.1px')};
    `};
`
