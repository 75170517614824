import React from 'react'
import { connect } from 'react-redux'
import { arrayOf, string, shape, bool } from 'prop-types'

import { Container, Title, StepList, StepItem, RoundedCheck, Text } from './styles'

/* eslint-disable */
const roundedSvg = {
  cross: [
    'M10.982 11.57a.586.586 0 0 1-.415-.172L5.172 6.004a.588.588 0 0 1 .832-.832l5.394 5.395a.588.588 0 0 1-.416 1.004',
    'M5.588 11.57a.588.588 0 0 1-.416-1.004l5.395-5.394a.588.588 0 1 1 .831.832l-5.394 5.394a.586.586 0 0 1-.416.173'
  ],
  check:
    'M8.18779462,10.978096 C8.18124917,10.978096 8.1747781,10.9780015 8.16820785,10.9778126 C7.9846376,10.9727102 7.81128223,10.8959385 7.68885248,10.7655448 L5.17051363,8.08333225 C4.92399297,7.82082044 4.94757148,7.41759209 5.2231252,7.18276532 C5.49857975,6.9478913 5.92180289,6.97033225 6.16834834,7.2328913 L8.21620785,9.41393855 L15.8461748,2.18552122 C16.1083649,1.93718264 16.5322326,1.93829288 16.7928607,2.18807241 C17.0535632,2.43785193 17.0523483,2.84169445 16.7902078,3.09003303 L8.65978636,10.7925685 C8.53418305,10.9115763 8.36439793,10.978096 8.18779462,10.978096',
  circle:
    'M8.28 16.272a7.964 7.964 0 0 1-5.65-2.34A7.964 7.964 0 0 1 .29 8.28a7.963 7.963 0 0 1 2.34-5.65A7.964 7.964 0 0 1 8.28.29a7.963 7.963 0 0 1 5.65 2.34 7.963 7.963 0 0 1 2.342 5.65 7.94 7.94 0 0 1-.766 3.419.54.54 0 1 1-.976-.462 6.86 6.86 0 0 0 .662-2.956 6.866 6.866 0 0 0-2.024-4.887A6.866 6.866 0 0 0 8.281 1.37a6.866 6.866 0 0 0-4.887 2.024A6.866 6.866 0 0 0 1.37 8.28c0 1.846.718 3.582 2.024 4.887a6.866 6.866 0 0 0 4.887 2.024c.93 0 1.833-.181 2.683-.54a.54.54 0 0 1 .42.995 7.943 7.943 0 0 1-3.103.625'
}
/* eslint-enable */

function StepBox({ steps, title, stepBoxData }) {
  const { stepsValidity, showErrors } = stepBoxData

  const validatedSteps = steps.map((step, index) => {
    const newStep = step
    newStep.valid = stepsValidity[index]

    return newStep
  })

  return (
    <Container>
      <Title>{title}</Title>
      <StepList>
        {validatedSteps.map(step => (
          <StepItem key={step.text}>
            <RoundedCheck valid={step.valid} showErrors={showErrors} height="16" width="16">
              {showErrors && step.valid && <path d={roundedSvg.check} />}

              {showErrors && !step.valid && (
                <g>
                  {roundedSvg.cross.map(crossDimension => (
                    <path key={crossDimension} d={crossDimension} />
                  ))}
                </g>
              )}
              <path d={roundedSvg.circle} />
            </RoundedCheck>
            <Text>{step.text}</Text>
          </StepItem>
        ))}
      </StepList>
    </Container>
  )
}

StepBox.defaultProps = {
  steps: [],
  title: string,
  stepBoxData: {
    showErrors: true,
    stepsValidity: []
  }
}

StepBox.propTypes = {
  steps: arrayOf(
    shape({
      valid: bool,
      text: string.isRequired
    })
  ),
  title: string.isRequired,
  stepBoxData: shape({
    showErrors: bool,
    stepsValidity: arrayOf(bool)
  })
}

export default connect(state => ({
  stepBoxData: state.stepBox
}))(StepBox)
