import React from 'react'
import styled from 'styled-components'
import pT from 'prop-types'
import { txt12OpenSansBold } from 'styles/settings/types'

const Tooltip = styled.div`
  position: relative;
  display: block;
`
const TooltipText = styled.span`
  ${txt12OpenSansBold};
  color: #fff;
  visibility: ${({ show }) => (show ? 'visible' : 'hidden')};
  width: 200px;
  background-color: ${({ tooltipColor }) => (tooltipColor ? `${tooltipColor}` : `black`)};
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  left: 50%;
  ${({ positionRight }) =>
    positionRight
      ? `
    transform: translateX(65%);
    top: 0;
  `
      : `
  transform: translateX(-50%);
  top: 115%;
  `} &::after {
    ${({ positionRight, tooltipColor }) =>
      positionRight
        ? `
    content: '';
    position: absolute;
    bottom: 45%;
    left: -2%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent ${tooltipColor ? `${tooltipColor}` : `black`} transparent transparent;
    `
        : `
    content: '';
    position: absolute;
    bottom: 100%;
    left: 50%;    
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent
      ${tooltipColor ? `${tooltipColor}` : `black`} transparent;
    `};
  }
`

const TooltipComponent = ({ text, showTooltip, children, tooltipColor, positionRight }) => {
  return (
    <Tooltip>
      <TooltipText tooltipColor={tooltipColor} positionRight={positionRight} show={showTooltip}>
        {text}
      </TooltipText>
      {children}
    </Tooltip>
  )
}

TooltipComponent.propTypes = {
  text: pT.string.isRequired,
  showTooltip: pT.bool.isRequired
}
TooltipComponent.defaultProps = {
  text: '',
  showTooltip: false
}

export default TooltipComponent
