const getOrderStatus = (detailsList, orderNumber) => {
  const orderDetailsList = detailsList.filter(d => d.codigoPedido === parseInt(orderNumber, 10))

  // Recuperar último status
  const latestDetails = orderDetailsList.reduce(
    (latest, details) => {
      return latest && details.dataEntrega < latest.dataEntrega ? latest : details
    },
    { dataEntrega: 0, statusPedido: 'Processado' }
  )

  // verificar se existe algum status diferente de enviado, inpendente da data (este registro tem precedencia)
  // Isto trata alguns casos de dados errados onde a data de entrega é inferiro a data de envio
  const entregaDetails = detailsList.find(
    d => d.statusPedido !== 'ENVIADO' && d.codigoPedido === parseInt(orderNumber)
  )

  return entregaDetails ? entregaDetails.statusPedido : latestDetails.statusPedido
}

export const transformOrders = ({ data }, type) => {
  const { buscarPedido: orders, buscarEntrega: detailsQuery, temRecarga: recargaQuery } = data
  const { status: temRecarga } = recargaQuery
  const { arsMestre: detailsList } = detailsQuery

  let paymentStatus = 'Pedido Realizado'

  if (type === 'pre') {
    paymentStatus = temRecarga ? 'Pagamento Aprovado' : 'Aguardando pagamento'
  }

  const pedidos = orders.map(order => {
    const date = order.data || ''
    const [year, month, day] = date.split('-')
    const newDate = date && day ? `${day}/${month}/${year}` : date

    return {
      paymentStatus,
      number: order.pedido,
      date: newDate,
      status: getOrderStatus(detailsList, order.pedido)
    }
  })

  return pedidos
}
