import styled from 'styled-components'
import { Link } from 'react-router-dom'

import rem from 'styles/tools/rem'
import em from 'styles/tools/em'
import media from 'styles/tools/media'

import { blizzardBlue, skyBlue, white } from 'styles/settings/colors'

import {
  title14Galano,
  title16Galano,
  title24Galano,
  title40GalanoBold,
  txt12OpenSans,
  txt14OpenSans
} from 'styles/settings/types'

export const Container = styled.section`
  background-color: ${skyBlue};
  height: 100vh;
  padding: ${rem('29px')};
  width: 100%;
  overflow: auto;

  ${media.large`
        padding: ${rem('81px')} ${rem('170px')} ${rem('69px')} ${rem('170px')};
    `};
`

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`

export const Title = styled.h1`
  ${title24Galano};

  color: ${white};
  letter-spacing: ${em('0.4')};

  ${media.large`
        ${title40GalanoBold};

        margin: 0 0 0 ${rem('15px')};
        max-width: ${rem('390px')};
    `};
`

export const Close = styled(Link)`
  bottom: 0;
  cursor: pointer;
  position: relative;
  right: ${'2px'};

  ${media.large`
        cursor: pointer;
        width: ${rem('20px')};
        height: ${rem('20px')};
        bottom: ${rem('30px')};
    `};
`

export const Details = styled.div`
  width: 100%;
`

export const Locale = styled.span`
  ${title14Galano} color: ${white};
  margin: 0;

  ${media.large`
        flex-grow: 1;
        margin: ${rem('24px')} 0 0 ${rem('15px')};
    `};
`

export const LocaleDetail = styled.p`
  ${txt12OpenSans} color: ${white};
  margin: 0;

  ${media.large`
        ${txt14OpenSans}
        margin-left: ${rem('15px')};
    `};
`

export const Date = styled.time`
  ${txt12OpenSans} color: ${white};

  ${media.large`
        ${txt14OpenSans}
        margin: 0 ${rem('48px')} 0 ${rem('15px')};
    `};
`

export const Value = styled.span`
  ${title16Galano} color: ${white};
  display: flex;
  justify-content: flex-end;
`

export const ValueLarge = styled.span`
  ${title16Galano} color: ${white};
  display: none;

  ${media.large`
        ${title24Galano}

        display: inline-block;
        margin-right: 30%;
    `};
`

export const Bold = styled.span`
  font-weight: bold;
`

export const Separator = styled.hr`
  border: ${rem('1px')} solid ${blizzardBlue};
  margin: ${rem('30px')} 0 ${rem('10px')} 0;
`
