import React from 'react'
import { Box, Flex } from 'grid-styled'
import { withRouter } from 'react-router'

import Hide from '../../../../styles/utilities/Hide'
import LogoPipe from '../../../elements/Icon/logoPipe'
import { lightBlue } from '../../../../styles/settings/colors'
import Button from '../../../elements/Button'

import { ListItem, LineTextContainer, LineText } from './styles'

function OrderRequestItem(props) {
  const { order, history, accountId, type } = props

  function getStatusColor(status) {
    switch (status) {
      case 'ENTREGUE':
        return { green: true }
      case 'DEVOLVIDO':
        return { red: true }
      case 'CANCELADO':
        return { red: true }
      case 'EXTRAVIADO':
        return { green: true }

      default:
        return null
    }
  }

  function buttonCredit() {
    return (
      <Button
        text="Efetuar Recarga"
        onClick={() => history.push(`/minha-veloe/${accountId}/editar-dados/cartao?path=track`)}
        loading={false}
      />
    )
  }

  function buttonActivate() {
    return (
      <Button
        onClick={() => {
          history.push('/identificadores/ativacao')
          window.dataLayer.push({
            event: 'event',
            eventAction: 'clique:ativar-produto',
            eventCategory: 'veloe:area-logado:meus-pedidos',
            eventLabel: 'ativar-produto'
          })
        }}
        text="Ativar produto"
      />
    )
  }

  function renderButtonByStatus(orderByStatus, historyByStatus) {
    switch (orderByStatus.status) {
      case 'ENTREGUE':
        if (type === 'pos' || orderByStatus.paymentStatus === 'Pagamento Aprovado') {
          return buttonActivate()
        } else {
          return buttonCredit()
        }
      case 'DEVOLVIDO':
      case 'EXTRAVIADO':
        return (
          <Button
            buttonType="whiteNavyBlue"
            onClick={() => historyByStatus.push(`acompanhar-pedido/${orderByStatus.number}`)}
            loading={false}
            text="Rastrear pedido"
          />
        )
      case 'CANCELADO':
        return <Button disabled loading={false} text="Rastrear pedido" />

      case 'ENVIADO':
        return (
          <Button
            buttonType="whiteNavyBlue"
            onClick={() => {
              historyByStatus.push(`acompanhar-pedido/${orderByStatus.number}`)
              window.dataLayer.push({
                event: 'event',
                eventAction: 'clique:rastrear-pedido',
                eventCategory: 'veloe:area-logado:meus-pedidos',
                eventLabel: 'rastrear-pedido'
              })
            }}
            loading={false}
            text="Rastrear pedido"
          />
        )

      default:
        return null
    }
  }

  return (
    <ListItem>
      <Flex width={1} direction={['column', 'row']}>
        <Box width={[1 / 12]}>
          <LineTextContainer>
            <LogoPipe fill={lightBlue} />
          </LineTextContainer>
        </Box>

        <Box width={[2 / 12]}>
          <LineTextContainer>
            <LineText bold>{order.number}</LineText>
          </LineTextContainer>
        </Box>

        <Hide at="small" width={[2 / 12]}>
          <LineTextContainer>
            <LineText>{order.date}</LineText>
          </LineTextContainer>
        </Hide>

        <Box width={[2 / 12]} mr={[10, 20]}>
          <LineTextContainer>
            <LineText capitalize {...getStatusColor(order.status)}>
              {order.status.toLowerCase()}
            </LineText>
          </LineTextContainer>
        </Box>
        <Box width={[5 / 12]} mr={[10, 20]}>
          <LineTextContainer justifyContent="flex-end">
            {renderButtonByStatus(order, history)}
          </LineTextContainer>
        </Box>
      </Flex>
    </ListItem>
  )
}

export default withRouter(OrderRequestItem)
