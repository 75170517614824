import styled from 'styled-components'
import { Link } from 'react-router-dom'

import media from 'styles/tools/media'
import { txt14OpenSans } from 'styles/settings/types'
import { linkNavyBlue } from 'styles/settings/colors'

export const TextPassword = styled(Link)`
  ${txt14OpenSans} color: ${linkNavyBlue};
  cursor: pointer;
  font-weight: bold;
  line-height: 1.57;
  text-decoration: none;
`

export const Buttons = styled.div`
  display: flex;
  flex-direction: column;

  ${media.large`
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    `};
`
