import types from '../actions/types'

const {
  SET_RECHARGE_VALUES,
  SET_CURRENT_RECHARGE_VALUE,
  SET_PAYMENT_INFO,
  SET_CHANGE_RECHARGE
} = types

const initialState = {
  rechargeValues: [],
  currentRechargeValue: 100,
  paymentInfo: {},
  valorRecarga: 0
}

function rechargeReducer(state = initialState, action = {}) {
  switch (action.type) {
    case SET_RECHARGE_VALUES: {
      return {
        ...state,
        rechargeValues: action.rechargeValues
      }
    }

    case SET_CURRENT_RECHARGE_VALUE: {
      return {
        ...state,
        currentRechargeValue: action.currentRechargeValue
      }
    }

    case SET_PAYMENT_INFO: {
      return {
        ...state,
        paymentInfo: action.paymentInfo
      }
    }

    case SET_CHANGE_RECHARGE: {
      return {
        ...state,
        valorRecarga: action.valorRecarga
      }
    }

    default:
      return state
  }
}

export default rechargeReducer
