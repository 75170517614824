const phoneMask = value => {
  if (!value) {
    return value
  }

  const onlyNums = value.replace(/[^\d]/g, '')

  if (onlyNums.length <= 4) {
    return onlyNums
  }
  if (onlyNums.length <= 8) {
    return `${onlyNums.slice(0, 4)}-${onlyNums.slice(4)}`
  }
  return `${onlyNums.slice(0, 5)}-${onlyNums.slice(5, 9)}`
}

export default phoneMask
