import {
  getInvoices,
  downloadInvoice,
  downloadCSVInvoice,
  downloadTicket,
  downloadFatura,
  requestBoleto
} from '../services/dashboardService'

import types from './types'

const { UPDATE_INVOICES, RESET_INVOICES, REQUEST_NEW_BOLETO } = types

export function fetchDashboardInvoices(planId, nextURL) {
  return async dispatch => {
    const invoices = await getInvoices(planId, nextURL)
    if (invoices && invoices.faturas) {
      dispatch({
        type: UPDATE_INVOICES,
        data: invoices.faturas,
        next: invoices.paginacao
      })
      return invoices
    }
    return {}
  }
}

export function requestNewBoleto(planId, invoiceId) {
  return async dispatch => {
    const { data } = await requestBoleto(planId, invoiceId)
    dispatch({
      type: REQUEST_NEW_BOLETO
    })
    return data
  }
}

export function downloadInvoiceCSVFile(planId, invoiceId) {
  return () => downloadCSVInvoice(planId, invoiceId).then(result => result)
}

export function downloadTicketFile(planId, invoiceId) {
  return () => downloadTicket(planId, invoiceId).then(result => result)
}

export function downloadInvoiceFile(planId, year, month) {
  return () => downloadInvoice(planId, year, month).then(result => result)
}

export function resetDashboardInvoices() {
  return dispatch =>
    dispatch({
      type: RESET_INVOICES
    })
}

export function downloadFaturaFile(planId, invoiceId) {
  return () => downloadFatura(planId, invoiceId).then(result => result)
}
