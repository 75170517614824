import React from 'react'

import Content from '../../../styles/objects/Content'
import { Icon } from '../../elements/Icon'

import { Arrow, Header, InnerContent, Label, ListItem, Title } from './styles'

const CollapsibleItem = ({ active, children, label, onClick, title }) => (
  <ListItem active={active}>
    <section>
      <Content>
        <Header onClick={onClick}>
          <Label>{label}</Label>
          <Title>{title}</Title>
          <Arrow>
            <Icon height="12px" icon="dropdown-arrow" viewBox="0 0 16 8" width="100%" />
          </Arrow>
        </Header>
      </Content>
      <InnerContent>{children}</InnerContent>
    </section>
  </ListItem>
)

export default CollapsibleItem
