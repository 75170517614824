import { gqlQuery, gqlMutate } from './common/apolloClient'
import { gqlResumo, resumoTransform } from './queries/resumo'
import { gqlRecharges, transformRecharges } from './queries/recharges'
import {
  gqlInvoices,
  gqlDownloadFaturaPdf,
  gqlDownloadFaturaCsv,
  gqlDownloadBoleto,
  gqlSolicitarBoleto,
  gqlNFSe
} from './queries/invoices'
import { gqlExtrato, gqlDownloadExtratoCsv, gqlDownloadExtratoPdf } from './queries/transactions'
import { gqlIdentificadores, transformIndentificadores } from './queries/vehicles'
import { getTipoTransacao, downloadBase64 } from './common/helpers'
import { gqlPlanData } from './queries/plans'

export * from '../services-middleware/dashboardService'

export async function getPlanResume(planId) {
  const resumo = resumoTransform(await gqlQuery(gqlResumo, { idConta: planId }), planId)
  return resumo
}

export async function getInvoices(planId, next) {
  const proxima = next || 1

  const payload = { idConta: planId, pagina: proxima }
  const { data = {} } = await gqlQuery(gqlInvoices, payload)

  const { historicoFaturas = {} } = data
  const { paginacao = {} } = historicoFaturas

  const response = {
    ...historicoFaturas,
    paginacao: paginacao && paginacao.proxima ? paginacao : {}
  }

  return response
}

export async function getRecharges(planId, _next) {
  const next = _next || 0
  const payload = { idConta: planId, skip: next }
  const { data = {} } = await gqlQuery(gqlRecharges, payload)

  const { historicoRecargas: { remaining = 0, recargas = [] } = {} } = data
  const recharges = transformRecharges(recargas)

  const response = {
    data: recharges,
    next: remaining ? next + recargas.length : null,
    totalCount: next + recargas.length + remaining
  }

  return response
}

export async function getStatements(planId, next, filter = {}) {
  let pagina = (next && next.pagina) || 1
  const QTD = 7
  const gqlVars = {
    pagina,
    ...(filter.plate && { placa: filter.plate }),
    ...(filter.month && { mes: `${filter.month}` }),
    ...(filter.type && { transacoes: [{ tipo: getTipoTransacao(filter.type) }] })
  }

  const response = await gqlQuery(gqlExtrato, { idConta: planId, quantidade: QTD, ...gqlVars })
  let responseCount = 0
  if (response && response.data && response.data.extrato && response.data.extrato.transacoes)
    responseCount = response.data.extrato.transacoes.length

  let nextAttrs = { hasNext: null, next: null }
  if (responseCount > 0) nextAttrs = { hasNext: 1, next: { pagina: pagina + 1 } }
  const { extrato } = response.data
  const { transacoes } = extrato
  const data = transacoes || []
  return { data, ...nextAttrs }
}

export async function getPlanData(plan) {
  const { id: idConta } = plan
  const { data = {} } = await gqlQuery(gqlPlanData, { idConta })

  const {
    valorMensalidade: { valor: monthlyPayment } = {},
    valorRecarga: { valor: recharge } = {},
    valorBonificacao: {
      dataFimBonificacao: bonificationEndDate,
      tipoBonificacao: bonificationType,
      valorBonificacao: bonificationVaue
    } = {}
  } = data
  return {
    monthlyPayment,
    recharge,
    bonificationEndDate,
    bonificationVaue,
    bonificationType
  }
}

export async function getVehicles(accountId) {
  const resp = await gqlQuery(gqlIdentificadores, { idConta: accountId })
  return transformIndentificadores(resp)
}

// TODO IMPLEMENTAR !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
export async function downloadInvoice(planId, year, month) {
  // const resp = await gqlQuery(gqlDownloadFaturaPdf, { idConta: planId })
  // const b64File = resp.data.download.result
  // return downloadBase64(b64File, 'application/pdf', 'fatura.pdf')
}

export async function downloadStatementCSV(planId, filter = {}) {
  let pagina = 1
  const QTD = 7
  const gqlVars = {
    pagina,
    ...(filter.plate && { placa: filter.plate }),
    ...(filter.month && { mes: `${filter.month}` }),
    ...(filter.type && { transacoes: [{ tipo: getTipoTransacao(filter.type) }] })
  }
  console.log('filter', filter)
  const resp = await gqlQuery(gqlDownloadExtratoCsv, {
    idConta: planId,
    quantidade: QTD,
    ...gqlVars
  })
  const b64File = resp.data.extratoCSV.result
  return downloadBase64(b64File, 'text/csv', 'extrato.csv')
}

export async function downloadStatement(planId, filter = {}) {
  let pagina = 1
  const QTD = 7
  const gqlVars = {
    pagina,
    ...(filter.plate && { placa: filter.plate }),
    ...(filter.month && { mes: `${filter.month}` }),
    ...(filter.type && { transacoes: [{ tipo: getTipoTransacao(filter.type) }] })
  }
  const resp = await gqlQuery(gqlDownloadExtratoPdf, {
    idConta: planId,
    quantidade: QTD,
    ...gqlVars
  })
  console.log({ resp })
  const b64File = resp.data.download.result
  return downloadBase64(b64File, 'application/pdf', 'extrato.pdf')
}

export async function downloadFatura(planId, invoiceId) {
  const resp = await gqlQuery(gqlDownloadFaturaPdf, {
    idConta: planId,
    numeroFatura: parseInt(invoiceId, 10)
  })
  console.log({ resp })
  const b64File = resp.data.downloadFaturaPdf && resp.data.downloadFaturaPdf.result
  if (!b64File) {
    const err = new Error('Erro ao baixar fatura')
    err.status = 404
    throw err
  }
  return downloadBase64(b64File, 'application/pdf', 'fatura.pdf')
}

export async function nfse(idSap) {
  const customerId = sessionStorage.getItem('customerId')
  if (customerId === '') {
  } else {
    var resp = await gqlQuery(gqlNFSe, {
      customerId: customerId,
      idSap: idSap
    })

    if (!resp) {
      const err = new Error('Error ao buscar NFSe')
      err.status = 404
      throw err
    }

    return resp
  }
}

export async function requestBoleto(idCliente, invoiceId) {
  const response = await gqlMutate(gqlSolicitarBoleto, {
    parametros: {
      idCliente: idCliente,
      nsu: invoiceId,
      origem: 'MINHA_VELOE'
    }
  })

  return response
}

export async function downloadCSVInvoice(planId, invoiceId) {
  const resp = await gqlQuery(gqlDownloadFaturaCsv, {
    idConta: planId,
    numeroFatura: parseInt(invoiceId, 10)
  })
  console.log({ resp })
  const b64File = resp.data.downloadFatura && resp.data.downloadFatura.result
  if (!b64File) {
    const err = new Error('Erro ao baixar fatura')
    err.status = 404
    throw err
  }
  return downloadBase64(b64File, 'text/csv', 'fatura.csv')
}

export async function downloadTicket(planId, nsu) {
  const resp = await gqlQuery(gqlDownloadBoleto, {
    idConta: planId,
    nsu: parseInt(nsu, 10)
  })
  let b64File = null
  let errObj = null
  const result = resp.data.downloadBoleto && resp.data.downloadBoleto.result
  try {
    errObj = JSON.parse(result)
  } catch (e) {
    b64File = result
  }
  if (errObj) {
    const err = new Error(errObj.message)
    err.statusCode = errObj.statusCode
    throw err
  }
  if (b64File) downloadBase64(b64File, 'application/pdf', 'boleto.pdf')
}
