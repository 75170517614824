import React from 'react'

import WarningBase from './base'
import illustrationManMegaphone from 'static/illustration-man-megaphone.svg'

const TollSemiautomatico = () => (
  <WarningBase
    content={
      <div>
        Ao chegar na praça de pedágio informe que irá pagar com <b>Veloe</b> e após a autorização do
        operador <b>aproxime o seu produto</b> no leitor disponível.*
        <br />
        <br />
        <b>*O pagamento por aproximação só está disponível nas cabines com arrecadação manual.</b>
      </div>
    }
    image={
      <img src={illustrationManMegaphone} className="WarningImage-megaphone" alt="man-megaphone" />
    }
  />
)

export default TollSemiautomatico
