import React from 'react'
import { Box, Flex } from 'grid-styled'
import { func, array, any } from 'prop-types'
import moment from 'moment'
// Assets
import rem from 'styles/tools/rem'
import Content from 'styles/objects/Content'
import { darkBlue } from 'styles/settings/colors'
// UIComponents
import Button from 'app/elements/Button'
import DotButton from 'app/elements/DotButton'
import Select from 'app/elements/Select'
// Styles
import {
  Title,
  Paragraph,
  Wrapper,
  EditHeader,
  NoContentText,
  ListItem,
  ListItemText
} from 'app/pages/EditIdentifiers/styles'
import { BoxEnd, RowButtonContainer, Options, Option, OptionsSeparator } from './styles'

const options = [
  { value: 'INFORMACAO_1', label: 'Informação 1' },
  { value: 'INFORMACAO_2', label: 'Informação 2' },
  { value: 'INFORMACAO_3', label: 'Informação 3' }
]

const ManageInfoList = ({
  onRowClick,
  data,
  rowActived,
  onEdit,
  onDelete,
  onChangeSelect,
  valueSelect,
  onAddInf,
  loadingDelete
}) => {
  const dayMonth = date => moment(date).format('DD/MM')
  const hourMinute = date => moment(date).format('HH:mm')
  return (
    <div>
      <Content>
        <Title>Informações Gerenciais</Title>
        <Paragraph>
          Para facilitar o gerenciamento das suas transações, você pode adicionar até 3 informações
          gerenciais por produto, que serão apresentadas nos seus extratos e faturas no formato .csv
        </Paragraph>
        <Flex>
          <Box width={[4 / 12]} mb={rem('20px')}>
            <Select
              value={valueSelect}
              onChange={onChangeSelect}
              options={options}
              label="Seleciona o tipo da informação"
            />
          </Box>
        </Flex>
      </Content>
      <Wrapper>
        <Content>
          <EditHeader>
            <Flex>
              <Box width={[4 / 12]} pl={rem('15px')}>
                Descrição
              </Box>
              <Box width={[2 / 12]}>Data</Box>
              <Box width={[2 / 12]}>Hora</Box>
            </Flex>
          </EditHeader>
        </Content>
      </Wrapper>
      <Content>
        {data.length > 0 &&
          data
            .filter(obj => obj.tipoInfo === valueSelect)
            .map(item => {
              const isActived = rowActived === item.id
              return (
                <ListItem key={item.id} isActived={isActived}>
                  <Flex width={1}>
                    <Box width={[4 / 12]} pl={rem('15px')}>
                      <ListItemText bold>{item.descricaoAtual}</ListItemText>
                    </Box>
                    <Box width={[2 / 12]}>
                      <ListItemText color={darkBlue} bold>
                        {dayMonth(item.dataInicioVigencia)}
                      </ListItemText>
                    </Box>
                    <Box width={[2 / 12]}>
                      <ListItemText>{hourMinute(item.dataInicioVigencia)}</ListItemText>
                    </Box>
                    <Options isActived={isActived}>
                      <Option
                        loading={loadingDelete}
                        onClick={() => {
                          onDelete(item)
                          window.dataLayer.push({
                            event: 'event',
                            eventAction: 'clique:apagar',
                            eventCategory: 'veloe:area-logado:meus-produtos:informacoes-gerenciais',
                            eventLabel: 'apagar',
                            dimension22: 'apagar'
                          })
                        }}
                      >
                        Apagar
                      </Option>
                      <OptionsSeparator>|</OptionsSeparator>
                      <Option
                        onClick={() => {
                          onEdit(item)
                          window.dataLayer.push({
                            event: 'event',
                            eventAction: 'clique:editar',
                            eventCategory: 'veloe:area-logado:meus-produtos:informacoes-gerenciais',
                            eventLabel: 'editar',
                            dimension22: 'editar'
                          })
                        }}
                      >
                        Editar
                      </Option>
                    </Options>
                    <RowButtonContainer isActived={isActived}>
                      <DotButton
                        onClick={() => {
                          onRowClick(item.id)
                          window.dataLayer.push({
                            event: 'event',
                            eventAction: 'clique:mais-opcoes',
                            eventCategory: 'veloe:area-logado:meus-produtos:informacoes-gerenciais',
                            eventLabel: 'mais-opcoes'
                          })
                        }}
                      />
                    </RowButtonContainer>
                  </Flex>
                </ListItem>
              )
            })}
        <Flex direction="column" justify="flex-end">
          <Box mt={rem('20px')}>
            {data.length === 0 && (
              <NoContentText>
                Você ainda não possui informações gerenciais adicionadas
              </NoContentText>
            )}
          </Box>
          <BoxEnd mt={rem('20px')}>
            <Button
              text="Add Informação"
              buttonType="darkBlue"
              onClick={() => {
                onAddInf()
                window.dataLayer.push({
                  event: 'event',
                  eventAction: 'clique:adicionar-informacao',
                  eventCategory: 'veloe:area-logado:meus-produtos:informacoes-gerenciais',
                  eventLabel: 'adicionar-informacao'
                })
              }}
            />
          </BoxEnd>
        </Flex>
      </Content>
    </div>
  )
}

ManageInfoList.defaultProps = {
  onRowClick: () => {},
  onEdit: () => {},
  onDelete: () => {},
  onChangeSelect: () => {},
  valueSelect: '',
  data: [],
  rowActived: null
}

ManageInfoList.propTypes = {
  onRowClick: func.isRequired,
  onEdit: func.isRequired,
  onDelete: func.isRequired,
  data: array.isRequired,
  onChangeSelect: func,
  valueSelect: any,
  rowActived: any
}

export default ManageInfoList
