import styled, { css } from 'styled-components'

import { title14Galano, title18Galano, txt12OpenSansLight } from '../../../styles/settings/types'

import { lighterGrey, darkGrey, linkNavyBlue } from '../../../styles/settings/colors'

import media from '../../../styles/tools/media'
import Content from '../../../styles/objects/Content'
import em from '../../../styles/tools/em'
import rem from '../../../styles/tools/rem'

export const Header = styled.header`
  cursor: pointer;
  display: flex;
  flex-direction: column;
  height: ${rem('90px')};
  justify-content: center;
  position: relative;
  user-select: none;
  width: 100%;

  ${media.large`
        height: ${rem('100px')};
    `};
`

export const Label = styled.p`
  ${txt12OpenSansLight};

  color: ${darkGrey};
  margin: 0 0 ${rem('8px')};
  text-transform: uppercase;

  ${media.large`
        letter-spacing: ${em('1px')};
        margin: 0 0 ${rem('10px')};
    `};
`

export const Title = styled.h2`
  ${title14Galano};

  color: ${linkNavyBlue};
  margin: 0;

  ${media.large`
        ${title18Galano};
    `};
`

export const Arrow = styled.i`
  color: ${linkNavyBlue};
  margin-right: ${rem('8px')};
  position: absolute;
  right: 0;
  text-align: center;
  top: 50%;
  transform: translateY(-50%) scaleY(1);
  width: ${rem('20px')};

  ${media.large`
        margin-right: ${rem('12px')};
        width: ${rem('24px')};
    `};
`

export const InnerContent = styled(Content)`
  max-height: 0;
  margin-bottom: 0;
  overflow: hidden;
  transition: 0.6s max-height ease-in-out, 0.6s margin-bottom ease-in-out;
`

export const ListItem = styled.li`
  border-bottom: ${rem('1px')} solid ${lighterGrey};
  list-style: none;
  margin: 0;
  padding: 0;

  :first-child {
    border-top: ${rem('1px')} solid ${lighterGrey};
  }

  ${({ active }) =>
    active &&
    css`
      ${InnerContent} {
        margin-bottom: ${rem('24px')};
        max-height: ${rem('1000px')};

        ${media.large`
                margin-bottom: ${rem('40px')};
            `};
      }

      ${Arrow} {
        transform: translateY(-50%) scaleY(-1);
      }
    `};
`
