import gql from 'graphql-tag'
import moment from 'moment/moment'
import { transformObject, getTipoConta } from '../common/helpers'

const formatDateResumo = date => moment(date).format('HH:mm - DD/MM/YYYY')

export const gqlResumo = gql`
  query ResumoV2($idConta: String!) {
    contas {
      tipo
      conta
    }
    resumoV2(idConta: $idConta) {
      resumo {
        transacoesAgrupadas {
          ESTACIONAMENTO
          PEDAGIO
          ABASTECIMENTO
        }
      }
      identificadores {
        nomeUsuario
        nuIdentificador
        placaVeiculo
        valorConsumido
      }
    }
  }
`

const resumoMap = {
  // type: 'contas[0].tipo', buscar conta pelo id
  // 'balance.date': data atual
  'balance.spending.fuel': 'resumo.transacoesAgrupadas.ABASTECIMENTO',
  'balance.spending.park': 'resumo.transacoesAgrupadas.ESTACIONAMENTO',
  'balance.spending.toll': 'resumo.transacoesAgrupadas.PEDAGIO',
  vehicles: 'identificadores' // TODO identificadores (validar formato dos atributos)
}

export const resumoTransform = (src, idConta) => {
  const resumo = transformObject(src.data.resumoV2, resumoMap)
  const conta = src.data.contas.find(c => c.conta === idConta)
  resumo.type = getTipoConta(conta.tipo)
  const { balance } = resumo
  const { spending } = balance
  balance.date = formatDateResumo(new Date())
  spending.fuel = spending.fuel || 0
  spending.park = spending.park || 0
  spending.toll = spending.toll || 0
  // resumo.vehicles = []
  return resumo
}
