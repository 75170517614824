export default {
  __html: `
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
    <g fill="none" fill-rule="evenodd">
        <rect width="16" height="16" fill="#9000A3" rx="8"/>
        <g fill="#D37EDF" fill-rule="nonzero" transform="translate(7 3)">
            <path d="M.886 6.429A.89.89 0 0 1 0 5.543V.886C0 .4.4 0 .886 0c.485 0 .885.4.885.886v4.657c0 .514-.4.886-.885.886z"/>
            <circle cx=".914" cy="8.914" r="1"/>
        </g>
    </g>
</svg>
`
}
