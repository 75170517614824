import TemplateEmpty from '../app/templates/TemplateEmpty'
import EditDueDate from '../app/pages/EditDueDate'

export default {
  path: '/minha-veloe/:id/data-de-vencimento',
  component: TemplateEmpty,
  routes: [
    {
      path: '/minha-veloe/:id/data-de-vencimento',
      exact: true,
      component: EditDueDate
    }
  ]
}
