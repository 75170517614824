import React from 'react'
import LoadMore from '../../elements/LoadMore'
import RechargeListItem from '../../elements/RechargeListItem'
import { ListContainer, ListFooter } from './styles'

const InvoicesList = ({ data, next, loadMoreHandler }) => (
  <ListContainer id="lctRechargeTable">
    {data.map(recharge => (
      <RechargeListItem key={recharge.id} {...recharge} />
    ))}
    {next && (
      <ListFooter>
        <LoadMore onClick={loadMoreHandler} />
      </ListFooter>
    )}
  </ListContainer>
)

export default InvoicesList
