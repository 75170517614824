import Home from '../../app/pages/Home'
import authentication from '../authentication'
import carPlate from './carPlate'
import dashboard from './dashboard'
import requestFile from './requestFile'

export default [
  {
    exact: true,
    path: '/',
    component: Home
  },
  authentication,
  carPlate,
  requestFile,
  dashboard
]
