import React, { Component } from 'react'
import { connect } from 'react-redux'
import { TransitionGroup, CSSTransition } from 'react-transition-group'
import { fetchGetUser, fetchGetBusinessUser, fetchAccountPlans } from 'alelo-logic/actions'

import Header from '../../modules/Header'
import FooterSmall from '../../elements/FooterSmall'

import { RouteWithSubRoutes } from '../../../routes'

class TemplateLayout extends Component {
  constructor(props) {
    super(props)
    this.state = {
      user: null
    }
  }

  componentDidMount() {
    this.props.getAccountPlans()
    this.props.getUser(this.props.account.accountType)
  }

  shouldComponentUpdate(nextProps) {
    const hasChange = nextProps.location.pathname !== this.props.location.pathname
    return hasChange
  }

  render() {
    const { routes, location, account } = this.props
    return (
      <div>
        <div className="empty-template">
          <Header userCancelled={!account.active} />
        </div>
        <TransitionGroup>
          <CSSTransition key={location.key} timeout={500} exit={false} classNames="fade-in">
            <div>
              {routes.map(route => (
                <RouteWithSubRoutes user={this.state.user} key={route.path} {...route} />
              ))}
            </div>
          </CSSTransition>
        </TransitionGroup>
        <div className="empty-template">
          <FooterSmall />
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  account: state.reducer.account,
  user: state.reducer.user
})

const mapDispatchToProps = dispatch => ({
  getAccountPlans: () => dispatch(fetchAccountPlans()),
  getUser: userType => {
    if (userType === 'pf') {
      dispatch(fetchGetUser())
    } else {
      dispatch(fetchGetBusinessUser())
    }
  }
})

export default connect(mapStateToProps, mapDispatchToProps)(TemplateLayout)
