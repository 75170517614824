import React from 'react'
import { connect } from 'react-redux'
import Loader from '../../elements/Loader'

import { LoaderContainer, ContentContainer } from './styles'

function PageLoaderContent(props) {
  return props.content
}

function StatementsFilter({ children, pageLoader }) {
  const { isLoading } = pageLoader

  return (
    <div>
      <LoaderContainer isLoading={isLoading}>
        <Loader />
      </LoaderContainer>
      <ContentContainer isLoading={isLoading}>
        <PageLoaderContent content={children} />
      </ContentContainer>
    </div>
  )
}

const mapStateToProps = ({ pageLoader }) => ({
  pageLoader: pageLoader
})

export default connect(mapStateToProps)(StatementsFilter)
