import styled from 'styled-components'
import { Link } from 'react-router-dom'

import { linkNavyBlue, lighterGrey, darkGrey } from '../../../styles/settings/colors'
import {
  title24GalanoBold,
  title40GalanoBold,
  txt12OpenSansLight,
  txt14OpenSans
} from '../../../styles/settings/types'
import media from '../../../styles/tools/media'
import rem from '../../../styles/tools/rem'
import content from '../../../styles/_objects/content'

export const IconColor = `fill: ${linkNavyBlue}`

export const Content = styled.section`
  ${content};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

export const BreadCrumb = styled.div`
    ${txt14OpenSans}
    border-bottom: ${rem('1px')} solid ${lighterGrey};
    display: block;
    font-weight: 700;
    letter-spacing: ${rem('-0.4px')};
    padding:${rem('16px')} 0;
    @media print {
      display: none;
    }

    ${Content} {
        align-items: center;
        justify-content: left;
    }
`

export const Back = styled(Link)`
  color: ${linkNavyBlue};
  cursor: pointer;
  display: table;
  text-decoration: none;
  margin: ${rem('18px')} 0;

  * {
    display: table-cell;
    vertical-align: middle;
  }

  svg {
    max-width: ${rem('40px')};
  }
`

export const ResumeTitle = styled.div`
  padding: ${rem('42px')} 0;
`

export const Text = styled.p`
  ${txt12OpenSansLight};

  color: ${darkGrey};
  margin: 0;
  position: relative;
  text-transform: uppercase;
  top: ${rem('3px')};
`

export const Title = styled.p`
  ${title24GalanoBold};

  color: ${linkNavyBlue};
  margin: 0;
  text-align: right;

  ${media.large`
        ${title40GalanoBold};
        margin-top: ${rem('10')};
        text-align: left;
    `};
`

export const NavHeader = styled.div`
  align-items: center;
  display: flex;
  position: relative;
  top: ${rem('16px')};
  @media print {
    display: none;
  }

  .print {
    display: block;
  }

  ${media.large`
        .print {
            display: block;
        }
    `};
`

export const NavIcon = styled.a`
  cursor: pointer;
  display: inline-block;
  height: ${rem('45px')};
  width: ${rem('45px')};
  @media print {
    display: none;
  }

  img {
    width: 100%;
    height: auto;
  }

  :not(:first-child) {
    margin-left: ${rem('28px')};
  }
`
