import React, { Component } from 'react'
import Helmet from 'react-helmet'
import { connect } from 'react-redux'
import { Box } from 'grid-styled'
import _ from 'lodash'
import moment from 'moment'
import {
  fetchLogout,
  fetchPlanData,
  fetchPaymentInfo,
  fetchTermsAndConditions,
  fetchDashboardResume,
  fetchBankList,
  checkEditAuthorizationPayment,
  simulateNextPaymentTypeDate,
  getLastPaymentScheduling,
  fetchPaymentType,
  fetchDueDates,
  fetchGetUser,
  fetchGetBusinessUser,
  fetchAccountPlans,
  toggleFooter,
  fetchSubsidyMessage
} from 'alelo-logic/actions'

import PlanResume from 'app/modules/PlanResume'
// import IconTextCarousel from 'app/modules/IconTextCarousel'
import CallDownloadTerms from 'app/modules/CallDownloadTerms'
import { setLoading } from 'app/modules/PageLoader/actions'

import EditDueDate from 'app/pages/EditDueDate'
import EditPaymentMethod from 'app/pages/EditPaymentMethod'

import {
  /*   Separator, */
  Container,
  /*   FinancialInformation, */
  // QrCodeBBBar,
  // QrCodeInnerWrapper,
  // QrCodeBBText,
  // QrCodeLink,
  // QrCodeImage,
  Notice,
  NoticeText
  /*   BannerInfoPagApp */
} from './styles'
import Icon from 'app/elements/Icon/Icon'
import Hide from 'styles/utilities/Hide'
import Content from 'styles/objects/Content'
// Utils
import { selectUserCurrentAccount } from 'utils/selectors'
import BannerInfoPaymentApp from '../../modules/BannerInfoPayment'

// const { dados, dadosSemiautomatico } = require('./data.json')

class PlanData extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showEditDueDate: false,
      showEditPaymentMethod: false,
      mounted: false
    }
  }

  async componentDidMount() {
    const { accountPlans, match, setLoading } = this.props
    const { id } = match.params
    await setLoading(true)
    const plan = accountPlans.plans.find(this.applyFilter)
    const accountId = (plan && plan.accountId) || id
    await this.props.fetchBankList()
    await this.props.getUser(this.props.account.accountType)
    await this.props.getPaymentInfo(accountId)

    await this.props.getLastPaymentScheduling(accountId)
    await this.checkAuthorizations(accountId)
    await this.props.simulateNextPaymentTypeDate(accountId)

    await this.props.fetchPaymentType()
    await this.props.fetchDueDates()
    await this.props.downloadTermsAndConditions()
    if (plan && !plan.paymentType) {
      this.changeForType()
    }
    await this.props.fetchPlanData(plan || { id })
    await this.props.fetchSubsidyMessage(accountId)
    await setLoading(false)
    this.setState({ mounted: true })
  }

  componentDidUpdate(prevProps, prevState) {
    const { currAccount } = this.props
    if (prevState.mounted !== this.state.mounted && currAccount && !currAccount.paymentType) {
      this.changeForType()
    }
  }

  changeForType = () => {
    this.setState({
      showEditPaymentMethod: true
    })
  }

  checkAuthorizations = async accountId => {
    const { plandata, checkEditAuthorizationPayment } = this.props
    if (_.isEmpty(plandata.authorizations)) {
      await checkEditAuthorizationPayment('DATA', accountId)
    } else {
      if (plandata.authorizations.DATA && !plandata.authorizations.DATA.error) {
        await checkEditAuthorizationPayment('DATA', accountId)
      }
    }
  }

  shouldComponentUpdate(nextProps) {
    const hasPlanChange =
      this.props.accountPlans.activePlanId !== nextProps.accountPlans.activePlanId

    if (hasPlanChange) {
      this.props.fetchResume(this.props.match.params.id)
    }

    return true
  }

  applyFilter = checkPlan => {
    return checkPlan.id.toString() === this.props.accountPlans.activePlanId.toString()
  }

  toggleEditPaymentForm = () => {
    const { toggleFooter } = this.props
    this.setState(
      prevState => ({
        showEditPaymentMethod: !prevState.showEditPaymentMethod
      }),
      () => toggleFooter()
    )
  }

  toggleEditDueDateForm = () => {
    const { toggleFooter } = this.props
    this.setState(
      prevState => ({
        showEditDueDate: !prevState.showEditDueDate
      }),
      () => toggleFooter()
    )
  }

  filterPFPaymentTypes = availablePaymentTypes => {
    return availablePaymentTypes
      ? {
          formas: availablePaymentTypes.formas.filter(
            paymentType => paymentType.forma !== 'DISPOSICION'
          )
        }
      : []
  }

  renderScheduleNotice = () => {
    const { plandata, editPaymentData } = this.props
    if (editPaymentData.paymentFormChange && editPaymentData.paymentFormChange.agendado) {
      return (
        <Box width={[1, 540]} mt={30}>
          <Notice>
            <Icon height={28} icon="info-purple" viewBox="0 0 24 28" />
            <NoticeText>
              As alterações serão aplicadas somente na fatura com vencimento em{' '}
              {moment(editPaymentData.paymentFormChange.dataAgendamento, 'YYYY-MM-DD').format(
                'DD/MM/YYYY'
              )}
            </NoticeText>
          </Notice>
        </Box>
      )
    }
    if (plandata && plandata.paymentSchedule && plandata.paymentSchedule.vencimentoFatura) {
      return (
        <Box width={[1, 540]} mt={30}>
          <Notice>
            <Icon height={28} icon="info-purple" viewBox="0 0 24 28" />
            <NoticeText>
              As alterações serão aplicadas somente na fatura com vencimento em{' '}
              {moment(plandata.paymentSchedule.vencimentoFatura, 'YYYY-MM-DD').format('DD/MM/YYYY')}
            </NoticeText>
          </Notice>
        </Box>
      )
    }
    return null
  }

  returnIfAccountHasPayment = () => {
    const { currAccount } = this.props
    return currAccount && !!currAccount.paymentType
  }

  handleFinish = async () => {
    const { currAccount, getAccountPlans, history, getPaymentInfo, setLoading } = this.props
    if (!this.returnIfAccountHasPayment()) {
      setLoading(true)
      await getAccountPlans()
      await getPaymentInfo(currAccount.accountId)
      history.push(`/minha-veloe/${currAccount.id}/resumo`)
    } else {
      setLoading(true)
      await getAccountPlans()
      await getPaymentInfo(currAccount.accountId)
      this.toggleEditPaymentForm()
      setLoading(false)
    }
  }

  handleEditPaymentBack = () => {
    if (!this.returnIfAccountHasPayment()) {
      this.props.doLogout()
      this.props.history.push('/conta/login')
    } else {
      this.toggleEditPaymentForm()
    }
  }

  render() {
    const { showEditDueDate, showEditPaymentMethod } = this.state
    const { accountPlans, plandata } = this.props
    const { id } = this.props.match.params
    const currentPlan = accountPlans.plans[0] ? accountPlans.plans.find(this.applyFilter) : ''
    // const isBB = currentPlan && currentPlan.debitPaymentData.bankCode === '1'
    const paymentTypes = this.filterPFPaymentTypes(plandata.paymentTypes)

    // SEMIAUTOMATICO
    // const identifiers = (currentPlan && currentPlan.identifiers) || []
    // const identifiersTypes = identifiers && _.map(identifiers, 'identifierType')
    // const semiautomaticoTypes = ['BAND', 'SMART_BAND']
    // const isSemiautomatico = _.some(semiautomaticoTypes, t => _.includes(identifiersTypes, t))
    const parceiroComercial = currentPlan ? currentPlan.nomeParceiroComercial : ''

    // REMOVENDO INFORMAÇÕES DE PAGAMENTO E ADICIONANDO BANNER DIRECIONANDO PARA APP

    /*     return (
      <Content>
        <Helmet title="Veloe" />
        {showEditDueDate ? (
          <Container editMode justifyContent="center">
            <EditDueDate
              currentPlan={currentPlan}
              dueDates={plandata.dueDates ? plandata.dueDates.sort((a, b) => a.dia - b.dia) : []}
              onBackButtonClick={() => this.toggleEditDueDateForm()}
            />
          </Container>
        ) : showEditPaymentMethod ? (
          <Container editMode justifyContent="center">
            <EditPaymentMethod
              currentPlan={currentPlan}
              accountHasPayment={this.returnIfAccountHasPayment()}
              paymentTypes={paymentTypes}
              nextPaymentDate={plandata.nextPaymentDate}
              parceiroComercial={parceiroComercial}
              onBackButtonClick={this.handleEditPaymentBack}
              onFinish={this.handleFinish}
              fetchPaymentInfo={() =>
                this.props.getPaymentInfo((currentPlan && currentPlan.accountId) || id)
              }
              fetchDebitPaymentInfo={this.props.getAccountPlans}
            />
          </Container>
        ) : (
          <Container>
            <Box width={[1, 3 / 12]}>
              <PlanResume showSummary />
              <Hide at="small">
                <CallDownloadTerms />
              </Hide>
            </Box>
            <FinancialInformation>
              {currentPlan && (
                <PaymentMethods
                  currentPlan={currentPlan}
                  plandata={plandata}
                  creditPaymentInfo={creditPaymentInfo}
                  accountType={account.accountType}
                  toggleEditDueDateForm={() => this.toggleEditDueDateForm()}
                  toggleEditPaymentForm={() => this.toggleEditPaymentForm()}
                />
              )}
              {this.renderScheduleNotice()}
              <Separator />

              {subsidyMessage.mensagemFrontPrimeiraTag && subsidyMessage.mensagemFrontDemaisTag && (
                <PaymentPolicy
                  messageTag1={subsidyMessage.mensagemFrontPrimeiraTag}
                  messageTag2={subsidyMessage.mensagemFrontDemaisTag}
                ></PaymentPolicy>
              )}

              {/* <IconTextCarousel data={isSemiautomatico ? dadosSemiautomatico : dados} /> }
              <Hide at="large">
                <CallDownloadTerms />
              </Hide>
            </FinancialInformation>
          </Container>
        )}
      </Content>
    ) */

    return (
      <Content>
        <Helmet title="Veloe" />
        {showEditDueDate ? (
          <Container editMode justifyContent="center">
            <EditDueDate
              currentPlan={currentPlan}
              dueDates={plandata.dueDates ? plandata.dueDates.sort((a, b) => a.dia - b.dia) : []}
              onBackButtonClick={() => this.toggleEditDueDateForm()}
            />
          </Container>
        ) : showEditPaymentMethod ? (
          <Container editMode justifyContent="center">
            <EditPaymentMethod
              currentPlan={currentPlan}
              accountHasPayment={this.returnIfAccountHasPayment()}
              paymentTypes={paymentTypes}
              nextPaymentDate={plandata.nextPaymentDate}
              parceiroComercial={parceiroComercial}
              onBackButtonClick={this.handleEditPaymentBack}
              onFinish={this.handleFinish}
              fetchPaymentInfo={() =>
                this.props.getPaymentInfo((currentPlan && currentPlan.accountId) || id)
              }
              fetchDebitPaymentInfo={this.props.getAccountPlans}
            />
          </Container>
        ) : (
          <Container>
            <Box width={[1, 3 / 12]}>
              <PlanResume showSummary />
              <Hide at="small">
                <CallDownloadTerms />
              </Hide>
            </Box>
            <BannerInfoPaymentApp />
          </Container>
        )}
      </Content>
    )
  }
}

const mapStateToProps = ({ reducer }) => {
  return {
    account: reducer.account,
    resume: reducer.dashboardResume,
    accountPlans: reducer.accountPlans,
    plandata: reducer.planData,
    editPaymentData: reducer.editPaymentData,
    creditPaymentInfo: reducer.recharge.paymentInfo,
    subsidyMessage: reducer.subsidyMessage,
    currAccount: selectUserCurrentAccount(reducer)
  }
}

const mapDispatchToProps = dispatch => ({
  // openQrCodeModal: () => dispatch(showModal(QRCODE_MODAL, {})),
  getUser: userType => {
    if (userType === 'pf') {
      dispatch(fetchGetUser())
    } else {
      dispatch(fetchGetBusinessUser())
    }
  },
  getPaymentInfo: accountPlanId => dispatch(fetchPaymentInfo(accountPlanId)),
  fetchResume: (token, planId) => dispatch(fetchDashboardResume(token, planId)),
  getLastPaymentScheduling: accountPlanId =>
    dispatch(getLastPaymentScheduling('FORMA', accountPlanId)),
  checkEditAuthorizationPayment: (type, accountPlanId) =>
    dispatch(checkEditAuthorizationPayment(type, accountPlanId)),
  simulateNextPaymentTypeDate: accountPlanId =>
    dispatch(simulateNextPaymentTypeDate(accountPlanId)),
  fetchPlanData: plano => dispatch(fetchPlanData(plano)),
  fetchPaymentType: () => dispatch(fetchPaymentType()),
  downloadTermsAndConditions: () => dispatch(fetchTermsAndConditions()),
  fetchDueDates: () => dispatch(fetchDueDates()),
  setLoading: isLoading => {
    dispatch(setLoading(isLoading))
  },
  fetchBankList: () => dispatch(fetchBankList()),
  getAccountPlans: () => dispatch(fetchAccountPlans()),
  toggleFooter: () => dispatch(toggleFooter()),
  doLogout: () => dispatch(fetchLogout()),
  fetchSubsidyMessage: accountId => dispatch(fetchSubsidyMessage(accountId))
})

export default connect(mapStateToProps, mapDispatchToProps)(PlanData)
