import React from 'react'
import { func, string, bool } from 'prop-types'
import { DotButton, DotIcon } from './styles'

const DotButtonComponent = ({ onClick = () => {}, color = '', removeCursor = false }) => (
  <DotButton onClick={onClick} removeCursor={removeCursor}>
    <DotIcon color={color}>&nbsp;</DotIcon>
  </DotButton>
)

DotButtonComponent.propTypes = {
  onClick: func,
  removeCursor: bool,
  color: string
}

export default DotButtonComponent
