/* eslint-disable */

import { string } from 'prop-types'

function ChooseAvatar() {
  return null
}

ChooseAvatar.defaultProps = {
  label: null
}

ChooseAvatar.propTypes = {
  label: string
}

export default ChooseAvatar
