import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { TransitionGroup, CSSTransition } from 'react-transition-group'
import { fetchGetUser, fetchGetBusinessUser, fetchAccountPlans } from 'alelo-logic/actions'

import Header from '../../modules/Header'
import Submenu from '../../elements/Submenu'
import FooterSmall from '../../elements/FooterSmall'

import { RouteWithSubRoutes } from '../../../routes'

const { subnavLinks } = require('./data.json')

function TemplateLayout({ getAccountPlans, getUser, account, ...props }) {
  const [user] = useState()
  useEffect(() => {
    getAccountPlans()
    const res = getUser(account.accountType)
    console.log(res)
  }, [getAccountPlans, getUser, account])

  const { routes, location } = props

  return (
    <div>
      <div className="empty-template">
        <Header userCancelled={!account.active} />
        {account.active && (
          // <Submenu lastItemOnRight navItems={subnavLinks[account.accountType]} />
          // Substituir a linha abaixo pela linha de cima, quando for voltar o menu de cancelar conta, e voltar os links no json do arquivo TemplateLayout/data.json
          <Submenu navItems={subnavLinks[account.accountType]} />
        )}
      </div>
      <TransitionGroup>
        <CSSTransition key={location.key} timeout={500} exit={false} classNames="fade-in">
          <div>
            {routes.map(route => (
              <RouteWithSubRoutes user={user} key={route.path} {...route} />
            ))}
          </div>
        </CSSTransition>
      </TransitionGroup>
      <div className="empty-template">
        <FooterSmall />
      </div>
    </div>
  )
}

const mapStateToProps = state => ({
  account: state.reducer.account,
  user: state.reducer.user
})

const mapDispatchToProps = dispatch => ({
  getAccountPlans: () => dispatch(fetchAccountPlans()),
  getUser: userType => {
    if (userType === 'pf') {
      dispatch(fetchGetUser())
    } else {
      dispatch(fetchGetBusinessUser())
    }
  }
})

export default connect(mapStateToProps, mapDispatchToProps)(TemplateLayout)
