import React from 'react'
import { Box, Flex } from 'grid-styled'
import Content from 'styles/objects/Content'
import pT from 'prop-types'
// Styles
import { VehicleInfo } from './styles'
import { Wrapper, EditHeader, StatusWrapper } from 'app/pages/EditIdentifiers/styles'

const Status = (status, data) => (
  <StatusWrapper active={status === 'ATIVO'}>
    <span>{status === 'ATIVO' ? 'Ativa' : `Bloqueada ${data ? ' em' : ''}`}</span>
    {data && status !== 'ATIVO' ? <span>{data}</span> : ''}
  </StatusWrapper>
)

const Header = ({ vehicle }) => {
  return (
    <div>
      <Wrapper>
        <Content>
          <EditHeader>
            <Flex>
              <Box width={[3 / 12]}>veículo</Box>
              <Box width={[2 / 12]}>placa</Box>
              <Box width={[2 / 12]}>eixos</Box>
              <Box width={[2 / 12]}>produto</Box>
              <Box width={[2 / 12]}>status</Box>
            </Flex>
          </EditHeader>
        </Content>
      </Wrapper>
      <Content>
        <VehicleInfo>
          <Flex align="center">
            <Box width={[3 / 12]}>
              <strong>{vehicle.veiculo && vehicle.veiculo.tipo}</strong>
            </Box>
            <Box width={[2 / 12]}>{vehicle.veiculo && vehicle.veiculo.placa}</Box>
            <Box width={[2 / 12]}>{vehicle.veiculo && vehicle.veiculo.categoria}</Box>
            <Box width={[2 / 12]}>{vehicle.codIdentificador}</Box>
            <Box width={[2 / 12]}>{Status(vehicle.situacao)}</Box>
          </Flex>
        </VehicleInfo>
      </Content>
    </div>
  )
}

Header.defaultProps = {
  vehicle: {
    veiculo: {
      tipo: '',
      placa: '',
      categoria: ''
    },
    codIdentificador: '',
    situacao: ''
  }
}

Header.propTypes = {
  vehicle: pT.shape({
    veiculo: pT.shape({
      tipo: pT.string,
      placa: pT.string,
      categoria: pT.string
    }),
    codIdentificador: pT.string,
    situacao: pT.string
  }).isRequired
}

export default Header
