import React from 'react'
import googleAppImage from '../../../static/botao-google-app.png'
import { ButtomGoogleAppStyle } from './styles'

const ButtomGoogleApp = () => {
  return (
    <a href="https://play.google.com/store/apps/details/?id=br.com.veloe.mobile">
      {' '}
      <ButtomGoogleAppStyle
        src={googleAppImage}
        alt="Botao App na Google Play Store"
      ></ButtomGoogleAppStyle>{' '}
    </a>
  )
}

export default ButtomGoogleApp
