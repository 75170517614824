import React from 'react'
import { Container, Input, Label, Text } from './styles'

const RoundedCheck = ({ checked, label, name, ...props }) => {
  const inputProps = props.input
  return (
    <Label>
      <Container checked={props.input ? props.input.checked : checked} role="presentation">
        <Input {...inputProps} type="radio" name={name} />
        <Text checked={props.input ? props.input.checked : checked}>{label}</Text>
      </Container>
    </Label>
  )
}

export default RoundedCheck
