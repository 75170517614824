import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import Helmet from 'react-helmet'
import { utils } from 'alelo-logic'
import { Box, Flex } from 'grid-styled'
import ImersiveHeader from '../../elements/ImersiveHeader'
import Content from '../../../styles/objects/Content'
import Button from '../../elements/Button'
import Hide from '../../../styles/utilities/Hide'

import { Container, Title, Paragraph, Separator, Protocol, Date, Illustration } from './styles'

import car from '../../../static/car.png'

const { text, title, helmetTitle } = require('./data.json')

const CancelPlanConfirmation = ({ accountPlans, match, history }) => {
  const plan = accountPlans.plan || {}
  const cancelation = plan.cancelation || {}

  return (
    <section>
      <Helmet title={helmetTitle} />
      <ImersiveHeader isColored text="Meus planos" url={`/minha-veloe/${match.params.id}/resumo`} />
      <Container>
        <Content>
          <Flex direction="row">
            <Box width={1}>
              <Title>{title}</Title>
              <Paragraph>{text}</Paragraph>
              <Separator />
              <Protocol>{cancelation.protocol}</Protocol>

              {cancelation.date && (
                <Date>
                  Salvo em {`${utils.ISODateFormat(cancelation.date)} `}
                  às {utils.ISODateFormat(cancelation.date, 'HH:mm')}
                </Date>
              )}

              <Box mt={32} mb={37}>
                <Button
                  buttonType="transparent"
                  onClick={() => history.push(`/minha-veloe/${match.params.id}/resumo`)}
                  text="Ok"
                  width={140}
                />
              </Box>
            </Box>
            <Box>
              <Hide at="small">
                <Illustration>
                  <img src={car} alt="semáforo" />
                </Illustration>
              </Hide>
            </Box>
          </Flex>
        </Content>
      </Container>
    </section>
  )
}

const mapStateToProps = ({ reducer }) => ({
  accountPlans: reducer.accountPlans
})

export default connect(mapStateToProps)(withRouter(CancelPlanConfirmation))
