import { verifyRecoverTokenStatus } from '../services/accountService'

import types from './types'

const { UPDATE_RECOVER_TOKEN_STATUS } = types

export default function fetchRecoverTokenStatus(token) {
  return dispatch =>
    verifyRecoverTokenStatus(token)
      .then(recoverTokenStatus => {
        dispatch({
          type: UPDATE_RECOVER_TOKEN_STATUS,
          recoverTokenStatus: recoverTokenStatus
        })
      })
      .catch(err => {
        err.json().then(response => {
          dispatch({
            type: UPDATE_RECOVER_TOKEN_STATUS,
            recoverTokenStatus: response
          })
        })
      })
}
