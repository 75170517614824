import styled from 'styled-components'

import rem from 'styles/tools/rem'
import media from 'styles/tools/media'
import { white } from 'styles/settings/colors'
import { title24Galano } from 'styles/settings/types'

import { PageTitle } from '../../templates/TemplateRecharge/styles'

export const Title = styled(PageTitle)`
  line-height: 50px;
  max-width: ${rem('240px')};
  ${media.small`
    line-height: normal;
    padding: 0;
    max-width: 100%;
  `};
`

export const Currency = styled.span`
  ${title24Galano};
  color: ${white};
  font-weight: bold;
  position: absolute;
  top: 30%;
  left: 80px;

  ${media.small`
    mmin-height: 227px !important;
    top: 25%;
    left: 30px;
  `};

  ${media.large`
    font-size: ${rem('50px')};
    letter-spacing: ${rem('-1.1px')};
  `};
`
