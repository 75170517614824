import styled, { keyframes } from 'styled-components'

import { skyBlue, lightGrey, darkGrey, white } from '../../../styles/settings/colors'

import rem from '../../../styles/tools/rem'

const animateCheck = keyframes`
    0% {
        transform: translateX(-50%) translateY(-50%) scale(0.6);
    }

    40% {
        transform: translateX(-50%) translateY(-50%) scale(1.3);
    }

    70% {
        transform: translateX(-50%) translateY(-50%) scale(.8);
    }

    100% {
        transform: translateX(-50%) translateY(-50%) scale(1);
    }
`

export const CheckIcon = styled.i`
  animation: 0.4s ${animateCheck} forwards;
  color: white;
  display: none;
  height: auto;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translateX(-50%) translateY(-50%) scale(1);
  width: auto;
`

export const Box = styled.span`
  border: solid ${rem('1px')} ${props => (props.negative ? `${white}` : `${lightGrey}`)};
  border-radius: ${rem('4px')};
  box-shadow: 0;
  display: block;
  height: ${rem('16px')};
  min-width: ${rem('16px')};
  min-height: ${rem('16px')};
  position: relative;
  transition: 0.3s box-shadow;
  width: ${rem('16px')};

  :not(:last-child) {
    margin-right: ${rem('15px')};
  }
`

export const Label = styled.label`
  align-items: center;
  cursor: pointer;
  display: inline-flex;
  user-select: none;

  ${props =>
    props.disabled &&
    `
        opacity: 0.4;
    `};

  :hover {
    ${Box} {
      box-shadow: 0 0 0 ${rem('5px')} ${skyBlue} inset;
    }
  }
`

export const Text = styled.span`
  color: ${darkGrey};
  display: inline-block;
  font-family: OpenSans;
  font-size: ${rem('16px')};
  font-weight: 200;
  letter-spacing: ${rem('-0.4px')};
  line-height: 1.5;
`

export const Input = styled.input`
  display: none;

  :checked + ${Box} {
    border-color: ${props => (props.negative ? `${white}` : `${lightGrey}`)};
    box-shadow: 0 0 0 ${rem('50px')} ${skyBlue} inset;

    ${CheckIcon} {
      display: block;
    }
  }
`
