const contactFactory = {
  toAPI: (data, nome) => {
    return (
      data && {
        email: {
          enderecoEletronico: data.email,
          newsletter: data.optInPromotions
        },
        telefone: {
          ddd: data.phoneddd,
          numero: data.phone,
          tipoTelefone: 'Telefone Residencial'
        },
        celular: {
          ddd: data.mobileddd,
          numero: data.mobile,
          tipoTelefone: 'Celular'
        },
        nome
      }
    )
  },
  toForm: data =>
    data && {
      mobileddd: data.celular && data.celular.ddd,
      mobile: data.celular && data.celular.numero,
      phoneddd: data.telefone && data.telefone.ddd,
      phone: data.telefone && data.telefone.numero,
      email: data.email && data.email.enderecoEletronico,
      checkbox: data.email && data.email.newsletter
    }
}

export default contactFactory
