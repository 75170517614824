import React from 'react'
import IconPlus from './icon-plus.png'
import { ItemIcon, Label, Container, LabelLoading } from './styles'

const LoadMore = ({ onClick, w, loadingMore }) => (
  <div>
    {loadingMore ? (
      <LabelLoading>carregando</LabelLoading>
    ) : (
      <Container width={`${w}px`} className="load-more-container">
        <ItemIcon id="btnLoadMore" onClick={onClick}>
          <img src={IconPlus} alt="" />
        </ItemIcon>
        <Label>Carregar mais</Label>
      </Container>
    )}
  </div>
)

export default LoadMore
