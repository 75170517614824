import TemplateEmpty from '../app/templates/TemplateEmpty'

// import Activation from '../app/pages/Activation'
import ActivationExperimental from '../app/pages/ActivationB'
import ActivationSuccess from '../app/pages/ActivationSuccess'
import FirstAccessFollowRequest from '../app/pages/FirstAccessFollowRequest'

export default {
  path: '/identificadores',
  component: TemplateEmpty,
  routes: [
    {
      path: '/identificadores/ativacao',
      exact: true,
      component: ActivationExperimental
    },
    {
      path: '/identificadores/ativacao/sucesso',
      exact: true,
      component: ActivationSuccess
    },
    {
      path: '/identificadores/acompanhar-pedido',
      exact: true,
      component: FirstAccessFollowRequest
    }
  ]
}
