import styled from 'styled-components'
import media from 'styles/tools/media'
import rem from 'styles/tools/rem'

import { txt20OpenSans, txt12OpenSansLight } from 'styles/settings/types'
import { skyBlue, white } from 'styles/settings/colors'

export const FilterWrapper = styled.div`
  height: ${rem('80px')};
  overflow: hidden;
  position: relative;

  ${media.small`
        height: ${rem('110px')};
    `};
`

export const Filter = styled.div`
    background: ${skyBlue};
    bottom: 0;
    display: block;
    height: ${rem('80px')};
    left: 0;
    position: relative;
    transition: height .3s 0s ease, position 0s .3s ease;
    width: 100%;
    z-index: 100;

    ${({ isOpen }) =>
      isOpen &&
      `
        height: 100vh;
        position: fixed;
        transition: height .3s .3s ease, position 0s .3s ease;
    `}

    ${({ isFixed }) =>
      isFixed &&
      `
        position: fixed;
    `}

    ${media.small`
        background: transparent;
        transition: height .3s 0s ease,
                    position 0s .3s ease,
                    bottom 0s 0s ease,
                    background .3s 0s ease;
        ${({ isFixed }) =>
          isFixed &&
          `
            bottom: ${rem('40px')};
            transition: height .3s 0s ease,
                        position 0s .3s ease,
                        bottom 0s 0s ease,
                        background .3s 0s ease;
        `}
        ${({ isOpen }) =>
          isOpen &&
          `
            background: ${skyBlue};
            bottom: 0;
            height: 100vh;
            position: fixed;
            transition: height .3s .3s ease,
                        position 0s .3s ease,
                        bottom 0s 0s ease,
                        background .3s 0s ease;
            ${({ isFixed }) =>
              isFixed &&
              `
                bottom: 0;
            `};
        `};
    `};
`
export const FilterContainer = styled.div`
  display: block;
  height: 100%;
  margin: 0 auto;
  position: relative;

  ${({ isOpen }) =>
    isOpen &&
    `
        overflow: auto;
    `};
`

export const FilterClosed = styled.div`
  align-items: center;
  display: flex;
  height: 100%;
  margin: 0 auto;
  max-width: ${rem('940px')};
  justify-content: space-between;
  transition: opacity 0.3s 0.6s ease, height 0.3s 0.3s ease, visibility 0s 0s ease;

  ${({ isOpen }) =>
    isOpen &&
    `
        height: 0;
        opacity: 0;
        transition: opacity .3s ease, height 0s .3s ease, visibility 0s .3s ease;
        visibility: hidden;
    `};
`

export const FilterBarLabel = styled.span`
  ${txt12OpenSansLight};

  color: ${white};
  display: inline-block;
  line-height: 1;
  margin-bottom: ${rem('4px')};
  text-align: left;
  text-transform: uppercase;
`

export const FilterBarValue = styled.strong`
  ${txt20OpenSans};

  color: ${white};
  display: block;
  line-height: 1;
  text-align: left;
  white-space: nowrap;

  span {
    font-family: Galano;
    font-weight: bold;
  }
`

export const FilterDriver = styled.div`
  display: block;
  min-width: 25%;

  ${media.small`
        display: none;
    `};
`

export const FilterLicense = styled.div`
  display: block;

  ${media.small`
        display: none;
    `};
`

export const FilterFuel = styled.div`
  display: block;

  ${media.small`
        display: none;
    `};
`

export const FilterTotalFuel = styled.div`
  display: block;

  ${media.small`
        display: none;
    `};
`

export const FilterOpened = styled.div`
  display: block;
  opacity: 0;
  transition: opacity 0.3s 0s ease, visible 0s 0.6s ease;
  visibility: hidden;

  ${({ isOpen }) =>
    isOpen &&
    `
        opacity: 1;
        transition: opacity .3s .6s ease, visible 0s 0s ease;
        visibility: visible;
    `};
`

export const FilterClose = styled.div`
  color: ${white};
  cursor: pointer;
  position: absolute;
  right: 50%;
  top: ${rem('42px')};
  transform: scale(1.3) translateX(50%);
  margin-right: ${rem('-455px')};

  ${media.small`
        right: ${rem('19px')};
        top: ${rem('21px')};
        transform: scale(.9);
        transform: none;
        margin-right: 0;
    `};
`

export const ButtonContainerMobile = styled.div`
  display: none;

  ${media.small`
        display: block;
        margin: 0 auto;
        width: ${rem('164px')};
    `};
`

export const ButtonContainerDesktop = styled.div`
  display: block;

  ${media.small`
        display: none;
    `};
`
