import styled, { keyframes, css } from 'styled-components'

import { hexToRgba } from '../../../utils/hexToRgba'
import { skyBlue, white, offWhite, darkBlue } from '../../../styles/settings/colors'

import { txt16OpenSans } from '../../../styles/settings/types'

import media from '../../../styles/tools/media'
import rem from '../../../styles/tools/rem'

const showModalContent = keyframes`
    0% {
        opacity: 0;
        transform: translateY(${rem('30px')}) scale(.95);
    }

    100% {
        opacity: 1;
        transform: translateY(0) scale(1);
    }
`

const hideModalContent = keyframes`
0% {
    opacity: 1;
    transform: translateY(0) scale(1);
}

100% {
    opacity: 0;
    transform: translateY(${rem('30px')}) scale(.95);
}
`

const showModal = keyframes`
0% {
    opacity: 0;
}

100% {
    opacity: 1;
}
`

const hideModal = keyframes`
0% {
    opacity: 1;
}

100% {
    opacity: 0;
}
`

export const Overlay = styled.div`
  align-items: center;
  background: ${({ modalBlue }) => (modalBlue ? hexToRgba(darkBlue, 0.64) : 'rgba(0, 0, 0, 0.15)')};
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
`

export const Content = styled.div`
  opacity: 0;
  position: relative;
  transform-origin: bottom;
  transition: 0.4s;
  z-index: 1;
  width: 100%;

  ${({ state }) => {
    switch (state) {
      case 'entered':
        return css`
          animation: 0.4s ${showModalContent} forwards;
        `
      case 'exiting':
        return css`
          animation: 0.4s ${hideModalContent} forwards;
        `
      case 'exited':
        return css`
          display: none;
        `
      default:
        return css`
          display: block;
        `
    }
  }};
  ${media.large`
      max-width: ${({ maxWidth }) => (maxWidth ? `${maxWidth}px` : rem('660px'))};
  `};
`

export const Wrapper = styled.div`
  align-items: center;
  background: rgba(0, 0, 0, 0.2);
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  opacity: 0;
  position: fixed;
  right: 0;
  top: 0;
  transition: 0.4s;
  z-index: 101;

  ${({ state }) => {
    switch (state) {
      case 'entered':
        return css`
          animation: 0.4s ${showModal} forwards;
        `
      case 'exiting':
        return css`
          animation: 0.4s ${hideModal} forwards;
        `
      case 'exited':
        return css`
          display: none;
        `
      default:
        return css`
          display: block;
        `
    }
  }};
`

export const ModalContainer = styled.div`
  background-color: ${props => props.background || skyBlue};
  overflow: auto;
  position: relative;
  width: 685px;
  height: 345px;
`

export const ModalSimpleContainer = styled.div`
  background-color: ${white};
  height: 100vh;
  overflow: auto;
  position: relative;
  width: 100%;
  display: flex;
  ${media.large`
    border-radius: ${rem('20px')};
    box-shadow: 0 ${rem('8px')} ${rem('8px')} 0 rgba(0, 0, 0, 0.12);
    height: auto;
    min-height: ${rem('440px')};
  `};
`

export const ModalContent = styled.div`
  padding: ${({ noPadding }) => (noPadding ? '0 !important' : `${rem('60px')} ${rem('30px')}`)};
  ${media.large`
      ${({ compact }) =>
        compact
          ? css`
              padding: ${rem('40px')} ${rem('64px')};
            `
          : css`
              padding: ${rem('74px')} ${rem('60px')} ${rem('55px')};
            `}
  `};
`

export const ModalIllustration = styled.img`
  display: block;
  position: absolute;
  width: auto;
`

export const ModalTitle = styled.h1`
  color: ${offWhite};
  font-family: 'Galano';
  font-size: ${rem('24px')};
  font-weight: 600;
  line-height: 1.17;
  margin: 0;
  text-align: center;

  ${media.large`
    font-size: ${props => (props.compact ? rem('32px') : rem('34px'))};
    line-height: 1.04;
    max-width: ${rem('320px')};
    text-align: left;
  `};
`

export const ModalDescription = styled.h1`
  ${txt16OpenSans} color: ${darkBlue};
  font-weight: normal;
  letter-spacing: $normal;
  line-height: 1.5;
  margin-bottom: ${rem('20px')};
  white-space: normal;
  width: ${rem('256px')};

  ${media.large`
    text-align: left;
  `};
`

export const Close = styled.div`
  color: ${white};
  cursor: pointer;
  position: absolute;
  right: ${rem('30px')};
  top: ${rem('30px')};
  padding: 10px;
  background-color: #230c87;
  border-radius: 50%;

  ${media.large`
    right: ${rem('35px')};
    top: ${rem('38px')};
  `};
`
export const JaBaixeiButton = styled.div`
  color: ${white};
  cursor: pointer;
  position: absolute;
  right: ${rem('82px')};
  top: ${rem('260px')};
  padding: 6px 24px 6px 24px;
  background-color: #230c87;
  border-radius: 10% / 50%;
  font-size: 12px;
  font-family: OpenSans;
  font-weight: bold;
`
export const ModalAppStore = styled.img`
  cursor: pointer;
  position: absolute;
  right: ${rem('140px')};
  top: ${rem('210px')};
  height: 36px;
  weight: 92px;
`
export const ModalPlayStore = styled.img`
  cursor: pointer;
  position: absolute;
  right: ${rem('25px')};
  top: ${rem('210px')};
  height: 36px;
  weight: 92px;
`
