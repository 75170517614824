import React from 'react'
import { Box, Flex } from 'grid-styled'

import { Content, Span, Title, VehicleBar } from './styles'

const { currency, licensePlate, subtotal, vehicle } = require('./data.json')

const InvoiceDetailsVehicle = ({ amount, licensePlateNumber, transacao }) => {
  return (
    <VehicleBar>
      <Content>
        <Flex align="center" justify="space-between">
          <Box
            width={[1, 2 / 12]}
            style={{
              height: 41,
              display: 'flex',
              flexDirection: 'column'
            }}
          >
            {licensePlateNumber === '' ? (
              <Span>{transacao.transacoesAgrupadas[0].tipoTransacao}</Span>
            ) : (
              <Span>{vehicle}</Span>
            )}
          </Box>
          <Box
            pl={[0, 20]}
            width={[1, 4 / 12]}
            style={{
              height: 41,
              display: 'flex',
              flexDirection: 'column'
            }}
          >
            {licensePlateNumber && (
              <div>
                <Span>{licensePlate}</Span>
                <Title>{licensePlateNumber}</Title>
              </div>
            )}
          </Box>
          <Box
            pl={[0, 20]}
            width={[1, 4 / 12]}
            style={{
              height: 41,
              display: 'flex',
              flexDirection: 'column'
            }}
          />
          <Box
            pl={[0, 20]}
            width={[1, 2 / 12]}
            style={{
              height: 41,
              display: 'flex',
              flexDirection: 'column'
            }}
          >
            <Span>
              {subtotal} ({currency})
            </Span>

            <Title>{amount.replace('-', '')}</Title>
          </Box>
        </Flex>
      </Content>
    </VehicleBar>
  )
}

export default InvoiceDetailsVehicle
