import React from 'react'
import { connect } from 'react-redux'
import { Box, Flex } from 'grid-styled'

import { fetchPaymentInfo } from 'alelo-logic/actions'

import Button from '../../elements/Button'
import rem from '../../../styles/tools/rem'

import { Wrapper, Text, TextBold, UserImage, Username, EditPaymentMethod } from './styles'

const { paymentTypeLabel, creditLabel, cardLabel, editDataBtn, confirmBtn } = require('./data.json')

class PaymentInfoCard extends React.Component {
  state = {
    editing: false
  }

  componentWillMount() {
    const { getPaymentInfo, accountPlanId } = this.props
    getPaymentInfo(accountPlanId)
  }

  onConfirm = () => {
    this.props.onConfirm()
  }

  goBack = () => {
    this.props.history.goBack()
  }

  render() {
    const { avatar, paymentInfo, loading } = this.props

    const splitedUsername = paymentInfo.nome ? paymentInfo.nome.split(' ') : ['']

    return (
      <Wrapper>
        <Box mb={[rem('20px'), rem('50px')]}>
          <Text>{paymentTypeLabel}</Text>
          <TextBold>{creditLabel}</TextBold>
        </Box>
        <Flex align="center" mb={[rem('30px'), rem('40px')]}>
          <UserImage src={require(`../../../static/avatars/avatar-${avatar + 1}.png`)} />
          <Username>
            <span id="lblFirstName">{splitedUsername.shift()}</span>
            <br />
            <span id="lblLastName">{splitedUsername.join(' ')}</span>
          </Username>
        </Flex>
        <Box mb="30px">
          <Text>{cardLabel}</Text>
          <TextBold id="lblMaskedCard">{paymentInfo.numeroMascarado}</TextBold>
        </Box>
        <Box>
          <Button
            id="btnContinue"
            onClick={this.onConfirm}
            width="100%"
            text={confirmBtn}
            disabled={loading}
            loading={loading}
          />
          <EditPaymentMethod onClick={this.goBack}>{editDataBtn}</EditPaymentMethod>
        </Box>
      </Wrapper>
    )
  }
}

const mapStateToProps = ({ reducer, header }) => {
  return {
    account: reducer.account,
    avatar: header.avatar,
    paymentInfo: reducer.recharge.paymentInfo
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getPaymentInfo: accountPlanId => fetchPaymentInfo(accountPlanId)(dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PaymentInfoCard)
