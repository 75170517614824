export default function groupArray(data, getKey) {
  if (!data || !getKey) return null

  return data.reduce((groups, item) => {
    const result = groups
    const val = getKey(item)
    result[val] = groups[val] || []
    result[val].push(item)
    return result
  }, {})
}
