import TemplateLayout from '../../app/templates/TemplateLayout'
import RequestFile from '../../app/pages/ec/RequestFile'

export default {
  path: '/minha-veloe/solicitar-lista',
  component: TemplateLayout,
  routes: [
    {
      path: '/minha-veloe/solicitar-lista',
      exact: true,
      component: RequestFile
    }
  ]
}
