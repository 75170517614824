/* eslint-disable */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Flex, Box } from 'grid-styled'

import Button from '../../elements/Button'
import RechargeBreadcrumbs from '../../modules/RechargeBreadcrumbs'
import Hide from '../../../styles/utilities/Hide'
import rem from '../../../styles/tools/rem'

import image from '../../../static/recarga.png'

import { Text, Title, Cash, CashPrefix, CashValue, TitleEdit, TextSmall } from './styles'

const { title, currency, disclaimer, buttonLabel, titleEdit, awaitUpdate } = require('./data.json')

class RechargeConfirmation extends Component {
  state = {
    isEditCard: false
  }

  componentDidMount() {
    const {
      location: { search }
    } = this.props
    if (search.includes('path=recharge')) this.setState({ isEditCard: true })
  }

  onConfirm = () => {
    this.props.history.push(`/minha-veloe/${this.props.match.params.id}/resumo`)
  }

  onNewRecharge = () => {
    this.props.history.push(`/minha-veloe/${this.props.match.params.id}/recarga`)
  }

  render() {
    const {
      recharge: { currentRechargeValue }
    } = this.props

    const { isEditCard } = this.state

    return (
      <section>
        <RechargeBreadcrumbs active={4} />
        <Flex direction={['column', 'row']}>
          <Box
            mr={[0, `${(1 / 12) * 100}%`]}
            mt={[rem('35px'), rem('60px')]}
            mb={rem('20px')}
            width={[1, 7 / 12]}
          >
            {isEditCard ? (
              <TitleEdit>{titleEdit}</TitleEdit>
            ) : (
              <div>
                <Title>{title}</Title>

                <TextSmall>{awaitUpdate}</TextSmall>

                <Cash>
                  <CashPrefix>{currency}</CashPrefix>
                  <CashValue id="lblCurrentRechargeValue">{currentRechargeValue}</CashValue>
                </Cash>
                <Text id="lblDisclaimer">{disclaimer}</Text>
              </div>
            )}

            <Flex direction={['column', 'row']} mt={[rem('30px'), rem('30px')]}>
              <Box mb={[rem('16px'), 0]} mr={[0, rem('10px')]} width={[1, 1 / 2]}>
                <Button
                  id="btnOk"
                  buttonType="white"
                  onClick={this.onConfirm}
                  width="100%"
                  text="Ok"
                />
              </Box>
              <Box ml={[0, rem('10px')]} width={[1, 1 / 2]}>
                <Button
                  id="btnNewRecharge"
                  buttonType="transparent"
                  onClick={this.onNewRecharge}
                  width="100%"
                  text={buttonLabel}
                />
              </Box>
            </Flex>
          </Box>
          <Hide at="small">
            <img alt="recarga" src={image} />
          </Hide>
        </Flex>
      </section>
    )
  }
}

const mapStateToProps = ({ reducer }) => ({
  recharge: reducer.recharge
})

export default connect(mapStateToProps)(RechargeConfirmation)
