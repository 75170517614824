import React from 'react'
import { connect } from 'react-redux'
import { Switch, withRouter } from 'react-router-dom'
import { ThemeProvider } from 'styled-components'
import { RouteWithSubRoutes, routes } from '../../../routes'

import { Main } from './styles'

function App(props) {
  const { account } = props

  let profileType
  let accountStatus

  if (account && account.accountType) {
    profileType = account.accountType
  } else {
    profileType = 'pf'
  }

  if (account && account.active === true) {
    accountStatus = 'active'
  } else {
    accountStatus = 'inactive'
  }
  // TEMPORARIO ALTERAR DEPOIS
  // accountStatus = 'active'
  // profileType = 'pj'
  return (
    <ThemeProvider
      theme={{
        breakpoints: [48, 48, 64]
      }}
    >
      <Main modalOpen={props.modal.type}>
        <Switch>
          {routes[profileType][accountStatus].map(route => (
            <RouteWithSubRoutes key={route.path} {...route} profile={profileType} />
          ))}
        </Switch>
      </Main>
    </ThemeProvider>
  )
}

const mapStateToProps = state => ({
  modal: state.modalReducer,
  account: state.reducer.account
})

export default withRouter(connect(mapStateToProps)(App))
