import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { Box, Flex } from 'grid-styled'
import _ from 'lodash'
import { fetchDashboardResume } from 'alelo-logic/actions'
import Button from '../../elements/Button'
import Advertisement from './Advertisement'
import BannerZapay from './BannerZapay'
import Content from 'styles/objects/Content'
import Hide from 'styles/utilities/Hide'
import PlanResume from '../../modules/PlanResume'
import ConsumptionChart from '../../modules/ConsumptionChart'
import ConsumptionChartPos from '../../modules/ConsumptionChartPos'
import DashboardCashDisplay from '../../modules/DashboardCashDisplay'
import DashboardIdentifierList from '../../modules/DashboardIdentifierList'
import rem from 'styles/tools/rem'
import { setLoading } from '../../modules/PageLoader/actions'
import { Separator } from './styles'
// import DashboardInvoiceWidget from '../../modules/DashboardInvoiceWidget'
import PageLoader from '../../modules/PageLoader'
import InfoCarousel from './InfoCarousel'
import Warning from './Warning'
// Utils
import { selectUserCurrentAccount } from 'utils/selectors'

const { rechargeButtonLabel } = require('./data.json')

function DashboardResume({
  resume,
  accountPlans,
  setLoading,
  fetchResume,
  currAccount,
  history,
  match,
  account: { accountType }
}) {
  useEffect(() => getResume(), [accountPlans])

  const getResume = async () => {
    if (currAccount && currAccount.paymentType) {
      setLoading(true)
      await fetchResume(currAccount.id)
      setLoading(false)
    }
  }

  if (!resume || !resume.type) {
    return null
  }

  let obj =
    accountPlans && accountPlans.plans && accountPlans.plans.length > 0
      ? accountPlans.plans.find(el => el && el.id === match.params.id)
      : null

  const finantialData = resume.consumption || resume.balance || {}

  const currentPlan = (currAccount && currAccount.plan) || {}
  const isPrePaid = currentPlan && currentPlan.type === 'pre'

  // SEMIAUTOMATICO
  const { identifiers = [] } = obj || {}
  const identifiersTypes = _.map(identifiers, 'identifierType')
  const semiautomaticoTypes = ['BAND', 'SMART_BAND']
  const isSemiautomatico = _.some(semiautomaticoTypes, t => _.includes(identifiersTypes, t))

  return (
    <section>
      <PageLoader>
        <Content>
          {resume && (
            <Flex mt={[0, rem('35px')]} mb={rem('60px')} align="stretch" wrap={[true, false]}>
              <Box mb={[rem('20px'), 0]} pr={[0, 20]} width={[1, 3 / 12]}>
                <PlanResume />
              </Box>
              <Box pl={[0, 20]} width={[1, 9 / 12]}>
                <DashboardCashDisplay
                  recharge={isPrePaid}
                  updateDate={finantialData.date}
                  value={obj ? (!isPrePaid ? obj.availableAmount : obj.ammount) : null}
                />
                {isPrePaid && <Separator />}
                {isPrePaid ? (
                  <ConsumptionChart
                    consumption={resume.consumption}
                    balance={resume.balance}
                    isSemiautomatico={isSemiautomatico}
                  />
                ) : (
                  <ConsumptionChartPos
                    consumption={resume.balance}
                    balance={resume.balance}
                    currentPlan={currAccount}
                    isSemiautomatico={isSemiautomatico}
                  />
                )}
                {isPrePaid && (
                  <Hide at="large" mt={rem('20px')}>
                    <Button
                      id="button-manual-recharge"
                      onClick={() => history.push(`/minha-veloe/${match.params.id}/recarga`)}
                      text={rechargeButtonLabel}
                    />
                  </Hide>
                )}
                <Separator />
                {accountPlans.plans.length > 0 && (
                  <DashboardIdentifierList accountPlans={accountPlans} resume={resume} />
                )}
                <InfoCarousel>
                  {isSemiautomatico ? <Warning.TollSemiautomatico /> : <Warning.Toll />}
                  <Warning.CheckApp />
                  {!isSemiautomatico && <Warning.Signalization />}
                  {accountType === 'pf' && <Advertisement />}
                  {currAccount.parceiroComercialId !== '2332' && !isSemiautomatico && (
                    <BannerZapay />
                  )}
                </InfoCarousel>
              </Box>
            </Flex>
          )}
        </Content>
      </PageLoader>
    </section>
  )
}

const mapStateToProps = ({ reducer }) => ({
  resume: reducer.dashboardResume,
  accountPlans: reducer.accountPlans,
  account: reducer.account,
  currAccount: selectUserCurrentAccount(reducer)
})

const mapDispatchToProps = dispatch => ({
  fetchResume: (token, planId) => dispatch(fetchDashboardResume(token, planId)),
  setLoading: isLoading => {
    dispatch(setLoading(isLoading))
  }
})

export default connect(mapStateToProps, mapDispatchToProps)(DashboardResume)
