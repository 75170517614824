import React from 'react'
import { connect } from 'react-redux'
import { Flex, Box } from 'grid-styled'
import { reduxForm } from 'redux-form'

import Button from 'app/elements/Button'
import { FileTypeField, SequentialField } from './form-fields'
import { FormContent, QueryButtonContainer, Container } from './styles'

const { labels, fileTypes } = require('./data.json')

class RequestFileForm extends React.Component {
  componentWillMount() {
    this.props.initialize()
  }

  render() {
    const { handleSubmit, saving } = this.props

    return (
      <FormContent>
        <Container>
          <form onSubmit={handleSubmit}>
            <Flex wrap>
              <Box width={[1, 3 / 12]} mb={3} mr={3}>
                <FileTypeField fileTypes={fileTypes} saving={saving} />
              </Box>
              <Box width={[1, 3 / 12]} mb={[3, 0]} mr={3}>
                <SequentialField saving={saving} />
              </Box>
              <Box width={[1, 2 / 12]} pl={[0, 3]}>
                <QueryButtonContainer>
                  <Button text={labels.submitButton} type="submit" disabled={saving} />
                </QueryButtonContainer>
              </Box>
            </Flex>
          </form>
        </Container>
      </FormContent>
    )
  }
}

const form = reduxForm({
  form: 'fileRequestForm',
  destroyOnUnmount: false
})(RequestFileForm)

const mapStateToProps = ({ reducer }) => ({
  saving: reducer.formState.saving
})

export default connect(mapStateToProps)(form)
