import gql from 'graphql-tag'
import { toDate } from 'unix-timestamp'

export const gqlInserirRecarga = gql`
  mutation inserirRecarga(
    $conta: String!
    $valor: Int!
    $atividade: String!
    $formaPagamento: String!
    $canal: String!
  ) {
    inserirRecarga(
      recarga: {
        conta: $conta
        valor: $valor
        atividade: $atividade
        formaPagamento: $formaPagamento
        canal: $canal
      }
    ) {
      conta
      valor
      atividade
      formaPagamento
      canal
    }
  }
`

export const gqlRecharges = gql`
  query historicoRecargas($idConta: String!, $skip: Int) {
    historicoRecargas(conta: $idConta, skip: $skip) {
      limit
      remaining
      recargas {
        valor
        formaPagamento
        atividade
        id
        idSap
        conta
        data
      }
    }
  }
`

export const gqlValoresRecarga = gql`
  query valoresRecarga($idConta: String!) {
    valoresRecarga(conta: $idConta) {
      valor
    }
  }
`

export const gqlDadosPagamento = gql`
  query dadosPagamento($conta: String!) {
    dadosPagamento(conta: $conta) {
      id
      paymentId
      conta
      numeroMascarado
      validade
      bandeira
      alias
      cpf
      cnpj
      nome
      paymentToken
      cardToken
      ativo
      excluido
      message
      estornado
    }
  }
`

export const gqlEditarValorRecarga = gql`
  mutation editarValorRecarga($valor: Float!, $conta: String!) {
    editarValorRecarga(
      recargaValor: { valorRecarga: $valor, recargaConta: { conta: { id: $conta } } }
    ) {
      valorRecarga
    }
  }
`

export const gqlEditarDadoPagamento = gql`
  mutation editarDadoPagamento(
    $conta: String!
    $numeroMascarado: String!
    $validade: String!
    $bandeira: String!
    $alias: String!
    $nome: String!
    $cpf: String!
    $paymentToken: String!
    $cvv: String!
    $ativo: Boolean!
    $valorRecarga: String!
    $tipoPagamento: String!
  ) {
    editarDadoPagamento(
      dadoPagamento: {
        conta: $conta
        numeroMascarado: $numeroMascarado
        validade: $validade
        alias: $alias
        bandeira: $bandeira
        nome: $nome
        cpf: $cpf
        paymentToken: $paymentToken
        cvv: $cvv
        ativo: $ativo
        valorRecarga: $valorRecarga
        tipoPagamento: $tipoPagamento
      }
    ) {
      conta
      numeroMascarado
      validade
      alias
      nome
      paymentToken
      cvv
      ativo
      valorRecarga
      tipoPagamento
    }
  }
`

export const gqlAccessTokenBrasPag = gql`
  {
    accessTokenBrasPag
  }
`
export const transformValoresRecarga = src => {
  const { valoresRecarga } = src.data
  const rechargeValues = valoresRecarga.reduce((acc, curr) => {
    acc.push(Number.parseInt(curr.valor, 10))
    return acc
  }, [])
  return { rechargeValues }
}

const decodePaymentMethod = {
  CARTAO_DE_CREDITO: 'credit',
  DEBITO_EM_CONTA: 'debit',
  BOLETO: 'bankSlip',
  CARTAO_DEBITO: 'debitCard'
}

const decodeType = {
  RECARGA_MANUAL: 'manual',
  RECARGA_AUTOMATICA: 'auto'
}

export const transformRecharges = src => {
  return src.map(r => ({
    value: r.valor,
    paymentMethod: decodePaymentMethod[r.formaPagamento],
    type: decodeType[r.atividade],
    id: r.id,
    idSap: r.idSap,
    accountPlanId: r.conta,
    date: toDate(r.data / 1000)
  }))
}

export const transformDadosPagamento = src => src.data.dadosPagamento[0]
