import React from 'react'
import Modal from '../../elements/ModalBlue'
import Button from '../../elements/Button'

import { Wrapper, Title, Text, WrapperButton } from './styles'

import image from '../../../static/icon-veloe-background.svg'

class AddTagsModal extends React.Component {
  render() {
    const { handleClick } = this.props
    return (
      <Modal backgroundImage={image} position="right bottom">
        <Wrapper>
          <Title>Sua tag foi substituída.</Title>
          <Text />
          <Text>
            Tudo certo, agora é só colar a tag no para-brisa do veículo e curtir o caminho livre.
          </Text>
          <WrapperButton>
            <Button
              buttonType="darkBlue"
              text="Ok"
              onClick={() => {
                handleClick()
                window.dataLayer.push({
                  event: 'event',
                  eventAction: 'click:ok',
                  eventCategory: 'veloe:area-logado:meus-produtos:bloquear-substituir',
                  eventLabel: 'tag-substituida'
                })
              }}
              />
          </WrapperButton>
        </Wrapper>
      </Modal>
    )
  }
}

export default AddTagsModal
