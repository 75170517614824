import styled from 'styled-components'
import { title18Galano, txt14OpenSans } from 'styles/settings/types'
import { linkNavyBlue, posGreyInfo, lightBlue, white } from 'styles/settings/colors'
import rem from 'styles/tools/rem'

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 380px;
`

export const WrapperContentModal = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  min-height: 380px;
  padding: ${rem('24px')};
  width: 100%;
  overflow: hidden;
`

export const SuccessContainer = styled.div`
  padding: ${rem('24px')};
  flex-direction: column;
  width: 100%;
  display: flex;
  background: ${lightBlue};
`
export const SuccessContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
`

export const SuccessMessage = styled.h2`
  ${title18Galano};
  margin: 0;
  margin-top: ${rem('25px')};
  color: ${white};
  font-weight: bold;
`

export const Header = styled.div`
  ${title18Galano};
  font-weight: bold;
  width: 100%;
  display: flex;
  justify-content: space-between;
  color: ${linkNavyBlue};
  margin-bottom: 20px;
`

export const Back = styled.a`
  ${txt14OpenSans};
  font-weight: bold;
  line-height: 1;
  color: ${linkNavyBlue};
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
  padding: ${rem('16px')};
  cursor: pointer;

  svg {
    fill: ${posGreyInfo};
  }
`

export const Content = styled.div`
  padding: ${rem('24px')};
`

export const WrapperButton = styled.div`
  margin-top: auto;
  display: flex;
  justify-content: ${props => props.justify};
`
WrapperButton.defaultProps = {
  justify: 'flex-end'
}
