import styled from 'styled-components'
import Icon from 'app/elements/Icon/Icon'
import {
  BaseTable,
  BaseTableHeader,
  BaseTableHeaderCell,
  BaseTableRow,
  BaseTableRowCell
} from 'styles/objects/Table'
import { darkGrey, offWhite, linkNavyBlue, graySuit } from 'styles/settings/colors'
import {
  title24GalanoBold,
  txt14OpenSans,
  txt14OpenSansBold,
  txt12OpenSans,
  txt12OpenSansBold
} from 'styles/settings/types'
import rem from 'styles/tools/rem'
import media from 'styles/tools/media'

export const Container = styled.section`
  background-color: ${offWhite};
  border-radius: 4.4px;
  height: 100%;
  width: 100%;
`

export const Header = styled.div`
  padding: 20px;
`

export const TotalValue = styled.p`
  ${title24GalanoBold};
  color: ${linkNavyBlue};
  margin: 0;
`

export const TotalText = styled.p`
  ${txt14OpenSans};
  color: ${darkGrey};
  margin: 0;
`

export const Table = styled(BaseTable)`
  ${media.small`
    padding: ${rem('16px')};
  `};
`

export const TableHeader = styled(BaseTableHeader)`
  height: 40px;
`

export const TableHeaderCell = styled(BaseTableHeaderCell)`
  ${txt12OpenSans};
  color: ${graySuit};
  padding: 0 10px;
`

export const TableRow = styled(BaseTableRow)`
  height: 40px;
`

export const TableRowCell = styled(BaseTableRowCell)`
  ${txt12OpenSansBold};
  color: ${linkNavyBlue};
  padding: 0 10px;
`

export const SeeAllContainer = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  padding: 28px 20px;
`

export const SeeAll = styled.p`
  ${txt14OpenSansBold};
  color: ${linkNavyBlue};
  margin: 0;
  padding-right: 10px;
`

export const SeeAllIcon = styled(Icon)`
  margin: auto 0;
`
