import types from './types'

const { CLEAR_EC_QUERY_PLATE } = types

export const clearECQueryPlate = () => {
  return dispatch =>
    dispatch({
      type: CLEAR_EC_QUERY_PLATE,
      state: null
    })
}

export default clearECQueryPlate
