import { getBusinessUser } from '../services/userService'

import types from './types'

const { FETCH_USER } = types

function fetchGetBusinessUser(token) {
  return dispatch =>
    getBusinessUser(token).then(user => {
      dispatch({
        type: FETCH_USER,
        state: {
          ...user,
          token
        }
      })

      return user
    })
}

export default fetchGetBusinessUser
