import Storage from './storages/tokenStorage'
import { FETCH_MAX_RETRY } from './constants'

const getHeaders = account => {
  const headers = new Headers()
  headers.append('Content-Type', 'application/json')

  if (account) headers.append('Authorization', `Bearer ${account.token}`)

  return headers
}

const storage = new Storage()

export default function fetchHandler(url, options, retryCount = 1) {
  return new Promise((resolve, reject) => {
    const headers = getHeaders(storage.getAccount())
    fetch(url, {
      ...options,
      credentials: 'include',
      headers
    })
      .then(resp => {
        const contentType = resp.headers.get('content-type')
        if (resp.status < 200 || resp.status >= 300) {
          if (resp && resp.status === 502 && retryCount < FETCH_MAX_RETRY) {
            resolve(fetchHandler(url, options, retryCount + 1))
          } else {
            reject(resp)
          }
        } else if (resp.status === 204) {
          resolve(resp)
        } else if (contentType && contentType.indexOf('application/json') !== -1) {
          resolve(resp.json())
        } else {
          resolve(resp)
        }
      })
      .catch(e => {
        reject(e)
      })
  })
}
