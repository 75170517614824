import styled from 'styled-components'
import Icon from 'app/elements/Icon/Icon'
import { darkGrey, linkNavyBlue, offWhite } from 'styles/settings/colors'
import { title24GalanoBold, txt14OpenSans, txt14OpenSansBold } from 'styles/settings/types'

export const Container = styled.section`
  background-color: ${offWhite};
  border-radius: 4.4px;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  padding: 20px;
  width: 100%;
`

export const Title = styled.p`
  ${title24GalanoBold};
  color: ${linkNavyBlue};
  margin: 0;
`

export const Subtitle = styled.p`
  ${txt14OpenSans};
  color: ${darkGrey};
`

export const SeeMoreContainer = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: row;
`

export const SeeMore = styled.p`
  ${txt14OpenSansBold};
  color: ${linkNavyBlue};
  margin: 0;
  padding-right: 10px;
`

export const SeeMoreIcon = styled(Icon)`
  margin: auto 0;
`
