import { injectGlobal } from 'styled-components'

injectGlobal`
    .fade-in-enter {
        opacity: 0.01;
    }

    .fade-in-enter.fade-in-enter-active {
        opacity: 1;
        transition: opacity 500ms ease-in;
    }

    .fade-in-leave {
        opacity: 1;
    }

    .fade-in-leave.fade-in-leave-active {
        opacity: 0.01;
        transition: opacity 300ms ease-in;
    }
`
