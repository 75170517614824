import moment from 'moment'
import Enum from 'enum'

const myEnum = new Enum({
  ABASTECIMENTO: 'fuel',
  ESTACIONAMENTO: 'park',
  PEDAGIO: 'toll',
  FATURA: 'payment',
  RECARGA: 'recharge',
  'LAVA RÁPIDO': 'dry-wash'
})

const myEnumTitle = new Enum({
  FATURA: 'Recebemos seu pagamento',
  RECARGA: 'Recebemos sua recarga'
})

function getTitle(data) {
  if (data['estabelecimento-comercial']['tipo-transacao']) {
    return myEnumTitle.get(data['estabelecimento-comercial']['tipo-transacao'])
      ? myEnumTitle.get(data['estabelecimento-comercial']['tipo-transacao']).value
      : data['estabelecimento-comercial'].nome
  }
  return data['motivo-transacao']
}

function getType(data) {
  return myEnum.get(data['estabelecimento-comercial']['tipo-transacao'])
    ? myEnum.get(data['estabelecimento-comercial']['tipo-transacao']).value
    : 'tax'
}

const statementFactory = {
  toForm: statement =>
    statement &&
    statement.map(
      (data, index) =>
        statement && {
          id: data['cyber-id'],
          date: moment(data['data-evento'], 'DD/MM/YYYY HH:mm:ss').toDate(),
          processDate: moment(data['data-processamento'], 'DD/MM/YYYY HH:mm:ss').toDate(),
          exitDate: moment(data['data-fim-evento'], 'DD/MM/YYYY HH:mm:ss').toDate(),
          startTime: data['hora-evento'],
          exitTime: data['hora-fim-evento'],
          stayTime: data['permanencia'],
          type: getType(data),
          value: data.valor,
          title: getTitle(data),
          details: data.endereco,
          tollFree: data.valePedagio,
          tollFreeValidityStart: data.vpVigenciaInicio,
          tollFreeValidityEnd: data.vpVigenciaFim,
          tollFreeOrigin: data.vpOrigem,
          tollFreeDestination: data.vpDestino,
          alias: data.alias,
          tipoKit: data.tipoKit,
          tollFreeVeloeId: data.vpIdveloe,
          tollFreeCNPJ: data.vpCnpjEmbarcador,
          code: data['cyber-id'],
          identifier: data['identificador'],
          vehicle: data.veiculo
        }
    )
}
export default statementFactory
