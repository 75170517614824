import styled from 'styled-components'
import { lightBlue, paleGray, lightSilver, white } from '../../../styles/settings/colors'
import { title18Galano } from '../../../styles/settings/types'

export const Container = styled.div`
  width: 56px;
  height: 56px;
  border-radius: 60px;
  display: flex;
  background-color: ${props => (props.checked ? lightBlue : paleGray)};
  justify-content: center;
  align-items: center;
  cursor: pointer;
`
export const Text = styled.span`
  ${title18Galano};
  font-weight: bold;
  text-align: center;
  color: ${props => (props.checked ? white : lightSilver)};
`

export const Label = styled.label`
  user-select: none;
`

export const Input = styled.input`
  display: none;
`
