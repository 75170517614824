const colors = {
  pre: {
    normal: {
      normal: '#c11f57',
      darken: '#a81b4c',
      lighter: '#cf7c99'
    },
    semiautomatico: {
      normal: '#561dff',
      darken: '#3c1aa1',
      lighter: '#9c7ff0'
    },
    Autorrecarga: {
      normal: '#561dff',
      darken: '#4416cc',
      lighter: '#8c66ff'
    }
  },
  pos: {
    normal: {
      normal: '#e5b200',
      darken: '#c79c00',
      lighter: '#E8C23A'
    },
    Urbano: {
      normal: '#9000a3',
      darken: '#710080',
      lighter: '#AA6BB3'
    },
    Estrada: {
      normal: '#6c9807',
      darken: '#5b8006',
      lighter: '#A2B86E'
    }
  },
  ec: {
    normal: {
      normal: '#28c4d4'
    }
  }
}

export default (type, category) => {
  const colorType = colors[type]

  if (!category) {
    return {}
  }

  if (!category.length || !colorType[category]) return colorType.normal

  return colorType[category]
}
