import styled, { keyframes, css } from 'styled-components'

import {
  red,
  skyBlue,
  lighterGrey,
  disabledGrey,
  charcoal,
  darkGrey,
  linkNavyBlue,
  grey,
  white
} from '../../../styles/settings/colors'

import rem from '../../../styles/tools/rem'

export const bottomLine = `
    border-radius: ${rem('4px')};
    bottom: 0;
    content: '';
    height: ${rem('4px')};
    position: absolute;
`

export const Content = styled.div`
  position: relative;

  :before {
    ${bottomLine} background-color: ${skyBlue};
    left: 50%;
    right: 0;
    transform: translateX(-50%);
    transition: 0.5s ease width;
    width: 0;
    z-index: 1;

    ${props =>
      props.isFocus &&
      `
            width: 100%;
        `} ${props =>
  props.hasError &&
  `
            background-color: ${red};
            width: 100%;
        `};
  }

  :after {
    ${bottomLine} background-color: ${grey};
    left: 0;
    right: 0;
    width: 100%;

    ${props =>
      props.disabled &&
      `
            background-color: ${lighterGrey};
        `};
  }
`

export const Label = styled.label`
  color: ${darkGrey};
  font-family: OpenSans;
  font-size: ${rem('12px')};
  font-weight: 200;
  margin-left: ${rem('16px')};
  letter-spacing: ${rem('1px')};
  line-height: 1;
  opacity: 0.6;
  pointer-events: none;
  text-transform: uppercase;
  white-space: nowrap;
`

export const InputField = styled.input`
  background: none;
  border: none;
  box-shadow: none;
  box-sizing: border-box;
  color: ${darkGrey};
  font-family: OpenSans;
  font-size: ${rem('20px')};
  height: ${rem('45px')};
  letter-spacing: ${rem('-0.6px')};
  outline: none;
  padding-left: ${rem('16px')};
  width: 100%;
  position: relative;
  z-index: 1;

  ${props =>
    props.disabled &&
    `
        color: ${disabledGrey};
        user-select: none;
    `} ${props =>
    props.isFocus &&
    `
        color: ${charcoal};
    `} ::placeholder {
    color: rgba(0, 0, 0, 0.1);
    transition: 0.2s color;

    ${props =>
      props.highlightPlaceholderOnBlur &&
      `
            color: rgba(0, 0, 0, .2);
        `};
  }
`

const bottomAnimate = keyframes`
    0% {
        max-height: 0;
    }
    100% {
        max-height: ${rem('50px')};
    }
`

export const Error = styled.span`
  color: ${red};
  display: inline-block;
  font-family: OpenSans;
  font-size: ${rem('12px')};
  letter-spacing: ${rem('-0.3px')};
`

export const BottomMessage = styled.div`
  animation: 0.4s ${bottomAnimate} forwards;
  display: flex;
  align-items: center;
  margin-top: ${rem('8px')};
  max-height: 0;
  overflow: hidden;
  padding-left: ${rem('16px')};

  .animated-loading {
    margin-right: ${rem('8px')};
    fill: ${grey};
  }
`

export const ShowPassword = styled.div`
  cursor: pointer;
  position: absolute;
  right: ${rem('17px')};
  top: ${rem('16px')};
  z-index: 1;
`

const rotateLoading = keyframes`
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(359deg);
    }
`

export const LoadingContainer = styled.div`
  animation: 1s ${rotateLoading} infinite linear;
  color: ${linkNavyBlue};
  padding-top: ${rem('6px')};
  position: absolute;
  right: ${rem('20px')};
  top: ${rem('12px')};
  z-index: 1;
`

export const LengthCounter = styled.span`
  float: right;
  font-family: OpenSans;
  font-size: ${rem('14px')};
  padding-top: ${rem('7px')};
  color: ${skyBlue};
  text-align: right;
`

export const Wrapper = styled.div`
  padding-top: ${rem('5px')};
  position: relative;

  ${props =>
    props.negative &&
    css`
      ${Content} {
        :before {
          display: none;
        }

        :after {
          background-color: ${white};
        }
      }

      ${Label} {
        color: ${white};
        opacity: 1;
      }

      ${InputField} {
        color: ${white};

        ::placeholder {
          color: rgba(255, 255, 255, 0.4);
        }
      }

      ${Error} {
        color: ${white};
      }

      ${LoadingContainer} {
        color: ${white};
      }
      ${LengthCounter} {
        color: ${white};
      }
    `};
`

export const LabelIconWrapper = styled.span`
  position: absolute;
  left: 92%;
`

export const InputIconWrapper = styled.span`
  position: absolute;
  left: 85%;
  top: 25%;
`
