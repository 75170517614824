import styled from 'styled-components'
import { darkBlue, darkSlateBlue } from '../../../styles/settings/colors'
import { title18Galano, title24Galano, txt14OpenSans } from '../../../styles/settings/types'

import content from '../../../styles/_objects/content'
import rem from '../../../styles/tools/rem'
import media from '../../../styles/tools/media'

export const Content = styled.section`
  ${content};
  margin: ${rem('42px')} auto;

  ${media.small`
        margin: ${rem('29px')} auto;
    `};
`

export const Paragraph = styled.p`
  ${txt14OpenSans} color: ${darkBlue};
  margin-top: ${rem('7px')};

  ${media.large`
        width: ${rem('313px')};
        margin: 0;
    `};
`

export const Heading = styled.h1`
  ${title24Galano}
  color: ${darkSlateBlue};
  font-size: 40px;
  font-weight: bold;
  line-height: normal;
  letter-spacing: 0.4px;
  margin: 0;

  ${media.small`
    ${title18Galano}
    margin: ${rem('8px')} 0;
  `};
`

export const BoxHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 40px;
  flex-direction: column;
`
