/* eslint-disable */

import React, { Component } from 'react'
import { arrayOf, object } from 'prop-types'
import { Field, change } from 'redux-form'

import ChooseFilterVehicleOption from '../../elements/ChooseFilterVehicleOption'

import {
  Container,
  Content,
  FilterTypeContainer,
  FilterTypes,
  ListItem,
  SelectAll,
  Title
} from './styles'

const { selectAll, title, unselectAll } = require('./data.json')

class ChooseFilterVehicle extends Component {
  constructor(props) {
    super(props)

    this.state = {
      allVehiclesChecked: false
    }
  }

  removeDuplicatedVehiclesPlates = vehicles => {
    let duplicated = []
    let nonDuplicatedVehicles = vehicles.filter(function(vehicle) {
      let add = this.indexOf(vehicle.carPlate) < 0

      if (add) {
        duplicated.push(vehicle.carPlate)
      }

      return add
    }, duplicated)

    return nonDuplicatedVehicles
  }

  onChange = event => {
    const targetChecked = event.target.checked
    const vehiclesCheckbox = document.querySelectorAll('.vehicleCheckbox')
    vehiclesCheckbox.forEach(vehicleCheckbox => (vehicleCheckbox.checked = false))
    event.target.checked = targetChecked

    if (this.state.allVehiclesChecked) {
      this.setState({
        allVehiclesChecked: false
      })
    }

    let currentIdentifiers
    if (targetChecked) {
      this.props.dispatch(change('statementsFilter', 'plate', event.target.id.toString()))
    } else {
      this.props.dispatch(change('statementsFilter', 'plate', ''))
    }
  }

  render() {
    const { vehicles } = this.props

    const checkAllVehicles = () => {
      if (this.state.allVehiclesChecked) {
        this.setState({
          allVehiclesChecked: false
        })
      } else {
        this.setState({
          allVehiclesChecked: true
        })
      }
      this.props.dispatch(change('statementsFilter', 'plate', ''))
    }

    let nonDuplicatedVehicles = this.removeDuplicatedVehiclesPlates(vehicles)

    return (
      <Container>
        <Title>{title}</Title>
        <Content>
          <SelectAll onClick={checkAllVehicles}>
            {this.state.allVehiclesChecked ? unselectAll : selectAll}
          </SelectAll>
          <FilterTypeContainer>
            <FilterTypes>
              {nonDuplicatedVehicles.map((vehicle, i) => (
                <ListItem key={vehicle.carPlate}>
                  {
                    <ChooseFilterVehicleOption
                      key={vehicle.carPlate}
                      onChange={this.onChange}
                      filterVehicle={vehicle}
                      name="identifiers"
                      checked={this.state.allVehiclesChecked}
                    />
                  }
                </ListItem>
              ))}
            </FilterTypes>
            <Field component="input" type="hidden" name="identifierId" />
          </FilterTypeContainer>
        </Content>
      </Container>
    )
  }
}

ChooseFilterVehicle.defaultProps = {
  vehicles: null
}

ChooseFilterVehicle.propTypes = {
  vehicles: arrayOf(object)
}

export default ChooseFilterVehicle
