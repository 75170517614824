export default {
  __html: `<g fill="none" fill-rule="evenodd">
                <rect width="109" height="37" x=".5" y=".5" stroke="#FFF" rx="3"/>
                <g fill="#FFF">
                    <path d="M10.705 7.945C9.763 7.945 9 8.78 9 9.812v19.32C9 30.165 9.762 31 10.705 31h8.49c.94 0 1.704-.834 1.704-1.867V9.813c0-1.032-.762-1.868-1.705-1.868h-8.49zm-.59 3.024v17.007h9.669V10.97h-9.668zm4.835 19.275a.75.75 0 0 0 .743-.756.75.75 0 0 0-.743-.756.75.75 0 0 0-.744.756.75.75 0 0 0 .744.756zM13.462 9.457c0 .209.128.378.296.378h2.383c.163 0 .296-.175.296-.378 0-.209-.128-.378-.296-.378h-2.383c-.163 0-.296.175-.296.378z"/>
                    <text font-family="OpenSans, Open Sans" font-size="9" letter-spacing=".18">
                        <tspan x="30.103" y="15.231">Disponível na</tspan>
                    </text>
                    <text font-family="OpenSans, Open Sans" font-size="16" letter-spacing="-.567">
                        <tspan x="30.103" y="30.077">App Store</tspan>
                    </text>
                </g>
            </g>`
}
