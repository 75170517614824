import React, { useState, useEffect } from 'react'
import Helmet from 'react-helmet'
import { Box, Flex } from 'grid-styled'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { fetchInactiveIdentifier, fetchReplaceIdentifier } from 'alelo-logic/actions'
import ImersiveHeader from '../../elements/ImersiveHeader'
import { setLoading } from '../../modules/PageLoader/actions'
import Button from '../../elements/Button'
import BlockReplaceModal from '../../modules/BlockReplaceModal'
import BlockModal from '../../modules/BlockModal'
import ReceivedModal from '../../modules/ReceivedModal'
import { currencyParser } from 'alelo-logic/utils'
import { showModal } from '../../modules/ModalRoot/actions'
import { CONFIRM_UPDATE } from '../../../constants/ModalTypes'

import imageError from '../../../static/contestacao.png'

import {
  Content,
  Heading,
  Subtitle,
  Paragraph,
  Wrapper,
  ParagraphGray,
  Row,
  CheckBoxCep,
  BoxCep,
  CepText,
  NewAddress,
  WrapperSelectNewAddress,
  WrapperFormNewAddress,
  NewAddressText
} from './styles'

import BlockIdentifiersListOptions from '../../modules/BlockIdentifiersListOptions'
import OtherAddressForm from '../../modules/OtherAddressForm'

const { helmetTitle, title } = require('./data.json')

const ListOptions = [
  {
    chave: 'RECEBER',
    descricao: 'Receber novo produto'
  },
  {
    chave: 'POSSUO',
    descricao: 'Já possuo um produto, quero apenas substituí-lo'
  },
  {
    chave: 'BLOQUEAR',
    descricao: 'Quero apenas bloquear o produto'
  }
]

function BlockNewIdentifier(props) {
  const [addressSelected, setAddressSelected] = useState('actual')
  const [address, setAddress] = useState({})
  const [, setReasonList] = useState([])
  const [reasonSelected, setReasonSelected] = useState({})
  const [identifier, setIdentifier] = useState('')
  const [idAccount, setIdAccount] = useState('')
  const [optionSelected, setOptionSelected] = useState(null)
  const [loading, setLoading] = useState(false)
  const [newIdentifier, setNewIdentifier] = useState('')
  const [modalBlock, setModalBlock] = useState(false)
  const [modalReceive, setModalReceive] = useState(false)
  const [modalReplace, setModalReplace] = useState(false)
  const [idOrder, setIdOrder] = useState('')

  useEffect(() => {
    setReasonList(props.reasonListBlock)
    const reasonParam = getUrlParameter('option')
    // const descriptionParam = getUrlParameter('description')

    if (reasonParam && props.reasonListBlock) {
      const reason = props.reasonListBlock.find(x => x.chave === reasonParam)
      setReasonSelected(reason)
    }
    setIdentifier(match.params.identifierId)
    setIdAccount(match.params.id)

    if (props.user && !props.user.enderecos) {
      history.push(`/minha-veloe/${idAccount}/identificadores`)
      return true
    }

    setAddress(props.user.enderecos[0])

    // const {
    //   cep,
    //   uf,
    //   cidade,
    //   bairro,
    //   logradouro,
    //   numero,
    //   complemento
    // } = user.enderecos[0]
  }, [])

  const getUrlParameter = name => {
    name = name.replace(/[[]/, '\\[').replace(/[\]]/, '\\]')
    var regex = new RegExp('[\\?&]' + name + '=([^&#]*)')
    var results = regex.exec(location.search)
    return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '))
  }

  const changeCheckBox = evt => {
    setAddressSelected(evt.target.value)
  }

  const handleSave = values => {
    setLoading(true)
    const { shoModalErro, fetchInactive, fetchReplace } = props

    if (optionSelected === 'BLOQUEAR') {
      const data = {
        codigoIdentificador: identifier,
        codigoConta: idAccount,
        motivoInativacaoIdentificador: reasonSelected.chave,
        motivoInativacaoIdentificadorDescricao: reasonSelected.descricao
      }
      fetchInactive(data)
        .then(response => {
          setLoading(false)
          setIdOrder(response.result)
          setModalBlock(true)
        })
        .catch(error => {
          console.log('error', error)
          error.json().then(reponse => {
            shoModalErro(CONFIRM_UPDATE, {
              modalTitle:
                reponse.message || 'Problemas ao bloquear seu produto, tente novamente mais tarde.',
              image: imageError
            })
            setLoading(false)
          })
        })
      window.dataLayer = window.dataLayer || []
      window.dataLayer.push({
        event: 'event',
        eventAction: 'clique:bloquear',
        eventCategory: 'veloe:area-logado:meus-produtos:bloquear-substituir',
        eventLabel: 'bloquear'
      })
    } else if (optionSelected === 'POSSUO') {
      const data = {
        codigoIdentificador: identifier,
        codigoConta: idAccount,
        motivoInativacaoIdentificador: reasonSelected.chave,
        motivoInativacaoIdentificadorDescricao: reasonSelected.descricao,
        novoCodigoIdentificador: newIdentifier
      }
      fetchReplace(data)
        .then(response => {
          console.log(response)
          setLoading(false)
          setIdOrder(response.result)
          setModalReplace(true)
        })
        .catch(error => {
          error.json().then(reponse => {
            shoModalErro(CONFIRM_UPDATE, {
              modalTitle: reponse.message,
              image: imageError
            })
            setLoading(false)
          })
        })
      window.dataLayer = window.dataLayer || []
      window.dataLayer.push({
        event: 'event',
        eventAction: 'clique:substituir',
        eventCategory: 'veloe:area-logado:meus-produtos:bloquear-substituir',
        eventLabel: 'substituir'
      })
    } else if (optionSelected === 'RECEBER') {
      let addressNew = null

      if (addressSelected === 'new') {
        const { additionalInfo, address, city, neighborhood, number, state, zipCode } = values[0]

        addressNew = {
          cep: zipCode,
          logradouro: address,
          numero: number,
          complemento: additionalInfo || '',
          bairro: neighborhood,
          uf: state,
          cidade: city
        }
      } else {
        const { cep, logradouro, numero, complemento, bairro, uf, cidade } = address
        addressNew = {
          cep,
          logradouro,
          numero,
          complemento,
          bairro,
          uf,
          cidade
        }
      }
      const data = {
        codigoIdentificador: identifier,
        codigoConta: idAccount,
        motivoInativacaoIdentificador: reasonSelected.chave,
        motivoInativacaoIdentificadorDescricao: reasonSelected.descricao,
        endereco: addressNew
      }
      fetchReplace(data)
        .then(response => {
          setLoading(false)
          setIdOrder(response.result)
          setModalReceive(true)
        })
        .catch(error => {
          console.log('error', error)
          error.json().then(reponse => {
            shoModalErro(CONFIRM_UPDATE, {
              modalTitle:
                reponse.message || 'Problemas ao bloquear seu produto, tente novamente mais tarde.',
              image: imageError
            })
            setLoading(false)
          })
        })
    }
  }

  const handleChange = e => {
    setOptionSelected(e.currentTarget.value)
  }

  const handleChangeNewVeloeTag = value => {
    const result = Object.keys(value).map(function(key) {
      return [value[key]]
    })

    const valueFilter = result.splice(0, result.length - 1).join('')
    setNewIdentifier(valueFilter)
  }

  const handleClick = async () => {
    setModalBlock(false)
    history.push(`/minha-veloe/${match.params.id}/identificadores`)
  }

  const { history, handleDelete, saving, match } = props

  return (
    <section>
      <ImersiveHeader text="Meus Produtos" back={history.goBack} />
      <Content>
        <Box width={[1, 1]}>
          <Helmet title={helmetTitle} />
          <Heading>{title}</Heading>
          <Row>
            <ParagraphGray>MOTIVO</ParagraphGray>
            <Subtitle>{reasonSelected?.descricao}</Subtitle>
          </Row>
          <Row>
            <ParagraphGray>ACESSÓRIO A SER BLOQUEADO/SUBSTITUIDO</ParagraphGray>
            <Subtitle>{identifier}</Subtitle>
          </Row>
          <Row>
            <Box width={[1, 6 / 12]}>
              <Wrapper>
                <Subtitle>Selecione uma opção</Subtitle>
                <BlockIdentifiersListOptions
                  list={ListOptions}
                  handleChange={handleChange}
                  optionSelected={optionSelected}
                  handleChangeNewVeloeTag={handleChangeNewVeloeTag}
                  newIdentifier={newIdentifier}
                />
              </Wrapper>
            </Box>
          </Row>
          {(optionSelected === 'RECEBER' || optionSelected === 'POSSUO') && (
            <Row>
              <Box width={[1, 6 / 12]}>
                <Paragraph>
                  {reasonSelected.cobrar && (
                    <p>
                      Para substituir seu produto, será necessário o pagamento do valor adicional de
                      R${currencyParser(reasonSelected.valor)} que será descontado do seu saldo
                      atual.
                    </p>
                  )}
                </Paragraph>
              </Box>
            </Row>
          )}
          {optionSelected !== 'RECEBER' && (
            <Row>
              <Flex wrap>
                <Box width={[1, 'auto']}>
                  <Button
                    buttonType="white"
                    onClick={() => {
                      history.goBack()
                      window.dataLayer = window.dataLayer || []
                      window.dataLayer.push({
                        event: 'event',
                        eventAction: 'clique:cancelar',
                        eventCategory: 'veloe:area-logado:meus-produtos:bloquear-substituir',
                        eventLabel: 'cancelar'
                      })
                    }}
                    text="Cancelar"
                  />
                </Box>
                <Box width={[1, 'auto']} ml={[0, 30]} mt={[20, 0]}>
                  <Button
                    disabled={!optionSelected || loading}
                    loading={loading}
                    buttonType="skyBlue"
                    text={optionSelected === 'POSSUO' ? 'Substituir' : 'Bloquear'}
                    onClick={() => handleSave()}
                  />
                </Box>
              </Flex>
            </Row>
          )}
          {optionSelected === 'RECEBER' && (
            <Row>
              <Box width={[1]}>
                <Flex align="center" px={[0, 0, 0, 0]}>
                  <Box width={[1, 1]}>
                    <Row>
                      <Subtitle>Confirme endereço de entrega</Subtitle>
                    </Row>
                    <Row>
                      <BoxCep>
                        <CheckBoxCep
                          type="radio"
                          value="actual"
                          checked={addressSelected === 'actual'}
                          onChange={e => changeCheckBox(e)}
                        />
                        <CepText
                          onClick={() => {
                            window.dataLayer = window.dataLayer || []
                            window.dataLayer.push({
                              event: 'event',
                              eventAction: 'clique:endereco-atual',
                              eventCategory: 'veloe:area-logado:meus-produtos:bloquear-substituir',
                              eventLabel: 'endereco-atual'
                            })
                          }}
                        >
                          {address.logradouro}, {address.numero}, {address.complemento}{' '}
                          {address.bairro} - {address.cidade} - {address.uf} -{address.cep}
                        </CepText>
                      </BoxCep>
                    </Row>
                    <Row>
                      <NewAddress focus={addressSelected === 'new'}>
                        <WrapperSelectNewAddress>
                          <NewAddressText>
                            <CheckBoxCep
                              type="radio"
                              value="new"
                              checked={addressSelected === 'new'}
                              onChange={e => changeCheckBox(e)}
                            />
                            <CepText
                              onClick={() => {
                                window.dataLayer = window.dataLayer || []
                                window.dataLayer.push({
                                  event: 'event',
                                  eventAction: 'clique:endereco-novo',
                                  eventCategory:
                                    'veloe:area-logado:meus-produtos:bloquear-substituir',
                                  eventLabel: 'endereco-novo'
                                })
                              }}
                            >
                              Outro endereço de entrega
                            </CepText>
                          </NewAddressText>
                        </WrapperSelectNewAddress>
                        <WrapperFormNewAddress>
                          {addressSelected === 'new' ? (
                            <OtherAddressForm
                              initialValues={{ address: [] }}
                              onSubmit={values => handleSave(values.address)}
                              onDelete={handleDelete}
                              length={address ? address.length : 0}
                              saving={saving}
                              match={match}
                              history={history}
                              loadingButton={loading}
                              disableButton={loading}
                            />
                          ) : null}
                        </WrapperFormNewAddress>
                      </NewAddress>
                    </Row>
                    {optionSelected === 'RECEBER' && addressSelected === 'actual' && (
                      <Row>
                        <Flex wrap>
                          <Box width={[1, 'auto']}>
                            <Button
                              buttonType="white"
                              onClick={() => {
                                history.goBack()
                                window.dataLayer = window.dataLayer || []
                                window.dataLayer.push({
                                  event: 'event',
                                  eventAction: 'clique:cancelar',
                                  eventCategory:
                                    'veloe:area-logado:meus-produtos:bloquear-substituir',
                                  eventLabel: 'cancelar'
                                })
                              }}
                              text="Cancelar"
                            />
                          </Box>
                          <Box width={[1, 'auto']} ml={[0, 30]} mt={[20, 0]} onClick={() => {}}>
                            <Button
                              disabled={!optionSelected || loading}
                              loading={loading}
                              width="185px"
                              buttonType="skyBlue"
                              text="Enviar Pedido"
                              onClick={() => {
                                handleSave()
                                window.dataLayer = window.dataLayer || []
                                window.dataLayer.push({
                                  event: 'event',
                                  eventAction: 'clique:enviar-pedido',
                                  eventCategory:
                                    'veloe:area-logado:meus-produtos:bloquear-substituir',
                                  eventLabel: 'enviar-pedido'
                                })
                              }}
                            />
                          </Box>
                        </Flex>
                      </Row>
                    )}
                  </Box>
                </Flex>
              </Box>
            </Row>
          )}
        </Box>
      </Content>
      {modalBlock && <BlockModal handleClick={handleClick} idPedido={idOrder} />}
      {modalReceive && <ReceivedModal handleClick={handleClick} idPedido={idOrder} />}
      {modalReplace && <BlockReplaceModal handleClick={handleClick} idPedido={idOrder} />}
    </section>
  )
}

const mapStateToProps = ({ form, reducer }) => ({
  form,
  account: reducer.account,
  accountPlans: reducer.accountPlans,
  user: reducer.user,
  reasonListBlock: reducer.reasonsListBlock.reasons
})

const mapDispatchToProps = dispatch => ({
  setLoading: isLoading => {
    dispatch(setLoading(isLoading))
  },
  fetchInactive: data => dispatch(fetchInactiveIdentifier(data)),
  fetchReplace: data => dispatch(fetchReplaceIdentifier(data)),
  shoModalErro: (imagem, infos) => dispatch(showModal(imagem, infos))
})

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(BlockNewIdentifier))
