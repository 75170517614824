import { getSubsidyMessage } from '../services/commonService'

import types from './types'

const { FETCH_SUBSIDY_MESSAGE } = types

export default function fetchSubsidyMessage(idConta) {
  return async dispatch => {
    const subsidyMessage = await getSubsidyMessage(idConta)
    if (subsidyMessage) {
      dispatch({
        type: FETCH_SUBSIDY_MESSAGE,
        subsidyMessage: subsidyMessage
      })
      return subsidyMessage
    }
    return {}
  }
}
