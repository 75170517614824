import { getVehicles } from '../services/dashboardService'
import types from './types'
const { FETCH_VEHICLES } = types

const fetchGetVehicles = accountId => dispatch => {
  return getVehicles(accountId)
    .then(vehicles => {
      dispatch({ type: FETCH_VEHICLES, vehicles })
    })
    .catch(error => {
      console.log('Erro em #fetchGetVehicles()', error)
      return error
    })
}

export default fetchGetVehicles
