import { zipCodeGet } from '../services'

import types from './types'

const { FETCH_ZIP_CODE } = types

function fetchGetZipCode(data) {
  return dispatch =>
    zipCodeGet(data).then(address => {
      dispatch({
        type: FETCH_ZIP_CODE,
        state: address
      })

      return address
    })
}

export default fetchGetZipCode
