export default {
  __html: `<g>
                <path fill="#b7c203"  d="M3,28.5c-0.3,0-0.5,0-0.8-0.1c-1.6-0.4-2.5-2-2.2-3.7c1.9-7,6.2-13.3,12-17.8c2.9-2.2,6.2-4,9.6-5.1
                    C25.3,0.6,29,0,32.8,0c3.8,0,7.5,0.6,11.2,1.8C47.4,3,50.7,4.8,53.6,7c2.8,2.2,5.4,4.9,7.4,7.9c2.1,3,3.6,6.4,4.6,9.9
                    c0.4,1.6-0.5,3.2-2.2,3.7c-1.6,0.4-3.3-0.5-3.7-2.1c-1.6-5.7-5.1-10.9-9.9-14.6C44.9,8,38.9,5.9,32.7,5.9c-6.3,0-12.2,2-17.2,5.8
                    c-4.8,3.7-8.2,8.8-9.8,14.7C5.6,27.7,4.4,28.5,3,28.5L3,28.5z M32.9,67.2c-15.4,0-28.9-10.3-32.8-25c-0.4-1.6,0.5-3.2,2.2-3.6
                    c1.6-0.4,3.2,0.5,3.6,2.1c3.2,12.1,14.3,20.6,27,20.6s23.8-8.4,27-20.6c0.4-1.6,2.1-2.5,3.6-2.1c1.6,0.4,2.6,2,2.2,3.6
                    C61.9,56.9,48.3,67.2,32.9,67.2z"/>
                <path  fill="#b7c203"  d="M25.7,47.5l-9.8-10.9c-1.1-1.3-1-3.2,0.2-4.3c1.3-1.1,3.2-1,4.3,0.2l0,0l7.6,8.5l17-15.5
                    c1.2-1.2,3.1-1.2,4.3,0c1.2,1.2,1.2,3.1,0,4.3l0,0L30.1,47.6c-0.6,0.6-1.3,0.9-2.1,0.9C27.1,48.4,26.3,48.1,25.7,47.5L25.7,47.5z"
                    />
                <path fill="none" d="M-17.4-16.1h100v99h-100V-16.1z"/>
            </g>`
}
