import React from 'react'
import Helmet from 'react-helmet'
import { actions } from 'alelo-logic'
import { connect } from 'react-redux'

import EditCompanyDataForm from '../../modules/EditCompanyDataForm'
import { showModal } from '../../modules/ModalRoot/actions'
import { CONFIRM_UPDATE } from '../../../constants/ModalTypes'
import { startSaving, stopSaving } from '../../modules/form-state-actions'
import companyFactory from './factory'

import { Content, Heading, Paragraph, Wrapper } from '../EditUserData/styles'

import image from '../../../static/confirmation.png'

const { helmetTitle, title, subtitle, modalTitle } = require('./data.json')

const { fetchUpdateBusinessProfile } = actions

const EditCompanyData = props => {
  const initialValues = companyFactory.toForm(props.profile)

  return (
    <section>
      <Helmet title={helmetTitle} />
      <Content>
        <Heading>{title}</Heading>
        <Paragraph>{subtitle}</Paragraph>
      </Content>
      <Wrapper>
        <EditCompanyDataForm
          initialValues={initialValues}
          onSubmit={values => props.handleSave(companyFactory.toAPI(values))}
        />
      </Wrapper>
    </section>
  )
}

const mapStateToProps = state => ({
  profile: state.reducer.user.profile
})

const mapDispatchToProps = dispatch => ({
  initFormState: () => dispatch(stopSaving()),
  handleSave: profile => {
    dispatch(startSaving())

    dispatch(fetchUpdateBusinessProfile(profile))
      .then(() => dispatch(showModal(CONFIRM_UPDATE, { modalTitle, image })))
      .then(() => dispatch(stopSaving()))
      .catch(e => {
        console.log('Erro na promise', e)
        dispatch(stopSaving())
      })
  }
})

export default connect(mapStateToProps, mapDispatchToProps)(EditCompanyData)
