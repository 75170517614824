import types from '../actions/types'

const { FETCH_EC_LOCATIONS } = types

function ecLocationsReducer(state = [], action = {}) {
  switch (action.type) {
    case FETCH_EC_LOCATIONS: {
      return action.state
    }

    default:
      return state
  }
}

export default ecLocationsReducer
