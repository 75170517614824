export default {
  __html: `<g transform="translate(-13, -13)">
                <path d="M83.2,53l-3.2-4.1c-0.5-0.7-1.3-1-2.2-1c-0.9-0.1-1.8,0.3-2.4,1.1c-1.2,1.5-3,2.4-5.1,2.4
                    c-2.1,0-3.9-0.9-5.1-2.4c-0.9-1.2-2.6-1.5-3.8-0.5c-1.2,1-1.4,2.7-0.5,4c2.2,2.9,5.6,4.6,9.4,4.6c0.9,0,1.8-0.1,2.6-0.3l1.3,2.6
                    c0.5,1,1.4,1.5,2.4,1.5c0.4,0,0.8-0.1,1.2-0.3c1.3-0.7,1.9-2.4,1.2-3.8L79,56.6c0.5,0.6,1.3,1,2.1,1c0.6,0,1.2-0.2,1.7-0.6
                    C84,56,84.1,54.2,83.2,53"/>
                <path d="M40.8,54.2c0,10.3,6.1,11.9,9.7,11.9c1.1,0,2.9-0.1,4.5-0.3c1.5-0.2,2.6-1.6,2.4-3.1
                    c-0.2-1.5-1.7-2.5-3.2-2.3c-1.2,0.2-2.7,0.2-3.6,0.2c-1.4,0-4,0-4-6.4v-8c0-0.1,0-0.3,0-0.4c0.7-0.4,1.3-0.8,2-1.2
                    c5.3-3.4,9.5-7.8,12.6-12.9c2.2,3.8,6.1,8.1,12.1,8.1c1.6,0,2.8-1.2,2.8-2.7c0-1.5-1.3-2.7-2.8-2.7c-5.2,0-8.3-7.3-9-9.2
                    c0.6-1.7,1.1-3.5,1.5-5.3c0.3-1.5-0.7-2.9-2.2-3.2c-1.5-0.3-3,0.7-3.3,2.1C58.3,27.6,53,35.1,45.3,40c-7.7,4.9-16.9,6.7-25.9,4.9
                    c-1.5-0.3-3,0.7-3.3,2.1c-0.3,1.5,0.7,2.9,2.2,3.2c2.7,0.5,5.4,0.8,8.1,0.8c4.9,0,9.8-0.9,14.4-2.6V54.2z"/>
                <path d="M64.5,74.5c-1.2-0.9-3-0.7-3.9,0.4c-2.4,2.8-6.3,4.4-10.6,4.4c-4.3,0-8.2-1.6-10.6-4.4
                    c-1-1.1-2.7-1.3-3.9-0.4c-1.2,0.9-1.4,2.4-0.5,3.5c3.5,4,9,6.4,15,6.4c6.1,0,11.5-2.3,15-6.4C65.9,76.9,65.7,75.3,64.5,74.5"/>
            </g>`
}
