/**
 * Select User Current Account
 */
export const selectUserCurrentAccount = (reducer = {}) => {
  const { plans = [], activePlanId } = reducer.accountPlans || {}
  if (plans.length && activePlanId) {
    return plans.find(pl => pl.id === activePlanId) || {}
  }
  return {}
}
