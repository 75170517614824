import React from 'react'
// import moment from 'moment-business-days'
import { isEmpty } from 'lodash'

import { WrapperInfoLink, TextInfoLink, ButtonAddress } from './styles'

import ArrowRight from '../../elements/Icon/arrow-right'
const {
  textActivateTagHelper,
  buttonActivateTagHelper,
  buttonNotArrived,
  textNotArrived
} = require('./data.json')

const ActivateTagHelper = props => (
  <WrapperInfoLink>
    <TextInfoLink>{textActivateTagHelper}</TextInfoLink>

    <ButtonAddress onClick={() => props.history.push('/identificadores/ativacao')}>
      {buttonActivateTagHelper}
      <ArrowRight width="16" height="16" />
    </ButtonAddress>
  </WrapperInfoLink>
)

const NotArrivedHelper = props => (
  <WrapperInfoLink>
    <TextInfoLink>{textNotArrived}</TextInfoLink>

    <ButtonAddress onClick={() => props.history.push('/primeiro-acesso/extravio')}>
      {buttonNotArrived}
      <ArrowRight width="16" height="16" />
    </ButtonAddress>
  </WrapperInfoLink>
)

export const renderNotArrivedHelper = (order, type, history) => {
  const trackerStatus = order.tracker[0].status.toUpperCase()
  const paymentStatus = order.payment.status.toUpperCase()
  if (
    (trackerStatus === 'DEVOLVIDO' && paymentStatus === 'PAGAMENTO APROVADO') ||
    (trackerStatus === 'DEVOLVIDO' && type === 'pos') ||
    (trackerStatus === 'CANCELADO' && type === 'pos') ||
    (trackerStatus === 'ENTREGUE' && isEmpty(paymentStatus)) ||
    (trackerStatus === 'DEVOLVIDO' && isEmpty(paymentStatus)) ||
    (trackerStatus === 'CANCELADO' && isEmpty(paymentStatus))
  ) {
    return <NotArrivedHelper history={history} />
  }
  return null
}

export const renderActivateTagHelper = (order, type, history) => {
  const trackerStatus = order.tracker[0].status.toUpperCase()
  const paymentStatus = order.payment.status.toUpperCase()
  if (
    (isEmpty(order.tracker.status) && paymentStatus === 'PAGAMENTO APROVADO') ||
    (trackerStatus === 'EM TRANSPORTE' && paymentStatus === 'PAGAMENTO APROVADO') ||
    (trackerStatus === 'CANCELADO' && paymentStatus === 'PAGAMENTO APROVADO') ||
    (trackerStatus === 'ENVIADO' && paymentStatus === 'PAGAMENTO APROVADO') ||
    (trackerStatus === 'EXTRAVIADO' && paymentStatus === 'PAGAMENTO APROVADO') ||
    (trackerStatus === 'DEVOLVIDO' && paymentStatus === 'PAGAMENTO APROVADO') ||
    (trackerStatus === 'EM TRANSPORTE' && type === 'pos') ||
    (trackerStatus === 'CANCELADO' && type === 'pos') ||
    (trackerStatus === 'ENVIADO' && type === 'pos') ||
    (trackerStatus === 'EXTRAVIADO' && type === 'pos') ||
    (trackerStatus === 'DEVOLVIDO' && type === 'pos')
  ) {
    return <ActivateTagHelper history={history} />
  }
  return null
}

// export const isShipmentDelayed = order => {
//   // extrair qtdade de dias uteis
//   const orderDate = moment(order.date, 'DD-MM-YYYY')
//   console.log('----------------orderDate--------------------')
//   console.log(orderDate)
//   console.log('------------------------------------')
//   const businessDays = orderDate.businessDiff(moment())
//   console.log('---------------businessDays---------------------')
//   console.log(businessDays)
//   console.log('------------------------------------')
//   // subtract from the diff date
//   const interval = moment(orderDate).diff(moment(), 'days')
//   console.log('-----------------interval-------------------')
//   console.log(interval)
//   console.log('------------------------------------')
//   if (interval <= 7) return false
//   return true
// }
