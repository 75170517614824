import { VALIDATION_NICKNAME_MSG_EMPTY, VALIDATION_NICKNAME_MSG_INVALID } from './constants'

export default function identifierNickname(value, values) {
  const { identifierType } = values

  if (identifierType === 'TAG') {
    return null
  }

  if (!value) return VALIDATION_NICKNAME_MSG_EMPTY

  if (value.length < 3) {
    return VALIDATION_NICKNAME_MSG_INVALID
  }
}
