import styled, { css } from 'styled-components'
import { Link } from 'react-router-dom'

import { offWhite, linkNavyBlue, darkGrey, tealish, white } from '../../../styles/settings/colors'

import content from '../../../styles/_objects/content'
import rem from '../../../styles/tools/rem'
import media from '../../../styles/tools/media'

export const Content = styled.div`
  ${content};

  margin: 0 auto;
  user-select: none;
`

export const RelativeContainer = styled.div`
  position: relative;
`

export const NavigationLink = styled(Link)`
  outline: none;
  font-family: 'OpenSans';
  font-size: ${rem('14px')};
  font-weight: 600;
  text-decoration: none;
  transition: 0.2s color;
  position: relative;
  transition: font-weight 0.3s;
`

export const SubmenuNavigationList = styled.ul`
  margin: 0;
  padding: 0;
  position: relative;
  text-decoration: none;
  white-space: nowrap;
  width: 100%;

  ${media.small`
        height: ${rem('46px')};
    `};

  :after {
    content: '';
    clear: both;
    display: table;
  }
`

export const SubmenuNavigationItem = styled.li`
  display: inline-block;
  margin-right: ${rem('32px')};
  padding: ${({ secondary }) => (secondary ? rem('12px') : rem('15px'))} 0;
  display: ${({ disabled }) => (disabled ? 'none' : 'inherit')};
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'inherit')};
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};

  ${media.large`
      float: left;
      :last-child {
        margin-right: 0;
      }
  `};

  ${NavigationLink} {
    ${({ active }) =>
      active &&
      css`
        color: ${({ secondary }) => (secondary ? white : linkNavyBlue)};
      `};
  }
  a {
    color: ${({ secondary }) => (secondary ? white : darkGrey)};
  }
  a:hover {
    color: ${({ secondary }) => (!secondary ? linkNavyBlue : white)};
  }
  ${({ secondary, active }) =>
    secondary &&
    active &&
    css`
      a {
        font-weight: bold;
        &::after {
          position: absolute;
          content: '';
          height: ${rem('4px')};
          border-radius: ${rem('4px')};
          background-color: ${white};
          left: 0;
          bottom: ${rem('-12px')};
          width: 100%;
        }
      }
    `};
`

export const BottomLine = styled.span`
  background-color: ${linkNavyBlue};
  border-radius: ${rem('4px')};
  bottom: 0;
  content: '';
  height: ${rem('4px')};
  position: absolute;
  left: 0;
  transition: 0.4s left, 0.4s width;
  width: 0;
`

export const SubmenuNavigation = styled.nav`
  background-color: ${({ secondary }) => (secondary ? tealish : offWhite)};
  overflow-style: none;
  -ms-overflow-style: none;
  overflow-x: scroll;
  overflow-y: hidden;

  ::scrollbar {
    display: none;
  }
  ::-webkit-scrollbar {
    display: none;
  }

  ${media.large`
        overflow: hidden;
    `};

  ${({ lastItemOnRight }) =>
    lastItemOnRight &&
    css`
      ${SubmenuNavigationItem}:last-child {
        ${media.large`
            float: right;
        `};
      }
    `};
`
