import types from '../actions/types'

const {
  SET_ACCOUNT_PLANS,
  SET_EC_ACCOUNT_PLANS,
  SET_ACCOUNT_ACTIVE_PLAN_ID,
  SET_ACCOUNT_PLAN,
  SET_CANCEL_ACCOUNT_STATUS,
  SET_ACCOUNT_PAYMENT_TYPE
} = types

const initialState = {
  plans: [],
  ecPlans: [],
  activePlanId: null,
  accountHasPaymentType: null
}

function accountPlansReducer(state = initialState, action = {}) {
  switch (action.type) {
    case SET_ACCOUNT_PLANS: {
      return {
        ...state,
        plans: action.plans
      }
    }

    case SET_ACCOUNT_PAYMENT_TYPE: {
      return {
        ...state,
        accountHasPaymentType: action.hasPayment
      }
    }

    case SET_EC_ACCOUNT_PLANS: {
      return {
        ...state,
        plans: action.plans
      }
    }

    case SET_ACCOUNT_PLAN: {
      return {
        ...state,
        plan: action.plan
      }
    }

    case SET_CANCEL_ACCOUNT_STATUS: {
      return {
        ...state,
        plan: {
          ...state.plan,
          cancelation: action.payload
        }
      }
    }

    case SET_ACCOUNT_ACTIVE_PLAN_ID: {
      return {
        ...state,
        activePlanId: action.activePlanId
      }
    }

    default:
      return state
  }
}

export default accountPlansReducer
