import styled from 'styled-components'

import { offWhite } from '../../../styles/settings/colors'

import rem from '../../../styles/tools/rem'
import media from '../../../styles/tools/media'

export const Structure = styled.div`
  width: 100%;
`

export const Resume = styled.div`
  background-color: ${offWhite};
  border: 0;

  ${media.large`
    height: ${rem('140px')};
  `};

  @media print {
    height: ${rem('140px')};
  }
`

export const BoxPlanResume = styled.div`
  margin-top: ${rem('-154px')};

  @media print {
    margin-top: ${rem('-120px')};
  }
`
