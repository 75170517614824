import { merge } from 'lodash'
import types from '../actions/types'

const { UPDATE_RECHARGES, RESET_RECHARGES } = types

const initialState = {
  data: [],
  next: null
}

function dashboardRechargesReducer(state = initialState, action = {}) {
  switch (action.type) {
    case UPDATE_RECHARGES: {
      return {
        ...state,
        data: merge([...state.data, ...action.data]),
        next: action.next
      }
    }
    case RESET_RECHARGES: {
      return {
        ...state,
        data: [],
        next: null
      }
    }
    default:
      return state
  }
}

export default dashboardRechargesReducer
