import React from 'react'
import { range } from 'lodash'

import Icon from '../../elements/Icon/Icon'

import { Steps, Step, StepCircle, CircleCheck } from './styles'

const RechargeBreadcrumbs = ({ steps = 3, active = 1 }) => (
  <nav>
    <Steps>
      {range(steps).map(step => (
        <Step key={step} active={step + 1 === active} visited={step + 1 < active}>
          <StepCircle>
            {step + 1 > 9 ? step + 1 : `0${step + 1}`}

            {step + 1 < active && (
              <CircleCheck>
                <Icon width="20" height="20" icon="green-check" viewBox="0 0 20 20" />
              </CircleCheck>
            )}
          </StepCircle>
        </Step>
      ))}
    </Steps>
  </nav>
)

export default RechargeBreadcrumbs
