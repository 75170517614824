export const updateTokenModal = token => ({
  type: 'UPDATE_TOKEN_MODAL',
  data: token
})

export const setTokenModalError = error => ({
  type: 'SET_TOKEN_MODAL_ERROR',
  data: error
})

export const setTokenModalLoading = isLoading => ({
  type: 'SET_TOKEN_MODAL_LOADING',
  data: isLoading
})

export const setTokenModalTitle = title => ({
  type: 'SET_TOKEN_MODAL_TITLE',
  data: title
})
