import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Field } from 'redux-form'
import { Flex, Box } from 'grid-styled'
import { utils, actions, services } from 'alelo-logic'
import Icon from '../../elements/Icon/Icon'
import Button from '../../elements/Button'

import {
  SecondaryHeading,
  Heading,
  ExcludeLinkContainer,
  ExcludeLink,
  ButtonContainer
} from './styles'

import {
  renderTextInput,
  renderSelectField,
  renderZipCodeInput,
  renderCheckboxField
} from '../../../utils/renderInputs'

import { parseCities, parseStates } from '../../../utils/parseCombo'

import { showModal } from '../../modules/ModalRoot/actions'
import { CONFIRM_UPDATE } from '../../../constants/ModalTypes'

import image from '../../../static/contestacao.png'

const {
  labels,
  secondaryAddressTitle,
  excludeLink,
  titleModalError,
  descriptionModalError
} = require('./data.json')

const { fetchZipCode } = actions
const { getStates, getCitiesFromState } = services

class AddressFormSection extends Component {
  constructor(props) {
    super(props)
    this.state = {
      states: [],
      formStatus: this.createAllIntialFormStatus(props)
    }
  }

  componentDidMount = () => {
    this.retrieveStates()
    if (!this.localControl.isCitiesInit) {
      this.fetchCitiesForEachForm(this.props)
    }
  }

  componentWillReceiveProps = (props, param2) => {
    if (!this.localControl.isCitiesInit) {
      this.fetchCitiesForEachForm(props)
    }

    if (!this.localControl.isFormStatusInit) {
      const allStatus = this.createAllIntialFormStatus(props)
      if (allStatus.length > 0) {
        this.setState({ formStatus: allStatus })
      }
    }
  }

  localControl = { isFormStatusInit: false, isCitiesInit: false }
  createAllIntialFormStatus = props => {
    const allFormStatus = []
    for (let i = 0; i < props.fields.length; i += 1) {
      const status = this.createEmptyFormStatus(false)
      status.lastSearch = props.fields.get(i).zipCode

      allFormStatus.push(status)
    }

    this.localControl.isFormStatusInit = props.fields.length > 0

    return allFormStatus
  }

  fetchCitiesForEachForm = props => {
    for (let i = 0; i < props.fields.length; i += 1) {
      this.updateFormStatus(i, { isSearchingCep: true })

      getCitiesFromState({ state: props.fields.get(i).state }).then((address = {}) => {
        const cities = parseCities(address)
        this.updateFormStatus(i, { isSearchingCep: false, cities: cities })
      })
    }

    this.localControl.isCitiesInit = props.fields.length > 0
  }

  createEmptyFormStatus = isNew => ({
    lastSearch: '',
    isSearchingCep: false,
    isNew: isNew,
    cities: []
  })

  checkboxNormalize = index => value => {
    const newValue = !!value
    const target = index === 0 ? 1 : 0
    this.props.fields.get(target).mail = !newValue

    return newValue
  }

  resetPage = index => {
    const fieldValues = this.props.fields.get(index)
    const fielsToPopulate = {
      address: '',
      number: '',
      additionalInfo: '',
      neighborhood: '',
      state: '',
      city: '',
      zipCode: ''
    }

    this.props.fields.remove(index)
    this.props.fields.insert(index, {
      ...fieldValues,
      ...fielsToPopulate
    })
  }

  autoCompleteAddress = index => (event, oldValue, newValue) => {
    const formStatus = this.state.formStatus[index]

    if (newValue && newValue.length >= 9 && newValue !== formStatus.lastSearch) {
      this.updateFormStatus(index, { isSearchingCep: true })

      const fieldValues = this.props.fields.get(index)
      const data = { zip_code: newValue }

      this.props
        .getAddressFromZipCode(data)
        .then((address = {}) => {
          const fielsToPopulate = {
            address: address.logradouro,
            number: '',
            additionalInfo: '',
            neighborhood: address.bairro,
            state: address.uf,
            city: address.cidade
          }

          this.props.fields.remove(index)
          this.props.fields.insert(index, {
            ...fieldValues,
            ...fielsToPopulate
          })

          this.updateFormStatus(index, { lastSearch: newValue })
          this.retrieveCities(index, address.uf)
        })
        .catch(e => {
          console.log('Erro ao consultar CEP', e)
          this.resetPage(index)
          this.updateFormStatus(index, { isSearchingCep: false })

          this.props.showErrorModal()
        })
    }
  }

  retrieveStates = () => {
    getStates().then(address => {
      const states = parseStates(address)
      this.setState({ states })
    })
  }

  retrieveCities = (index, state) => {
    // this.props.getCitiesFromState({ state: state })
    getCitiesFromState({ state: state }).then((address = {}) => {
      const cities = parseCities(address)
      this.updateFormStatus(index, { isSearchingCep: false, cities: cities })
    })
  }

  changeComboStates = index => (event, oldValue, newValue) => {
    console.log(index, event, oldValue, newValue)
    this.retrieveCities(index, newValue)
  }

  removeAddress = index => {
    const isCorrespondence = this.props.fields.get(index).mail
    const key = this.props.fields.get(index).key
    this.props.onDelete(key, isCorrespondence).then(() => {
      this.props.fields.pop()
      this.props.fields.get(0).mail = true

      this.state.formStatus.pop()
    })
  }

  addAddress = () => {
    const allFormStatus = this.state.formStatus
    allFormStatus.push(this.createEmptyFormStatus(true))
    this.setState({ formStatus: allFormStatus })

    this.props.fields.push({
      mail: false
    })
  }

  updateFormStatus = (index, newData) => {
    const allFormStatus = this.state.formStatus
    const oldData = allFormStatus[index]

    allFormStatus[index] = {
      ...oldData,
      ...newData
    }

    this.setState({ formStatus: allFormStatus })
  }

  render() {
    return (
      <div>
        {this.props.fields &&
          this.props.fields.map((field, index) => {
            const formStatus = this.state.formStatus[index] || {} // primeira chamada initialValues está undefined
            const disabled =
              (formStatus.isNew && formStatus.lastSearch === '') ||
              formStatus.isSearchingCep ||
              this.props.saving

            return (
              <div key={field}>
                {index === 1 && (
                  <SecondaryHeading>
                    <Heading>{secondaryAddressTitle}</Heading>
                    <ExcludeLinkContainer>
                      <Icon icon="exclude" svgStyle="height: 6px; width: 6px" viewBox="0 0 8 8" />
                      <ExcludeLink onClick={() => this.removeAddress(index)}>
                        {excludeLink}
                      </ExcludeLink>
                    </ExcludeLinkContainer>
                  </SecondaryHeading>
                )}
                <Flex wrap width={[1, 7 / 12]} mb={[0, 16]} key={field}>
                  <Box width={2 / 3} mb={3}>
                    <Field
                      component={renderZipCodeInput}
                      label={labels.zipCode}
                      validate={utils.cepValidate}
                      normalize={utils.cepMask}
                      onBlur={this.autoCompleteAddress(index)}
                      name={`${field}.zipCode`}
                      type="text"
                      isRequired
                      disabled={formStatus.isSearchingCep || this.props.saving}
                      loading={formStatus.isSearchingCep}
                    />
                  </Box>
                  <Box width={[1, 2 / 3]} pr={[0, 2]} mb={3}>
                    <Field
                      component={renderTextInput}
                      label={labels.address}
                      validate={utils.emptyFieldValidate}
                      name={`${field}.address`}
                      type="text"
                      isRequired
                      disabled={disabled}
                    />
                  </Box>
                  <Box width={[1, 1 / 3]} pl={[0, 2]} mb={3}>
                    <Field
                      component={renderTextInput}
                      label={labels.number}
                      validate={utils.emptyFieldValidate}
                      normalize={utils.numberMask}
                      name={`${field}.number`}
                      type="text"
                      isRequired
                      disabled={disabled}
                    />
                  </Box>
                  <Box width={1} mb={3}>
                    <Field
                      component={renderTextInput}
                      label={labels.additionalInfo}
                      name={`${field}.additionalInfo`}
                      type="text"
                      disabled={disabled}
                    />
                  </Box>
                  <Box width={1} mb={3}>
                    <Field
                      component={renderTextInput}
                      label={labels.neighborhood}
                      validate={utils.emptyFieldValidate}
                      name={`${field}.neighborhood`}
                      type="text"
                      isRequired
                      disabled={disabled}
                    />
                  </Box>
                  <Box width={[1, 1 / 5]} mb={3}>
                    <Field
                      component={renderSelectField}
                      label={labels.state}
                      name={`${field}.state`}
                      options={this.state.states}
                      placeholder={labels.select}
                      isRequired
                      disabled={true}
                    />
                  </Box>
                  <Box width={[1, 4 / 5]} pl={[0, 3]} mb={3}>
                    <Field
                      component={renderSelectField}
                      label={labels.city}
                      placeholder={labels.select}
                      validate={utils.emptyFieldValidate}
                      name={`${field}.city`}
                      options={formStatus.cities || []}
                      isRequired
                      disabled={true}
                    />
                  </Box>
                  <Box width={1} mb={3}>
                    <Field
                      component={renderCheckboxField}
                      label={labels.correspondence}
                      name={`${field}.mail`}
                      normalize={this.checkboxNormalize(index)}
                      disabled={this.props.fields.length === 1}
                    />
                  </Box>
                </Flex>
              </div>
            )
          })}
        <ButtonContainer amount={this.props.fields.length <= 1 ? 'singular' : 'plural'}>
          <Button text="Salvar" type="submit" disabled={this.props.saving} />
        </ButtonContainer>
        {this.props.fields.length <= 1 ? (
          <Button text="Adicionar endereço" buttonType="white" onClick={this.addAddress} />
        ) : null}
      </div>
    )
  }
}

const mapDispatchToProps = dispatch => ({
  getAddressFromZipCode: zipCode => dispatch(fetchZipCode(zipCode)),
  showErrorModal: () =>
    dispatch(
      showModal(CONFIRM_UPDATE, {
        modalTitle: titleModalError,
        modalDescription: descriptionModalError,
        image
      })
    )
  // getCitiesFromState: state => dispatch(fetchCitiesFromState(state)),
  // getStates: state => dispatch(fetchStates())
})

export default connect(null, mapDispatchToProps)(AddressFormSection)
