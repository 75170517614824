import styled, { css } from 'styled-components'
import rem from 'styles/tools/rem'
import { Box } from 'grid-styled'
import { darkGrey, ghostWhite, darkBlue, lightBlue, posGreyInfo } from 'styles/settings/colors'
import { txt12OpenSans, txt12OpenSansBold } from 'styles/settings/types'

import imgLoading from '../../../static/loading.gif'

export const BoxEnd = styled(Box)`
  align-self: flex-end;
`

export const ListItem = styled.div`
  padding: ${rem('15px')} 0;
  position: relative;
  overflow: hidden;
  &:nth-child(2n + 1) {
    background-color: ${ghostWhite};
  }
  ${({ isActived }) =>
    isActived &&
    css`
      outline: 1px solid ${lightBlue};
    `};
`
export const ListItemText = styled.span`
  ${txt12OpenSans};
  color: ${({ color }) => color || darkGrey};
  font-weight: ${({ bold }) => (bold ? 'bold' : 'regular')};
`

export const Options = styled.div`
  position: absolute;
  right: 10%;
  display: flex;
  top: 50%;
  transition: transform 0.3s ease-in;
  transform: ${({ isActived }) => (isActived ? 'translateY(-50%)' : 'translateY(4rem)')};
`

export const Option = styled.a`
  cursor: pointer;
  ${txt12OpenSansBold};
  letter-spacing: 1px;
  color: ${darkBlue};
  &:hover {
    text-decoration: underline;
  }
  ${props =>
    props.loading &&
    `
      background-image: url(${imgLoading});
      background-size: contain;
      padding-left: 30px;
      cursor: not-allowed;
      color: ${posGreyInfo};

      &:hover {
        text-decoration: none;
      }
    `};
`

export const OptionsSeparator = styled(ListItemText)`
  margin: 0 ${rem('10px')};
  color: ${darkBlue};
  font-weight: bold;
`

export const RowButtonContainer = styled.div`
  top: 30%;
  position: absolute;
  right: ${rem('20px')};
  transition: transform 0.3s;
  ${({ isActived }) =>
    isActived &&
    css`
      transform: rotate(-90deg);
      span,
      span::after,
      span::before {
        background-color: ${darkBlue};
      }
    `};
`
