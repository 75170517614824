import styled from 'styled-components'
import { skyBlue, white } from '../../../styles/settings/colors'
import { txt14OpenSansBold, txt12OpenSans } from '../../../styles/settings/types'
import rem from '../../../styles/tools/rem'
import content from '../../../styles/_objects/content'

export const Content = styled.section`
  ${content};
`

export const VehicleBar = styled.div`
  background-color: ${skyBlue};
  color: ${white};
  padding: ${rem('25px')} 0;
`

export const Span = styled.div`
  ${txt12OpenSans};

  text-transform: uppercase;
`

export const Title = styled.div`
  ${txt14OpenSansBold};
`
