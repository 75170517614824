import styled from 'styled-components'
import rem from 'styles/tools/rem'
import media from 'styles/tools/media'

import { linkNavyBlue, darkGrey, ghostWhite, paleGrayTwo } from 'styles/settings/colors'

import {
  title40GalanoBold,
  title24GalanoBold,
  txt12OpenSans,
  txt14OpenSans,
  txt16OpenSans
} from 'styles/settings/types'

export const Heading = styled.header`
  padding: ${rem('25px')} 0 ${rem('15px')};

  ${media.large`
        padding: ${rem('68px')} 0 ${rem('32px')};
    `};
`

export const Title = styled.h1`
  ${title24GalanoBold} color: ${linkNavyBlue};
  margin: 0 0 ${rem('15px')};
  line-height: 1;

  ${media.large`
        ${title40GalanoBold}
    `};
`

export const Label = styled.p`
  ${txt12OpenSans} color: ${darkGrey};
  margin: 0 0 ${rem('16px')};
  line-height: 1;
  text-transform: uppercase;
`

export const Text = styled.p`
  ${txt14OpenSans} color: ${darkGrey};
  margin: 0;
  line-height: 1.5;
`

export const Hr = styled.hr`
  height: ${rem('1px')};
  border: 0;
  background: ${paleGrayTwo};
  width: 100%;
  display: block;
`

export const Footer = styled.footer`
  background-color: ${ghostWhite};
  padding: ${rem('40px')} 0;
`

export const Disclaimer = styled.strong`
  ${txt16OpenSans} color: ${darkGrey};
  margin-bottom: ${rem('26px')};

  ${media.large`
        margin-bottom: 0;
        max-width: ${rem('380px')};
    `};
`
