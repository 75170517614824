import React from 'react'
import { utils } from 'alelo-logic'
import { renderTextInput, renderSelectField } from 'utils/renderInputs'
import { Field } from 'redux-form'
const { labels } = require('./data.json')

const FileTypeField = ({ fileTypes, saving }) => (
  <Field
    component={renderSelectField}
    label={labels.fileType}
    name="fileType"
    options={fileTypes}
    validate={utils.emptyFieldValidate}
    isRequired
    disabled={saving}
  />
)

const SequentialField = ({ saving }) => (
  <Field
    component={renderTextInput}
    label={labels.fileNumber}
    name="sequential"
    type="text"
    validate={utils.emptyFieldValidate}
    normalize={utils.numberMask}
    isRequired
    disabled={saving}
  />
)

export { FileTypeField, SequentialField }
