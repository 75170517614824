import styled from 'styled-components'

import media from 'styles/tools/media'

import { txt14OpenSans } from 'styles/settings/types'
import { linkNavyBlue, red } from 'styles/settings/colors'

export const ErrorMessage = styled.span`
  ${txt14OpenSans}
  color: ${red};
`

export const TextPassword = styled.a`
  ${txt14OpenSans} color: ${linkNavyBlue};
  cursor: pointer;
  font-weight: bold;
  line-height: 1.57;
  text-decoration: none;
`

export const Buttons = styled.div`
  display: flex;
  flex-direction: column;

  ${media.large`
    display: flex;
    flex-direction: row;
  `};
`

export const Radios = styled.div`
  display: flex;
  flex-direction: column;

  ${media.large`
    display: flex;
    flex-direction: row;
    justify-content: space-around;
  `};
`
