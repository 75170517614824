import styled, { css } from 'styled-components'

import { olive, crimson, offWhite, linkNavyBlue, grey } from '../../../styles/settings/colors'

import em from '../../../styles/tools/em'
import rem from '../../../styles/tools/rem'
import media from '../../../styles/tools/media'

export const Container = styled.aside`
  border-radius: ${rem('6px')};
  width: 100%;

  ${media.large`
        background-color: ${offWhite};
        box-shadow: 0 ${rem('10px')} ${rem('15px')} ${rem('-5px')} rgba(0, 0, 0, .2);
        padding: ${rem('22px')} ${rem('38px')};
    `};
`

export const Title = styled.h2`
  color: ${linkNavyBlue};
  font-family: Galano;
  font-size: ${rem('20px')};
  font-weight: bold;
  margin-bottom: ${rem('10px')};
`

export const StepList = styled.ul`
  margin: 0;
  padding: 0;
`

export const StepItem = styled.li`
  align-items: center;
  display: flex;
  list-style: none;
  margin-bottom: ${rem('17px')};
`

export const Text = styled.div`
  color: ${linkNavyBlue};
  font-family: OpenSans;
  font-size: ${rem('12px')};
  letter-spacing: ${em('-0.5px')};
`

export const RoundedCheck = styled.svg`
  flex: 0 0 auto;
  fill: ${olive};
  margin-right: ${rem('8px')};
  transition: 0.2s fill;

  ${({ showErrors, valid }) =>
    showErrors &&
    !valid &&
    css`
      fill: ${crimson};
    `} ${({ showErrors, valid }) =>
    !showErrors &&
    !valid &&
    css`
      fill: ${grey};
    `};
`
