import React from 'react'

const LogoPipe = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="38"
    height="14"
    viewBox={'0 0 38 14'}
  >
    <defs>
      <path
        id="a"
        d="M19 14C11.845 14 5.374 11.485.778 6.919.205 6.374-.056 5.797.01 5.215c.088-.783.737-1.247.874-1.337L5.504.586A2.843 2.843 0 0 1 7.194 0c.68 0 1.31.265 1.869.789C11.596 3.034 15.129 4.276 19 4.276c3.871 0 7.405-1.242 9.948-3.497C29.497.265 30.125 0 30.805 0c.905 0 1.555.477 1.691.586l4.62 3.292c.137.09.786.554.874 1.337.065.582-.195 1.159-.776 1.712C32.625 11.485 26.154 14 19 14"
      />
    </defs>
    <g fill="none" fillRule="evenodd">
      <mask id="b" fill="#fff">
        <use xlinkHref="#a" />
      </mask>
      <use fill={props.fill} xlinkHref="#a" />
      <g fill={props.fill} mask="url(#b)">
        <path d="M-5-17h48v48H-5z" />
      </g>
    </g>
  </svg>
)

export default LogoPipe
