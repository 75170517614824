import TemplateNoSubMenu from '../app/templates/TemplateNoSubMenu'
import FirstAccessFollowRequest from '../app/pages/FirstAccessFollowRequest'
import FirstAccessFollowRequestDetail from '../app/pages/FirstAccessFollowRequestDetail'

export default {
  path: '/minha-veloe/:id/identificadores/acompanhar-pedido',
  component: TemplateNoSubMenu,
  routes: [
    {
      path: '/minha-veloe/:id/identificadores/acompanhar-pedido',
      exact: true,
      component: FirstAccessFollowRequest
    },
    {
      path: '/minha-veloe/:id/identificadores/acompanhar-pedido/:order',
      exact: true,
      component: FirstAccessFollowRequestDetail
    }
  ]
}
