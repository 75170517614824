import { fetchAccountPlans, fetchGetBusinessUser, fetchGetUser } from 'alelo-logic/actions'
import PageLoader from 'app/modules/PageLoader'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { RouteWithSubRoutes } from '../../../routes'

class TemplateEmpty extends Component {
  constructor(props) {
    super(props)
    this.state = {
      user: null
    }
  }

  componentDidMount() {
    this.props.getAccountPlans()
    this.props.getUser(this.props.account.accountType)
  }

  shouldComponentUpdate(nextProps) {
    const hasChange = nextProps.location.pathname !== this.props.location.pathname
    return hasChange
  }

  render() {
    const { routes } = this.props

    return (
      <PageLoader>
        <div>
          {routes.map(route => (
            <RouteWithSubRoutes key={route.path} {...route} />
          ))}
        </div>
      </PageLoader>
    )
  }
}

const mapStateToProps = state => ({
  account: state.reducer.account,
  user: state.reducer.user
})

const mapDispatchToProps = dispatch => ({
  getAccountPlans: () => dispatch(fetchAccountPlans()),
  getUser: userType => {
    if (userType === 'pf') {
      dispatch(fetchGetUser())
    } else {
      dispatch(fetchGetBusinessUser())
    }
  }
})

export default connect(mapStateToProps, mapDispatchToProps)(TemplateEmpty)
