import React from 'react'
import { Flex } from 'grid-styled'
import { utils } from 'alelo-logic'

import Chart from './chart'
import Table from './table'
import { Text, CashDisplay, Currency } from './styles'
const { title } = require('./data.json')

const { currencyParser } = utils

const DashboardCashDisplay = ({ resume, value, moneyTransfer }) => (
  <div>
    <CashDisplay>
      <Text>
        <span>{title}</span>
      </Text>

      <Flex display="flex" direction={['column', 'row']} justify="space-between">
        <Currency>{currencyParser(value)}</Currency>
      </Flex>
    </CashDisplay>
    <Chart consumption={resume.consumption} />
    <Table data={moneyTransfer} />
  </div>
)

export default DashboardCashDisplay
