import React from 'react'
import 'react-select/dist/react-select.css'
import Icon from '../Icon/Icon'
import {
  SelectConstructor,
  // MobileSelect,
  WrapperSelect,
  Wrapper,
  dropdownArrow
} from './styles'

import { Label, BottomMessage, Error } from '../TextInput/styles'

function SelectBox({
  disabled,
  errorMessage,
  isRequired,
  label,
  name,
  negative,
  onChange,
  options,
  loadOptions,
  value,
  placeholder
}) {
  const Select = SelectConstructor(loadOptions)

  return (
    <Wrapper negative={negative}>
      <Label htmlFor={name}>
        {label}
        {isRequired && '*'}
      </Label>
      <WrapperSelect>
        <Select
          value={value}
          onChange={e => onChange(e.value)}
          options={options}
          placeholder={placeholder}
          searchable={false}
          clearable={false}
          arrowRenderer={() => null}
          hasError={errorMessage}
          openOnFocus
          disabled={disabled}
          loadOptions={loadOptions}
        />
        <Icon
          icon="dropdown-arrow"
          svgStyle={dropdownArrow}
          width="12"
          height="7"
          viewBox="0 0 12 7"
        />
      </WrapperSelect>
      {/* <MobileSelect disabled={disabled}>
        <select
          className="Select-control"
          value={value}
          disabled={disabled}
          onBlur={e => onChange(e.target.value)}
        >
          {options.map(option => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
        <Icon
          icon="dropdown-arrow"
          svgStyle={dropdownArrow}
          width="12"
          height="7"
          viewBox="0 0 12 7"
        />
      </MobileSelect> */}
      {errorMessage && errorMessage.length > 1 && (
        <BottomMessage>
          <Error>{errorMessage}</Error>
        </BottomMessage>
      )}
    </Wrapper>
  )
}

export default SelectBox
