import styled from 'styled-components'
import { linkNavyBlue } from '../../../styles/settings/colors'
import { title18Galano } from '../../../styles/settings/types'
import rem from '../../../styles/tools/rem'

export const LoaderContainer = styled.div`
  display: block;
  position: relative;
  width: 100%;
  height: calc(100vh - 200px);
`
export const LoaderContent = styled.div`
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`

export const LoaderAnimation = styled.div`
  width: ${rem('24px')};
  height: ${rem('24px')};
  border-radius: 100%;
  position: relative;
  margin: 0 auto;

  &:before,
  &:after {
    content: '';
    position: absolute;
    top: ${rem('-4px')};
    left: ${rem('-4px')};
    width: 100%;
    height: 100%;
    border-radius: 100%;
    border: ${rem('4px')} solid transparent;
    border-top-color: #00c3d3;
  }

  &:before {
    z-index: 100;
    animation: spin 1s infinite linear;
  }

  &:after {
    border: ${rem('4px')} solid #f1f1f7;
  }

  @keyframes spin {
    0% {
      -webkit-transform: rotate(0deg);
      -ms-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
    }

    100% {
      -webkit-transform: rotate(360deg);
      -ms-transform: rotate(360deg);
      -o-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
`

export const LoaderMessage = styled.div`
  ${title18Galano} display: block;
  text-align: center;
  color: ${linkNavyBlue};
  margin-top: ${rem('15px')};
  opacity: 0;
  animation: fadeIn 0.4s 1s linear;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }
`
