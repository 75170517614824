import React, { useState } from 'react'
import { Container, InputField } from './styles'
import IcSearch from '../Icon/icSearch'

function RoundedInput(props) {
  const [focused, setFocused] = useState(false)

  const setFocus = () => {
    setFocused(true)
  }

  const setBlur = () => {
    setFocused(false)
  }

  return (
    <Container focused={focused} empty={props.pristine}>
      <InputField
        {...props}
        empty={props.pristine}
        placeholder={props.placeholder}
        focused={focused}
        onFocus={() => setFocus()}
        onBlur={() => setBlur()}
      />
      <IcSearch {...props} focused={focused} />
    </Container>
  )
}

export default RoundedInput
