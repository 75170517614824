/* eslint-disable */

import validate from 'validate.js/validate.js'
import { VALIDATION_CPF_MSG_INVALID } from './constants'

function checkCPF(cpf = '') {
  let sum = 0
  let residual

  const value = cpf.replace(/(-)|\./g, '')

  if (value === null || value === '00000000000' || '') return false

  if (value.length !== 11) return false

  for (let i = 1; i <= 9; i++) {
    sum += parseInt(value.substring(i - 1, i)) * (11 - i)
  }
  residual = (sum * 10) % 11

  if (residual === 10 || residual === 11) residual = 0
  if (residual != parseInt(value.substring(9, 10))) return false

  sum = 0
  for (let i = 1; i <= 10; i++) {
    sum += parseInt(value.substring(i - 1, i)) * (12 - i)
  }
  residual = (sum * 10) % 11

  if (residual === 10 || residual === 11) residual = 0
  if (residual != parseInt(value.substring(10, 11))) return false

  return true
}

export default function validateCPF(value) {
  if (checkCPF(value)) return undefined
  return VALIDATION_CPF_MSG_INVALID
}
