import moment from 'moment'

const companyFactory = {
  toAPI: data =>
    data && {
      nomeFantasia: data.name,
      cnaePrincipal: data.cnae,
      faturamentoMedioMensal: data.income
    },
  toForm: data =>
    data && {
      name: data.nomeFantasia,
      cnpj: data.cnpj,
      companyName: data.razaoSocial,
      doc: moment(data.dataConstituicao).format('DD/MM/YYYY'),
      foc: data.formaConstituicao,
      cnae: data.cnaePrincipal,
      registration: data.inscricaoMunicipal,
      income: data.faturamentoMedioMensal,
      avatar: data.avatar
    }
}

export default companyFactory
