import styled from 'styled-components'
import { txt12OpenSansBold, txt12OpenSans } from 'styles/settings/types'
import { white, ghostWhite, linkNavyBlue, graySuit } from 'styles/settings/colors'
import {
  BaseTable,
  BaseTableHeader,
  BaseTableHeaderCell,
  BaseTableRow,
  BaseTableRowCell
} from 'styles/objects/Table'
import rem from 'styles/tools/rem'
import media from 'styles/tools/media'

export const Table = styled(BaseTable)`
  max-width: 700px;
  ${media.small`
    padding: ${rem('16px')};
  `};
  ${media.large`
    margin: 40px 0px;
  `};
`

export const TableHeader = styled(BaseTableHeader)`
  height: 40px;
  ${media.small`
    justify-content: space-between;
  `};
`

export const TableHeaderCell = styled(BaseTableHeaderCell)`
  ${txt12OpenSansBold};
  color: ${graySuit};
`

export const TableHeaderActionCell = styled(TableHeaderCell)`
  ${txt12OpenSansBold};
  color: ${linkNavyBlue};
  text-align: right;
`

export const TableRow = styled(BaseTableRow)`
  height: 40px;
  ${({ index }) => `background-color: ${index % 2 === 0 ? white : ghostWhite};`};
`

export const TableRowCell = styled(BaseTableRowCell)`
  ${txt12OpenSans};
  color: ${linkNavyBlue};
`

export const TableRowBoldCell = styled(TableRowCell)`
  ${txt12OpenSansBold};
`

export const TableRowActionCell = styled(TableRowBoldCell)`
  text-align: right;
`
