import { requestFileHistory } from '../services/ecService'

import types from './types'

const { FETCH_EC_REQUEST_FILE_HISTORY } = types

function fetchECRequestFileHistory() {
  return dispatch =>
    requestFileHistory()
      .then(result => {
        dispatch({
          type: FETCH_EC_REQUEST_FILE_HISTORY,
          state: result
        })
        return result
      })
      .catch(error => {
        console.log('Erro em #fetchECRequestFileHistory()')
        console.log(error)
      })
}

export default fetchECRequestFileHistory
