import React from 'react'

function AddItem(props) {
  const { fetchHandler, children, data, next, planId, hasNext } = props

  const getMore = () => {
    fetchHandler(next)
  }

  return (
    <div>
      {React.cloneElement(children, {
        data,
        next,
        planId,
        loadMoreHandler: getMore,
        hasNext
      })}
    </div>
  )
}

export default AddItem
