import React from 'react'
import PropTypes from 'prop-types'

import { Overlay, Modal } from './styles'

const Blue = ({ backgroundImage, children, position }) => (
  <Overlay>
    <Modal backgroundImage={backgroundImage} position={position}>
      {children}
    </Modal>
  </Overlay>
)

Blue.propTypes = {
  backgroundImage: PropTypes.string,
  children: PropTypes.element.isRequired,
  position: PropTypes.string
}

export default Blue
