import React from 'react'
import { utils } from 'alelo-logic'
import moment from 'moment'
import { withRouter } from 'react-router-dom'
import { Icon } from '../Icon'

import {
  Text,
  ListItem,
  ItemTitle,
  ItemIcon,
  ItemBullet,
  Bullet,
  ItemDescription,
  ItemPrice,
  Price
  // ContestButton,
  // ContestButtonMobile
} from './styles'

const formatTime = info => {
  let time = ''
  if (info.type === 'park' && info.value < 0) {
    time = (
      <Text>
        <span>
          <b>Utilizado em</b>
          <time>{utils.ISODateFormat(info.date)}</time>
        </span>
        <span>
          <b>Entrada</b>
          <time>{utils.timeFormat(moment(info.startTime, 'HH:mm:ss'))}</time>
        </span>
        <span>
          <b>Saída</b>
          <time>{utils.timeFormat(moment(info.exitTime, 'HH:mm:ss'))}</time>
        </span>
        <span>
          <b>Permanência</b>
          <time>{info.stayTime}</time>
        </span>
        {info.vehicle.placa && (
          <span>
            <b>Placa</b>
            <time>{info.vehicle.placa}</time>
          </span>
        )}
      </Text>
    )
  } else if (info.type === 'payment') {
    time = (
      <Text>
        <span>
          <b>Recebido em</b>
          <time>{utils.ISODateFormat(info.date)}</time>
        </span>
        <span>
          <b>Hora</b>
          <time>{utils.timeFormat(moment(info.startTime, 'HH:mm:ss'))}</time>
        </span>
      </Text>
    )
  } else if (info.type === 'dry-wash') {
    time = (
      <Text>
        <span>
          <b>Utilizado em</b>
          <time>{utils.ISODateFormat(info.date)}</time>
        </span>
        <span>
          <b>Entrada</b>
          <time>{utils.timeFormat(moment(info.startTime, 'HH:mm:ss'))}</time>
        </span>
        <span>
          <b>Saída</b>
          <time>{utils.timeFormat(moment(info.exitTime, 'HH:mm:ss'))}</time>
        </span>
        <span>
          <b>Permanência</b>
          <time>{info.stayTime}</time>
        </span>
      </Text>
    )
  } else if (info.value > 0) {
    time = (
      <Text>
        <span>
          <b>Realizada em</b>
          <time>{utils.ISODateFormat(info.date)}</time>
        </span>
        <span>
          <b>Hora</b>
          <time>{utils.timeFormat(moment(info.startTime, 'HH:mm:ss'))}</time>
        </span>
      </Text>
    )
  } else {
    time = (
      <Text>
        <span>
          <b>Utilizado em</b>
          <time>{utils.ISODateFormat(info.date)}</time>
        </span>
        <span>
          <b>Hora</b>
          <time>{utils.timeFormat(moment(info.startTime, 'HH:mm:ss'))}</time>
        </span>
        {info.type === 'toll' && info.vehicle.placa && (
          <span>
            <b>Placa</b>
            <time>{info.vehicle.placa}</time>
          </span>
        )}
        {info.alias && info.tipoKit && (
          <span>
            <b>{info.tipoKit}</b>
            {info.alias}
          </span>
        )}
        {info.tollFreeVeloeId && (
          <span>
            <br />
            <b>NÚMERO DA VIAGEM</b>
            <time>{info.tollFreeVeloeId}</time>
          </span>
        )}
      </Text>
    )
  }
  return time
}

const displayStatementIcon = (type, value, title) => {
  let width = '35'
  let heigth = '35'
  if (title === 'CASHBACK') {
    return (
      <ItemIcon>
        <Icon
        width="30"
        height="30"
        icon={title.toLowerCase()}
        color="#8583a4"
        viewBox={`-1 -2 ${width} ${heigth}`}
      />
      </ItemIcon>
    )
  } else if (type === 'dry-wash') {
    width = '100'
    heigth = '100'
    return (
      <ItemIcon>
        <Icon
          width="20"
          height="20"
          icon={type}
          color="#8583a4"
          viewBox={`-1 -2 ${width} ${heigth}`}
        />
      </ItemIcon>
    )
  } else if (type === 'recharge') {
    width = '35'
    heigth = '35'
    return (
      <ItemIcon>
        <Icon
          width="80"
          height="80"
          icon={type}
          color="#d7dd1a"
          viewBox={`-3 -2 ${width} ${heigth}`}
        />
      </ItemIcon>
    )
  } else {
    width = '100'
    heigth = '70'
    return (
      <ItemIcon>
        <Icon
          width="24"
          height="26"
          icon={type}
          color="#8583a4"
          viewBox={`-15 0 ${width} ${heigth}`}
        />
      </ItemIcon>
    )
  }
}

const StatementsListItem = ({ match, ...statement }) => {
  // const hasIdentifier = statement.type === 'toll' && statement.identifier
  const defaultText = `${statement.code} ${statement.details ? ` - ${statement.details}` : ''}`
  // const text = hasIdentifier ? `${statement.identifier} - NFC Payment` : defaultText
  const text = defaultText
  const textCashback = `${statement.code}`

  return (
    <ListItem value={statement.value}>
      {displayStatementIcon(statement.type, statement.value, statement.title)}
      <ItemDescription>
        <ItemTitle type={statement.type}>
          {statement.tollFree && 'VALE PEDÁGIO - '}
          {statement.title}
        </ItemTitle>
        {statement.title === 'CASHBACK' ? <Text>{textCashback}</Text> : <Text>{text}</Text>}
        {formatTime(statement)}
      </ItemDescription>
      <ItemPrice>
        <Price type={statement.type}>
          {statement.value.toFixed(2) > 0
            ? '+' + statement.value.toFixed(2)
            : statement.value.toFixed(2)}
        </Price>
        {/* <ContestButton
          to={`/minha-veloe/${match.params.id}/extrato/${statement.id}/contestacao`}
          className="contest"
        >
          Contestar
        </ContestButton> */}
        {/* <ContestButtonMobile to={`/minha-veloe/2/extrato/${statement.id}/contestacao`}>
          <img src={require('./icon-contest.png')} alt="" />
        </ContestButtonMobile> */}
      </ItemPrice>
    </ListItem>
  )
}

export default withRouter(StatementsListItem)
