import React from 'react'
import { connect } from 'react-redux'
import { Flex, Box } from 'grid-styled'
import { reduxForm } from 'redux-form'
import moment from 'moment'

import { fetchECLocations } from 'alelo-logic/actions'

import Button from 'app/elements/Button'
import { LocationField, PlateField, PeriodInitDateField, PeriodEndDateField } from './form-fields'
import { FormContent, QueryButtonContainer, Divider, Container } from './styles'

const { labels } = require('./data.json')

const dateFormat = 'DD/MM/YYYY'

class CarPlateForm extends React.Component {
  constructor(props) {
    super(props)

    const today = moment().format(dateFormat)
    this.initialValues = {
      periodInitDate: today,
      periodEndDate: today
    }
  }

  componentWillMount() {
    this.props.initialize({ ...this.initialValues, ...this.props.formValues })
  }

  componentDidMount() {
    this.props.fetchLocations()
  }

  render() {
    const { handleSubmit, locations } = this.props

    return (
      <FormContent>
        <Container>
          <form onSubmit={handleSubmit}>
            <Flex wrap>
              <Box width={[1, 10 / 12]} mb={3}>
                <LocationField locations={locations} />
              </Box>
              <Box width={[1, 4 / 12]} mb={[3, 0]}>
                <PlateField />
              </Box>
              <Box width={[1, 6 / 12]} pl={[0, 3]} mb={[3, 0]}>
                <div style={{ display: 'flex', direction: 'row' }}>
                  <Box width={1 / 2}>
                    <PeriodInitDateField dateFormat={dateFormat} />
                  </Box>
                  <Divider />
                  <Box width={1 / 2}>
                    <PeriodEndDateField dateFormat={dateFormat} />
                  </Box>
                </div>
              </Box>
              <Box width={[1, 2 / 12]} pl={[0, 3]}>
                <QueryButtonContainer>
                  <Button text={labels.submitButton} type="submit" />
                </QueryButtonContainer>
              </Box>
            </Flex>
          </form>
        </Container>
      </FormContent>
    )
  }
}

const mapStateToProps = ({ reducer }) => ({
  saving: reducer.formState.saving,
  locations: reducer.ecLocations
})

const mapDispatchToProps = dispatch => ({
  fetchLocations: () => dispatch(fetchECLocations())
})

const form = reduxForm({
  form: 'carPlateForm',
  destroyOnUnmount: false
})(CarPlateForm)

export default connect(mapStateToProps, mapDispatchToProps)(form)
