import gql from 'graphql-tag'
import { transformObject, getTipoConta } from '../common/helpers'

export const gqlFirstAccessFacade = gql`
  query PrimeiroAcessoFacade($login: String) {
    primeiroAcessoFacade(login: $login) {
      customerId
      usuarioEncontrado
      primeiroAcesso
      ddd
      telefone
      email
      messageService {
        code
        error
        message
      }
    }
  }
`

export const gqlContas = gql`
  query {
    contas {
      produto
      plano
      conta
      status
      tipo
      autorizador
      dataAdesao
      temMensalidade
      totalIdentificadores
      saldoMinRecarga
      tipoRecarga
      limite
      saldoDisponivel
      tipoPagamento
      saldo
      nomeParceiroComercial
      parceiroComercialId
      dadosPos {
        vencimentoFatura
        dataCorteFatura
        diaVencimentoConta
      }
      dadosPagamentoDebito {
        codigoBanco
        nomeBanco
        agencia
        digitoAgencia
        contaCorrente
      }

      valorAdesao
      cobrar
      entregarIdentificador
    }
  }
`

export const gqlPlanData = gql`
  query valorMensalidade($idConta: String!) {
    valorMensalidade(idConta: $idConta) {
      valor
    }
    valorRecarga(idConta: $idConta) {
      valor
    }
    valorBonificacao(idConta: $idConta) {
      dataFimBonificacao
      tipoBonificacao
      valorBonificacao
    }
  }
`

export const gqlInfoExtra = gql`
  query infosAdicionais($idConta: String!) {
    buscarPedido(conta: $idConta) {
      pedido
      data
    }
    identificadores(idConta: $idConta) {
      idIdentificador
      veiculo {
        marca
        modelo
        placa
      }
      placaVeiculo
      valorConsumido
      situacao
      tipoIdentificador
    }
  }
`

const contaMap = {
  id: 'conta',
  active: 'status',
  accountId: 'conta',
  'plan.id': 'conta',
  'plan.category': 'plano',
  'plan.rechargeType': 'tipoRecarga',
  'plan.rechargeAmount': 'saldoMinRecarga',
  accessionDate: 'dataAdesao',
  isMonthlyPayment: 'temMensalidade',
  ammount: 'saldo',
  nomeParceiroComercial: 'nomeParceiroComercial',
  parceiroComercialId: 'parceiroComercialId',
  limit: 'limite',
  'posData.payDay': 'dadosPos.vencimentoFatura',
  'posData.cutDay': 'dadosPos.dataCorteFatura',
  'posData.dueDayAccount': 'dadosPos.diaVencimentoConta',
  'debitPaymentData.bankCode': 'dadosPagamentoDebito.codigoBanco',
  'debitPaymentData.bankName': 'dadosPagamentoDebito.nomeBanco',
  'debitPaymentData.agency': 'dadosPagamentoDebito.agencia',
  'debitPaymentData.agencyDigit': 'dadosPagamentoDebito.digitoAgencia',
  'debitPaymentData.account': 'dadosPagamentoDebito.contaCorrente',
  availableAmount: 'saldoDisponivel',
  paymentType: 'tipoPagamento',
  autorizador: 'autorizador',
  isHubClient: ({ autorizador }) => (autorizador === 'HUB' ? true : null),

  valorAdesao: 'valorAdesao',
  cobrar: 'cobrar',
  entregarIdentificador: 'entregarIdentificador',

  // paymentMethod: '???', TODO valor não encontrado no graphQL
  __v: ''
}

export const gqlSubsidyMessage = gql`
  query mensagemSubsidio($idConta: String!, $idCliente: String!) {
    mensagemSubsidio(idConta: $idConta, idCliente: $idCliente) {
      mensagemFrontPrimeiraTag
      mensagemFrontDemaisTag
    }
  }
`
const InfosAdicionaisMap = {
  carPlate: 'veiculo.placa',
  identifierId: 'idIdentificador',
  // "carNick": "",
  carBrand: 'veiculo.marca',
  carModel: 'veiculo.modelo',
  // "carYear": 0,
  // "carColor": "",
  // "driverName": "",
  identifierType: 'tipoIdentificador',
  consumption: 'valorConsumido',
  active: 'situacao'
}

// TODO pode ser que não retorne nenhum plano por algum motivo, tratar!!
export const transformConta = src => {
  const { contas } = src.data
  return contas.map(conta => {
    const contaTransformed = transformObject(conta, contaMap)
    contaTransformed.plan.type = getTipoConta(conta.tipo)
    return contaTransformed
  })
}

export const transformInfosAdicionais = src => ({
  identifiersOrders: src.data.buscarPedido,
  identifiers: src.data.identificadores.map(i => transformObject(i, InfosAdicionaisMap)),
  identifiersQtd: {
    quantidade: src.data.identificadores.filter(i => i.situacao === 'ATIVO').length
  },
  representatives: [] // TODO O middleware retorna sempre este valor
})
