import React from 'react'
import { connect } from 'react-redux'
import QrCodeModal from '../QrCodeModal'
import ConfirmUpdateModal from '../ConfirmationModal'
import PaymentConfirmationModal from '../PaymentConfirmationModal'
import BankSlipModal from '../BankSlipModal'
import InputModal from '../InputModal'
import TokenModal from '../TokenModal'

const MODAL_LIST = {
  QRCODE_MODAL: QrCodeModal,
  CONFIRM_UPDATE: ConfirmUpdateModal,
  TOKEN_MODAL: TokenModal,
  INPUT_MODAL: InputModal,
  PAYMENT_CONFIRMATION_MODAL: PaymentConfirmationModal,
  BANK_SLIP_MODAL: BankSlipModal
}

const ModalRoot = ({ type, props }) => {
  if (!type) {
    return null
  }

  const Component = MODAL_LIST[type]
  return <Component {...props} />
}

export default connect(state => ({
  type: state.modalReducer.type,
  props: state.modalReducer.props
}))(ModalRoot)
