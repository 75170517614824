import React from 'react'
import PropTypes from 'prop-types'
import Icon from '../../../elements/Icon/Icon'

import {
  DropdownContainer,
  DropdownLabel,
  DropdownArrow,
  HeaderLabel,
  DropdownLabelContainer
} from './styles'

function Dropdown({ categoryLabel, dropdownLabel, onClick, isOpen }) {
  return (
    <DropdownContainer isOpen={isOpen} onClick={onClick}>
      <DropdownLabelContainer>
        <HeaderLabel isOpen={isOpen}>{categoryLabel}</HeaderLabel>
        <Icon
          icon="dropdown-arrow"
          svgStyle={DropdownArrow}
          width="12"
          height="7"
          viewBox="0 0 12 7"
        />
      </DropdownLabelContainer>

      <DropdownLabel>{dropdownLabel}</DropdownLabel>
    </DropdownContainer>
  )
}

Dropdown.defaultProps = {
  categoryLabel: '',
  dropdownLabel: '',
  headerLabelCategory: '',
  onClick: null
}

Dropdown.propTypes = {
  categoryLabel: PropTypes.string,
  dropdownLabel: PropTypes.string,
  headerLabelCategory: PropTypes.string,
  onClick: PropTypes.func
}

export default Dropdown
