import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { ConnectedRouter } from 'react-router-redux'
import 'sanitize.css/sanitize.css'
import './styles/settings/fonts'
import './styles/settings/transition'
import TagManager from 'react-gtm-module'

import store, { history } from './store'
import { App } from 'app/templates/App'
import ModalRoot from 'app/modules/ModalRoot'

const tagManagerArgs = {
  gtmId: 'GTM-PGCNQGR'
}
TagManager.initialize(tagManagerArgs)

const target = document.getElementById('root')

ReactDOM.render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <div>
        <App />
        <ModalRoot />
      </div>
    </ConnectedRouter>
  </Provider>,
  target
)
