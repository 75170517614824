import React from 'react'
import { Field, reduxForm } from 'redux-form'
import { Flex, Box } from 'grid-styled'
import PasswordInput from '../../elements/PasswordInput'
import Button from '../../elements/Button'
import StepBox from '../StepBox'
import { utils } from 'alelo-logic'

import { validatePassword } from './validatePassword'

const {
  buttonText,
  labels,
  placeholders,
  passwordStrength,
  stepBox,
  errors
} = require('./data.json')

function renderPasswordInput(props) {
  const { touched, error } = props.meta
  let strength
  let errorMessage

  if (typeof error === 'object') {
    const passwordMaxStrength = 8
    const errorList = Object.keys(error)

    strength = passwordMaxStrength / (errorList.length + 1)
    errorMessage = errors[errorList[0]]
  } else if (typeof error === 'string') {
    errorMessage = error
  }

  return (
    <div>
      <PasswordInput
        {...props.input}
        value={utils.newDefaultPassword(props.input.value)}
        label={props.label}
        placeholder={props.placeholder}
        disabled={props.disabled}
        loading={props.loading}
        maxlength={props.maxlength}
        passwordStrength={touched && props.passwordStrength && strength}
        errorMessage={touched && !!errorMessage}
        passwordMessage={touched && ((strength >= 8 && passwordStrength.high) || errorMessage)}
      />
    </div>
  )
}

function ChangePasswordForm(props) {
  const { asyncValidating, handleSubmit, hasCurrentPasswordField, valid } = props

  return (
    <form onSubmit={handleSubmit}>
      <Flex wrap>
        <Box width={[1, 6 / 12]}>
          {hasCurrentPasswordField && (
            <Box mb={3}>
              <Field
                component={renderPasswordInput}
                label={labels.current}
                placeholder={placeholders.current}
                name="currentPassword"
                loading={asyncValidating}
                isRequired
              />
            </Box>
          )}
          <Box mb={3}>
            <Field
              component={renderPasswordInput}
              label={labels.new}
              placeholder={placeholders.new}
              passwordStrength
              name="newPassword"
              setStepBoxErrors
              isRequired
              maxlength="14"
            />
          </Box>
          <Box mb={3}>
            <Field
              component={renderPasswordInput}
              label={labels.confirm}
              placeholder={placeholders.confirm}
              name="confirmPassword"
              isRequired
              maxlength="14"
            />
          </Box>
        </Box>
        <Box mb={[38, 0]} ml={[0, 60]} width={[1, 3 / 10]}>
          <StepBox
            title={stepBox.title}
            steps={[
              {
                text: stepBox.newPasswordLength
              },
              {
                text: stepBox.lowerLetterNeeded
              },
              {
                text: stepBox.upperLetterNeeded
              },
              {
                text: stepBox.numberNeeded
              },
              {
                text: stepBox.especialNeeded
              }
            ]}
          />
        </Box>
      </Flex>
      <div>
        <Button type="submit" text={buttonText} disabled={!valid} />
      </div>
    </form>
  )
}

export default reduxForm({
  form: 'changeUserPasswordData',
  validate: validatePassword,
  asyncBlurFields: ['currentPassword'],
  destroyOnUnmount: true
})(ChangePasswordForm)
