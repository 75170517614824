import {
  VALIDATION_IDENTIFIER,
  VALIDATION_IDENTIFIER_MSG_EMPTY,
  VALIDATION_IDENTIFIER_BAND_MSG_EMPTY,
  VALIDATION_IDENTIFIER_MSG_INVALID,
  VALIDATION_IDENTIFIER_BAND_MSG_INVALID,
  VALIDATION_IDENTIFIER_BAND_INVALID_NUMBER
} from './constants'

export default function validateIdentifier(value) {
  const validate = !VALIDATION_IDENTIFIER.test(value)
    ? VALIDATION_IDENTIFIER_MSG_INVALID
    : undefined
  return !value ? VALIDATION_IDENTIFIER_MSG_EMPTY : validate
}

export function identifierBandValidate(value) {
  if (!value) {
    return VALIDATION_IDENTIFIER_BAND_MSG_EMPTY
  }

  if (value.length > 2) {
    const [, second] = value.split('')
    if (second !== '4' && second !== '5') {
      return VALIDATION_IDENTIFIER_BAND_INVALID_NUMBER
    }
  }

  if (!VALIDATION_IDENTIFIER.test(value)) {
    return VALIDATION_IDENTIFIER_BAND_MSG_INVALID
  }

  return undefined
}
