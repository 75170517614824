import gql from 'graphql-tag'
import { transformObject } from '../common/helpers'

export const gqlExtrato = gql`
  query extrato(
    $idConta: String!
    $ano: String
    $mes: String
    $pagina: Int
    $placa: String
    $quantidade: Int
    $tipoTransacao: String
    $transacoes: [FiltroTransacoes]
  ) {
    extrato(
      idConta: $idConta
      ano: $ano
      mes: $mes
      pagina: $pagina
      placa: $placa
      quantidade: $quantidade
      tipoTransacao: $tipoTransacao
      transacoes: $transacoes
    ) {
      transacoes {
        identificador
        veiculo {
          placa
        }
        alias
        tipoKit
        endereco
        valor
        valePedagio
        vpVigenciaInicio
        vpVigenciaFim
        vpOrigem
        vpDestino
        vpIdveloe
        vpCnpjEmbarcador
        fastId
        cyberId
        estabelecimentoComercial {
          codigo
          nome
          tipoTransacao
        }
        clienteConta {
          cliente {
            id
          }
          conta {
            conta
          }
        }
        tipoMovimento
        dataProcessamento
        horaProcessamento
        dataEvento
        horaEvento
        dataFimEvento
        horaFimEvento
        motivoTransacao
        permanencia
      }
    }
  }
`

const mapExtrato = {
  'fast-id': 'fastId',
  'cyber-id': 'cyberId',
  'estabelecimento-comercial.nome': 'estabelecimentoComercial.nome',
  'estabelecimento-comercial.tipo-transacao': 'estabelecimentoComercial.tipoTransacao',
  'cliente-conta': 'clienteConta',
  'tipo-movimento': 'tipoMovimento',
  'data-processamento': 'dataProcessamento',
  'hora-processamento': 'horaProcessamento',
  'data-evento': 'dataEvento',
  'hora-evento': 'horaEvento',
  'data-fim-evento': 'dataFimEvento',
  'hora-fim-evento': 'horaFimEvento',
  'motivo-transacao': 'motivoTransacao'
  // TODO GraphQL não retorna info de eixo-cobrado
  // TODO GraphQL não retorna info de eixo-cadastrado
}

export const transformExtrato = transacoes => {
  return transacoes
    .map(x =>
      x.motivoTransacao.includes('ESTORNO') &&
      transacoes.find(
        y =>
          y.estabelecimentoComercial.nome === x.estabelecimentoComercial.nome &&
          y.valor === -x.valor
      )
        ? null
        : x
    )
    .filter(x => !!x)
    .map(t => ({
      ...t,
      ...transformObject(t, mapExtrato),
      valePedagio: Boolean(t.valePedagio),
      identificador: Number.parseInt(t.identificador, 10)
    }))
}

export const gqlDownloadExtratoCsv = gql`
  query extratoCSV(
    $idConta: String!
    $ano: String
    $mes: String
    $pagina: Int
    $placa: String
    $quantidade: Int
    $tipoTransacao: String
    $transacoes: [FiltroTransacoes]
  ) {
    extratoCSV(
      idConta: $idConta
      ano: $ano
      mes: $mes
      pagina: $pagina
      placa: $placa
      quantidade: $quantidade
      tipoTransacao: $tipoTransacao
      transacoes: $transacoes
    ) {
      result
    }
  }
`

export const gqlDownloadExtratoPdf = gql`
  query download(
    $idConta: String!
    $ano: String
    $mes: String
    $pagina: Int
    $placa: String
    $quantidade: Int
    $tipoTransacao: String
    $transacoes: [FiltroTransacoes]
  ) {
    download(
      idConta: $idConta
      ano: $ano
      mes: $mes
      pagina: $pagina
      placa: $placa
      quantidade: $quantidade
      tipoTransacao: $tipoTransacao
      transacoes: $transacoes
    ) {
      result
    }
  }
`
