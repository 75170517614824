import React from 'react'
import Helmet from 'react-helmet'
import { connect } from 'react-redux'
import moment from 'moment'
import { Box, Flex } from 'grid-styled'

import { setNewDueDate, lockAuthorizationPayment } from 'alelo-logic/actions'
import { setLoading } from 'app/modules/PageLoader/actions'

import Icon from 'app/elements/Icon/Icon'
import DueDateForm from 'app/modules/DueDateForm'
import { showModal } from 'app/modules/ModalRoot/actions'
import { CONFIRM_UPDATE } from 'constants/ModalTypes'

import image from 'static/confirmation.png'
import Content from 'styles/objects/Content'
import { Notice } from './styles'
const { titleModalError, titleModalSuccess } = require('./data.json')

const EditDueDate = props => {
  const onSubmit = values => {
    const {
      updateDueDate,
      setBlockChangeDueDate,
      showSuccessModal,
      showErrorModal,
      currentPlan
    } = props

    return new Promise((resolve, reject) => {
      if (values.dataFatura) {
        const data = { idConta: currentPlan.accountId, diaVenctoSolicitado: values.dataFatura }
        updateDueDate(data)
          .then(async () => {
            window.dataLayer = window.dataLayer || []
            window.dataLayer.push({
              event: 'event',
              eventCategory: 'veloe:area-logado:dados-plano',
              eventAction: 'clique:botao:alterar-data:pos',
              eventLabel: 'alteracao:[[sucesso]]',
              dimension29: `${values.dataFatura}`
            })
            await setBlockChangeDueDate()
            showSuccessModal(props.onBackButtonClick)
            resolve()
          })
          .catch(err => {
            window.dataLayer = window.dataLayer || []
            window.dataLayer.push({
              event: 'event',
              eventCategory: 'veloe:area-logado:dados-plano',
              eventAction: 'clique:botao:alterar-data:pos',
              eventLabel: 'alteracao:[[erro]]'
            })
            console.error(err)
            showErrorModal()
            return reject(err)
          })
      }
    })
  }

  const ableToChangeDueDate =
    props.authorizations.DATA &&
    !props.authorizations.DATA.dataProximaAlteracao &&
    !props.authorizations.DATA.error
  const daysToChangeDueDate = !ableToChangeDueDate
    ? moment(props.authorizations.DATA.dataProximaAlteracao, 'DD/MM/YYYY').diff(moment(), 'days') +
      1
    : null

  return (
    <Flex direction="column">
      <Helmet title="Editar data de vencimento" />
      <Content>
        <Box width={[1, 480]} mt={24}>
          <Notice>
            <Icon height={14} icon="alert" viewBox="0 0 16 13" display="flex" />
            {!ableToChangeDueDate && daysToChangeDueDate ? (
              props.authorizations.DATA.error && props.authorizations.DATA.description ? (
                <span>{props.authorizations.DATA.description}</span>
              ) : (
                <span>
                  Você fez uma alteração de vencimento recentemente. <br />
                  Em <b>{daysToChangeDueDate}</b> dia{daysToChangeDueDate > 1 ? 's' : ''} você
                  poderá fazer uma nova alteração.
                </span>
              )
            ) : (
              <span>
                Você só pode alterar a data de vencimento uma vez a cada <b>90 dias</b>.
              </span>
            )}
          </Notice>
        </Box>
        <Box width={[1, 680]}>
          <DueDateForm
            ableToChangeDueDate={ableToChangeDueDate}
            onSubmit={onSubmit}
            dueDates={props.dueDates}
            handleBackClick={() => props.onBackButtonClick()}
          />
        </Box>
      </Content>
    </Flex>
  )
}

const mapStateToProps = ({ reducer }) => ({
  authorizations: reducer.planData.authorizations
})

const mapDispatchToProps = dispatch => ({
  showSuccessModal: successAction =>
    dispatch(
      showModal(CONFIRM_UPDATE, {
        onConfirm: successAction,
        modalTitle: titleModalSuccess,
        modalDescription: (
          <span>
            Solicitação realizada com sucesso! <br /> A sua próxima fatura já virá com o novo dia de
            vencimento.
            <br />
            <br />
            Lembre-se: será mantido o dia de vencimento da sua fatura atual.
          </span>
        ),
        image
      })
    ),
  showErrorModal: () =>
    dispatch(
      showModal(CONFIRM_UPDATE, {
        modalTitle: titleModalError,
        modalDescription: (
          <span>
            Não foi possível alterar sua data de vencimento. <br />
            <br /> Verifique os dados fornecidos e tente novamente.
          </span>
        ),
        image
      })
    ),
  updateDueDate: data => dispatch(setNewDueDate(data)),
  setBlockChangeDueDate: () => dispatch(lockAuthorizationPayment('DATA')),
  setLoading: isLoading => {
    dispatch(setLoading(isLoading))
  }
})

export default connect(mapStateToProps, mapDispatchToProps)(EditDueDate)
