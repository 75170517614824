import styled from 'styled-components'
import content from '../../../styles/_objects/content'
import rem from '../../../styles/tools/rem'
import media from '../../../styles/tools/media'

export const ListContainer = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  min-height: 560px;
`

export const ListFooter = styled.div`
  ${content} display: flex;
  justify-content: flex-start;
  padding: ${rem('40px')} ${rem('30px')};

  ${media.small`
        justify-content: center;
        width: ${rem('180px')};
    `};
`
