import styled from 'styled-components'

import { hexToRgba } from '../../../utils/hexToRgba'
import { txt16OpenSans } from '../../../styles/settings/types'
import { white, paleGrayThree, blueGray, tealish, darkBlue } from '../../../styles/settings/colors'

export const Wrap = styled.li`
  background-color: ${white};
  border-radius: 8px;
  border: solid 2px ${paleGrayThree};
  display: flex;
  min-width: 280px;
  padding: 14px 13px;
  align-items: center;

  &:hover {
    cursor: pointer;
  }

  ${({ active }) =>
    active &&
    `
    border: solid 2px ${tealish};
    box-shadow: 0 4px 12px 0 ${hexToRgba(tealish, 0.24)};
  `};
`

export const Icon = styled.span`
  position: relative;

  svg path,
  svg rect {
    ${({ notGray }) => !notGray && `fill: ${blueGray};`};

    ${({ active }) => active && `fill: ${tealish};`};
  }
`

export const Description = styled.p`
  ${txt16OpenSans};
  color: ${blueGray};
  font-weight: bold;
  line-height: 1.38;
  margin: 0 0 0 16px;

  ${({ active }) => active && `color: ${darkBlue};`};
`
