import React from 'react'
import _ from 'lodash'
import { connect } from 'react-redux'
import {
  // Link,
  withRouter
} from 'react-router-dom'

import {
  // Arrow,
  CallContainer,
  IconColorCian,
  // IconColorDarkBlue,
  Container,
  IconDownload,
  // LinkCancelPlan,
  // Text,
  Title,
  TitleWithLink,
  DownloadItem
} from './styles'

const {
  // cancelPlan,
  // cancelAccount,
  downloadTerm,
  downloadGuide,
  downloadPrivacy
} = require('./data.json')

const CallDownloadTerms = ({ match, accountId, planId, accountPlans, terms, ...props }) => {
  const { plans, activePlanId } = accountPlans
  const currentPlan = plans.find(plan => String(plan.id) === String(activePlanId)) || { plan: {} }

  // const plans = accountPlans.plans || []
  // const hasMultiplePlans = plans.length > 1
  // const cancelLink = hasMultiplePlans ? `/minha-veloe/${planId}/cancelar-plano` : '/cancelar-conta'

  // SEMIAUTOMATICO
  const { identifiers = [] } = currentPlan
  const identifiersTypes = _.map(identifiers, 'identifierType')
  const semiautomaticoTypes = ['BAND', 'SMART_BAND']
  const isSemiautomatico = _.some(semiautomaticoTypes, t => _.includes(identifiersTypes, t))

  const manualUsuarioByIdentifier = {
    BAND: 'https://veloe.com.br/mobile-static/nfcpayment/ManualUsuarioPulseira.pdf',
    SMART_BAND: 'https://veloe.com.br/mobile-static/nfcpayment/ManualUsuarioRelogio.pdf'
  }

  // Here are the functions to send data do GTM and open the .pdf's files
  // TASK 95450
  function handleOpenUserManual(value) {
    window.open(terms.arquivos.urlManualUsuario)
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({
      event: 'event',
      eventCategory: 'veloe:area-logado:dados-plano',
      eventAction: 'selecionar:documento',
      eventLabel: `[[${value}]]`
    })
  }

  function handleOpenVeloeContract(value) {
    window.open(terms.arquivos.urlContratoVeloeCliente)
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({
      event: 'event',
      eventCategory: 'veloe:area-logado:dados-plano',
      eventAction: 'selecionar:documento',
      eventLabel: `[[${value}]]`
    })
  }

  function handleOpenPolicyContract(value) {
    window.open(terms.arquivos.urlPoliticaDePrivacidade)
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({
      event: 'event',
      eventCategory: 'veloe:area-logado:dados-plano',
      eventAction: 'selecionar:documento',
      eventLabel: `[[${value}]]`
    })
  }

  return (
    <CallContainer>
      <Container>
        <DownloadItem>
          <IconDownload
            svgStyle={IconColorCian}
            icon="download"
            height="40px"
            width="40px"
            viewBox="0 0 70 70"
            onClick={() => handleOpenVeloeContract('contrato-veloe')}
          />
          <Title onClick={() => handleOpenVeloeContract('contrato-veloe')}>{downloadTerm}</Title>
        </DownloadItem>
        <DownloadItem>
          <IconDownload
            svgStyle={IconColorCian}
            icon="download"
            height="40px"
            width="40px"
            viewBox="0 0 70 70"
            onClick={() => handleOpenUserManual('manual-usuario')}
          />
          {!isSemiautomatico ? (
            <Title style={{ marginTop: 0 }} onClick={() => handleOpenUserManual('manual-usuario')}>
              {downloadGuide}
            </Title>
          ) : (
            <TitleWithLink
              style={{ marginTop: 0 }}
              href={manualUsuarioByIdentifier.BAND || ''}
              target="_blank"
            >
              {downloadGuide}
            </TitleWithLink>
          )}
        </DownloadItem>
        <DownloadItem>
          <IconDownload
            svgStyle={IconColorCian}
            icon="download"
            height="40px"
            width="40px"
            viewBox="0 0 70 70"
            onClick={() => handleOpenPolicyContract('politica-privacidade')}
          />
          <Title
            style={{ marginTop: 0 }}
            onClick={() => handleOpenPolicyContract('politica-privacidade')}
          >
            {downloadPrivacy}
          </Title>
        </DownloadItem>
      </Container>

      {/* <LinkCancelPlan align="center">
        <Text to={cancelLink}>{hasMultiplePlans ? cancelPlan : cancelAccount}</Text>
        <Link to={cancelLink}>
          <Arrow
            svgStyle={IconColorDarkBlue}
            icon="right-arrow"
            height="16px"
            width="16px"
            viewBox="0 -1 14 14"
          />
        </Link>
      </LinkCancelPlan> */}
    </CallContainer>
  )
}

const mapStateToProps = ({ reducer }) => ({
  accountPlans: reducer.accountPlans,
  terms: reducer.termsCondition,
  userGuideLink: reducer.userGuideLink
})

export default connect(mapStateToProps)(withRouter(CallDownloadTerms))
