import React from 'react'
import { connect } from 'react-redux'
import Helmet from 'react-helmet'
import { withRouter } from 'react-router-dom'
import { fetchAuthentication } from 'alelo-logic/actions'

import { Main } from './styles'

const { profiles } = require('./data.json')

const Home = ({ loadUser, history }) => {
  history.push('/conta/login')

  return (
    <Main>
      <Helmet title="Veloe" />
      <h1>Hello Veloe</h1>
      <ul>
        {profiles.map((profile, index) => (
          <li key={index}>
            <button onClick={() => loadUser(profile, history)}>{profile.description}</button>
          </li>
        ))}
      </ul>
    </Main>
  )
}

Home.displayName = 'Home'

const mapDispatchToProps = dispatch => ({
  loadUser: (profile, history) => {
    if (!profile.login) {
      history.push(profile.redirectTo)
      return
    }

    if (profile.login) {
      dispatch(fetchAuthentication(profile.login, profile.password)).then(credentials => {
        if (credentials.success) {
          history.push(profile.redirectTo)
        }
      })
    }
  }
})

export default withRouter(connect(null, mapDispatchToProps)(Home))
