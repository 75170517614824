import React, { useCallback, useEffect, useState } from 'react'
import Modal from '../../elements/ModalBlue'
import Button from '../../elements/Button'

import { Wrapper, Title, Text, WrapperButton } from './styles'

import image from '../../../static/icon-veloe-background.svg'
import { getDeadline } from '../../../alelo-logic/services/commonService'

const AddTagsModal = props => {
  const [deadline, setDeadline] = useState({})

  const { handleClick } = props

  const fetchDeadline = useCallback(async () => {
    const response = await getDeadline()

    setDeadline(response)
  }, [])

  useEffect(() => {
    console.log(deadline)
    fetchDeadline()
  }, [])

  return (
    <Modal backgroundImage={image} position="right bottom">
      <Wrapper>
        <Title>Seu produto foi bloqueado</Title>
        <Text>
          Agora este veículo não vai mais ter acesso livre aos locais conveniados. Você receberá seu
          produto em até <b>{deadline.description}</b>, no endereço solicitado. Ela deverá ser
          recebida por uma pessoa maior de 18 anos com documento com foto em mãos. Fique tranquilo,
          a mensalidade será cobrada apenas após você receber e ativar a seu produto.
        </Text>
        <Text />

        <WrapperButton>
          <Button
            buttonType="darkBlue"
            text="Ok"
            onClick={() => {
              handleClick()
              window.dataLayer.push({
                event: 'event',
                eventAction: 'clique:ok',
                eventCategory: 'veloe:area-logado:meus-produtos:bloquear-substituir',
                eventLabel: 'produto-bloqueado'
              })
            }}
          />
        </WrapperButton>
      </Wrapper>
    </Modal>
  )
}

export default AddTagsModal
