import styled, { css } from 'styled-components'
import { Link } from 'react-router-dom'
import media from '../../../styles/tools/media'
import rem from '../../../styles/tools/rem'

import { txt12OpenSans, txt14OpenSans } from '../../../styles/settings/types'

import {
  linkNavyBlue,
  darkGrey,
  lightGray,
  nearWhite,
  olive,
  blueGray,
  white
} from '../../../styles/settings/colors'

export const Text = styled.p`
  ${txt14OpenSans};
  margin: 0;
`

export const ItemIcon = styled.div`
  align-items: center;
  background-color: ${white};
  border-radius: ${rem('80px')};
  border: ${rem('1px')} solid ${lightGray};
  box-shadow: 0 0 0 ${rem('10px')} ${white};
  display: flex;
  height: ${rem('40px')};
  justify-content: center;
  margin-right: ${rem('20px')};
  margin-top: ${rem('16px')};
  position: relative;
  width: ${rem('40px')};
  z-index: 11;
`

export const ItemBullet = styled.div`
  align-items: center;
  background-color: ${white};
  border-radius: ${rem('80px')};
  display: flex;
  height: ${rem('40px')};
  height: ${rem('40px')};
  justify-content: center;
  margin-right: ${rem('20px')};
  position: relative;
  width: ${rem('40px')};
  width: ${rem('40px')};
  z-index: 11;
`

export const Bullet = styled.div`
  background-color: ${darkGrey};
  border-radius: ${rem('80px')};
  display: block;
  height: ${rem('14px')};
  margin-top: ${rem('5px')};
  width: ${rem('14px')};
`

export const ItemDescription = styled.div`
  flex: 8;
  position: relative;
  z-index: 10;

  :after {
    border-left: ${rem('1px')} solid ${lightGray};
    content: '';
    height: 90%;
    left: ${rem('-40px')};
    position: absolute;
    top: ${rem('20px')};
    z-index: 10;
  }

  ${Text} {
    color: ${blueGray};
    margin-bottom: ${rem('2px')};

    b {
      font-weight: 600;
      margin-right: ${rem('5px')};
    }
  }

  span {
    margin-right: ${rem('10px')};
  }

  ${media.small`
        span {
            display: block;
        }
    `};
`

export const ItemTitle = styled.h2`
    ${txt14OpenSans}
    color: ${linkNavyBlue};
    font-weight: 700;
    margin-bottom: ${rem('2px')};

    ${props =>
      props.type === 'refund' &&
      `
        color: ${olive};
    `}

    ${props =>
      props.type === 'payment' &&
      `
        font-size: ${rem('16px')};
    `}
}
`
export const ItemPrice = styled.div`
  flex: 2;
  position: relative;
  text-align: right;
  z-index: 11;
  min-height: ${rem('95px')};

  ${media.large`
        margin-right: ${rem('10px')};
    `};
`

export const Price = styled.strong`
  display: block;
  ${txt12OpenSans};
  font-weight: 600;
  color: ${linkNavyBlue};
  margin-top: ${rem('10px')};

  ${props =>
    props.type === 'refund' &&
    `
        color: ${olive};
    `} ${media.large`
        font-size: ${rem('16px')};
    `};
`

export const ContestButton = styled(Link)`
  ${txt14OpenSans};

  border-radius: ${rem('6px')};
  border: ${rem('1px')} solid ${blueGray};
  bottom: ${rem('15px')};
  color: ${linkNavyBlue};
  font-weight: 600;
  margin: 0;
  opacity: 0;
  padding: ${rem('7px')} ${rem('20px')};
  position: absolute;
  right: 0;
  transition: opacity 0.2s linear;

  :hover {
    cursor: pointer;
  }
`

export const ContestButtonMobile = styled(Link)`
  position: absolute;
  right: 0;
  bottom: ${rem('10px')};
  width: ${rem('22px')};

  img {
    max-width: 100%;
    height: auto;
  }

  ${media.large`
        display: none;
    `};
`

export const ListItem = styled.li`
  display: flex;
  padding-bottom: ${rem('20px')};
  align-items: stratch;

  ${props =>
    props.value > 0 &&
    css`
      ${ItemTitle} {
        color: ${olive};
      }
      ${Price} {
        color: ${olive};
      }
    `} ${media.large`
        min-height: ${rem('95px')};
        padding-bottom: 0;

        :before {
            background: transparent;
            content: '';
            left: 0;
            min-height: ${rem('97px')};
            position: absolute;
            transition: background 0.3s ease;
            width: 100vw;
            z-index: 1;
        }

        :hover {
            :before {
                background-color: ${nearWhite};
            }

            .contest {
                opacity: 1;
            }
        }

    `};
`
