import { gqlMutate, gqlQuery } from './common/apolloClient'

import { gqlEditarDadoPagamento } from './queries/recharges'
import {
  gqlConsultarFormasPagamento,
  gqlAutorizaEditarFormaPagamentoDataVencimento,
  gqlConsultarUltimoAgendamentoPendente,
  gqlSimulaEdicaoFormaPagamento,
  gqlConsultarDataVencimento,
  gqlEditarFormaPagamentoV2,
  gqlEditarDataVencimento
} from './queries/payment-type'

export async function editPaymentMethod(data) {
  const {
    conta,
    numeroMascarado,
    validade,
    bandeira,
    alias,
    nome,
    cpf,
    paymentToken,
    cvv,
    valorRecarga,
    tipoPagamento,
    gateways
  } = data.dados
  await gqlMutate(gqlEditarDadoPagamento, {
    conta,
    numeroMascarado,
    validade,
    bandeira,
    alias,
    nome,
    cpf,
    paymentToken,
    cvv,
    valorRecarga: `${valorRecarga}`,
    ativo: true,
    tipoPagamento,
    gateways
  })
  // TODO Descobrir qual deve ser o retorno desta função: resp.result.msgRecarga ????
}

export async function getPaymentType() {
  const resp = await gqlQuery(gqlConsultarFormasPagamento)
  return resp.data.ConsultarFormasPagamento
}

export async function getEditAuthorizationPayment(type, accountPlanId) {
  const resp = await gqlQuery(gqlAutorizaEditarFormaPagamentoDataVencimento, {
    tipo: type,
    idConta: accountPlanId
  })

  return resp.data.AutorizaEditarFormaPagamentoDataVencimento
  // FIXME Qual o retorno desta função?
}

export async function getLastPaymentScheduling(type = 'FORMA', accountPlanId) {
  const resp = await gqlQuery(gqlConsultarUltimoAgendamentoPendente, {
    tipo: type,
    idConta: accountPlanId
  })

  return resp.data.ConsultarUltimoAgendamentoPendente
}

export async function simulateEditPaymentType(accountPlanId) {
  const resp = await gqlQuery(gqlSimulaEdicaoFormaPagamento, {
    conta: accountPlanId
  })

  return resp.data.SimulaEdicaoFormaPagamento
}

export async function getDueDate() {
  const resp = await gqlQuery(gqlConsultarDataVencimento)
  return resp.data.ConsultarDataVencimento
}

export async function editPaymentMethodWithoutRecharge(data) {
  const resp = await gqlMutate(gqlEditarFormaPagamentoV2, data.dados)
  return resp.data.EditarFormaPagamentoV2
}

export function editDueDate(data) {
  return gqlMutate(gqlEditarDataVencimento, data)
}
