import styled from 'styled-components'
import media from 'styles/tools/media'
import content from 'styles/_objects/content'
import rem from 'styles/tools/rem'

import { txt14OpenSans, title44GalanoBold } from 'styles/settings/types'
import { darkGrey } from 'styles/settings/colors'

export const Title = styled.h2`
  ${title44GalanoBold} color: ${darkGrey};
  line-height: 1;
  margin: 0;
`

export const Text = styled.p`
  ${txt14OpenSans};
  margin: 0;
`

export const Statements = styled.div`
  ${content};
  margin-top: ${rem('26px')};
  margin-bottom: ${rem('30px')};
`

export const StatementGroup = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  flex: 1;

  ${media.large`
        flex-direction: row;
    `};
`

export const Sidebar = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  z-index: 2;

  ${media.large`
        flex-direction: column;
        flex: 1;
        justify-content: flex-start;
        margin-right: ${rem('105px')};
        
    `} ${Title} {
    margin-top: ${rem('-0px')};
    margin-bottom: ${rem('8px')};
    font-size: ${rem('30px')};

    ${media.small`
            margin-top: ${rem('10px')};
            margin-right: ${rem('13px')};
        `};
  }

  ${Text} {
    color: ${darkGrey};
    font-size: ${rem('12px')};
    font-weight: 100;
    letter-spacing: ${rem('1px')};
    text-transform: uppercase;
  }
`

export const List = styled.ul`
  flex: 10;
  list-style: none;
  margin-bottom: ${rem('20px')};
  padding: 0;

  ${media.large`
        margin: 0;
    `} ${media.small`
        li:last-child {
            .description:after {
                border-left: 0;
            }
        }
    `};
`

export const StatementsFooter = styled.div`
  display: flex;

  ${media.large`
        margin-left: ${rem('142px')};
        justify-content: flex-start;
    `} ${media.small`
        .load-more-container {
            margin-left: ${rem('-4px')};
            width: ${rem('180px')};
        }
    `};
`
