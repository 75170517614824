import styled from 'styled-components'

import { lightBlue, darkBlue, darkGrey, grey } from '../../../styles/settings/colors'

export const Container = styled.div`
  position: relative;
  svg {
    position: absolute;
    right: 24px;
    top: 7px;
  }
  &:hover path {
    fill: ${darkGrey};
  }
  path {
    fill: ${({ focused, empty }) => (focused || !empty ? darkBlue : grey)} !important;
  }
`
export const InputField = styled.input`
  width: 100%;
  height: 40px;
  border-radius: 32px;
  outline: none;
  border: solid 2px
    ${({ empty, focused }) => {
      if (!empty) return `${darkGrey} !important`
      if (focused) return `${lightBlue} !important`
      return grey
    }};
  &:hover {
    border: solid 2px ${darkGrey};
  }
  &::placeholder {
    color: ${({ focused }) => (focused ? darkBlue : grey)};
  }
  &::placeholder:hover {
    color: ${darkGrey};
  }
`
