import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Box } from 'grid-styled'
import {
  fetchRechargeValues,
  fetchAccountPlans,
  fetchLogout,
  setActivePlanId,
  fetchGetUser
} from 'alelo-logic/actions'

import { RouteWithSubRoutes } from '../../../routes'

import Icon from '../../elements/Icon/Icon'
import Content from '../../../styles/objects/Content'
import rem from '../../../styles/tools/rem'

import { Header, HeaderContainer, HeaderLink, Container, HeaderButton } from './styles'

const data = require('./data.json')
const { backCopy, backCopyDados, leave } = data

class TemplateRecharge extends Component {
  componentDidMount() {
    this.prepareTemplateRecharge()
  }

  prepareTemplateRecharge = async () => {
    const { match, fetchRechargeValues, getAccountPlans, setActivePlanId, getUserData } = this.props
    const { id } = match.params
    await getUserData()
    await setActivePlanId(id)
    await fetchRechargeValues(id)
    await getAccountPlans()
  }

  onLogout = () => {
    this.props.doLogout()
    this.props.history.push('/conta/login')
  }

  renderBackBtn = () => {
    const { routes, location, accountPlans, match } = this.props
    const hasPlans = accountPlans.plans && accountPlans.plans.length > 0
    const currPlan = hasPlans && accountPlans.plans.find(pl => pl.id === match.params.id)
    const hasPaymentType = hasPlans && currPlan && !!currPlan.paymentType
    const { search } = location
    const templateType =
      (routes[0].path === '/minha-veloe/:id/editar-dados') &
      (search !== '?path=recharge') &
      (search !== '?path=track')
        ? 'dados-do-plano'
        : 'resumo'

    function renderTextBack() {
      if (!hasPaymentType) {
        return leave
      } else if (templateType === 'dados-do-plano' && search !== '?path=recharge') {
        return backCopyDados
      }
      return backCopy
    }
    if (hasPaymentType) {
      return (
        <HeaderLink id="hypBackSummary" to={`/minha-veloe/${match.params.id}/${templateType}`}>
          <Icon icon="back-arrow" width="10px" height="15px" viewBox="0 0 10 15" />
          <span>{renderTextBack()}</span>
        </HeaderLink>
      )
    }
    return (
      <HeaderButton id="hypBackSummary" onClick={this.onLogout}>
        <Icon icon="back-arrow" width="10px" height="15px" viewBox="0 0 10 15" />
        <span>{renderTextBack()}</span>
      </HeaderButton>
    )
  }

  render() {
    const { routes } = this.props

    return (
      <Container>
        <Header>
          <HeaderContainer>{this.renderBackBtn()}</HeaderContainer>
        </Header>
        <Box my={rem('45px')}>
          <Content>
            {routes.map(route => (
              <RouteWithSubRoutes key={route.path} {...route} />
            ))}
          </Content>
        </Box>
      </Container>
    )
  }
}

const mapStateToProps = ({ reducer }) => {
  return {
    accountPlans: reducer.accountPlans
  }
}

const mapDispatchToProps = dispatch => ({
  getAccountPlans: () => dispatch(fetchAccountPlans()),
  getUserData: () => dispatch(fetchGetUser()),

  setActivePlanId: planId => dispatch(setActivePlanId(planId)),
  fetchRechargeValues: planId => dispatch(fetchRechargeValues(planId)),
  doLogout: () => dispatch(fetchLogout())
})

export default connect(mapStateToProps, mapDispatchToProps)(TemplateRecharge)
