import React from 'react'

const IcPlus = props => (
  <svg width="1em" height="1em" viewBox="0 0 24 24" {...props}>
    <defs>
      <path
        id="prefix__a"
        d="M11 11V7a1 1 0 0 1 2 0v4h4a1 1 0 0 1 0 2h-4v4a1 1 0 0 1-2 0v-4H7a1 1 0 0 1 0-2h4zm1 11C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-1a9 9 0 1 0 0-18 9 9 0 0 0 0 18z"
      />
    </defs>
    <g fill="none" fillRule="evenodd">
      <mask id="prefix__b" fill="#fff">
        <use xlinkHref="#prefix__a" />
      </mask>
      <use fill="#27C2D5" fillRule="nonzero" xlinkHref="#prefix__a" />
      <g fill="#D8D7E1" mask="url(#prefix__b)">
        <path d="M0 0h24v24H0z" />
      </g>
    </g>
  </svg>
)

export default IcPlus
