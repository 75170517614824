import styled, { css } from 'styled-components'
import imgLoading from '../../../static/loading.gif'

import {
  white,
  grey,
  skyBlue,
  darkBlue,
  lightBlue,
  offWhite,
  linkNavyBlue
} from 'styles/settings/colors'

import rem from 'styles/tools/rem'
import media from 'styles/tools/media'

const buttonStyles = {
  white: css`
    background-color: ${white};
    box-shadow: 0 0 0 ${rem('2px')} ${darkBlue} inset;
    color: ${darkBlue};
    ${media.large`
      :hover {
        color: ${white};
        background-color: ${darkBlue};
      }
    `};
  `,
  transparent: css`
    background-color: transparent;
    box-shadow: 0 0 0 ${rem('2px')} ${white} inset;
    color: ${white};
  `,
  darkBlue: css`
    background-color: ${darkBlue};
    color: ${white};
  `,
  skyBlue: css`
    background-color: ${skyBlue};
    box-shadow: 0 ${rem('2px')} ${rem('8px')} 0 rgba(0, 0, 0, 0.29);
    color: ${white};
  `,
  whiteNavyBlue: css`
    background-color: ${white};
    box-shadow: 0 0 0 ${rem('2px')} ${linkNavyBlue} inset;
    color: ${linkNavyBlue};
  `
}

const disabled = props =>
  props.disabled &&
  css`
    background-color: ${offWhite};
    box-shadow: none;
    color: ${grey};
    cursor: default;

    :hover,
    :visited,
    :active,
    :focus {
      transform: none;
      background-color: ${offWhite};
      box-shadow: none;
      color: ${grey};
      cursor: default;
    }

    :before {
      display: none;
    }
  `

const buttonModifier = props => buttonStyles[props.buttonType]

export const Wrapper = styled.div`
  background: transparent;
  background-color: ${lightBlue};
  border-radius: 9999px;
  color: ${white};
  display: inline-block;
  min-width: auto;
  overflow: hidden;
  position: relative;
  text-align: center;
  transform: translateY(0);
  user-select: none;
  width: 100%;

  &:hover {
    transform: scale(1.1);
    transition: transform 0.25s;
  }

  &:before {
    background-color: #110e49;
    border-radius: 9999px 0 0 9999px;
    left: 0;
    height: 100%;
    content: '';
    display: block;
    position: absolute;
    opacity: 0;
    transition: width 0.15s ease-in 0s, opacity 0.2s ease-in 0.15s;
    top: 0;
    width: 100%;
  }

  &:active:before {
    opacity: 0.8;
    transition: 0s;
    width: 0;
  }

  ${buttonModifier};
  ${disabled};
`

export const ButtonField = styled.a`
  cursor: pointer;
  display: block;
  font-family: Galano;
  font-size: ${rem('16px')};
  font-weight: 700;
  height: 100%;
  line-height: ${rem('60px')};
  letter-spacing: ${rem('0px')};
  position: relative;
  text-align: center;
  width: 100%;
  z-index: 1;

  ${media.large`
    line-height: ${rem('56px')};
    padding: 0 ${rem('32px')} ${rem('2px')};
  `};

  ${props =>
    props.disabled &&
    css`
      cursor: not-allowed;
      ${props.loading && `background-image: url(${imgLoading})`};
      background-repeat: no-repeat;
      background-position: 20px center;
      background-size: 20px;
    `};
`

export const FormButtonField = ButtonField.withComponent('button').extend`
    border: none;
`
export const BtnIcon = styled.img`
  padding-right: 10px;
`
