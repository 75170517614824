import styled from 'styled-components'
import { darkBlue, darkGrey } from '../../../styles/settings/colors'
import { title24Galano } from '../../../styles/settings/types'

import rem from '../../../styles/tools/rem'
import media from '../../../styles/tools/media'

export const Heading = styled.h1`
  ${title24Galano} color: ${darkBlue};
`

export const ExcludeLink = styled.a`
  color: ${darkGrey};
  cursor: pointer;
  font-family: OpenSans;
  font-size: ${rem('12px')};
  margin-left: ${rem('9px')};
`

export const SecondaryHeading = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding-top: ${rem('32px')};
  border-top: 1px solid rgba(197, 195, 216, 0.5);
`

export const ExcludeLinkContainer = styled.div`
  align-items: center;
  display: flex;
`

export const ButtonContainer = styled.div`
  ${props =>
    props.amount === 'singular' &&
    `
        float: left;
        margin-right: ${rem('20px')};
    `} ${media.small`
        width: 100%
    `};
`
