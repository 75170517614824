import { gqlMutate, gqlQuery } from './common/apolloClient'
import {
  gqlActivateEMVACGIdentification as gqlActivateNFCEMVIdentification,
  gqlActivateIdentification,
  gqlDesabilitarIdentificador,
  gqlGetVehiclesCategory,
  gqlNewIdentifier,
  gqlSetAlias,
  gqlSubstituirIdentificador,
  gqlVerifyIdentifier
} from './queries/vehicles'

export async function getVehiclesCategory(categoria) {
  const payload = { categoria }
  const response = await gqlQuery(gqlGetVehiclesCategory, payload)
  return response.data.consultaCategorias
}

function getCategory({ typeCar = '', axis }) {
  if (typeCar === 'carro-passeio' || typeCar === 'carro-utilitario') {
    return 'DOIS_EIXOS_RODAGEM_SIMPLES'
  }

  if (typeCar === 'moto') {
    return 'MOTOCICLETA_MOTONETA_BICICLETA_A_MOTOR'
  }

  return axis
}

export async function verifyIdentifier(data) {
  const { identifier } = data || {}

  const payload = { serialNumber: identifier }
  // eslint-disable-next-line no-redeclare
  const { data: respData } = await gqlQuery(gqlVerifyIdentifier, payload)
  return { status: 200, ...respData }
}

export async function activateIdentifier(data) {
  const { identifier = '', accountId, plate = '', type, tipoIdentificador } = data || {}

  const categoria = plate ? getCategory(data) : ''

  const payload = {
    placa: plate,
    categoria,
    id: accountId,
    tipoProduto: type === 'pre' ? 'PRE_PAGO' : 'POS_PAGO',
    codIdentificador: identifier,
    tipoIdentificador
  }

  const { data: respData } = await gqlMutate(gqlActivateIdentification, payload)
  if (respData && respData.identificadorAtivar.error && respData.identificadorAtivar.error[0]) {
    const error = new Error('Erro ao ativar identificador')
    error.json = async () => respData.identificadorAtivar.error[0]
    throw error
  }
  return { status: 200, ...respData }
}

export async function activateEMVACGIdentifier(data) {
  const { customerId, nome, cpf, accountId, identifier, type, cvv } = data

  const payload = {
    cvv,
    codIdentificador: identifier,
    tipoIdentificador: type,
    idConta: String(accountId) || null,
    idCliente: String(customerId) || null,
    nome,
    cpf
  }

  const { data: respData } = await gqlMutate(gqlActivateNFCEMVIdentification, payload)

  if (respData && respData.identificadorAtivar.error && respData.identificadorAtivar.error[0]) {
    const error = new Error('Erro ao ativar identificador')
    error.json = async () => respData.identificadorAtivar.error[0]
    throw error
  }
  return { status: 200, ...respData }
}

export async function setAlias(data) {
  const { nickname: alias = '', identifier: codIdentificador = '' } = data || {}

  const payload = {
    codIdentificador,
    alias
  }

  const response = await gqlMutate(gqlSetAlias, payload)
  const { data: respData } = response || {}
  return { status: 200, ...respData }
}

export async function newIdentifier(data) {
  const payload = {
    fluxoCanal: 'Portal PF',
    id: data.codigoConta,
    tipoIdentificador: data.tipoIdentificador,
    endereco: {
      cep: data.endereco.cep,
      logradouro: data.endereco.logradouro,
      numero: data.endereco.numero,
      complemento: data.endereco.complemento,
      bairro: data.endereco.bairro,
      uf: data.endereco.uf,
      cidade: data.endereco.cidade
    },
    quantidadeIdentificadores: Number(data.quantidadeIdentificadores),
    valorAdesao: data.valorAdesao,
    cobrar: data.cobrar,
    entregarIdentificador: data.entregarIdentificador,
    tipoPlano: data.tipoPlano
  }

  const response = await gqlMutate(gqlNewIdentifier, payload)
  return response
}

export async function replaceIdentifier(data) {
  const payload = {
    codigoConta: data.codigoConta,
    codigoIdentificador: data.codigoIdentificador,
    motivoInativacao: data.motivoInativacaoIdentificador,
    motivoDescricao: data.motivoInativacaoIdentificadorDescricao,
    novoCodigo: data.novoCodigoIdentificador,
    ...data.endereco
  }
  const response = await gqlMutate(gqlSubstituirIdentificador, payload)
  const feedback = response.data.identificadorSubstituir

  if (feedback.error.length > 0) {
    const errorFeedback = {
      title: feedback.error[0].codigo,
      message: feedback.error[0].message
    }
    const err = new Error('Erro ao bloquear produto')
    err.json = async () => errorFeedback
    throw err
  }

  return feedback
}

export async function inactiveIdentifier(data) {
  const payload = {
    quantidadeIdentificadores: 1,
    codigoIdentificador: data.codigoIdentificador,
    motivoInativacaoIdentificador: data.motivoInativacaoIdentificador,
    motivoInativacaoIdentificadorDescricao: data.motivoInativacaoIdentificadorDescricao,
    id: data.codigoConta
  }
  const response = await gqlMutate(gqlDesabilitarIdentificador, payload)

  return { result: response.data.identificadorInativar.result }
}
