import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import moment from 'moment'

import { fetchECRequestFileHistory } from 'alelo-logic/actions'

import Header from './header'
import Form from './form'
import Table from './table'
import { Main, Container, TableTitle, SubHeader } from './styles'

class RequestFile extends React.Component {
  state = {
    requestedFiles: []
  }

  componentDidMount() {
    this.props.fetchECRequestFileHistory()
  }

  componentWillMount() {
    const { state } = this.props.history.location
    if (state) this.onFormSubmit(state)
  }

  onFormSubmit = values => {
    const request = { ...values }
    const current = moment()
    request.date = current.format('DD/MM/YYYY')
    request.hour = current.format('hh:mma')
    request.status = 'Processando'
    this.setState({ requestedFiles: [request].concat(this.state.requestedFiles) })
  }

  render() {
    const { requestedFiles } = this.state
    const { requestFileHistory } = this.props

    return (
      <Main>
        <Header />
        <Form onSubmit={this.onFormSubmit} />
        <Container>
          <SubHeader>
            <TableTitle>Acompanhe o status da sua solicitação.</TableTitle>
          </SubHeader>
          <Table data={requestedFiles.concat(requestFileHistory)} />
        </Container>
      </Main>
    )
  }
}

const mapStateToProps = ({ reducer }) => ({
  requestFileHistory: reducer.ecRequestFileHistory
})

const mapDispatchToProps = dispatch => ({
  fetchECRequestFileHistory: () => dispatch(fetchECRequestFileHistory())
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(RequestFile))
