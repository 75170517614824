import React from 'react'
import _ from 'lodash'
import { connect } from 'react-redux'
import { utils } from 'alelo-logic'
import { Box } from 'grid-styled'

import { Icon } from '../../elements/Icon'

import {
  Accession,
  Aside,
  Currency,
  Date,
  FinancialInfo,
  Label,
  MonthlyPayment,
  PlansContainer,
  PlanState,
  PlanType,
  State,
  Title,
  TitlePlan,
  ValueLimited,
  Vehicle,
  VehicleNumber,
  VehiclesContainer,
  VehicleSvg,
  VehicleText,
  WrapperAccession
} from './styles'

const {
  accessionDate,
  currency,
  monthlyPayment,
  totalLimit,
  vehicleLabel,
  bonificationEndDate,
  bonificationValid
} = require('./data.json')

const planTypes = {
  pos: 'pós',
  pre: 'pré'
}

const PlanResume = ({ accountPlans, showSummary, plandata }) => {
  const { plans, activePlanId } = accountPlans

  if (!activePlanId || plans.length <= 0) {
    return null
  }

  const currentPlan = plans.find(plan => String(plan.id) === String(activePlanId)) || { plan: {} }

  // SEMIAUTOMATICO
  const { identifiers = [] } = currentPlan
  const identifiersTypes = _.map(identifiers, 'identifierType')
  const semiautomaticoTypes = ['BAND', 'SMART_BAND']
  const isSemiautomatico = _.some(semiautomaticoTypes, t => _.includes(identifiersTypes, t))

  const planColors = isSemiautomatico
    ? utils.planColor('pre', 'semiautomatico')
    : (currentPlan.plan && utils.planColor(currentPlan.plan.type, currentPlan.plan.category)) || {
        plan: {}
      }

  const vehiclesQtd = currentPlan.identifiersQtd ? currentPlan.identifiersQtd.quantidade : 0

  const active = currentPlan.active
  return (
    <Aside color={planColors.normal}>
      <PlansContainer>
        <TitlePlan>
          <Title id="lblPlanTypes">{currentPlan.plan.category}</Title>
          <PlanType>{planTypes[currentPlan.plan.type]}</PlanType>
        </TitlePlan>
        <State>
          <PlanState>{active}</PlanState>
          {showSummary && currentPlan.accessionDate && (
            <WrapperAccession bonification={plandata.bonificationEndDate}>
              <Accession>
                <Label>{accessionDate}</Label>
                <Date>{utils.ISODateFormat(currentPlan.accessionDate)}</Date>
              </Accession>
              {plandata.bonificationEndDate && (
                <Accession>
                  <Label>
                    {bonificationEndDate} {plandata.bonificationType === 'VL' ? 'R$ ' : ''}
                    {plandata.bonificationType === 'VL'
                      ? utils.currencyParser(plandata.bonificationVaue)
                      : plandata.bonificationVaue}
                    {plandata.bonificationType === 'PC' ? '%' : ''}
                  </Label>
                  <Label>
                    {bonificationValid} <Date>{plandata.bonificationEndDate}</Date>
                  </Label>
                </Accession>
              )}
            </WrapperAccession>
          )}
        </State>
      </PlansContainer>
      <VehiclesContainer color={planColors.darken}>
        <FinancialInfo>
          {currentPlan.plan.type === 'pos' && currentPlan.limit && showSummary && (
            <Box>
              <Box>
                <Label>{totalLimit}</Label>
                <ValueLimited>
                  <Currency>{currency} </Currency>
                  {utils.currencyParser(currentPlan.limit)}
                </ValueLimited>
              </Box>
            </Box>
          )}
          {currentPlan.isMonthlyPayment &&
            showSummary &&
            plandata &&
            plandata.monthlyPayment !== undefined && (
              <Box>
                <Label>{monthlyPayment}</Label>
                <MonthlyPayment>
                  <Currency>{currency} </Currency>
                  {utils.currencyParser(plandata.monthlyPayment)}
                </MonthlyPayment>
              </Box>
            )}
        </FinancialInfo>
        {vehiclesQtd !== undefined && (
          <VehicleText color={planColors.lighter}>
            <VehicleNumber>{vehiclesQtd <= 9 ? `0${vehiclesQtd}` : vehiclesQtd}</VehicleNumber>
            <Vehicle>{vehiclesQtd > 1 ? vehicleLabel.multiple : vehicleLabel.single}</Vehicle>
          </VehicleText>
        )}
        <VehicleSvg color={planColors.lighter}>
          <Icon
            width="100%"
            icon="trama-veloe"
            svgStyle={`
                            height: 70px;
                            margin-top: 15px;
                        `}
          />
        </VehicleSvg>
      </VehiclesContainer>
    </Aside>
  )
}

const mapStateToProps = ({ reducer }) => ({
  accountPlans: reducer.accountPlans,
  plandata: reducer.planData
})

export default connect(mapStateToProps)(PlanResume)
