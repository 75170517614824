import styled from 'styled-components'
import { title32GalanoBold, title24GalanoBold, txt16OpenSans } from '../../../styles/settings/types'
import media from '../../../styles/tools/media'

import { white, darkBlue } from '../../../styles/settings/colors'

export const Wrapper = styled.div`
  min-width: 596px;
  max-width: 596px;
  width: 100%;
  min-height: 360px;
  display: flex;
  justify-content: space-around;
  flex-direction: column;

  ${media.small`
    min-width: auto;
    max-width: auto;
  `};
`

export const Title = styled.h2`
  ${title32GalanoBold};
  color: ${white};
  margin: 0 0 16px;
  max-width: 422px;
  line-height: 32px;

  ${media.small`
    ${title24GalanoBold};
  `};
`

export const Text = styled.p`
  ${txt16OpenSans};
  color: ${darkBlue};
  line-height: 25px;
  margin: 0 0 10px;
  max-width: 550px;
`

export const Subtitle = styled.h2`
  ${title24GalanoBold};
  color: ${darkBlue};
  font-size: 24px;
  margin: 0;

  ${media.small`
    font-size: 18px;
  `};
`

export const WrapperButton = styled.div`
  display: block;
  margin-top: 44px;
`
