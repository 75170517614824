import styled from 'styled-components'

import rem from '../../../styles/tools/rem'
import em from '../../../styles/tools/em'
import media from '../../../styles/tools/media'

import { txt16OpenSans, title40GalanoBold } from '../../../styles/settings/types'

import { darkBlue, white, tealish } from '../../../styles/settings/colors'

import image from '../../../static/truck-travel.png'

export const Wrapper = styled.div`
  background: url(${image}) no-repeat bottom right;
  background-color: ${tealish};
  min-height: 100vh;

  ${media.small`
    background-size: contain;
    padding-bottom: 190px;
  `};
`

export const Title = styled.h1`
  ${title40GalanoBold} letter-spacing: ${em('0.4px')};
  color: ${white};
  font-size: ${rem('48px')};
  margin: ${rem('40px')} 0;
  max-width: ${rem('615px')};

  ${media.small`
    font-size: ${rem('35px')};
  `};
`

export const Paragraph = styled.p`
  ${txt16OpenSans} color: ${darkBlue};
  max-width: ${rem('615px')};
  margin-bottom: ${rem('40px')};
`

export const ProtocolTitle = styled.p`
  ${txt16OpenSans} color: ${darkBlue};
  max-width: ${rem('615px')};
`

export const Protocol = styled.h2`
  ${title40GalanoBold}
  color: ${darkBlue};
  font-size: ${rem('32px')};
  max-width: ${rem('615px')};
  margin-top: ${rem('0')};
`

export const UpdateIn = styled.p`
  ${txt16OpenSans} color: ${darkBlue};
  opacity: 0.72;
  max-width: ${rem('615px')};
  margin-bottom: 40px;
`
