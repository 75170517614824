import React from 'react'
import { connect } from 'react-redux'

import { hideModal } from '../ModalRoot/actions'
import Modal from '../../elements/Modal'

const Confirmation = props => {
  const handleConfirm = () => {
    if (props.onConfirm) {
      props.onConfirm()
    }
    if (props.modalTitle === 'Erro ao ativar seu produto') window.dataLayer = window.dataLayer || []
    props.hideModal()
  }

  return (
    <Modal
      description={props.modalDescription}
      image={props.image}
      hideModal={() => {
        props.hideModal()
        if (props.modalTitle === 'Erro ao ativar seu produto') {
          window.dataLayer = window.dataLayer || []
          window.dataLayer.push({
            event: 'event',
            eventAction: 'tentativa:fechar',
            eventCategory: 'veloe:area-logado:ativar-identificador'
          })
        }
      }}
      onConfirm={() => handleConfirm()}
      title={props.modalTitle}
      accountType={props.accountType}
      confirmButtonLabel={props.confirmButtonLabel}
    />
  )
}

export default connect(null, { hideModal })(Confirmation)
