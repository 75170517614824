import styled from 'styled-components'

// import rem from '../../../styles/tools/rem'
import em from '../../../../../styles/tools/em'
import media from '../../../../../styles/tools/media'

import {
  txt12OpenSans,
  // txt16OpenSans,
  txt18OpenSans,
  txt14OpenSans,
  title36GalanoBold
  // title40GalanoBold
} from '../../../../../styles/settings/types'

import { blueGray, linkNavyBlue, darkBlue } from '../../../../../styles/settings/colors'

export const ListForm = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 40px 0 30px;

  ${media.small`
    flex-direction: column;
    margin-top: 20px;
  `};

  > div {
    max-width: 216px;
    margin-right: 25px;

    ${media.small`
      margin-bottom: 15px;
      margin-right: 0;
      max-width: 100%;
    `};
  }

  label {
    ${txt12OpenSans};
    font-weight: bold;
    letter-spacing: ${em('1.6px')};
    color: ${blueGray};
    text-transform: uppercase;
  }
`

export const Device = styled.div`
  display: flex;
  ${media.small`
    flex-direction: column;
  `};
`

export const DeviceContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 288px;
  min-width: 288px;
  flex: 1;
  height: 246px;
  border-radius: 6px;
  border: 2px solid #e4e3ed;
  overflow: hidden;
  margin-bottom: 30px;
  margin-right: 30px;
  position: relative;

  ${media.small`
    width: 100%;
    max-width: 345px;
    height: 237px;
  `};
`

export const DeviceContent = styled.div``

export const DeviceHeader = styled.div``

export const DeviceResume = styled.div`
  display: flex;
  flex-direction: column;
  padding: 30px 35px;
  position: relative;
`

export const DeviceMiniThumb = styled.div`
  /* width: 136px; */
  max-width: 193px;
  height: 152px;
  margin-bottom: 25px;
  display: flex;
  align-self: center;
  & > img {
    object-fit: contain;
    max-width: 100%;
  }
`

export const DeviceTitle = styled.div`
  ${title36GalanoBold};
  font-size: 22px;
  font-weight: 800;
  line-height: 1.44;
  margin-bottom: 5px;
  color: ${({ active }) => (active ? linkNavyBlue : darkBlue)};
`

export const DeviceDetailsTitle = styled.div`
  ${txt18OpenSans};
  /* font-size: 1.2rem; */
  line-height: 1.44;
  margin-bottom: 10px;
  font-weight: 700;
  color: #585380;
`

export const DeviceDescription = styled.div`
  ${txt14OpenSans};
  color: #585380;
  margin-bottom: 20px;
`

export const DeviceDetailsItem = styled.div`
  ${txt14OpenSans};
  color: #585380;
  margin-bottom: 10px;
  padding-left: 30px;
  &::before {
    content: '•';
    /* font-size: 0.2rem; */
    font-weight: 800;
    color: ${darkBlue};
    margin-right: 15px;
    margin-left: -20px;
  }
`

export const More = styled.a`
  cursor: pointer;
  ${txt14OpenSans};
  color: ${darkBlue};
  text-align: center;
  font-weight: 600;
  margin-top: 20px;
  text-decoration: underline;

  &:hover {
    opacity: 0.8;
  }
`

export const DeviceDetails = styled.div``
