import styled from 'styled-components'

import { darkBlue, orange, green, yellow, grey } from '../../../styles/settings/colors'

import rem from '../../../styles/tools/rem'

function getFilledColor(counter) {
  let color = grey

  switch (true) {
    case counter >= 8:
      color = green
      break
    case counter >= 3:
      color = yellow
      break
    case counter >= 1:
      color = orange
      break
    default:
      break
  }

  return color
}

export const Path = styled.path`
  fill: ${grey};

  ${props =>
    props.active &&
    `
        fill: ${getFilledColor(props.counter)};
    `};
`

export const Container = styled.div`
  align-items: center;
  display: flex;
`

export const Message = styled.span`
  color: ${darkBlue};
  display: inline-block;
  font-family: OpenSans;
  font-size: ${rem('12px')};
  letter-spacing: ${rem('-0.5px')};
  margin-left: ${rem('12px')};

  ${props =>
    props.counter < 8 &&
    `
        color: ${orange};
    `} ${props =>
    !props.counter &&
    props.counter !== 0 &&
    `
        color: ${orange};
        margin-left: 0;
    `};
`
