import styled from 'styled-components'
import { darkGrey, linkNavyBlue, offWhite } from 'styles/settings/colors'
import { title24GalanoBold, txt14OpenSans } from 'styles/settings/types'
import media from 'styles/tools/media'

export const Container = styled.section`
  background-color: ${offWhite};
  border-radius: 4.4px;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  width: 100%;

  ${media.small`
    padding: 20px;
  `};
  ${media.large`
    padding: 30px;
  `};
`

export const Title = styled.p`
  ${title24GalanoBold};
  color: ${linkNavyBlue};
  margin: 0;
`

export const Subtitle = styled.p`
  ${txt14OpenSans};
  color: ${darkGrey};
  ${media.large`
    max-width: 50%;
  `};
`
export const ButtonContainer = styled.div`
  margin-top: 30px;
  ${media.large`
    max-width: 33%;
  `};
`
