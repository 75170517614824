import { bacenAPI } from 'alelo-logic/services/rechargeService'
import { validateCreditCardFlag } from 'app/modules/EditPaymentForm/validators'

const DEFAULT_AGENCY_LENGTH = 5

export const BANKS = {
  BB: 'BB',
  BRADESCO: 'BRADESCO',
  ORIGINAL: 'ORIGINAL'
}

const BANKS_DIGIT_WEIGHTS = {
  BB: [2, 3, 4, 5, 6, 7, 8, 9, 2, 3, 4, 5, 6, 7, 8, 9],
  BRADESCO: [2, 3, 4, 5, 6, 7, 2, 3, 4, 5, 6, 7],
  ORIGINAL: [2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13]
}

export const checkCreditCardFlag = values => {
  const creditCardFlag = validateCreditCardFlag(values)
  if (creditCardFlag) {
    return creditCardFlag
  }
}

export function normalizeAgencyNumber(agencyNumber) {
  if (agencyNumber.length < DEFAULT_AGENCY_LENGTH) {
    const numberOfZeros = DEFAULT_AGENCY_LENGTH - agencyNumber.length
    let result = '0'.repeat(numberOfZeros).concat(agencyNumber)
    return result
  }
  return agencyNumber
}

export function sumDigits(value) {
  let sum = 0
  while (value) {
    sum += value % 10
    value = Math.floor(value / 10)
  }
  return sum
}

export function validateItauDVAccount(agencyAccount) {
  const digitsWeight = [2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1]

  if (agencyAccount.length > 16) {
    throw new Error('Número da Conta não suportada pela função de Cálculo de Dígito Verificador!')
  }

  let sumCalculation = 0
  let digitIndex = 0

  for (var index = agencyAccount.length - 1; index >= 0; index--) {
    let currentCalculation = parseInt(agencyAccount[index]) * digitsWeight[digitIndex]
    sumCalculation += currentCalculation > 9 ? sumDigits(currentCalculation) : currentCalculation

    digitIndex++
  }
  return sumCalculation % 10 === 0 ? 0 : 10 - (sumCalculation % 10)
}

export function validateDVAccount(account, bankName) {
  const digitsWeight = BANKS_DIGIT_WEIGHTS[bankName]

  if (!digitsWeight) {
    throw new Error('Banco inválido!')
  }

  if (account.length > 16) {
    throw new Error('Número da Conta não suportada pela função de Cálculo de Dígito Verificador!')
  }

  let sumCalculation = 0
  let digitIndex = 0

  for (var index = account.length - 1; index >= 0; index--) {
    sumCalculation += parseInt(account[index]) * digitsWeight[digitIndex]
    digitIndex++
  }
  let resultedDigit = (sumCalculation * 10) % 11

  return resultedDigit >= 10 ? 0 : resultedDigit
}

export async function validateBBAgency(agencyNumber) {
  const normalizedNumber = normalizeAgencyNumber(agencyNumber)
  const apiResult = await bacenAPI(normalizedNumber)
  return Boolean(
    apiResult.data.value.length && apiResult.data.value[0].CodigoCompe === normalizedNumber
  )
}
