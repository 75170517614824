import styled, { css } from 'styled-components'
import { linkNavyBlue, darkGrey } from 'styles/settings/colors'
import { txt12OpenSans } from 'styles/settings/types'
import rem from 'styles/tools/rem'

export const DropdownListItem = styled.li`
  text-decoration: none;
  list-style: none;
  cursor: pointer;
  ${({ secondary }) =>
    secondary
      ? css`
          padding: ${rem('10px')} ${rem('15px')};
        `
      : css`
          padding: ${rem('15px')} ${rem('20px')};
        `};
`

export const Title = styled.span`
  ${txt12OpenSans};
  color: ${({ isActived }) => (isActived ? linkNavyBlue : darkGrey)};
  display: block;
  line-height: 1;
  text-transform: ${({ withIcon }) => (withIcon ? `initial` : `capitalize`)};
  transition: color 0.2s;
  ${DropdownListItem}:hover & {
    color: ${linkNavyBlue};
  }
`

export const DropdownListContainer = styled.ul`
  background-color: white;
  border-radius: ${rem('6px')};
  box-shadow: 0 ${rem('8px')} ${rem('8px')} 0 rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  pointer-events: none;
  position: absolute;
  top: ${rem('50px')};
  z-index: 20;
  margin: 0;
  visibility: hidden;
  opacity: 0;
  transform: translateY(-2em);
  transition: all 0.2s ease-in 0s, visibility 0s linear 0.2s;
  ${({ secondary, withIcon }) =>
    withIcon
      ? css`
          right: 80%;
          left: initial;
          width: ${rem('256px')};
          padding: ${rem('8px')} 0;
        `
      : secondary
      ? css`
          right: 80%;
          left: initial;
          width: ${rem('200px')};
          padding: ${rem('8px')} 0;
        `
      : css`
          right: initial;
          left: 0;
          width: 100%;
          padding: ${rem('15px')} 0;
        `};
  ${({ isOpen }) =>
    isOpen &&
    css`
      pointer-events: all;
      visibility: visible;
      opacity: 1;
      transform: translateY(0%);
      transition-delay: 0s, 0s;
    `};
`
