function stepBoxReducer(state, action) {
  if (!state) {
    state = {
      stepsValidity: [],
      showErrors: true
    }
  }
  switch (action.type) {
    case 'SET_STEPBOX_ERRORS': {
      return {
        ...state,
        stepsValidity: action.stepsValidity
      }
    }
    case 'SHOW_STEPBOX_ERRORS': {
      return {
        ...state,
        showErrors: action.showErrors
      }
    }
    default:
      return state
  }
}

export default stepBoxReducer
