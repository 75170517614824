import types from '../actions/types'

const { UPDATE_PLAN_RESUME } = types

function dashboardResumeReducer(state = {}, action = {}) {
  switch (action.type) {
    case UPDATE_PLAN_RESUME: {
      return action.planResume
    }
    default:
      return state
  }
}

export default dashboardResumeReducer
