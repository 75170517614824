import styled from 'styled-components'

import { hexToRgba } from '../../../utils/hexToRgba'
import media from '../../../styles/tools/media'
import { title32GalanoBold } from '../../../styles/settings/types'
import { white, lightBlue, darkBlue } from '../../../styles/settings/colors'

export const Overlay = styled.div`
  background-color: ${hexToRgba(darkBlue, 0.64)};
  height: 100vh;
  width: 100vw;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 99999;
`

export const Modal = styled.section`
  background: ${lightBlue} url(${({ backgroundImage }) => backgroundImage})
    ${({ position }) => position || `right center`} no-repeat;
  border-radius: 12px;
  box-shadow: 0 12px 16px 0 rgba(42, 34, 100, 0.64);
  left: 50%;
  max-width: calc(100% - 40px);
  padding: 50px;
  position: fixed;
  top: 50%;
  transform: translate(-50%, -50%);

  ${media.small`
    background-image: none;
    border-radius: 0;
    left: 0;
    height: 100vh;
    min-width: calc(100vw - 48px);
    padding: 24px;
    top: 0;
    transform: none;
  `};

  h3 {
    ${title32GalanoBold};
    color: ${white};
    line-height: 1.12;
    margin: 0 0 16px;

    ${media.small`
      margin-bottom: 24px;
    `};
  }
`

export const Left = styled.div`
  max-width: 100%;
  width: 70%;
  height: 100%;

  ${({ backgroundImage }) =>
    backgroundImage &&
    `
      width: 328px;
  `};
`
