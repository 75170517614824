import { getLocations } from '../services/ecService'

import types from './types'

const { FETCH_EC_LOCATIONS } = types

function fetchECLocations() {
  return dispatch =>
    getLocations()
      .then(locations => {
        dispatch({
          type: FETCH_EC_LOCATIONS,
          state: locations
        })
        return locations
      })
      .catch(error => {
        console.log('Erro em #fetchGetLocations()')
        console.log(error)
      })
}

export default fetchECLocations
