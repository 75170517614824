import { getInvoiceDetails } from '../services/invoiceService'

import types from './types'

const { UPDATE_INVOICE_DETAILS, RESET_INVOICE_DETAILS } = types

export function fetchInvoiceDetails(planId, faturaId, qtdTransacoes) {
  return dispatch =>
    getInvoiceDetails(planId, faturaId, qtdTransacoes).then(details => {
      dispatch({
        type: UPDATE_INVOICE_DETAILS,
        details
      })
    })
}

export function resetInvoiceDetails() {
  return dispatch =>
    dispatch({
      type: RESET_INVOICE_DETAILS
    })
}
