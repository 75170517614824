import React from 'react'

const ArrowRight = props => (
  <svg width={props.width} height={props.height} viewBox={props.viewBox}>
    <defs>
      <path
        id="a"
        d="M4.463 9.401l5.763-5.527a1.369 1.369 0 0 1 1.886.005c.52.504.517 1.318-.005 1.818l-6.704 6.429c-.52.499-1.36.499-1.88 0l-6.63-6.358a1.253 1.253 0 0 1-.005-1.818 1.369 1.369 0 0 1 1.886-.005L4.464 9.4z"
      />
    </defs>
    <use fill="#292663" fillRule="nonzero" transform="matrix(0 1 1 0 .5 3.5)" xlinkHref="#a" />
  </svg>
)

export default ArrowRight
