/* eslint-disable */

import React from 'react'
import { string } from 'prop-types'

import { Icon } from '../Icon'

import { Box, CheckIcon, Input, Text, Label } from './styles'

function Checkbox(props) {
  const { label } = props
  return (
    <Label disabled={props.disabled}>
      <Input
        {...props}
        name={props.name}
        type="checkbox"
        disabled={props.disabled}
        negative={props.negative}
      />
      <Box negative={props.negative}>
        <CheckIcon>
          <Icon width="11" height="8" icon="check" viewBox="0 0 11 8" />
        </CheckIcon>
      </Box>
      {label && <Text>{label}</Text>}
    </Label>
  )
}

Checkbox.defaultProps = {
  label: null
}

Checkbox.propTypes = {
  label: string
}

export default Checkbox
