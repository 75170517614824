import TemplateRecharge from '../app/templates/TemplateRecharge'
import RechargePrices from '../app/pages/RechargePrices'
import RechargePayment from '../app/pages/RechargePayment'
import RechargeConfirmation from '../app/pages/RechargeConfirmation'

export default {
  path: '/minha-veloe/:id/recarga',
  component: TemplateRecharge,
  routes: [
    {
      path: '/minha-veloe/:id/recarga',
      exact: true,
      component: RechargePrices
    },
    {
      path: '/minha-veloe/:id/recarga/pagamento',
      exact: true,
      component: RechargePayment
    },
    {
      path: '/minha-veloe/:id/recarga/confirmacao',
      exact: true,
      component: RechargeConfirmation
    }
  ]
}
