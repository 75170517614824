import types from '../actions/types'

const { FETCH_TERMS_CONDITIONS } = types

function termsConditionReducer(state = {}, action = {}) {
  switch (action.type) {
    case FETCH_TERMS_CONDITIONS: {
      return {
        ...state,
        ...action.state
      }
    }
    default:
      return state
  }
}

export default termsConditionReducer
