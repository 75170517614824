import styled from 'styled-components'
import { darkGrey, linkNavyBlue, offWhite } from 'styles/settings/colors'
import { title24GalanoBold, txt14OpenSans } from 'styles/settings/types'
import media from 'styles/tools/media'

export const Container = styled.section`
  display: flex;
  flex-direction: row;
  border-radius: 4.4px;
  height: 100%;
  width: 100%;
  background-color: ${offWhite};

  b {
    color: ${linkNavyBlue};
  }

  .WarningImage-megaphone {
    position: absolute;
    right: 10px;
    bottom: 0;
  }

  .WarningImage-mobile {
    position: absolute;
    right: 54px;
    bottom: 124px;
  }

  .WarningImage-signal {
    position: absolute;
    right: 64px;
    bottom: 0;
  }
`

export const ContainerContent = styled.div`
  width: 351px;
  margin: 30px;

  ${media.small`
    margin: 20px;
  `};
`

export const Title = styled.p`
  ${title24GalanoBold};
  color: ${linkNavyBlue};
  margin: 0;
`

export const Body = styled.div`
  ${txt14OpenSans};
  margin-top: 24px;
  color: ${darkGrey};
`
