import styled from 'styled-components'

import media from '../../../styles/tools/media'
import rem from '../../../styles/tools/rem'

export const BarContainer = styled.div`
  height: ${rem('8px')};
  border-radius: 78px;
  overflow: hidden;
  display: flex;
  background-color: #f1f1f7;

  ${media.large`
        height: ${rem('16px')};
    `};
`

export const BarSlice = styled.div`
  height: 100%;
  width: ${props => props.width};
  ${({ color }) => `background-color: ${color};`};
`
