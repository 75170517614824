import React from 'react'
import { arrayOf, number, string } from 'prop-types'

import { BarContainer, BarSlice } from './styles'

const ConsumptionChartBar = ({ colors, values }) => (
  <BarContainer>
    {values.map((value, index) => (
      <BarSlice key={index} width={`${value}%`} color={colors[index]} />
    ))}
  </BarContainer>
)

ConsumptionChartBar.defaultProps = {
  colors: [],
  values: []
}

ConsumptionChartBar.propTypes = {
  colors: arrayOf(string),
  values: arrayOf(number)
}

export default ConsumptionChartBar
