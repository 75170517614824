import React from 'react'

import { Field, reduxForm } from 'redux-form'
import Radio from 'app/elements/Radio'

import { Wrapper } from './styles'

const renderRadio = ({ input, label, checked }) => (
  <Radio {...input} label={label} checked={checked} />
)

const validate = values => {
  const errors = {}
  if (!values.information) {
    errors.information = 'Campo Obrigatório'
  }
}

const ChooseInformationOption = props => {
  const { handleChangeInformation } = props
  return (
    <form>
      <Wrapper>
        <Field
          component={renderRadio}
          label="Informação 1"
          name="information"
          type="radio"
          value="INFORMACAO_1"
          onChange={() => {
            handleChangeInformation('INFORMACAO_1')
            window.dataLayer.push({
              event: 'event',
              eventAction: 'clique:INFORMACAO_1',
              eventCategory: 'veloe:area-logado:meus-produtos:adicionar-informacao',
              dimension23: 'INFORMACAO_1'
            })
          }}
        />
        <Field
          component={renderRadio}
          label="Informação 2"
          name="information"
          type="radio"
          value="INFORMACAO_2"
          onChange={() => {
            handleChangeInformation('INFORMACAO_2')
            window.dataLayer.push({
              event: 'event',
              eventAction: 'clique:INFORMACAO_2',
              eventCategory: 'veloe:area-logado:meus-produtos:adicionar-informacao',
              dimension23: 'INFORMACAO_2'
            })
          }}
        />
        <Field
          component={renderRadio}
          label="Informação 3"
          name="information"
          type="radio"
          value="INFORMACAO_3"
          onChange={() => {
            handleChangeInformation('INFORMACAO_3')
            window.dataLayer.push({
              event: 'event',
              eventAction: 'clique:INFORMACAO_3',
              eventCategory: 'veloe:area-logado:meus-produtos:adicionar-informacao',
              dimension23: 'INFORMACAO_3'
            })
          }}
        />
      </Wrapper>
    </form>
  )
}

export default reduxForm({
  form: 'ChoosemanageInfoForm',
  validate
})(ChooseInformationOption)
