import { getReasons } from '../services/dashboardService'

import types from './types'

const { UPDATE_REASONS } = types

export default function fetchReasons() {
  return dispatch =>
    getReasons().then(reasons => {
      dispatch({
        type: UPDATE_REASONS,
        reasons: reasons.data
      })
    })
}
