/* eslint-disable */
import { VALIDATION_CEP, VALIDATION_CEP_MSG_EMPTY, VALIDATION_CEP_MSG_INVALID } from './constants'

export default function validateCep(value) {
  return !value
    ? VALIDATION_CEP_MSG_EMPTY
    : !VALIDATION_CEP.test(value)
    ? VALIDATION_CEP_MSG_INVALID
    : undefined
}
