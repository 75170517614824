/* eslint-disable */
import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import IndentifiersRequestStatus from '../../modules/IdentifiersRequestStatus'
import Content from '../../../styles/objects/Content'
import ImersiveHeader from '../../elements/ImersiveHeader'
import { setLoading } from '../../modules/PageLoader/actions'
import { fetchFollowRequestDetails } from 'alelo-logic/actions'
import PageLoader from '../../modules/PageLoader'
import { renderNotArrivedHelper, renderActivateTagHelper } from './helpers'

import { Title, Paragraph, Wrapper, Ticket, WrapperOrder, WrapperHelpers } from './styles'

const { title, paragraph, ticket } = require('./data.json')

class FirstAccessFollowRequest extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      orders: null,
      type: 'pre'
    }
  }

  componentWillMount() {
    const accPlans = this.props.accountPlans
    const acc =
      accPlans && accPlans.plans && accPlans.plans.length > 0
        ? accPlans.plans.find(plan => plan.accountId == accPlans.activePlanId)
        : null

    const type = acc && acc.plan && acc.plan.type ? acc.plan.type : null

    this.setState({ type })
  }

  async componentDidMount() {
    // this.props.setLoading(true)
    const accountNumber = this.props.match.params.id
    try {
      const response = await fetchFollowRequestDetails(accountNumber, this.props.match.params.order)

      this.setState({ orders: response })
      // this.props.setLoading(false)
    } catch (error) {
      console.log(error)
    }
  }

  render() {
    const { orders, type } = this.state
    const {
      accountPlans: { activePlanId },
      // match: { url },
      history
      // loading
    } = this.props
    const urlBack = `/minha-veloe/${activePlanId}/identificadores/acompanhar-pedido`

    return (
      <Wrapper>
        <div>
          <ImersiveHeader text="Voltar" url={urlBack} />
        </div>
        <PageLoader>
          <Content>
            <Title>{title}</Title>
            <Paragraph>{paragraph}</Paragraph>
            {orders && (
              <WrapperOrder>
                <Ticket>
                  {ticket} {orders.ticket}
                </Ticket>
                <IndentifiersRequestStatus
                  payment={orders.payment}
                  tracker={orders.tracker}
                  history={history}
                  accountId={this.props.match.params.id}
                  type={type}
                />
                <WrapperHelpers>
                  {renderActivateTagHelper(orders, type, history)}
                  {renderNotArrivedHelper(orders, type, history)}
                </WrapperHelpers>
              </WrapperOrder>
            )}
          </Content>
        </PageLoader>
      </Wrapper>
    )
  }
}

const mapStateToProps = state => {
  return {
    accountPlans: state.reducer.accountPlans,
    loading: state.pageLoader.isLoading
  }
}

const mapDispatchToProps = dispatch => ({
  setLoading: isLoading => {
    dispatch(setLoading(isLoading))
  }
})

const routeredFirstAccessFollowRequest = withRouter(FirstAccessFollowRequest)

export default connect(mapStateToProps, mapDispatchToProps)(routeredFirstAccessFollowRequest)
