import React from 'react'
import { Box, Flex } from 'grid-styled'
import { invoiceMonthFormat, ISODateFormat, capitalize, currencyParser } from 'alelo-logic/utils'
import Hide from '../../../styles/utilities/Hide'
import InvoiceStatus from '../InvoiceStatus'
import { Content, LinkToInvoice, ListItem, Title } from './styles'

const InvoiceListItem = ({
  dataVencimento,
  mes,
  formaPagamento,
  linkBoleto,
  planId,
  status,
  valor,
  numero,
  nsu,
  requestNewBoleto,
  situacao
}) => {
  const returnFinalValue = value => {
    if (value < 0) return currencyParser(value * -1)
    return currencyParser(value)
  }

  return (
    <ListItem>
      <Content>
        <Flex width={1} direction={['column', 'row']}>
          <Box width={[1, 1 / 10]} style={{ display: 'flex', alignItems: 'stretch' }}>
            <LinkToInvoice
              to={`/minha-veloe/${planId}/fatura/${numero}`}
              style={{ display: 'flex', alignItems: 'center' }}
            >
              <Title status={status}>{capitalize(invoiceMonthFormat(mes)) || 'Atual'}</Title>
            </LinkToInvoice>
          </Box>
          <Hide
            at="small"
            width={[1, 2 / 6]}
            style={{ display: 'flex', alignItems: 'stretch', justifyContent: 'flex-end' }}
          >
            <LinkToInvoice
              to={`/minha-veloe/${planId}/fatura/${numero}`}
              style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
            >
              <Title left status={status}>
                {returnFinalValue(valor)}
              </Title>
            </LinkToInvoice>
          </Hide>
          <Hide at="small" width={[1, 1 / 10]} style={{ display: 'flex', alignItems: 'stretch' }}>
            <LinkToInvoice
              to={`/minha-veloe/${planId}/fatura/${numero}`}
              style={{ display: 'flex', alignItems: 'center' }}
            >
              <Title status={status}>{ISODateFormat(dataVencimento) || 'Em aberto'}</Title>
            </LinkToInvoice>
          </Hide>
          <Box width={[1, 2 / 6]}>
            <InvoiceStatus
              dataVencimento={dataVencimento}
              status={status}
              linkBoleto={linkBoleto}
              method={formaPagamento}
              fatura={numero}
              nsu={nsu}
              requestNewBoleto={requestNewBoleto}
              situacao={situacao}
            />
          </Box>
        </Flex>
      </Content>
    </ListItem>
  )
}

export default InvoiceListItem
