import {
  API,
  dynamicAPI,
  dynPublicAPI,
  USER_CANCEL,
  CREATE_CANCEL_TOKEN,
  VALIDATE_CANCEL_TOKEN,
  CANCEL_OPTIONS,
  // ACCESS_TOKEN,
  // LOGOUT,
  API_URL
} from './common/constants'

import fetchHandler from './common/fetchHandler'

const ecAccountPlans = [
  {
    _id: '5a15bab4fd64ee427df9ea26',
    id: '89392',
    active: 'Vigente',
    accountId: '89392',
    plan: {
      id: '89392',
      category: 'normal',
      type: 'ec'
    },
    accessionDate: '2017-10-05T05:00:00.000Z',
    isMonthlyPayment: true,
    paymentMethod: null,
    identifiersQtd: '',
    __v: 0,
    identifiersOrders: [
      {
        orderNumber: 13420,
        orderAmount: 12,
        orderTrackingUrl: '#',
        _id: '5a15bab4fd64ee427df9ea28'
      },
      {
        orderNumber: 13421,
        orderAmount: 8,
        orderTrackingUrl: '#',
        _id: '5a15bab4fd64ee427df9ea27'
      }
    ],
    identifiers: [],
    representatives: []
  }
]

export function doCancelAccount(data) {
  return fetchHandler(API + USER_CANCEL, {
    method: 'PUT',
    body: JSON.stringify(data)
  })
}

export function createCancelAccountToken() {
  return fetchHandler(API + CREATE_CANCEL_TOKEN, {
    method: 'POST'
  })
}

export function validateCancelAccountToken(token) {
  return fetchHandler(API + VALIDATE_CANCEL_TOKEN, {
    method: 'POST',
    body: JSON.stringify({ token })
  })
}

export function getCancelOptions() {
  return fetchHandler(API + CANCEL_OPTIONS, {
    method: 'GET'
  })
}

// export async function authenticate(login, password, responseCaptcha) {
//   // TODO Remover chamada fetchHandler quando terminar de migrar os serviços
//   const [middlewareResp] = await Promise.all([
//     fetchHandler(dynamicAPI + ACCESS_TOKEN, {
//       method: 'POST',
//       body: JSON.stringify({ login, password, responseCaptcha })
//     }),
//     accountService.authenticate(login, password)
//   ])
//
//   return middlewareResp
// }

// TODO Remover quando terminar de migrar os serviços
// export function logout() {
//   return fetchHandler(dynamicAPI + LOGOUT, {
//     method: 'DELETE'
//   })
// }

export function recover(login) {
  return fetchHandler(`${dynPublicAPI}/account/recover`, {
    method: 'POST',
    body: JSON.stringify({ login })
  })
}

export function newUserPassword(data) {
  return fetchHandler(`${dynamicAPI}/account/password`, {
    method: 'POST',
    body: JSON.stringify(data)
  })
}

export function verifyRecoverTokenStatus(token = '') {
  return fetchHandler(`${dynamicAPI}/account/recover/${token}/status`, {
    method: 'GET'
  })
}

// export function getAccountPlans(accountId) {
//   return accountService.getAccountPlans(accountId)
// }

export function getECAccountPlans(accountId) {
  return new Promise(resolve => {
    window.setTimeout(() => {
      resolve(ecAccountPlans)
    }, 1000)
  })
}

// export function getTermsAndConditions() {
//   return accountService.getTermsAndConditions()
// }

export function postCancellationRequest(accountId, planId, motive) {
  return fetchHandler(`${dynamicAPI}/accounts/${accountId}/plans/${planId}/cancellation-request`, {
    method: 'POST',
    body: JSON.stringify(motive)
  })
}

export function putCancellationRequest(accountId, planId, token) {
  return fetchHandler(`${dynamicAPI}/accounts/${accountId}/plans/${planId}/cancellation-request`, {
    method: 'PUT',
    body: JSON.stringify({ token })
  })
}

export function getTrackIdentifiers(accountNumber, type) {
  const url = `${API_URL}/acompanhar-pedido/${accountNumber}?type=${type}`
  return fetchHandler(url, {
    method: 'GET'
  })
}

// export function getTrackIdentifiersDetails(accountNumber, order) {
//   return accountService.getTrackIdentifiersDetails(accountNumber, order)
// }
//
// export function getOrders(accountNumber, type) {
//   return accountService.getOrders(accountNumber, type)
// }
