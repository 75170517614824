import styled from 'styled-components'
import { white, linkNavyBlue, skyBlue } from 'styles/settings/colors'
import { title24GalanoBold, txt14OpenSans } from 'styles/settings/types'
import rem from 'styles/tools/rem'

export const Container = styled.section`
  background-color: ${skyBlue};
  border-radius: 4.4px;
  display: flex;
  width: 100%;

  .WarningImage-phone {
    position: relative;
    height: 222px;
    top: 50px;
  }
`

export const ContainerContent = styled.section`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin: 35px 0;
  margin-right: 33px;
  max-width: 351px;
`

export const Title = styled.p`
  ${title24GalanoBold};
  color: ${linkNavyBlue};
  margin: 0;
`

export const Subtitle = styled.p`
  ${txt14OpenSans};
  color: ${white};
  text-align: right;
  padding-left: 50px;
  line-height: 21px;
  margin: 25px 0 29px;
  letter-spacing: ${rem('-0.5px')};
  max-width: 385px;
`
export const ButtonContainer = styled.div`
  a {
    img {
      max-width: 176px;
      max-height: 60px;
    }

    &:first-child {
      margin-right: 4px;
    }
  }
`
