import { ApolloClient } from 'apollo-client'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { HttpLink } from 'apollo-link-http'
import { onError } from 'apollo-link-error'
import { ApolloLink } from 'apollo-link'
import {
  GRAPHQL_URL,
  API_AUTHORIZATION,
  GRAPHQL_MAX_RETY,
  AUTH_URL,
  AJAX_MAX_RETRY
} from './constants'
import { getAccessToken, getRefreshToken, setAuthObj } from './storage'
import qs from 'qs'
import { post } from './axiosClient'

export const refreshToken = async (refreshToken = getRefreshToken(), retryCount = 1) => {
  try {
    const postData = qs.stringify({ grant_type: 'refresh_token', refresh_token: refreshToken })
    const cred = await post(AUTH_URL, postData, {
      preventToken: true,
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
    })
    setAuthObj(cred)
  } catch (e) {
    let statusCode = e.status
    if (e.networkError) {
      statusCode = e.networkError.statusCode
      // Erros 504 não são tratados pois podem gerar duplicidade de dados em algumas requisições
      if ([502].includes(statusCode)) {
        if (retryCount < AJAX_MAX_RETRY) {
          return refreshToken(refreshToken, retryCount + 1)
        }
      }
    }
    const err = new Error('Error refreshing token:' + e.message)
    err.status = statusCode
    if (err.status === 401) err.status = 403 // FIXME: o tratamento na UI é feito apenas para o erro 403
    throw err
  }
}

const apolloClient = new ApolloClient({
  link: ApolloLink.from([
    onError(({ graphQLErrors, networkError }) => {
      if (graphQLErrors)
        graphQLErrors.map(({ message, locations, path }) =>
          console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`)
        )
      if (networkError) console.log(`[Network error]: ${networkError}`)
    }),
    new HttpLink({
      uri: GRAPHQL_URL,
      credentials: 'same-origin'
    })
  ]),
  cache: new InMemoryCache()
})

apolloClient.defaultOptions = {
  query: {
    fetchPolicy: 'network-only'
  }
}

const handleNetworkErros = async (e, retryCount = 1, retryFn) => {
  let statusCode = -1
  if (e.networkError) {
    statusCode = e.networkError.statusCode
    // Erros 504 não são tratados pois podem gerar duplicidade de dados em algumas requisições
    if ([502].includes(statusCode)) {
      if (retryCount < GRAPHQL_MAX_RETY) {
        return retryFn(retryCount + 1)
      }
    } else if (statusCode === 403) {
      await refreshToken()
      return retryFn(1)
    }
  }
  e.status = statusCode
  throw e
}

export const gqlQuery = async (query, variables, retryCount = 1) => {
  // TODO tratar expiração de token e outros erros
  const token = getAccessToken()
  try {
    return await apolloClient.query({
      query,
      variables,
      context: {
        headers: {
          Authorization: API_AUTHORIZATION,
          ...(token && { token })
        }
      }
    })
  } catch (e) {
    return handleNetworkErros(e, retryCount, retry => gqlQuery(query, variables, retry))
  }
}

export const gqlMutate = async (mutation, variables, retryCount = 1) => {
  // TODO tratar expiração de token e outros erros
  try {
    return await apolloClient.mutate({
      mutation,
      variables,
      context: {
        headers: {
          Authorization: API_AUTHORIZATION,
          token: getAccessToken()
        }
      }
    })
  } catch (e) {
    return handleNetworkErros(e, retryCount, retry => gqlMutate(mutation, variables, retry))
  }
}

export default apolloClient
