import { updateUserContact } from '../services/userService'

import types from './types'

const { FETCH_CONTACT_UPDATE } = types

function fetchUpdateContact(data) {
  return async dispatch => {
    const response = await updateUserContact(data)
    dispatch({
      type: FETCH_CONTACT_UPDATE,
      state: response
    })
    return response
  }
}

export default fetchUpdateContact
