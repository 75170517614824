import valid from 'card-validator'
// import elo from 'creditcards-types/types/elo'
import { binAlelo, binElo } from './bins'

export const cvcRequired = value => (value ? undefined : 'Digite o código.')
export const validateCreditCard = value => {
  const numberValidation = valid.number(value)
  return numberValidation.isPotentiallyValid ? undefined : 'Cartão inválido.'
}

const isVisa = number => {
  const specificVisaBins = ['589916', '406655']
  if (number) {
    return specificVisaBins.includes(
      number
        .match(/\d+/g)
        .join([])
        .slice(0, 6)
    )
  }
  return false
}

export const validateCreditCardFlag = value => {
  const newValue = value || ''
  const number = newValue.replace(/[^\d]/g, '')
  const numberValidation = valid.number(number)
  // Elo is outside because function valid not returns all elo bins
  if (isElo(number)) {
    return 'elo'
  }
  if (numberValidation.isPotentiallyValid) {
    if (isVisa(number)) {
      return 'visa'
    }
    if (isAlelo(number)) {
      return 'alelo'
    }

    if (
      (numberValidation.card && numberValidation.card.type === 'mastercard') ||
      (numberValidation.card && numberValidation.card.type === 'master-card')
    ) {
      return 'master'
    }
    if (numberValidation.card && numberValidation.card.type === 'american-express') {
      return 'amex'
    }
    if (numberValidation.card && numberValidation.card.type) {
      return numberValidation.card.type
    }
  } else return undefined
}

export const isElo = cardNum => {
  if (!cardNum) {
    return undefined
  }
  const bin = cardNum.replace(/[\s+|\.|\-]/g, '').slice(0,6) // eslint-disable-line
  const hasBin = binElo.indexOf(bin) !== -1
  return hasBin
}

export const isAlelo = numbers => {
  const stripedNumbers = numbers.replace(' ', '')
  const bin = stripedNumbers.slice(0, 6)
  return binAlelo.indexOf(bin) !== -1
}
