import styled from 'styled-components'
import { title24GalanoBold, txt14OpenSans, txt24OpenSansBold } from 'styles/settings/types'
import { linkNavyBlue, offWhite, darkGrey } from 'styles/settings/colors'
import rem from 'styles/tools/rem'
import media from 'styles/tools/media'
import container from 'styles/_objects/container'

export const HideOnSmall = styled.div`
  ${media.small`
      display: none;
  `};
`

export const HideOnLarge = styled.div`
  ${media.large`
      display: none;
  `};
`

export const Container = styled.div`
  width: 100%;
  ${container};
`

export const RowContainer = styled(Container)`
  display: flex;
  flex-direction: row;
`

export const Main = styled.main`
  display: block;
`

export const SubHeader = styled.div`
  margin: 40px 0px;
  ${media.small`
      padding: 0px ${rem('16px')};
  `};
`

export const Title = styled.h1`
  ${title24GalanoBold} margin: 0px;
  color: ${linkNavyBlue};
`

export const Subtitle = styled.p`
  ${txt14OpenSans} margin: 0px;
  color: ${linkNavyBlue};
`

export const BaseFormContent = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;

  form {
    width: 100%;
    ${container};
  }
`

export const FormSimpleContent = styled(BaseFormContent)`
  border-radius: 4.4px;
  border: solid 1px #c5c3d8;
  padding: 20px;
  height: 100%;
`

export const FormContent = styled(BaseFormContent)`
  background-color: ${offWhite};
  ${media.small`
    padding: ${rem('16px')};
  `};
  ${media.large`
    padding: ${rem('50px')} ${rem('65px')};
  `};
`

export const QueryButtonContainer = styled.div`
  ${media.large`
    margin: 17px 0px 0px 0px;
  `};
  ${media.small`
    width: 50%;
    margin: auto;
  `};
`

export const SimpleFormQueryButtonContainer = styled(QueryButtonContainer)`
  width: 100%;

  div {
    width: 100%;
  }
`

export const TableTitle = styled.p`
  ${txt14OpenSans} margin: 40px 0px 24px;
  color: ${linkNavyBlue};
`

export const RequestFileFormTitle = styled.p`
  ${txt24OpenSansBold};
  color: ${linkNavyBlue};
  margin: 0;
`

export const RequestFileFormSubtitle = styled.p`
  ${txt14OpenSans};
  color: ${darkGrey};
  margin: 0 0 28px 0;
`
