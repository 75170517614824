import React from 'react'
import { Flex, Box } from 'grid-styled'
import { Field, reduxForm } from 'redux-form'
import { utils } from 'alelo-logic'
import TextInput from 'app/elements/TextInput'
import Button from 'app/elements/Button'
import rem from 'styles/tools/rem'
// Assets
import {
  WrapperButton,
  WrapperContentModal,
  Header,
  Form
} from 'app/modules/ManageInfoModal/styles'
import { Chip, ChipsContainer, MsgError } from './styles'

const validate = values => {
  const errors = {}
  if (!values.description) {
    errors.description = 'Campo Obrigatório'
  }

  if (!values.data) {
    errors.data = 'Campo Obrigatório'
  }

  if (!values.hora) {
    errors.hora = 'Campo Obrigatório'
  }
}

const renderTextInput = ({ input, meta: { touched, error }, ...props }) => {
  return <TextInput type="text" {...input} {...props} errorMessage={touched && error} />
}

function FormEditManageInfo(props) {
  const {
    handleSubmit,
    formTitle,
    saving,
    isLoading,
    top5List,
    onSubmit,
    loading,
    msgError,
    dateTimeDisabled,
    valid
  } = props

  return (
    <WrapperContentModal>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Header>{formTitle}</Header>
        <Flex>
          <Box width={[1]}>
            <Field
              name="descricao"
              lengthCounter
              placeholder="Digite Aqui"
              maxlength="20"
              component={renderTextInput}
              label="Nome da Informação"
              validate={utils.emptyFieldValidate}
              isRequired
              onFocus={() => {
                window.dataLayer.push({
                  event: 'event',
                  eventAction: 'preencher:nome-da-informacao',
                  eventCategory: 'veloe:area-logado:meus-produtos:adicionar-informacao'
                })
              }}
            />
          </Box>
        </Flex>
        <Flex>
          <Box width={[5 / 12]}>
            <Field
              name="data"
              placeholder="15/02/2019"
              component={renderTextInput}
              label="data"
              validate={utils.dateValidateWithFormat}
              normalize={utils.dateMask}
              disabled={dateTimeDisabled}
              isRequired
              onFocus={() => {
                window.dataLayer.push({
                  event: 'event',
                  eventAction: 'preencher:data',
                  eventCategory: 'veloe:area-logado:meus-produtos:adicionar-informacao'
                })
              }}
            />
          </Box>
          <Box width={[2 / 12]} />
          <Box width={[5 / 12]} mb={rem('20px')}>
            <Field
              name="hora"
              placeholder="10:40"
              component={renderTextInput}
              label="hora"
              validate={utils.hourValidate}
              normalize={utils.hourMask}
              disabled={dateTimeDisabled}
              isRequired
              onFocus={() => {
                window.dataLayer.push({
                  event: 'event',
                  eventAction: 'preencher:hora',
                  eventCategory: 'veloe:area-logado:meus-produtos:adicionar-informacao'
                })
              }}
            />
          </Box>
        </Flex>
        <Header>Informações Recentes</Header>
        <ChipsContainer>
          {top5List.map((item, i) => (
            <Chip
              key={i}
              onClick={() => {
                props.change('descricao', item.descricaoAtual)
                window.dataLayer.push({
                  event: 'event',
                  eventAction: 'clique:informacoes-recentes',
                  eventCategory: 'veloe:area-logado:meus-produtos:adicionar-informacao',
                  eventLabel: item.descricaoAtual
                })
              }}
            >
              {item.descricaoAtual}
            </Chip>
          ))}
        </ChipsContainer>
        <WrapperButton
          onClick={() => {
            window.dataLayer.push({
              event: 'event',
              eventAction: 'clique:ok',
              eventCategory: 'veloe:area-logado:meus-produtos:adicionar-informacao'
            })
          }}
        >
          <Button
            text="OK"
            buttonType="darkBlue"
            type="submit"
            loading={loading}
            disabled={!valid || saving || isLoading || loading}
          />
        </WrapperButton>
      </Form>
      {msgError && msgError.length > 0 && <MsgError>{msgError}</MsgError>}
    </WrapperContentModal>
  )
}

export default reduxForm({
  form: 'manageInfoForm',
  validate
})(FormEditManageInfo)
