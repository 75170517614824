const passwordFactory = {
  toAPI: (data, token) =>
    data &&
    token && {
      token,
      password: data.newPassword
    }
}

export default passwordFactory
