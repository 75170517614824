import TemplateEmpty from '../app/templates/TemplateEmpty'
import CancelPlan from '../app/pages/CancelPlan'
import CancelPlanMotive from '../app/pages/CancelPlanMotive'
import CancelPlanConfirmation from '../app/pages/CancelPlanConfirmation'

export default {
  path: '/minha-veloe/:id/cancelar-plano',
  component: TemplateEmpty,
  routes: [
    {
      path: '/minha-veloe/:id/cancelar-plano',
      exact: true,
      component: CancelPlan
    },
    {
      path: '/minha-veloe/:id/cancelar-plano/motivo',
      exact: true,
      component: CancelPlanMotive
    },
    {
      path: '/minha-veloe/:id/cancelar-plano/confirmacao',
      exact: true,
      component: CancelPlanConfirmation
    }
  ]
}
