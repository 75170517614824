import React from 'react'
// Assets
import searchImage from '../../../static/ic-search.png'
// Components(styles)
import { Form, SearchField, SearchButton, SearchImg } from './styles'
// Util
import { removeSpecialChars } from '../../../utils/textSlug'

export const searchFilter = (searchValue, list) => {
  if (searchValue && searchValue.length > 2) {
    return list.filter(({ codIdentificador, veiculo, informacoesGerenciais }) => {
      const onlyChars = removeSpecialChars(searchValue)
      return (
        codIdentificador.includes(onlyChars.toUpperCase()) ||
        veiculo.placa.includes(onlyChars.toUpperCase()) ||
        (informacoesGerenciais &&
          informacoesGerenciais
            .map(inf => ({ ...inf, descricaoAtual: removeSpecialChars(inf.descricaoAtual) }))
            .some(inf => inf.descricaoAtual.includes(onlyChars)))
      )
    })
  }
  return list
}

export const SearchForm = props => (
  <Form>
    <SearchField
      type="text"
      name="search"
      {...props}
      onFocus={() => {
        window.dataLayer.push({
          event: 'event',
          eventAction: 'busca:filtrar-produtos',
          eventCategory: 'veloe:area-logado:meus-produtos',
          eventLabel: target.value
        })
      }}
    />
    <SearchButton>
      <SearchImg src={searchImage} alt="Search Image" />
    </SearchButton>
  </Form>
)

export default SearchForm
