import React from 'react'
import { utils } from 'alelo-logic'
import { renderTextInput, renderSelectField } from 'utils/renderInputs'
import { Field } from 'redux-form'

const { labels } = require('./data.json')

const AxisField = ({ axis }) => (
  <Field
    placeholder=" "
    component={renderSelectField}
    label={labels.axis}
    name="axis"
    options={axis}
    validate={utils.emptyFieldValidate}
    onSelected={(value) => {
      window.dataLayer = window.dataLayer || []
      window.dataLayer.push({
        event: 'event',
        eventAction: 'preencher:eixos',
        eventCategory: 'veloe:area-logado:ativar-identificador',
        eventLabel: value.toLowerCase(),
        dimension20: value.toLowerCase()
      })
    }}
  />
)

const PlateField = () => (
  <Field
    component={renderTextInput}
    label={labels.plate}
    name="plate"
    type="text"
    validate={utils.plateValidate}
    normalize={utils.plateMask}
    isRequired
    autoComplete="off"
    tooltip="O preenchimento deve seguir o padrão atual ABC1234 ou padrão placa Mercosul ABC1D23"
    onFocus={() => {
      window.dataLayer.push({
        event: 'event',
        eventAction: 'preencher:placa',
        eventCategory: 'veloe:area-logado:ativar-identificador',
        eventLabel: 'placa'
      })
    }}
  />
)

const IdentifierField = ({ selected }) => (
  <Field
    component={renderTextInput}
    label={selected === 'moto' ? labels.identifier.moto : labels.identifier.default}
    name="identifier"
    type="text"
    validate={selected === 'moto' ? utils.identifierBandValidate : utils.identifierValidate}
    isRequired
  />
)

const NickNameField = ({ isRequired = false }) => (
  <Field
    component={renderTextInput}
    label={labels.nickname}
    name="nickname"
    type="text"
    validate={utils.identifierNickname}
    isRequired={isRequired}
    onFocus={() => {
      window.dataLayer.push({
        event: 'event',
        eventAction: 'preencher:apelido',
        eventCategory: 'veloe:area-logado:ativar-identificador',
        eventLabel: 'apelido'
      })
    }}
  />
)

export { AxisField, PlateField, IdentifierField, NickNameField }
