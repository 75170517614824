import React from 'react'
import Helmet from 'react-helmet'
import { connect } from 'react-redux'
import { fetchUpdatePassword } from 'alelo-logic/actions'
import { reset } from 'redux-form'
import ChangePasswordForm from '../../modules/ChangePasswordForm'
import { showModal } from '../../modules/ModalRoot/actions'
import { CONFIRM_UPDATE } from '../../../constants/ModalTypes'
import passwordFactory from './factory'

import { Content, Heading, Paragraph, Wrapper } from './styles'

import image from '../../../static/alterarSenha.png'
import imageError from '../../../static/contestacao.png'

const { helmetTitle, title, subtitle, modalTitle } = require('./data.json')

function ChangePassword({ handleSave }) {
  return (
    <Content>
      <Helmet title={helmetTitle} />
      <Heading>{title}</Heading>
      <Paragraph>{subtitle}</Paragraph>
      <Wrapper>
        <ChangePasswordForm
          hasCurrentPasswordField
          onSubmit={values => handleSave(passwordFactory.toAPI(values))}
        />
      </Wrapper>
    </Content>
  )
}

const mapDispatchToProps = dispatch => ({
  handleSave: password =>
    dispatch(fetchUpdatePassword(password))
      .then(() => {
        dispatch(showModal(CONFIRM_UPDATE, { modalTitle, image }))
        dispatch(reset('changeUserPasswordData'))
      })
      .catch(() =>
        dispatch(
          showModal(CONFIRM_UPDATE, {
            modalTitle: 'Ocorreu algum problema!',
            modalDescription: 'Tente novamente mais tarde.',
            image: imageError
          })
        )
      )
})

export default connect(null, mapDispatchToProps)(ChangePassword)
