import { gqlQuery, gqlMutate } from './common/apolloClient'
import {
  gqlBuscarInfoGerencialAtiva,
  gqlBuscarInfoGerencialHistorico,
  gqlBuscarInfoGerencialVigente,
  gqlBuscarInfoGerencialTop5,
  gqlCriarInfoGerencial,
  gqlAlterarInfoGerencial,
  gqlExcluirInfoGerencial
} from './queries/informacao-gerencial'

export async function getManageInfs(accountId, plate) {
  const payload = {
    idConta: accountId,
    placa: plate
  }
  const response = await gqlQuery(gqlBuscarInfoGerencialVigente, payload)
  return response.data.buscarInfoGerencialVigente
}

export async function getManageInfsActive(accountId, plate) {
  const payload = {
    idConta: accountId,
    placa: plate
  }
  const response = await gqlQuery(gqlBuscarInfoGerencialAtiva, payload)
  return response.data.buscarInfoGerencialAtiva
}

export async function getManageInfHistory(accountId, plate) {
  const payload = {
    idConta: accountId,
    placa: plate
  }
  const response = await gqlQuery(gqlBuscarInfoGerencialHistorico, payload)
  return response.data.buscarInfoGerencialHistorico
}

export async function getManageInfTop5(accountId, plate) {
  const payload = {
    idConta: accountId,
    placa: plate
  }
  const response = await gqlQuery(gqlBuscarInfoGerencialTop5, payload)
  return response.data.buscarInfoGerencialTop5
}

export async function createManageInfo({ data }) {
  const payload = {
    identificador: data.codIdentificador,
    conta: data.conta,
    data: data.dataInicioVigencia,
    descricao: data.descricao,
    placa: data.placa,
    tipo: data.tipoInfo
  }
  const response = await gqlMutate(gqlCriarInfoGerencial, payload)
  return response
}

export async function updateManageInfo({ data }) {
  const payload = {
    identificador: data.codIdentificador,
    conta: data.conta,
    data: data.dataInicioVigencia,
    descricao: data.descricao,
    placa: data.placa,
    tipo: data.tipoInfo
  }
  const response = await gqlMutate(gqlAlterarInfoGerencial, payload)
  return response
}

export async function deleteManageInfo(data) {
  const payload = {
    identificador: data.codIdentificador,
    conta: data.conta,
    data: data.dataInicioVigencia,
    descricao: data.descricao,
    placa: data.placa,
    tipo: data.tipoInfo
  }
  const response = await gqlMutate(gqlExcluirInfoGerencial, payload)
  return response
}
