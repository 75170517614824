import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Flex, Box } from 'grid-styled'

import { fetchCreateRecharge } from 'alelo-logic/actions'
import { converText } from '../../../utils/textSlug'

import Button from '../../elements/Button'
import RechargeBreadcrumbs from '../../modules/RechargeBreadcrumbs'
import Hide from '../../../styles/utilities/Hide'
import rem from '../../../styles/tools/rem'

import image from '../../../static/recarga.png'
import { showModal } from '../../modules/ModalRoot/actions'
import imageContestacao from '../../../static/contestacao.png'
import { CONFIRM_UPDATE } from '../../../constants/ModalTypes'

import { Title, Text, TextSmall } from './styles'

const {
  title,
  titleModalError,
  descriptionModalError,
  buttonLabel,
  button,
  awaitUpdate
} = require('./data.json')

class EditPaymentSuccess extends Component {
  state = {
    loading: false
  }

  onConfirm = () => {
    this.setState({ loading: true })
    const {
      recharge: { currentRechargeValue, paymentInfo },
      history,
      match,
      dispatch,
      dashboardResume,
      actualPlan
    } = this.props

    const paymentType = actualPlan.paymentType === 'CARTAO_DEBITO' ? 'debitCard' : 'credit'

    dispatch(
      fetchCreateRecharge({
        accountPlanId: match.params.id,
        value: currentRechargeValue,
        paymentMethod: paymentType,
        type: 'manual'
      })
    )
      .then(() => {
        window.dataLayer.push({
          event: 'purchase',
          eventCategory: 'veloe:area-logado:recarga-manual',
          eventAction: 'sucesso:recarga',
          eventLabel: 'purchase',
          dimension9: 'recarga',
          ecommerce: {
            purchase: {
              actionField: {
                id: paymentInfo.id,
                revenue: currentRechargeValue.toFixed(2)
              },
              products: [
                {
                  name: converText(`plano-${actualPlan.plan.category}`),
                  id: converText(`plano-${actualPlan.plan.category}-${currentRechargeValue}`),
                  price: currentRechargeValue.toFixed(2),
                  category: dashboardResume.type,
                  quantity: '1'
                }
              ]
            }
          }
        })
        this.setState({ loading: false })
        history.push(`/minha-veloe/${match.params.id}/recarga/confirmacao`)
      })
      .catch(e => {
        this.setState({ loading: false })
        console.error(e) // eslint-disable-line
        dispatch(
          showModal(CONFIRM_UPDATE, {
            modalTitle: titleModalError,
            modalDescription: descriptionModalError,
            image: imageContestacao
          })
        )
      })
  }

  minor = () => {
    const { actualPlan } = this.props
    return parseFloat(actualPlan.ammount) < parseFloat(actualPlan.plan.rechargeAmount)
  }

  onBack = () => {
    const {
      location: { search }
    } = this.props

    if (search.includes('path=recharge')) {
      if (this.minor()) {
        this.props.history.push(`/minha-veloe/${this.props.match.params.id}/resumo`)
      } else {
        this.props.history.push(
          `/minha-veloe/${this.props.match.params.id}/recarga/confirmacao?path=recharge`
        )
      }
    } else {
      this.props.history.push(`/minha-veloe/${this.props.match.params.id}/dados-do-plano`)
    }
  }

  onStatement = () => {
    this.props.history.push(`/minha-veloe/${this.props.match.params.id}/historico-de-recarga`)
  }

  render() {
    const {
      location: { search }
    } = this.props
    const { loading } = this.state
    return (
      <section>
        <RechargeBreadcrumbs active={4} />
        <Flex direction={['column', 'row']}>
          <Box
            mr={[0, `${(1 / 12) * 100}%`]}
            mt={[rem('35px'), rem('60px')]}
            mb={rem('20px')}
            width={[1, 7 / 12]}
          >
            <Title>{title}</Title>

            {this.minor() ? (
              <div>
                <Text>{search.split('msgrecarga=')[1]}</Text>

                <TextSmall>{awaitUpdate}</TextSmall>

                <Flex direction={['column', 'row']} mt={[rem('30px'), rem('30px')]}>
                  <Box mb={[rem('16px'), 0]} mr={[0, rem('10px')]} width={[1, 1 / 2]}>
                    <Button
                      id="btnOk"
                      buttonType="white"
                      onClick={this.onBack}
                      width="50%"
                      text="Voltar"
                    />
                  </Box>
                  <Box ml={[0, rem('10px')]} width={[1, 1 / 2]}>
                    <Button
                      id="btnNewRecharge"
                      buttonType="transparent"
                      onClick={this.onStatement}
                      width="100%"
                      text="Consultar suas recargas"
                    />
                  </Box>
                </Flex>
              </div>
            ) : (
              <div>
                <Text>Confirmar sua recarga escolhida ?</Text>

                <Flex direction={['column', 'row']} mt={[rem('30px'), rem('30px')]}>
                  <Box mb={[rem('16px'), 0]} mr={[0, rem('10px')]} width={[1, 1 / 2]}>
                    <Button
                      id="btnOk"
                      buttonType="white"
                      onClick={this.onBack}
                      width="50%"
                      text={button}
                    />
                  </Box>
                  <Box ml={[0, rem('10px')]} width={[1, 1 / 2]}>
                    <Button
                      id="btnNewRecharge"
                      buttonType="transparent"
                      onClick={this.onConfirm}
                      width="100%"
                      text={loading ? 'Carregando' : buttonLabel}
                      disabled={loading}
                      loading={loading}
                    />
                  </Box>
                </Flex>
              </div>
            )}
          </Box>
          <Hide at="small">
            <img alt="recarga" src={image} />
          </Hide>
        </Flex>
      </section>
    )
  }
}

const mapStateToProps = ({ reducer }) => ({
  recharge: reducer.recharge,
  dashboardResume: reducer.dashboardResume,
  actualPlan:
    reducer.accountPlans.plans.find(plan => plan.accountId === reducer.accountPlans.activePlanId) ||
    {}
})

export default connect(mapStateToProps)(EditPaymentSuccess)
