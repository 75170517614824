import { authenticate, logout } from '../services/accountService'
import Storage from '../services/common/storages/tokenStorage'

import types from './types'

const { SET_AUTHENTICATION, LOGOUT } = types

const storage = new Storage()

export function fetchAuthentication(login, password, responseCaptcha) {
  return dispatch =>
    authenticate(login, password, responseCaptcha).then(credentials => {
      storage.setAccount(credentials)
      storage.setSessionId(credentials.sessionId)

      dispatch({
        type: SET_AUTHENTICATION,
        credentials
      })

      return credentials
    })
}

export function setToken(credentials) {
  return dispatch => {
    storage.setAccount(credentials)

    return dispatch({
      type: SET_AUTHENTICATION,
      credentials
    })
  }
}

export function fetchLogout() {
  return dispatch =>
    logout().then(() => {
      dispatch({
        type: LOGOUT
      })

      return true
    })
}
