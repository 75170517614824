import styled from 'styled-components'

import { linkNavyBlue, darkGrey } from '../../../styles/settings/colors'
import {
  title24GalanoBold,
  title40GalanoBold,
  txt12OpenSansLight
} from '../../../styles/settings/types'
import media from '../../../styles/tools/media'
import rem from '../../../styles/tools/rem'

export const Title = styled.p`
  ${title24GalanoBold};

  color: ${linkNavyBlue};
  font-size: ${rem('30px')}!important;
  margin: 0;
  text-align: right;

  ${media.large`
        ${title40GalanoBold};
        margin-top: ${rem('20px')};
        text-align: left;
    `};

  @media print {
    font-size: ${rem('22px')}!important;
    margin-top: ${rem('20px')};
    text-align: left;
  }
`

export const Text = styled.p`
  ${txt12OpenSansLight};

  color: ${darkGrey};
  margin: 0;
  position: relative;
  text-transform: uppercase;
  top: ${rem('3px')};
`

export const Value = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: start;
  padding-top: ${rem('20px')};

  ${Title} {
    ${media.large`
            margin-top: 0;
        `};

    @media print {
      margin-top: 0 !important;
    }
  }
`

export const Currency = styled.p`
  ${title24GalanoBold};

  color: ${linkNavyBlue};
  font-size: ${rem('15px')};
  margin: 0 ${rem('10px')} 0 0;
`

export const Payment = styled.p`
  ${txt12OpenSansLight};

  color: ${darkGrey};
  margin: 0;
`
