import React, { useEffect } from 'react'
import { arrayOf, func, number, oneOfType, string } from 'prop-types'

import { Item, List, Text } from './styles'

import Slider from './slider'

export const ValuePicker = props => {
  let element = null
  let slider = null

  useEffect(() => {
    slider = new Slider({
      el: element,
      startIndex: props.startIndex,
      onSlide: props.onChange
    })

    return () => {
      slider?.destroy()
    }
  }, [])

  const { values, id } = props
  console.dir(props)
  return (
    <div>
      <List
        id={id}
        innerRef={el => {
          element = el
        }}
      >
        {values.map((value, index) => (
          <Item key={index}>
            <Text>{value}</Text>
          </Item>
        ))}
      </List>
    </div>
  )
}

ValuePicker.defaultProps = {
  startIndex: 2,
  onChange: null
}

ValuePicker.propTypes = {
  values: arrayOf(oneOfType([number, string])).isRequired,
  startIndex: number,
  onChange: func
}

export default ValuePicker
