import React from 'react'
import { Box, Flex } from 'grid-styled'
import { utils } from 'alelo-logic'
import InvoiceDetailsListItems from '../InvoiceDetailsListItems'

import { Content, GroupHeader, GroupAmountSpan, GroupSpan, GroupSpanCenter } from './styles'

const {
  benefits,
  cat,
  currency,
  date,
  description,
  entExt,
  hour,
  payable,
  parking,
  place,
  receive,
  stay,
  toll,
  // valuesIn,
  veloeServices
} = require('./data.json')

// function calcAmount(data) {
//   let amount = 0
//   data.forEach(el => {
//     amount += el.value
//   })
//   return utils.currencyParser(amount)
// }

function renderHeaderCat(typeName) {
  if (typeName.tipoTransacao === toll) return cat
  if (typeName.tipoTransacao === parking) return stay
  return ''
}

function renderHeaderPlace(typeName) {
  return typeName.tipoTransacao === veloeServices || typeName.tipoTransacao === benefits
    ? description
    : place
}

function renderHeaderHour(typeName) {
  return typeName.tipoTransacao === parking ? entExt : hour
}

function renderHeaderPayable(typeName, amountType) {
  if (typeName.tipoTransacao === benefits) return `${receive} (${currency}) + ${amountType}`
  // if (typeName.tipoTransacao === veloeServices) return `${valuesIn} (${currency})`
  return `${payable} (${currency}) ${amountType}`
}

function renderGroups(data, loadingItem) {
  // const groupedData = utils.groupArray(data || [], item => item.type.caption)
  // const keys = Object.keys(groupedData)
  return data.map((key, i) => {
    const amountType = utils.currencyParser(key.total)
    return (
      <div key={i}>
        {loadingItem && parseInt(loadingItem) === i ? (
          <div>
            <GroupHeader>
              <Content>
                <Flex align="center" justify="space-between">
                  <Box width={[1, 2 / 12]}>
                    <GroupSpan>{key.tipoTransacao}</GroupSpan>
                  </Box>
                  <Box pl={[0, 20]} width={[1, 1 / 12]}>
                    <GroupSpan>{date}</GroupSpan>
                  </Box>
                  <Box pl={[0, 20]} width={[1, 2 / 12]}>
                    <GroupSpanCenter>{renderHeaderHour(key)}</GroupSpanCenter>
                  </Box>
                  <Box pl={[0, 20]} width={[1, 1 / 12]}>
                    <GroupSpanCenter>{renderHeaderCat(key)}</GroupSpanCenter>
                  </Box>
                  <Box pl={[0, 20]} width={[1, 3 / 12]}>
                    <GroupSpan>{renderHeaderPlace(key)}</GroupSpan>
                  </Box>
                  <Box pl={[0, 20]} width={[1, 3 / 12]}>
                    <GroupAmountSpan>{renderHeaderPayable(key, amountType)}</GroupAmountSpan>
                  </Box>
                </Flex>
              </Content>
            </GroupHeader>
            <InvoiceDetailsListItems data={key} />
          </div>
        ) : null}
        {!loadingItem && (
          <div>
            <GroupHeader>
              <Content>
                <Flex align="center" justify="space-between">
                  <Box width={[1, 2 / 12]}>
                    <GroupSpan>{key.tipoTransacao}</GroupSpan>
                  </Box>
                  <Box pl={[0, 20]} width={[1, 1 / 12]}>
                    <GroupSpan>{date}</GroupSpan>
                  </Box>
                  <Box pl={[0, 20]} width={[1, 2 / 12]}>
                    <GroupSpanCenter>{renderHeaderHour(key)}</GroupSpanCenter>
                  </Box>
                  <Box pl={[0, 20]} width={[1, 1 / 12]}>
                    <GroupSpanCenter>{renderHeaderCat(key)}</GroupSpanCenter>
                  </Box>
                  <Box pl={[0, 20]} width={[1, 3 / 12]}>
                    <GroupSpan>{renderHeaderPlace(key)}</GroupSpan>
                  </Box>
                  <Box pl={[0, 20]} width={[1, 3 / 12]}>
                    <GroupAmountSpan>{renderHeaderPayable(key, amountType)}</GroupAmountSpan>
                  </Box>
                </Flex>
              </Content>
            </GroupHeader>
            <InvoiceDetailsListItems data={key} />
          </div>
        )}
      </div>
    )
  })
}

const InvoiceDetailsListHeaders = ({ data, loadingItem }) => (
  <div>{renderGroups(data, loadingItem)}</div>
)

export default InvoiceDetailsListHeaders
