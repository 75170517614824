/* eslint-disable */
import React from 'react'
import { connect } from 'react-redux'
import { Flex, Box } from 'grid-styled'
import { setCurrentRechargeValue } from 'alelo-logic/actions'

import ValuePicker from '../../modules/ValuePicker'
import RechargeBreadcrumbs from '../../modules/RechargeBreadcrumbs'
import Button from '../../elements/Button/Button'
import rem from '../../../styles/tools/rem'

import { Title, Currency } from './styles'

const { title, currency } = require('./data.json')

export const RechargePrices = props => {
  const onConfirm = () => {
    props.history.push(`/minha-veloe/${props.match.params.id}/recarga/pagamento`)
  }

  const {
    recharge: { currentRechargeValue, rechargeValues }
  } = props

  const valuePickerStartIndex = rechargeValues.findIndex(
    rechargeValue => rechargeValue === currentRechargeValue
  )

  return (
    <section>
      <RechargeBreadcrumbs active={1} />
      <Flex justify="space-between" direction={['column', 'row']} mt={rem('50px')}>
        <Box width={[1, 'auto']}>
          <Title>{title}</Title>
        </Box>
        <Flex
          justify="space-between"
          align="center"
          mt={[rem('50px'), rem('-30px')]}
          wrap
          width={[1, 8 / 12]}
        >
          <Currency>{currency}</Currency>

          {rechargeValues.length > 0 && (
            <ValuePicker
              id="lstRechargesValues"
              values={rechargeValues}
              startIndex={valuePickerStartIndex}
              onChange={index => props.dispatch(setCurrentRechargeValue(rechargeValues[index]))}
            />
          )}

          <Box mt={[rem('50px'), 0]} width={[1, 'auto']}>
            <Button
              id="btnContinue"
              width={rem('182px')}
              buttonType="transparent"
              text="Continuar"
              onClick={onConfirm}
              className="gtm-link-event"
              data-gtm-event-category="veloe:area-logado:recarga"
              data-gtm-event-action="clique:continuar"
              data-gtm-event-label="codigo-token"
            />
          </Box>
        </Flex>
      </Flex>
    </section>
  )
}

const mapStateToProps = ({ reducer }) => ({
  recharge: reducer.recharge
})

export default connect(mapStateToProps)(RechargePrices)
