import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import { fetchAccountPlans, setActivePlanId } from 'alelo-logic/actions'

import Header from '../../modules/Header'
import Submenu from '../../elements/Submenu'
import FooterSmall from '../../elements/FooterSmall'
import PageLoader from '../../modules/PageLoader'
import { RouteWithSubRoutes } from '../../../routes'
import veloeTagLinks from './linksVeloeTags.json'
import { setLoading } from '../../modules/PageLoader/actions'
// Redux Selectors
import { selectUserCurrentAccount } from 'utils/selectors'
const { subnavLinks } = require('./data.json')

class TemplateDashboard extends Component {
  componentDidMount() {
    this.prepareTemplateDashboard()
  }

  componentDidUpdate(prevProps) {
    const { pathname } = prevProps.location
    const hasLocationChanged = pathname !== this.props.location.pathname
    if (hasLocationChanged) {
      const { location, accountPlans, match } = this.props
      const hasPlans = accountPlans.plans && accountPlans.plans.length > 0
      const currPlan = accountPlans.plans.find(pl => pl.id === match.params.id)
      const hasPlanAndCurrPlan = hasPlans && currPlan
      if (!location.pathname.includes('/dados-do-plano') && hasPlanAndCurrPlan) {
        this.redirectIfPaymentEmpty(currPlan)
      }
    }
  }

  prepareTemplateDashboard = async () => {
    const { setLoading, fetchAccountPlans, match } = this.props
    setLoading(true)
    const accountPlans = await fetchAccountPlans()
    const hasPlans = accountPlans && accountPlans.length > 0
    const currPlan = hasPlans && accountPlans.find(pl => pl.id === match.params.id)
    const hasPlanAndCurrPlan = hasPlans && currPlan
    if (hasPlanAndCurrPlan) {
      this.redirectIfPaymentEmpty(currPlan)
    }
  }

  redirectIfPaymentEmpty = async currentPlan => {
    const { history, setActivePlanId } = this.props
    await setActivePlanId(currentPlan.accountId)
    if (!currentPlan.paymentType) {
      if (currentPlan.plan.type === 'pre') {
        history.push(`/minha-veloe/${currentPlan.id}/primeira-recarga`)
      } else {
        history.push(`/minha-veloe/${currentPlan.id}/dados-do-plano`)
      }
    }
  }

  shouldComponentUpdate(nextProps) {
    this.props.setDataLayer(nextProps)
    const accountChanged = nextProps.currAccount !== this.props.currAccount
    const hasChange =
      // eslint-disable-next-line eqeqeq
      nextProps.accountPlans.activePlanId != nextProps.match.params.id ||
      nextProps.location.pathname !== this.props.location.pathname
    if (hasChange) {
      this.props.setActivePlanId(this.props.match.params.id)
    }
    return hasChange || accountChanged
  }

  renderSubMenu = currentPlan => {
    const { location, match } = this.props
    const { pathname } = location
    const showSubMenu = pathname.includes('/identificadores') || pathname.includes('/instalacao')
    if (showSubMenu) {
      const veloeTagsLinksWithPlanId = veloeTagLinks.map(navLink => ({
        ...navLink,
        link: navLink.link.replace(':id', match.params.id)
      }))
      return <Submenu secondary navItems={veloeTagsLinksWithPlanId} />
    }
    return null
  }

  render() {
    const { routes, match, hideFooter, currAccount } = this.props

    // SEMIAUTOMATICO
    // const { isHubClient = [] } = currentAccountPlan
    // const identifiersTypes = _.map(identifiers, 'identifierType')
    // const semiautomaticoTypes = ['BAND', 'SMART_BAND']
    // const isSemiautomatico = _.some(semiautomaticoTypes, t => _.includes(identifiersTypes, t))

    // Substitui no link o :id pelo id
    const selectedPlan = (currAccount && currAccount.plan) || {}
    const comercialId = (currAccount && currAccount.parceiroComercialId) || {}
    let subnavLinksWithPlanId =
      subnavLinks[selectedPlan.type] &&
      subnavLinks[selectedPlan.type].map(navLink => ({
        ...navLink,
        link: navLink.link.replace(':id', match.params.id)
      }))
    if (comercialId === '2332') {
      subnavLinksWithPlanId = subnavLinksWithPlanId.filter(
        navLink => navLink.id !== 'mnuVehicleDebits'
      )
    }
    const hasPaymentType = currAccount && !!currAccount.paymentType

    return (
      <div>
        <div className="empty-template">
          <Header />
          {hasPaymentType && <Submenu navItems={subnavLinksWithPlanId} />}
        </div>
        {this.renderSubMenu(currAccount)}
        <PageLoader>
          <div>
            {routes.map(route => (
              <RouteWithSubRoutes key={route.path} {...route} />
            ))}
          </div>
        </PageLoader>
        {!hideFooter && (
          <div className="footer-small">
            <FooterSmall />
          </div>
        )}
      </div>
    )
  }
}

const mapStateToProps = ({ reducer, pageLoader }) => {
  return {
    isLoading: pageLoader.isLoading,
    account: reducer.account,
    accountPlans: reducer.accountPlans,
    hideFooter: reducer.ui.toggleFooter,
    currAccount: selectUserCurrentAccount(reducer)
  }
}

const mapDispatchToProps = (dispatch, ownProps) => ({
  fetchAccountPlans: () =>
    dispatch(fetchAccountPlans()).then(accountPlans => {
      const { history, match } = ownProps

      if (accountPlans.length) {
        if (match.params.id === '' || match.params.id === 'resumo') {
          const firstPlanId = accountPlans && accountPlans[0] && accountPlans[0].id
          history.push(`/minha-veloe/${firstPlanId}/resumo`)

          window.dataLayer = window.dataLayer || []
          window.dataLayer.push({
            event: 'login',
            eventCategory: 'veloe:login',
            eventAction: 'login:tentativa',
            eventLabel: 'sucesso',
            dimension1: accountPlans[0].id,
            dimension4: accountPlans[0].accountId
          })
        }
        return accountPlans
      }
      return []
    }),
  setActivePlanId: planId => dispatch(setActivePlanId(planId)),
  setLoading: isLoading => {
    dispatch(setLoading(isLoading))
  },
  setDataLayer: ({ accountPlans, account }) => {
    const { plans } = accountPlans
    const currPlan = (plans.length > 0 && plans[0]) || {}
    if (currPlan) {
      window.dataLayer = window.dataLayer || []
      window.dataLayer.push({
        dimension1: currPlan.id,
        dimension4: currPlan.accountId,
        dimension6: 'logado',
        dimension7: account.accountType,
        dimension8: currPlan.plan && currPlan.plan.category
      })
    }
  }
})

const TemplateWithRouter = withRouter(TemplateDashboard)

export default connect(mapStateToProps, mapDispatchToProps)(TemplateWithRouter)
