import React from 'react'
import { oneOfType, number, string } from 'prop-types'

import { Container, Path, Message } from './styles'

/* eslint-disable */
const paths = [
  'M13.18,3.81a0.6,0.6,0,0,1-.59.55H11.42a0.6,0.6,0,0,1-.59-0.55L10.58,0.55A0.5,0.5,0,0,1,11,0h1.87a0.5,0.5,0,0,1,.51.55L13.18,3.8h0Z',
  'M18.77,7.41a0.61,0.61,0,0,1-.81,0l-0.83-.82a0.6,0.6,0,0,1,0-.8l2.15-2.47A0.51,0.51,0,0,1,20,3.22l0,0,1.28,1.28a0.5,0.5,0,0,1,0,.71l0,0Z',
  'M20.17,13.08a0.6,0.6,0,0,1-.55-0.59V11.33a0.6,0.6,0,0,1,.55-0.59l3.28-.24A0.5,0.5,0,0,1,24,11v1.8a0.5,0.5,0,0,1-.55.51Z',
  'M17.11,18.07a0.6,0.6,0,0,1,0-.8L18,16.45a0.61,0.61,0,0,1,.81,0l2.49,2.13a0.5,0.5,0,0,1,.07.7l0,0L20,20.58a0.51,0.51,0,0,1-.72,0l0,0-2.15-2.48h0Z',
  'M10.82,20a0.6,0.6,0,0,1,.59-0.55h1.17a0.6,0.6,0,0,1,.59.55l0.24,3.26a0.5,0.5,0,0,1-.46.55H11.09a0.5,0.5,0,0,1-.51-0.55Z',
  'M5.39,16.82a0.61,0.61,0,0,1,.81,0L7,17.67a0.6,0.6,0,0,1,0,.8L4.91,20.95a0.51,0.51,0,0,1-.72.06l0,0L2.87,19.7a0.5,0.5,0,0,1,0-.71l0,0Z',
  'M3.83,10.75a0.6,0.6,0,0,1,.55.59v1.16a0.6,0.6,0,0,1-.55.59l-3.28.24A0.5,0.5,0,0,1,0,12.82V11A0.5,0.5,0,0,1,.55,10.5Z',
  'M7.06,5.35a0.6,0.6,0,0,1,0,.8L6.2,7a0.61,0.61,0,0,1-.81,0L2.9,4.87a0.5,0.5,0,0,1-.07-0.7l0,0L4.16,2.85a0.51,0.51,0,0,1,.75,0Z'
]
/* eslint-enable */

function StepCounter({ counter, message }) {
  return (
    <Container>
      {counter && counter !== 0 && (
        <svg height="24" width="24" className="animated-loading" xmlns="http://www.w3.org/2000/svg">
          {paths.map((path, index) => (
            <Path
              key={path}
              counter={counter}
              active={counter >= index}
              className="loading-dash"
              d={path}
              transform="translate(0 0)"
            />
          ))}
        </svg>
      )}
      {message && <Message counter={counter}>{message}</Message>}
    </Container>
  )
}

StepCounter.defaultProps = {
  counter: null,
  message: null
}

StepCounter.propTypes = {
  counter: oneOfType([number, string]),
  message: string
}

export default StepCounter
