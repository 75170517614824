import React from 'react'
import Modal from '../../elements/ModalBlue'
import Button from '../../elements/Button'

import { Wrapper, Title, Text, WrapperButton } from './styles'

import image from '../../../static/icon-veloe-background.svg'

function AddTagsModal(props) {
  const { handleClick } = props
  return (
    <Modal backgroundImage={image} position="right bottom">
      <Wrapper>
        <Title>Seu pedido foi solicitado.</Title>
        <Text>
          Em até 24h você poderá acompanhar o status do seu pedido no menu "<b>Meus pedidos</b>"
        </Text>
        <Text>
          <b>Obrigado por escolher Veloe! ;)</b>
        </Text>

        <WrapperButton>
          <Button
            buttonType="darkBlue"
            text="Ok"
            onClick={() => {
              handleClick()
              window.dataLayer.push({
                event: 'event',
                eventAction: 'clique:ok',
                eventCategory: 'veloe:area-logado:solicitar-produto',
                eventLabel: 'ok'
              })
            }}
          />
        </WrapperButton>
      </Wrapper>
    </Modal>
  )
}

export default AddTagsModal
