import { editPaymentData } from '../services-middleware/editPaymentDataService'

import {
  editPaymentMethod,
  editPaymentMethodWithoutRecharge,
  editDueDate,
  getEditAuthorizationPayment,
  getLastPaymentScheduling as getLastPaymentSchedulingService,
  simulateEditPaymentType
} from '../services/editPaymentDataService'

import types from './types'

const {
  SET_NEW_DATA_VALUE,
  SET_NEW_DUE_DATE,
  GET_EDIT_AUTH_PAYMENT,
  GET_LAST_PAYMENT_SCHEDULE,
  LOCK_EDIT_AUTH_PAYMENT,
  FETCH_NEXT_PAYMENT_TYPE_DATE,
  SET_ACTUAL_PLAN
} = types

export function setNewDataValue(data) {
  return dispatch =>
    editPaymentMethod(data).then(edited => {
      dispatch({
        type: SET_NEW_DATA_VALUE,
        data: data
      })
      return edited
    })
}

export function setActualPlan(actualPlan) {
  return {
    type: SET_ACTUAL_PLAN,
    actualPlan: actualPlan
  }
}

export function setNewDataValueWithoutRecharge(data) {
  return async dispatch => {
    const response = await editPaymentMethodWithoutRecharge(data)
    dispatch({
      type: SET_NEW_DATA_VALUE,
      data: response
    })
    return response
  }
}

export function setNewDueDate(data) {
  return dispatch =>
    editDueDate(data).then(edited => {
      dispatch({
        type: SET_NEW_DUE_DATE,
        data
      })
      return edited
    })
}

export function fetchEditPaymentData({ accountPlanId, recharge, payment }) {
  return () => editPaymentData(accountPlanId, recharge, payment).then(resp => resp)
}

export function lockAuthorizationPayment(type) {
  return dispatch =>
    dispatch({
      type: LOCK_EDIT_AUTH_PAYMENT,
      changeType: type,
      authorizations: {
        error: 'Já alterado'
      }
    })
}

export function checkEditAuthorizationPayment(type, accountPlanId) {
  return dispatch =>
    getEditAuthorizationPayment(type, accountPlanId).then(authorizations => {
      return dispatch({
        type: GET_EDIT_AUTH_PAYMENT,
        changeType: type,
        authorizations
      })
    })
}

export function getLastPaymentScheduling(type, accountPlanId) {
  return dispatch =>
    getLastPaymentSchedulingService(type, accountPlanId).then(data =>
      dispatch({
        type: GET_LAST_PAYMENT_SCHEDULE,
        payload: data
      })
    )
}

export function simulateNextPaymentTypeDate(accountPlanId) {
  return dispatch =>
    simulateEditPaymentType(accountPlanId).then(nextDate => {
      return dispatch({
        type: FETCH_NEXT_PAYMENT_TYPE_DATE,
        nextDate
      })
    })
}
