import React from 'react'

import { DropdownListContainer, DropdownListItem, Title } from './styles'

function DropdownList({ options, isOpen, onClose, onSelect }) {
  const closeDropdown = onClick => {
    if (onClose) {
      onClose()
    }

    if (onClick) {
      onClick()
    }
  }

  return (
    <DropdownListContainer isOpen={isOpen}>
      {isOpen &&
        options.map((option, index) => (
          <DropdownListItem
            key={index}
            onClick={() => {
              closeDropdown(option.onClick)
              onSelect(option)
            }}
          >
            <Title>{option.label}</Title>
          </DropdownListItem>
        ))}
    </DropdownListContainer>
  )
}

export default DropdownList
