import types from '../actions/types'

const { STATE_MODAL, LOGIN_MSG_ERROR, SHOW_CAPTCHA } = types

const initialState = {
  stateModal: false,
  loginMsgError: '',
  showCaptcha: false
}

export default function loginModalReducer(state = initialState, action) {
  switch (action.type) {
    case STATE_MODAL: {
      return {
        ...state,
        stateModal: action.stateModal
      }
    }
    case LOGIN_MSG_ERROR: {
      return {
        ...state,
        loginMsgError: action.loginMsgError
      }
    }
    case SHOW_CAPTCHA: {
      return {
        ...state,
        showCaptcha: action.showCaptcha
      }
    }
    default:
      return state
  }
}
