import React from 'react'

const IcTruck = props => (
  <svg width="48" height="48" viewBox="0 0 48 48">
    <defs>
      <path
        id="a2"
        d="M7.444 24.956l-2.775-.007c-2.185 0-3.964-1.804-3.964-4.022V4.021C.705 1.804 2.484 0 4.673 0h14.924c2.19 0 3.968 1.804 3.968 4.021V5.34h5.618c3.718 0 6.741 3.064 6.741 6.829v11.49c0 .74-.592 1.341-1.321 1.341h-2.445c-.562 1.558-2.041 2.674-3.774 2.674-1.73 0-3.208-1.117-3.77-2.676l-9.604-.021c-.556 1.57-2.04 2.697-3.782 2.697-1.744 0-3.234-1.136-3.784-2.717zm-.006-2.68c.545-1.59 2.04-2.735 3.79-2.735 1.762 0 3.262 1.153 3.802 2.754l9.55.02c.534-1.61 2.038-2.774 3.804-2.774 1.77 0 3.276 1.165 3.81 2.777h1.083V14.4l-5.255-.04a1.333 1.333 0 0 1-1.313-1.35 1.33 1.33 0 0 1 1.334-1.33l5.21.04c-.221-2.077-1.961-3.699-4.07-3.699h-5.618v10.307c0 .74-.591 1.34-1.321 1.34-.734 0-1.326-.6-1.326-1.34V4.02c0-.739-.591-1.34-1.321-1.34H4.673c-.73 0-1.321.601-1.321 1.34v7.662l12.931.046c.793.005 1.43.61 1.426 1.35-.01.737-.652 1.33-1.44 1.33l-12.917-.046v6.564c0 .74.592 1.34 1.321 1.34l2.765.008zm20.946-.054c-.75 0-1.364.621-1.364 1.385s.613 1.385 1.364 1.385c.756 0 1.37-.622 1.37-1.385 0-.764-.614-1.385-1.37-1.385zm-17.156 0c-.751 0-1.364.621-1.364 1.385s.613 1.385 1.364 1.385c.756 0 1.37-.622 1.37-1.385 0-.764-.614-1.385-1.37-1.385z"
      />
    </defs>
    <g fill="none" fillRule="evenodd" transform="translate(7 10)">
      <mask id="b2" fill="#fff">
        <use xlinkHref="#a2" />
      </mask>
      <use fill="#252853" xlinkHref="#a2" />
      <g mask="url(#b2)">
        <path fill="currentColor" d="M-7-10h48v48H-7z" />
      </g>
      <path d="M-6.627-9.581h47.534v47.534H-6.627z" mask="url(#b2)" />
    </g>
  </svg>
)

export default IcTruck
