import {
  deleteRepresentative,
  updateBusinessUserRepresentative,
  createRepresentative
} from '../services-middleware/userService'

import types from './types'

const { DELETE_REPRESENTATIVE, UPDATE_REPRESENTATIVE, ADD_REPRESENTATIVE } = types

const cleanDocument = (document = '') => document.replace(/\.|-/g, '')

export function fetchDeleteRepresentative(rawDocument) {
  const document = cleanDocument(rawDocument)

  return dispatch =>
    deleteRepresentative(document).then(() => {
      setTimeout(() => {
        dispatch({
          type: DELETE_REPRESENTATIVE,
          document
        })
      }, 600)
    })
}

export function fetchUpdateRepresentative(rawData) {
  const data = rawData
  data.document = cleanDocument(data.document)

  return dispatch =>
    updateBusinessUserRepresentative(data).then(() => {
      dispatch({
        type: UPDATE_REPRESENTATIVE,
        data
      })
    })
}

export function fetchCreateRepresentative(rawData) {
  const data = rawData
  data.document = cleanDocument(data.document)

  return dispatch =>
    createRepresentative(data).then(() => {
      dispatch({
        type: ADD_REPRESENTATIVE,
        data
      })
    })
}
