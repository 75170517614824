import React from 'react'

import { Icon } from '../../elements/Icon'
import Button from '../../elements/Button'

import { Item, Description, ItemContainer, DateTicket, Wrapper, Infos } from './styles'
function IndentifiersRequestStatus(props) {
  return (
    <Wrapper>
      <ItemContainer borderBlue>
        <Item>
          <Icon icon="checkStatus" width="28" height="28" viewBox="0 0 28 28" />
        </Item>
        <Infos>
          <Description light>
            {props.type === 'pos' ? 'Solicitado com sucesso' : props.payment.status}
          </Description>
          <DateTicket>{props.payment.date}</DateTicket>
        </Infos>
      </ItemContainer>

      <ItemContainer borderBlue={props.tracker[0].date !== ''}>
        <Item>
          <Icon icon="truck" width="35" height="25" viewBox="0 0 35 25" />
        </Item>
        <Infos>
          <Description light>{props.tracker[0].status}</Description>
          <DateTicket>{props.tracker[0].date}</DateTicket>
        </Infos>
      </ItemContainer>

      <ItemContainer borderBlue={props.tracker[1].date !== ''}>
        <Item>
          <Icon icon="packageDelivery" width="30" height="27" viewBox="0 0 30 27" />
        </Item>
        <Infos>
          <Description light>{props.tracker[1].status}</Description>
          <DateTicket>{props.tracker[1].date}</DateTicket>
        </Infos>
      </ItemContainer>
      <ItemContainer>
        {props.payment.status === 'Pagamento Aprovado' || props.type === 'pos' ? (
          <Button
            text="Ativar seu produto"
            onClick={() => props.history.push(`/identificadores/ativacao`)}
            disabled={
              props.tracker[1].date === '' ||
              props.tracker[1].status === 'EXTRAVIADO' ||
              props.tracker[1].status === 'DEVOLVIDO' ||
              props.tracker[1].status === 'SEPARACAO' ||
              props.tracker[1].status === 'CANCELADO'
            }
            loading={false}
          />
        ) : (
          <Button
            text="Efetuar Recarga"
            onClick={() =>
              props.history.push(`/minha-veloe/${props.accountId}/editar-dados/cartao?path=track`)
            }
            loading={false}
          />
        )}
      </ItemContainer>
    </Wrapper>
  )
}

export default IndentifiersRequestStatus
