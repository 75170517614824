import React from 'react'

const IcBus = props => (
  <svg width="48" height="48" viewBox="0 0 48 48">
    <defs>
      <path
        id="a3"
        d="M25.268 14.838v3.875h5.206v-3.875h-5.206zm-2.84 0h-5.3v3.875h5.3v-3.875zm10.886 0v3.875h2.89a6.55 6.55 0 0 1 3.742 1.168v-1.307a3.743 3.743 0 0 0-3.742-3.736h-2.89zm-19.026 0H9.26c-.782 0-1.42.634-1.42 1.417v13.162c0 .782.638 1.421 1.42 1.421h.008l.562.003a4.314 4.314 0 0 1 4.069-2.892 4.314 4.314 0 0 1 4.076 2.914l11.688.024a4.314 4.314 0 0 1 4.084-2.938 4.314 4.314 0 0 1 4.077 2.918l2.123.013v-5.593a3.743 3.743 0 0 0-3.742-3.736H12.099a1.418 1.418 0 1 1 0-2.838h2.19v-3.875zM9.832 33.674l-.577-.003A4.258 4.258 0 0 1 5 29.417V16.255A4.261 4.261 0 0 1 9.26 12h26.944c3.629 0 6.581 2.95 6.581 6.574v13.732c0 .38-.15.74-.418 1.009a1.422 1.422 0 0 1-1.001.412h-.009l-3.557-.022a4.315 4.315 0 0 1-4.054 2.85 4.315 4.315 0 0 1-4.048-2.83L17.954 33.7a4.315 4.315 0 0 1-4.056 2.855 4.315 4.315 0 0 1-4.066-2.882zm23.914-2.887c-.81 0-1.468.657-1.468 1.468a1.467 1.467 0 0 0 2.935 0c0-.81-.658-1.468-1.467-1.468zm-19.848 0c-.81 0-1.468.657-1.468 1.468a1.467 1.467 0 0 0 2.935 0c0-.81-.658-1.468-1.467-1.468z"
      />
    </defs>
    <g fill="none" fillRule="evenodd">
      <mask id="b3" fill="#fff">
        <use xlinkHref="#a3" />
      </mask>
      <use fill="#252853" xlinkHref="#a3" />
      <g fill="currentColor" mask="url(#b3)">
        <path d="M0 0h48v48H0z" />
      </g>
      <path d="M.373.419h47.534v47.534H.373z" mask="url(#b3)" />
    </g>
  </svg>
)

export default IcBus
