import React from 'react'
import { func, string, bool } from 'prop-types'
import { connect } from 'react-redux'

import { hideModal } from '../ModalRoot/actions'
import { updateTokenModal, setTokenModalError } from './actions'

import { Icon } from '../../elements/Icon'
import Modal from '../../elements/Modal'
import TextInput from '../../elements/TextInput'

import { Description, InputContainer, Resend, Title, TokenModalContainer } from './styles'

const { description, label, placeholder, title } = require('./data.json')

const TokenModal = props => {
  const { error, loading, onConfirm, onResendToken, token } = props

  const handleConfirm = () => {
    if (onConfirm) {
      onConfirm(props)
    } else {
      props.hideModal()
    }
  }

  const handleResend = () => {
    if (onResendToken) {
      onResendToken()
    }
  }

  return (
    <TokenModalContainer>
      <Modal hideModal={props.hideModal} onConfirm={handleConfirm}>
        <Title>{props.title ? props.title : title}</Title>
        <Description>{description}</Description>

        <InputContainer mb={[60, 48]} width={[1, 262]}>
          <TextInput
            errorMessage={error}
            label={label}
            loading={loading}
            negative
            onChange={props.updateTokenModal}
            placeholder={placeholder}
            value={token}
          />

          {!loading && onResendToken && (
            <Resend onClick={handleResend}>
              <Icon height="18px" icon="resend" width="18px" viewBox="0 0 18 18" />
            </Resend>
          )}
        </InputContainer>
      </Modal>
    </TokenModalContainer>
  )
}

TokenModal.defaultProps = {
  error: null,
  loading: false,
  onConfirm: null,
  onResendToken: null,
  token: ''
}

TokenModal.propTypes = {
  error: string,
  loading: bool,
  onConfirm: func,
  onResendToken: func,
  token: string
}

const mapStateToProps = ({ tokenModal }) => tokenModal

export default connect(mapStateToProps, {
  hideModal,
  setTokenModalError,
  updateTokenModal
})(TokenModal)
