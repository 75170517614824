import {
  getStatements,
  downloadStatement,
  downloadStatementCSV
} from '../services/dashboardService'

import types from './types'

const { SET_STATEMENTS, UPDATE_STATEMENTS, RESET_STATEMENTS } = types

export function setDashboardStatements(planId, next, filter) {
  return dispatch =>
    getStatements(planId, next, filter).then(statements => {
      if (statements.status === 204) {
      } else {
        dispatch({
          type: SET_STATEMENTS,
          data: statements.data,
          total: statements.total,
          monthlyTotal: statements.monthlyTotal,
          filter: filter,
          next: statements.next,
          updatedOn: statements.updatedOn,
          hasNext: statements.hasNext
        })
      }
    })
}

export function fetchDashboardStatements(planId, next, filter) {
  return dispatch =>
    getStatements(planId, next, filter).then(statements => {
      if (statements.status === 204) {
      } else {
        dispatch({
          type: UPDATE_STATEMENTS,
          data: statements.data,
          total: statements.total,
          monthlyTotal: statements.monthlyTotal,
          filter: filter,
          next: statements.next,
          updatedOn: statements.updatedOn,
          hasNext: statements.hasNext
        })
      }
    })
}

export function downloadStatementFile(planId, filter) {
  return () => downloadStatement(planId, filter)
}

export function downloadStatementFileCSV(planId, filter) {
  return () => downloadStatementCSV(planId, filter)
}

export function resetDashboardStatements() {
  return dispatch =>
    dispatch({
      type: RESET_STATEMENTS
    })
}
