import React from 'react'
import Helmet from 'react-helmet'
import { Box, Flex } from 'grid-styled'
import ImersiveHeader from '../../elements/ImersiveHeader'
import Content from '../../../styles/objects/Content'
import Button from '../../elements/Button'
import Hide from '../../../styles/utilities/Hide'

import { Container, Title, Paragraph, Separator, Protocol, Date, Illustration } from './styles'

import semaphore from '../../../static/semaphore.png'

const { helmetTitle, date, title, infoSubtitle, protocol } = require('./data.json')

const BlockIdentifierSuccess = props => (
  <section>
    <Helmet title={helmetTitle} />
    <ImersiveHeader isColored text="Dados de plano" url="/minha-veloe/2/identificadores" />
    <Container>
      <Content>
        <Flex direction="row">
          <Box width={1}>
            <Title>{title}</Title>
            <Paragraph>{infoSubtitle}</Paragraph>
            <Separator />
            <Protocol>{protocol}</Protocol>
            <Date>{date}</Date>
            <Box mt={32} mb={37}>
              <Button buttonType="transparent" text="Ok" width={140} />
            </Box>
          </Box>
          <Box>
            <Hide at="small">
              <Illustration>
                <img src={semaphore} alt="semáforo" />
              </Illustration>
            </Hide>
          </Box>
        </Flex>
      </Content>
    </Container>
  </section>
)

export default BlockIdentifierSuccess
