import styled from 'styled-components'
import { txt16OpenSans } from 'styles/settings/types'
import { darkBlue } from 'styles/settings/colors'
import rem from 'styles/tools/rem'

export const VehicleInfo = styled.div`
  ${txt16OpenSans};
  color: ${darkBlue};
  padding: ${rem('15px')} 0;
`
