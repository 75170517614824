import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Flex, Box } from 'grid-styled'
import { setCurrentRechargeValue, fetchChangeRecharge } from 'alelo-logic/actions'

import ValuePicker from '../../modules/ValuePicker'
import RechargeBreadcrumbs from '../../modules/RechargeBreadcrumbs'
import Button from '../../elements/Button/Button'
import rem from '../../../styles/tools/rem'

import { Title, Currency } from './styles'

const { title, currency, button } = require('./data.json')

class FirstAccessRecharge extends Component {
  state = {}

  onConfirm = () => {
    const {
      recharge: { currentRechargeValue },
      history,
      match
    } = this.props

    const accountId = match.params.id
    this.props
      .dispatch(fetchChangeRecharge(accountId, currentRechargeValue))
      .then(() => {
        history.push(`/minha-veloe/${match.params.id}/primeira-recarga/cartao`)
      })
      .catch(e => {
        console.error(e) // eslint-disable-line
      })
  }

  render() {
    const {
      recharge: { currentRechargeValue, rechargeValues }
    } = this.props
    const valuePickerStartIndex = rechargeValues.findIndex(
      rechargeValue => rechargeValue === currentRechargeValue
    )

    return (
      <section>
        <RechargeBreadcrumbs active={1} />
        <Flex justify="space-between" direction={['column', 'row']} mt={rem('50px')}>
          <Box width={[1, 'auto']}>
            <Title>{title}</Title>
          </Box>
          <Flex
            justify="space-between"
            align="center"
            mt={[rem('50px'), rem('-30px')]}
            wrap
            width={[1, 8 / 12]}
            direction="column"
            style={{ position: 'relative' }}
          >
            <Currency>{currency}</Currency>

            {rechargeValues.length > 0 && (
              <ValuePicker
                id="lstRechargesValues"
                values={rechargeValues}
                startIndex={valuePickerStartIndex}
                onChange={index =>
                  this.props.dispatch(setCurrentRechargeValue(rechargeValues[index]))
                }
              />
            )}

            <Box mt={rem('50px')} width={[1, 'auto']}>
              <Button
                id="btnContinue"
                width={rem('182px')}
                buttonType="transparent"
                text={button}
                onClick={this.onConfirm}
              />
            </Box>
          </Flex>
        </Flex>
      </section>
    )
  }
}

const mapStateToProps = ({ reducer }) => ({
  recharge: reducer.recharge
})

export default connect(mapStateToProps)(FirstAccessRecharge)
