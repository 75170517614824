import React from 'react'
import { utils } from 'alelo-logic'
import LoadMore from '../../elements/LoadMore'
import StatementsListItem from '../../elements/StatementsListItem'
import { Title, Text, Statements, StatementGroup, Sidebar, List, StatementsFooter } from './styles'

const dayOfWeekMap = {
  0: 'Domingo',
  1: 'Segunda',
  2: 'Terça',
  3: 'Quarta',
  4: 'Quinta',
  5: 'Sexta',
  6: 'Sábado'
}

const monthOfYearMap = {
  0: 'Janeiro',
  1: 'Fevereiro',
  2: 'Março',
  3: 'Abril',
  4: 'Maio',
  5: 'Junho',
  6: 'Julho',
  7: 'Agosto',
  8: 'Setembro',
  9: 'Outubro',
  10: 'Novembro',
  11: 'Dezembro'
}
function renderSectionItems(items) {
  return items.map((statement, index) => (
    <StatementsListItem
      {...statement}
      key={index}
      nextType={items[index + 1] ? items[index + 1].type : ''}
    />
  ))
}

function renderSectionDate(key) {
  const date = utils.dateParser(key)
  return (
    <Sidebar>
      <Text>{dayOfWeekMap[date.getDay()].toUpperCase()}</Text>
      <Title>{date.getDate()}</Title>
      <Text>{monthOfYearMap[date.getMonth()].toUpperCase()}</Text>
    </Sidebar>
  )
}

function renderSection(data) {
  const groupedData = utils.groupArray(data || [], item => utils.ISODateFormat(item.processDate))

  const keys = Object.keys(groupedData)

  return keys.map(key => (
    <StatementGroup key={key}>
      {renderSectionDate(key)}
      <List>{renderSectionItems(groupedData[key])}</List>
    </StatementGroup>
  ))
}

const StatementsList = ({ data, next, loadMoreHandler, hasNext }) => (
  <Statements>
    {renderSection(data)}
    {next != null && hasNext != null && (
      <StatementsFooter>
        <LoadMore
          w={130}
          onClick={() => {
            loadMoreHandler()
            window.dataLayer = window.dataLayer || []
            window.dataLayer.push({
              event: 'event',
              eventCategory: 'veloe:area-logado:extrato',
              eventAction: 'clique:icone:carregar-mais',
              eventLabel: 'carregar-mais'
            })
          }}
        />
      </StatementsFooter>
    )}
  </Statements>
)

export default StatementsList
