import moment from 'moment'

const ptBrLocale = require('moment/locale/pt-br')

moment.updateLocale('pt-br', ptBrLocale)

export default function getDayOfWeek(date) {
  if (!date) {
    return null
  }
  return moment(date)
    .locale('pt-br')
    .format('dddd')
}
