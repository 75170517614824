import { updateBusinessUserProfile } from '../services/userService'

// import types from './types'

// const { FETCH_PROFILE_UPDATE } = types

function fetchUpdateBusinessProfile(data) {
  return dispatch =>
    updateBusinessUserProfile(data).then(user => {
      // dispatch({
      //   type: FETCH_PROFILE_UPDATE,
      //   state: user
      // })
    })
}

export default fetchUpdateBusinessProfile
