import React from 'react'
import styled from 'styled-components'

const Path = styled.path`
  ${({ active, hover }) => {
    if (active) return `fill: #2a2264`
    if (hover) return `fill: #8583a4`
    return `fill: #c5c3d8`
  }};
`

const IcSearch = props => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
    <Path
      {...props}
      d="M10.974 18.876c-2.13 0-4.116-.83-5.642-2.32A7.895 7.895 0 0 1 3 10.937c0-2.12.835-4.098 2.332-5.617A7.967 7.967 0 0 1 10.974 3c2.13 0 4.117.831 5.643 2.321a7.895 7.895 0 0 1 2.331 5.617c0 2.12-.834 4.098-2.331 5.617-1.526 1.49-3.512 2.321-5.643 2.321zm0-14.128c-3.426 0-6.218 2.78-6.218 6.19 0 3.41 2.792 6.19 6.218 6.19s6.218-2.78 6.218-6.19c-.028-3.41-2.792-6.19-6.218-6.19zm-3.742 7.079a.895.895 0 0 1-.893-.889 4.56 4.56 0 0 1 4.578-4.556c.49 0 .892.4.892.888a.895.895 0 0 1-.892.888 2.82 2.82 0 0 0-2.822 2.809c.03.458-.374.86-.863.86zM21.136 22a.895.895 0 0 1-.633-.258l-2.936-2.923a.862.862 0 0 1 0-1.232.872.872 0 0 1 1.238 0l2.936 2.923a.862.862 0 0 1 0 1.232.786.786 0 0 1-.605.258z"
    />
  </svg>
)

export default IcSearch
