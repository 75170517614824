import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import Helmet from 'react-helmet'
import { actions } from 'alelo-logic'
import { showModal } from '../../modules/ModalRoot/actions'
import EditAddressForm from '../../modules/EditAddressForm'
import { CONFIRM_UPDATE } from '../../../constants/ModalTypes'
import { startSaving, stopSaving } from '../../modules/form-state-actions'
import addressFactory from './factory'

import { Content, Heading, Paragraph, Wrapper } from './styles'

import image from '../../../static/confirmation.png'

const { helmetTitle, title, subtitle, modalUpdateTitle, modalDeleteTitle } = require('./data.json')

const { fetchUpdateAddress, fetchDeleteAddress } = actions

class EditAddress extends PureComponent {
  render() {
    const { address, handleSave, handleDelete, saving, match } = this.props
    const initialValues = addressFactory.toForm(address)

    return (
      <Content>
        <Helmet title={helmetTitle} />
        <Heading>{title}</Heading>
        <Paragraph>{subtitle}</Paragraph>
        <Wrapper>
          <EditAddressForm
            initialValues={{ address: initialValues }}
            onSubmit={values => handleSave(addressFactory.toAPI(values.address))}
            onDelete={handleDelete}
            length={address ? address.length : 0}
            saving={saving}
            match={match}
          />
          {/* <LastSavedLabel>{lastSaved}</LastSavedLabel> */}
        </Wrapper>
      </Content>
    )
  }
}

const mapStateToProps = state => ({
  address: state.reducer.user.enderecos,
  saving: state.reducer.formState.saving
})

const mapDispatchToProps = dispatch => ({
  handleSave: address => {
    dispatch(startSaving())

    dispatch(fetchUpdateAddress(address))
      .then(() => {
        dispatch(showModal(CONFIRM_UPDATE, { modalTitle: modalUpdateTitle, image }))
        dispatch(stopSaving())
      })
      .catch(e => {
        console.log('Erro ao atualizar o endereço', e)
        dispatch(
          showModal(CONFIRM_UPDATE, { modalTitle: 'Ops! Falha ao atualizar o endereço.', image })
        )
        dispatch(stopSaving())
      })
  },
  handleDelete: (id, isCorrespondence) =>
    dispatch(fetchDeleteAddress(id))
      .then(() =>
        dispatch(
          showModal(
            CONFIRM_UPDATE,
            isCorrespondence
              ? { modalTitle: modalDeleteTitle, image }
              : { modalTitle: modalUpdateTitle, image }
          )
        )
      )
      .catch(e => {
        console.log('Erro ao excluir o endereço', e)
        dispatch(stopSaving())
      })
})

export default connect(mapStateToProps, mapDispatchToProps)(EditAddress)
