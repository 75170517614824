import axios from 'axios/index'
import { API_AUTHORIZATION, AJAX_MAX_RETRY } from './constants'
import { getAccessToken } from './storage'

const getResponseErrorDescription = error => {
  let decription = 'Erro inesperado'
  let respData = error.response.data
  if (typeof respData === 'string' || respData instanceof String) {
    try {
      const respDataObj = JSON.parse(error.response.data)
      decription = respDataObj.messageError || respDataObj.description
    } catch (e) {
      decription = respData
    }
  } else {
    decription = respData.messageError
  }
  return decription
}

export const request = async (config, retryCount = 1) => {
  // TODO tratar erros de token espirado e normalizar erros de conexão
  try {
    const token = getAccessToken()
    const headers = {
      ...config.headers,
      Authorization: API_AUTHORIZATION
    }
    // prevent token from being sent in the request, used by authentication
    if (!config.preventToken && token) headers.token = token

    const resp = await axios.request({
      ...config,
      headers
    })
    return resp.data
  } catch (error) {
    if (error.response) {
      error.jsonMessage = getResponseErrorDescription(error)
      error.status = error.response.status || -1
    } else if (error.request) {
      // provalvement NetworkError, tentar novamente
      if (retryCount < AJAX_MAX_RETRY) {
        return request(config, retryCount + 1)
      }
      error.jsonMessage = `Erro ao se comunicar com o servidor, aguarde alguns instantes e tente novamente.`
      error.status = 0
    } else {
      // Something happened in setting up the request that triggered an Error
      error.jsonMessage = `Error não esperado`
      error.status = -1
      console.error(`Error não esperado: ${error.message}`)
    }
    error.json = async () => ({
      messageError: error.jsonMessage
    })
    throw error
  }
}

export const get = async (url, config) => request({ ...config, url, method: 'get' })
export const post = async (url, data = {}, config) =>
  request({ data, ...config, url, method: 'post' })
export const put = async (url, data = {}, config) =>
  request({ data, ...config, url, method: 'put' })
