import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import {
  // downloadInvoiceFile,
  // downloadTicketFile,
  downloadInvoiceCSVFile
  // downloadFaturaFile
} from 'alelo-logic/actions'
// import { showModal } from '../../modules/ModalRoot/actions'
// import { setLoading } from '../../modules/PageLoader/actions'
import // INPUT_MODAL,
// CONFIRM_UPDATE
'../../../constants/ModalTypes'

import { Icon } from '../../elements/Icon'
// import image from '../../../static/mail-to.png'
// import imageSuccess from '../../../static/confirmation.png'

import { Content, IconColor, NavHeader, NavIcon, ResumeTitle, Text, Title } from './styles'

const {
  downloadInvoice,
  downloadBoleto,
  invoice,
  printInvoice
  // shareInvoice,
  // modal,
  // boletoModalTitle,
  // boletoModalBody
  // boletoUnavailableModalTitle,
  // boletoUnavailableModalBody,
  // erroModalTitleFatura,
  // erroModalBodyFatura
} = require('./data.json')

const InvoiceHeader = props => {
  const {
    title,
    match,
    // email,
    nfe,
    // status,
    transacoes,
    // nsu,
    linkFaturaPDF,
    linkBoleto,
    ...restProps
  } = props
  return (
    <div>
      <Content>
        <ResumeTitle>
          <Text>{invoice}</Text>
          <Title>{title}</Title>
        </ResumeTitle>
        {transacoes > 0 && (
          <NavHeader>
            {nfe && (
              <NavIcon title="Download da NFe">
                <Icon
                  icon="doc"
                  svgStyle={IconColor}
                  aria-label={downloadInvoice}
                  width="40"
                  height="40"
                  viewBox="0 0 45 45"
                  onClick={() => {
                    window.open(nfe, '_blank')
                    window.dataLayer = window.dataLayer || []
                    window.dataLayer.push({
                      event: 'event',
                      eventCategory: 'veloe:area-logado:faturas',
                      eventAction: 'clique:botao:download',
                      eventLabel: 'download:NFe'
                    })
                  }}
                />
              </NavIcon>
            )}
            {
              <NavIcon title="Download fatura em CSV">
                <Icon
                  icon="download"
                  svgStyle={IconColor}
                  aria-label={downloadInvoice}
                  width="40"
                  height="40"
                  viewBox="0 0 70 70"
                  onClick={() => {
                    restProps.downloadCSVInvoice(match.params.id, match.params.fatura)
                    window.dataLayer = window.dataLayer || []
                    window.dataLayer.push({
                      event: 'event',
                      eventCategory: 'veloe:area-logado:faturas',
                      eventAction: 'clique:botao:download',
                      eventLabel: 'download:csv'
                    })
                  }}
                />
              </NavIcon>
            }
            {/* <NavIcon>
          <Icon
            icon="share"
            svgStyle={IconColor}
            aria-label={shareInvoice}
            width="40"
            height="40"
            viewBox="0 0 85 70"
            onClick={() => {
              props.shareInvoice(match.params.id, match.params.year, match.params.month, email)
            }}
          />
        </NavIcon> */}
            {linkBoleto && (
              <NavIcon title="Download do Boleto">
                <Icon
                  icon="barcode"
                  svgStyle={IconColor}
                  aria-label={downloadBoleto}
                  width="40"
                  height="40"
                  viewBox="0 0 23 23"
                  onClick={() => window.open(linkBoleto, '_blank')}
                />
              </NavIcon>
            )}
            {linkFaturaPDF && (
              <NavIcon className="print" title="Download fatura em PDF" id="btn_download_pdf">
                <Icon
                  icon="print"
                  svgStyle={IconColor}
                  aria-label={printInvoice}
                  width="40"
                  height="40"
                  viewBox="0 0 85 75"
                  onClick={() => {
                    window.open(linkFaturaPDF, '_blank')
                    window.dataLayer = window.dataLayer || []
                    window.dataLayer.push({
                      event: 'event',
                      eventCategory: 'veloe:area-logado:faturas',
                      eventAction: 'clique:botao:download',
                      eventLabel: 'download:pdf'
                    })
                  }}
                />
              </NavIcon>
            )}
          </NavHeader>
        )}
      </Content>
    </div>
  )
}

const mapDispatchToProps = dispatch => ({
  downloadCSVInvoice: (planId, invoiceId) => dispatch(downloadInvoiceCSVFile(planId, invoiceId))
  // requestNewBoleto: planId => {
  //   dispatch(
  //     dispatch(
  //       showModal(CONFIRM_UPDATE, {
  //         modalTitle: boletoModalTitle,
  //         modalDescription: boletoModalBody,
  //         image: imageSuccess
  //       })
  //     )
  //   )
  // }
  // downloadBoletoInvoice: (planId, invoiceId) => {
  //   dispatch(setLoading(true))
  //   dispatch(downloadTicketFile(planId, invoiceId))
  //     .catch(err => {
  //       console.error(err)
  //       return err
  //     })
  //     .then((message = {}) => {
  //       if (message.statusCode === '20' || message.statusName === 'NAO_DISPONIVEL_SOLICITANDO') {
  //         dispatch(
  //           showModal(CONFIRM_UPDATE, {
  //             modalTitle: boletoModalTitle,
  //             modalDescription: boletoModalBody,
  //             image: imageError
  //           })
  //         )
  //       } else if (
  //         message.statusCode === '21' ||
  //         message.statusName === 'NAO_DISPONIVEL_SOLICITADO_PREVIAMENTE'
  //       ) {
  //         dispatch(
  //           showModal(CONFIRM_UPDATE, {
  //             modalTitle: boletoUnavailableModalTitle,
  //             modalDescription: boletoUnavailableModalBody || message.message,
  //             image: imageError
  //           })
  //         )
  //       }
  //     })
  //     .finally(() => dispatch(setLoading(false)))
  // },
  // downloadFaturaInvoice: (planId, invoiceId) => {
  //   console.log('Call Download Fatura')
  //   dispatch(setLoading(true))
  //   dispatch(downloadFaturaFile(planId, invoiceId))
  //     .catch(err => {
  //       if (err.status === 404 || err.statusText === 'Not Found') {
  //         dispatch(
  //           showModal(CONFIRM_UPDATE, {
  //             modalTitle: erroModalTitleFatura,
  //             modalDescription: erroModalBodyFatura,
  //             image: imageError
  //           })
  //         )
  //       } else {
  //         console.error('Erro download fatura', err)
  //       }
  //     })
  //     .finally(() => dispatch(setLoading(false)))
  // },
  // downloadPdfInvoice: (planId, year, month) =>
  //   dispatch(downloadInvoiceFile(planId, year, month)).then(response => {
  //     window.open(`data:application/octed-stream;base64,${response.data}`)
  //   }),
  // shareInvoice: (planId, year, month, email) => {
  //   dispatch(
  //     showModal(INPUT_MODAL, {
  //       ...modal,
  //       value: email,
  //       fetchSendProvider: () =>
  //         fetch(`/api/accountplans/${planId}/invoices/${year}/${month}/mail`),
  //       onConfirm: () => {
  //         dispatch(
  //           showModal(CONFIRM_UPDATE, {
  //             modalTitle: 'Sua fatura foi enviada.',
  //             image
  //           })
  //         )
  //       },
  //       image
  //     })
  //   )
  // }
})

export default connect(null, mapDispatchToProps)(withRouter(InvoiceHeader))
