import styled, { css } from 'styled-components'
import { Link } from 'react-router-dom'
import Icon from '../Icon/Icon'

import { linkNavyBlue, lightGray, skyBlue, blizzardBlue } from 'styles/settings/colors'
import { txt14OpenSansBold } from 'styles/settings/types'

import rem from 'styles/tools/rem'
import content from 'styles/objects/Content'

export const Content = styled(content)`
  height: ${rem('70px')};
  line-height: ${rem('70px')};
`

export const BackIcon = styled(Icon)`
  margin-right: ${rem('18px')};
  display: inline-block;
`

export const BackLink = styled(Link)`
  ${txt14OpenSansBold};

  color: ${linkNavyBlue};
  display: inline-block;
  position: relative;
  text-decoration: none;
`

export const ButtonLink = styled.a`
  ${txt14OpenSansBold};
  cursor: pointer;
  color: ${linkNavyBlue};
  display: inline-block;
  position: relative;
  text-decoration: none;
`

export const Wrapper = styled.nav`
  border-bottom: ${rem('1px')} solid ${lightGray};
  width: 100%;

  ${props =>
    props.isColored &&
    css`
      background: ${skyBlue};
      border: ${rem('1px')} solid ${blizzardBlue};

      ${BackLink} {
        color: white;
      }
    `};
`
