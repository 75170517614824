import React from 'react'
import { Field, reduxForm } from 'redux-form'
import { Flex, Box } from 'grid-styled'
import { withRouter } from 'react-router-dom'

import TextInput from '../../elements/TextInput'
import Button from '../../elements/Button'
import Radio from '../../elements/Radio'

const { labels, placeholders, errorMessage, buttons } = require('./data.json')

const renderRadio = ({ input, label }) => <Radio {...input} label={label} />

const renderOtherMotive = ({ placeholder, input, meta: { touched, error } }) => (
  <TextInput
    placeholder={placeholder}
    maxlength="150"
    lengthCounter
    {...input}
    errorMessage={touched && error}
  />
)

const renderRadioWithContent = ({ input, label }) => (
  <Radio {...input} label={label}>
    <Field placeholder={placeholders.other} name="otherMotive" component={renderOtherMotive} />
  </Radio>
)

const validateCancel = (values = {}) => {
  const errors = {}
  const allFields = Object.keys(values)
  const { option, otherMotive } = values

  const validOtherMotive = otherMotive && otherMotive.length > 0

  if (option === labels.other && !validOtherMotive) {
    errors.otherMotive = errorMessage.otherMotive
  }

  errors.option = allFields.length === 0

  return errors
}

const CancelAccountForm = props => {
  const { handleSubmit, pristine, history, ctaText } = props

  return (
    <form onSubmit={handleSubmit}>
      <Box mb={24}>
        <Field
          component={renderRadio}
          label={labels.dontWantService}
          name="option"
          value={labels.dontWantService}
          type="radio"
        />
      </Box>
      <Box mb={24}>
        <Field
          component={renderRadio}
          label={labels.dontWantCar}
          name="option"
          value={labels.dontWantCar}
          type="radio"
        />
      </Box>
      {props.profile === 'pf' ? (
        <Box mb={24}>
          <Field
            component={renderRadio}
            label={labels.moving}
            name="option"
            value={labels.moving}
            type="radio"
          />
        </Box>
      ) : (
        <div>
          <Box mb={24}>
            <Field
              component={renderRadio}
              label={labels.changingCompanies}
              name="option"
              value={labels.changingCompanies}
              type="radio"
            />
          </Box>
          <Box mb={24}>
            <Field
              component={renderRadio}
              label={labels.cantPayForService}
              name="option"
              value={labels.cantPayForService}
              type="radio"
            />
          </Box>
        </div>
      )}
      <Box>
        <Field
          component={renderRadioWithContent}
          label={labels.other}
          name="option"
          value={labels.other}
          type="radio"
        />
      </Box>
      <Flex wrap mt={60}>
        <Box width={[1, 'auto']} mr={[0, 30]} mb={[20, 0]}>
          <Button disabled={pristine} type="submit" text={ctaText || buttons.cancel} />
        </Box>
        <Box width={[1, 'auto']}>
          <Button buttonType="white" onClick={() => history.goBack()} text={buttons.back} />
        </Box>
      </Flex>
    </form>
  )
}

export default reduxForm({
  form: 'changeUserPasswordData',
  validate: validateCancel,
  destroyOnUnmount: false
})(withRouter(CancelAccountForm))
