import React from 'react'
import { utils } from 'alelo-logic'
import {
  fetchGenderList,
  fetchMaritialStatusList,
  fetchTelephoneTypeList
} from 'alelo-logic/actions'
import TextInput from '../app/elements/TextInput'

import Select from '../app/elements/Select'
import PhoneInput from '../app/elements/PhoneInput'
import Checkbox from '../app/elements/Checkbox'
import PasswordInput from '../app/elements/PasswordInput'
import {
  creditCardMask,
  cvcMask,
  validThruMask,
  numberMask,
  accountDigitMask
} from '../utils/masks'
import RoundedInput from '../app/elements/RoundedInput'

export const renderTextInput = props => (
  <TextInput
    {...props.input}
    label={props.label}
    disabled={props.disabled}
    placeholder={props.placeholder}
    errorMessage={props.meta.touched && props.meta.error}
    isRequired={props.isRequired}
    lockCursor={props.lockCursor}
    negative={props.negative}
    maxlength={props.maxlength}
    lengthCounter={props.lengthCounter}
    tooltip={props.tooltip}
    autoComplete={props.autoComplete}
  />
)

export const renderRoundedInput = props => (
  <RoundedInput {...props.input} {...props.meta} placeholder={props.placeholder} />
)

export const renderSelectField = props => (
  <Select
    {...props.input}
    label={props.label}
    onChange={value => {
      props.input.onChange(value)
      props.onSelected && props.onSelected(value)
    }}
    options={props.options}
    errorMessage={props.meta.touched && props.meta.error}
    isRequired={props.isRequired}
    placeholder={props.placeholder}
    negative={props.negative}
    disabled={props.disabled}
  />
)

export const renderTelephoneField = props => (
  <PhoneInput
    {...props.input}
    label={props.label}
    disabled={props.disabled}
    errorMessage={props.meta.touched && props.meta.error}
    isRequired={props.isRequired}
    negative={props.negative}
  />
)

export const renderPhoneInput = props => (
  <TextInput
    {...props.input}
    maxlength={props.maxlength}
    label={props.label}
    disabled={props.disabled}
    placeholder={props.placeholder}
    errorMessage={props.errorMessage}
    value={utils.phoneMask(`${props.input.value}`)}
    negative={props.negative}
  />
)

export const renderCpfInput = props => (
  <TextInput
    {...props.input}
    label={props.label}
    disabled={props.disabled}
    placeholder={props.placeholder}
    errorMessage={props.meta.touched && props.meta.error}
    isRequired={props.isRequired}
    value={utils.cpfMask(props.input.value)}
    negative={props.negative}
  />
)

export const renderCnpjInput = props => (
  <TextInput
    {...props.input}
    label={props.label}
    disabled={props.disabled}
    placeholder={props.placeholder}
    errorMessage={props.meta.touched && props.meta.error}
    isRequired={props.isRequired}
    value={utils.cnpjMask(props.input.value)}
    negative={props.negative}
  />
)

export const renderZipCodeInput = props => (
  <TextInput
    {...props.input}
    label={props.label}
    disabled={props.disabled}
    errorMessage={props.meta.touched && props.meta.error}
    value={utils.cepMask(props.input.value)}
    negative={props.negative}
    loading={props.loading}
  />
)

export const renderCheckboxField = props => (
  <Checkbox
    {...props.input}
    label={props.label}
    checked={props.input.value}
    disabled={props.disabled}
    onChange={value => props.input.onChange(value)}
    negative={props.negative}
  />
)

export const renderPasswordInput = props => {
  const { touched, error } = props.meta

  return (
    <div>
      <PasswordInput
        {...props.input}
        label={props.label}
        placeholder={props.placeholder}
        disabled={props.disabled}
        errorMessage={touched && !!error}
        passwordMessage={touched && error}
        type="password"
        negative={props.negative}
      />
    </div>
  )
}

export function renderGenderField(props) {
  const getGenders = () => fetchGenderList().then(response => ({ options: response }))

  return (
    <Select
      {...props.input}
      label={props.label}
      onChange={value => props.input.onChange(value)}
      options={[]}
      errorMessage={props.meta.touched && props.meta.error}
      isRequired={props.isRequired}
      negative={props.negative}
      disabled={props.disabled}
      loadOptions={getGenders}
    />
  )
}

export function renderMaritialStatusField(props) {
  const getMaritialStatus = () =>
    fetchMaritialStatusList().then(response => ({ options: response }))

  return (
    <Select
      {...props.input}
      label={props.label}
      onChange={value => props.input.onChange(value)}
      options={[]}
      errorMessage={props.meta.touched && props.meta.error}
      isRequired={props.isRequired}
      negative={props.negative}
      disabled={props.disabled}
      loadOptions={getMaritialStatus}
    />
  )
}

export function renderTelephoneTypesField(props) {
  const getTelephoneTypes = () => fetchTelephoneTypeList().then(response => ({ options: response }))

  return (
    <Select
      {...props.input}
      label={props.label}
      onChange={value => props.input.onChange(value)}
      options={[]}
      errorMessage={props.meta.touched && props.meta.error}
      isRequired={props.isRequired}
      negative={props.negative}
      disabled={props.disabled}
      loadOptions={getTelephoneTypes}
    />
  )
}

export const renderCardInput = props => (
  <TextInput
    {...props.input}
    maxlength={props.maxlength}
    label={props.label}
    disabled={props.disabled}
    placeholder={props.placeholder}
    errorMessage={
      props.meta.touched && props.meta.error ? props.errorMessage || props.meta.error : ''
    }
    value={creditCardMask(`${props.input.value}`)}
    negative={props.negative}
    labelIcon={props.labelIcon}
  />
)

export const renderAgencyInput = props => (
  <TextInput
    {...props.input}
    maxlength={props.maxlength}
    label={props.label}
    disabled={props.disabled}
    placeholder={props.placeholder}
    errorMessage={
      props.meta.touched && props.meta.error ? props.errorMessage || props.meta.error : ''
    }
    value={numberMask(`${props.input.value}`, 4)}
    negative={props.negative}
    inputRefFn={props.agencyRefFn}
  />
)

export const renderAccountInput = props => (
  <TextInput
    {...props.input}
    maxlength={props.maxlength}
    label={props.label}
    disabled={props.disabled}
    placeholder={props.placeholder}
    errorMessage={
      props.meta.touched && props.meta.error ? props.errorMessage || props.meta.error : ''
    }
    value={numberMask(`${props.input.value}`, props.limitDigit ? props.limitDigit : 7)}
    negative={props.negative}
    inputRefFn={props.accountRefFn}
  />
)

export const renderAccountDigitInput = props => (
  <TextInput
    {...props.input}
    maxlength={props.maxlength}
    label={props.label}
    disabled={props.disabled}
    placeholder={props.placeholder}
    errorMessage={
      props.meta.touched && props.meta.error ? props.errorMessage || props.meta.error : ''
    }
    value={accountDigitMask(`${props.input.value}`, props.limitDigit ? props.limitDigit : 1)}
    negative={props.negative}
  />
)

export const renderValidThruInput = props => {
  return (
    <TextInput
      {...props.input}
      maxlength={props.maxlength}
      label={props.label}
      disabled={props.disabled}
      placeholder={props.placeholder}
      isRequired={props.isRequired}
      errorMessage={
        props.meta.touched && props.meta.error ? props.errorMessage || props.meta.error : ''
      }
      value={validThruMask(`${props.input.value}`)}
      negative={props.negative}
    />
  )
}
export const renderCvcInput = props => (
  <TextInput
    {...props.input}
    maxlength={props.maxlength}
    label={props.label}
    disabled={props.disabled}
    placeholder={props.placeholder}
    errorMessage={
      props.meta.touched && props.meta.error ? props.errorMessage || props.meta.error : ''
    }
    value={cvcMask(`${props.input.value}`, props.limitDigit)}
    negative={props.negative}
    tooltip={props.tooltip}
    tooltipColor={props.tooltipColor}
    tooltipPositionRight={props.tooltipPositionRight}
    endIcon={props.endIcon}
  />
)
