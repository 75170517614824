import React from 'react'
import { Field, reduxForm } from 'redux-form'
import { connect } from 'react-redux'
import { Flex, Box } from 'grid-styled'
import Button from '../../elements/Button'
import {
  renderTextInput,
  renderTelephoneField,
  renderCheckboxField
} from '../../../utils/renderInputs'

import { validate } from './validateContact'

const { labels } = require('./data.json')

const EditContactData = props => {
  const { handleSubmit, saving } = props

  return (
    <form onSubmit={handleSubmit}>
      <Flex wrap width={[1, 7 / 12]}>
        <Box width={1} mb={3}>
          <Field
            component={renderTextInput}
            id="email"
            label={labels.email}
            name="email"
            type="email"
            isRequired
            disabled={saving}
          />
        </Box>
        <Box width={[1, 1 / 2]} pr={[0, 2]} mb={3}>
          <Field
            component={renderTelephoneField}
            id="phone"
            label={labels.phone}
            name="phone"
            type="text"
            disabled={saving}
          />
        </Box>
        <Box width={[1, 1 / 2]} pl={[0, 2]} mb={3}>
          <Field
            component={renderTelephoneField}
            id="mobile"
            label={labels.mobile}
            name="mobile"
            type="text"
            isRequired
            disabled={saving}
          />
        </Box>
        <Box width={1} mb={3}>
          <Field
            component={renderCheckboxField}
            id="checkbox"
            label={labels.checkbox}
            name="checkbox"
            disabled={saving}
          />
        </Box>
      </Flex>
      <div>
        <Button type="submit" text={labels.submit} disabled={saving} />
      </div>
    </form>
  )
}

const mapStateToProps = state => {
  return {
    saving: state.reducer.formState.saving
  }
}

const mapDispatchToProps = dispatch => ({})

const form = reduxForm({
  form: 'editContactData',
  validate,
  destroyOnUnmount: false
})(EditContactData)

export default connect(mapStateToProps, mapDispatchToProps)(form)
