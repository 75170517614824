import React from 'react'
import Icon from '../Icon/Icon'
import styled, { css } from 'styled-components'

import {
  AppIcon,
  AppsLinkContainer,
  FooterNavigation,
  NavigationBottomItem,
  NavigationColumn,
  NavigationContent,
  NavigationLabel,
  NavigationLink,
  NavigationText,
  SocialIcons,
  VeloeLogo,
  TelephoneNumber
} from './styles'

function FooterNavigationSmall() {
  return (
    <FooterNavigationSm>
      <NavigationContentSm>
        <NavigationColumnSm>
          <Icon
            icon="veloe-logo"
            svgStyle={VeloeLogoSmall}
            width="74"
            height="31"
            viewBox="0 0 74 31"
          />
          <NavigationLink>Sobre a Veloe</NavigationLink>
          <NavigationLink>Planos</NavigationLink>
          <NavigationLink>Onde encontrar</NavigationLink>
          <NavigationLink>Ajuda</NavigationLink>
          <NavigationLink>Imprensa</NavigationLink>
        </NavigationColumnSm>
        <NavigationColumnSm>
          <IconWrapper>
            <Icon
              icon="social-icons"
              svgStyle={SocialIconsSmall}
              width="58"
              height="24"
              viewBox="0 0 58 24"
            />
          </IconWrapper>
          <NavigationLink>Carreiras</NavigationLink>
          <NavigationLink>Mapa do site</NavigationLink>
          <NavigationLink>Termos de uso</NavigationLink>
          <NavigationLink>Mais carreiras</NavigationLink>
        </NavigationColumnSm>
      </NavigationContentSm>
      <NavigationBottomItemSm>
        <NavigationLink>Tem alguma dúvida?</NavigationLink>
        <TelephoneNumber>0800 000 800</TelephoneNumber>
        <NavigationText short>
          Estamos prontos para te atender 24h por dia, 7 dias por semana.
        </NavigationText>
      </NavigationBottomItemSm>
      <NavigationBottomItemSm>
        <NavigationLabel>Baixe nossos apps</NavigationLabel>
        <AppsLinkContainer>
          <Icon icon="play-store" svgStyle={AppIcon} width="110" height="38" viewBox="0 0 110 38" />
          <Icon icon="app-store" svgStyle={AppIcon} width="110" height="38" viewBox="0 0 110 38" />
        </AppsLinkContainer>
      </NavigationBottomItemSm>
    </FooterNavigationSm>
  )
}

export default FooterNavigationSmall

// Extending styles for small breakpoint
const FooterNavigationSm = FooterNavigation.extend`
  flex-direction: column;
  padding: 50px;
`
const NavigationContentSm = NavigationContent.extend`
  width: 100%;
`
const NavigationBottomItemSm = NavigationBottomItem.extend`
  text-align: center;
`
const NavigationColumnSm = NavigationColumn.extend`
  min-width: 0;
`
const VeloeLogoSmall = css`
  ${VeloeLogo} margin-bottom: 40px;
`
const SocialIconsSmall = css`
  ${SocialIcons} margin-bottom: 40px;
`
const IconWrapper = styled.div`
  padding: 10px;
`
