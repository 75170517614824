import React from 'react'
import { utils } from 'alelo-logic'

import {
  Container,
  Currency,
  CurrencySign,
  DriverName,
  Info,
  LicenseCode,
  LicenseLabel,
  LicensePlate,
  Tax
} from './styles'

const { currencyParser } = utils

const DashboardIdentifierCard = ({ consumptionLabel, order, isOrdered, plate, expense }) => {
  const licensePlateLetters = plate.slice(0, 3)
  const licensePlateNumbers = plate.slice(3)

  return (
    <Container isOrdered={isOrdered && order}>
      <Info>
        {plate && (
          <LicensePlate>
            <LicenseLabel>Placa</LicenseLabel>
            <LicenseCode>
              {licensePlateLetters}
              <span>{licensePlateNumbers}</span>
            </LicenseCode>
          </LicensePlate>
        )}

        <Tax>
          <DriverName>{consumptionLabel}</DriverName>
          <Currency>
            <CurrencySign>R$</CurrencySign>
            {currencyParser(expense)}
          </Currency>
        </Tax>
      </Info>
    </Container>
  )
}

DashboardIdentifierCard.defaultProps = {
  carNickname: '',
  driverName: '',
  order: 0,
  licensePlate: '',
  tax: ''
}

export default DashboardIdentifierCard
