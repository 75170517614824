import React from 'react'
import { LoaderContainer, LoaderContent, LoaderAnimation, LoaderMessage } from './styles'

const { LoadMessages } = require('./data.json')

function Loader() {
  const messageNumber = Math.floor(Math.random() * LoadMessages.length)

  return (
    <LoaderContainer>
      <LoaderContent>
        <LoaderAnimation />
        <LoaderMessage>{LoadMessages[messageNumber]}</LoaderMessage>
      </LoaderContent>
    </LoaderContainer>
  )
}

export default Loader
