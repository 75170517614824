const isClient = typeof window !== 'undefined'

function getScreenWidth() {
  if (isClient) {
    return (
      window.innerWidth ||
      document.documentElement.clientWidth ||
      document.body.clientWidth ||
      document.body.offsetWidth
    )
  }
}

function isMobile() {
  if (isClient) {
    return getScreenWidth() < 767
  }
}

function isDesktop() {
  if (isClient) {
    return getScreenWidth() > 767
  }
}

export { isMobile, isDesktop }
