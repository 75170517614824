import React from 'react'
import Helmet from 'react-helmet'
import { Box } from 'grid-styled'
import { connect } from 'react-redux'
import { actions } from 'alelo-logic'
import { withRouter } from 'react-router-dom'

import ImersiveHeader from '../../elements/ImersiveHeader'
import CancelAccountForm from '../../modules/CancelAccountForm'
import { showModal } from '../../modules/ModalRoot/actions'
import {
  setTokenModalError,
  setTokenModalLoading,
  setTokenModalTitle
} from '../../modules/TokenModal/actions'
import { TOKEN_MODAL } from '../../../constants/ModalTypes'

import { Content, Heading, Subtitle, Paragraph, Wrapper } from './styles'

const { helmetTitle, title, infoSubtitle, text, optionsSubtitle } = require('./data.json')

const {
  fetchCancelAccount,
  fetchCancelAccountTokenCreate,
  fetchCancelAccountTokenValidate
} = actions

const CancelAccount = props => (
  <section>
    <ImersiveHeader text="Perfil" />
    <Content>
      <Box width={[1, 6 / 12]}>
        <Helmet title={helmetTitle} />
        <Heading>{title}</Heading>
        <section>
          <Subtitle>{infoSubtitle}</Subtitle>
          <Paragraph>{text}</Paragraph>
        </section>
        <section>
          <Subtitle>{optionsSubtitle}</Subtitle>
          <Wrapper>
            <CancelAccountForm onSubmit={values => props.handleSave(values, props.history)} />
          </Wrapper>
        </section>
      </Box>
    </Content>
  </section>
)

const handleCancelToken = (data, dispatch, history) => {
  dispatch(
    showModal(TOKEN_MODAL, {
      onConfirm: ({ hideModal, token }) => {
        dispatch(setTokenModalLoading(true))

        dispatch(fetchCancelAccountTokenValidate(token))
          .then(() => {
            dispatch(
              fetchCancelAccount({
                reason: data.option,
                detail: data.otherMotive
              })
            ).then(() => {
              hideModal()
              dispatch(setTokenModalLoading(null))
              dispatch(setTokenModalError(null))
              history.push('/perfil/conta-cancelada')
            })
          })
          .catch(() => {
            dispatch(setTokenModalLoading(null))
            dispatch(setTokenModalError('O código não é esse. Tente de novo.'))
          })
      },
      onResendToken: () => {
        dispatch(setTokenModalLoading(true))

        dispatch(fetchCancelAccountTokenCreate())
          .then(() => {
            dispatch(setTokenModalLoading(null))
            dispatch(setTokenModalTitle('Reenviamos o código para você.'))
          })
          .catch(() => {
            dispatch(setTokenModalLoading(null))
          })
      }
    })
  )
}

const mapStateToProps = ({ form, reducer }) => ({
  form,
  cancelAccount: reducer.cancelAccount
})

const mapDispatchToProps = dispatch => ({
  handleSave: (values, history) => {
    dispatch(fetchCancelAccountTokenCreate()).then(() =>
      handleCancelToken(values, dispatch, history)
    )
  }
})

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(CancelAccount))
