import React from 'react'
import { Container, Title, Subtitle, SeeMore, SeeMoreIcon, SeeMoreContainer } from './styles'

const { title, subtitle, seeMore } = require('./data.json')

const NewPlaces = () => (
  <Container>
    <header>
      <Title>{title}</Title>
      <Subtitle>{subtitle}</Subtitle>
    </header>
    <SeeMoreContainer>
      <SeeMore>{seeMore}</SeeMore>
      <SeeMoreIcon icon="right-arrow" width="8" height="13" viewBox="0 0 8 13" />
    </SeeMoreContainer>
  </Container>
)

export default NewPlaces
