import TemplateEmpty from '../app/templates/TemplateEmpty'
import Login from '../app/pages/Login'
import ForgotPassword from '../app/pages/ForgotPassword'
import CreateNewPassword from '../app/pages/CreateNewPassword'

export default {
  path: '/conta',
  component: TemplateEmpty,
  routes: [
    {
      path: '/conta/login',
      exact: true,
      component: Login
    },
    {
      path: '/conta/esqueci-a-senha',
      exact: true,
      component: ForgotPassword
    },
    {
      path: '/conta/esqueci-a-senha/:token',
      exact: true,
      component: CreateNewPassword
    }
  ]
}
