import React from 'react'

import {
  lightOlive,
  lightRed,
  white,
  linkNavyBlue,
  darkGrey,
  darkBlue
} from '../../../../styles/settings/colors'
import {
  CarContent,
  CarStatus,
  Container,
  RowContainer,
  PlateLabel,
  Plate,
  HideOnLarge
} from './styles'

import Table from './table'

import blockedCarImage from '../../../../static/blocked-car.png'
import unblockedCarImage from '../../../../static/unblocked-car.png'
const { labels } = require('./data.json')

const Car = ({ data }) => {
  const { blocked, queries } = data

  const colors = blocked
    ? { content: lightRed, plateLabel: white, plate: white, status: white }
    : { content: lightOlive, plateLabel: darkGrey, plate: darkBlue, status: linkNavyBlue }

  const image = blocked
    ? { src: blockedCarImage, alt: 'carro bloqueado' }
    : { src: unblockedCarImage, alt: 'carro liberado' }

  const status = blocked ? 'Bloqueado' : 'Liberado'

  return (
    <div>
      <CarContent color={colors.content}>
        <HideOnLarge>
          <PlateLabel color={colors.plateLabel}>{labels.plate}</PlateLabel>
          <Plate color={colors.plate}>{data.plate}</Plate>
        </HideOnLarge>
        <RowContainer>
          <img src={image.src} alt={image.alt} />
          <CarStatus color={colors.status}>{status}</CarStatus>
        </RowContainer>
      </CarContent>
      <Container>
        <Table data={queries} />
      </Container>
    </div>
  )
}
export default Car
