import styled from 'styled-components'

import { darkBlue, white } from '../../../styles/settings/colors'

import { txt12OpenSans, txt14OpenSans, txt14OpenSansBold } from '../../../styles/settings/types'

import rem from '../../../styles/tools/rem'

import em from '../../../styles/tools/em'

import media from '../../../styles/tools/media'

export const Container = styled.section`
    border-bottom: 1px solid rgba(255,255,255,0.4);
    margin-bottom: ${rem('120px')};
    margin-top: ${rem('2px')};
    padding: ${rem('15px')} 0 0};

    ${media.small`
        margin-bottom: ${rem('140px')};
        padding: 0;
    `};
`

export const Content = styled.div`
  border-top: 1px solid rgba(255, 255, 255, 0.4);
  display: block;
`

export const FilterTypeContainer = styled.div`
  ${media.large`
        padding: 0;
    `};
  ${media.small`
        margin: 0;
    `};
`

export const FilterTypes = styled.ul`
  display: block;
  list-style: none;
  margin: 0;
  padding: 0;

  ${media.small`
        display: block;
        width: 100%;

        :after {
            clear: both;
            content: '';
            display: table;
        }
    `};
`

export const ListItem = styled.li`
  width: 100%;

  ${media.small`
        display: block;
        text-align: center;
        width: 100%;
    `};
`

export const Title = styled.h2`
  ${txt12OpenSans} color: ${white};
  letter-spacing: ${em('1px')};
  line-height: 1.33;
  margin: 0 auto ${rem('24px')};
  max-width: ${rem('940px')};
  text-transform: uppercase;

  ${media.small`
        ${txt12OpenSans}

        margin: ${rem('-4px')} ${rem('24px')} ${rem('15px')};
    `};
`

export const SelectAll = styled.h3`
  ${txt14OpenSansBold} line-height: 1.33;
  color: ${white};
  cursor: pointer;
  margin: 0 auto ${rem('15px')};
  max-width: ${rem('940px')};
  padding-top: ${rem('15px')};
  transition: color 0.3s ease;

  &:hover {
    color: ${darkBlue};
  }

  ${media.small`
        ${txt14OpenSans}

        letter-spacing: ${rem('0.4px')};
        margin-left: ${rem('24px')};
        margin: 0 0 ${rem('15px')};
        padding-top: ${rem('27px')};
    `};
`
