import React from 'react'
import Modal from '../../elements/ModalBlue'
import Button from '../../elements/Button'

import { Wrapper, Title, Text, WrapperButton } from './styles'

import image from '../../../static/icon-veloe-background.svg'

function AddTagsModal(props) {
  const { handleClick } = props
  return (
    <Modal backgroundImage={image} position="right bottom">
      <Wrapper>
        <Title>Seu produto foi bloqueado</Title>
        <Text>
          Por enquanto, você não vai conseguir ter acesso livre aos locais conveniados, mas fique
          tranquilo. Você pode pedir um novo produto para curtir o que realmente importa.
        </Text>

        <WrapperButton>
          <Button buttonType="darkBlue" text="Ok" onClick={() => handleClick()} />
        </WrapperButton>
      </Wrapper>
    </Modal>
  )
}

export default AddTagsModal
