import React from 'react'
import styled from 'styled-components'
import { destroy } from 'redux-form'
import { connect } from 'react-redux'

import Modal from 'app/elements/Modal'
import Button from 'app/elements/Button'
import media from '../../../styles/tools/media'
import { title16Galano } from '../../../styles/settings/types'

const FormModal = props => {
  return props.isOpen ? (
    <Modal title="Tem algo errado com seu login ou sua senha. Tente novamente.">
      <div>
        <SecurityMessage>{props.loginMsgError}</SecurityMessage>
        <Row>
          <Button
            buttonType="transparent"
            onClick={() => {
              props.closeModal()
              props.resetCaptcha()
              props.dispatch(destroy('login'))
            }}
            type="button"
            title="Ok"
            borderWhite
            minWidth="180"
            text="Ok"
          />
        </Row>
      </div>
    </Modal>
  ) : null
}

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 40px 0 0;

  ${media.small`
    align-items: center;
    flex-direction: column;
    justify-content: center;

    > button {
      width: 160px;
    }
  `};
`
const SecurityMessage = styled.h4`
  ${title16Galano};
`

export default connect(state => state)(FormModal)
