import React from 'react'
import { array } from 'prop-types'
import { Box, Flex } from 'grid-styled'
import rem from 'styles/tools/rem'
import Content from 'styles/objects/Content'
import moment from 'moment'
// Assets
import { darkBlue } from 'styles/settings/colors'
// Styles
import {
  Title,
  CustomContent,
  Paragraph,
  Wrapper,
  EditHeader,
  NoContentText,
  ListItem,
  ListItemText
} from 'app/pages/EditIdentifiers/styles'

const ManageInfoHistoricList = ({ data = [] }) => {
  const dayMonth = date => moment(date).format('DD/MM')
  const hourMinute = date => moment(date).format('HH:mm')
  return (
    <div>
      <Content>
        <Title>Histórico</Title>
        <Paragraph>Veja abaixo todas as ações referente ao veículo selecionado:</Paragraph>
      </Content>
      <Wrapper>
        <Content>
          <EditHeader>
            <Flex>
              <Box width={[1.6 / 12]} pl={rem('15px')}>
                Data da ação
              </Box>
              <Box width={[1.6 / 12]}>hora da ação</Box>
              <Box width={[1.6 / 12]}>tipo da ação</Box>
              <Box width={[2 / 12]}>descrição</Box>
              <Box width={[2 / 12]}>descrição nova</Box>
              <Box width={[1.6 / 12]}>data início</Box>
              <Box width={[1.6 / 12]}>hora início</Box>
            </Flex>
          </EditHeader>
        </Content>
      </Wrapper>
      <CustomContent>
        {data.length > 0 &&
          data.map((item, i) => (
            <ListItem key={i}>
              <Flex width={1}>
                <Box width={[1.6 / 12]} pl={rem('15px')}>
                  <ListItemText color={darkBlue} bold>
                    {dayMonth(item.dataAtualizacao)}
                  </ListItemText>
                </Box>
                <Box width={[1.6 / 12]}>
                  <ListItemText>{hourMinute(item.dataAtualizacao)}</ListItemText>
                </Box>
                <Box width={[1.6 / 12]}>
                  <ListItemText bold>{item.acao}</ListItemText>
                </Box>
                <Box width={[2 / 12]}>
                  <ListItemText bold>{item.descricaoAnterior}</ListItemText>
                </Box>
                <Box width={[2 / 12]}>
                  <ListItemText bold>{item.descricaoAtual}</ListItemText>
                </Box>
                <Box width={[1.6 / 12]}>
                  <ListItemText color={darkBlue} bold>
                    {dayMonth(item.dataInicioVigencia)}
                  </ListItemText>
                </Box>
                <Box width={[1.6 / 12]}>
                  <ListItemText>{hourMinute(item.dataInicioVigencia)}</ListItemText>
                </Box>
              </Flex>
            </ListItem>
          ))}

        <Flex>
          {data.length === 0 && (
            <Box mt={rem('20px')}>
              <NoContentText>Você ainda não possui um histórico</NoContentText>
            </Box>
          )}
        </Flex>
      </CustomContent>
    </div>
  )
}

ManageInfoHistoricList.propTypes = {
  data: array.isRequired
}

export default ManageInfoHistoricList
