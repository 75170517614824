import styled, { css } from 'styled-components'

import { darkGrey, grey, linkNavyBlue, white } from '../../../styles/settings/colors'
import {
  title28GalanoBold,
  title20GalanoBold,
  txt10OpenSans,
  txt12OpenSans,
  txt12OpenSansLight,
  txt20OpenSans
} from '../../../styles/settings/types'

import media from '../../../styles/tools/media'
import rem from '../../../styles/tools/rem'

export const Info = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: ${rem('19px')};

  ${media.large`
        margin-bottom: ${rem('15px')};
    `};
`

export const LicensePlate = styled.h3`
  line-height: 0;
  margin: 0;
`

export const LicenseLabel = styled.span`
  ${txt12OpenSansLight};

  color: ${darkGrey};
  display: inline-block;
  line-height: 1;
  margin-bottom: ${rem('10px')};
  text-align: left;
  text-transform: uppercase;
`

export const LicenseCode = styled.strong`
  ${txt20OpenSans};

  color: ${linkNavyBlue};
  display: block;
  line-height: 1;
  text-align: left;
  white-space: nowrap;

  span {
    font-family: Galano;
    font-weight: bold;
  }
`

export const Tax = styled.div`
  margin: 0;
  text-align: right;
`

export const DriverName = styled.p`
  ${txt12OpenSans};

  color: ${darkGrey};
  font-weight: 200;
  line-height: 1;
  margin: 0 0 ${rem('10px')};
`

export const Currency = styled.p`
  ${title20GalanoBold};

  color: ${linkNavyBlue};
  display: flex;
  justify-content: flex-end;
  letter-spacing: 0;
  line-height: 1em;
  margin: 0;
`

export const CurrencySign = styled.span`
  ${txt20OpenSans};
  margin-right: 5px;
`

export const CurrencyLabel = styled.span`
  ${txt10OpenSans};

  color: ${grey};
`

export const ShowMore = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  min-height: ${rem('20px')};
  margin-right: ${rem('18px')};
  margin-bottom: ${rem('10px')};
`

export const CarNickname = styled.strong`
  ${txt12OpenSansLight};

  color: ${darkGrey};
  font-weight: 400;
  text-align: center;
`

export const Order = styled.strong`
  ${title28GalanoBold};

  background-color: #f1f1f7;
  color: #292663;
  padding: ${rem('13px')};
`

export const Container = styled.section`
  background-color: ${white};
  border-radius: ${rem('4.4px')};
  border: solid ${rem('1px')} ${grey};
  padding: ${rem('18px')} ${rem('20px')} ${rem('14px')};

  ${({ isOrdered }) =>
    isOrdered &&
    css`
      display: flex;
      flex-direction: column;
      padding: 0;

      ${LicensePlate} {
        margin-bottom: ${rem('20px')};
      }

      ${Info} {
        flex: 1;
        padding: ${rem('16px')};
        margin: 0;
        height: 100px;
      }

      ${DriverName} {
        margin-bottom: ${rem('10px')};
      }

      ${Currency} {
        display: block;
      }
    `};
`
