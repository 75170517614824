export default {
  __html: `<defs>
            <path id="ab123" d="M15.27 3.906H4.354a.724.724 0 0 0-.706.706V36c0 .376.33.706.706.706h19.812c.376 0 .706-.33.706-.706V13.506h-5.977a3.625 3.625 0 0 1-3.623-3.624V3.906zm8.895 35.718H4.353A3.625 3.625 0 0 1 .729 36V4.612C.73 2.635 2.33.988 4.353.988h12.33c.376 0 .752.141 1.035.424l9.6 9.553c.282.282.423.659.423 1.035v24c.047 2.024-1.6 3.624-3.576 3.624zm-5.647-19.295h-8.66c-.8 0-1.458-.658-1.458-1.458 0-.8.659-1.46 1.459-1.46h8.659c.8 0 1.458.66 1.458 1.46 0 .8-.658 1.458-1.458 1.458zm0 6.212h-8.66c-.8 0-1.458-.659-1.458-1.459s.659-1.458 1.459-1.458h8.659c.8 0 1.458.658 1.458 1.458 0 .8-.658 1.46-1.458 1.46zm0 6.212h-8.66c-.8 0-1.458-.659-1.458-1.459s.659-1.459 1.459-1.459h8.659c.8 0 1.458.66 1.458 1.46 0 .8-.658 1.458-1.458 1.458z"/>
          </defs>
          <g fill="none" fill-rule="evenodd" transform="translate(10 4)">
            <mask id="ba123" fill="#fff">
                <use xlink:href="#ab123"/>
            </mask>
            <use fill="#201A5B" fill-rule="nonzero" xlink:href="#ab123"/>
            <g fill="#2A2264" mask="url(#ba123)">
                <path d="M-10-4h48v48h-48z"/>
            </g>
          </g>`
}
