import React from 'react'
import { connect } from 'react-redux'

import Content from '../../../styles/objects/Content'
import HeaderWithBrand from '../../elements/HeaderWithBrand'
import Button from 'app/elements/Button'

import { Title, Paragraph, Wrapper, ProtocolTitle, Protocol, UpdateIn } from './styles'

const { title, paragraph, titleProtocol, submitButton } = require('./data.json')

const ActivationSuccess = props => {
  const { history } = props
  return (
    <Wrapper>
      <HeaderWithBrand />
      <Content>
        <Title>{title}</Title>
        <Paragraph>{paragraph}</Paragraph>
        <ProtocolTitle>{titleProtocol}</ProtocolTitle>
        <Protocol>201801228659</Protocol>
        <UpdateIn>Salvo em 22/01/2018, às 19:30.</UpdateIn>
        <Button text={submitButton} onClick={() => history.goBack(-3)} buttonType="transparent" />
      </Content>
    </Wrapper>
  )
}

const mapStateToProps = ({ reducer }) => {
  return {
    accountPlans: reducer.accountPlans
  }
}

export default connect(mapStateToProps, null)(ActivationSuccess)
