import gql from 'graphql-tag'
import { transformObject, removeTypename } from '../common/helpers'

export const gqlPessoal = gql`
  {
    usuario {
      cpf
      customerId
      estadoCivil {
        codigo
      }
      nascimento
      nome
      nomeMae
      sexo {
        codigo
      }
      enderecos {
        bairro
        cep
        cidade
        codigo
        complemento
        correspondencia
        logradouro
        numero
        uf
      }
    }
    contatos {
      celular {
        ddd
        id
        numero
        tipoTelefone
      }
      email {
        id
        enderecoEletronico
        # additionDate - "20180521000000000" chega nesse formato pelo middlaew
        newsletter
      }
      # identificationNumber: null
      nome {
        nomeCompleto
        id
        emiteNotaFiscal
      }
      # site: null
      # telefone: null
      telefone {
        id
        ddd
        numero
        tipoTelefone
        # ramal
      }
    }
    representantes {
      nome
      cpf
      nacionalidade
      tipoRelacao {
        id
        longDesc
        nemotecnico
        shortDesc
      }
      email
      celular
      dataInicio # 2002-01-02 vindo pelo middlware neste formato
      dataFim # 2078-12-31 vindo pelo middlware neste formato
    }
  }
`

export const gqlPap = gql`
  query pap($documento: String!) {
    pap(documento: $documento) {
      adesaoPap
      documento
    }
  }
`

export const gqlEditarContato = gql`
  mutation editarContato(
    $email: String!
    $newsletter: Boolean
    $telefoneDDD: Int
    $telefoneNumero: Int
    $telefoneTipo: Int
    $celularDDD: Int
    $celularNumero: Int
    $celularTipo: Int
    $emiteNotaFiscal: Boolean
    $nome: String
  ) {
    editarContato(
      contato: {
        nome: { emiteNotaFiscal: $emiteNotaFiscal, nomeCompleto: $nome }
        email: { enderecoEletronico: $email, newsletter: $newsletter }
        telefone: { ddd: $telefoneDDD, numero: $telefoneNumero, tipoTelefone: $telefoneTipo }
        celular: { ddd: $celularDDD, numero: $celularNumero, tipoTelefone: $celularTipo }
      }
    ) {
      email {
        enderecoEletronico
        newsletter
      }
      telefone {
        ddd
        numero
        tipoTelefone
      }
      celular {
        ddd
        numero
        tipoTelefone
      }
      nome {
        nomeCompleto
        id
        emiteNotaFiscal
      }
    }
  }
`

// TODO No objeto de contato algumas informações não foram encontradas no graphql
// identificationNumber
// nome.emiteNotaFiscal
// site

const profileMap = {
  cpf: 'cpf',
  'estado-civil': 'estadoCivil',
  nascimento: 'nascimento',
  nome: 'nome',
  customerId: 'customerId',
  'nome-mae': 'nomeMae',
  // 'pap': não achamos na query
  sexo: 'sexo'
}

export const transformPessoal = src => {
  const { contatos: contato, usuario } = src.data
  const { pap } = src
  const enderecos = usuario.enderecos
  const profile = transformObject(usuario, profileMap)
  profile.sexo.codigo = profile.sexo.codigo.toString()
  profile['estado-civil'].codigo = profile['estado-civil'].codigo.toString()
  profile.pap = pap.adesaoPap
  const representantes = src.data.representantes
  return removeTypename({
    contato,
    enderecos,
    profile,
    representantes
  })
}

export const gqlEditarUsuarioFisico = gql`
  mutation editarUsuarioFisico(
    $sexo: String!
    $estadoCivil: String!
    $adesaoPap: Boolean
    $documento: String
  ) {
    editarUsuarioFisico(
      usuarioFisico: {
        sexo: { codigo: $sexo }
        estadoCivil: { codigo: $estadoCivil }
        tipo: "pessoa-fisica"
      }
    ) {
      cpf
      sexo {
        codigo
      }
    }
    mudarSituacaoPap(adesaoPap: $adesaoPap, documento: $documento) {
      adesaoPap
      documento
    }
  }
`
