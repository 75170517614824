import React, { useState } from 'react'
import { Box, Flex } from 'grid-styled'
import ClickOut from 'react-click-out'
import { orderBy } from 'lodash'
import moment from 'moment'

import { ListContainer, ListItem, DropdownBox } from './styles'
import OrderRequestItem from './OrderRequestItem'
import Dropdown from './Dropdown'
import DropdownList from './DropdownList'
import { statusFilter } from './DropdownList/helpers'
import { orderStatus } from './data'

function OrdersRequestList(props) {
  const [isOpenStatus, setIsOpenStatus] = useState(false)
  const [selectedStatus, setSelectedStatus] = useState('Todos')
  const [isOpenDate, setIsOpenDate] = useState(false)

  const toggleDropdownStatus = () => {
    setIsOpenStatus(!isOpenStatus)
    window.dataLayer.push({
      event: 'event',
      eventAction: 'ordenar:status-do-pedido',
      eventCategory: 'veloe:area-logado:meus-pedidos',
      eventLabel: 'ordernacao-status-pedido'
    })
  }

  const closeDropdownStatus = () => {
    setIsOpenStatus(false)
  }

  const toggleDropdownDate = () => {
    setIsOpenDate(!isOpenDate)
    window.dataLayer.push({
      event: 'event',
      eventAction: 'ordenar:data-do-pedido',
      eventCategory: 'veloe:area-logado:meus-pedidos',
      eventLabel: 'ordenacao-data-pedido'
    })
  }

  const sortDate = (ordersSortDate, isOpenDataSortDate) => {
    const ordered = orderBy(ordersSortDate, o => moment(o.date, 'DD/MM/YYYY').format('YYYYMMDD'))
    return isOpenDataSortDate ? ordered : ordered.reverse()
  }

  const selectStatus = status => {
    setSelectedStatus(status)
    closeDropdownStatus()
  }

  const { orders = [], accountId, type, history } = props

  return (
    <ListContainer>
      <ListItem>
        <Flex width={1} direction={['column', 'row']}>
          <Box width={[1 / 12]} />
          <Box width={[2 / 12]}>Nº do pedido</Box>
          <Box width={[2 / 12]}>
            <Dropdown
              isOpen={isOpenDate}
              categoryLabel="DATA DO PEDIDO"
              dropdownLabel={isOpenDate ? 'Mais antigos' : 'Mais recentes'}
              onClick={toggleDropdownDate}
            />
          </Box>
          <DropdownBox width={[2 / 12]}>
            <Dropdown
              isOpen={isOpenStatus}
              categoryLabel="STATUS"
              dropdownLabel={selectedStatus}
              onClick={toggleDropdownStatus}
            />
            {isOpenStatus && (
              <ClickOut callback={closeDropdownStatus}>
                <DropdownList options={orderStatus} isOpen={isOpenStatus} onSelect={selectStatus} />
              </ClickOut>
            )}
          </DropdownBox>
          <Box width={[5 / 12]} />
        </Flex>
      </ListItem>
      {statusFilter(selectedStatus, sortDate(orders, isOpenDate)).map((order, index) => (
        <OrderRequestItem
          order={order}
          key={index}
          accountId={accountId}
          type={type}
          history={history}
        />
      ))}
    </ListContainer>
  )
}

export default OrdersRequestList
