import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Field, reduxForm, getFormValues } from 'redux-form'
import { Flex, Box } from 'grid-styled'
import Button from '../../elements/Button'
import rem from '../../../styles/tools/rem'
import Hide from '../../../styles/utilities/Hide'
import Icon from '../../elements/Icon/Icon'
import { renderTextInput, renderCvcInput } from '../../../utils/renderInputs'
import { validateCreditCardFlag } from './validators'
import { required, validateCVV, validateAmexCVV } from '../../../utils/validators'
import { utils } from 'alelo-logic'

import {
  Buttons,
  Separator,
  WrapperPayment,
  BoxCreditCard,
  ButtonBack,
  DebitCardNotice
} from './style'
import Radio from '../../elements/Radio'
// Utils
import { errorMessage } from 'utils/dictionary'
import { selectUserCurrentAccount } from 'utils/selectors'

const renderRadio = ({ input, label, negative, checked, onClick }) => (
  <Radio {...input} label={label} negative={negative} checked={checked} onClick={onClick} />
)

const validate = values => {
  const errors = {}

  if (!values.cardNumber) {
    errors.cardNumber = errorMessage.required
  }

  if (!values.yourName) {
    errors.yourName = errorMessage.required
  }

  if (!values.securityCode) {
    errors.securityCode = errorMessage.required
  }

  if (!values.validad) {
    errors.validad = errorMessage.required
  }

  return errors
}

function CreditCardFields({ formValues }) {
  const creditCardFlag = validateCreditCardFlag((formValues && formValues.cardNumber) || null)
  const validateSecurityCode =
    creditCardFlag === 'amex' ? [required, validateAmexCVV] : [required, validateCVV]

  return (
    <BoxCreditCard>
      <Flex direction="column">
        <Box width={[1, 358]} mb={[17, 20]}>
          <Field
            component={renderTextInput}
            label="número do cartāo"
            name="cardNumber"
            validate={utils.creditCardValidate}
            normalize={utils.creditCardMask}
            isRequired
            negative
            placeholder="XXXX XXXX XXXX XXXX"
          />
        </Box>
        <Box width={[1, 358]} mb={[17, 20]}>
          <Field
            component={renderTextInput}
            label="seu nome"
            name="yourName"
            isRequired
            negative
            placeholder="Nome do portador do cartão"
          />
        </Box>
        <Flex direction={['column', 'row']} width={[1, 358]} justify="space-between">
          <Box mb={[17, 20]} mr={33} width={[1, 170]}>
            <Field
              component={renderTextInput}
              validate={utils.creditCardValidadValidate}
              normalize={utils.creditCardValidadMask}
              label="validade"
              name="validad"
              isRequired
              negative
              placeholder="mm/aa"
            />
          </Box>
          <Box mb={[17, 37]} width={[1, 170]}>
            <Field
              component={renderCvcInput}
              label="cód. de segurança"
              validate={validateSecurityCode}
              limitDigit={creditCardFlag === 'amex' ? 4 : 3}
              name="securityCode"
              isRequired
              negative
              placeholder="CVV"
            />
          </Box>
        </Flex>
      </Flex>
    </BoxCreditCard>
  )
}

const paymentTypesOptions = {
  formas: [
    {
      descricao: 'Cartão de Crédito',
      forma: 'CAJA'
    },
    {
      descricao: 'Cartão de Débito',
      forma: 'CARTAO_DEBITO'
    }
  ]
}

class PaymentForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isCreditCard: false,
      cardFinal: 'XXXX',
      isTrack: false,
      isDebitCard: false
    }
  }

  onSubmit = (evt, values) => {
    const { isCreditCard, isDebitCard } = this.state

    if (values) {
      if (isCreditCard) {
        values.paymentMethod = 'Adicionar um novo cartão'
        values.tipoPagamento = isDebitCard ? 'CARTAO_DEBITO' : 'CAJA'
      } else {
        values.paymentMethod = ''
      }
    }
    evt.preventDefault()
    this.props.onSubmit(evt, values)
  }

  componentDidMount() {
    const { currAccount } = this.props
    window.checkedPaymentType = 'CAJA'
    if (currAccount && !currAccount.paymentType) {
      this.setState({
        isCreditCard: !currAccount.paymentType
      })
    }
  }

  componentDidUpdate(prevProps) {
    const { currAccount } = this.props
    if (prevProps.currAccount !== currAccount && currAccount && !currAccount.paymentType) {
      this.setState({
        isCreditCard: !currAccount.paymentType
      })
    }
  }

  render() {
    const {
      creditCardFinaly,
      paymentInfo,
      handleSubmit,
      values,
      accountPlanId,
      loading,
      id,
      location,
      accountPlans,
      currAccount,
      backButton,
      ...props
    } = this.props

    this.isTrack = location.search.includes('path=track')
    this.isRecharge = location.search.includes('path=recharge')

    const label = !this.isTrack && !this.isRecharge ? 'Adicionar um novo cartão' : ''

    const checked = this.state.isCreditCard || this.isTrack || this.isRecharge

    var parceiroComercial =
      accountPlans.plans && accountPlans.plans[0] && accountPlans.plans[0].nomeParceiroComercial
        ? accountPlans.plans[0].nomeParceiroComercial
        : ''
    if (values) {
      values.tipoPagamento = values ? window.checkedPaymentType : ''
    }

    let isParceiroCartaoDebito = false

    if (parceiroComercial) {
      isParceiroCartaoDebito = !!(
        parceiroComercial === 'Next' || parceiroComercial.toUpperCase().includes('BTG')
      )
    }

    return (
      <WrapperPayment>
        <form onSubmit={evt => handleSubmit(this.onSubmit.bind(this.onSubmit(evt, values)))}>
          {currAccount && currAccount.paymentType && (
            <Flex direction={['column', 'row']}>
              <Box mb={[39, 28]} width={[1, 458]}>
                <Field
                  component={renderRadio}
                  label={label}
                  name="paymentMethod"
                  type="radio"
                  value="Adicionar um novo cartão"
                  negative={!this.isTrack && !this.isRecharge}
                  disabled={this.isTrack || this.isRecharge}
                  checked={checked}
                  onClick={() => this.setState({ isCreditCard: !this.state.isCreditCard })}
                />
              </Box>
            </Flex>
          )}
          {checked && isParceiroCartaoDebito && (
            <Flex direction={['column', 'row']}>
              {paymentTypesOptions &&
                paymentTypesOptions.formas.map(paymentType => (
                  <Box key={paymentType.forma} mb={[39, 28]} width={[1, 458]}>
                    <Field
                      component={renderRadio}
                      label={paymentType.descricao}
                      name="tipoPagamento"
                      type="radio"
                      value={paymentType.forma}
                      checked={window.checkedPaymentType === paymentType.forma}
                      negative={true}
                      onClick={() => (window.checkedPaymentType = paymentType.forma)}
                    />
                  </Box>
                ))}
            </Flex>
          )}
          {checked &&
            isParceiroCartaoDebito &&
            window.checkedPaymentType &&
            window.checkedPaymentType === 'CARTAO_DEBITO' && (
              <Flex direction="column">
                <DebitCardNotice>
                  <img src={require(`../../../static/atencao.png`)} alt="" />
                  Insira apenas cartões {parceiroComercial}
                </DebitCardNotice>
              </Flex>
            )}

          <Hide at="large" width={1}>
            {!this.state.isCreditCard && <Separator />}
          </Hide>
          <Flex direction="column">
            {(this.state.isCreditCard && <CreditCardFields formValues={values} />) ||
              (this.isTrack && <CreditCardFields formValues={values} />) ||
              (this.isRecharge && <CreditCardFields formValues={values} />)}{' '}
          </Flex>
          <Buttons>
            <ButtonBack to={`/minha-veloe/${id}/${backButton}`}>
              <Icon
                icon="back-arrow"
                width="10px"
                height="15px"
                viewBox="0 0 10 15"
                hidden={this.isTrack}
              />
              <span hidden={this.isTrack}>Voltar</span>
            </ButtonBack>
            <Box>
              <Button
                text={loading ? 'Carregando' : 'Continuar para concluir'}
                type="submit"
                loading={loading}
                width={rem('220px')}
                disabled={
                  (props.saving || !props.dirty || !props.valid || loading) &&
                  (this.state.isCreditCard || this.isTrack || this.isRecharge)
                  // || ((props.saving || !props.dirty || !props.valid || loading) && this.isTrack)
                }
                buttonType="transparent"
              />
            </Box>
          </Buttons>
        </form>
      </WrapperPayment>
    )
  }
}

const mapStateToProps = (state, reducer) => {
  return {
    values: getFormValues('PaymentForm')(state),
    // isCreditCard: state.isCreditCard,
    paymentInfo: state.reducer.recharge.paymentInfo,
    accountPlans: state.reducer.accountPlans,
    currAccount: selectUserCurrentAccount(state.reducer)
  }
}

const Container = connect(mapStateToProps, null)

const Form = reduxForm({
  form: 'PaymentForm',
  validate
})(Container(PaymentForm))

export default Form
