import { gqlQuery, gqlMutate } from './common/apolloClient'
import {
  gqlPap,
  gqlPessoal,
  transformPessoal,
  gqlEditarUsuarioFisico,
  gqlEditarContato
} from './queries/pessoal'
import { put } from './common/axiosClient'
import { CHANGE_PASSWORD } from './common/constants'
import {
  gqlBusiness,
  gqlUpdateBusiness,
  transformBusiness,
  gqlEditarContatoAdministrador
} from './queries/business'
import { gqlUpdateAddress, gqlDeleteAddress } from './queries/address'
import { gqlFirstAccessFacade } from './queries/plans'

export async function updateUserAddress(data) {
  const transformedData = data.map(({ principal, ...rest }) => ({ ...rest }))
  const enderecos = { enderecos: transformedData }
  await gqlMutate(gqlUpdateAddress, enderecos)
}

const getTelefoneAsNumber = value =>
  value ? Number.parseInt(value.toString().replace('-', ''), 10) : null

export async function deleteUserAddress(id) {
  const codigo = { id }
  await gqlMutate(gqlDeleteAddress, codigo)
}

export async function getUser() {
  const pessoalQuery = await gqlQuery(gqlPessoal)
  const documento = pessoalQuery.data.usuario.cpf
  const papQuery = await gqlQuery(gqlPap, { documento })
  return transformPessoal({ ...pessoalQuery, pap: papQuery.data.pap })
}

export async function firstAccess(cpf) {
  console.log(cpf)
  const resp = await gqlQuery(gqlFirstAccessFacade, { login: cpf })

  let customerId = ''

  if (resp) {
    customerId = resp.data.primeiroAcessoFacade.customerId
  }

  sessionStorage.setItem('customerId', customerId)

  return customerId
}

export function updateUserPassword(data) {
  const transformedData = {
    antiga: data.current_password,
    nova: data.new_password
  }
  return put(CHANGE_PASSWORD, transformedData, { 'content-type': 'application/json' })
}

export async function getBusinessUser() {
  const businessQuery = await gqlQuery(gqlBusiness)
  return transformBusiness(businessQuery)
}

export async function updateBusinessUserProfile(data) {
  const { nomeFantasia, faturamentoMedioMensal, cnaePrincipal } = data

  const response = await gqlMutate(gqlUpdateBusiness, {
    nomeFantasia,
    faturamentoMedioMensal,
    cnaePrincipal,
    tipoUsuario: 'pessoa-juridica'
  })

  return response
}

export async function updateUserProfile(data) {
  const { sexo, 'estado-civil': estadoCivil } = data.user
  await gqlMutate(gqlEditarUsuarioFisico, {
    sexo: sexo.codigo,
    estadoCivil: estadoCivil.codigo,
    ...data.pap
  })
}

export async function updateBusinessUserContact(data) {
  const variables = {
    nome: data.nome && data.nome.nomeCompleto,
    email: data.email.enderecoEletronico,
    site: data.site.enderecoEletronico,
    newsletter: data.email.newsletter,
    telefoneDDD: Number.parseInt(data.telefone.ddd, 10),
    telefoneNumero: getTelefoneAsNumber(data.telefone.numero),
    telefoneTipo: Number.parseInt(data.telefone.tipoTelefone, 10),
    celularDDD: Number.parseInt(data.celular.ddd, 10),
    celularNumero: getTelefoneAsNumber(data.celular.numero)
  }
  await gqlMutate(gqlEditarContatoAdministrador, variables)
}

export async function updateUserContact(data) {
  const variables = {
    email: data.email.enderecoEletronico,
    newsletter: !!data.email.newsletter,
    telefoneDDD: data.telefone && data.telefone.ddd ? Number.parseInt(data.telefone.ddd, 10) : null,
    telefoneNumero: getTelefoneAsNumber(data.telefone && data.telefone.numero),
    telefoneTipo: 42,
    celularDDD: data.celular && data.celular.ddd ? Number.parseInt(data.celular.ddd, 10) : null,
    celularNumero: getTelefoneAsNumber(data.celular && data.celular.numero, 10),
    nome: data.nome.nomeCompleto,
    emiteNotaFiscal: !!data.nome.emiteNotaFiscal
  }
  await gqlMutate(gqlEditarContato, variables)
}

export async function updateBusinessUserAddress(data) {
  const transformedData = data.map(({ principal, ...rest }) => ({ ...rest }))
  const enderecos = { enderecos: transformedData }
  await gqlMutate(gqlUpdateAddress, enderecos)
}

export async function deleteBusinessUserAddress(id) {
  const codigo = { id }
  await gqlMutate(gqlDeleteAddress, codigo)
}
