export const paymentMethodDescription = {
  DEBITO_EM_CONTA: 'Débito em Conta',
  CARTAO_DE_CREDITO: 'Cartão de Crédito',
  money: 'Dinheiro',
  BOLETO: 'Boleto',
  partner: 'Parceiros',
  CARTAO_DEBITO: 'Cartão de Débito'
}

/**
 * Return Payment Method based on status
 * @param {*} method
 */
export const formatMethod = (status, method) => {
  switch (status) {
    case 'PENDENTE':
      return 'Aguardando pagamento'
    case 'ATRASO':
      return 'Regularize sua situação'
    case 'COMPENSADO':
      return paymentMethodDescription[method]
    default:
      return 'Em aberto'
  }
}
