import React from 'react'
import PropTypes from 'prop-types'

import { converText } from '../../../utils/textSlug'

import { DropdownListContainer, DropdownListItem, DropdowLink, Title, Description } from './styles'

function DropdownList({ options, isOpen, onClose, userCancelled }) {
  function checkUserStatus(option) {
    return option.cancelledMenu ? option.link : '#'
  }

  const closeDropdown = onClick => {
    if (onClose) {
      onClose()
    }

    if (onClick) {
      onClick()
    }
  }

  const gtmLabel = 'item:'

  return (
    <DropdownListContainer isOpen={isOpen}>
      {options.map((option, index) => (
        <DropdownListItem
          key={index}
          userCancelled={userCancelled}
          onClick={() => closeDropdown(option.onClick)}
          cancelledMenuItem={option.cancelledMenu}
        >
          {option.onClick ? (
            <span>
              {option.title && <Title>{option.title}</Title>}
              {option.description && (
                <Description
                  className="gtm-element-event"
                  data-gtm-event-category="veloe:area-logado:geral"
                  data-gtm-event-action="clique:menu-principal"
                  data-gtm-event-label={gtmLabel + converText(option.description)}
                >
                  {option.description}
                </Description>
              )}
            </span>
          ) : (
            <DropdowLink
              to={userCancelled ? checkUserStatus(option) : option.link}
              className="gtm-link-event"
              data-gtm-event-category="veloe:area-logado:geral"
              data-gtm-event-action="clique:menu-principal"
              data-gtm-event-label={gtmLabel + converText(option.description)}
            >
              {option.title && <Title>{option.title}</Title>}
              {option.description && <Description>{option.description}</Description>}
            </DropdowLink>
          )}
        </DropdownListItem>
      ))}
    </DropdownListContainer>
  )
}

DropdownList.defaultProps = {
  options: [],
  isOpen: false,
  title: null,
  description: null,
  userCancelled: false
}

DropdownList.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      link: PropTypes.string,
      title: PropTypes.string,
      description: PropTypes.string,
      cancelledMenu: PropTypes.bool
    })
  ),
  isOpen: PropTypes.bool,
  userCancelled: PropTypes.bool
}

export default DropdownList
