import styled from 'styled-components'

import rem from '../../../styles/tools/rem'
import em from '../../../styles/tools/em'
import media from '../../../styles/tools/media'

import { txt16OpenSans, title40GalanoBold } from '../../../styles/settings/types'

import { darkBlue, white, tealish } from '../../../styles/settings/colors'

export const Wrapper = styled.div`
  background-color: ${tealish};
  min-height: 100vh;

  ${media.small`
        height: ${rem('70px')};
        background: ${white};
    `};
`

export const Title = styled.h1`
  ${title40GalanoBold} letter-spacing: ${em('0.4px')};
  color: ${white};
  font-size: ${rem('48px')};
  margin: ${rem('40px')} 0;
  max-width: ${rem('615px')};
`

export const Paragraph = styled.p`
  ${txt16OpenSans} color: ${darkBlue};
  max-width: ${rem('615px')};
  margin-bottom: ${rem('40px')};
`
