import React from 'react'
import { Container, ContainerContent, ButtonContainer, Title, Subtitle } from './styles'
import handphone from 'static/hand-phone.svg'

const {
  androidAppUrl,
  androidAppImgAlt,
  androidAppImgSrc,
  iosAppUrl,
  iosAppImgAlt,
  iosAppImgSrc
} = require('./data.json')

const Advertisement = () => (
  <Container>
    <img src={handphone} className="WarningImage-phone" alt="phone" />
    <ContainerContent>
      <Title>
        Baixe nosso app. <br />
        Agora mesmo ;)
      </Title>
      <Subtitle>
        Tenha acesso aos nossos serviços e gerencie a sua conta pelo celular de forma simples e
        descomplicada.
        <br />
        <br />
        Use o seu tempo com o que realmente importa!
      </Subtitle>
      <ButtonContainer>
        <a
          href={androidAppUrl}
          target="_blank"
          onClick={() => {
            window.dataLayer = window.dataLayer || []
            window.dataLayer.push({
              event: 'event',
              eventCategory: 'veloe:area-logado:resumo',
              eventAction: 'clique:banner:store',
              eventLabel: 'store:google'
            })
          }}
        >
          <img alt={androidAppImgAlt} src={androidAppImgSrc} />
        </a>
        <a
          href={iosAppUrl}
          target="_blank"
          onClick={() => {
            window.dataLayer = window.dataLayer || []
            window.dataLayer.push({
              event: 'event',
              eventCategory: 'veloe:area-logado:resumo',
              eventAction: 'clique:banner:store',
              eventLabel: 'store:apple'
            })
          }}
        >
          <img alt={iosAppImgAlt} src={iosAppImgSrc} />
        </a>
      </ButtonContainer>
    </ContainerContent>
  </Container>
)

export default Advertisement
