import React from 'react'
import { Box } from 'grid-styled'

import { connect } from 'react-redux'
import Helmet from 'react-helmet'

import { actions } from 'alelo-logic'
import { showModal } from '../../modules/ModalRoot/actions'
import EditAddressForm from '../../modules/EditAddressForm'
import { CONFIRM_UPDATE } from '../../../constants/ModalTypes'
import { startSaving, stopSaving } from '../../modules/form-state-actions'
import addressFactory from './factory'
import image from '../../../static/confirmation.png'

import ImersiveHeader from '../../elements/ImersiveHeader'
import Content from '../../../styles/objects/Content'
import Icon from '../../elements/Icon/Icon'

import {
  Title,
  Paragraph,
  WrapperAddress,
  Cep,
  CepText,
  ActualAddress,
  NewAddress,
  NewAddressText,
  CheckBoxCep,
  ButtonContainer,
  ShippingTitle,
  ShippingText,
  BoxCep,
  BoxShipping,
  ShippingIconText,
  WrapperSelectNewAddress,
  WrapperFormNewAddress
} from './styles'

import Button from '../../elements/Button'

const { titleModal, title, paragraph, shippingTitle, newAddressText, cep } = require('./data.json')

const { fetchShippingUpdateAddress, fetchGetShippingAmount } = actions

class FirstAccessIdentifierNotArrived extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      addressSelected: 'actual',
      addressMock: {
        cep: '12100-290',
        logradouro: 'Alameda Xingu',
        numero: '512',
        complemento: '21º andar Tamboré',
        bairro: 'Barueri',
        uf: 'SP',
        cidade: 'São Paulo',
        correspondencia: '',
        principal: true,
        codigo: '1'
      }
    }
  }
  componentDidMount() {
    this.getShippingAmount()
  }

  getShippingAmount() {
    const { cep } = this.state
    this.props.handleShippingAmount(cep)
  }

  changeCheckBox = evt => {
    this.setState({ addressSelected: evt.target.value })
  }

  render() {
    const { addressSelected, addressMock } = this.state
    const { address, handleSave, handleDelete, saving, match, history } = this.props
    const initialValues = addressFactory.toForm(address)
    return (
      <Box>
        <Helmet title="Minha Veloe - Extravio de identificador" />
        <div>
          <ImersiveHeader text="Acompanhar pedido" back={history.goBack} />
        </div>

        <Content>
          <Title>{title}</Title>
          <Paragraph>{paragraph}</Paragraph>
          <WrapperAddress>
            <ActualAddress focus={addressSelected === 'actual'}>
              <CheckBoxCep
                type="radio"
                value="actual"
                checked={addressSelected === 'actual'}
                onChange={e => this.changeCheckBox(e)}
              />
              <BoxCep>
                <Cep>
                  {cep} {addressMock.cep}
                </Cep>
                <CepText>
                  {addressMock.logradouro} {addressMock.numero}, {addressMock.complemento}{' '}
                  {addressMock.bairro} {addressMock.estado} {addressMock.cidade}
                </CepText>
              </BoxCep>
              {addressSelected === 'actual' ? (
                <BoxShipping>
                  <ShippingTitle>{shippingTitle}</ShippingTitle>
                  <ShippingText>
                    <Icon
                      icon="checkGreen"
                      svgStyle="height: 16px; width: 16px"
                      viewBox="0 0 24 24"
                    />
                    <ShippingIconText>Grátis</ShippingIconText>
                  </ShippingText>
                </BoxShipping>
              ) : null}
            </ActualAddress>
            <NewAddress focus={addressSelected === 'new'}>
              <WrapperSelectNewAddress>
                <CheckBoxCep
                  type="radio"
                  value="new"
                  checked={addressSelected === 'new'}
                  onChange={e => this.changeCheckBox(e)}
                />
                <NewAddressText>{newAddressText}</NewAddressText>
              </WrapperSelectNewAddress>
              <WrapperFormNewAddress>
                {addressSelected === 'new' ? (
                  <EditAddressForm
                    initialValues={{ address: initialValues }}
                    onSubmit={values => handleSave(addressFactory.toAPI(values.address))}
                    onDelete={handleDelete}
                    length={address ? address.length : 0}
                    saving={saving}
                    match={match}
                  />
                ) : null}
              </WrapperFormNewAddress>
            </NewAddress>
            {addressSelected !== 'new' ? (
              <ButtonContainer>
                <Button
                  text="Reenviar Identificador"
                  onClick={() => handleSave(addressFactory.toAPI(address))}
                />
              </ButtonContainer>
            ) : null}
          </WrapperAddress>
        </Content>
      </Box>
    )
  }
}

const mapStateToProps = state => ({
  address: state.reducer.user.enderecos,
  saving: state.reducer.formState.saving
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  initFormState: () => dispatch(stopSaving()),
  handleSave: address => {
    dispatch(startSaving())

    dispatch(fetchShippingUpdateAddress(address))
      .then(resp => {
        if (resp && resp.status) {
          ownProps.history.push('/primeiro-acesso/novo-identificador-sucesso')
        } else {
          dispatch(showModal(CONFIRM_UPDATE, { modalTitle: titleModal, image }))
        }
      })
      .catch(e => {
        dispatch(showModal(CONFIRM_UPDATE, { modalTitle: titleModal, image }))
        console.log('Erro ao atualizar o endereço', e)
        dispatch(stopSaving())
      })
  },
  handleShippingAmount: zipcode => dispatch(fetchGetShippingAmount(zipcode))
})

export default connect(mapStateToProps, mapDispatchToProps)(FirstAccessIdentifierNotArrived)
