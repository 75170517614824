import styled from 'styled-components'

import { darkGrey, offWhite, posYellow, posGreyInfo } from '../../../styles/settings/colors'
import media from '../../../styles/tools/media'
import rem from '../../../styles/tools/rem'

const textStyles = `
    font-family: OpenSans;
    font-size: ${rem('12px')};
    letter-spacing: ${rem('1px')};
    color: ${darkGrey};
    font-weight: 200;
    text-transform: uppercase;
`

export const SpentBalance = styled.div`
  font-family: OpenSans;
  font-size: ${rem('12px')};
  color: ${posYellow};
  span {
    font-family: Galano;
    font-size: ${rem('12px')};
    font-weight: bold;
    color: ${posYellow};
  }
`

export const Limit = styled.div`
  font-family: OpenSans;
  font-size: ${rem('12px')};
  color: ${posGreyInfo};
  span {
    font-family: Galano;
    font-size: ${rem('12px')};
    font-weight: bold;
    color: ${posGreyInfo};
  }
`

export const BarInfoContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  div {
    padding: ${rem('2px')};
  }
`

export const Title = styled.h2`
  ${textStyles};

  margin-top: 0;
`

export const Usage = styled.p`
  ${textStyles};

  margin-top: 0;
  display: flex;
  justify-content: space-between;

  span {
    font-family: OpenSans;
    font-size: ${rem('14px')};
    font-weight: 400;
    line-height: 1.57;
    color: ${darkGrey};
    text-transform: none;
    margin-top: ${rem('7px')};

    :first-child {
      font-family: Galano;
      font-size: ${rem('18px')};
      font-weight: bold;
    }
  }
`

export const Text = styled.p`
  ${textStyles};
`

export const IndicatorList = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0;
  margin: 0;

  ${media.large`
        display: flex;
        justify-content: space-between;
        margin-top: ${rem('12px')};
    `};
`

export const IndicatorItem = styled.div`
  display: flex;
`
export const Separator = styled.hr`
  background-color: ${offWhite};
  border: 0;
  height: ${rem('1px')};
  margin: ${rem('10px')} 0;
`
