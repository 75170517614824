const contactFactory = {
  toAPI: data =>
    data && {
      nome: {
        nomeCompleto: data.name
      },
      site: {
        enderecoEletronico: data.site
      },
      email: {
        enderecoEletronico: data.email,
        newsletter: data.optInPromotions
      },
      telefone: {
        ddd: data.telephoneddd,
        numero: data.telephone,
        tipoTelefone: data.telephoneType,
        ramal: data.line
      },
      celular: {
        ddd: data.cellphoneddd,
        numero: data.cellphone
      }
    },
  toForm: data =>
    data && {
      name: data.nome && data.nome.nomeCompleto,
      email: data.email && data.email.enderecoEletronico,
      telephoneddd: data.telefone && data.telefone.ddd,
      telephone: data.telefone && data.telefone.numero,
      line: data.telefone && data.telefone.ramal,
      telephoneType: data.telefone && data.telefone.tipoTelefone,
      cellphoneddd: data.celular && data.celular.ddd,
      cellphone: data.celular && data.celular.numero,
      optInPromotions: data.email && data.email.newsletter,
      site: data.site && data.site.enderecoEletronico
    }
}

export default contactFactory
