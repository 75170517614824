import styled from 'styled-components'

import { offWhite, darkBlue } from 'styles/settings/colors'
import rem from 'styles/tools/rem'

export const Separator = styled.hr`
  background-color: ${offWhite};
  border: 0;
  height: ${rem('1px')};
  margin: ${rem('20px')} 0;
`

export const WarningContent = styled.div`
  color: ${darkBlue};
  font-family: OpenSans;
  font-size: ${rem('14px')};
`

export const WarningSubtitle = styled.div`
  margin-bottom: ${rem('4px')};
  font-size: ${rem('16px')};
  font-weight: 600;
`

export const WarningDisclaimer = styled.div`
  margin-top: ${rem('24px')};
  margin-bottom: ${rem('36px')};
  font-size: ${rem('12px')};
  font-weight: 600;
`
