import React from 'react'
import { Box, Flex } from 'grid-styled'
import { utils } from 'alelo-logic'

import {
  Benefits,
  GroupedSummary,
  Message,
  ResumeContent,
  Subtotal,
  SummaryCategory,
  SummaryItemNumber,
  SummaryItemTitle,
  Total,
  Values
} from './styles'

const {
  benefits,
  consumption,
  currency,
  messageTax,
  messageTax2,
  subtotal,
  total,
  veloeServices
} = require('./data.json')

function parseNumber(number) {
  return number < 10 ? `0${number}` : number
}

function renderItems(item, index) {
  if (item.tipoTransacao) {
    return (
      <Flex align="stretch" justify="space-between" key={index}>
        <Box width={[1, 8 / 12]}>
          <SummaryItemTitle>{item.tipoTransacao}</SummaryItemTitle>
        </Box>
        <Box pl={[0, 20]} width={[1, 1 / 12]}>
          <SummaryItemNumber>{parseNumber(item.quantidade)}</SummaryItemNumber>
        </Box>
        <Box pl={[0, 20]} width={[1, 3 / 12]}>
          <SummaryItemNumber>
            {utils.currencyParser(item.total * -1, false, currency)}
          </SummaryItemNumber>
        </Box>
      </Flex>
    )
  }
}

function renderResume(items) {
  return items.map((item, index) => renderItems(item, index))
}

const InvoiceResume = ({
  benefitsValue,
  dataServices,
  dataConsumption,
  subtotalValue,
  totalValue
}) => {
  return (
    <div>
      {dataServices && dataConsumption && (
        <ResumeContent>
          <GroupedSummary>
            <SummaryCategory>{veloeServices}</SummaryCategory>
            {renderResume(dataServices)}
          </GroupedSummary>
          <GroupedSummary>
            <SummaryCategory>{consumption}</SummaryCategory>
            {renderResume(dataConsumption)}
          </GroupedSummary>
          <Flex align="stretch" justify="space-between">
            <Box pt={6} width={[1, 9 / 12]}>
              <Subtotal>{subtotal}</Subtotal>
              <Benefits>{benefits}</Benefits>
              <Total>{total}</Total>
            </Box>
            <Box pl={[0, 20]} width={[1, 3 / 12]}>
              <Values>
                <Subtotal>{utils.currencyParser(subtotalValue * -1, false, currency)}</Subtotal>
                <Benefits>{utils.currencyParser(benefitsValue, false, currency)}</Benefits>
                <Total>{utils.currencyParser(totalValue * -1, false, currency)}</Total>
              </Values>
            </Box>
          </Flex>
          <Message>{messageTax}</Message>
          <Message marginBottom>{messageTax2}</Message>
        </ResumeContent>
      )}
    </div>
  )
}

export default InvoiceResume
