import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import DashboardIdentifierCard from '../../elements/DashboardIdentifierCard'
import Carousel from '../../elements/Carousel'

import { Title, IdentifierList, Header, Span, Text, DesktopText, MobileText } from './styles'

import { Icon } from '../../elements/Icon'

const { planTypes } = require('./data.json')

const DashboardIdentifierList = ({ accountPlans, match, resume }) => {
  const { plans, activePlanId } = accountPlans

  if (!activePlanId) {
    return null
  }

  const currentPlan = plans.find(plan => String(plan.id) === String(activePlanId))

  function filterIdentifiers(data) {
    return data.filter(identifier => identifier.active && !identifier.blocked)
  }

  function sortIdentifiers(data) {
    const innerData = [...data]
    innerData.sort((current, next) => next.expense - current.expense)
    return innerData
  }

  const filteredIdentifiers = filterIdentifiers(resume.vehicles)
  const identifiers = sortIdentifiers(filteredIdentifiers).slice(0, 3)

  const isSimplified = identifiers.length <= 1
  const showMoreText =
    currentPlan && isSimplified
      ? planTypes[currentPlan.plan.type].showMore.simplified
      : planTypes[currentPlan.plan.type].showMore.complex

  return (
    <section>
      <Header>
        <Title>{currentPlan && planTypes[currentPlan.plan.type].yourIdentifier}</Title>
        <Text
          onClick={() => {
            window.dataLayer.push({
              event: 'event',
              eventAction: `clique:${showMoreText}`,
              eventCategory: 'veloe:area-logado:geral',
              eventLabel: showMoreText
            })
          }}
          to={`/minha-veloe/${match.params.id}/identificadores`}>
          <DesktopText>{showMoreText}</DesktopText>
          <MobileText>Ver mais</MobileText>
          <Span>
            <Icon icon="right-arrow" width="8" height="13" viewBox="0 0 8 13" />
          </Span>
        </Text>
      </Header>
      <IdentifierList>
        <Carousel hasMobileNavigation fullWidth={false}>
          {identifiers.map((identifier, index) => (
            <DashboardIdentifierCard
              key={identifier.id + index}
              order={index + 1}
              isOrdered={identifiers.length > 1}
              description={identifier.description}
              consumptionLabel={currentPlan && planTypes[currentPlan.plan.type].consumption}
              plate={identifier.plate}
              expense={identifier.expense}
            />
          ))}
        </Carousel>
      </IdentifierList>
    </section>
  )
}

const mapStateToProps = ({ reducer }) => ({
  accountPlans: reducer.accountPlans
})

export default connect(mapStateToProps)(withRouter(DashboardIdentifierList))
