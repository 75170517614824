import React from 'react'
import { withRouter } from 'react-router-dom'
import { Flex } from 'grid-styled'
import { utils } from 'alelo-logic'
import Button from '../../elements/Button'
import Hide from '../../../styles/utilities/Hide'

import { Text, CashDisplay, Currency } from './styles'

const { planTypes } = require('./data.json')

function renderTitle(recharge) {
  return planTypes[checkPlanType(recharge)].balance
}

const { currencyParser } = utils

const checkPlanType = isPrePaid => (isPrePaid ? 'pre' : 'pos')

const DashboardCashDisplay = ({ recharge, updateDate, value, match, history }) => (
  <CashDisplay recharge={recharge}>
    <Text>
      <span className="teste">{renderTitle(recharge)}</span>
      {updateDate}
    </Text>

    <Flex display="flex" direction={['column', 'row']} justify="space-between">
      <Currency>
        <span>R$</span> {currencyParser(value)}
      </Currency>
      <Hide at="small">
        <Button
          id="btnManual"
          onClick={() => {
            if (planTypes[checkPlanType(recharge)].buttonAction === 'recarga') {
              window.dataLayer = window.dataLayer || []
              window.dataLayer.push({
                event: 'event',
                eventCategory: 'veloe:area-logado:resumo',
                eventAction: 'clique:botao:fazer-recarga',
                eventLabel: 'fazer-recarga'
              })
            } else {
              window.dataLayer = window.dataLayer || []
              window.dataLayer.push({
                event: 'event',
                eventCategory: 'veloe:area-logado:resumo',
                eventAction: 'clique:botao:ver-fatura',
                eventLabel: 'ver-fatura'
              })
            }

            history.push(
              `/minha-veloe/${match.params.id}/${planTypes[checkPlanType(recharge)].buttonAction}`
            )
          }}
          text={planTypes[checkPlanType(recharge)].buttonLabel}
        />
      </Hide>
    </Flex>
  </CashDisplay>
)

export default withRouter(DashboardCashDisplay)
