export default {
  __html: `<g fill="none" fill-rule="evenodd">
                <g fill="#201A5B" fill-rule="nonzero">
                    <path d="M50.194 83.786c-1.65 0-3.01-1.359-3.01-3.01V61.069c0-1.65 1.36-3.01 3.01-3.01 1.65 0 3.01 1.36 3.01 3.01v19.709c-.097 1.65-1.36 3.01-3.01 3.01z"/>
                    <path d="M70.68 83.98H29.806c-4.078 0-7.476-3.3-7.476-7.475v-26.31c0-1.651 1.36-3.01 3.01-3.01 1.65 0 3.01 1.359 3.01 3.01v26.31c0 .777.68 1.456 1.456 1.456H70.68c.776 0 1.456-.68 1.456-1.456v-26.31c0-1.651 1.36-3.01 3.01-3.01 1.65 0 3.01 1.359 3.01 3.01v26.31c0 4.175-3.399 7.476-7.476 7.476z"/>
                </g>
                <path fill="#201A5B" fill-rule="nonzero" d="M75.049 53.204H25.534a8.909 8.909 0 0 1-8.932-8.932V35.34a8.909 8.909 0 0 1 8.932-8.932h49.515a8.909 8.909 0 0 1 8.932 8.932v8.932a8.909 8.909 0 0 1-8.932 8.932zM25.534 32.33c-1.65 0-3.01 1.36-3.01 3.01v8.932c0 1.65 1.36 3.01 3.01 3.01h49.515c1.65 0 3.01-1.36 3.01-3.01V35.34c0-1.65-1.36-3.01-3.01-3.01H25.534z"/>
                <path fill="#201A5B" fill-rule="nonzero" d="M50.194 53.204c-1.65 0-3.01-1.36-3.01-3.01V29.32c0-1.65 1.36-3.01 3.01-3.01 1.65 0 3.01 1.36 3.01 3.01v20.874c-.097 1.65-1.36 3.01-3.01 3.01z"/>
                <path fill="#201A5B" fill-rule="nonzero" d="M50.194 32.33c-1.65 0-3.01-1.36-3.01-3.01 0-3.883-3.203-7.087-7.087-7.087-3.883 0-7.087 3.204-7.087 7.087 0 1.65-1.36 3.01-3.01 3.01-1.65 0-3.01-1.36-3.01-3.01 0-7.184 5.826-13.01 13.01-13.01s13.01 5.826 13.01 13.01a2.809 2.809 0 0 1-2.816 3.01z"/>
                <path fill="#201A5B" fill-rule="nonzero" d="M70.388 32.427c-1.65 0-3.01-1.359-3.01-3.01 0-3.883-3.203-7.087-7.087-7.087-3.883 0-7.087 3.204-7.087 7.087 0 1.651-1.36 3.01-3.01 3.01-1.65 0-3.01-1.359-3.01-3.01 0-7.184 5.826-13.01 13.01-13.01 7.185 0 13.01 5.826 13.01 13.01.097 1.651-1.262 3.01-2.816 3.01z"/>
                <path d="M.583.583h99.029v99.029H.583z"/>
            </g>`
}
