import React, { Component } from 'react'
import Helmet from 'react-helmet'
import { connect } from 'react-redux'

import { Box, Flex } from 'grid-styled'
import { utils } from 'alelo-logic'

import { fetchReasons, fetchStatement, fetchContestationProtocol } from 'alelo-logic/actions'
import contestationFactory from './factory'

import {
  Bold,
  Close,
  Container,
  Date,
  Details,
  Header,
  Locale,
  LocaleDetail,
  Separator,
  Title,
  Value,
  ValueLarge
} from './style'
import { Icon } from '../../elements/Icon'

import Hide from '../../../styles/utilities/Hide'
import ContestationForm from '../../modules/ContestationForm'

const { title } = require('./data.json')

function getUrlStatement(urlContestation) {
  const split = urlContestation.split('/')
  return `/${split[1]}/${split[2]}/${split[3]}`
}

function renderValue(value) {
  if (value < 0) return `- ${utils.currencyParser(value * -1, false, 'R$ ')}`
  return `${utils.currencyParser(value, false, 'R$ ')}`
}

class Contestation extends Component {
  componentWillMount() {
    const params = this.props.match.params || {}
    this.props.transaction(params.id, params.statementId)
    this.props.reasons()
  }
  render() {
    const handleSend = this.props.handleSend
    const statementId = this.props.match.params.statementId
    const { statement } = this.props

    const reasonsItems = Object.values(this.props.reasonsItems) || []
    const urlStatement = getUrlStatement(this.props.location.pathname)
    return (
      <Container>
        <Flex>
          <Helmet title="Contestation" />
          <Header>
            <Title>{title}</Title>
            <Close to={urlStatement}>
              <Icon height={23} icon="new-close" viewBox="0 0 52 53" />
            </Close>
          </Header>
        </Flex>
        <Separator />
        <Details>
          <Flex justify={'space-between'} align="baseline">
            <Locale>{statement.title}</Locale>
            <ValueLarge>{renderValue(statement.value)}</ValueLarge>
          </Flex>
          <LocaleDetail>{statement.local}</LocaleDetail>
          <Flex justify={['space-between', 'flex-start']}>
            <Date>
              <Bold>Utilizado em </Bold>
              {utils.ISODateFormat(statement.date)}
            </Date>
            <Date>
              <Bold>Hora </Bold>
              {utils.ISODateFormat(statement.date, 'HH:mm')}
            </Date>
          </Flex>
          <Hide at="large">
            <Box mb={24}>
              <Value>{renderValue(statement.value)}</Value>
            </Box>
          </Hide>
        </Details>
        <Separator />
        <Box width={[1, 500]} mt={15}>
          <ContestationForm
            data={reasonsItems[0]}
            onSubmit={values => handleSend(contestationFactory.toAPI(values, statementId))}
          />
        </Box>
      </Container>
    )
  }
}

const mapStateToProps = ({ reducer }) => ({
  reasonsItems: reducer.reasons,
  statement: reducer.statement,
  protocol: reducer.protocol
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  handleSend: contestation =>
    dispatch(fetchContestationProtocol(contestation)).then(() => {
      ownProps.history.push(`${ownProps.location.pathname}/confirmacao`)
    }),
  reasons: () => dispatch(fetchReasons()),
  transaction: (planId, statementId) => dispatch(fetchStatement(planId, statementId))
})

export default connect(mapStateToProps, mapDispatchToProps)(Contestation)
