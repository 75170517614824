import React from 'react'
import { Box, Flex } from 'grid-styled'

import buddies from '../../../static/modalBuddies.png'
import Button from '../../elements/Button'
import Hide from '../../../styles/utilities/Hide'

import {
  Content,
  Title,
  Text,
  SmallText,
  ProtocolTitle,
  Protocol,
  SavedDate,
  Wrapper
} from './styles'

const { title, description, protocolTitle, generationDate, savedDate } = require('./data.json')

function CanceledAccount() {
  return (
    <section>
      <Wrapper>
        <Content>
          <Flex align="center" wrap justify="space-between">
            <Box width={[1, 7 / 12]}>
              <section>
                <Title>{title}</Title>
                <Text>{description}</Text>
              </section>
              <section>
                <ProtocolTitle>{protocolTitle}</ProtocolTitle>
                <Protocol>12340298091284091284</Protocol>

                <SmallText>{generationDate}</SmallText>

                <Hide at="large" mt={'10%'} mx="auto" width={[6 / 12]}>
                  <img alt="" src={buddies} width="100%" />
                </Hide>

                <Box mt={60}>
                  <Box mb={[8, 24]}>
                    <Button buttonType="transparent" text="Reativar conta" />
                  </Box>
                  <SavedDate>{savedDate}</SavedDate>
                </Box>
              </section>
            </Box>
            <Hide at="small" mt={'10%'} width={[1, 'auto']}>
              <img alt="" src={buddies} width="100%" />
            </Hide>
          </Flex>
        </Content>
      </Wrapper>
    </section>
  )
}

export default CanceledAccount
