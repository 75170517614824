import styled from 'styled-components'

import {
  title14Galano,
  title18Galano,
  title24GalanoBold,
  title32GalanoBold,
  txt12OpenSans,
  txt12OpenSansLight,
  txt16OpenSans,
  txt24OpenSansNormal
} from '../../../styles/settings/types'

import { green, white } from '../../../styles/settings/colors'

import media from '../../../styles/tools/media'
import em from '../../../styles/tools/em'
import rem from '../../../styles/tools/rem'

export const Aside = styled.aside`
  align-items: center;
  background-color: ${props => props.color};
  display: block;
  margin: 0 -${rem('20px')};
  overflow: hidden;

  ${media.large`
        background-color: background-color: ${props => props.color};
        border-radius: ${rem('4.4px')};
        display: flex;
        flex-direction: column;
        min-height: ${rem('472px')};
        justify-content: stretch;
        margin: ${rem('-3px')};
        padding: 0;
        width: ${rem('220px')};
    `};

  @media print {
    background-color: ${props => props.color};
    border-radius: ${rem('4.4px')};
    display: flex;
    flex-direction: column;
    min-height: ${rem('472px')};
    justify-content: stretch;
    margin: ${rem('-3px')};
    padding: 0;
    width: ${rem('220px')};
  }
`

export const PlansContainer = styled.div`
  background-color: ${props => props.color};
  height: auto;
  padding: ${rem('23px')} 0 0 ${rem('28px')};
  width: 100%;

  ${media.large`
        flex: 1;
        padding: ${rem('28px')} ${rem('22px')} 0;
        position: relative;
    `};

  @media print {
    flex: 1;
    padding: ${rem('28px')} ${rem('22px')} 0;
    position: relative;
  }
`

export const VehiclesContainer = styled.div`
  height: auto;
  padding: 0 ${rem('30px')} 0 ${rem('30px')};

  ${media.large`
        background-color: ${props => props.color};
        display: flex;
        flex-direction: column;
        height: auto;
        justify-content: flex-end;
        min-height: ${rem('221px')};
        padding: 0;
    `};

  @media print {
    background-color: ${props => props.color};
    display: flex;
    flex-direction: column;
    height: auto;
    justify-content: flex-end;
    min-height: ${rem('221px')};
    padding: 0;
  }
`

export const Title = styled.h1`
  ${title24GalanoBold} color: ${white};
  display: flex;
  flex-direction: row;
  line-height: 1;
  margin: 0 ${rem('10px')} 0 0;
  text-transform: capitalize;

  ${media.large`
        ${title24GalanoBold}
    `};
`

export const TitlePlan = styled.div`
  align-items: baseline;
  display: flex;

  ${media.large`
        display: block;
        line-height: 1;
    `};

  @media print {
    display: block;
    line-height: 1;
  }
`

export const PlanType = styled.p`
  ${txt24OpenSansNormal} color: ${white};
  font-weight: bold;
  margin: 0;
  text-transform: capitalize;

  ${media.large`
        ${txt24OpenSansNormal}
        margin: 0 0 ${rem('12px')};
        max-width: ${rem('160')};
    `};

  @media print {
    ${txt24OpenSansNormal}
    margin: 0 0 ${rem('12px')};
    max-width: ${rem('160')};
  }
`

export const PlanState = styled.p`
  ${txt12OpenSansLight} color: ${white};
  display: block;
  letter-spacing: ${em('1.3px')};
  margin-right: ${rem('17px')};
  text-transform: uppercase;
  vertical-align: center;

  :before {
    background-color: ${green};
    border-radius: ${rem('64px')};
    content: '';
    display: inline-block;
    height: ${rem('8px')};
    margin-right: ${rem('8px')};
    width: ${rem('24px')};
  }
`

export const State = styled.div`
  align-items: baseline;
  display: flex;

  ${media.large`
    display: block;
  `};

  ${media.small`
    flex-direction: column;
  `};

  @media print {
    display: block;
  }
`

export const VehicleText = styled.p`
  ${txt16OpenSans} align-items: baseline;
  color: ${props => props.color};
  display: flex;
  line-height: 1;
  margin: 0;
  text-align: left;

  ${media.large`
        ${txt16OpenSans};
        display: block;
        font-size: ${rem('14px')};
        line-height: 0;
        padding: 0 ${rem('22px')};
        text-align: left;
    `};

  @media print {
    ${txt16OpenSans};
    display: block;
    font-size: ${rem('14px')};
    line-height: 0;
    padding: 0 ${rem('22px')};
    text-align: left;
  }
`

export const Vehicle = styled.span`
  ${txt16OpenSans} font-weight: bold;
  margin-bottom: ${rem('14px')};
`

export const VehicleNumber = styled.span`
  ${txt16OpenSans};

  display: block;
  letter-spacing: ${rem('0.2px')};
  margin-right: ${rem('5px')};

  ${media.large`
        ${title32GalanoBold}

        line-height: 1.5;
    `};

  @media print {
    ${title32GalanoBold} line-height: 1.5;
  }
`

export const VehicleSvg = styled.div`
  ${title32GalanoBold};

  color: ${props => props.color};
  display: none;

  ${media.large`
        border-radius: ${rem('4.4px')};
        display: block;
        margin-left: ${rem('15px')};
    `};

  @media print {
    border-radius: ${rem('4.4px')};
    display: block;
    margin-left: ${rem('15px')};
  }
`

export const WrapperAccession = styled.div`
  display: flex;
  min-height: 150px;
  flex-direction: column;
  justify-content: ${props => (props.bonification ? 'space-between' : 'flex-end')};
  padding: 12px 0;
  ${media.small`
    min-height: 100px;
    width: 50%;
  `};
`

export const Accession = styled.div`
  display: flex;
  flex-direction: row;

  ${media.large`
    bottom: ${rem('10px')};
    display: block;
  `};

  @media print {
    bottom: ${rem('10px')};
    display: block;
    position: absolute;
  }
`

export const Label = styled.p`
  ${txt12OpenSans} color: ${white};
  letter-spacing: ${rem('1px')};
  margin: 0;
  text-transform: uppercase;
`

export const Date = styled.time`
  ${txt12OpenSans} color: ${white};
  letter-spacing: ${rem('1px')};
`

export const FinancialInfo = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: ${rem('10px')};
  padding: 0;

  ${media.large`
        flex-direction: column;
        margin-bottom: 0;
        padding: ${rem('17px')} 0 ${rem('10')} ${rem('20px')};
    `};

  @media print {
    flex-direction: column;
    margin-bottom: 0;
    padding: ${rem('17px')} 0 ${rem('10')} ${rem('20px')};
  }
`

export const Currency = styled.span`
  ${title14Galano} color: ${white};
  font-weight: bold;
`

export const ValueLimited = styled.p`
  ${title18Galano} color: ${white};
  margin: 0;

  ${media.large`
        ${title24GalanoBold}
    `};
`

export const MonthlyPayment = styled.p`
  ${title18Galano} color: ${white};
  margin: 0;

  ${media.large`
        ${title24GalanoBold}
    `};
`
