import styled from 'styled-components'

import { white } from '../../../styles/settings/colors'

import { txt12OpenSans, txt14OpenSans } from '../../../styles/settings/types'

import rem from '../../../styles/tools/rem'
import em from '../../../styles/tools/em'

import media from '../../../styles/tools/media'

export const Container = styled.section`
  max-width: ${rem('940px')};
  margin: 0 auto;
  padding: ${rem('23px')} 0 0;

  ${media.small`
        padding: ${rem('2px')} ${rem('24px')};
    `};
`

export const Content = styled.div`
  display: block;
`

export const FilterTypeContainer = styled.div`
  ${media.large`
        padding: 0;
    `};
  ${media.small`
        margin: 0 ${rem('-24px')};
        overflow: auto;
        padding: 0 ${rem('20px')};
    `};
`

export const FilterTypes = styled.ul`
  display: flex;
  justify-content: space-between;
  list-style: none;
  margin: 0;
  min-height: ${rem('76px')};
  padding: 0;
  width: 100%;

  ${media.small`
        display: block;
        width: 322%;

        :after {
            clear: both;
            content: '';
            display: table;
        }
    `};
`

export const ListItem = styled.li`
  ${media.small`
        float: left;
        margin-bottom: ${rem('24px')};
        text-align: center;
        width: 8.3%;
    `};
`

export const Title = styled.h2`
  ${txt12OpenSans} letter-spacing: ${em('-0.5px')};
  line-height: 1.33;
  color: ${white};
  margin: 0 0 ${rem('24px')};
  text-transform: uppercase;

  ${media.small`
        ${txt14OpenSans}

        margin: 0 0 ${rem('15px')};
    `};
`
