import TemplateRecharge from '../app/templates/TemplateRecharge'
import EditPaymentRechargePrices from '../app/pages/EditPaymentRechargePrices'
import EditPaymentCreditCard from '../app/pages/EditPaymentCreditCard'
import EditPaymentConfirmation from '../app/pages/EditPaymentConfirmation'
import EditPaymentSuccess from '../app/pages/editPaymentSuccess'
import EditPaymentSuccessAutomatic from '../app/pages/editPaymentSuccessAutomatic'

export default {
  path: '/minha-veloe/:id/editar-dados',
  component: TemplateRecharge,
  routes: [
    {
      path: '/minha-veloe/:id/editar-dados',
      exact: true,
      component: EditPaymentRechargePrices
    },
    {
      path: '/minha-veloe/:id/editar-dados/recarga',
      exact: true,
      component: EditPaymentRechargePrices
    },
    {
      path: '/minha-veloe/:id/editar-dados/cartao',
      exact: true,
      component: EditPaymentCreditCard
    },
    {
      path: '/minha-veloe/:id/editar-dados/confirmacao',
      exact: true,
      component: EditPaymentConfirmation
    },
    {
      path: '/minha-veloe/:id/editar-dados/sucesso',
      exact: true,
      component: EditPaymentSuccess
    },
    {
      path: '/minha-veloe/:id/editar-dados/sucesso-automatico',
      exact: true,
      component: EditPaymentSuccessAutomatic
    }
  ]
}
