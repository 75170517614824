import React from 'react'
import { utils } from 'alelo-logic'

import {
  Content,
  GroupItems,
  Item,
  ItemCell,
  ItemRow,
  ItemSpan,
  ItemSpanCenter,
  ItemStrong,
  ItemStrongGrey,
  Value,
  ValueBlue
} from './styles'

const { benefits, parking, toll, veloeServices } = require('./data.json')

function renderHour(item, transacao) {
  return item.tipoTransacao === parking
    ? `${transacao.horaEntrada} / ${transacao.horaSaida}`
    : transacao.hora
}

function renderCategory(item, transacao) {
  if (item.tipoTransacao === toll) {
    if (transacao.categoria < 10) return `0${transacao.categoria || 0}`
    return transacao.categoria
  }
  if (item.tipoTransacao === parking) return transacao.permanencia
  return ''
}

function renderPlace(item, transacao) {
  if (item.tipoTransacao === veloeServices || item.tipoTransacao === benefits) {
    return <ItemStrongGrey>{item.tipoTransacao}</ItemStrongGrey>
  }
  return (
    <div>
      <ItemStrongGrey>{transacao.estabelecimento}</ItemStrongGrey>

      <ItemSpan>
        <span>{transacao.endereco}</span>
      </ItemSpan>
    </div>
  )
}

function renderValue(item, transacao) {
  if (item.tipoTransacao === benefits)
    return <ValueBlue>{utils.currencyParser(transacao.valor)}</ValueBlue>
  return <Value>{utils.currencyParser(transacao.valor)}</Value>
}

function renderItems(data) {
  return (
    data &&
    data.transacaoes &&
    data.transacaoes.map((transacao, i) => (
      <Item key={i}>
        <Content>
          <ItemRow align="center" justify="space-between">
            <ItemCell width={[1, 2 / 12]} />
            <ItemCell pl={[0, 20]} width={[1, 1 / 12]}>
              <ItemStrong>{utils.ISODateFormat(transacao.data)}</ItemStrong>
            </ItemCell>
            <ItemCell pl={[0, 20]} width={[1, 2 / 12]}>
              <ItemSpanCenter>{renderHour(data, transacao)}</ItemSpanCenter>
            </ItemCell>
            <ItemCell pl={[0, 20]} width={[1, 1 / 12]}>
              <ItemSpanCenter>{renderCategory(data, transacao)}</ItemSpanCenter>
            </ItemCell>
            <ItemCell pl={[0, 20]} width={[1, 5 / 12]}>
              {renderPlace(data, transacao)}
            </ItemCell>
            <ItemCell pl={[0, 20]} width={[1, 1 / 12]}>
              {renderValue(data, transacao)}
            </ItemCell>
          </ItemRow>
        </Content>
      </Item>
    ))
  )
}

const InvoiceDetailsListItems = ({ data }) => <GroupItems>{renderItems(data)}</GroupItems>

export default InvoiceDetailsListItems
