import styled from 'styled-components'

import { linkNavyBlue, darkGrey } from '../../../../styles/settings/colors'

import { txt12OpenSans } from '../../../../styles/settings/types'

import rem from '../../../../styles/tools/rem'

export const Title = styled.span`
  ${txt12OpenSans};

  color: ${darkGrey};
  display: block;
  line-height: 1;
  text-transform: capitalize;
  :hover {
    color: ${linkNavyBlue};
  }
`

export const DropdownListContainer = styled.ul`
  background-color: white;
  border-radius: ${rem('2px')};
  box-shadow: 0 ${rem('8px')} ${rem('8px')} 0 rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  left: 0;
  pointer-events: none;
  position: absolute;
  top: ${rem('50px')};
  width: 100%;
  z-index: 20;
  margin: 0;
  padding: ${rem('15px')} 0;

  ${props =>
    props.isOpen &&
    `
        pointer-events: all;
    `};
`

export const DropdownListItem = styled.li`
  text-decoration: none;
  list-style: none;
  padding: ${rem('15px')} ${rem('20px')};
`
