import TemplateEmpty from '../app/templates/TemplateEmpty'
import EditPaymentMethod from '../app/pages/EditPaymentMethod'

export default {
  path: '/minha-veloe/:id/metodo-de-pagamento',
  component: TemplateEmpty,
  routes: [
    {
      path: '/minha-veloe/:id/metodo-de-pagamento',
      exact: true,
      component: EditPaymentMethod
    }
  ]
}
