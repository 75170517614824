import styled, { keyframes } from 'styled-components'

import { grey, skyBlue, lightGrey, darkGrey, white } from '../../../styles/settings/colors'
import { txt16OpenSans } from '../../../styles/settings/types'

import rem from '../../../styles/tools/rem'

const bounceRadio = keyframes`
    0% {
        transform: translateX(-50%) translateY(-50%) scale(0);
    }

    40% {
        transform: translateX(-50%) translateY(-50%) scale(1.1);
    }

    70% {
        transform: translateX(-50%) translateY(-50%) scale(0.95);
    }

    100% {
        transform: translateX(-50%) translateY(-50%) scale(1);
    }
`

export const Circle = styled.span`
  border: solid ${rem('1px')} ${props => (props.negative ? `${white}` : `${lightGrey}`)};
  border-radius: 100%;
  box-shadow: 0;
  display: block;
  height: ${rem('16px')};
  position: relative;
  transition: 0.3s box-shadow;
  width: ${rem('16px')};

  :after {
    animation: 0.4s ${bounceRadio} forwards;
    background: ${props => (props.negative ? `${white}` : `${skyBlue}`)};
    border-radius: 100%;
    content: '';
    display: none;
    height: ${rem('8px')};
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translateX(-50%) translateY(-50%) scale(0);
    width: ${rem('8px')};
  }

  :not(:last-child) {
    margin-right: ${rem('15px')};
  }
`

export const Label = styled.label`
  user-select: none;

  :hover {
    ${Circle} {
      box-shadow: 0 0 0 ${rem('1px')} ${grey} inset;
    }
  }
`

export const Container = styled.div`
  align-items: center;
  cursor: pointer;
  display: inline-flex;
`

export const Text = styled.span`
  ${txt16OpenSans} color: ${darkGrey};
  display: inline-block;
  letter-spacing: ${rem('-0.4px')};
  line-height: 1.5;
`

export const HiddenContent = styled.div`
  display: none;
`

export const Input = styled.input`
  display: none;

  :checked {
    + ${Container} ${Circle} {
      border-color: ${props => (props.negative ? `${white}` : `${skyBlue}`)};
      box-shadow: 0 0 0 ${rem('1px')} ${props => (props.negative ? `${white}` : `${skyBlue}`)} inset;

      :after {
        display: block;
      }
    }

    ~ ${HiddenContent} {
      display: block;
    }
  }
`
