import React from 'react'

const IcUtility = props => (
  <svg width="48" height="48" viewBox="0 0 48 48">
    <defs>
      <path
        id="a1"
        d="M17.429 2.833H4.259c-.782 0-1.42.64-1.42 1.422v13.162a1.42 1.42 0 0 0 1.42 1.416h.008l.563.003a4.315 4.315 0 0 1 4.068-2.887 4.314 4.314 0 0 1 4.076 2.914l11.687.024a4.315 4.315 0 0 1 4.085-2.938c1.89 0 3.5 1.223 4.078 2.918l2.122.013v-5.593a3.743 3.743 0 0 0-3.742-3.736H7.099a1.42 1.42 0 1 1 0-2.838h10.333a1.436 1.436 0 0 1-.003-.093V2.866v-.033zm2.839 0V6.62l-.003.093h6.85a5.25 5.25 0 0 0-1.437-2.287 5.89 5.89 0 0 0-4.056-1.593h-1.354zM4.833 21.674l-.577-.003A4.26 4.26 0 0 1 0 17.417V4.255A4.261 4.261 0 0 1 4.26 0h17.362c2.257 0 4.39.838 6.006 2.366 1.275 1.204 2.103 2.713 2.407 4.347h1.169c3.629 0 6.581 2.949 6.581 6.574v7.019c0 .38-.15.74-.418 1.004a1.408 1.408 0 0 1-1.002.417h-.008l-3.557-.022a4.314 4.314 0 0 1-4.054 2.85 4.314 4.314 0 0 1-4.047-2.83L12.953 21.7a4.313 4.313 0 0 1-4.055 2.855 4.314 4.314 0 0 1-4.065-2.882zm23.913-2.887c-.81 0-1.468.657-1.468 1.463a1.468 1.468 0 0 0 2.935 0c0-.806-.658-1.463-1.467-1.463zm-19.848 0c-.81 0-1.468.657-1.468 1.463a1.468 1.468 0 0 0 2.935 0c0-.806-.658-1.463-1.467-1.463z"
      />
    </defs>
    <g fill="none" fillRule="evenodd" transform="translate(5 12)">
      <mask id="b1" fill="#fff">
        <use xlinkHref="#a1" />
      </mask>
      <use fill="#252853" xlinkHref="#a1" />
      <g mask="url(#b1)">
        <path fill="currentColor" d="M-5-12h48v48H-5z" />
      </g>
      <path d="M-4.627-11.581h47.534v47.534H-4.627z" mask="url(#b1)" />
    </g>
  </svg>
)

export default IcUtility
