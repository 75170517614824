import styled from 'styled-components'

import { darkGrey, linkNavyBlue } from '../../../styles/settings/colors'
import media from '../../../styles/tools/media'
import rem from '../../../styles/tools/rem'
import { title14Galano, title18Galano, txt14OpenSans } from '../../../styles/settings/types'

export const Container = styled.div`
  width: 100%;
  display: flex;
`
export const BarChartContainer = styled.div`
  display: flex;
  align-items: center;
  margin: ${rem('8px')};
  width: 100%;
`

export const Name = styled.div`
  color: ${darkGrey};
  display: block;
  font-family: OpenSans;
  font-size: ${rem('12px')};
  font-weight: 200;
  line-height: 1;
  margin: ${rem('8px')};
`

export const Value = styled.div`
  ${title14Galano} color: ${linkNavyBlue};
  letter-spacing: ${rem('1.2px')};
  line-height: 1;
  margin: ${rem('8px')} 0 ${rem('8px')} ${rem('8px')};
  span {
    ${txt14OpenSans}
  }
  ${media.large`
        ${title18Galano}
        letter-spacing: ${rem('1px')};
    `};
`
