import { push } from 'react-router-redux'

export default store => next => action => {
  const resolvedAction = next(action)

  if (resolvedAction && resolvedAction.catch) {
    resolvedAction.catch(e => {
      if (typeof e === 'object' && e.status === 403) {
        store.dispatch(push('/conta/login'))
      }
      return e
    })
  }

  return resolvedAction
}
