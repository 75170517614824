export default function pageLoaderReducer(state, action) {
  if (!state) {
    state = {}
  }
  switch (action.type) {
    case 'SET_LOADING': {
      return { ...state, isLoading: action.isLoading }
    }
    default: {
      return state
    }
  }
}
