import { SAVING_FORM } from '../../constants/ActionTypes'

export const startSaving = () => ({
  type: SAVING_FORM,
  state: { saving: true }
})

export const stopSaving = () => ({
  type: SAVING_FORM,
  state: { saving: false }
})
