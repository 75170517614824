import styled, { css } from 'styled-components'
import { Link } from 'react-router-dom'

import rem from 'styles/tools/rem'
import media from 'styles/tools/media'
import { skyBlue, white, navyBlue } from 'styles/settings/colors'
import { txt14OpenSansBold, title24Galano, title40GalanoBold } from 'styles/settings/types'

export const Container = styled.section`
  background-color: ${skyBlue};
  height: 100vh;
  overflow: auto;
`

export const Header = styled.header`
  background-color: ${skyBlue};
  border-bottom: solid ${rem('2px')} ${navyBlue};
`

export const HeaderContainer = styled.div`
  align-items: center;
  display: flex;
  height: ${rem('60px')};
  margin: 0 ${rem('30px')};
  position: relative;

  ${media.large`
        height: ${rem('70px')};
    `};
`

const CommonHeaderButton = css`
  ${txt14OpenSansBold};
  align-items: center;
  color: ${white};
  display: inline-flex;
  line-height: 1;
  text-decoration: none;
  cursor: pointer;
  svg {
    margin-right: ${rem('25px')};
  }
  ${media.small`
  display: block;
  line-height: ${rem('60px')};
  position: relative;
  text-align: center;
  vertical-align: middle;
  width: 100%;
  svg {
    left: 0;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }
`};
`

export const HeaderLink = styled(Link)`
  ${CommonHeaderButton};
`

export const HeaderButton = styled.a`
  ${CommonHeaderButton};
`

export const PageTitle = styled.p`
  ${title24Galano};
  color: ${white};
  line-height: 1;
  padding-right: ${rem('80px')};
  margin: 0;

  ${media.large`
        ${title40GalanoBold};
        padding: 0;
    `};
`
