import {
  getManageInfs,
  getManageInfHistory,
  getManageInfTop5,
  createManageInfo,
  updateManageInfo,
  deleteManageInfo,
  getManageInfsActive
} from '../services/manageInfService'
import types from './types'

export const fetchGetManageInfs = (accountId, plate) => async dispatch => {
  dispatch({ type: types.FETCH_MANAGE_INF })
  try {
    const informations = await getManageInfs(accountId, plate)
    dispatch({ type: types.FETCH_MANAGE_INF_FINISHED, informations })
  } catch (error) {
    console.log('Erro em #fetchGetManageInfs()', error)
    dispatch({ type: types.FETCH_MANAGE_INF_FINISHED, informations: [] })
    return error
  }
}

export const fetchGetManageInfsActive = (accountId, plate) => async dispatch => {
  dispatch({ type: types.FETCH_MANAGE_INF })
  try {
    const informations = await getManageInfsActive(accountId, plate)
    dispatch({ type: types.FETCH_MANAGE_INF_FINISHED, informations })
  } catch (error) {
    console.log('Erro em #getManageInfsActive()', error)
    dispatch({ type: types.FETCH_MANAGE_INF_FINISHED, informations: [] })
    return error
  }
}

export const fetchGetManageInfsHistory = (accountId, plate) => async dispatch => {
  dispatch({ type: types.FETCH_HIST_MANAGE_INF })
  try {
    const informations = await getManageInfHistory(accountId, plate)
    dispatch({ type: types.FETCH_HIST_MANAGE_INF_FINISHED, informations })
  } catch (error) {
    console.log('Erro em #fetchGetManageInfHistory()', error)
    dispatch({ type: types.FETCH_HIST_MANAGE_INF_FINISHED, informations: [] })
    return error
  }
}

export const fetchGetManageInfsTop5 = accountId => async dispatch => {
  dispatch({ type: types.FETCH_TOP5_MANAGE_INF })
  try {
    const informations = await getManageInfTop5(accountId)
    dispatch({ type: types.FETCH_TOP5_MANAGE_INF_FINISHED, informations })
  } catch (error) {
    console.log('Erro em #fetchGetManageInfTop5()', error)
    dispatch({ type: types.FETCH_TOP5_MANAGE_INF_FINISHED, informations: [] })
    return error
  }
}

export const saveManageInf = data => dispatch => {
  dispatch({ type: types.UPDATE_MANAGE_INF })
  return new Promise((resolve, reject) =>
    createManageInfo(data)
      .then(resp => {
        dispatch({ type: types.UPDATE_MANAGE_INF_FINISHED })
        return resolve(resp)
      })
      .catch(error => {
        console.log('Erro em #saveManageInf(): ', error)
        dispatch({ type: types.UPDATE_MANAGE_INF_FINISHED })
        return reject(error)
      })
  )
}
export const deleteManageInf = data => dispatch => {
  dispatch({ type: types.UPDATE_MANAGE_INF })
  return new Promise((resolve, reject) =>
    deleteManageInfo(data)
      .then(resp => {
        dispatch({ type: types.UPDATE_MANAGE_INF_FINISHED })
        return resolve(resp)
      })
      .catch(error => {
        console.log('Erro em #deleteManageInf()', error)
        dispatch({ type: types.UPDATE_MANAGE_INF_FINISHED })
        return reject(error)
      })
  )
}
export const updateManageInf = data => dispatch => {
  dispatch({ type: types.UPDATE_MANAGE_INF })
  return new Promise((resolve, reject) =>
    updateManageInfo(data)
      .then(resp => {
        dispatch({ type: types.UPDATE_MANAGE_INF_FINISHED })
        return resolve(resp)
      })
      .catch(error => {
        console.log('Erro em #updateManageInf()', error)
        dispatch({ type: types.UPDATE_MANAGE_INF_FINISHED })
        return reject(error)
      })
  )
}
