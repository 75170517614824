import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Field, getFormValues, reduxForm } from 'redux-form'
import { Flex, Box } from 'grid-styled'
import { isEmpty } from 'lodash'
import ReCAPTCHA from 'react-google-recaptcha'
import Button from '../../elements/ButtonV2'
import { renderTextInput, renderPasswordInput } from '../../../utils/renderInputs'
import { TextPassword, Buttons, Radios, ErrorMessage } from './style'
import { PUBLIC_SITE_URL, BUILD_NUMBER } from '../../../alelo-logic/services/common/constants'
import Radio from '../../elements/Radio'
import FormModal from './FormModal'
const { forgotpassword } = require('./data.json')
const { pfURL, pjURL } = require('../../../alelo-logic/config.json')

function validateEmailLogin(value) {
  return value.replace(/[^\w@.\-_]/gi, '')
}

function validateCpfLogin(value) {
  return value.replace(/[^\d]/gi, '')
}

function validate(values) {
  const errors = {}
  if (!values || isEmpty(values.cpfLogin || values.emailLogin)) {
    errors.cpfLogin = 'Não esqueça do número do seu documento.'
    errors.emailLogin = 'Não esqueça do seu e-mail.'
  } else {
    if (values.cpfLogin) values.cpfLogin = validateCpfLogin(values.cpfLogin)
    else if (values.emailLogin) values.emailLogin = validateEmailLogin(values.emailLogin)
  }

  if (isEmpty(values.password)) {
    errors.password = 'Não esqueça da sua senha.'
  }

  if (!values.captcha) {
    errors.captcha = 'É preciso resolver o captcha.'
  }
  return errors
}

const renderRadio = ({ input, label, checked }) => (
  <Radio {...input} label={label} checked={checked} />
)

let recaptcha

const renderCaptcha = ({ input, meta }) => (
  <div>
    <ReCAPTCHA
      ref={e => (recaptcha = e)}
      sitekey={'6Lfkrl4UAAAAACDEi92ieHL94KKMnDNt0c0MUuzs'}
      onChange={value => {
        input.onChange(value)
      }}
    />
    {meta.touched && meta.error && <ErrorMessage>{meta.error}</ErrorMessage>}
  </div>
)

class LoginForm extends Component {
  constructor(props) {
    super(props)

    this.state = {
      radioTipoLogin: 'loginPessoaFisica'
    }
  }

  componentDidMount() {
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({
      dimension6: 'nao-logado'
    })
  }

  resetCaptcha = () => {
    if (recaptcha) {
      recaptcha.reset()
    }
  }

  handleRadioChange = e => {
    const urlsMap = {
      [this.props.tipoPF]: pfURL,
      [this.props.tipoPJ]: pjURL
    }
    this.setState(
      {
        radioTipoLogin: e.currentTarget.value
      },
      () => (window.location = urlsMap[e.currentTarget.value])
    )
  }

  render() {
    const {
      isOpen,
      closeModal,
      msgError,
      handleSubmit,
      nomeCompTipoLogin,
      tipoPF,
      tipoPJ,
      loginMsgError,
      formValues,
      showCaptcha
    } = this.props

    if (!formValues) {
      this.props.initialize({
        radioTipoLogin: this.props.tipoPF
      })
    }

    return (
      <div>
        <form onSubmit={handleSubmit}>
          <Radios>
            <Box mb={24}>
              <Field
                component={renderRadio}
                label="Pessoa Física"
                name={nomeCompTipoLogin}
                type="radio"
                value={tipoPF}
                checked={
                  this.state.radioTipoLogin === tipoPF ||
                  (formValues &&
                    formValues[nomeCompTipoLogin] &&
                    formValues[nomeCompTipoLogin] === tipoPF)
                }
                onChange={e => {
                  this.handleRadioChange(e)
                  window.dataLayer.push({
                    event: 'event',
                    eventAction: 'login:tentativa',
                    eventCategory: 'veloe:login',
                    eventLabel: 'preencher:pf'
                  })
                }}
              />
            </Box>
            <Box mb={24}>
              <Field
                component={renderRadio}
                label="Pessoa Jurídica"
                name={nomeCompTipoLogin}
                type="radio"
                value={tipoPJ}
                checked={
                  this.state.radioTipoLogin === tipoPJ ||
                  (formValues &&
                    formValues[nomeCompTipoLogin] &&
                    formValues[nomeCompTipoLogin] === tipoPJ)
                }
                onChange={e => {
                  this.handleRadioChange(e)
                  window.dataLayer.push({
                    event: 'event',
                    eventAction: 'login:tentativa',
                    eventCategory: 'veloe:login',
                    eventLabel: 'pj'
                  })
                }}
              />
            </Box>
          </Radios>

          <Flex direction="column">
            <Box mb={[24, 28]}>
              {this.state.radioTipoLogin === tipoPF ||
              (formValues &&
                formValues[nomeCompTipoLogin] &&
                formValues[nomeCompTipoLogin] === tipoPF) ? (
                <Field
                  component={renderTextInput}
                  label="CPF"
                  name="cpfLogin"
                  onFocus={() =>
                    window.dataLayer.push({
                      event: 'event',
                      eventAction: 'login:preencher',
                      eventCategory: 'veloe:login',
                      eventLabel: 'preencher:cpf'
                    })
                  }
                />
              ) : (
                <Field
                  component={renderTextInput}
                  label="E-mail"
                  name="emailLogin"
                  onFocus={() =>
                    window.dataLayer.push({
                      event: 'event',
                      eventAction: 'login:preencher',
                      eventCategory: 'veloe:login',
                      eventLabel: 'preencher:email'
                    })
                  }
                />
              )}
            </Box>
            <Box mb={[17, 20]}>
              <Field
                component={renderPasswordInput}
                label="senha"
                name="password"
                onFocus={() =>
                  window.dataLayer.push({
                    event: 'event',
                    eventAction: 'login:preencher',
                    eventCategory: 'veloe:login',
                    eventLabel: 'preencher:senha'
                  })
                }
              />
            </Box>

            <Box mb={17}>
              <TextPassword
                href={`${PUBLIC_SITE_URL}/login/recuperar-a-senha`}
                onClick={() => {
                  window.dataLayer.push({
                    event: 'event',
                    eventAction: 'esqueci-senha:tentativa',
                    eventCategory: 'veloe:login',
                    eventLabel: 'clique:login'
                  })
                }}
              >
                {forgotpassword}
              </TextPassword>
            </Box>
            <Box mb={17}>{showCaptcha && <Field component={renderCaptcha} name="captcha" />}</Box>
          </Flex>
          <Buttons>
            <Box mr={[0, 15]} mt={15} flex={1}>
              <Button type="submit" text="Continuar" buttonType="darkBlue" />
            </Box>
            <Box mt={15}>
              <Button
                text="É sua primeira visita?"
                buttonType="white"
                onClick={() => {
                  window.dataLayer.push({
                    event: 'event',
                    eventAction: 'clique:primeira-visita',
                    eventCategory: 'veloe:login',
                    eventLabel: 'login'
                  })
                  window.location = `${PUBLIC_SITE_URL}/login/acesso`
                }}
              />
            </Box>
          </Buttons>
        </form>
        <FormModal
          closeModal={closeModal}
          isOpen={isOpen}
          error={msgError}
          resetCaptcha={this.resetCaptcha}
          loginMsgError={loginMsgError}
        />
        <div style={{ color: '#FFFFFF', position: 'fixed', bottom: 0, right: 0, fontSize: '9px' }}>
          {BUILD_NUMBER}
        </div>
      </div>
    )
  }
}

const Container = connect(state => ({
  formValues: getFormValues('login')(state)
}))

export default reduxForm({ form: 'login', validate, destroyOnUnmount: true })(Container(LoginForm))
