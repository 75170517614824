import styled from 'styled-components'

import { linkNavyBlue, darkGrey } from '../../../styles/settings/colors'
import { txt12OpenSans, txt12OpenSansBold } from '../../../styles/settings/types'
import rem from '../../../styles/tools/rem'

export const ResumeContent = styled.div`
  margin-bottom: ${rem('40px')};
`

export const GroupedSummary = styled.div`
  margin-bottom: ${rem('20px')};
`

export const SummaryCategory = styled.div`
  ${txt12OpenSansBold};

  color: ${linkNavyBlue};
  font-weight: bold;
  text-transform: uppercase;
`

export const SummaryItemTitle = styled.div`
  ${txt12OpenSans};

  color: ${linkNavyBlue};
`

export const SummaryItemNumber = styled.div`
  ${txt12OpenSans};

  color: ${darkGrey};
  text-align: right;
`

export const Subtotal = styled.div`
  ${txt12OpenSansBold};
  color: ${linkNavyBlue};
  font-weight: bold;
  margin-bottom: ${rem('15px')};
  text-transform: uppercase;
`

export const Benefits = styled.div`
  ${txt12OpenSansBold};

  color: ${linkNavyBlue};
  font-weight: bold;
  margin-bottom: ${rem('15px')};
  text-transform: uppercase;
`

export const Total = styled.div`
  ${txt12OpenSansBold};

  color: ${linkNavyBlue};
  font-weight: bold;
  margin-bottom: ${rem('15px')};
  text-transform: uppercase;
`

export const Values = styled.div`
  ${Subtotal}, ${Benefits}, ${Total} {
    text-align: right;
  }
`

export const Message = styled.div`
  ${txt12OpenSans};

  ${props => props.marginBottom || `margin-bottom: ${rem('10px')}`};
  color: ${darkGrey};
`
