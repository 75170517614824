import { requestContestations } from '../services/ecService'

import types from './types'

const { FETCH_EC_CONTESTATIONS } = types

function fetchECContestations() {
  return dispatch =>
    requestContestations()
      .then(contestations => {
        dispatch({
          type: FETCH_EC_CONTESTATIONS,
          state: contestations
        })
        return contestations
      })
      .catch(error => {
        console.log('Erro em #fetchECContestations()')
        console.log(error)
      })
}

export default fetchECContestations
