import types from '../actions/types'

const { FETCH_EC_REQUEST_FILE_HISTORY } = types

function ecRequestFileHistory(state = [], action = {}) {
  switch (action.type) {
    case FETCH_EC_REQUEST_FILE_HISTORY:
      return action.state
    default:
      return state
  }
}

export default ecRequestFileHistory
