import { fetchVerifyUserPassword } from 'alelo-logic/actions'
import validatePasswordRules from './passwordRules'

import { isEmpty, omitBy } from 'lodash'

import { setStepValidity, showStepBoxErrors } from '../StepBox/actions'

const { errors } = require('./data.json')

const {
  emptyConfirmPassword,
  emptyCurrentPassword,
  emptyNewPassword,
  notMatch,
  samePassword
} = errors

function validateNewPassword(value, dispatch) {
  const { showErrors, error } = validatePasswordRules(value)
  let stepErrors = showErrors
    ? [
        !error.newPasswordLength,
        !error.lowerLetterNeeded,
        !error.upperLetterNeeded,
        !error.numberNeeded,
        !error.especialNeeded
      ]
    : []

  dispatch(showStepBoxErrors(showErrors))
  dispatch(setStepValidity(stepErrors))

  return error
}

function validatePassword(values, props) {
  if (!values) {
    values = {}
  }
  const { confirmPassword, currentPassword, newPassword } = values

  const error = {}
  const newPasswordErrors = validateNewPassword(newPassword, props.dispatch)
  const confirmPasswordErrors = newPassword !== confirmPassword && notMatch

  error.confirmPassword = isEmpty(confirmPassword) ? emptyConfirmPassword : confirmPasswordErrors
  error.currentPassword = isEmpty(currentPassword) && emptyCurrentPassword
  error.newPassword = isEmpty(newPassword) ? emptyNewPassword : newPasswordErrors
  error.newPassword =
    newPassword && newPassword === currentPassword ? samePassword : error.newPassword

  return omitBy(error, isEmpty)
}

const fetchCheckPassword = (val, dispatch, props) =>
  fetchVerifyUserPassword(props.values.currentPassword).catch(() => {
    throw { currentPassword: { wrongPassword: true } } // eslint-disable-line no-throw-literal
  })

export { fetchCheckPassword, validateNewPassword, validatePassword, validatePasswordRules }
