import styled from 'styled-components'
import { darkGrey } from 'styles/settings/colors'

export const DotButton = styled.a`
  cursor: ${({ removeCursor }) => (!removeCursor ? 'pointer' : 'initial')};
  padding: 10px;
`

export const DotIcon = styled.span`
  position: relative;
  &,
  &::before,
  &::after {
    width: 4px;
    height: 4px;
    border-radius: 100px;
    background-color: ${({ color }) => (color === '' ? darkGrey : color)};
    display: inline-block;
  }
  &::before,
  &::after {
    content: '';
    position: absolute;
    left: 0;
    transition: all 0.2s;
  }
  &::before {
    top: 8px;
  }
  &::after {
    top: 16px;
  }
`
