import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Box, Flex } from 'grid-styled'

import {
  fetchDashboardResume,
  fetchECMoneyTransfer,
  fetchECContestations
} from 'alelo-logic/actions'

import PlanResume from 'app/modules/PlanResume'

import Content from 'styles/objects/Content'
import rem from 'styles/tools/rem'
import Hide from 'styles/utilities/Hide'

import { setLoading } from 'app/modules/PageLoader/actions'

import Advertise from './advertise'
import Contestations from './contestations'
import CarPlateForm from '../CarPlateQuery/form-simple'
import NewPlaces from './newPlaces'
import Receipts from './receipts'
import RequestFileForm from '../RequestFile/form-simple'

class DashboardResume extends Component {
  async componentWillMount() {
    this.props.setLoading(true)
    await this.props.fetchResume(this.props.match.params.id)
    await this.props.fetchMoneyTransfer()
    await this.props.fetchContestations()
  }

  onCarPlateQuery = values => {
    this.props.history.push('/minha-veloe/consultar-placa', values)
  }

  onRequestFile = values => {
    this.props.history.push('/minha-veloe/solicitar-lista', values)
  }

  render() {
    const { contestations, resume, moneyTransfer } = this.props

    if (!resume || !resume.type) return null

    const finantialData = resume.consumption || resume.balance || {}

    return (
      <section>
        <Content>
          <Flex mt={[0, rem('35px')]} align="stretch" wrap={[true, false]}>
            <Box mb={rem('20px')} pr={[0, 20]} width={[1, 3 / 12]}>
              <PlanResume />
            </Box>
            <Box pl={[0, 20]} width={[1, 9 / 12]}>
              <Receipts
                resume={resume}
                value={finantialData.cash || finantialData.spent}
                moneyTransfer={moneyTransfer}
              />
            </Box>
          </Flex>
          <Flex mb={[0, rem('20px')]} wrap={true}>
            <Box pr={[0, 20]} width={[1, 3 / 12]} mb={[2, 0]}>
              <Contestations data={contestations} />
            </Box>
            <Box pr={[0, 20]} width={[1, 4.5 / 12]} mb={[2, 0]}>
              <Hide at="small">
                <CarPlateForm onSubmit={this.onCarPlateQuery} />
              </Hide>
            </Box>
            <Box width={[1, 4.5 / 12]} mb={[2, 0]}>
              <Hide at="small">
                <RequestFileForm onSubmit={this.onRequestFile} />
              </Hide>
            </Box>
          </Flex>
          <Flex mb={rem('60px')} wrap={true}>
            <Box pr={[0, 20]} width={[1, 3 / 12]} mb={[2, 0]}>
              <NewPlaces />
            </Box>
            <Box width={[1, 9 / 12]}>
              <Advertise />
            </Box>
          </Flex>
        </Content>
      </section>
    )
  }
}

const mapStateToProps = ({ reducer }) => ({
  contestations: reducer.ecContestations,
  resume: reducer.dashboardResume,
  moneyTransfer: reducer.ecMoneyTransfer
})

const mapDispatchToProps = dispatch => ({
  fetchResume: planId =>
    dispatch(fetchDashboardResume(planId)).then(() => dispatch(setLoading(false))),
  fetchMoneyTransfer: () => dispatch(fetchECMoneyTransfer()),
  fetchContestations: () => dispatch(fetchECContestations()),
  setLoading: isLoading => {
    dispatch(setLoading(isLoading))
  }
})

export default connect(mapStateToProps, mapDispatchToProps)(DashboardResume)
