import React from 'react'
import { currencyParser } from 'alelo-logic/utils'
import { posYellow } from 'styles/settings/colors'
import ConsumptionChartBarPos from '../../elements/ConsumptionChartBarPos'
import ConsumptionChartIndicatorPos from '../../elements/ConsumptionChartIndicatorPos'

import {
  IndicatorList,
  IndicatorItem,
  Separator,
  SpentBalance,
  BarInfoContainer,
  Limit
} from './styles'

const { indicatorLabel } = require('./data.json')

const colors = [posYellow]

const toPercentage = (value, total) => {
  const decimal = value / total
  return decimal * 100
}

// TODO: move to logic
const calculateDataPercentage = (maxValue, dataArray = []) => {
  if (maxValue) {
    return dataArray.map(data => toPercentage(data, maxValue))
  }

  const total = dataArray.reduce((data, nextData) => data + nextData, 0)

  return dataArray.map(data => toPercentage(data, total))
}

const ConsumptionChartPos = ({ balance = { spending: { toll: 500 } }, currentPlan }) => {
  const data = (balance && balance.spending) || {}
  const consumptionTypes = Object.keys(indicatorLabel)
  const dataPercentage = calculateDataPercentage(currentPlan.limit, [currentPlan.ammount]) || []

  return (
    <section>
      <Separator />
      <ConsumptionChartBarPos colors={colors} values={dataPercentage} />
      <BarInfoContainer>
        <SpentBalance>
          Limite consumido R$ <span>{currencyParser(currentPlan.ammount)}</span>
        </SpentBalance>
        <Limit>
          Limite total R$ <span>{currencyParser(currentPlan.limit)}</span>
        </Limit>
      </BarInfoContainer>

      <IndicatorList>
        {consumptionTypes.map(indicator => (
          <IndicatorItem key={indicator}>
            <ConsumptionChartIndicatorPos
              name={indicatorLabel[indicator]}
              color={posYellow}
              value={currencyParser(data[indicator])}
              indicator={indicator}
              dataPercentage={calculateDataPercentage(currentPlan.limit, [data[indicator]])}
            />
          </IndicatorItem>
        ))}
      </IndicatorList>
    </section>
  )
}

export default ConsumptionChartPos
