import { connect } from 'react-redux'
import Modal, { SimpleModal } from './Modal'

function mapStateToProps(state) {
  return {
    isOpen: state.modal
  }
}

export { SimpleModal, mapStateToProps }
export default connect(mapStateToProps)(Modal)
