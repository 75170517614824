import styled from 'styled-components'

export const LoaderContainer = styled.div`
  visibility: hidden;
  opacity: 0;
  height: 0;
  overflow: hidden;
  transition: opacity 0.3s ease, visibility 0s 0.3s ease, height 0s 0.3s ease;

  ${props =>
    props.isLoading &&
    `
        visibility: visible;
        opacity: 1;
        height: auto;
        overflow: visible;
    `};
`
export const ContentContainer = styled.div`
  visibility: visible;
  opacity: 1;
  transition: opacity 0.3s 0.3s ease, visibility 0s 0.3s ease, height 0s 0.3s ease;
  min-height: calc(100vh - 604px);
  height: auto;
  overflow: visible;

  ${props =>
    props.isLoading &&
    `
        min-height: initial;
        visibility: hidden;
        opacity: 0;
        height: 0;
        overflow: hidden;
    `};
`
