export default {
  __html: `
        <g fill="none" fill-rule="evenodd">
            <g transform="translate(2 2)">
                <rect fill="#D6DE26" id="a" width="16" height="16" rx="8"/>
                <rect width="18" height="18" x="-1" y="-1" stroke="#28C4D4" stroke-width="2" rx="9"/>
            </g>
            <path stroke="#6C9807" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 8l-4.322 4.286L7 10.622"/>
        </g>
    `
}
