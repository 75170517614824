import React from 'react'
import { Flex, Box } from 'grid-styled'

import { Label, Text } from './styles'

import car from './car.svg'
import rem from '../../../styles/tools/rem'
import Hide from '../../../styles/utilities/Hide'

const IdentifierData = ({ label, value, highlighted }) =>
  value ? (
    <Box mb={[rem('24px'), 0]} width={[1 / 2, 3 / 12]}>
      <Label>{label}</Label>
      {value && <Text highlighted={highlighted}>{value}</Text>}
    </Box>
  ) : null

const IdentifierDetails = ({
  carBrand,
  carColor,
  carModel,
  carPlate,
  carYear,
  driverName,
  identifierId,
  category,
  serialNumber,
  Axes,
  driverEmail,
  driverDocument,
  driverPhone
}) => (
  <Flex px={[0, rem('20px')]} py={rem('32px')}>
    <Hide at="small" width={2 / 12}>
      <img src={car} alt="" />
    </Hide>

    <Box width={[1, 10 / 12]}>
      <Flex wrap mb={[0, rem('27px')]}>
        <IdentifierData highlighted label="Placa" value={carPlate} />
        <IdentifierData highlighted label="Produto" value={identifierId} />
      </Flex>

      <Flex wrap mb={[0, rem('24px')]}>
        <IdentifierData label="fabricante" value={carBrand} />
        <IdentifierData label="Modelo" value={carModel} />
        <IdentifierData label="Ano" value={carYear} />
        <IdentifierData label="Cor" value={carColor} />
      </Flex>

      <Flex wrap mb={[0, rem('24px')]}>
        <IdentifierData label="Categoria" value={category} />
        <IdentifierData label="Número de série" value={serialNumber} />
        <IdentifierData label="Eixos" value={Axes} />
      </Flex>

      <Flex wrap>
        <IdentifierData label="Condutor" value={driverName} />
        <IdentifierData label="Email" value={driverEmail} />
        <IdentifierData label="CPF" value={driverDocument} />
        <IdentifierData label="Telefone" value={driverPhone} />
      </Flex>
    </Box>
  </Flex>
)

export default IdentifierDetails
