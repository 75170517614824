import styled from 'styled-components'

import { darkGrey } from 'styles/settings/colors'
import media from 'styles/tools/media'
import rem from 'styles/tools/rem'

const textStyles = `
  font-family: OpenSans;
  font-size: ${rem('12px')};
  letter-spacing: ${rem('1px')};
  color: ${darkGrey};
  font-weight: 200;
  text-transform: uppercase;
`

export const Usage = styled.p`
  ${textStyles};

  margin-top: 0;
  display: flex;
  justify-content: space-between;

  span {
    font-family: OpenSans;
    font-size: ${rem('14px')};
    font-weight: 400;
    line-height: 1.57;
    color: ${darkGrey};
    text-transform: none;
    margin-top: ${rem('7px')};

    :first-child {
      font-family: Galano;
      font-size: ${rem('18px')};
      font-weight: bold;
    }
  }
`

export const Text = styled.p`
  ${textStyles};
`

export const TextWithoutMargins = styled(Text)`
  margin: 0px;
`

export const IndicatorList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;

  ${media.small`
    display: flex;
    flex-direction: row;
    margin-top: 20px;
  `};

  ${media.large`
    display: flex;
    justify-content: space-between;
    margin-top: ${rem('12px')};
  `};
`

export const IndicatorItem = styled.li`
  ${media.small`
    display: inline-flex;
    width: 33%;
  `};
`
