import { getContestationProtocol } from '../services/dashboardService'

import types from './types'

const { UPDATE_CONTESTATION_PROTOCOL, CLEAR_CONTESTATION_PROTOCOL } = types

export function fetchContestationProtocol(data) {
  return dispatch =>
    getContestationProtocol(data).then(protocol => {
      dispatch({
        type: UPDATE_CONTESTATION_PROTOCOL,
        protocol
      })
      return protocol
    })
}

export function clearContestationProtocol() {
  return dispatch =>
    dispatch({
      type: CLEAR_CONTESTATION_PROTOCOL
    })
}
