import React from 'react'
import { connect } from 'react-redux'
import Helmet from 'react-helmet'
import { actions } from 'alelo-logic'
import EditContactDataForm from '../../modules/EditContactDataForm'
import { showModal } from '../../modules/ModalRoot/actions'
import { CONFIRM_UPDATE } from '../../../constants/ModalTypes'
import { startSaving, stopSaving } from '../../modules/form-state-actions'
import contactFactory from './factory'

import { Heading, Paragraph, Wrapper, Content } from '../EditUserData/styles'

import image from '../../../static/confirmation.png'

const {
  helmetTitle,
  title,
  subtitle,
  modalTitle,
  modaTitleError,
  modaTextError
} = require('./data.json')

const { fetchUpdateContact } = actions

const EditContactData = ({ contacts, handleSave, profile }) => {
  const initialValues = contactFactory.toForm(contacts)
  const name = {
    emiteNotaFiscal: contacts && contacts.nome && contacts.nome.emiteNotaFiscal,
    nomeCompleto: profile && profile.nome
  }

  return (
    <section>
      <Helmet title={helmetTitle} />
      <Content>
        <Heading>{title}</Heading>
        <Paragraph>{subtitle}</Paragraph>
        <Wrapper>
          <EditContactDataForm
            initialValues={initialValues}
            onSubmit={values => {
              const factored = contactFactory.toAPI(values, name)
              handleSave(factored)
            }}
          />
        </Wrapper>
      </Content>
    </section>
  )
}

const mapStateToProps = state => ({
  profile: state.reducer.user.profile,
  contacts: state.reducer.user.contato,
  saving: state.reducer.formState.saving
})

const mapDispatchToProps = (dispatch, state) => ({
  initFormState: () => dispatch(stopSaving()),
  handleSave: contacts => {
    dispatch(startSaving())

    dispatch(fetchUpdateContact(contacts))
      .then(() => dispatch(showModal(CONFIRM_UPDATE, { modalTitle, image })))
      .then(() => dispatch(stopSaving()))
      .catch(e => {
        dispatch(
          showModal(CONFIRM_UPDATE, {
            modalTitle: modaTitleError,
            modalDescription: modaTextError,
            image
          })
        )
        dispatch(stopSaving())
      })
  }
})

export default connect(mapStateToProps, mapDispatchToProps)(EditContactData)
