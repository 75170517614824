import React from 'react'
import { connect } from 'react-redux'
import { hideModal } from '../ModalRoot/actions'
import Modal from '../../elements/BankSlipModal'

function BlackSlipModal({ hideModalSplit = hideModal, ...props }) {
  return <Modal onClose={hideModalSplit} {...props} />
}

const mapStateToProps = ({ reducer }) => {
  const { user, ...props } = reducer
  return {
    user,
    ...props
  }
}

export default connect(mapStateToProps, { hideModal })(BlackSlipModal)
