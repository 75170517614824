import types from '../actions/types'

const { UPDATE_STATEMENT } = types

function statementReducer(state = {}, action = {}) {
  switch (action.type) {
    case UPDATE_STATEMENT: {
      return {
        ...state,
        ...action.statement
      }
    }
    default:
      return state
  }
}

export default statementReducer
