export default {
  __html: `<g fill="none" fill-rule="evenodd">
                <path fill="#151245" d="M1.405 32.503c.112 1.383.222 2.793-.127 4.14-.167.644-.435 1.336-.159 1.945.067.147.166.298.132.455-.03.131-.146.23-.186.358-.125.404.493.605.932.599l11.121-.158c2.643-.038 5.29-.076 7.922-.32 1.147-.107 2.295-.253 3.447-.222 1.365.038 2.708.326 4.066.462 1.696.171 3.407.106 5.11.042l8.543-.326c.856-.032 1.762-.08 2.47-.543.136-.089.27-.203.31-.356.079-.291-.2-.551-.461-.718-.83-.53-1.897-1.044-1.984-1.996-.036-.386.11-.763.194-1.141.143-.648.104-1.318.065-1.978l-.249-4.221a38.497 38.497 0 0 0-7.85-.201c-.487.037-.985.087-1.423.294-.72.341-1.176 1.072-1.345 1.826-.169.754-.09 1.538.017 2.303.065.462.067 1.059-.379 1.247-.267.112-.573.01-.853-.067-2.01-.564-4.174.128-6.254-.105-1.41-.159-2.77-.742-4.19-.685-.703.028-1.39.214-2.093.265-1.017.074-2.028-.137-3.036-.285-1.16-.17-2.332-.258-3.505-.328a112.607 112.607 0 0 0-4.487-.177c.13-1.154.177-2.518-.76-3.25-.357-.278-.806-.416-1.243-.547l-1.844-.557c-.984-.297-2.06-.567-2.199.582-.138 1.142.205 2.514.298 3.663"/>
                <path fill="#C5168C" d="M42.82 27.045c.197-.467.188-.991.173-1.497-.101-3.572-.748-7.077-.464-10.701.093-1.187-.347-2.32-.968-3.34a241.87 241.87 0 0 0-6.638-10.282c-.168-.246-.341-.498-.58-.678-.45-.34-1.053-.378-1.618-.401-7.358-.299-12.57-.108-21.878.291-1.72.074-3.64.25-4.877 1.434-.438.42-.713.974-.98 1.516-1.25 2.54-2.5 5.079-3.697 7.643-.5 1.072-.996 2.163-1.188 3.328-.15.917-.108 1.852-.065 2.78l.366 7.967c.04.845.081 1.707.392 2.495.31.788 41.657.312 42.022-.555"/>
                <path fill="#151245" d="M41.582 28.836c.89-.387 1.864-.855 2.236-1.769.135-.333.175-.692.182-1.055.003-.175-43 1.64-43 1.64.316.83.96 1.584 1.81 1.78 11.492 2.644 23.703 1.412 35.42.17 1.063-.113 2.368-.34 3.352-.766"/>
                <path fill="#28C4D4" d="M37.001 14.798c-.005-.194.003-.416.16-.539.093-.073.218-.091.337-.106a19.473 19.473 0 0 1 3.447-.127c.198.011.41.03.565.148.201.154.245.426.274.672.316 2.776-.066 5.093-.15 7.885-.007.204.479.904.342 1.06-.195.223-.547.22-.849.2l-2.72-.187c-.222-.015-.461-.037-.628-.178-.182-.154-.227-.405-.26-.636-.388-2.76-.444-5.441-.518-8.192M1.009 16.508c-.027-.657-.021-1.39.478-1.869.434-.417 1.115-.517 1.745-.589.694-.079 1.494-.121 1.996.319.405.355.476.91.518 1.418.188 2.306.272 4.618.25 6.93-.001.243-.008.496-.135.71-.293.491-1.04.541-1.66.529-.643-.014-2.26.22-2.726-.27-.349-.365-.161-1.273-.185-1.711-.1-1.823-.207-3.644-.281-5.467"/>
                <path fill="#FFF" d="M26 24c.213 0 .44.004.617.133.276.2.33.616.356.98.057.767.071 1.657-.452 2.165-.371.36-.905.409-1.398.438a162.02 162.02 0 0 1-10.882.279c-.27-.003-.562-.013-.776-.196-.276-.238-.322-.672-.346-1.058-.04-.622-.078-1.245-.117-1.867-.07-1.142 1.442-.71 2.155-.727A476.236 476.236 0 0 1 26.001 24M21.38 15.86c1.208.062 2.457.228 3.6-.207.052-.576.036-1.294-.436-1.556-.208-.115-.453-.102-.685-.088l-3.213.206c-.553.036-1.106.071-1.658.124-.24.023-.497.058-.678.232-.64.616-.184 1.328.418 1.405.813.103 1.802-.16 2.651-.116"/>
                <path fill="#28C4D4" d="M37.99 10.757a57.518 57.518 0 0 0-4.103-7.272c-.327-.494-.69-1.007-1.244-1.265-.492-.23-1.065-.226-1.615-.217-3.85.064-7.701.17-11.551.277l-3.783.104c-2.57.07-5.144.142-7.705.364-.45.04-.922.091-1.29.338-.344.232-.546.602-.735.958L2.04 11.421c-.24.45.632.633 1.163.565 4.472-.574 8.994-.69 13.506-.762 6.868-.108 13.776-.716 20.605-.026 1.012.102.572-.355.676-.44"/>
                <path fill="#FFF" d="M5 20.42c5.476-.66 10.978-.81 16.474-.959l16.999-.46c.164-.005.354.007.452.19.098.181.11.517-.022.381C27.7 19.931 16.5 20.407 5.301 21c-.061-.221-.16-.444-.301-.58"/>
            </g>`
}
