export const types = {
  STATE_MODAL: 'STATE_MODAL',
  LOGIN_MSG_ERROR: 'LOGIN_MSG_ERROR',
  SHOW_CAPTCHA: 'SHOW_CAPTCHA',
  ACTIVATION_IDENTIFIER: 'ACTIVATION_IDENTIFIER',
  ACTIVATION_EMV_ACG_IDENTIFIER: 'ACTIVATION_EMV_ACG_IDENTIFIER',
  INACTIVE_IDENTIFIER: 'INACTIVE_IDENTIFIER',
  REPLACE_IDENTIFIER: 'REPLACE_IDENTIFIER',
  NEW_IDENTIFIER: 'NEW_IDENTIFIER',
  SET_ALIAS: 'SET_ALIAS',
  ADD_REPRESENTATIVE: 'ADD_REPRESENTATIVE',
  CLEAR_CONTESTATION_PROTOCOL: 'CLEAR_CONTESTATION_PROTOCOL',
  CLEAR_EC_QUERY_PLATE: 'CLEAR_EC_QUERY_PLATE',
  DELETE_REPRESENTATIVE: 'DELETE_REPRESENTATIVE',
  DOWNLOAD_STATEMENTS: 'DOWNLOAD_STATEMENTS',
  DOWNLOAD_INVOICES: 'DOWNLOAD_INVOICES',
  FETCH_ADDRESS_DELETE: 'FETCH_ADDRESS_DELETE',
  FETCH_ADDRESS_UPDATE: 'FETCH_ADDRESS_UPDATE',
  FETCH_BANK_LIST: 'FETCH_BANK_LIST',
  FETCH_CANCEL_ACCOUNT: 'FETCH_CANCEL_ACCOUNT',
  FETCH_CONTACT_UPDATE: 'FETCH_CONTACT_UPDATE',
  FETCH_EC_CONTESTATIONS: 'FETCH_EC_CONTESTATIONS',
  FETCH_EC_LOCATIONS: 'FETCH_EC_LOCATIONS',
  FETCH_EC_MONEY_TRANSER: 'FETCH_EC_MONEY_TRANSER',
  FETCH_EC_REQUEST_FILE_HISTORY: 'FETCH_EC_REQUEST_FILE_HISTORY',
  FETCH_EC_QUERY_PLATE: 'FETCH_EC_QUERY_PLATE',
  FETCH_GENERATE_CANCEL_ACCOUNT_TOKEN: 'FETCH_GENERATE_CANCEL_ACCOUNT_TOKEN',
  FETCH_NEXT_PAYMENT_TYPE_DATE: 'FETCH_NEXT_PAYMENT_TYPE_DATE',
  FETCH_PROFILE_UPDATE: 'FETCH_PROFILE_UPDATE',
  FETCH_PAYMENT_TYPE: 'FETCH_PAYMENT_TYPE',
  FETCH_DUE_DATES: 'FETCH_DUE_DATES',
  FETCH_SHIPPING_UPDATE_ADDRESS: 'FETCH_SHIPPING_UPDATE_ADDRESS',
  FETCH_SHIPPING_AMOUNT: 'FETCH_SHIPPING_AMOUNT',
  FETCH_USER: 'FETCH_USER',
  FETCH_ZIP_CODE: 'FETCH_ZIP_CODE',
  FETCH_STATES: 'FETCH_STATES',
  FETCH_CITIES_FROM_STATE: 'FETCH_CITIES_FROM_STATE',
  FETCH_VEHICLES: 'FETCH_VEHICLES',
  FETCH_TERMS_CONDITIONS: 'FETCH_TERMS_CONDITIONS',
  FETCH_REASON_LIST: 'FETCH_REASON_LIST',
  RESET_REASON_LIST: 'RESET_REASON_LIST',
  GET_EDIT_AUTH_PAYMENT: 'GET_EDIT_AUTH_PAYMENT',
  SET_EDIT_AUTH_PAYMENT: 'SET_EDIT_AUTH_PAYMENT',
  LOCK_EDIT_AUTH_PAYMENT: 'LOCK_EDIT_AUTH_PAYMENT',
  GET_LAST_PAYMENT_SCHEDULE: 'GET_LAST_PAYMENT_SCHEDULE',
  LOGOUT: 'LOGOUT',
  FETCH_RECOVER: 'FETCH_RECOVER',
  REQUEST_NEW_BOLETO: 'REQUEST_NEW_BOLETO',
  RESET_INVOICES: 'RESET_INVOICES',
  RESET_INVOICE_DETAILS: 'RESET_INVOICE_DETAILS',
  RESET_RECHARGES: 'RESET_RECHARGES',
  RESET_STATEMENTS: 'RESET_STATEMENTS',
  SET_ACCOUNT_ACTIVE_PLAN_ID: 'SET_ACCOUNT_ACTIVE_PLAN_ID',
  SET_ACCOUNT_PLAN: 'SET_ACCOUNT_PLAN',
  SET_ACCOUNT_PAYMENT_TYPE: 'SET_ACCOUNT_PAYMENT_TYPE',
  SET_ACCOUNT_PLANS: 'SET_ACCOUNT_PLANS',
  SET_ACTUAL_PLAN: 'SET_ACTUAL_PLAN',
  SET_EC_ACCOUNT_PLANS: 'SET_EC_ACCOUNT_PLANS',
  SET_AUTHENTICATION: 'SET_AUTHENTICATION',
  SET_NEW_DATA_VALUE: 'SET_NEW_DATA_VALUE',
  SET_NEW_DUE_DATE: 'SET_NEW_DUE_DATE',
  UPDATE_CONTESTATION_PROTOCOL: 'UPDATE_CONTESTATION_PROTOCOL',
  UPDATE_INVOICE_DETAILS: 'UPDATE_INVOICE_DETAILS',
  UPDATE_INVOICES: 'UPDATE_INVOICES',
  UPDATE_PLAN_RESUME: 'UPDATE_PLAN_RESUME',
  UPDATE_REASONS: 'UPDATE_REASONS',
  UPDATE_REASONS_BLOCK: 'UPDATE_REASONS_BLOCK',
  UPDATE_RECHARGES: 'UPDATE_RECHARGES',
  UPDATE_REPRESENTATIVE: 'UPDATE_REPRESENTATIVE',
  UPDATE_STATEMENT: 'UPDATE_STATEMENT',
  SET_STATEMENTS: 'SET_STATEMENTS',
  UPDATE_STATEMENTS: 'UPDATE_STATEMENTS',
  UPDATE_RECOVER_TOKEN_STATUS: 'UPDATE_RECOVER_TOKEN_STATUS',
  SET_RECHARGE_VALUES: 'SET_RECHARGE_VALUES',
  SET_CURRENT_RECHARGE_VALUE: 'SET_CURRENT_RECHARGE_VALUE',
  SET_CANCEL_ACCOUNT_STATUS: 'SET_CANCEL_ACCOUNT_STATUS',
  SET_PAYMENT_INFO: 'SET_PAYENT_INFO',
  UPDATE_PLAN_DATA: 'UPDATE_PLAN_DATA',
  SET_CHANGE_RECHARGE: 'SET_CHANGE_RECHARGE',
  // Manage Infos
  FETCH_MANAGE_INF: 'FETCH_MANAGE_INF',
  FETCH_MANAGE_INF_FINISHED: 'FETCH_MANAGE_INF_FINISHED',
  FETCH_HIST_MANAGE_INF: 'FETCH_HIST_MANAGE_INF',
  FETCH_HIST_MANAGE_INF_FINISHED: 'FETCH_HIST_MANAGE_INF_FINISHED',
  FETCH_TOP5_MANAGE_INF: 'FETCH_TOP5_MANAGE_INF',
  FETCH_TOP5_MANAGE_INF_FINISHED: 'FETCH_TOP5_MANAGE_INF_FINISHED',
  UPDATE_MANAGE_INF: 'UPDATE_MANAGE_INF',
  UPDATE_MANAGE_INF_FINISHED: 'UPDATE_MANAGE_INF_FINISHED',
  // Manage UI effects
  TOGGLE_FOOTER: 'TOGGLE_FOOTER',

  FETCH_SUBSIDY_MESSAGE: 'FETCH_SUBSIDY_MESSAGE'
}

export default types
