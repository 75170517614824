import styled from 'styled-components'

import { white } from '../../../styles/settings/colors'

import rem from '../../../styles/tools/rem'

export const TypeOption = styled.div`
  border-radius: 6px;
  border: 1px solid ${white};
  background: transparent;
  padding: 30px 16px 16px;
  transition: background 0.3s ease;
  span {
    display: block;
    font-family: 'OpenSans';
    font-size: ${rem('12px')};
    font-weight: bold;
    color: ${white};
    margin-top: 5px;
    transition: color 0.3s ease;
  }
  svg {
    path {
      fill: ${white};
      transition: fill 0.3s ease;
    }
  }
`

export const Label = styled.label`
  cursor: pointer;
  user-select: none;

  :hover {
    ${TypeOption} {
      background: ${white};
      span {
        color: #292663;
      }
      svg {
        path {
          fill: #292663;
        }
      }
    }
  }
`

export const Input = styled.input`
  display: none;

  :checked + ${TypeOption} {
    background: ${white};
    span {
      color: #292663;
    }
    svg {
      path {
        fill: #292663;
      }
    }
  }
  :disabled + ${TypeOption} {
    background: transparent;
    opacity: 0.4;
    span {
      color: ${white};
    }
    svg {
      path {
        fill: ${white};
      }
    }
  }
`
