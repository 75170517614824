import { getPlanData } from '../services/dashboardService'

import types from './types'

const { UPDATE_PLAN_DATA } = types

export default function fetchPlanData(plan) {
  return dispatch =>
    getPlanData(plan)
      .then(plandata => {
        dispatch({
          type: UPDATE_PLAN_DATA,
          plandata: plandata
        })
      })
      .catch(error => {
        console.log('Erro em #fetchPlanData()', error)
        return error
      })
}
