import styled from 'styled-components'
import {
  txt12OpenSansBold,
  txt14OpenSans,
  txt14OpenSansBold
} from '../../../../../styles/settings/types'
import {
  linkNavyBlue,
  white,
  ghostWhite,
  paleGrayTwo,
  graySuit,
  lightRed
} from '../../../../../styles/settings/colors'
import rem from '../../../../../styles/tools/rem'
import media from '../../../../../styles/tools/media'

export const Table = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 600px;
  ${media.small`
      padding: ${rem('16px')};
    `};
  ${media.large`
      margin: 40px 0px;
    `};
`

export const TableHeader = styled.div`
  border-top: 1px solid ${paleGrayTwo};
  border-bottom: 1px solid ${paleGrayTwo};
  display: flex;
  flex-direction: row;
  height: 40px;
  width: 100%;
`

export const TableHeaderCell = styled.p`
  ${txt12OpenSansBold} margin: 10px;
  color: ${graySuit};
  display: inline-block;
  overflow: hidden;
  ${({ width }) => `width: ${width};`};
`

export const TableRow = styled.div`
  display: flex;
  flex-direction: row;
  height: 40px;
  width: 100%;
  ${({ index }) => `background-color: ${index % 2 === 0 ? white : ghostWhite};`};
`

export const TableRowCell = styled.p`
  ${txt14OpenSans} margin: 10px;
  color: ${linkNavyBlue};
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  ${({ width }) => `width: ${width};`};
`

export const TableRowCellBold = styled(TableRowCell)`
  ${txt14OpenSansBold};
`

export const TableRowCellStatus = styled(TableRowCellBold)`
  ${({ blocked }) => `color: ${blocked ? lightRed : linkNavyBlue};`};
`
