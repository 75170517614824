import types from '../actions/types'

const { FETCH_VEHICLES } = types

function dashboardVehiclesReducer(state = [], action = {}) {
  switch (action.type) {
    case FETCH_VEHICLES: {
      return action.vehicles
    }
    default:
      return state
  }
}

export default dashboardVehiclesReducer
