import { updateBusinessUserAddress, deleteBusinessUserAddress } from '../services/userService'

import types from './types'

const { FETCH_ADDRESS_UPDATE, FETCH_ADDRESS_DELETE } = types

export function fetchUpdateBusinessAddress(data) {
  return dispatch =>
    updateBusinessUserAddress(data).then(address => {
      dispatch({
        type: FETCH_ADDRESS_UPDATE,
        state: data
      })
    })
}

export function fetchDeleteBusinessAddress(data) {
  return dispatch =>
    deleteBusinessUserAddress(data).then(address => {
      dispatch({
        type: FETCH_ADDRESS_DELETE,
        state: address
      })
    })
}
