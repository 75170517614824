import styled from 'styled-components'
import {
  title24GalanoBold,
  txt12OpenSans,
  txt14OpenSans,
  txt14OpenSansBold,
  txt20OpenSansBold,
  txt24OpenSansBold
} from 'styles/settings/types'
import { linkNavyBlue, offWhite, darkGrey } from 'styles/settings/colors'
import rem from 'styles/tools/rem'
import media from 'styles/tools/media'
import container from 'styles/_objects/container'

export const HideOnSmall = styled.div`
  ${media.small`
    display: none;
  `};
`

export const HideOnLarge = styled.div`
  ${media.large`
    display: none;
  `};
`

export const Container = styled.div`
  width: 100%;
  ${container};
`

export const RowContainer = styled(Container)`
  display: flex;
  flex-direction: row;
`

export const Main = styled.main`
  display: block;
`

export const SubHeader = styled.div`
  margin: 40px 0px;
  ${media.small`
    padding: 0px ${rem('16px')};
  `};
`

export const Title = styled.h1`
  ${title24GalanoBold} margin: 0px;
  color: ${linkNavyBlue};
`

export const Subtitle = styled.p`
  ${txt14OpenSans} margin: 0px;
  color: ${linkNavyBlue};
`

export const BaseFormContent = styled.div`
  display: flex;
  flex-direction: row;
  padding: 20px;
  width: 100%;

  form {
    width: 100%;
    ${container};
  }
`

export const FormSimpleContent = styled(BaseFormContent)`
  border-radius: 4.4px;
  border: solid 1px #c5c3d8;
`

export const FormContent = styled(BaseFormContent)`
  background-color: ${offWhite};
  ${media.small`
    padding: ${rem('16px')};
  `};
  ${media.large`
    padding: ${rem('50px')} ${rem('65px')};
  `};
`

export const CarContent = styled(FormContent)`
  ${({ color }) => `background-color: ${color};`};
  flex-direction: column;
`

export const PlateLabel = styled.div`
  ${txt12OpenSans};
  ${({ color }) => `color: ${color};`};
`

export const Plate = styled.div`
  ${txt20OpenSansBold};
  ${({ color }) => `color: ${color};`};
  margin-bottom: 36px;
`

export const Label = styled.span`
  ${txt14OpenSans} color: ${darkGrey};
  align-self: flex-end;
  margin-bottom: 10px;
  text-align: center;
  width: 22px;
`

export const Divider = styled.div`
  width: 22px;
`

export const QueryButtonContainer = styled.div`
  ${media.large`
    margin: 17px 0px 0px 0px;
  `};
  ${media.small`
    width: 50%;
    margin: auto;
  `};
`

export const SimpleFormQueryButtonContainer = styled(QueryButtonContainer)`
  width: 100%;

  div {
    width: 100%;
  }
`

export const CarStatus = styled.p`
  ${title24GalanoBold} margin: 6px 16px;
  ${({ color }) => `color: ${color};`};
`

export const BackToFormContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin: 16px;
`

export const BackToFormText = styled.p`
  ${txt14OpenSansBold} margin: 0px 8px;
  color: ${linkNavyBlue};
  cursor: pointer;
`

export const CarPlateQueryTitle = styled.p`
  ${txt24OpenSansBold};
  color: ${linkNavyBlue};
  margin: 0;
`

export const CarPlateQuerySubtitle = styled.p`
  ${txt14OpenSans};
  color: ${darkGrey};
  margin: 0 0 28px 0;
`
