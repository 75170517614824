export const darkGreen = '#187580'
export const green = '#d7dd1a'
export const olive = '#c2c600'
export const violet = '#C5168C'
export const darkViolet = '#9000a3'
export const deepCerise = '#ca2293'
export const yellow = '#ffd200'
export const posYellow = '#e5b200'
export const yellowGreen = '#b8e986'
export const eletricViolet = '#bd10e0'
export const bbYellow = 'rgba(254; 237; 11)'
export const orange = '#f2634e'
export const red = '#f3614b'
export const carnation = '#f45f48'
export const lightRed = '#f45f48'
export const crimson = '#F55D45'
export const lightOlive = '#d7dd1a'

export const easternBlue = '#23abba'
export const lightEasternBlue = '#1F94A1'
export const navyBlue = '#18a2b3'
export const lightBlue = '#27c2d5'
export const darkBlue = '#2a2264'
export const linkNavyBlue = '#292663'
export const skyBlue = '#28c4d4'
export const paleBlue = '#9eaec8'
export const greyBlue = '#73829b'
export const veloeBlue = '#36bfd4'
export const blizzardBlue = '#93E1E9'
export const lightestBlue = '#f0f0f4'
export const menuOptionGrey = '#5b5983'
export const posGrey = '#d8d7e1'
export const posGreyInfo = '#b5bbc1'

export const deepPurple = '#100d49'
export const charcoal = '#354762'
export const darkGrey = '#8583a4'
export const grey = '#c5c3d8'
export const lighterGrey = '#edf0f2'
export const borderGrey = '#eaebf0'
export const disabledGrey = '#919394'
export const lightGrey = '#a1a0b7'
export const mediumGrey = '#9a98b2'
export const separatorGrey = '#364762'
export const paleGray = '#f1f1f7'
export const paleGrayTwo = '#e9e9ef'
export const paleGrayThree = '#e4e3ed'
export const offWhite = '#f1f1f7'
export const graySuit = '#bbbace'
export const white = '#ffffff'
export const darkSlateBlue = '#2a2264'
export const lightGray = '#edf0f2'
export const blueGray = '#b1b0c5'
export const tealish = '#27c2d5'
export const nearWhite = '#fafafc'
export const ghostWhite = '#f8f8fb'
export const solitude = '#e2e1eb'
export const lightSilver = '#c5c3d8'
export const deliveredGreen = '#6c9807'
export const quartz = '#f7f7fc'
export const butterflyBush = '#585380'
export const informationalLight = '#CDF3F7'
export const informationalBlue = '#24206B'
