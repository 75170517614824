import TemplateEmptyWithLoading from '../app/templates/TemplateEmptyWithLoading'
import EditIdentifiers from '../app/pages/EditIdentifiers'

export default {
  path: '/minha-veloe/:id/identificador/:identifierId/alterar',
  component: TemplateEmptyWithLoading,
  routes: [
    {
      path: '/minha-veloe/:id/identificador/:identifierId/alterar',
      exact: true,
      component: EditIdentifiers
    }
  ]
}
