import { recover } from '../services/accountService'
import types from './types'

const { FETCH_RECOVER } = types

export default function fetchRecover(login) {
  return dispatch =>
    recover(login).then(email => {
      dispatch({
        type: FETCH_RECOVER,
        email
      })
      return email
    })
}
