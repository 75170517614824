import React, { Component } from 'react'
import { connect } from 'react-redux'
import Helmet from 'react-helmet'
import { actions } from 'alelo-logic'
import { showModal } from '../../modules/ModalRoot/actions'
import EditAddressForm from '../../modules/EditAddressForm'
import { CONFIRM_UPDATE } from '../../../constants/ModalTypes'
import { startSaving, stopSaving } from '../../modules/form-state-actions'
import addressFactory from './factory'
import { Content, Heading, Paragraph, Wrapper, LastSavedLabel } from '../EditAddress/styles'

import image from '../../../static/confirmation.png'

const {
  helmetTitle,
  title,
  subtitle,
  modalUpdateTitle,
  modalDeleteTitle,
  lastSaved
} = require('./data.json')

const { fetchUpdateBusinessAddress, fetchDeleteBusinessAddress } = actions

let key = 0

class EditAddress extends Component {
  constructor(props) {
    super(props)

    this.state = {
      address: props.address
    }
  }

  componentWillMount() {
    if (!this.props.address) {
      return false
    }
  }

  removeAddress = () => {
    this.props.address.splice(0, 1)
    this.props.address[0].mail = true

    this.setState({
      address: this.props.address
    })
  }

  saveForm = () => {
    this.props.handleSave(this.props.address)
  }

  addAddress = () => {
    key += 1
    this.props.address.push({
      zipCode: '',
      address: '',
      number: '',
      additionalInfo: '',
      neighborhood: '',
      state: '',
      city: '',
      key: key,
      mail: true
    })

    this.setState({
      address: this.props.address
    })
  }

  render() {
    const { address, handleSave, handleDelete, saving } = this.props

    const initialValues = addressFactory.toForm(address)

    return (
      <Content>
        <Helmet title={helmetTitle} />
        <Heading>{title}</Heading>
        <Paragraph>{subtitle}</Paragraph>
        <Wrapper>
          <EditAddressForm
            initialValues={{ address: initialValues }}
            saveForm={this.saveForm}
            removeAddress={this.removeAddress}
            addAddress={this.addAddress}
            onSubmit={values => handleSave(addressFactory.toAPI(values.address))}
            onDelete={handleDelete}
            length={address ? address.length : 0}
            saving={saving}
          />
          <LastSavedLabel>{lastSaved}</LastSavedLabel>
        </Wrapper>
      </Content>
    )
  }
}

const mapStateToProps = state => ({
  address: state.reducer.user.enderecos,
  saving: state.reducer.formState.saving
})

const mapDispatchToProps = dispatch => ({
  initFormState: () => dispatch(stopSaving()),
  handleSave: address => {
    dispatch(startSaving())
    dispatch(fetchUpdateBusinessAddress(address))
      .then(() => {
        dispatch(showModal(CONFIRM_UPDATE, { modalTitle: modalUpdateTitle, image }))
        dispatch(stopSaving())
      })
      .catch(e => {
        console.log('Erro na promise', e)
        dispatch(stopSaving())
      })
  },
  handleDelete: (id, isCorrespondence) =>
    dispatch(fetchDeleteBusinessAddress(id)).then(() =>
      dispatch(
        showModal(
          CONFIRM_UPDATE,
          isCorrespondence ? { modalTitle: modalDeleteTitle } : { modalTitle: modalUpdateTitle }
        )
      )
    )
})

export default connect(mapStateToProps, mapDispatchToProps)(EditAddress)
