import gql from 'graphql-tag'

export const gqlTiposTelefone = gql`
  {
    tiposTelefone {
      id
      shortDesc
    }
  }
`

export const transformTelefones = src => {
  const { tiposTelefone } = src.data
  return tiposTelefone.map(t => ({
    value: t.id.toString(),
    label: t.shortDesc
  }))
}
