function tokenModalReducer(state, action) {
  if (!state) {
    state = {
      token: '',
      error: null,
      title: null,
      loading: null
    }
  }
  switch (action.type) {
    case 'UPDATE_TOKEN_MODAL':
      return {
        ...state,
        token: action.data
      }
    case 'SET_TOKEN_MODAL_TITLE':
      return {
        ...state,
        title: action.data
      }
    case 'SET_TOKEN_MODAL_ERROR':
      return {
        ...state,
        error: action.data
      }
    case 'SET_TOKEN_MODAL_LOADING':
      return {
        ...state,
        loading: action.data
      }
    default:
      return state
  }
}

export default tokenModalReducer
