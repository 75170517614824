import types from '../actions/types'

const { UPDATE_RECOVER_TOKEN_STATUS } = types

function recoverTokenStatusReducer(state = {}, action = {}) {
  switch (action.type) {
    case UPDATE_RECOVER_TOKEN_STATUS: {
      return {
        ...state,
        recoverTokenStatus: action.recoverTokenStatus
      }
    }
    default:
      return state
  }
}

export default recoverTokenStatusReducer
