import types from '../actions/types'

const { CLEAR_EC_QUERY_PLATE, FETCH_EC_QUERY_PLATE } = types

function ecQueryPlateReducer(state = null, action = {}) {
  switch (action.type) {
    case CLEAR_EC_QUERY_PLATE:
      return null
    case FETCH_EC_QUERY_PLATE:
      return action.state
    default:
      return state
  }
}

export default ecQueryPlateReducer
