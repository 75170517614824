import React from 'react'
import { Box, Flex } from 'grid-styled'
import LoadMore from '../../elements/LoadMore'
import InvoiceListItem from '../../elements/InvoiceListItem'
import Hide from '../../../styles/utilities/Hide'

import { ListContainer, ListFooter, Label } from './styles'

const InvoicesList = ({
  data,
  loadMoreHandler,
  next,
  planId,
  downloadTicket,
  loadingMore,
  requestNewBoleto
}) => {
  return (
    <ListContainer>
      <Flex width={1} direction={['column', 'row']} style={{ padding: '0 30px' }}>
        <Box width={[1, 1 / 10]}>
          <Label className="month">Mês</Label>
        </Box>
        <Hide at="small" width={[1, 2 / 6]}>
          <Label right>Valor total (R$)</Label>
        </Hide>
        <Hide at="small" width={[1, 1 / 10]}>
          <Label>Vencimento</Label>
        </Hide>
        <Box width={[1, 2 / 6]}>
          <Label marginStatus>Status da fatura</Label>
        </Box>
      </Flex>

      {data.map((invoice, index) => (
        <InvoiceListItem
          key={index}
          first={index === 0}
          {...invoice}
          planId={planId}
          requestNewBoleto={requestNewBoleto}
          downloadTicket={downloadTicket}
          situacao={invoice.situacao}
        />
      ))}
      {next && (
        <ListFooter>
          <LoadMore onClick={loadMoreHandler} loadingMore={loadingMore} />
        </ListFooter>
      )}
    </ListContainer>
  )
}

export default InvoicesList
