export default {
  __html: `<g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                <g id="09.1_Resumo_Pos_Pago_complexo" transform="translate(-185.000000, -603.000000)" stroke="currentColor">
                    <g id="card_5-copy-7" transform="translate(170.000000, 247.000000)">
                        <g id="Trama-veloe" transform="translate(110.500000, 383.500000) rotate(-270.000000) translate(-110.500000, -383.500000) translate(84.000000, 289.000000)">
                            <g id="Group-6" transform="translate(0.000000, -0.000000)">
                                <path d="M5.51858426,43.3442316 C5.51858426,39.8812322 6.88279615,36.6281115 9.29640182,34.4243846 C9.61121995,34.1095665 9.92603808,34.0046271 10.2408562,34.0046271 C10.6606137,34.0046271 10.8704925,34.3194453 10.9754318,34.4243846 L12.7594012,36.7330509 C12.8643406,36.8379903 13.0742194,37.1528084 13.0742194,37.5725659 C13.0742194,37.8873841 12.96928,38.2022022 12.6544619,38.5170203 C11.3951893,39.7762928 10.7655531,41.4553229 10.7655531,43.3442316 C10.7655531,45.2331404 11.3951893,47.0171098 12.6544619,48.171443 C12.96928,48.4862611 13.0742194,48.6961399 13.0742194,49.1158974 C13.0742194,49.5356549 12.8643406,49.850473 12.7594012,49.9554124 L10.9754318,52.2640787 C10.9754318,52.369018 10.6606137,52.6838362 10.2408562,52.6838362 C9.92603808,52.6838362 9.61121995,52.5788968 9.29640182,52.2640787 C6.88279615,49.9554124 5.51858426,46.8072311 5.51858426,43.3442316" id="Shape"></path>
                                <path d="M40.7782148,40.6158079 C43.2967598,38.0972628 46.4449411,36.8379903 49.8030012,36.9429297 C50.2227587,36.9429297 50.5375768,37.0478691 50.7474556,37.3626872 C50.9573343,37.6775053 50.9573343,38.0972628 50.9573343,38.2022022 L50.5375768,41.0355654 C50.5375768,41.1405047 50.5375768,41.5602622 50.2227587,41.8750804 C50.0128799,42.0849591 49.6980618,42.1898985 49.2783043,42.1898985 C47.5992743,42.1898985 45.8153049,42.9244741 44.5560323,44.288686 C43.1918204,45.6528979 42.4572448,47.331928 42.4572448,49.010958 C42.4572448,49.4307155 42.3523054,49.7455336 42.1424267,49.9554124 C41.8276086,50.2702305 41.407851,50.2702305 41.3029117,50.2702305 L38.4695485,50.5850486 C38.3646091,50.5850486 37.9448516,50.689988 37.6300335,50.3751699 C37.4201547,50.1652911 37.210276,49.850473 37.210276,49.4307155 C37.0003972,46.2825342 38.3646091,43.1343529 40.7782148,40.6158079" id="Shape"></path>
                                <path d="M11.9198862,75.0359234 C14.4384313,77.5544684 15.6977038,80.7026497 15.5927644,84.0607098 C15.5927644,84.4804673 15.487825,84.7952854 15.1730069,85.0051641 C14.8581888,85.3199823 14.4384313,85.2150429 14.3334919,85.2150429 L11.5001287,84.9002248 C11.3951893,84.9002248 10.9754318,84.9002248 10.6606137,84.5854066 C10.450735,84.3755279 10.3457956,84.0607098 10.3457956,83.6409523 C10.3457956,81.9619222 9.50628057,80.1779528 8.24700805,78.9186803 C6.88279615,77.5544684 5.20376613,76.8198928 3.5247361,76.8198928 C3.1049786,76.8198928 2.79016047,76.7149534 2.58028172,76.5050746 C2.26546359,76.1902565 2.26546359,75.770499 2.16052421,75.6655596 L1.84570608,72.8321965 C1.84570608,72.7272571 1.7407667,72.3074996 2.05558483,71.9926815 C2.26546359,71.7828027 2.58028172,71.5729239 3.00003922,71.5729239 C6.2531599,71.3630452 9.40134119,72.6223177 11.9198862,75.0359234" id="Shape"></path>
                                <path d="M39.728821,77.7643472 C39.728821,74.3013477 41.0930329,71.0482271 43.5066386,68.8445002 C43.8214567,68.529682 44.1362748,68.4247426 44.451093,68.4247426 C44.8708505,68.4247426 45.0807292,68.7395608 45.1856686,68.8445002 L46.969638,71.1531664 C47.0745774,71.2581058 47.2844561,71.5729239 47.2844561,71.9926815 C47.2844561,72.3074996 47.1795168,72.6223177 46.8646986,72.9371358 C45.7103655,74.1964084 44.9757899,75.8754384 44.9757899,77.7643472 C44.9757899,79.6532559 45.6054261,81.4372253 46.8646986,82.5915585 C47.1795168,82.9063766 47.2844561,83.1162554 47.2844561,83.5360129 C47.2844561,83.9557704 47.0745774,84.2705885 46.969638,84.3755279 L45.1856686,86.6841942 C45.0807292,86.7891335 44.8708505,87.1039517 44.451093,87.1039517 C44.1362748,87.1039517 43.8214567,86.9990123 43.5066386,86.6841942 C41.0930329,84.3755279 39.728821,81.2273466 39.728821,77.7643472" id="Shape"></path>
                                <path d="M9.29640182,175.523608 C12.7594012,175.523608 16.0125219,176.88782 18.2162488,179.301426 C18.531067,179.616244 18.6360063,179.931062 18.6360063,180.24588 C18.6360063,180.665638 18.3211882,180.875517 18.2162488,180.980456 L16.0125219,182.764425 C15.9075825,182.869365 15.5927644,183.079244 15.1730069,183.079244 C14.8581888,183.079244 14.5433706,182.974304 14.2285525,182.659486 C12.96928,181.505153 11.29025,180.770577 9.40134119,180.770577 C7.51243241,180.770577 5.72846301,181.400214 4.57412987,182.659486 C4.25931174,182.974304 4.04943299,183.079244 3.62967548,183.079244 C3.20991797,183.079244 2.89509984,182.869365 2.79016047,182.764425 L0.481494184,180.980456 C0.376554807,180.980456 0.0617366772,180.665638 0.0617366772,180.24588 C0.0617366772,179.931062 0.166676054,179.616244 0.481494184,179.301426 C2.58028172,176.88782 5.72846301,175.523608 9.29640182,175.523608" id="Shape"></path>
                                <path d="M43.4016992,141.103493 C46.8646986,141.103493 50.1178193,142.467705 52.3215462,144.881311 C52.6363643,145.196129 52.7413037,145.510947 52.7413037,145.825765 C52.7413037,146.245522 52.3215462,146.455401 52.3215462,146.560341 L50.0128799,148.34431 C49.9079406,148.449249 49.5931224,148.659128 49.1733649,148.659128 C48.8585468,148.659128 48.5437287,148.554189 48.2289105,148.239371 C46.969638,147.085037 45.290608,146.350462 43.4016992,146.350462 C41.5127904,146.350462 39.728821,146.980098 38.5744879,148.239371 C38.2596698,148.554189 37.9448516,148.659128 37.6300335,148.659128 C37.210276,148.659128 36.8954579,148.449249 36.7905185,148.34431 L34.4818522,146.560341 C34.3769128,146.560341 34.0620947,146.245522 34.0620947,145.825765 C34.0620947,145.510947 34.1670341,145.196129 34.4818522,144.881311 C36.7905185,142.467705 39.9386998,141.103493 43.4016992,141.103493" id="Shape"></path>
                                <path d="M47.1795168,179.301426 C47.1795168,182.764425 45.8153049,186.017546 43.4016992,188.221273 C43.0868811,188.536091 42.7720629,188.641031 42.4572448,188.641031 C42.0374873,188.641031 41.8276086,188.326212 41.7226692,188.221273 L39.9386998,185.912607 C39.8337604,185.807667 39.6238816,185.492849 39.6238816,185.073092 C39.6238816,184.758274 39.728821,184.443455 40.0436392,184.128637 C41.1979723,182.869365 41.9325479,181.190335 41.9325479,179.301426 C41.9325479,177.412517 41.3029117,175.628548 40.0436392,174.474215 C39.728821,174.159397 39.6238816,173.949518 39.6238816,173.52976 C39.6238816,173.110003 39.8337604,172.795185 39.9386998,172.690245 L41.7226692,170.381579 C41.8276086,170.27664 42.0374873,169.961822 42.4572448,169.961822 C42.7720629,169.961822 43.0868811,170.066761 43.4016992,170.381579 C45.8153049,172.585306 47.1795168,175.733487 47.1795168,179.301426" id="Shape"></path>
                                <path d="M12.96928,144.881311 C12.96928,148.34431 11.6050681,151.597431 9.19146244,153.801158 C8.87664431,154.115976 8.56182618,154.220915 8.24700805,154.220915 C7.82725054,154.220915 7.61737179,153.906097 7.51243241,153.801158 L5.72846301,151.492491 C5.62352364,151.387552 5.41364488,151.072734 5.41364488,150.652976 C5.41364488,150.338158 5.51858426,150.02334 5.83340239,149.708522 C6.98773553,148.449249 7.72231117,146.770219 7.72231117,144.881311 C7.72231117,142.992402 7.09267491,141.208432 5.83340239,140.054099 C5.51858426,139.739281 5.41364488,139.424463 5.41364488,139.109645 C5.41364488,138.689887 5.62352364,138.375069 5.72846301,138.27013 L7.51243241,135.961464 C7.51243241,135.856524 7.82725054,135.541706 8.24700805,135.541706 C8.56182618,135.541706 8.87664431,135.646645 9.19146244,135.961464 C11.7100075,138.16519 12.96928,141.313372 12.96928,144.881311" id="Shape"></path>
                                <path d="M11.9198862,107.732771 C14.4384313,110.251316 15.6977038,113.399498 15.5927644,116.757558 C15.5927644,117.177315 15.487825,117.492133 15.1730069,117.702012 C14.8581888,118.01683 14.4384313,117.911891 14.3334919,117.911891 L11.5001287,117.597073 C11.3951893,117.597073 10.9754318,117.597073 10.6606137,117.282254 C10.450735,117.072376 10.3457956,116.757558 10.3457956,116.3378 C10.3457956,114.65877 9.50628057,112.874801 8.24700805,111.615528 C6.88279615,110.251316 5.20376613,109.516741 3.5247361,109.516741 C3.1049786,109.516741 2.79016047,109.411801 2.58028172,109.201922 C2.26546359,108.887104 2.26546359,108.467347 2.16052421,108.362407 L1.84570608,105.529044 C1.84570608,105.424105 1.7407667,105.004347 2.05558483,104.689529 C2.26546359,104.479651 2.58028172,104.269772 3.00003922,104.269772 C6.2531599,103.954954 9.40134119,105.319166 11.9198862,107.732771" id="Shape"></path>
                                <path d="M39.728821,110.461195 C39.728821,106.998196 41.0930329,103.745075 43.5066386,101.541348 C43.8214567,101.22653 44.1362748,101.12159 44.451093,101.12159 C44.8708505,101.12159 45.0807292,101.436409 45.1856686,101.541348 L46.969638,103.850014 C47.0745774,103.954954 47.2844561,104.269772 47.2844561,104.689529 C47.2844561,105.004347 47.1795168,105.319166 46.8646986,105.633984 C45.7103655,106.893256 44.9757899,108.572286 44.9757899,110.461195 C44.9757899,112.350104 45.6054261,114.134073 46.8646986,115.288406 C47.1795168,115.603224 47.2844561,115.813103 47.2844561,116.232861 C47.2844561,116.652618 47.0745774,116.967436 46.969638,117.072376 L45.1856686,119.381042 C45.0807292,119.485981 44.8708505,119.8008 44.451093,119.8008 C44.1362748,119.8008 43.8214567,119.69586 43.5066386,119.381042 C41.0930329,117.072376 39.728821,113.924194 39.728821,110.461195" id="Shape"></path>
                                <path d="M43.4016992,11.3170104 C39.9386998,11.3170104 36.6855791,9.95279851 34.4818522,7.53919284 C34.1670341,7.22437471 34.0620947,6.90955659 34.0620947,6.59473846 C34.1670341,6.17498095 34.4818522,5.9651022 34.4818522,5.86016282 L36.7905185,4.07619342 C36.8954579,3.97125404 37.210276,3.76137529 37.6300335,3.76137529 C37.9448516,3.76137529 38.2596698,3.86631466 38.5744879,4.18113279 C39.8337604,5.33546594 41.5127904,6.07004157 43.4016992,6.07004157 C45.290608,6.07004157 47.0745774,5.44040531 48.3338499,4.18113279 C48.5437287,3.86631466 48.8585468,3.76137529 49.2783043,3.76137529 C49.6980618,3.76137529 50.0128799,3.97125404 50.1178193,4.07619342 L52.4264856,5.86016282 C52.531425,5.86016282 52.8462431,6.17498095 52.8462431,6.59473846 C52.8462431,6.90955659 52.7413037,7.22437471 52.4264856,7.53919284 C50.1178193,9.95279851 46.969638,11.3170104 43.4016992,11.3170104" id="Shape"></path>
                                <path d="M6.56797802,10.2676166 C4.04943299,7.7490716 2.79016047,4.6008903 2.89509984,1.24283025 C2.89509984,0.823072744 3.00003922,0.508254614 3.31485735,0.298375861 C3.62967548,-0.0164422687 4.04943299,0.0884971078 4.15437236,0.0884971078 L6.98773553,0.403315238 C7.09267491,0.403315238 7.51243241,0.403315238 7.82725054,0.718133367 C8.0371293,0.92801212 8.14206867,1.24283025 8.14206867,1.66258776 C8.14206867,3.34161778 8.98158369,5.12558718 10.2408562,6.3848597 C11.6050681,7.7490716 13.2840981,8.48364723 14.9631282,8.48364723 C15.3828857,8.48364723 15.6977038,8.58858661 15.9075825,8.79846536 C16.2224007,9.11328349 16.2224007,9.533041 16.2224007,9.63798038 L16.5372188,12.4713435 C16.5372188,12.5762829 16.6421582,12.9960404 16.32734,13.3108586 C16.1174613,13.5207373 15.8026432,13.7306161 15.3828857,13.7306161 C12.3396437,14.0454342 9.08652306,12.6812223 6.56797802,10.2676166" id="Shape"></path>
                            </g>
                        </g>
                    </g>
                </g>
            </g>`
}
