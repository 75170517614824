import React, { Component } from 'react'
import { bool, func, oneOfType, string } from 'prop-types'

import { Icon } from '../Icon'
import Tooltip from './Tooltip'

import {
  BottomMessage,
  Content,
  Error,
  InputField,
  Label,
  LengthCounter,
  LoadingContainer,
  Wrapper,
  LabelIconWrapper,
  InputIconWrapper
} from './styles'

class TextInput extends Component {
  constructor(props) {
    super(props)
    this.state = {
      valueLength: (props.value && props.value.length) || 0,
      isFocus: false
    }
  }

  onClick = e => {
    if (this.props.lockCursor) {
      this.lockCursorFunc(e)
    }
  }

  keyUp = e => {
    const { lockCursor, lengthCounter } = this.props

    if (lockCursor) {
      this.lockCursorFunc(e)
    }
    if (lengthCounter) {
      this.setState({ valueLength: e.target.value.length })
    }
  }

  keyDown = e => {
    if (this.props.lockCursor) {
      this.lockCursorFunc(e)
    }
  }

  blur = () => {
    if (this.props.onBlur) {
      this.props.onBlur()
    }
    this.input.blur()
    this.setState({
      isFocus: false
    })
  }

  focus = () => {
    if (this.props.onFocus) {
      this.props.onFocus()
    }

    this.input.focus()
    this.setState({
      isFocus: true
    })
  }

  lockCursorFunc = e => {
    const currentValue = e.target.value
    e.target.value = ''
    e.target.value = currentValue
  }

  isEmpty = value => {
    const isString = typeof value === 'string'
    const isEmpty = !(isString && value.length > 0)

    return isEmpty
  }

  render() {
    const {
      disabled,
      errorMessage,
      label,
      loading,
      name,
      onChange,
      value,
      negative,
      isRequired,
      tooltip,
      tooltipColor,
      tooltipPositionRight,
      endIcon,
      labelIcon,
      inputRefFn
    } = this.props

    const { isFocus } = this.state

    const isEmpty = this.isEmpty(value)
    const highlightPlaceholderOnBlur = isEmpty && !isFocus
    return (
      <Tooltip
        text={tooltip}
        tooltipColor={tooltipColor}
        positionRight={tooltipPositionRight}
        showTooltip={tooltip && isFocus}
      >
        <Wrapper negative={negative}>
          <Label htmlFor={name}>
            {label}
            {isRequired && '*'}
          </Label>
          {labelIcon && <LabelIconWrapper>{labelIcon}</LabelIconWrapper>}
          <Content disabled={disabled} isFocus={isFocus} hasError={errorMessage}>
            <InputField
              {...this.props}
              maxLength={this.props.maxlength}
              innerRef={component => {
                this.input = component
                inputRefFn && inputRefFn(component)
              }}
              onChange={onChange && (e => onChange(e.target.value))}
              onFocus={this.focus}
              onKeyUp={this.keyUp}
              onKeyDown={this.keyDown}
              onClick={this.onClick}
              onBlur={this.blur}
              isFocus={isFocus}
              highlightPlaceholderOnBlur={highlightPlaceholderOnBlur}
            />
            {endIcon && <InputIconWrapper>{endIcon}</InputIconWrapper>}
            {loading && (
              <LoadingContainer>
                <Icon width="11" height="4" icon="loading" viewBox="0 0 11 4" />
              </LoadingContainer>
            )}
          </Content>
          {this.props.lengthCounter && this.props.maxlength && (
            <LengthCounter>
              {this.state.valueLength}/{this.props.maxlength}
            </LengthCounter>
          )}
          {errorMessage && errorMessage.length > 1 && (
            <BottomMessage>
              <Error>{errorMessage}</Error>
            </BottomMessage>
          )}
        </Wrapper>
      </Tooltip>
    )
  }
}

TextInput.defaultProps = {
  disabled: null,
  errorMessage: null,
  label: null,
  lengthCounter: null,
  loading: null,
  lockCursor: null,
  name: '',
  maxlength: null,
  onChange: null,
  negative: null
}

TextInput.propTypes = {
  disabled: bool,
  errorMessage: oneOfType([string, bool]),
  label: string,
  lengthCounter: bool,
  loading: bool,
  lockCursor: bool,
  name: string,
  maxlength: string,
  onChange: func,
  negative: bool
}

export default TextInput
