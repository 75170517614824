import styled from 'styled-components'

import { white } from '../../../styles/settings/colors'

import rem from '../../../styles/tools/rem'

export const MonthOption = styled.div`
  border-radius: 50%;
  border: 1px solid ${white};
  background: transparent;
  transition: background 0.3s ease;
  text-align: center;
  padding: ${rem('15px')} 0;
  width: ${rem('51px')};

  span {
    display: block;
    font-family: 'OpenSans';
    font-size: ${rem('14px')};
    font-weight: bold;
    text-transform: uppercase;
    color: ${white};
    transition: color 0.3s ease;
  }
`

export const Label = styled.label`
  cursor: pointer;
  user-select: none;

  :hover {
    ${MonthOption} {
      background: ${white};
      span {
        color: #292663;
      }
    }
  }
`

export const Input = styled.input`
  display: none;

  :checked + ${MonthOption} {
    background: ${white};
    span {
      color: #292663;
    }
  }
`
