import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Field } from 'redux-form'
import { Flex, Box } from 'grid-styled'
import { utils, actions, services } from 'alelo-logic'
import Button from '../../elements/Button'

import { ButtonContainer } from './styles'

import { renderTextInput, renderSelectField, renderZipCodeInput } from '../../../utils/renderInputs'

import { parseCities, parseStates } from '../../../utils/parseCombo'

import { showModal } from '../../modules/ModalRoot/actions'
import { CONFIRM_UPDATE } from '../../../constants/ModalTypes'

import image from '../../../static/contestacao.png'

const { labels, titleModalError, descriptionModalError } = require('./data.json')

const { fetchZipCode } = actions
const { getStates, getCitiesFromState } = services

class AddressFormSection extends Component {
  constructor(props) {
    super(props)
    this.state = {
      states: [],
      formStatus: this.createAllIntialFormStatus(props)
    }
  }

  componentDidMount = () => {
    this.retrieveStates()
    // if (!this.localControl.isCitiesInit) {
    //   this.fetchCitiesForEachForm(this.props)
    // }
    this.addAddress()
  }

  componentWillReceiveProps = (props, param2) => {
    if (!this.localControl.isCitiesInit) {
      this.fetchCitiesForEachForm(props)
    }

    if (!this.localControl.isFormStatusInit) {
      const allStatus = this.createAllIntialFormStatus(props)
      if (allStatus.length > 0) {
        this.setState({ formStatus: allStatus })
      }
    }
  }

  localControl = { isFormStatusInit: false, isCitiesInit: false }
  createAllIntialFormStatus = props => {
    const allFormStatus = []
    for (let i = 0; i < props.fields.length; i += 1) {
      const status = this.createEmptyFormStatus(false)
      status.lastSearch = props.fields.get(i).zipCode

      allFormStatus.push(status)
    }

    this.localControl.isFormStatusInit = props.fields.length > 0

    return allFormStatus
  }

  fetchCitiesForEachForm = props => {
    for (let i = 0; i < props.fields.length; i += 1) {
      this.updateFormStatus(i, { isSearchingCep: true })

      getCitiesFromState({ state: props.fields.get(i).state }).then((address = {}) => {
        const cities = parseCities(address)
        this.updateFormStatus(i, { isSearchingCep: false, cities: cities })
      })
    }

    this.localControl.isCitiesInit = props.fields.length > 0
  }

  createEmptyFormStatus = isNew => ({
    lastSearch: '',
    isSearchingCep: false,
    isNew: isNew,
    cities: []
  })

  checkboxNormalize = index => value => {
    const newValue = !!value
    const target = index === 0 ? 1 : 0
    this.props.fields.get(target).mail = !newValue
    return newValue
  }

  resetPage = index => {
    const fieldValues = this.props.fields.get(index)
    const fielsToPopulate = {
      address: '',
      number: '',
      additionalInfo: '',
      neighborhood: '',
      state: '',
      city: '',
      zipCode: ''
    }

    this.props.fields.remove(index)
    this.props.fields.insert(index, {
      ...fieldValues,
      ...fielsToPopulate
    })
  }

  autoCompleteAddress = index => (event, oldValue, newValue) => {
    const formStatus = this.state.formStatus[index]

    if (newValue && newValue.length >= 9 && newValue !== formStatus.lastSearch) {
      this.updateFormStatus(index, { isSearchingCep: true })

      const fieldValues = this.props.fields.get(index)
      const data = { zip_code: newValue }

      this.props
        .getAddressFromZipCode(data)
        .then((address = {}) => {
          const fielsToPopulate = {
            address: address.logradouro,
            number: '',
            additionalInfo: '',
            neighborhood: address.bairro,
            state: address.uf,
            city: address.cidade
          }

          this.props.fields.remove(index)
          this.props.fields.insert(index, {
            ...fieldValues,
            ...fielsToPopulate
          })

          this.updateFormStatus(index, { lastSearch: newValue })
          this.retrieveCities(index, address.uf)
        })
        .catch(e => {
          console.log('Erro ao consultar CEP', e)
          this.resetPage(index)
          this.updateFormStatus(index, { isSearchingCep: false })

          this.props.showErrorModal()
        })
    }
  }

  retrieveStates = () => {
    getStates().then(address => {
      const states = parseStates(address)
      this.setState({ states })
    })
  }

  retrieveCities = (index, state) => {
    getCitiesFromState({ state: state }).then((address = {}) => {
      const cities = parseCities(address)
      this.updateFormStatus(index, { isSearchingCep: false, cities: cities })
    })
  }

  changeComboStates = index => (event, oldValue, newValue) => {
    this.retrieveCities(index, newValue)
  }

  removeAddress = index => {
    const isCorrespondence = this.props.fields.get(index).mail
    const key = this.props.fields.get(index).key
    this.props.onDelete(key, isCorrespondence).then(() => {
      this.props.fields.pop()
      this.props.fields.get(0).mail = true

      this.state.formStatus.pop()
    })
  }

  addAddress = () => {
    const allFormStatus = this.state.formStatus
    allFormStatus.push(this.createEmptyFormStatus(true))
    this.setState({ formStatus: allFormStatus })

    this.props.fields.push({
      mail: false
    })
  }

  updateFormStatus = (index, newData) => {
    const allFormStatus = this.state.formStatus
    const oldData = allFormStatus[index]

    allFormStatus[index] = {
      ...oldData,
      ...newData
    }

    this.setState({ formStatus: allFormStatus })
  }

  render() {
    const { history } = this.props

    return (
      <div>
        {this.props.fields &&
          this.props.fields.map((field, index) => {
            const formStatus = this.state.formStatus[index] || {} // primeira chamada initialValues está undefined
            const disabled =
              (formStatus.isNew && formStatus.lastSearch === '') ||
              formStatus.isSearchingCep ||
              this.props.saving

            return (
              <div key={field}>
                <Flex wrap width={[1]} mb={[0, 32]} key={field}>
                  <Box width={3 / 12} mb={3}>
                    <Field
                      component={renderZipCodeInput}
                      label={labels.zipCode}
                      validate={utils.cepValidate}
                      normalize={utils.cepMask}
                      onBlur={this.autoCompleteAddress(index)}
                      name={`${field}.zipCode`}
                      type="text"
                      isRequired
                      disabled={formStatus.isSearchingCep || this.props.saving}
                      loading={formStatus.isSearchingCep}
                      onFocus={() => {
                        if (
                          window.location.pathname.includes('/identificadores/novo-produto')
                        ) {
                          window.dataLayer = window.dataLayer || []
                          window.dataLayer.push({
                            event: 'event',
                            eventAction: 'preencher:cep',
                            eventCategory: 'veloe:area-logado:solicitar-produto',
                            eventLabel: 'cep'
                          })
                        } else {
                          window.dataLayer = window.dataLayer || []
                          window.dataLayer.push({
                            event: 'event',
                            eventAction: 'preencher:cep',
                            eventCategory: 'veloe:area-logado:meus-produtos:bloquear-substituir',
                            eventLabel: 'cep'
                          })
                        }
                      }}
                    />
                  </Box>
                  <Box width={6 / 12} px={3} mb={3}>
                    <Field
                      component={renderTextInput}
                      label={labels.address}
                      validate={utils.emptyFieldValidate}
                      name={`${field}.address`}
                      type="text"
                      isRequired
                      disabled={disabled}
                      onFocus={() => {
                        if (
                          window.location.pathname.includes('/identificadores/novo-produto')
                        ) {
                          window.dataLayer = window.dataLayer || []
                          window.dataLayer.push({
                            event: 'event',
                            eventAction: 'preencher:rua',
                            eventCategory: 'veloe:area-logado:solicitar-produto',
                            eventLabel: 'rua'
                          })
                        } else {
                          window.dataLayer = window.dataLayer || []
                          window.dataLayer.push({
                            event: 'event',
                            eventAction: 'preencher:rua',
                            eventCategory: 'veloe:area-logado:meus-produtos:bloquear-substituir',
                            eventLabel: 'rua'
                          })
                        }
                      }}
                    />
                  </Box>
                  <Box width={3 / 12} mb={3}>
                    <Field
                      component={renderTextInput}
                      label={labels.number}
                      validate={utils.emptyFieldValidate}
                      normalize={utils.numberMask}
                      name={`${field}.number`}
                      type="text"
                      isRequired
                      disabled={disabled}
                      onFocus={() => {
                        if (
                          window.location.pathname.includes('/identificadores/novo-produto')
                        ) {
                          window.dataLayer = window.dataLayer || []
                          window.dataLayer.push({
                            event: 'event',
                            eventAction: 'preencher:numero-casa',
                            eventCategory: 'veloe:area-logado:solicitar-produto',
                            eventLabel: 'numero-casa'
                          })
                        } else {
                          window.dataLayer = window.dataLayer || []
                          window.dataLayer.push({
                            event: 'event',
                            eventAction: 'preencher:numero-casa',
                            eventCategory: 'veloe:area-logado:meus-produtos:bloquear-substituir',
                            eventLabel: 'numero-casa'
                          })
                        }
                      }}
                    />
                  </Box>
                  <Box width={8 / 12} pr={3} mb={3}>
                    <Field
                      component={renderTextInput}
                      label={labels.additionalInfo}
                      name={`${field}.additionalInfo`}
                      type="text"
                      disabled={disabled}
                      onFocus={() => {
                        if (
                          window.location.pathname.includes('/identificadores/novo-produto')
                        ) {
                          window.dataLayer = window.dataLayer || []
                          window.dataLayer.push({
                            event: 'event',
                            eventAction: 'preencher:complemento',
                            eventCategory: 'veloe:area-logado:solicitar-produto',
                            eventLabel: 'complemento'
                          })
                        } else {
                          window.dataLayer = window.dataLayer || []
                          window.dataLayer.push({
                            event: 'event',
                            eventAction: 'preencher:complemento',
                            eventCategory: 'veloe:area-logado:meus-produtos:bloquear-substituir',
                            eventLabel: 'complemento'
                          })
                        }
                      }}
                    />
                  </Box>
                  <Box width={4 / 12} mb={3}>
                    <Field
                      component={renderTextInput}
                      label={labels.neighborhood}
                      validate={utils.emptyFieldValidate}
                      name={`${field}.neighborhood`}
                      type="text"
                      isRequired
                      disabled={disabled}
                      onFocus={() => {
                        if (
                          window.location.pathname.includes('/identificadores/novo-produto')
                        ) {
                          window.dataLayer = window.dataLayer || []
                          window.dataLayer.push({
                            event: 'event',
                            eventAction: 'preencher:bairro',
                            eventCategory: 'veloe:area-logado:solicitar-produto',
                            eventLabel: 'bairro'
                          })
                        } else {
                          window.dataLayer = window.dataLayer || []
                          window.dataLayer.push({
                            event: 'event',
                            eventAction: 'preencher:bairro',
                            eventCategory: 'veloe:area-logado:meus-produtos:bloquear-substituir',
                            eventLabel: 'bairro'
                          })
                        }
                      }}
                    />
                  </Box>
                  <Box width={6 / 12} pr={3} mb={3}>
                    <Field
                      component={renderSelectField}
                      label={labels.state}
                      placeholder={labels.select}
                      name={`${field}.state`}
                      options={this.state.states}
                      isRequired
                      disabled={true}
                      onFocus={() => {
                        if (
                          window.location.pathname.includes('/identificadores/novo-produto')
                        ) {
                          window.dataLayer = window.dataLayer || []
                          window.dataLayer.push({
                            event: 'event',
                            eventAction: 'preencher:estado',
                            eventCategory: 'veloe:area-logado:solicitar-produto',
                            eventLabel: 'estado'
                          })
                        } else {
                          window.dataLayer = window.dataLayer || []
                          window.dataLayer.push({
                            event: 'event',
                            eventAction: 'preencher:estado',
                            eventCategory: 'veloe:area-logado:meus-produtos:bloquear-substituir',
                            eventLabel: 'estado'
                          })
                        }
                      }}
                    />
                  </Box>
                  <Box width={6 / 12} mb={3}>
                    <Field
                      component={renderSelectField}
                      label={labels.city}
                      validate={utils.emptyFieldValidate}
                      placeholder={labels.select}
                      name={`${field}.city`}
                      options={formStatus.cities || []}
                      isRequired
                      disabled={true}
                      onFocus={() => {
                        if (
                          window.location.pathname.includes('/identificadores/novo-produto')
                        ) {
                          window.dataLayer = window.dataLayer || []
                          window.dataLayer.push({
                            event: 'event',
                            eventAction: 'preencher:cidade',
                            eventCategory: 'veloe:area-logado:solicitar-produto',
                            eventLabel: 'cidade'
                          })
                        } else {
                          window.dataLayer = window.dataLayer || []
                          window.dataLayer.push({
                            event: 'event',
                            eventAction: 'preencher:cidade',
                            eventCategory: 'veloe:area-logado:meus-produtos:bloquear-substituir',
                            eventLabel: 'cidade'
                          })
                        }
                      }}
                    />
                  </Box>
                </Flex>
                <ButtonContainer>
                  <Flex wrap>
                    <Box width={[1, 'auto']}>
                      <Button
                        buttonType="white"
                        onClick={() => {
                          if (
                            window.location.pathname.includes('/identificadores/novo-produto')
                          ) {
                            window.dataLayer = window.dataLayer || []
                            window.dataLayer.push({
                              event: 'event',
                              eventAction: 'clique:cancelar',
                              eventCategory: 'veloe:area-logado:solicitar-produto',
                              eventLabel: 'cancelar'
                            })
                          } else {
                            window.dataLayer = window.dataLayer || []
                            window.dataLayer.push({
                              event: 'event',
                              eventAction: 'clique:cancelar',
                              eventCategory: 'veloe:area-logado:meus-produtos:bloquear-substituir',
                              eventLabel: 'cancelar'
                            })
                          }
                          history.goBack()
                        }}
                        text="Cancelar"
                      />
                    </Box>
                    <Box
                      width={[1, 'auto']}
                      ml={[0, 30]}
                      mt={[20, 0]}
                      onClick={() => {
                        if (
                          window.location.pathname.includes('identificadores/novo-produto')
                        ) {
                          window.dataLayer = window.dataLayer || []
                          window.dataLayer.push({
                            event: 'event',
                            eventAction: 'clique:enviar-pedido',
                            eventCategory: 'veloe:area-logado:solicitar-produto',
                            eventLabel: 'enviar-pedido'
                          })
                        } else {
                          window.dataLayer = window.dataLayer || []
                          window.dataLayer.push({
                            event: 'event',
                            eventAction: 'clique:enviar-pedido',
                            eventCategory: 'veloe:area-logado:meus-produtos:bloquear-substituir',
                            eventLabel: 'enviar-pedido'
                          })
                        }
                      }}
                    >
                      <Button
                        text="Enviar Pedido"
                        width="185px"
                        type="submit"
                        loading={this.props.loadingButton}
                        disabled={this.props.saving || this.props.disableButton}
                      />
                    </Box>
                  </Flex>
                </ButtonContainer>
              </div>
            )
          })}
      </div>
    )
  }
}

const mapDispatchToProps = dispatch => ({
  getAddressFromZipCode: zipCode => dispatch(fetchZipCode(zipCode)),
  showErrorModal: () =>
    dispatch(
      showModal(CONFIRM_UPDATE, {
        modalTitle: titleModalError,
        modalDescription: descriptionModalError,
        image
      })
    )
})

export default connect(null, mapDispatchToProps)(AddressFormSection)
