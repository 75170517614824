import TemplateEmpty from '../app/templates/TemplateEmpty'
import Contestation from '../app/pages/Contestation'
import ContestationConfirm from '../app/pages/ContestationConfirm'

export default {
  path: '/minha-veloe/:id/extrato/:statementId/contestacao',
  component: TemplateEmpty,
  routes: [
    {
      path: '/minha-veloe/:id/extrato/:statementId/contestacao',
      exact: true,
      component: Contestation
    },
    {
      path: '/minha-veloe/:id/extrato/:statementId/contestacao/confirmacao',
      exact: true,
      component: ContestationConfirm
    }
  ]
}
